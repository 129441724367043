import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Models
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getBatch } from 'app/store/DMSBatches/DMSBatches.async';
import { createDocument, updateDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
import { useGetDocumentQuery } from 'app/store/DMSDocuments/DMSDocuments.api';
// Actions
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import CaseInfoBar from 'app/components/CaseInfoBar';
import { Loader } from 'app/components/Utilities';
import { Editor, Toolbar } from 'app/components/QuillEditor';
// 
import DialogTitle from './DialogTitle';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  collectionId: string;
  name: string;
  content: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  documentId?: number;
  document?: IDocument;
  content?: string;
}

const DocumentFormDialog:FC<Props> = ({
  // Props
  open, onClose, documentId, document, content
}) => {
  const { t } = useTranslation();
  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();

  // This will fetch document from sidebar
  // Since you might have selected one document and edit another one
  const { isLoading, data:documentData } = useGetDocumentQuery(documentId, {
    skip: !documentId,
    refetchOnMountOrArgChange: true, // Fetch new data on each mount or arg change
  });

  // Preselect value from document inner `edit` button
  // And `content` if is create document from template
  const methods = useForm<IFormData>({
    defaultValues: {
      name: document?.name || '',
      collectionId: document?.collectionId.toString() || '',
      content: document?.content || content || ''
    }
  });

  const { handleSubmit, reset } = methods;

  // Merge `document` from props and `documentData` to one variable
  const selectedDocument = useMemo(() => {
    return documentData || document;
  }, [document, documentData]);

  // Preselect document data
  useEffect(() => {
    if ( !documentId || !documentData ) return;
    reset({
      name: documentData.name,
      collectionId: documentData.collectionId.toString(),
      content: documentData.content
    });
  }, [reset, documentId, documentData])

  const onSubmit = handleSubmit((data:IFormData) => {
    const { collectionId, ...restData } = data;
    const nextData:any = {
      ...restData,
      insuranceCaseId: Number(caseId),
      collectionId: Number(collectionId)
    };
    if ( !selectedDocument ){
      asyncCreateDocument(nextData);
    } else {
      if ( selectedDocument && selectedDocument.labels ){
        nextData['labels'] = selectedDocument.labels;
      }
      asyncUpdateDocument(selectedDocument.id, nextData);
    }
  });

  const asyncCreateDocument = async (data:any) => {
    try {
      const documentResponse = await dispatch(createDocument(data)).unwrap();
      const batchResponse = await dispatch(getBatch(documentResponse.batchId)).unwrap();
      dispatch(DMSBatchesActions.addBatch(batchResponse));
      onClose();
    } catch(error){
      console.log(error);
    }
  }

  const asyncUpdateDocument = async (documentId:number, data:any) => {
    try {
      await dispatch(updateDocument({ documentId, data })).unwrap();
      onClose();
    } catch(error){
      console.log(error);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{ sx: { border: 0 } }}
      ContentProps={{ sx: { border: 0, p: 0 } }}
    >
      <FormProvider {...methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative'
          }}
          component="form"
          onSubmit={onSubmit}
          noValidate
        >
          <DialogTitle onClose={onClose} />
          <CaseInfoBar />
          <Box
            sx={{
              borderBottom: '1px solid rgba(0,0,0,0.08)',
              px: 6,
              py: 2
            }}
          >
            <Toolbar id="content" isTabStopVisible={true} isPageBreakVisible={true} />
          </Box>
          <Box sx={{
            flexGrow: 1,
            bgcolor: '#f7f7f7',
            py: 4,
            px: 6,
            overflow: 'hidden'
          }}>
            <Box sx={{
              width: '100%',
              maxWidth: 1280,
              height: '100%',
              mx: 'auto'
            }}>
              {isLoading ? <Loader /> : (
                <Controller
                  name="content"
                  render={({ field }) => (
                    <Editor
                      {...field}
                      label={t('labels.content')} id="content"
                    />
                  )}
                />
              )}
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default DocumentFormDialog;
