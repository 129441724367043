import { FC, Fragment } from 'react';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Actions
import MixpanelActions from 'app/features/mixpanel/MixpanelActions';
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
// Mui
import { Chip, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  documentId: number;
  pageNum: number;
  highlighted?: boolean;
  messageId?: string;
}

const MetadataDocumentPageNum:FC<Props> = ({
  // Props
  documentId, pageNum, highlighted = false, messageId
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(AppUIActions.setPreviewPageOpen(true));
    dispatch(AppUIActions.setPreviewPage({ documentId, pageNum }));
    if ( messageId ){
      dispatch(MixpanelActions.handleAIChatConversationResponsePopupPageClick({
        messageId,
        documentId,
        pageNum
      }));
    }
  }

  return (
    <Fragment>
      <Chip
        sx={{
          border: (theme:Theme) => highlighted ? `2px solid ${theme.palette.primary.main}` : 'initial',
        }}
        label={`${t('labels.page')} ${pageNum}`}
        onClick={handleClick}
        size="small"
      />
    </Fragment>
  )
}

export default MetadataDocumentPageNum;
