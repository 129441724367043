// @ts-nocheck

import { Theme, Box, Paper, IconButton, Tooltip, FormHelperText } from '@mui/material';
import { MouseEvent, FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { uuidv4 } from 'app/utilities/Utilities';

import { Toolbar, Editor } from 'app/components/QuillEditor';

import Portal from 'app/components/Portal';

// i18next
import { useTranslation } from 'react-i18next';

import {
  Delete as DeleteIcon,
  AddCircleOutline as AddCircleOutlineIcon
} from '@mui/icons-material';

type Props = {
  activeEditorId: string | null;
  onFocus: (id:string | null) => void;
}

interface IColumn {
  id: string;
  header: string;
  data: string;
  width: number;
}

const TableConfigColumns:FC<Props> = ({
  // Props
  activeEditorId, onFocus
}) => {
  const { t } = useTranslation();

  const MIN_COLUMN_WIDTH = 5;

  const paperRef = useRef<HTMLDivElement | null>(null)

  const { formState:{ isSubmitted }, getValues, setValue, watch } = useFormContext();

  const [ resizing, setResizing ] = useState(false);
  const [ columns, setColumns ] = useState<IColumn[]>([]);

  useEffect(() => {
    const tableConfig = getValues('tableConfig');
    setColumns(tableConfig?.columns ? tableConfig.columns.map((c) => ({...c, id: uuidv4() })) : []);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !resizing ) setValue('tableConfig', { columns });
    // eslint-disable-next-line
  }, [columns, resizing]);

  const handleAddColumn = (index?:number, insertBefore?:boolean = false) => () => {
    const newColumnWidth = MIN_COLUMN_WIDTH * 2;
    const newColumn = { id: uuidv4(), header: '', data: '', width: columns.length === 0 ? 100 : newColumnWidth };
    const totalWidth = columns.reduce((acc, cur) => acc + Number(cur.width), 0);
    const remainingWidth = 100 - newColumnWidth;
    const reductionFactor = totalWidth !== 0 ? remainingWidth / totalWidth : 1;
    const updatedColumns = columns.map((column) => ({
      ...column,
      width: Number((column.width * reductionFactor).toFixed(2))
    }));

    if ( typeof index !== 'undefined' ){
      const insertIndex = insertBefore ? index : index + 1;
  
      setColumns([
        ...updatedColumns.slice(0, insertIndex),
        newColumn,
        ...updatedColumns.slice(insertIndex),
      ]);
    } else {
      setColumns([
        ...updatedColumns,
        newColumn
      ]);
    }
  }

  const handleRemoveColumn = (id:string) => () => {
    const filteredColumns = columns.filter((c) => c.id !== id)
    const totalWidth = filteredColumns.reduce((acc, cur) => acc + Number(cur.width), 0);
    const reductionFactor = 100 / totalWidth;
    const updatedColumns = filteredColumns.map((column) => ({
      ...column,
      width: Number((column.width * reductionFactor).toFixed(2))
    }));

    setColumns(updatedColumns);
  }

  const handleMouseDown = (index:number) => (event:React.MouseEvent) => {
    setResizing(true);

    const paperWidth = paperRef.current?.clientWidth || 1;

    const initialPosition = event.clientX;
    const column = columns[index];
    const nextColumn = columns[index + 1];

    const totalWidth = column.width + nextColumn.width;

    const handleMouseMove = (e:MouseEvent) => {
      const currentPosition = e.clientX;
      const delta = currentPosition - initialPosition;

      const deltaToPercents = Number(((delta * 100)/paperWidth).toFixed(2));
      const newWidth = column.width + deltaToPercents;
      const nextWidth = nextColumn.width - deltaToPercents;

      const nextColumnWidth = nextWidth + MIN_COLUMN_WIDTH < totalWidth
        ? nextWidth <= MIN_COLUMN_WIDTH ? MIN_COLUMN_WIDTH : nextWidth
        : totalWidth - MIN_COLUMN_WIDTH
      ;
      const newColumnWidth = newWidth + MIN_COLUMN_WIDTH < totalWidth
        ? newWidth <= MIN_COLUMN_WIDTH ? MIN_COLUMN_WIDTH : newWidth
        : totalWidth - MIN_COLUMN_WIDTH
      ;

      const updatedColumns = columns.map((column, i) => {
        if ( i === index ) return {...column, width: newColumnWidth }
        if ( i === index + 1 ) return {...column, width: nextColumnWidth }
        return column;
      });
      setColumns(updatedColumns);
    };

    const handleMouseUp = () => {
      setResizing(false);

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleFocus = (id:string) => () => {
    onFocus(id);
  }

  const handleChange = (id:number, column:string) => (value:any) => {
    setColumns((prevState:any) => prevState.map((c) => {
      if ( c.id === id ) return {...c, [column]: value}
      return c;
    }));
  }

  const watchLayout = watch('layout');
  const watchDataType = watch('dataType');

  return (
    <Box ref={paperRef} sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      maxWidth: 1280,
      mx: 'auto',
      py: 4
    }}>
      <FormHelperText
        sx={{ mt: 0, mb: 2 }}
      >{t('dialogs.templateForm.tableConfigColumnsHint')}</FormHelperText>
      <Paper
        sx={{
          flexGrow: 1,
          width: '100%',
          userSelect: resizing ? 'none' : 'initial'
        }}
      >
        {columns.length ? (
          <table style={{ borderCollapse: 'collapse', width: '100%', height: '100%' }}>
            <thead>
              <tr>
                {columns.map((column, index) => {
                  const headerId = `tableConfig-columns-${column.id}-header`;
                  return (
                    <TableCell
                      key={`table header ${column.id}`}
                      column={column}
                      index={index}
                      onAdd={handleAddColumn}
                      onDelete={handleRemoveColumn}
                    >
                      <Portal targetId="column-editor-toolbars">
                        <Box
                          sx={{
                            display: activeEditorId === headerId ? 'block' : 'none',
                            borderBottom: '1px solid rgba(0,0,0,0.08)',
                            px: 6,
                            py: 2
                          }}
                        >
                          <Toolbar
                            columnId="header"
                            id={headerId as string}
                            dataType={watchDataType}
                            layout={watchLayout}
                            isLinkVariableVisible={true}
                            isVariableVisible={true}
                            isTabStopVisible={true}
                            isPageBreakVisible={true}
                          />
                        </Box>
                      </Portal>
                      <Editor
                        paperSx={{
                          overflow: 'visible',
                          '&.ql-container.ql-snow': {
                            border: 'none !important'
                          },
                          '& .ql-editor': {
                            wordBreak: 'break-word'
                          }
                        }}
                        label="Header"
                        value={column.header}
                        onChange={handleChange(column.id, 'header')}
                        id={headerId}
                        onFocus={handleFocus(headerId)}
                      />
                      {index < columns.length - 1 ? (
                        <Box
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            width: `1px`,
                            bgcolor: `rgba(0,0,0,0.23)`,
                            zIndex: 2,
                            '&::after': {
                              content: '""',
                              display: 'none',
                              width: '3px',
                              height: '100%',
                              position: 'absolute',
                              top: 0, right: '-1px',
                              bgcolor: (theme:Theme) => theme.palette.primary.main,
                              userSelect: 'none'
                            },
                            '&:hover': {
                              cursor: 'ew-resize',
                              '&::after': {
                                display: 'block'
                              }
                            }
                          }}
                          onMouseDown={handleMouseDown(index)}
                        />
                      ) : null}
                    </TableCell>
                  )
                })}
              </tr>
            </thead>
            <tbody style={{ height: 56 }}>
              <tr>
                {columns.map((column, index) => {
                  const dataId = `tableConfig-columns-${column.id}-data`;
                  return (
                    <TableCell
                      key={`table cell ${column.id}`}
                      column={column}
                      index={index}
                      onAdd={handleAddColumn}
                      onDelete={handleRemoveColumn}
                      bottom={true}
                    >
                      <Portal targetId="column-editor-toolbars">
                        <Box
                          sx={{
                            display: activeEditorId === dataId ? 'block' : 'none',
                            borderBottom: '1px solid rgba(0,0,0,0.08)',
                            px: 6,
                            py: 2
                          }}
                        >
                          <Toolbar
                            columnId="data"
                            id={dataId as string}
                            dataType={watchDataType}
                            layout={watchLayout}
                            isLinkVariableVisible={true}
                            isVariableVisible={true}
                            isTabStopVisible={true}
                            isPageBreakVisible={true}
                          />
                        </Box>
                      </Portal>
                      <Editor
                        paperSx={{
                          overflow: 'visible',
                          '&.ql-container.ql-snow': {
                            border: 'none !important'
                          },
                          '& .ql-editor': {
                            wordBreak: 'break-word'
                          }
                        }}
                        label="Data"
                        value={column.data}
                        onChange={handleChange(column.id, 'data')}
                        id={dataId}
                        onFocus={handleFocus(dataId)}
                      />
                      {index < columns.length - 1 ? (
                        <Box
                          sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            width: `1px`,
                            bgcolor: `rgba(0,0,0,0.23)`,
                            zIndex: 2,
                            '&::after': {
                              content: '""',
                              display: 'none',
                              width: '3px',
                              height: '100%',
                              position: 'absolute',
                              top: 0, right: '-1px',
                              bgcolor: (theme:Theme) => theme.palette.primary.main,
                              userSelect: 'none'
                            },
                            '&:hover': {
                              cursor: 'ew-resize',
                              '&::after': {
                                display: 'block'
                              }
                            }
                          }}
                          onMouseDown={handleMouseDown(index)}
                        />
                      ) : null}
                    </TableCell>
                  )
                })}
              </tr>
            </tbody>
          </table>
        ) : (
          <Tooltip
            sx={{
              position: 'absolute',
              top: '50%', left: '50%',
              mt: -4,
              ml: -4,
              zIndex: 1
            }}
            title={t('dialogs.templateForm.addColumnButton')}
          >
            <IconButton
              onClick={handleAddColumn()}
            ><AddCircleOutlineIcon /></IconButton>
          </Tooltip>
        )}
        {isSubmitted && !columns.length  ? (
          <FormHelperText
            sx={{
              position: 'absolute',
              bottom: 0, left: 0,
              mb: 2,
              ml: 2,
              zIndex: 1
            }}
            error
          >{t('dialogs.templateForm.columnsHint')}</FormHelperText>
        ) : null}
      </Paper>
    </Box>
  )
}

export default TableConfigColumns;

const TableCell = ({ index, column, children, onAdd, onDelete, bottom = false }:any) => {
  return (
    <Box
      component="td"
      sx={{
        padding: 0,
        border: `1px solid rgba(0,0,0,0.23)`,
        position: 'relative',
        width: `${column.width}%`,
        minHeight: 46,
        '&:hover .actions': {
          display: 'flex'
        }
      }}
    >
      <Paper
        className="actions"
        sx={{
          display: 'none',
          position: 'absolute',
          top: bottom ? 'iniital' : 0,
          bottom: bottom ? 0 : 'initial',
          left: '50%',
          transform: `translate(-50%, ${bottom ? '' : '-'}100%)`,
          p: 2,
          zIndex: 1
        }}
      >
        <Tooltip title="Add column before">
          <IconButton
            onClick={onAdd(index, true)}
            size="small"
          ><AddCircleOutlineIcon fontSize="small" /></IconButton>
        </Tooltip>
        <Tooltip title="Remove column">
          <IconButton
            onClick={onDelete(column.id)}
            color="error"
            size="small"
          ><DeleteIcon color="error" fontSize="small" /></IconButton>
        </Tooltip>
        <Tooltip title="Add column after">
          <IconButton
            onClick={onAdd(index, false)}
            size="small"
          ><AddCircleOutlineIcon fontSize="small" /></IconButton>
        </Tooltip>
      </Paper>
      {children}
    </Box>
  )
}