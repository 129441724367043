import i18n from 'i18n';
// Models
import IAuthCredential from 'app/models/AuthCredential';
import ILabel from 'app/models/Label';

const STORAGE_KEY = 'cases-portal';

enum StorageUserPreferences {
  CasesSorting = 'casesSorting',
  CollectionTab = 'collectionTab'
};

export default class LocalStorageService {
  private static readonly ACCESS_TOKEN:string = `${STORAGE_KEY}:access_token`;
  private static readonly ACCESS_TOKEN_TYPE:string = `${STORAGE_KEY}:access_token_type`;
  private static readonly EXPIRES_ON_TIME:string = `${STORAGE_KEY}:expires_on_time`;
  private static readonly REFRESH_TOKEN:string = `${STORAGE_KEY}:refresh_token`;

  public static getAccessToken():string {
    return localStorage.getItem(this.ACCESS_TOKEN) || '';
  };
  public static getRefreshToken():string {
    return localStorage.getItem(this.REFRESH_TOKEN) || '';
  };
  public static getExpiresOnTime():number {
    const expiresOn = localStorage.getItem(this.EXPIRES_ON_TIME) || '0';
    return parseInt(expiresOn, 10);
  };
  public static getAccessTokenType():string {
    return localStorage.getItem(this.ACCESS_TOKEN_TYPE) || '';
  };

  public static isAccessTokenExpired():boolean {
    if ( !this.getAccessToken() ) return true;
    const currentTime = new Date().getTime();
    const expiresOnTime = this.getExpiresOnTime();
    return currentTime > expiresOnTime;
  };

  public static setAuthCredential(authCredential:IAuthCredential):void {
    const { accessToken, refreshToken, expiresIn, tokenType } = authCredential;
    const currentDate = new Date();
    localStorage.setItem(this.ACCESS_TOKEN, accessToken);
    if ( refreshToken ) localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
    localStorage.setItem(this.EXPIRES_ON_TIME, currentDate.setSeconds(
      currentDate.getSeconds() + expiresIn
    ).toString());
    localStorage.setItem(this.ACCESS_TOKEN_TYPE, tokenType);
  };

  public static clearAuthCredential():void {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.ACCESS_TOKEN_TYPE);
    localStorage.removeItem(this.EXPIRES_ON_TIME);
    localStorage.removeItem(this.REFRESH_TOKEN);
  };

  // UI
  private static readonly USER_PREFERENCES:string = `${STORAGE_KEY}:user_preferences`;

  private static getUserPreferences = (key?:StorageUserPreferences) => {
    const userPreferences = localStorage.getItem(this.USER_PREFERENCES) || '{}';
    const parsedUserPreferences = JSON.parse(userPreferences);
    return key ? parsedUserPreferences[key] : parsedUserPreferences;
  }

  private static setUserPreferences = (key:StorageUserPreferences, data:any) => {
    const userPreferences = this.getUserPreferences();
    userPreferences[key] = data;
    localStorage.setItem(this.USER_PREFERENCES, JSON.stringify(userPreferences));
  }

  public static getCasesSorting = (accountId:number) => {
    const casesSorting = this.getUserPreferences(StorageUserPreferences.CasesSorting);
    return casesSorting && casesSorting[accountId] ? casesSorting[accountId] : {};
  }

  public static setCasesSorting = (accountId:number, data:any) => {
    const casesSorting = this.getUserPreferences(StorageUserPreferences.CasesSorting);
    this.setUserPreferences(StorageUserPreferences.CasesSorting, {
      ...casesSorting,
      [accountId]: data
    });
  }

  public static getCollectionTab = (accountId:number):number => {
    const collectionTab = this.getUserPreferences(StorageUserPreferences.CollectionTab);
    return collectionTab && collectionTab[accountId] ? collectionTab[accountId] : 0;
  }

  public static setCollectionTab = (accountId:number, tab:number) => {
    const collectionTab = this.getUserPreferences(StorageUserPreferences.CollectionTab);
    this.setUserPreferences(StorageUserPreferences.CollectionTab, {
      ...collectionTab,
      [accountId]: tab
    });
  }

  // UI
  private static readonly TRIAL_MODE:string = `${STORAGE_KEY}:trial_mode`;
  private static readonly WORKSPACE_PAGE_IDS:string = `${STORAGE_KEY}:workspace_page_ids`;
  private static readonly CALENDAR_DATES:string = `${STORAGE_KEY}:calendar_dates`;
  private static readonly GRID_RESIZER_WIDTH:string = `${STORAGE_KEY}:grid_resizer_width`;
  private static readonly LANGUAGE:string = `${STORAGE_KEY}:user_language`;
  private static readonly SCHEDULE_SEARCH_CARRIER_NUMBER:string = `${STORAGE_KEY}:schedule_search_carrier_number`;

  public static setTrialMode = (trialMode:boolean) => {
    localStorage.setItem(this.TRIAL_MODE, JSON.stringify(trialMode));
  }
  public static getTrialMode = () => {
    const trialMode = localStorage.getItem(this.TRIAL_MODE) || 'false';
    return trialMode === 'true' ? true : false;
  }

  public static setWorkspacePageIds = (data:Record<number, string>) => {
    localStorage.setItem(this.WORKSPACE_PAGE_IDS, JSON.stringify(data));
  }
  public static getWorkspacePageIds = () => {
    const workspacePages = localStorage.getItem(this.WORKSPACE_PAGE_IDS) || '{}';
    return JSON.parse(workspacePages);
  }

  public static setCalendarDates = (data:Record<number, string>) => {
    localStorage.setItem(this.CALENDAR_DATES, JSON.stringify(data));
  }
  public static getCalendarDates = () => {
    const calendarDates = localStorage.getItem(this.CALENDAR_DATES) || '{}';
    return JSON.parse(calendarDates);
  }

  // Grid resizer max width
  public static setGridResizerWidth(width:number):void {
    localStorage.setItem(this.GRID_RESIZER_WIDTH, width.toString());
  }
  public static getGridResizerWidth():number {
    const width = window.innerWidth < 1280
      ? '50'
      : window.innerWidth < 1440
        ? '55'
        : window.innerWidth < 1600
          ? '60'
          : window.innerWidth < 1920
            ? '65'
            : '70'
    ;
    const gridResizerWidth = localStorage.getItem(this.GRID_RESIZER_WIDTH) || width;
    return Number(gridResizerWidth);
  }

  // Language
  public static hasLanguage = ():boolean => {
    return Boolean(localStorage.getItem(this.LANGUAGE));
  }

  public static getLanguage = () => {
    return localStorage.getItem(this.LANGUAGE) || 'en';
  }
  public static setLanguage(language:string):void {
    i18n.changeLanguage(language);
    localStorage.setItem(this.LANGUAGE, language);
  }

  public static setScheduleSearchCarrierNumber = (carrierNumber:string) => {
    localStorage.setItem(this.SCHEDULE_SEARCH_CARRIER_NUMBER, carrierNumber);
  }
  public static getScheduleSearchCarrierNumber = ():string => {
    return localStorage.getItem(this.SCHEDULE_SEARCH_CARRIER_NUMBER) || '';
  }

  // Documents labels
  private static readonly DOCUMENTS_LABELS:string = `${STORAGE_KEY}:documents_labels`;

  public static setDocumentsLabels = (label: ILabel) => {
    const documentsLabels = this.getDocumentsLabels();
  
    const filteredLabels = documentsLabels.filter(l => l.name !== label.name);
  
    filteredLabels.unshift(label);
  
    const nextData = filteredLabels.slice(0, 10);
  
    localStorage.setItem(this.DOCUMENTS_LABELS, JSON.stringify(nextData));
  };

  public static getDocumentsLabels = (): ILabel[] => {
    const documentsLabels = localStorage.getItem(this.DOCUMENTS_LABELS) || "[]";
    return JSON.parse(documentsLabels);
  };

  // Search keywords
  private static readonly SEARCH_KEYWORDS:string = `${STORAGE_KEY}:search_keywords`;

  public static setSearchKeywords = (keyword: string) => {
    const keywords = this.getSearchKeywords();
  
    const filteredKeywords = keywords.filter(k => k !== keyword);
  
    filteredKeywords.unshift(keyword);
  
    const nextData = filteredKeywords.slice(0, 20);
  
    localStorage.setItem(this.SEARCH_KEYWORDS, JSON.stringify(nextData));
  };

  public static getSearchKeywords = (): string[] => {
    const keywords = localStorage.getItem(this.SEARCH_KEYWORDS) || "[]";
    return JSON.parse(keywords);
  };
}
