import { FC, Fragment, useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { ContentType, DroppableIds } from 'app/types/ContentItem';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredAllTemplateIds } from 'app/store/Templates/Templates.selectors';
import { selectCustomDocumentIds } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Theme, Box, Tabs, Tab, FormHelperText } from '@mui/material';
// Icons
import { Error as ErrorIcon } from '@mui/icons-material';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';
// 
import PresetSelector from './PresetSelector';
import TemplatesTabContent from './TemplatesTabContent';
import DocumentsTabContent from './DocumentsTabContent';
import SettingsTabContent from './SettingsTabContent';
import ContainerContent from './ContainerContent';

const DialogContainer:FC = () => {
  const { t } = useTranslation();
  // State
  const templateIds = useAppSelector(selectFilteredAllTemplateIds);
  const documentIds = useAppSelector(selectCustomDocumentIds);

  const { formState:{ isSubmitted, errors }, setValue } = useFormContext();

  const { content, onAddContentItem, onRemoveContentItemByIndex, onReorderContent, getAvailableIds } = useReportPresetContext();

  const [ tab, setTab ] = useState(0);

  const availabelContentIds = {
    [ContentType.Template]: getAvailableIds(ContentType.Template, templateIds),
    [ContentType.Document]: getAvailableIds(ContentType.Document, documentIds)
  };

  useEffect(() => {
    setValue('blocks', content);
    // eslint-disable-next-line
  }, [content]);

  const handleDragEnd = (result:DropResult) => {
    const { source, destination } = result;

    if ( !destination ) return; // Dropped outside a list

    const sourceId = source.droppableId as DroppableIds;
    const destinationId = destination.droppableId as DroppableIds;

    if ( sourceId === destinationId ){
      if ( sourceId === DroppableIds.Destination ){
        onReorderContent(source.index, destination.index);
      }
    } else {
      if ( sourceId === DroppableIds.Destination ){
        onRemoveContentItemByIndex(source.index);
      } else {
        const type = sourceId as unknown as ContentType;
        const availabelIds = availabelContentIds[type] as number[];
        const id = availabelIds[source.index];
        onAddContentItem(type, id, destination.index);
      }
    }
  };

  const handleChangeTab = (_:any, nextTab:number) => {
    setTab(nextTab);
  }

  const hasContentAnyTemplate = content.some((contentItem) => contentItem.includes(ContentType.Template));
  const hasContentAnyDocument = content.some((contentItem) => contentItem.includes(ContentType.Document));

  const hasContentFieldError = isSubmitted && !(hasContentAnyTemplate || hasContentAnyDocument);
  const hasSettingsTabError = (errors.watermark as any)?.text;

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      overflowY: 'auto'
    }}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Box
          sx={{
            flexShrink: 0,
            width: 400,
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'white',
            borderRight: '1px solid rgba(0,0,0,0.08)'
          }}
        >
          <PresetSelector />
          {hasContentFieldError ? (
            <FormHelperText sx={{ px: 4, pt: 2 }} error>{t('dialogs.reportPreset.templateOrDocumentShouldBeSelected')}</FormHelperText>
          ) : null}
          <Box sx={{
            borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
            px: 4
          }}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              centered
            >
              <Tab
                sx={{ flexDirection: 'row' }}
                label={
                  <Fragment>
                    {t('labels.templates')}
                    {hasContentFieldError ? <ErrorIcon sx={{ ml: 2 }} color="error" /> : null}
                  </Fragment>
                }
              />
              <Tab sx={{ flexDirection: 'row' }} label={
                <Fragment>
                  {t('labels.docs')}
                  {hasContentFieldError ? <ErrorIcon sx={{ ml: 2 }} color="error" /> : null}
                </Fragment>
              } />
              <Tab
                sx={{ flexDirection: 'row' }}
                label={
                  <Fragment>
                    {t('labels.settings')}
                    {hasSettingsTabError ? <ErrorIcon sx={{ ml: 2 }} color="error" /> : null}
                  </Fragment>
                }
              />
            </Tabs>
          </Box>
          <Box sx={{ display: tab === 0 ? 'flex' : 'none', overflow: 'hidden' }}>
            <TemplatesTabContent templateIds={availabelContentIds.template as number[]} />
          </Box>
          <Box sx={{ display: tab === 1 ? 'flex' : 'none', overflow: 'hidden' }}>
            <DocumentsTabContent documentIds={availabelContentIds.document as number[]} />
          </Box>
          <Box sx={{ display: tab === 2 ? 'block' : 'none' }}>
            <SettingsTabContent />
          </Box>
        </Box>
        <ContainerContent />
      </DragDropContext>
    </Box>
  )
}

export default DialogContainer;
