import { FC } from 'react';
// Components
import PageImage from '@root/components/PageImage';
import { IPage } from '@root/models/Page';
import { Box, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { generateLowQualityBorderSX } from 'app/utilities/Utilities';

type Props = {
  page: IPage;
  isLeft: boolean;
  onClose: () => void;
  isFilesPage: boolean;
};

const PageCardPreview:FC<Props> = ({
  // Props
  page, isLeft, onClose, isFilesPage
}) => {
  const lowQualityBorderSx = generateLowQualityBorderSX(page.annotations, 4);
  return (
    <Box className={`card-page-preview ${!isLeft ? 'left' : ''}`}>
      <IconButton
        sx={{
          position: 'absolute',
          top: 0, right: 0,
          m: 4,
          bgcolor: 'white',
          border: '1px solid rgba(0,0,0,0.08)',
          zIndex: 1
        }}
        onClick={onClose}
      ><CloseIcon /></IconButton>
      <Box sx={{ overflow: 'hidden', ...lowQualityBorderSx }}>
        <PageImage
          documentId={page.documentId}
          pageNum={page.pageNum}
          highlights={isFilesPage && page.highlights ? page.highlights : undefined}
        />
      </Box>
    </Box>
  )
}

export default PageCardPreview;
