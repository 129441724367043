import { FC } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks/useStore';
// Actions
import { removeFromCompare } from 'app/store/ui/uiSlice';
// Selectors
import { selectCompare } from 'app/store/ui/uiSelectors';
import { selectPageEntity, selectStaplePageIds } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box, IconButton, Tooltip } from '@mui/material';
// Components
import DocumentName from 'app/components/DocumentName';
import PageImage from 'app/components/PageImage';
import Icon from 'app/components/Icon';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  pageId: string;
}

const ComparePage:FC<Props> = ({
  // Props
  pageId
}) => {
  const { t } = useTranslation();

  const [ documentId, pageNum ] = pageId.split(':').map(Number);
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const compare:string[] = useAppSelector(selectCompare);
  const page = useAppSelector((state:RootState) => selectPageEntity(state, { documentId, pageNum }));
  const staplePageIds:string[] | null = useAppSelector((state:RootState) => selectStaplePageIds(state, {
    documentId,
    stapleId: page?.staple?.id || null
  }));

  const handleRemoveFromCompare = () => {
    dispatch(removeFromCompare(pageId));
  }

  const maxWidth = `${compare.length > 2 ? 45 : compare.length === 1 ? 100 : 50}%`;

  return (
    <Box sx={{
      flexShrink: 0,
      flexBasis: maxWidth,
      maxWidth,
      overflowY: 'auto'
    }}>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, p: 4 }}>
          <Tooltip title={t('buttons.removeFromCompare')}>
            <IconButton
              onClick={handleRemoveFromCompare}
              size="small"
              color="error"
            ><Icon icon="delete" /></IconButton>
          </Tooltip>
          <DocumentName documentId={documentId} />
        </Box>
        {!staplePageIds || !staplePageIds.length ? (
          <PageImage documentId={documentId} pageNum={pageNum} />
        ) : (
          staplePageIds.map((id:string) => {
            const pageIdToNum = id.split(':').map(Number);
            return (
              <Box
                key={`compare-item-staple-${id}`}
                sx={{
                  '& + &': {
                    borderTop: '1px solid rgba(0,0,0,0.08)'
                  }
                }}
              >
                <PageImage
                  documentId={pageIdToNum[0]}
                  pageNum={pageIdToNum[1]}
                />
              </Box>
            )
          })
        )}
      </Box>
    </Box>
  )
}

export default ComparePage;
