import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import AccountsFilter from './AccountsFilter';
import AccountsList from './AccountsList';
import AccountsFab from './AccountsFab';

const CompanyAccountsPage:FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.companyAccounts.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <AccountsFilter />
          <AccountsList />
        </Container>
      </Box>
      <AccountsFab />
    </Fragment>
  )
}

export default CompanyAccountsPage;
