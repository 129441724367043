import { FC } from 'react';
// Mui
import { Box, Typography } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const FilesUploadHints:FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography display="block" variant="caption" component="small">{t('dialogs.caseUploadDocuments.hint1')}</Typography>
      <Typography display="block" variant="caption" component="small">{t('dialogs.caseUploadDocuments.hint2')}</Typography>
      <Typography display="block" variant="caption" component="small">{t('dialogs.caseUploadDocuments.hint3')}</Typography>
    </Box>
  )
}

export default FilesUploadHints;
