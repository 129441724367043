import { useCallback } from "react";
// Store
import { useAppDispatch } from "app/hooks/useStore";
// Actions
import { AppUIActions } from "app/store/AppUI/AppUI.slice";

export const useLinkHandler = () => {
  // Dispatch
  const dispatch = useAppDispatch();

  const validateAndExtractHref = useCallback((href:string) => {
    const urlPattern = /\/documents\/(\d+)\/(\d+)/;
    const url = new URL(href);

    // Check if the path matches the /documents/:documentId/:pageNum pattern
    const match = url.pathname.match(urlPattern);
    if ( !match ) return null;

    const documentId = match[1];
    const pageNum = match[2];
    const bbParam = url.searchParams.get("bb");

    const result:any = { documentId: Number(documentId), pageNum: Number(pageNum) };
    if ( bbParam ) result.bb = bbParam;
    return result;
  }, []);

  const handleContainerClick = (event:any) => {
    const target = event.target as HTMLElement;
    let linkElement: HTMLAnchorElement | null = null;

    if ( target.tagName === 'A' ) {
      linkElement = target as HTMLAnchorElement;
    } else {
      let parent = target.parentElement;
      while ( parent && parent.tagName !== 'A' ) {
        parent = parent.parentElement;
      }
      if ( parent && parent.tagName === 'A' ) {
        linkElement = parent as HTMLAnchorElement;
      }
    }

    if ( linkElement ){
      event.preventDefault();
      const href = linkElement.href;
      const pageDataFromHref = validateAndExtractHref(href);
      if ( href && pageDataFromHref ) {
        dispatch(AppUIActions.setPreviewPageOpen(true));
        dispatch(AppUIActions.setPreviewPage(null));
        setTimeout(() => {
          dispatch(AppUIActions.setPreviewPage(pageDataFromHref));
        }, 0);
      }
    }
  }

  return handleContainerClick;
};