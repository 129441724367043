import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
// Redux
import { AppDispatch } from 'app/store';
// Async
import { checkAccessToken } from 'app/store/Auth/Auth.async';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
// Services
import LocalStorageService from 'app/services/LocalStorage.service';
// Constants
import { PUBLIC_URLS } from 'app/App.constants';

const RequestedUrlMiddleware:Middleware = ({ dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type } = action;
  if (
    type === checkAccessToken.pending.type &&
    (LocalStorageService.isAccessTokenExpired() && !LocalStorageService.getRefreshToken())
  ){

    const { pathname } = window.location;

    if ( !PUBLIC_URLS.includes(pathname) ){
      dispatch(AppUIActions.setRequestedUrl(pathname));
    }

  }

  return next(action);
}

export default RequestedUrlMiddleware;