import i18n from "i18n";

const Messages = {
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.invoice') }),
  Pay: i18n.t('notifications.billingInvoices.pay')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.invoice') });
  Messages.Pay = i18n.t('notifications.billingInvoices.pay');
});