import { FC, Fragment } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Box, Divider } from '@mui/material';
// Dialogs
import ConversationFormDialog from 'app/dialogs/ConversationFormDialog';
// Components
import { Button } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

const ConversationsCreate:FC = () => {
  const { t } = useTranslation();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);

  const { open, toggle } = useToggle();

  if ( myUser?.role !== UserRoles.Admin ) return null;
  return (
    <Fragment>
      <Box sx={{ p: 4 }}>
        <Button
          name="Create conversation"
          onClick={toggle}
          color="primary"
          variant="contained"
          fullWidth
        >{t('pages.messages.createButton')}</Button>
      </Box>
      <Divider />
      {open ? (
        <ConversationFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default ConversationsCreate;
