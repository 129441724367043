import { FC, Fragment } from 'react';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getStats, getMonthlyStats } from 'app/store/Analytics/Analytics.async';
// Mui
import { Box, Divider, Toolbar, Typography, Container, Paper } from '@mui/material';
// Components
import { AnalyticsStats, AnalyticsMonthlyStats } from 'app/components/Analytics';
// i18next
import { useTranslation } from 'react-i18next';

const AnalyticsPage:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();

  const handleGetAccountStats = () => {
    dispatch(getStats({}));
  }

  const handleGetAccountMonhtlyStats = (params:any) => {
    dispatch(getMonthlyStats(params));
  }

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.analytics.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="md">
          <Paper sx={{ p: 4 }} variant="outlined">
            <AnalyticsStats
              title={t('pages.analytics.allAccountsTotals')}
              onGetStats={handleGetAccountStats}
            />
            <Divider sx={{ my: 4 }} />
            <AnalyticsMonthlyStats onGetMonthlyStats={handleGetAccountMonhtlyStats} />
          </Paper>
        </Container>
      </Box>
    </Fragment>
  )
}

export default AnalyticsPage;
