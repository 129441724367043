import { FC, memo, useMemo, useCallback, useEffect, useState, useRef, ChangeEvent, Fragment } from 'react';
import dayjs from 'dayjs';
// Canvas
import CanvasJSReact from 'app/libraries/canvasjs.react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { setFilter } from 'app/store/currentUser/currentUserSlice';
// Selectors
import { selectFilter, selectWorkspaceEpisodeLabels, selectWorkspaceEpisodeAuthors, selectFullChartData } from 'app/store/currentUser/currentUserSelectors';
// Mui
import { IconButton, Tooltip, Box, Button, Typography, TextField, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Autocomplete } from '@mui/material';
// Icons
import { Download as DownloadIcon } from '@mui/icons-material';
// 
import ChartPopover from './ChartPopover';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import { useParams } from 'react-router-dom';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

import useMenu from 'app/hooks/useMenu';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type Props = {
  onDownloadJPEG: (chartImageURL:string) => void;
}

const FullChart:FC<Props> = ({
  // Props
  onDownloadJPEG
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode);
  const filter = useSelector(selectFilter);
  const episodeLabels = useSelector(selectWorkspaceEpisodeLabels);
  const episodeAuthors = useSelector(selectWorkspaceEpisodeAuthors);
  const { maxEventsPerDate, fullChartData, initialViewportMinimum, initialViewportMaximum } = useSelector(selectFullChartData);

  const canvasRef = useRef<any>(null);

  const [ selectedBar, setSelectedBar ] = useState(null);

  const { Menu, MenuItem, openMenu } = useMenu();

  const handleOpenBar = (event:any) => {
    setSelectedBar(event);
  }
  const handleCloseBar = () => setSelectedBar(null);

  const data = useMemo(() => {
    return fullChartData.map((data:any) => ({
      ...data,
      click: (event:any) => handleOpenBar(event)
    }))
  }, [fullChartData]);

  const toggleShowAll = useCallback(() => {
    dispatch(setFilter({ field: 'showAll', value: !filter.showAll }));
    setOptions((prevState:any) => ({
      ...prevState,
      data: prevState.data.map((data:any) => ({...data, visible: !filter.showAll}))
    }));
    // eslint-disable-next-line
  }, [filter.showAll]);

  const handleZoomOut = useCallback(() => {
    setOptions((prevState:any) => {
      let { viewportMinStack, viewportMaxStack } = prevState;
      let nextViewportMinStack = [];
      let nextViewportMaxStack = [];
      let nextViewportMinimim = initialViewportMinimum;
      let nextViewportMaximum = initialViewportMaximum;
      let zoomOutVisible = false;
      let pointWidth = dataPointWidth;
      if ( viewportMinStack.length > 1 ){
        viewportMinStack.pop();
        viewportMaxStack.pop();
        nextViewportMinStack = viewportMinStack;
        nextViewportMaxStack = viewportMaxStack;
        nextViewportMinimim = nextViewportMinStack[nextViewportMinStack.length - 1];
        nextViewportMaximum = nextViewportMaxStack[nextViewportMaxStack.length - 1];
        pointWidth = setDataPointWidth(nextViewportMinimim, nextViewportMaximum);
        zoomOutVisible = true;
      }
      return {
        ...prevState,
        zoomOutVisible,
        dataPointWidth: pointWidth,
        viewportMinStack: nextViewportMinStack,
        viewportMaxStack: nextViewportMaxStack,
        axisX: {
          ...prevState.axisX,
          viewportMinimum: nextViewportMinimim,
          viewportMaximum: nextViewportMaximum
        }
      }
    })
    // eslint-disable-next-line
  }, []);

  const handleClick = useCallback((event:any) => {
    const key = event.dataSeries.key;
    setOptions((prevState:any) => ({
      ...prevState,
      data: prevState.data.map((data:any) => data.key === key ? {...data, visible: !data.visible} : data)
    }));
    // eslint-disable-next-line
  }, []);

  const handleRangeChanging = useCallback((event:any) => {
    if ( event.trigger === 'zoom' ){
      const axisX = event.axisX[0];
      const { viewportMinimum, viewportMaximum } = axisX;
      setOptions((prevState:any) => ({
        ...prevState,
        zoomOutVisible: true,
        // Resize bar width if daterange is less than 12 months
        dataPointWidth: setDataPointWidth(viewportMinimum, viewportMaximum),

        // Zoom Out points
        viewportMinStack: [...prevState.viewportMinStack, viewportMinimum],
        viewportMaxStack: [...prevState.viewportMaxStack, viewportMaximum],
        axisX: {
          ...prevState.axisX,
          viewportMinimum,
          viewportMaximum
        }
      }));
    }
    if ( event.trigger === 'reset' ){
      setOptions((prevState:any) => ({
        ...prevState,
        zoomOutVisible: false,
        dataPointWidth,
        axisX: {
          ...prevState.axisX,
          viewportMinimum: initialViewportMinimum,
          viewportMaximum: initialViewportMaximum
        }
      }))
    }
    // eslint-disable-next-line
  }, []);

  const [ options, setOptions ] = useState({
    ...initialOptions,
    rangeChanging: handleRangeChanging,
    legend: {
      ...initialOptions.legend,
      itemclick: handleClick
    },
    axisX: {
      ...initialOptions.axisX,
      viewportMinimum: initialViewportMinimum,
      viewportMaximum: initialViewportMaximum
    },
    data
  });

  useEffect(() => {
    const interval = setCustomInterval(maxEventsPerDate);
    setOptions((prevState:any) => ({
      ...prevState,
      axisY: {
        ...prevState.axisY,
        interval: interval > 15
          ? Math.round(interval/((interval/10)/2))
          : 1
      },
      data
    }));
    // eslint-disable-next-line
  }, [filter.labels, filter.authors, filter.inWorkspace]);

  const handleEpisodeLabelChange = (_:any, value:string[]) => {
    dispatch(setFilter({ field: 'labels', value }));

    Mixpanel.track(MixpanelTracks.CaseTimelineFilter, { caseId: Number(caseId), filterName: 'labels' });
  }

  const handleEpisodeAuthorChange = (_:any, value:string[]) => {
    dispatch(setFilter({ field: 'authors', value }));

    Mixpanel.track(MixpanelTracks.CaseTimelineFilter, { caseId: Number(caseId), filterName: 'authors' });
  }

  const handleInWorkspace = (event:ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilter({ field: 'inWorkspace', value: event.target.checked }));

    Mixpanel.track(MixpanelTracks.CaseTimelineFilter, { caseId: Number(caseId), filterName: 'inWorkspace' });
  }

  const handleDownloadJPEG = () => {
    if ( canvasRef.current ){
      const imageURL = canvasRef.current.chart.canvas.toDataURL('image/jpeg', 1.0);
      onDownloadJPEG(imageURL);
    }
  }

  const barOpen = Boolean(selectedBar);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }} style={{ gap: '8px' }}>
          {filter.showAll ? (
            <Tooltip title={t('pages.calendar.tooltip.hideAllBars')}>
              <Button
                onClick={toggleShowAll}
                color="primary"
                variant="outlined"
                size="small"
              >{t('pages.calendar.hideAllButton')}</Button>
            </Tooltip>
          ) : (
            <Tooltip title={t('pages.calendar.tooltip.showAllBars')}>
              <Button
                onClick={toggleShowAll}
                color="primary"
                variant="outlined"
                size="small"
              >{t('pages.calendar.showAllButton')}</Button>
            </Tooltip>
          )}
          {options.zoomOutVisible ? (
            <Button
              onClick={handleZoomOut}
              color="primary"
              variant="outlined"
              size="small"
            >{t('pages.calendar.zoomOutButton')}</Button>
          ) : null}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }} style={{ gap: '8px' }}>
          {!trialMode ? (
            <Autocomplete
              style={{ minWidth: 240 }}
              value={filter.labels}
              options={episodeLabels}
              onChange={handleEpisodeLabelChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('pages.calendar.episodeLabel')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              multiple
              size="small"
              limitTags={3}
              filterSelectedOptions
            />
          ) : null}
          <Autocomplete
            style={{ minWidth: 240 }}
            value={filter.authors}
            options={episodeAuthors}
            onChange={handleEpisodeAuthorChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('pages.calendar.episodeAuthor')}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            multiple
            size="small"
            limitTags={3}
            filterSelectedOptions
            getOptionLabel={(option:any) => option.label}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.verified ? (
                  <CheckCircleIcon sx={{ mr: 2 }} color="success" />
                ) : null}
                {option.label}
              </li>
            )}
            isOptionEqualToValue={(option, value) => {
              if ( value.value ) return option.value === value.value;
              return option.value === value;
            }}
          />
          {!trialMode ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={filter.inWorkspace}
                    onChange={handleInWorkspace}
                    color="primary"
                  />
                }
                label={t('labels.inWorkspaceOnly')}
              />
            </FormGroup>
          ) : null}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {!trialMode ? (
            <Box>
              <Tooltip title={t('buttons.download')}>
                <IconButton
                  onClick={openMenu}
                ><DownloadIcon /></IconButton>
              </Tooltip>
              <Menu>
                <MenuItem name="Download as JPEG" onClick={handleDownloadJPEG}>{t('pages.calendar.chartAsJpeg')}</MenuItem>
              </Menu>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box sx={{ paddingBottom: '16px' }}>
        <Typography variant="caption">{t('pages.calendar.selectAreaOnChart')}</Typography>
      </Box>
      <CanvasJSChart ref={canvasRef} options={options} />
      {barOpen ? (
        <ChartPopover
          selectedBar={selectedBar}
          onClose={handleCloseBar}
          showDailyButton={true}
        />
      ) : null}
    </Box>
  )
}

export default memo(FullChart);

const dataPointWidth = 5;
const initialOptions = {
  zoomOutVisible: false,
  viewportMinStack: [],
  viewportMaxStack: [],
  pointWidths: [],

  dataPointWidth,
  zoomEnabled: true,
  toolTip: {
    enabled: false,
  },
  legend: {
    verticalAlign: 'bottom',
    horizontalAlign: 'center',
    cursor: 'pointer'
  },
  axisX: {
    intervalType: 'month',
    valueFormatString: "MMM, DD YYYY"
  },
  axisY: {
    tickLength: 1,
    includeZero: true,
    tickColor: '#d3d3d3',
    gridColor: '#d3d3d3'
  }
};

const setDataPointWidth = (min:Date | string, max:Date | string) => {
  let diff = dayjs(max).diff(dayjs(min), 'month');
  return diff < 12 ? (12 - diff + 1) * 2 : dataPointWidth;
}

const setCustomInterval = (baseValue:number) => {
  const log10 =  parseInt(Math.log(baseValue) / Math.log(10) as any);
  const basePower =  Math.pow(10, log10); 
  return ((((baseValue/basePower) as any).toFixed(0)) * basePower); 
}
