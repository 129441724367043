import { FC, Fragment, useMemo } from 'react';
import { useParams, useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import UserRoles from 'app/types/UserRoles';
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Models
import { RootState } from 'app/store';
import { IDocument, IDocumentLink } from 'app/store/DMSDocuments/DMSDocuments.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createAuditRecord } from 'app/store/Audit/Audit.async';
import { downloadDocument, processDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectLoading, selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import {
  Theme, Box, ListItem, ListItemText, ListItemSecondaryAction,
  Typography, Tooltip
} from '@mui/material';
// Icons
import {
  MoreVert as MoreVertIcon,
  Restore as RestoreIcon,
  Sync as SyncIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon
} from '@mui/icons-material';
// Dialogs
import DocumentFormDialog from 'app/dialogs/DocumentFormDialog';
import ConsentToExportDialog from 'app/dialogs/ConsentToExportDialog';
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import Labels from 'app/components/Labels';
import { IconButton } from 'app/components/Mui';
// Hooks
import useIsDocumentProcessAllowed from 'app/hooks/useIsDocumentProcessAllowed';
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// Utilities
import { textToCapitalizeCase } from 'app/utilities/Utilities';
// 
import DocumentMoveDialog from './DocumentMoveDialog';
import DocumentLabelsDialog from './DocumentLabelsDialog';
import DocumentDeleteDialog from './DocumentDeleteDialog';

type Props = {
  documentId: number;
};

const DocumentItem:FC<Props> = ({
  // Props
  documentId
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  const { pathname } = useLocation<{ pathname:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser);
  const documentEntity = useAppSelector((state:RootState) => selectDocumentEntity(state, { documentId })) as IDocument;
  const loading:boolean = useAppSelector(selectLoading);

  const { showProcessMenuItem } = useIsDocumentProcessAllowed(documentEntity);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();
  const { open:openMoveDialog, toggle:toggleMoveDialog } = useToggle();
  const { open:openDeleteDialog, toggle:toggleDeleteDialog } = useToggle();
  const { open:openLabelsDialog, toggle:toggleLabelsDialog } = useToggle();
  const { open:openConsentToExportDialog, toggle:toggleConsentToExportDialog }  = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const links = useMemo(() => {
    if ( !documentEntity.links ) return {};
    return documentEntity.links.reduce((acc:Record<string, IDocumentLink>, cur:IDocumentLink) => {
      acc[cur.rel] = cur;
      return acc;
    }, {});
  }, [documentEntity.links]);

  const handleConfirm = async () => {
    try {
      await dispatch(processDocument(documentEntity.id)).unwrap();
      toggleConfirmationDialog();
    } catch(err){}
  }

  const handleConsetToExportConfirm = async () => {
    if ( openConsentToExportDialog ) toggleConsentToExportDialog();
    if ( !links.download ) return;

    try {
      await dispatch(downloadDocument({
        url: links.download.href,
        documentName: documentEntity.name
      })).unwrap();
      await dispatch(createAuditRecord({
        operation: AuditOperations.DocumentExport,
        data: {
          caseId: +caseId,
          userName: myUser?.name || '',
          fileName: documentEntity.name
        }
      }));
    } catch(error){
      console.log(error);
    }
  }

  const status = !documentEntity.deleted ? documentEntity.processing.status : 'deleted';
  const isActiveLink = pathname.includes(`/documents/${documentEntity.id}`);
  const isActiveDocument = !documentEntity.deleted && documentEntity.processing.status === 'ready';

  const listItemProps = isActiveDocument ? {
    component: NavLink,
    to: `/admin/cases/${caseId}/documents/${documentEntity.id}`,
    sx: {
      border: (theme:Theme) => isActiveLink ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent',
      color: 'initial',
      padding: '4px 46px 4px 14px',
      '&:hover': {
        bgcolor: isActiveLink ? 'initial' : 'rgba(0,0,0,0.025)',
        borderColor: (theme:Theme) => isActiveLink ? theme.palette.primary.main : 'rgba(0,0,0,0.025)'
      }
    },
    onClick: () => {
      const lastDocument = localStorage.getItem('shimlaw_ep:last_document') || `{}`;
      const parsed = JSON.parse(lastDocument);
      localStorage.setItem('shimlaw_ep:last_document', JSON.stringify({
        ...parsed,
        [caseId]: documentId
      }));
    }
  } : {
    sx: {
      opacity: ['pending', 'processing'].includes(status) ? 0.5 : 1,
      bgcolor: status === 'failed' ? 'rgba(255,0,0,0.15)' : 'initial',
    }
  };

  const isMyRoleAdmin = myUser?.role === UserRoles.Admin;
  const isDownloadMenuItemShown = documentEntity.fileFormat !== 'custom';

  return (
    <Fragment>
      <ListItem
        {...listItemProps}
        data-scroll-id={`document-${documentEntity.id}`}
        data-document-status={status}
      >
        <ListItemText
          primary={
            <Fragment>
              <Typography
                display="block"
                variant="subtitle2"
                component="span"
                noWrap
                title={documentEntity.name}
              >{documentEntity.name}</Typography>
              <Typography
                color="CaptionText"
                variant="body2"
              >{documentEntity.numberOfPages ? <span>{documentEntity.numberOfPages} {t('labels.pages')}</span> : null}</Typography>
              {documentEntity.labels && documentEntity.labels.length ? (
                <Box sx={{ mt: 1 }}>
                  <Labels id={documentEntity.id} labels={documentEntity.labels} />
                </Box>
              ) : null}
              {documentEntity.deleted ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
                  <Typography variant="body2" color="red"><b>{t('labels.deletedBy')}:</b>&nbsp;{documentEntity.deletedBy?.name}</Typography>
                  <Typography variant="body2"><b>{t('labels.message')}:</b>&nbsp;{documentEntity.message}</Typography>
                </Box>
              ) : null}
            </Fragment>
          }
        />
        {['pending', 'processing', 'failed'].includes(status) && (
          <Tooltip title={textToCapitalizeCase(status)}>
            {status === 'processing' ? <SyncIcon /> : status === 'pending' ? <RestoreIcon /> : <ErrorOutlineOutlinedIcon color="error" />}
          </Tooltip>
        )}
        {isActiveDocument || status === 'failed' ? (
          <ListItemSecondaryAction>
            <IconButton
              name={`Document dropdown ${documentEntity.id}`}
              aria-label="Document item dropdown button"
              onClick={openMenu}
              edge="end"
            ><MoreVertIcon /></IconButton>
            <Menu>
              <MenuItem
                name={`Document labels ${documentEntity.id}`}
                onClick={toggleLabelsDialog}
              >{t('labels.labels')}</MenuItem>
              {documentEntity.fileFormat === 'custom' ? (
                <MenuItem
                  name={`Document edit ${documentEntity.id}`}
                  onClick={toggle}
                >{t('buttons.edit')}</MenuItem>
              ) : null}
              {isDownloadMenuItemShown ? (
                <MenuItem
                  name={`Download ${documentEntity.id}`}
                  onClick={toggleConsentToExportDialog}
                >{t('buttons.download')}</MenuItem>
              ) : null}
              <MenuItem
                name={`Move document ${documentEntity.id}`}
                onClick={toggleMoveDialog}
              >{t('buttons.move')}</MenuItem>
              {showProcessMenuItem ? (
                <MenuItem
                  name={`Process document ${documentEntity.id}`}
                  onClick={toggleConfirmationDialog}
                >{t('buttons.process')}</MenuItem>
              ) : null}
              {isMyRoleAdmin && documentEntity.processing?.status === 'ready'  ? (
                <MenuItem
                  name={`Delete document ${documentEntity.id}`}
                  sx={{
                    color: (theme:Theme) => theme.palette.error.main
                  }}
                  onClick={toggleDeleteDialog}
                >{t('buttons.delete')}</MenuItem>
              ) : null}
            </Menu>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
      {open ? (
        <DocumentFormDialog
          open={open}
          onClose={toggle}
          documentId={documentEntity.id}
        />
      ) : null}
      {openMoveDialog ? (
        <DocumentMoveDialog
          open={openMoveDialog}
          onClose={toggleMoveDialog}
          documentEntity={documentEntity}
        />
      ) : null}
      {openLabelsDialog ? (
        <DocumentLabelsDialog
          open={openLabelsDialog}
          onClose={toggleLabelsDialog}
          documentEntity={documentEntity}
        />
      ) : null}
      {openDeleteDialog ? (
        <DocumentDeleteDialog
          open={openDeleteDialog}
          onClose={toggleDeleteDialog}
          documentId={documentId}
        />
      ) : null}
      {openConsentToExportDialog ? (
        <ConsentToExportDialog
          open={openConsentToExportDialog}
          onClose={toggleConsentToExportDialog}
          onConfirm={handleConsetToExportConfirm}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
          title={t('components.filesSidebar.processTitle')}
          content={t('components.filesSidebar.processConfirmation', { documentName: documentEntity.name })}
          loading={loading}
        />
      ) : null}
    </Fragment>
  )
}

export default DocumentItem;
