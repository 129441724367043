import { FC, Fragment, RefObject, useEffect } from 'react';
// Models
import { IManagerUser } from 'app/models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectGettingMore, selectHasMore, selectParams, selectUsers } from 'app/store/UsersManagement/UsersManagement.selectors';
// Actions
import { UsersManagementActions } from 'app/store/UsersManagement/UsersManagement.slice';
// Components
import { Loader, Message } from 'app/components/Utilities';
// Mui
import { Box } from '@mui/material';
// Hooks
import useIntersectionObserver from 'app/hooks/useIntersectionObserver';
// 
import UserItem from './UserItem';
// i18next
import { useTranslation } from 'react-i18next';

const UsersList:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const users:IManagerUser[] | null = useAppSelector(selectUsers);
  const params = useAppSelector(selectParams);
  const hasMore = useAppSelector(selectHasMore);
  const gettingMore = useAppSelector(selectGettingMore)

  const [ targetRef, isIntersecting ] = useIntersectionObserver({});

  useEffect(() => {
    if ( !isIntersecting ) return;
    if ( !hasMore ) return;

    const offset = params.offset + params.limit;

    dispatch(UsersManagementActions.setParams({ offset }));
    // eslint-disable-next-line
  }, [isIntersecting]);

  if ( !users ) return <Loader />;
  if ( !users.length ) return <Message text={t('pages.amUsers.noData')} />;
  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {users.map((user:IManagerUser, index:number) => (
          <Fragment key={`user item ${user.id}`}>
            <UserItem user={user} />
            {index === users.length - 1 ? (
              <span ref={targetRef as RefObject<HTMLSpanElement>} />
            ) : null}
          </Fragment>
        ))}
      </Box>
      {gettingMore ? <Loader /> : null}
    </Fragment>
  )
}

export default UsersList;
