import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { DesktopDatePicker, DesktopTimePicker } from 'app/components/Mui';
// Utilities
import { validateEpisodeDate, validateEpisodeTime } from 'app/utilities/Validations';
import { setPreEvent } from 'app/utilities/DateTime';

type Props = {
  isDisabled: boolean;
}

const EpisodeFormDateTime:FC<Props> = ({ isDisabled }) => {
  const { t } = useTranslation();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;

  const { control, formState: { errors }, setValue, getValues, watch } = useFormContext();

  const handleChangeDate = (onChange:(date:Dayjs | null) => void) => (nextDate:Dayjs | null) => {
    onChange(nextDate);
    handlePreEvent()
  }

  const handleChangeTime = (onChange:(date:Dayjs | null) => void) => (nextDate:Dayjs | null) => {
    onChange(nextDate);
    handlePreEvent()
  }

  const handlePreEvent = () => {
    const date = getValues('date');
    const time = getValues('time');
    setValue('preEvent', setPreEvent({
      eventDate: insuranceCase.eventDate,
      eventTime: insuranceCase.eventTime,
      currentDate: date,
      currentTime: time
    }));
  }

  const watchDate = watch('date');

  useEffect(() => {
    if ( !watchDate ) setValue('time', null);
    // eslint-disable-next-line
  }, [watchDate]);

  return (
    <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
      <Controller
        control={control} name="date"
        rules={{
          validate: validateEpisodeDate
        }}
        render={({ field }) => (
          <DesktopDatePicker
            {...field}
            label={t('labels.date')} name={field.name}
            onChange={handleChangeDate(field.onChange)}
            disabled={isDisabled}
            inputProps={{
              error: Boolean(errors.date),
              helperText: (errors.date?.message as string) || '',
              margin: 'none',
              size: 'small'
            }}
          />
        )}
      />
      {/* if added check on show/hide component on date exist it will not reset time */}
      {/* as controller, with time, in this way wasn`t exist */}
      {/* So as workarround, is to show/hide component with styles */}
      <Box sx={{ display: !watchDate ? 'none' : 'block', width: '100%' }}>
        <Controller
          control={control} name="time"
          rules={{
            validate: validateEpisodeTime
          }}
          render={({ field }) => (
            <DesktopTimePicker
              {...field}
              label={t('labels.time')} name={field.name}
              onChange={handleChangeTime(field.onChange)}
              disabled={isDisabled}
              inputProps={{
                error: Boolean(errors.time),
                helperText: (errors.time?.message as string) || '',
                margin: 'none',
                size: 'small'
              }}
            />
          )}
        />
      </Box>
    </Box>
  )
}

export default EpisodeFormDateTime;
