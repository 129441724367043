import { FC, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// Types
import GrantTypes from 'app/types/GrantTypes';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { login } from 'app/store/Auth/Auth.async';
// Actions
import { AuthActions } from 'app/store/Auth/Auth.slice';
// Selectors
import { selectMultiAccountsData, selectLoading } from 'app/store/Auth/Auth.selectors';
// Mui
import {
  Box,
  Container, Paper, Typography
} from '@mui/material';
// Components
import Brand from 'app/components/Brand';
import LanguagePicker from 'app/components/LanguagePicker';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// Dialogs
import MultiAccountsDialog from 'app/dialogs/MultiAccountsDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { isRequired, isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  grantType: GrantTypes;
  username: string;
  password: string;
}

const LoginPage:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const multiAccountsData = useAppSelector(selectMultiAccountsData);
  const loading = useAppSelector(selectLoading)

  const { open, toggle } = useToggle();

  const { register, control, handleSubmit, formState:{ errors }, getValues } = useForm<IFormData>({
    defaultValues: {
      grantType: GrantTypes.Password,
      username: '',
      password: ''
    }
  });

  useEffect(() => {
    if ( multiAccountsData.accounts && multiAccountsData.accounts.length ) toggle();
    // eslint-disable-next-line
  }, [multiAccountsData]);

  const onSubmit = handleSubmit((data:IFormData) => {
    dispatch(login(data));
  });

  const handleClose = (accountId?:number) => {
    dispatch(AuthActions.setInitialField('multiAccounts'));
    toggle();
    if ( !accountId ) return;
    dispatch(login({ ...getValues(), accountId }));
  }

  return (
    <Fragment>
      <Container sx={{ pt: 8 }} maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <Brand />
        </Box>
        <Paper
          sx={{ p: 8 }}
          variant="outlined"
          component="form"
          onSubmit={onSubmit}
          noValidate
        >
          {/* Hidden field */}
          <input {...register('grantType') as any} type="hidden" value={GrantTypes.Password} />
          {/* Username */}
          <Controller
            control={control} name="username"
            rules={{ required: isRequired, pattern: isEmailValid }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('labels.email')} id="username" type="email" autoComplete="email"
                required
                error={Boolean(errors.username)}
                helperText={errors.username?.message || ''}
              />
            )}
          />
          {/* Password */}
          <Controller
            control={control} name="password"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('labels.password')} id="password" type="password" autoComplete="current-password"
                required
                error={Boolean(errors.password)}
                helperText={errors.password?.message || ''}
              />
            )}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
            <LoadingButton
              name="Log in form"
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >{t('buttons.login')}</LoadingButton>
            <Button
              name="Forgot password"
              component={Link}
              to="/forgot-password"
              fullWidth
            >{t('buttons.forgotPassword')}</Button>
          </Box>
        </Paper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography display="block" align="center" variant="subtitle2" color="textSecondary" sx={{ py: 4 }}>
            <a
              style={{ color: 'rgba(0, 0, 0, 0.54)', textDecoration: 'none' }}
              href="https://www.casechronology.com/patent-information"
              target="_blank"
              rel="noreferrer"
            >{t('buttons.patentedSoftware')}</a>
          </Typography>
          <LanguagePicker />
        </Box>
      </Container>
      {open ? (
        <MultiAccountsDialog
          open={open}
          onClose={handleClose}
        />
      ) : null}
    </Fragment>
  )
}

export default LoginPage;
