import { createAsyncThunk } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';
import GrantTypes from 'app/types/GrantTypes';
// Async
import { getCurrentUser } from 'app/store/Users/Users.async';
// Actions
import { AuthActions } from 'app/store/Auth/Auth.slice';
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $post } from 'app/utilities/HttpClient';
// Service
import LocalStorageService from 'app/services/LocalStorage.service';
import CookieService from 'app/services/Cookie.service';

const _url:string = `/auth`;

export const checkAccessToken = createAsyncThunk(
  `${Reducers.Auth}/Check access token`,
  async (_, { dispatch, rejectWithValue }) => {
    const cookieToken = CookieService.getToken();

    if ( cookieToken ){
      LocalStorageService.clearAuthCredential();

      dispatch(login({
        grantType: GrantTypes.TokenExchange,
        subjectToken: cookieToken
      }))
    } else {
      if ( LocalStorageService.isAccessTokenExpired() && !LocalStorageService.getRefreshToken() ){
        LocalStorageService.clearAuthCredential();

        dispatch(AuthActions.setAuthenticated(false));

        return rejectWithValue(undefined);
      }

      dispatch(getCurrentUser({}));
    }
  }
)

export const login = asyncThunkHandler(
  `${Reducers.Auth}/Login`,
  async (data:any) => {
    const response:Response = await $post(`${_url}/token`, data);
    return response.json();
  }
);

// We adding new async thunk `switchAccount` to switch between accounts
// to extract login from `login` async thunk to do custom manipulation on `rejected` type
export const switchAccount = asyncThunkHandler(
  `${Reducers.Auth}/Switch account`,
  async (accountId:number) => {
    const accessToken = LocalStorageService.getAccessToken();
    const data = {
      grantType: GrantTypes.TokenExchange,
      subjectToken: accessToken,
      accountId
    };
    const response:Response = await $post(`${_url}/token`, data);
    return response.json();
  }
);

export const logout = createAsyncThunk(
  `${Reducers.Auth}/Logout`,
  async (_, { fulfillWithValue, dispatch }) => {
    dispatch(AuthActions.setAuthenticated(false));
    return fulfillWithValue(true);
  }
);
