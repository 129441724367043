import { FC, Fragment, useMemo, useEffect } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
// Models
import IUser, { IMyUser } from 'app/models/User';
import IOption from 'app/models/Option';
// Types
import UserRoles from 'app/types/UserRoles';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getUsers } from 'app/store/Users/Users.async';
// Actions
import { UsersActions } from 'app/store/Users/Users.slice';
// Selectos
import { selectTeams } from 'app/store/Teams/Teams.selectors';
import { selectMyUser, selectUsersAsOptions } from 'app/store/Users/Users.selectors';
// Mui
import { Autocomplete, Box, FormHelperText, TextField } from '@mui/material';
// Components
import { OutlineBlock } from './Utilities';
// Utilities
import { sortByName } from 'app/utilities/SortBy';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  teamId: string | number;
  notificationPreferences: {
    recipientIds: IUser[];
  }
}

type Props = {
  outlineBlockVisible?: boolean;
  disabled?: boolean;
}

const NotificationPreferences:FC<Props> = ({ outlineBlockVisible = true, disabled = false }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const teams = useAppSelector(selectTeams);
  const usersOptions = useAppSelector(selectUsersAsOptions);

  const { control, watch, setValue } = useFormContext<IFormData>();
  const { isDirty } = useFormState({ control });

  const teamIdWatcher = watch('teamId');
  const recipientIdsWatcher = watch('notificationPreferences.recipientIds');

  const recipientOptions = useMemo(() => {
    if (!teams || !usersOptions) return [myUser];

    const options:IOption[] = [];
  
    if (teamIdWatcher) {
      const team = teams.find(team => team.id === teamIdWatcher);
      options.push(...(team?.members || []));
    } else if (usersOptions) {
      options.push(...usersOptions);
    }
    if (!options.some((o) => o.id === myUser.id)) options.unshift(myUser);
    return options.sort((a:any,b:any) => sortByName(a,b,'name'));

    // eslint-disable-next-line
  }, [teamIdWatcher, teams, usersOptions]);

  useEffect(() => {
    if (!isDirty) return
    
    const nextValue = recipientIdsWatcher.filter((recipient:IUser) => recipientOptions.some((r) => r.id === recipient.id));
    const team = teams?.find(team => team.id === teamIdWatcher);
    if (team) {
      const teamRecipients = team.caseNotificationPreferences?.recipients || [];
      teamRecipients.forEach((recipient:IUser) => {
        if (!nextValue.some((r) => r.id === recipient.id)) nextValue.push(recipient);
      });
    }

    setValue('notificationPreferences.recipientIds', nextValue);

    // eslint-disable-next-line
  }, [teamIdWatcher]);

  useEffect(() => {
    dispatch(getUsers({ role: [UserRoles.Admin] }));

    return () => {
      dispatch(UsersActions.setInitialField('users'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <OutlineBlock
      sx={outlineBlockVisible ? { mt: 6, pt: 6 } : { all: 'unset' }}
      label={outlineBlockVisible ? t('components.notificationPreferences.title') : ''}
    >
      <Fragment>
        <Controller
          control={control} name="notificationPreferences.recipientIds"
          render={({ field:{ value, onChange }}) => (
            <Autocomplete
              disabled={disabled}
              value={[...value].sort((a:any,b:any) => sortByName(a,b,'name'))}
              onChange={(_:any, nextValue:any) => onChange(nextValue)}
              options={recipientOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('labels.notificationRecipients')}
                  fullWidth
                  margin="normal"
                />
              )}
              multiple
              filterSelectedOptions
              getOptionLabel={(option:IOption) => option.name}
              isOptionEqualToValue={(option:IOption, value:IOption) => option.id === value.id}
              renderOption={(props, option: any) => (
                <li {...props} key={option.id}>{option.name}</li>
              )}
            />
          )}
        />
        <Box>
          <FormHelperText>{t('form.helperText.emailNotificationsForCaseEvents')}</FormHelperText>
        </Box>
      </Fragment>
    </OutlineBlock>
  );
};

export default NotificationPreferences;
