import { MouseEvent, FC, Fragment, useRef, useState, useEffect } from 'react';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectHighlightModeEnabled } from 'app/store/TODO2_app/app.selectors';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { pageMessages } from 'app/store/page/page.messages';
// Mui
import { Box } from '@mui/material';
// 
import Highlights, { IHighlight } from './Highlights';

type Props = {
  documentId: number;
  pageNum: number;
  version: number;
  highlights?: IHighlight[];
  // 
  onLoading: () => void;
}

const PageImageHighlights:FC<Props> = ({
  // Props
  documentId, pageNum, version, highlights = [], onLoading
}) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const highlightModeEnabled = useAppSelector((store:any) => selectHighlightModeEnabled(store));

  const [ startPos, setStartPos ] = useState({ x: 0, y: 0, xRation: 0, yRation: 0 });
  const [ placeholder, setPlaceholder ] = useState<any>(null);
  const [ isDrawing, setIsDrawing ] = useState(false);
  const [ isOut, setIsOut ] = useState(false);

  const boxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if ( !isDrawing || !isOut ) return;

    const onMouseUp = () => {
      setPlaceholder(null);
      setIsDrawing(false);
      setIsOut(false);
    }

    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    }
  }, [isDrawing, isOut]);

  const handleMouseDown = (event:MouseEvent<HTMLDivElement>) => {
    event.persist();

    if ( trialMode || !highlightModeEnabled ) return;

    if ( !boxRef.current ) return;

    const { offsetX, offsetY } = event.nativeEvent;

    const xRation = boxRef.current.offsetWidth / 100;
    const yRation = boxRef.current.offsetHeight / 100;

    setStartPos({ x: offsetX, y: offsetY, xRation, yRation });
    setIsDrawing(true);
  };

  const handleMouseMove = (event:MouseEvent<HTMLDivElement>) => {
    if ( !isDrawing ) return;

    const { offsetX, offsetY } = event.nativeEvent;

    if ( offsetX < startPos.x || offsetY < startPos.y ) return; // Disable negative width and height

    const top = startPos.y / startPos.yRation;
    const left = startPos.x / startPos.xRation;
    const width = (offsetX - startPos.x) / startPos.xRation;
    const height = (offsetY - startPos.y) / startPos.yRation;

    setPlaceholder({
      top: `${top.toFixed(2)}%`,
      left: `${left.toFixed(2)}%`,
      width: `${width.toFixed(2)}%`,
      height: `${height.toFixed(2)}%`,
    });
  };

  const handleMouseUp = () => {
    if ( isDrawing && placeholder ){
      onLoading();

      dispatch(PageActions.patchPage(documentId, pageNum, {
        version,
        highlights: [...highlights, placeholder]
      }, pageMessages.addHighlightToPage));
    }
    setPlaceholder(null);
    setIsDrawing(false);
    setIsOut(false);
  };

  const handleMouseEnter = () => {
    if ( isDrawing ) setIsOut(false);
  }

  const handleMouseLeave = () => {
    if ( isDrawing ) setIsOut(true);
  };

  const handleHighlightRemove = (index:number) => {
    onLoading();

    dispatch(PageActions.patchPage(documentId, pageNum, {
      version,
      highlights: highlights.filter((_:any, i:number) => i !== index)
    }, pageMessages.deleteHighlightFromPage));
  }

  return (
    <Fragment>
      <Box
        ref={boxRef}
        style={{
          position: 'absolute',
          top: 0, left: 0,
          width: '100%',
          height: '100%',
          zIndex: placeholder ? 3 : 1
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        {placeholder ? (
          <Box
            sx={{
              ...placeholder,
              position: 'absolute',
              border: '2px dashed rgba(0, 0, 0, 0.5)',
              pointerEvents: 'none'
            }}
          />
        ) : null}
      </Box>
      <Highlights
        highlights={highlights}
        onRemove={handleHighlightRemove}
      />
    </Fragment>
  );
}

export default PageImageHighlights;