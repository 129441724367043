import { FC, Fragment } from 'react';
// Models
import IReportPreset from 'app/models/ReportPreset';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deletePreset } from 'app/store/ReportPresets/ReportPresets.async';
// Selectors
import { selectLoading } from 'app/store/ReportPresets/ReportPresets.selectors';
// Mui
import { Theme, ListItem, ListItemText } from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import TemplateReportFormDialog from 'app/dialogs/ReportPresetFormDialog';
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  preset: IReportPreset;
};

const PresetsListItem:FC<Props> = ({
  // Props
  preset
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();

  const { open:openFormDialog, toggle:toggleFormDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = async () => {
    try {
      await dispatch(deletePreset(preset.id)).unwrap();
    } catch(error){}
  }

  return (
    <Fragment>
      <ListItem
        sx={{ '& + &': { borderTop: '1px solid rgba(0,0,0,0.08)' } }}
        secondaryAction={
          <Fragment>
            <IconButton
              name={`Preset dropdown ${preset.id}`}
              aria-label="Preset item dropdown button"
              onClick={openMenu}
            ><MoreVertIcon /></IconButton>
            <Menu>
              <MenuItem
                key={`Edit preset ${preset.id}`}
                name={`Edit preset ${preset.id}`}
                onClick={toggleFormDialog}
              >{t('buttons.edit')}</MenuItem>
              <MenuItem
                name={`Delete preset ${preset.id}`}
                sx={{
                  color: (theme:Theme) => theme.palette.error.main
                }}
                onClick={toggleConfirmationDialog}
              >{t('buttons.delete')}</MenuItem>
            </Menu>
          </Fragment>
        }
      >
        <ListItemText primary={preset.name} />
      </ListItem>
      {openFormDialog ? (
        <TemplateReportFormDialog
          open={openFormDialog}
          onClose={toggleFormDialog}
          preset={preset}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
          loading={loading}
          title={t('dialogs.confirmation.deleteTitle', { dataType: t('labels.preset') })}
          content={(
            <Trans
              t={t} i18nKey="dialogs.confirmation.deleteConfirmationWithName"
              components={{ strong: <strong /> }}
              values={{ dataType: t('labels.preset'), dataName: preset.name }}
            />
          )}
        />
      ) : null}
    </Fragment>
  )
}

export default PresetsListItem;
