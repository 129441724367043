import { FC, Fragment } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectHcpcsCodesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { FormHelperText } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// Utility
import { isRequired } from 'app/utilities/Validations';

interface IFormData {
  settings: {
    medicareOverchargeThreshold: number;
  };
}

const MedicareStepContent:FC = () => {
  const { t } = useTranslation();
  // State
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);

  const { control, formState:{ errors } } = useFormContext<IFormData>();

  if ( !hcpcsCodesEnabled ) return null;
  return (
    <Fragment>
      <Controller
        control={control} name="settings.medicareOverchargeThreshold"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.medicareOverchargeThreshold')} type="number"
            error={Boolean(errors.settings?.medicareOverchargeThreshold)}
            helperText={errors.settings?.medicareOverchargeThreshold?.message || ''}
            required={hcpcsCodesEnabled}
          />
        )}
      />
      <FormHelperText>{t('form.helperText.medicareOverchargeThreshold')}</FormHelperText>
    </Fragment>
  )
}

export default MedicareStepContent;
