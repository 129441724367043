import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Models
import { RootState } from 'app/store';
import IOption from 'app/models/Option';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLoading } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectCollectionsAsOptionsByFileType_TODO } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Box, MenuItem, Typography } from '@mui/material';
// Components
import { Input, Select, Button, LoadingButton } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
}

const DialogTitle:FC<Props> = ({
  // Props
  onClose
}) => {
  const { t } = useTranslation();

  // State
  const collectionOptions = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType_TODO(state, {
    fileFormat: 'custom'
  }));
  const loading = useAppSelector(selectLoading);

  const { control, formState: { errors } } = useFormContext();

  return (
    <Fragment>
      <Box sx={{
        display: 'flex',
        alignItems: 'flex-start',
        borderBottom: '1px solid rgba(0,0,0,0.08)',
        px: 6,
        py: 4
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 4
        }}>
          <Controller
            control={control} name="name"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                sx={{ width: 351 }}
                label={t('labels.name')}
                // placeholder={t('labels.name')}
                required
                error={Boolean(errors.name)}
                helperText={(errors.name?.message as string) || ''}
                margin="none"
                size="small"
                fullWidth={false}
              />
            )}
          />
          <Controller
            control={control} name="collectionId"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Select
                {...field}
                sx={{ width: 351 }}
                label={t('labels.documentCollection')}
                options={collectionOptions}
                required
                error={Boolean(errors.collectionId)}
                helperText={(errors.collectionId?.message as string) || ''}
                renderOptions={(option:IOption, index:number) => (
                  <MenuItem
                    sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                    key={`option item ${index} ${option.id}`}
                    value={option.id.toString()}
                  >
                    <Typography variant="subtitle1">{option.name}</Typography>
                    <Typography sx={{ maxWidth: 320, whiteSpace: 'normal' }} variant="caption">{option.props.description}</Typography>
                  </MenuItem>
                )}
                margin="none"
                size="small"
                fullWidth={false}
              />
            )}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}>
          <Button
            name="Cancel report form dialog"
            onClick={onClose}
            variant="outlined"
          >{t('buttons.close')}</Button>
          <LoadingButton
            name="Save document form dialog"
            type="submit"
            loading={loading}
            variant="contained"
          >{t('buttons.save')}</LoadingButton>
        </Box>
      </Box>
    </Fragment>
  )
}

export default DialogTitle;
