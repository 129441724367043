import { FC, ReactNode } from 'react';
// Mui
import { Box, IconButton, Tooltip } from '@mui/material';
// Icons
import {
  Close as CloseIcon,
  Link as LinkIcon
} from '@mui/icons-material';
// Components
import Portal from 'app/components/Portal';
import PageImage from 'app/components/PageImage';
import { useAppSelector } from 'app/hooks/useStore';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import IInsuranceCase from 'app/models/Case';
import { selectPageEntity } from 'app/store/page/page.selectors';
import CardPageDeletedPlaceholder from '@root/dialogs/CardPageDialog/CardPageDeletedPlaceholder';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  documentId: number;
  pageNum: number;
  isLeft: boolean;
  onClose: () => void;
  backdrop?: ReactNode;
}

const CardPageBodyPreview:FC<Props> = ({
  // Props
  documentId, pageNum, isLeft, onClose, backdrop
}) => {
  const { t } = useTranslation();

  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;

  const pageEntity = useAppSelector((state:any) => selectPageEntity(state, { documentId, pageNum }));

  const handleClick = () => {
    const toDocument = `/admin/cases/${insuranceCase?.id}/documents/${documentId}`;
    const toPage = `${toDocument}/${pageNum}`;
    const link = `${window.location.origin}${toPage}`;
    window.open(link, '_blank');
  }

  // Show preview button only for `/ai-chat` pages
  const isPreviewShown = window.location.pathname.includes('/ai-chat');

  return (
    <Portal className="relative z-50">
      {backdrop || null}
      <Box
        sx={{
          position: 'fixed',
          left: !isLeft ? 0 : 'initial',
          right: isLeft ? 0 : 'initial',
          top: 0,
          bottom: 0,
          width: '45%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'white',
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: 1,
          margin: 4,
          overflow: 'hidden',
          zIndex: 2
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: 0, right: 0,
          m: 4,
          p: 2,
          bgcolor: 'white',
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: 2,
          display: 'flex',
          gap: 2,
          zIndex: 1
        }}>
          {(pageEntity && !pageEntity.deleted) && isPreviewShown ? (
            <Tooltip title={t('buttons.goToPage')}>
              <IconButton
                onClick={handleClick}
                size="small"
              ><LinkIcon /></IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title={t('buttons.close')}>
            <IconButton
              onClick={onClose}
              size="small"
            ><CloseIcon /></IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            position: 'relative'
          }}
        >
          <Box sx={{
            position: 'absolute',
            p: 4,
            left: 0, top: 0,
            width: '100%',
            height: '100%',
            '& > div': {
              width: '100%',
              height: '100%',
              '& > img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }
            }
          }}>
            {(!pageEntity || pageEntity.deleted) ? (
              <CardPageDeletedPlaceholder />
            ) : (
              <PageImage documentId={documentId} pageNum={pageNum} showPageNum={true} />
            )}
          </Box>
        </Box>
      </Box>
    </Portal>
  )
}

export default CardPageBodyPreview;
