import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import {
  Theme,
  Box, Paper, Avatar, Typography,
  alpha,
  Tooltip,
} from '@mui/material';
// Icons
import { Delete as DeleteIcon } from '@mui/icons-material';
// Components
import { Button, IconButton } from 'app/components/Mui';
// Hooks
import { useLinkHandler } from 'app/hooks/useContainerLinkHandler';
// Utils
import { parseDateTimeToString } from 'app/utilities/DateTime';
// Mutations
import { useDeleteCommentMutation } from 'app/store/DMSDocuments/DMSDocuments.api';

export interface IComment {
  id: number;
  content: string;
  createdBy?: {
    id: number;
    name: string;
  };
  createdOn: string;
  modifiedOn: string;
};

const MAX_PREVIEW_LENGTH = 150;

type Props = {
  documentId: number;
  comment: IComment;
}

const Comment:FC<Props> = ({
  // Props
  documentId, comment
}) => {
  const { t } = useTranslation();

  const isContentLong = comment.content.length > MAX_PREVIEW_LENGTH;

  const [ deleteComment ] = useDeleteCommentMutation();

  const handleClick = useLinkHandler();

  const [ expanded, setExpanded ] = useState(!isContentLong);

  const toggleExpanded = () => setExpanded(!expanded);

  const handleDeleteClick = () => {
    deleteComment({ documentId, commentId: comment.id });
  }

  const displayContent = expanded
    ? comment.content
    : `${comment.content.substring(0, MAX_PREVIEW_LENGTH)}...`
  ;

  return (
    <Paper
      elevation={2}
      sx={{
        bgcolor: (theme:Theme) => alpha(theme.palette.primary.main, 0.05),
        borderRadius: 2,
        p: 4,
      }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        mb: 4
      }}>
        <Avatar sx={{ bgcolor: "primary.main" }}>
          {comment.createdBy?.name[0] ?? 'A'}
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          {/* User Info */}
          {comment.createdBy ? (
            <Typography variant="subtitle2">{comment.createdBy.name}</Typography>
          ) : null}
          <Typography variant="body2" color="text.secondary">
            {parseDateTimeToString(comment.createdOn)}
          </Typography>
        </Box>
        <Box>
          <Tooltip title={t('common.delete')}>
            <IconButton
              name={`Delete comment ${comment.id}`}
              size="small"
              onClick={handleDeleteClick}
              edge="end"
            ><DeleteIcon /> </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {/* Comment Text */}
      <Box
        sx={{
          height: 'initial',
          overflow: 'initial',
          p: 0,
          whiteSpace: 'break-spaces'
        }}
        className="ql-editor preview"
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: displayContent }}
      />
      {/* Show More / Show Less Button */}
      {isContentLong ? (
        <Box sx={{ pt: 2 }}>
          <Button
            name={`${expanded ? "Show Less" : "Show More"} comment ${comment.id}`}
            sx={{
              textTransform: "none",
              p: 0,
              '&:hover': {
                bgcolor: "transparent",
                textDecoration: "underline"
              }
            }}
            onClick={toggleExpanded}
            size="small"
            variant="text"
          >{expanded ? "Show Less" : "Show More"}</Button>
        </Box>
      ) : null}
    </Paper>
  )
}

export default Comment;