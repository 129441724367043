import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
// Types
import StapleModes from '@root/types/StapleModes';
import { pageMessages } from 'app/store/page/page.messages';
// Models
import { IPage, IPageWithAction } from '@root/models/Page';
// Store
import { useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectStapleMode, selectStapleId, selectStaplePagesToUpdate, selectStatus } from 'app/store/page/page.selectors';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
// Service
import { generateStapleGroup, generateWorkspaceOrder } from 'app/store/page/page.service';
// MaterialUI
import { Fab } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';
import { deleteEpisodes } from 'app/store/Episodes/Episodes.async';
import { useAppDispatch } from 'app/hooks/useStore';

const FilesStapleFabs:React.FC = () => {
  const { t } = useTranslation();

  const { documentId } = useParams<{ documentId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const stapleId:string | null = useSelector(selectStapleId);
  const stapleMode:StapleModes | null = useSelector(selectStapleMode);
  const staplePagesToUpdate:Array<IPageWithAction> = useSelector(selectStaplePagesToUpdate);
  const episodes:any = useSelector(selectEpisodesAll);
  const status:any = useSelector(selectStatus);

  const { isArchived } = useCaseStatus();

  const [ loading, setLoading ] = useState(false);

  React.useEffect(() => {
    return () => {
      handleCancelStapleMode();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( status === "Success" ){
      handleCancelStapleMode();
      setLoading(false);
    }
    if ( status === "Error" ){
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [status]);

  React.useEffect(() => {
    handleCancelStapleMode();
    // eslint-disable-next-line
  }, [documentId]);

  const handleSelectAll = () => {
    dispatch(PageActions.addAllPagesToStaple(Number(documentId)));
  }
  const handleSave = async () => {
    if ( !stapleId ) return;

    setLoading(true);

    const generatedStapleGroup = generateStapleGroup(stapleMode, stapleId, staplePagesToUpdate, episodes);

    const pagesByPageNum = generatedStapleGroup.reduce((acc:any, cur:any) => {
      acc[cur.pageNum] = cur;
      return acc;
    }, {});

    const pagesToUpdate = stapleMode === StapleModes.Create
      ? generatedStapleGroup
      : staplePagesToUpdate.length === generatedStapleGroup.length && generatedStapleGroup.every((page:IPage) => !page.staple)
        ? generatedStapleGroup
        : staplePagesToUpdate.map((page) => {
            const nextPage = pagesByPageNum[page.pageNum] || page;

            if ( !nextPage.staple ) return nextPage;

            const [ stapleId, version ] = (nextPage.staple as any).id.split(':');

            const { action, annotations, highlights, ...otherNextPage } = nextPage;

            const nextVersion = version ? Number(version) + 1 : 1;

            return {
              ...otherNextPage,
              staple:{
                ...otherNextPage.staple,
                id: `${stapleId}:${nextVersion}`
              }
            }
          });
    ;

    const pagesIds = pagesToUpdate.map((page:IPage) => `${page.documentId}:${page.pageNum}`);

    const pagesEpisodes = episodes
      .filter((episode:any) => pagesIds.includes(`${episode.documentId}:${episode.pageNum}`))
      .map((episode:any) => {
        const { author, ...otherEpisode } = episode;
        if ( author && !author.id ) return otherEpisode;
        return episode;
      });

    let uniqueEpisodes:any[] = [];
    let ununiqueEpisodesIds:number[] = [];

    if ( stapleMode === StapleModes.Create ){
      for ( let episode of pagesEpisodes ){
        const duplicateEpisode = uniqueEpisodes.find((eps) => eps.date === episode.date && eps.time === episode.time);
        if ( !duplicateEpisode ) {
          uniqueEpisodes = [...uniqueEpisodes, episode];
        } else {
          if (duplicateEpisode.author?.id !== episode.author?.id) {
            uniqueEpisodes = [...uniqueEpisodes, episode];
          } else if (duplicateEpisode.type !== episode.type) {
            uniqueEpisodes = [...uniqueEpisodes, episode];
          } else if (duplicateEpisode.notes !== episode.notes) {
            uniqueEpisodes = [...uniqueEpisodes, episode];
          } else {
            ununiqueEpisodesIds = [...ununiqueEpisodesIds, episode.id];
          }
        }
      }
    }

    let message = stapleMode === StapleModes.Create ? pageMessages.createStaple : pageMessages.updateStaple;
    if ( staplePagesToUpdate.length === pagesToUpdate.length && pagesToUpdate.every((page:IPage) => !page.staple) ){
      message = pageMessages.removeStaple;
    }

    const nextPagesToUpdate = ununiqueEpisodesIds.length
      ? pagesToUpdate.map((page:IPage) => {
          if ( page.parentEpisodeId && ununiqueEpisodesIds.includes(page.parentEpisodeId) ) return {
            ...page,
            parentEpisodeId: null,
            workspaceOrder: generateWorkspaceOrder(episodes, {
              parentEpisodeId: null,
              workspaceOrder: page.workspaceOrder
            })
          };
          return page;
        })
      : pagesToUpdate
    ;

    dispatch(PageActions.patchPages(nextPagesToUpdate, message, () => {
      if ( ununiqueEpisodesIds.length ) dispatch(deleteEpisodes(ununiqueEpisodesIds))
    }));

    if ( message === pageMessages.updateStaple || message === pageMessages.removeStaple ){
      const parentStaplePage = nextPagesToUpdate.find((p) => p.staple && p.staple.order === 1);

      if ( !parentStaplePage ) return;

      dispatch(PageActions.removePageFromCompare(
        parentStaplePage.documentId,
        parentStaplePage.pageNum,
        stapleId
      ))
    }
  }

  const handleCancelStapleMode = () => dispatch(PageActions.cancelStapleMode());
  const handleStapleModeCreate = () => dispatch(PageActions.setStapleModeCreate(Number(documentId)));

  const isSaveDisabled = !staplePagesToUpdate.some((page:IPageWithAction) => page.action);

  return (
    <div style={{
      position: 'fixed',
      right: '16px',
      bottom: '16px'
    }}>
      {stapleMode ? (
        <React.Fragment>
          {stapleMode === StapleModes.Create && isSaveDisabled ? (
            <Fab
              name="Select all pages"
              style={{ marginRight: '8px' }}
              onClick={handleSelectAll}
              color="default"
              variant="extended"
            >{t('buttons.selectAll')}</Fab>
          ) : null}
          <Fab
            name="Cancel staple"
            style={{ marginRight: '8px' }}
            onClick={handleCancelStapleMode}
            color="default"
            variant="extended"
          >{t('buttons.cancel')}</Fab>
          <Fab
            name="Save staple"
            disabled={isSaveDisabled || loading}
            onClick={handleSave}
            color="primary"
            variant="extended"
          >{t('buttons.save')}</Fab>
        </React.Fragment>
      ) : (
        <Fab
          name="Create staple"
          disabled={isArchived}
          type="button"
          onClick={handleStapleModeCreate}
          color="primary"
          variant="extended"
        >{t('pages.files.createStapleButton')}</Fab>
      )}
    </div>
  )
}

export default FilesStapleFabs;