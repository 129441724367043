import { FC, Fragment } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { toggleWorkspaceAdditionalTools } from 'app/store/ui/uiSlice';
// Selectors
import { selectAdditionalToolsShown } from 'app/store/ui/uiSelectors';
// Selectors
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';
import { selectTotalPagesInWorkspace, selectEpisodesInWorkspace } from 'app/store/page/page.selectors';
// Mui
import { Box, Toolbar, IconButton, Tooltip, Typography, Skeleton } from '@mui/material';
// Icons
import { Tune as TuneIcon } from '@mui/icons-material';

import GoToPostEvent from './GoToPostEvent';
import GoToPage from './GoToPage';
import GoToLastOpenedPage from './GoToLastOpenedPage';
// i18next
import { useTranslation } from 'react-i18next';
import AdditionalToolsNames from 'app/types/AdditionalToolsNames';

type Props = {
  listRef: any;
}

const WorkspaceToolbar:FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  // State
  const tools = useAppSelector((state:RootState) => selectAdditionalToolsShown(state, {
    toolsType: 'workspace'
  }));
  // ToDO
  // State
  const episodesInWorkspace:any = useSelector(selectEpisodesInWorkspace);
  const workspaceType = useSelector(selectWorkspaceType);
  const totalPages:any = useSelector(selectTotalPagesInWorkspace);

  const handleClick = () => {
    dispatch(toggleWorkspaceAdditionalTools());
  }

  return (
    <Toolbar sx={{ gap: 4 }}>
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        {!totalPages ? (
          <Skeleton width={360} />
        ) : (
          workspaceType === 'pages' ? (
            <Typography variant="body2">
              {totalPages.totalMainPagesInWorkspace ? (
                <Fragment>{t('pages.workspace.totalNumberOfPages')}: <b>{totalPages.totalMainPagesInWorkspace}</b></Fragment>
              ) : null}
              {totalPages.totalPagesInWorkspace ? (
                <Fragment>&nbsp;{t('pages.workspace.includingStapledPages')}: <b>{totalPages.totalPagesInWorkspace}</b></Fragment>
              ) : null}
            </Typography>
          ) : (
            episodesInWorkspace ? (
              <Typography>{t('pages.workspace.totalEpisodes')}: <b>{episodesInWorkspace.length}</b>.</Typography>
            ) : null
          )
        )}
      </Box>
      {tools.includes(AdditionalToolsNames.GoToPostEvent) ? (
        <GoToPostEvent listRef={listRef} />
      ) : null}
      {tools.includes(AdditionalToolsNames.GoToLastOpenedPage) ? (
        <GoToLastOpenedPage listRef={listRef} />
      ) : null}
      {tools.includes(AdditionalToolsNames.GoToPage) ? (
        <GoToPage listRef={listRef} />
      ) : null}
      <Tooltip title={t('labels.additionalTools')}>
        <IconButton
          onClick={handleClick}
        ><TuneIcon /></IconButton>
      </Tooltip>
    </Toolbar>
  )
}

export default WorkspaceToolbar;
