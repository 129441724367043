import { FC, useEffect } from 'react';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getInsuranceCases } from 'app/store/Presenter/Presenter.async';
// Selectors
import { selectFilteredInsuranceCases } from 'app/store/Presenter/Presenter.selectors';
// Components
import { Loader, Message } from 'app/components/Utilities';
// Mui
import { Box } from '@mui/material';
// 
import InsuranceCasesListItem from './InsuranceCasesListItem';
// i18next
import { useTranslation } from 'react-i18next';

const InsuranceCasesList:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCases:any = useAppSelector(selectFilteredInsuranceCases);

  useEffect(() => {
    dispatch(getInsuranceCases({}));
    // eslint-disable-next-line
  }, []);

  if ( !insuranceCases ) return <Loader />;
  if ( insuranceCases.length === 0 ) return <Message text={t('pages.cases.noData')} />;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {insuranceCases.map((insuranceCase:any) => (
        <InsuranceCasesListItem
          key={`insurance-case-item-${insuranceCase.id}`}
          insuranceCase={insuranceCase}
        />
      ))}
    </Box>
  )
}

export default InsuranceCasesList;
