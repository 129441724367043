import { FC } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectReportSectionTemplates } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.selectors';
// MaterialUI
// Core
import { Container, List, Paper } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import ReportSectionTemplateItem from './ReportSectionTemplateItem';

const ReportSectionTemplatesList:FC = () => {
  const { t } = useTranslation();
  // State
  const reportSectionTemplates = useAppSelector(selectReportSectionTemplates);

  if ( !reportSectionTemplates ) return <Loader />;
  if ( !reportSectionTemplates.length ) return <Message text={t('pages.reportSectionTemplates.noData')} />
  return (
    <Container sx={{ pt: 4, pb: 22 }} maxWidth="sm">
      <Paper variant="outlined">
        <List>
          {reportSectionTemplates.map((reportSectionTemplate:any) => (
            <ReportSectionTemplateItem
              key={`report section template item ${reportSectionTemplate.id}`}
              reportSectionTemplate={reportSectionTemplate}
            />
          ))}
        </List>
      </Paper>
    </Container>
  )
}

export default ReportSectionTemplatesList;
