import { FC, useMemo } from 'react';
// Models
import { IUsageInvoice } from 'app/store/BillingUsage/BillingUsage.models';
// Mui
import { Box, Tooltip } from '@mui/material';
// Icons
import { Receipt as ReceiptIcon } from '@mui/icons-material';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  invoices: IUsageInvoice[] | undefined;
}

const InvoiceDraftIcon:FC<Props> = ({
  // Props
  invoices
}) => {
  const { t } = useTranslation();

  const hasAnyDraft = useMemo(() => {
    if ( !invoices || !invoices.length ) return false;
    return invoices.some((invoice) => invoice.status === 'draft');
  }, [invoices]);
  if ( !hasAnyDraft ) return null;
  return (
    <Tooltip title={t('pages.billingAndSubscriptions.draftInvoiceIconTooltip')}>
      <Box sx={{
        display: 'flex',
        color: 'warning.light'
      }}><ReceiptIcon fontSize="small" /></Box>
    </Tooltip>
  )
}

export default InvoiceDraftIcon