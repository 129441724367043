import { FC, useMemo } from 'react';
// Models
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getPage } from 'app/store/DMSDocumentPages/DMSDocumentPages.async';
// Selectors
import { selectPage } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
import { selectFilteredWorkspacePrimaryPagesIdsByPreEvent } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box, Button, FormHelperText } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const CardPageNavigation:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const page:IPage | IPresenterPage | null = useAppSelector(selectPage);
  const pageIds:string[] | null = useAppSelector(selectFilteredWorkspacePrimaryPagesIdsByPreEvent);

  const filteredPageIds = useMemo(() => {
    if ( !pageIds ) return [];
    return pageIds.filter((id:string) => id !== 'pre-event' && id !== 'post-event');
  }, [pageIds]);

  const currentIndex = useMemo(() => {
    if ( !page ) return undefined;
    const pageId = `${page.documentId}:${page.pageNum}`;
    return filteredPageIds.indexOf(pageId)
    // eslint-disable-next-line
  }, [page, filteredPageIds]);

  const prevIndex = useMemo(() => {
    if ( typeof currentIndex === 'undefined' ) return undefined;
    return currentIndex === 0 ? undefined : currentIndex - 1;
    // eslint-disable-next-line
  }, [currentIndex]);

  const nextIndex = useMemo(() => {
    if ( typeof currentIndex === 'undefined' ) return undefined;
    return currentIndex === filteredPageIds.length - 1 ? undefined : currentIndex + 1;
    // eslint-disable-next-line
  }, [currentIndex]);

  const handleClick = (isNext:boolean = false) => {
    const index = isNext ? nextIndex : prevIndex;
    if ( typeof index === 'undefined' ) return;
    const [ documentId, pageNum ] = filteredPageIds[index].split(':').map(Number);
    dispatch(getPage({ documentId, pageNum }));
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      mr: 4
    }}>
      <Button
        onClick={() => handleClick()}
        variant="outlined"
        disabled={typeof prevIndex === 'undefined'}
      >{t('buttons.prev')}</Button>
      <Button
        onClick={() => handleClick(true)}
        variant="outlined"
        disabled={typeof nextIndex === 'undefined'}
      >{t('buttons.next')}</Button>
      {typeof currentIndex !== 'undefined' ? (
        <FormHelperText sx={{ mt: 0, textTransform: 'none' }}>
          {t('dialogs.cardPage.pageOfPages', { currentPage: currentIndex + 1, allPages: filteredPageIds.length })}
        </FormHelperText>
      ) : null}
    </Box>
  )
}

export default CardPageNavigation;
