import i18n from "i18n";

const RecordsPagesMessages = {
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.page') }),
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.page') }),
  Rotate: i18n.t('notifications.page.rotatePage'),

  UpdateColors: i18n.t('notifications.common.update', { dataType: i18n.t('labels.pageColors') }),
  RemoveColors: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.pageColors') }),

  UpdateTag: i18n.t('notifications.common.update', { dataType: i18n.t('labels.pageTag') }),
  RemoveTag: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.pageTag') }),

  AddToWorkspace: i18n.t('notifications.page.addPageToWorkspace'),
  RemoveFromWorkspace: i18n.t('notifications.page.removePageFromWorkspace'),

  AddHighlight: i18n.t('notifications.page.addHighlightToPage'),
  DeleteHighlight: i18n.t('notifications.page.deleteHighlightFromPage'),

  RemoveFromStaple: i18n.t('notifications.page.removePageFromStaple'),

  ExportPagesToPDF: i18n.t('notifications.page.exportPagesToPDF'),

  CreateStaple: i18n.t('notifications.common.create', { dataType: i18n.t('labels.staple') }),
  UpdateStaple: i18n.t('notifications.common.update', { dataType: i18n.t('labels.staple') }),
  RemoveStaple: i18n.t('notifications.common.remove', { dataType: i18n.t('labels.staple') })
}

export default RecordsPagesMessages;

i18n.on('languageChanged', () => {
  RecordsPagesMessages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.page') });
  RecordsPagesMessages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.page') });
  RecordsPagesMessages.Rotate = i18n.t('notifications.page.rotatePage');

  RecordsPagesMessages.UpdateColors = i18n.t('notifications.common.update', { dataType: i18n.t('labels.pageColors') });
  RecordsPagesMessages.RemoveColors = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.pageColors') });

  RecordsPagesMessages.UpdateTag = i18n.t('notifications.common.update', { dataType: i18n.t('labels.pageTag') });
  RecordsPagesMessages.RemoveTag = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.pageTag') });

  RecordsPagesMessages.AddToWorkspace = i18n.t('notifications.page.addPageToWorkspace');
  RecordsPagesMessages.RemoveFromWorkspace = i18n.t('notifications.page.removePageFromWorkspace');

  RecordsPagesMessages.AddHighlight = i18n.t('notifications.page.addHighlightToPage');
  RecordsPagesMessages.DeleteHighlight = i18n.t('notifications.page.deleteHighlightFromPage');

  RecordsPagesMessages.RemoveFromStaple = i18n.t('notifications.page.removePageFromStaple');

  RecordsPagesMessages.ExportPagesToPDF = i18n.t('notifications.page.exportPagesToPDF');

  RecordsPagesMessages.CreateStaple = i18n.t('notifications.common.create', { dataType: i18n.t('labels.staple') });
  RecordsPagesMessages.UpdateStaple = i18n.t('notifications.common.update', { dataType: i18n.t('labels.staple') });
  RecordsPagesMessages.RemoveStaple = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.staple') });
});
