import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import { Autocomplete } from 'app/components/Mui/Autocompletes';

interface IFormData {
  clientAccountId?: string | number;
}

const ClientTabContent:FC = () => {
  const { t } = useTranslation();

  // State
  const accountOptions = useAppSelector(selectAccountsAsOptions);

  const { control } = useFormContext<IFormData>();

  return (
    <Box sx={{ pt: 4 }}>
      {/* Client account */}
      <Controller
        control={control} name="clientAccountId"
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={accountOptions || []}
            TextFieldProps={{
              label: t('labels.clientAccount')
            }}
            renderOption={(props, option) => (
              <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                <Typography variant="subtitle1">{option.label}</Typography>
                {option.props.address ? (
                  <Typography variant="caption">{option.props.address}</Typography>
                ) : null}
              </li>
            )}
            disableClearable={true}
          />
        )}
      />
    </Box>
  );
};

export default ClientTabContent;