import React from 'react';
// Models
import { IPage } from '@root/models/Page';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectIsPageDisabled, selectIsPageInStapleUpdate } from 'app/store/page/page.selectors';
// MaterialUI
import Button from '@mui/material/Button';
// Components
import Icon from 'app/components/Icon';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  page: IPage;
};

const PageCardFooterStaple:React.FC<Props> = ({
  // Props
  page
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const isPageDisabled = useSelector((state:any) => selectIsPageDisabled(state, {
    documentId: page.documentId,
    pageNum: page.pageNum
  }));
  const isPageInStapleUpdate = useSelector((state:any) => selectIsPageInStapleUpdate(state, {
    documentId: page.documentId,
    pageNum: page.pageNum
  }));

  const handleAddPageToStaple = () => dispatch(PageActions.addPageToStaple(page));
  const handleRemovePageFromStaple = () => dispatch(PageActions.removePageFromStaple(page));

  if ( isPageDisabled ) return null;
  return (
    <React.Fragment>
      {isPageInStapleUpdate ? (
        <Button
          variant="outlined"
          color="primary"
          onClick={handleRemovePageFromStaple}
          fullWidth
        >
          <Icon sx={{ mr: 2 }} icon="done" />
          {t('components.cardPage.inStaple')}
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="inherit"
          onClick={handleAddPageToStaple}
          fullWidth
        >
          <Icon sx={{ mr: 2 }} icon="add" />
          {t('components.cardPage.addToStaple')}
        </Button>
      )}
    </React.Fragment>
  )
}

export default PageCardFooterStaple;
