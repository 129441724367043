import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getUsers } from 'app/store/UsersManagement/UsersManagement.async';
// Selectors
import { selectParams } from 'app/store/UsersManagement/UsersManagement.selectors';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import UsersFilter from './UsersFilter';
import UsersList from './UsersList';
// i18next
import { useTranslation } from 'react-i18next';

const UsersPage:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const params = useAppSelector(selectParams);

  useEffect(() => {
    dispatch(getUsers(params));
    // eslint-disable-next-line
  }, [params]);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.amUsers.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="sm">
          <UsersFilter />
          <UsersList />
        </Container>
      </Box>
    </Fragment>
  )
}

export default UsersPage;
