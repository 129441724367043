import { FC } from 'react';
// Models
import { IInvoiceTransaction } from 'app/models/BillingInvoice';
// Mui
import { Divider, Box } from '@mui/material';
// Components
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useLongDateTme from 'app/hooks/useLongDateTime';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  invoiceId: number;
  transactions: IInvoiceTransaction[] | undefined;
}

const InvoicesListItemTransactions:FC<Props> = ({
  // Props
  invoiceId, transactions
}) => {
  if ( !transactions ) return null;
  return (
    <Box sx={{ mb: 4 }}>
      <Divider sx={{ my: 4 }} />
      {transactions.map((transaction:IInvoiceTransaction, index:number) => (
        <TransactionItem
          key={`biling-invoice-item-${invoiceId}-transaction-${index}`}
          transaction={transaction}
        />
      ))}
    </Box>
  )
}

export default InvoicesListItemTransactions;

type TransactionItemProps = {
  transaction: IInvoiceTransaction;
}

const TransactionItem:FC<TransactionItemProps> = ({
  // Props
  transaction
}) => {
  const { t } = useTranslation();

  const longCreatedOn = useLongDateTme({
    dateValue: transaction.createdOn,
    displayTime: true,
    local: true
  })

  return (
    <Box>
      {longCreatedOn ? (
        <InfoBlock
          label={t('labels.paidAt')}
          value={longCreatedOn}
          direction="row"
        />
      ) : null}
      <InfoBlock
        label={t('labels.paymentMethod')}
        value={transaction.paymentMethod === 'creditCard' ? t('labels.creditCard') : transaction.paymentMethod}
        direction="row"
      />
    </Box>
  )
}
