import { createSelector } from '@reduxjs/toolkit';
// Models
import { RootState } from 'app/store';
import IBillingService from 'app/models/BillingService';
import { IBillingPackageItem } from 'app/models/BillingPackage';

export const selectIds = (state:RootState) => state['billing/services'].ids;
export const selectEntities = (state:RootState) => state['billing/services'].entities;
export const selectLoading = (state:RootState) => state['billing/services'].loading;

export const selectServiceEntity = createSelector(
  [
    selectEntities,
    (_, props:{ serviceId:number }) => props
  ],
  (entities:any, { serviceId }) => entities[serviceId]
);

export const selectServicesAsOptions_TODO = createSelector(
  [
    selectIds,
    selectEntities
  ],
  (ids:number[] | null, entities:any) => {
    if ( !ids ) return null;
    return ids.map((serviceId:number) => {
      const service:IBillingService = entities[serviceId];
      return ({
        id: service.id,
        name: service.name
      })
    });
  }
);

export const selectServicesAsOptions = createSelector(
  [
    selectIds,
    selectEntities
  ],
  (ids:number[] | null, entities:any) => {
    if ( !ids ) return null;
    return ids.map((serviceId:number) => {
      const service:IBillingService = entities[serviceId];
      return ({
        value: service.id,
        label: service.name
      })
    });
  }
);

export const selectNonAdditionalBillingPackageItems = createSelector(
  [
    selectIds,
    selectEntities
  ],
  (ids:number[] | null, entities:Record<number, IBillingService>) => {
    if ( !ids ) return null;
    return ids.map((serviceId:number) => {
      const service:IBillingService = entities[serviceId];
      const { id, unit, additional } = service;
      return {
        serviceId: id,
        additional,
        price: 0,
        numberOfFreeOfChargeUnits: 0,
        unit,
        usageType: service.code === 'cptCodesAccess' ? 'licensed' : 'metered',
        recurring: {
          interval: 'day',
          frequency: 1
        }
      } as IBillingPackageItem;
    }).filter((item:IBillingPackageItem) => !item.additional) as IBillingPackageItem[]
  }
);
