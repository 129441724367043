import { ChangeEvent, FC, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Models
import IAuditRecord from 'app/models/AuditRecord';
// Actions
import { AuditActions } from 'app/store/Audit/Audit.slice';
// Selectors
import { selectAuditRecords, selectSearch } from 'app/store/Audit/Audit.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

const AuditRecordsFilter:FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const auditRecords:IAuditRecord[] | null = useAppSelector(selectAuditRecords);
  const search = useAppSelector(selectSearch);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(AuditActions.setSearch(value));
  }

  const handleClearSearch = () => {
    dispatch(AuditActions.setSearch(''));
  }

  useEffect(() => {
    return () => {
      dispatch(AuditActions.setInitialField('search'));
    }
    // eslint-disable-next-line
  }, []);

  if (!auditRecords || !auditRecords.length) return null;
  return (
    <Box sx={{ mb: 4 }}>
      <Input
        label={t('labels.search')} id="search" name="search" value={search}
        fullWidth size="small" margin="none"
        onChange={handleChange}
        onClear={handleClearSearch}
      />
    </Box>
  )
}

export default AuditRecordsFilter;
