import { FC, useMemo, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
// Models
import { IPage } from '@root/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Async
import { useLazyGetAuthorsQuery } from 'app/store/EpisodeAuthors/EpisodeAuthors.api';
// Selectors
import { selectEpisodeAuthorSuggestionEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectPageStaples } from 'app/store/page/page.selectors';
// Mui
import { Box, Button, Tooltip, IconButton } from '@mui/material';
// Icons
import {
  Person as PersonIcon,
  ChevronLeftOutlined as ChevronLeftOutlinedIcon,
  ChevronRightOutlined as ChevronRightOutlinedIcon
} from '@mui/icons-material';

const MAX_ITEMS_TO_SHOW = 4;

type Props = {
  isDisabled: boolean;
};

const SuggestedAuthors:FC<Props> = ({
  // Props
  isDisabled
}) => {
  // State
  const episodeAuthorSuggestionEnabled = useAppSelector(selectEpisodeAuthorSuggestionEnabled);
  const staplePages = useAppSelector(selectPageStaples);

  const { setValue } = useFormContext();

  const [ trigger ] = useLazyGetAuthorsQuery();

  const suggestedAuthorNames = useMemo(() => {
    if ( !staplePages || !staplePages.length ) return [];
    return staplePages.reduce((acc:string[], cur:IPage) => {
      if ( cur.annotations && cur.annotations.length ){
        for ( let annotation of cur.annotations ){
          if ( annotation.name !== 'per' || acc.includes(annotation.value) ) continue;
          acc.push(annotation.value);
        }
      }
      return acc;
    }, []);
  }, [staplePages]);

  const hasMoreThanMaxItemsToShow = useMemo(() => {
    return suggestedAuthorNames.length > MAX_ITEMS_TO_SHOW;
  }, [suggestedAuthorNames]);

  const [ selectedAuthorName, setSelectedAuthorName ] = useState<string | null>(null);
  const [ showMore, setShowMore ] = useState(!hasMoreThanMaxItemsToShow);

  useEffect(() => {
    if ( !selectedAuthorName ) return;

    const fetchAuthors = async () => {
      const result = await trigger({ namePrefix: selectedAuthorName, t: Date.now() }).unwrap();

      if ( result.length > 0 ) {
        setValue('author', result[0]);
        setValue('authorId', result[0].id);
      } else {
        setValue('author', { id: -1, name: selectedAuthorName });
        setValue('authorId', -1);
      }

      setSelectedAuthorName(null);
    };

    fetchAuthors();

    // trigger({ namePrefix: selectedAuthorName, t: Date.now() });
  }, [selectedAuthorName, trigger, setValue]);

  const handleClick = (authorName:string) => () => {
    setSelectedAuthorName(authorName);
  }

  const toggleShowMore = () => {
    setShowMore(!showMore);
  }

  const slicedSuggestedAuthorNames = showMore
    ? suggestedAuthorNames
    : suggestedAuthorNames.slice(0, MAX_ITEMS_TO_SHOW)
  ;

  if ( !episodeAuthorSuggestionEnabled ) return null;
  if ( !suggestedAuthorNames.length || isDisabled ) return null;
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      mb: 4
    }}>
      <Tooltip title="Suggested authors">
        <PersonIcon sx={{ color: 'rgba(0,0,0,0.54)' }} />
      </Tooltip>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 2
      }}>
        {slicedSuggestedAuthorNames.map((authorName:string, index:number) => (
          <Button
            key={`authorItem-${index}`}
            sx={{ whiteSpace: 'nowrap' }}
            onClick={handleClick(authorName)}
            variant="outlined"
          >{authorName}</Button>
        ))}
        {hasMoreThanMaxItemsToShow ? (
          <IconButton
            onClick={toggleShowMore}
            size="small"
          >{showMore ? <ChevronLeftOutlinedIcon /> : <ChevronRightOutlinedIcon />}</IconButton>
        ) : null}
      </Box>
    </Box>
  )
}

export default SuggestedAuthors