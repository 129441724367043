import { FC, Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Models
import { IUsageInvoice } from 'app/store/BillingUsage/BillingUsage.models';
// Mui
import { Chip, Box, Typography, Theme, alpha } from '@mui/material';
// Utilities
import { statusThemeColor } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  invoices: IUsageInvoice[];
}

const InvoiceNumberChips:FC<Props> = ({
  // Props
  invoices
}) => {
  const { t } = useTranslation();
  const MAX_VISIBLE = 2;

  const [ showAll, setShowAll ] = useState(false);

  const history = useHistory();

  const handleClick = (invoiceNumber:string) => () => {
    const params = { tab: '0', invoiceNumber };
    const queryString = new URLSearchParams(params).toString();
    history.push(`/admin/billing-and-subscriptions?${queryString}`);
  };

  const visibleInvoices = showAll ? invoices : invoices.slice(0, MAX_VISIBLE);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if ( !invoices.length ) return <Fragment>-</Fragment>;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {visibleInvoices.map((invoice) => {
        const chipSx = invoice.status !== 'draft' ? ({
          bgcolor: (theme:Theme) => statusThemeColor(invoice.status, theme),
          color: 'white',
          '&:hover': {
            bgcolor: (theme:Theme) => alpha(statusThemeColor(invoice.status, theme), 0.9)
          }
        }) : {};
        return (
          <Chip
            key={invoice.id}
            sx={chipSx}
            label={invoice.invoiceNumber}
            onClick={handleClick(invoice.invoiceNumber)}
            size="small"
          />
        )
      })}
      {invoices.length > MAX_VISIBLE && (
        <Typography
          sx={{
            alignSelf: 'center',
            cursor: 'pointer',
            color: 'primary.main'
          }}
          onClick={toggleShowAll}
          variant="caption"
        >{showAll ? t('buttons.showLess') : t('buttons.showMore')}</Typography>
      )}
    </Box>
  )
}

export default InvoiceNumberChips