import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.exportTemplate') }),
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.exportTemplate') }),
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.exportTemplate') }),
  Upload: i18n.t('notifications.exportTemplates.upload')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.exportTemplate') });
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.exportTemplate') });
  Messages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.exportTemplate') });
  Messages.Upload = i18n.t('notifications.exportTemplates.upload');
})