import { ChangeEvent, FC, Fragment, useRef, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import {
  createReportSectionTemplate, updateReportSectionTemplate
} from 'app/store/ReportSectionTemplates/ReportSectionTemplates.async';
// Selectors
import { selectTagsAsOptions } from 'app/store/Accounts/Accounts.selectors';
import { selectLoading } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.selectors';
// Mui
import { 
  Box, Grid, FormHelperText, Tooltip, Typography
} from '@mui/material';
// Icons
import {
  FileCopy as FileCopyIcon,
  Check as CheckIcon
} from '@mui/icons-material';
// Components
import Dialog from 'app/components/Dialog';
import QuillEditor from 'app/components/QuillEditor';
import { Input, Select, IconButton, Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useCopyToClipboard from 'app/hooks/useCopyToClipboard';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  type: string;
  tag: string;
  name: string;
  variableName: string;
  content: string;
};

const defaultValues:IFormData = {
  type: '',
  tag: '',
  name: '',
  variableName: '{{ }}',
  content: ''
};

type Props = {
  open: boolean;
  onClose: () => void;
  reportSectionTemplate?: any;
}

const ReportSectionTemplateFormDialog:FC<Props> = ({
  // Props
  open, onClose, reportSectionTemplate
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const tagOptions = useAppSelector(selectTagsAsOptions);
  const loading = useAppSelector(selectLoading);

  const [ asyncCopyTextToClipboardFn, copied ] = useCopyToClipboard();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [ variableUpdated, setVariabledUpdated ] = useState(false);

  const generateReportSectionData = (data:IFormData) => {
    const { variableName, tag, content, ...otherData } = data;
    const nextData:any = {
      ...otherData,
      variableName: variableName.replace('{{ ', '').replace(' }}', '')
    }
    if ( otherData.type === 'freeText' ) nextData['content'] = content;
    if ( otherData.type === 'tag' ) nextData['tag'] = tag;
    return nextData;
  }

  const { handleSubmit, control, watch, formState:{ errors }, reset, setValue, getValues } = useForm<IFormData>({
    defaultValues: {
      type: reportSectionTemplate?.type || '',
      name: reportSectionTemplate?.name || '',
      variableName:
        reportSectionTemplate?.variableName
        ? `{{ ${reportSectionTemplate.variableName} }}`
        : '{{  }}'
      ,
      content: reportSectionTemplate?.content || '',
      tag: reportSectionTemplate?.tag || '',
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const nextData = generateReportSectionData(data);

    try {
      if ( reportSectionTemplate ){
        await asyncUpdateReportSectionTemplate(reportSectionTemplate.id, nextData);
      } else {
        await dispatch(createReportSectionTemplate(nextData)).unwrap();
      }
      onClose();
    } catch(error){}
  });

  const onSubmitWithoutClosing = async (data:IFormData) => {
    const nextData = generateReportSectionData(data);

    if ( !reportSectionTemplate ) return;

    await asyncUpdateReportSectionTemplate(reportSectionTemplate.id, nextData);
  }

  const asyncUpdateReportSectionTemplate = async (reportSectionTemplateId:number, data:any):Promise<boolean> => {
    try {
      await dispatch(updateReportSectionTemplate({
        reportSectionTemplateId,
        data
      })).unwrap();
      return Promise.resolve(true);
    } catch(error){
      console.log(error);
      return Promise.reject(false);
    }
  }

  const handleClick = useCallback(async () => {
    try {
      const value = getValues('variableName') || '';
      await asyncCopyTextToClipboardFn(value, false);
    } catch(error){
      console.log(error);
    }
    // eslint-disable-next-line
  }, [getValues('variableName')]);

  const handleChange = (onChange: (...event: any[]) => void) => (event:ChangeEvent<HTMLInputElement>) => {
    if ( !variableUpdated ){
      const { name, value } = event.target;

      if ( name === 'variableName' ) setVariabledUpdated(true);
      if ( name === 'type' ) reset({ ...defaultValues, type: value });
      if ( name === 'tag' ){
        setValue('name', value);
        setValue('variableName', `{{ ${value.replace(/\s/g, '_').toUpperCase()} }}`)
      }
      if ( name === 'name' ){
        setValue('variableName', `{{ ${value.replace(/\s/g, '_').toUpperCase()} }}`)
      }
    }

    onChange(event)
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel report section template dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      {reportSectionTemplate ? (
        <Fragment>
          <LoadingButton
            name="Save report section template dialog"
            loading={loading}
            onClick={handleSubmit(onSubmitWithoutClosing)}
            variant="contained"
            color="primary"
          >{t('buttons.save')}</LoadingButton>
          <LoadingButton
            name="Save and close report section template dialog"
            loading={loading}
            onClick={onSubmit}
            variant="contained"
            color="primary"
          >{t('buttons.saveAndClose')}</LoadingButton>
        </Fragment>
      ) : (
        <LoadingButton
          name="Create report section template dialog"
          loading={loading}
          onClick={onSubmit}
          color="primary"
          variant="contained"
        >{t('buttons.create')}</LoadingButton>
      )}
    </Fragment>
  );

  const watchType = watch('type');
  const watchVariableName = watch('variableName');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      title={reportSectionTemplate
        ? t('dialogs.reportSection.updateFormTitle')
        : t('dialogs.reportSection.createFormTitle')
      }
      actions={actions}
    >
      <form noValidate>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Controller
              control={control} name="type"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('labels.type')}
                  onChange={handleChange(field.onChange)}
                  options={[
                    { id: '', name: t('dialogs.reportSection.chooseType') },
                    { id: 'tag', name: t('dialogs.reportSection.tag') },
                    { id: 'freeText', name: t('dialogs.reportSection.freeText') }
                  ]}
                  required={true}
                  disabled={reportSectionTemplate?.id ? true : false}
                  error={Boolean(errors.type)}
                  helperText={errors.type?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">
              {watchType === 'freeText' ? (
                t('dialogs.reportSection.freeTextHint')
              ) : watchType === 'tag' ? (
                t('dialogs.reportSection.tagHint')
              ) : null}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          {watchType === 'tag' ? (
            <Grid item xs={12} sm={2}>
              <Controller
                control={control} name="tag"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={t('dialogs.reportSection.tag')}
                    onChange={handleChange(field.onChange)}
                    options={[
                      { id: '', name: t('dialogs.reportSection.chooseTag') },
                      ...(tagOptions || [])
                    ]}
                    required={true}
                    error={Boolean(errors.tag)}
                    helperText={errors.tag?.message || ''}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={watchType === 'tag' ? 5 : 6}>
            <Controller
              control={control} name="name"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.name')}
                  onChange={handleChange(field.onChange)}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={watchType === 'tag' ? 5 : 6}>
            <Controller
              control={control} name="variableName"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('dialogs.reportSection.variableName')}
                  onChange={handleChange(field.onChange)}
                  required={true}
                  error={Boolean(errors.variableName)}
                  helperText={errors.variableName?.message || ''}
                  inputRef={inputRef}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title={copied ? t('labels.copied') : t('labels.copyVariableName')}>
                        <IconButton
                          name="Copy variable name"
                          onClick={handleClick}
                        >{copied
                          ? <CheckIcon color="success" fontSize="small" />
                          : <FileCopyIcon />
                        }</IconButton>
                      </Tooltip>
                    )
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <FormHelperText error={Boolean(errors.variableName)}>
                  {t('dialogs.reportSection.variableHelperText1')}
                </FormHelperText>
                <FormHelperText error={Boolean(errors.variableName)}>
                  {t('dialogs.reportSection.variableHelperText2')}
                </FormHelperText>
              </Box>
              <FormHelperText>{watchVariableName.length}/50</FormHelperText>
            </Box>
          </Grid>
        </Grid>
        {watchType === 'freeText' ? (
          <Controller
            control={control} name="content"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Fragment>
                <QuillEditor
                  {...field}
                  id="report-section-quill-editor"
                  label={t('labels.content')}
                  required={true}
                  error={Boolean(errors.content)}
                  helperText={errors.content?.message || ''}
                  height={480}
                />
              </Fragment>
            )}
          />
        ) : null}
      </form>
    </Dialog>
  );
}

export default ReportSectionTemplateFormDialog;
