import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
import IUser, { IMyUser } from "app/models/User";
// Types
import Reducers from "app/types/Reducers";
import UserRoles from "app/types/UserRoles";
// Models
import { IPendingTerms } from "app/store/Terms/Terms.models";
// Async
import {
  updatePassword, resetPassword,
  getCurrentUser, updateCurrentUser, sendEmailChangeVerificationLink,
  getUsers, createUser, updateUser, deleteUser,
  importClientUser, enableUser, disableUser, updateUserMFA,
  acceptTermsAndConditions,
  sendUserEmailChangeVerificationLink
} from './Users.async';

interface IState {
  myUser: IMyUser | null;
  users: IUser[] | null;
  filter: {
    search: string;
    role: UserRoles | 'all';
    platform: string;
  };
  loading: boolean;
}

const initialState:IState = {
  myUser: null,
  users: null,
  filter: {
    search: '',
    role: 'all',
    platform: 'all'
  },
  loading: false
};

const slice = createSlice({
  name: Reducers.Users,
  initialState,
  reducers: {
    updateMyUserPermissions: (state, action:PayloadAction<IMyUser['permissions']>) => {
      if ( state.myUser ){
        state.myUser = { ...state.myUser, permissions: action.payload };
      }
    },
    setFilter: (state, action:PayloadAction<{ field:any, value:any }>) => {
      const { field, value } = action.payload;
      (state.filter as any)[field] = value;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Reset password
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    // Update password
    builder.addCase(updatePassword.pending, (state) => {
      state.loading = true;
    });
    // Get current user
    builder.addCase(getCurrentUser.pending, (state) => {
      state.myUser = null;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action:PayloadAction<IMyUser>) => {
      state.myUser = action.payload;
    });
    // Update current user
    builder.addCase(updateCurrentUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCurrentUser.fulfilled, (state, action:PayloadAction<IMyUser>) => {
      state.myUser = action.payload;
    });
    // Send email change verification link
    builder.addCase(sendEmailChangeVerificationLink.fulfilled, (state, action:PayloadAction<{ sentOn:string; expiresOn:string; }>) => {
      if ( state.myUser && state.myUser.emailChangeRequest ){
        state.myUser.emailChangeRequest = {
          ...state.myUser.emailChangeRequest,
          verificationLinkSentOn: action.payload.sentOn,
          verificationLinkExpiresOn: action.payload.expiresOn
        };
      }
    });
    // Get users
    builder.addCase(getUsers.pending, (state) => {
      state.users = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action:PayloadAction<IUser[]>) => {
      state.users = action.payload;
    });
    // Create user
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action:PayloadAction<IUser>) => {
      if ( state.users ){
        state.users = [...state.users, action.payload];
      }
    });
    // Update user
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action:PayloadAction<IUser>) => {
      if ( state.users ){
        state.users = state.users.map((user:IUser) => {
          if ( user.id === action.payload.id ) return action.payload;
          return user;
        });
      }
    });
    // Create user
    builder.addCase(importClientUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(importClientUser.fulfilled, (state, action:PayloadAction<IUser>) => {
      if ( state.users ){
        state.users = [...state.users, action.payload];
      }
    });
    // Delete user
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.users ){
        state.users = state.users.filter((user:IUser) => user.id !== action.payload)
      }
    });
    // Enable user
    builder.addCase(enableUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(enableUser.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.users ){
        state.users = state.users.map((user:IUser) => {
          if ( user.id === action.payload ) return {...user, enabled: true};
          return user;
        });
      }
    });
    // Disable user
    builder.addCase(disableUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disableUser.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.users ){
        state.users = state.users.map((user:IUser) => {
          if ( user.id === action.payload ) return {...user, enabled: false};
          return user;
        });
      }
    });
    // Update user mfa
    builder.addCase(updateUserMFA.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserMFA.fulfilled, (state, action:PayloadAction<IUser>) => {
      if ( state.users ){
        state.users = state.users.map((user:IUser) => {
          if ( user.id === action.payload.id ) return action.payload;
          return user;
        })
      }
    });
    // Accept terms and conditions
    builder.addCase(acceptTermsAndConditions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(acceptTermsAndConditions.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.myUser && state.myUser.pendingTermsAndConditions ){
        state.myUser.pendingTermsAndConditions = state.myUser.pendingTermsAndConditions
          .filter((pendingTerms:IPendingTerms) => pendingTerms.id !== action.payload);
      }
    });
    // Send user email change verification link
    builder.addCase(sendUserEmailChangeVerificationLink.fulfilled, (state, action:PayloadAction<{ id:number; sentOn:string; expiresOn:string; }>) => {
      if ( state.users ){
        const { id, sentOn, expiresOn } = action.payload;
        state.users = state.users.map((user:IUser) => {
          if ( user.id === id ) return {
            ...user,
            emailChangeRequest: user.emailChangeRequest ? {
              ...user.emailChangeRequest,
              verificationLinkSentOn: sentOn,
              verificationLinkExpiresOn: expiresOn
            } : undefined
          };
          return user;
        });
      }
    });

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    );
  }
});

export const UsersActions = slice.actions;

export default slice.reducer;