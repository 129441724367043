import { FC, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getTermsAndConditions } from 'app/store/Terms/Terms.async';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import TermsList from './TermsList';
import TermsFab from './TermsFab';

const TermsAndConditionsPage:FC = () => {
  const { t } = useTranslation();

   // Dispatch
   const dispatch = useAppDispatch();
 
   useEffect(() => {
      dispatch(getTermsAndConditions({}));
     // eslint-disable-next-line
   }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.termsAndConditions.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <TermsList />
        </Container>
      </Box>
      <TermsFab />
    </Fragment>
  )
}

export default TermsAndConditionsPage;
