import { FC, useState, useCallback, useEffect, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
// Models
import IUser from 'app/models/User';
// Mui
import { Alert, FormHelperText } from '@mui/material';
// Components
import { Button } from 'app/components/Mui';
// Utils
import { parseDateTimeToString } from 'app/utilities/DateTime';

const RESEND_DELAY = 30; // 30 seconds

type Props = {
  emailChangeRequest?: IUser['emailChangeRequest'];
  onResend: () => void;
};

const EmailVerificationHelperText:FC<Props> = ({
  // Props
  emailChangeRequest, onResend
}) => {
  const [ timeLeft, setTimeLeft ] = useState<number | null>(null);
  const [ expired, setExpired ] = useState(false);

  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Store interval reference

  // Function to start countdown
  const startCountdown = useCallback((sentTime:Dayjs) => {
    const now = dayjs();
    const cooldown = Math.max(RESEND_DELAY - now.diff(sentTime, 'second'), 0);
    setTimeLeft(cooldown > 0 ? cooldown : null);

    // Clear existing interval before starting a new one
    if ( intervalRef.current ) clearInterval(intervalRef.current);

    if ( cooldown > 0 ) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev === null || prev <= 1) {
            if (intervalRef.current) clearInterval(intervalRef.current); // Stop when reaching 0
            return null;
          }
          return prev - 1;
        });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if ( !emailChangeRequest ) return;

    const { verificationLinkSentOn, verificationLinkExpiresOn } = emailChangeRequest;
    const sentTime = dayjs(verificationLinkSentOn);
    const expireTime = dayjs(verificationLinkExpiresOn);
    const now = dayjs();

    setExpired(now.isAfter(expireTime));

    if ( sentTime.isAfter(now.subtract(RESEND_DELAY, 'second')) ){
      startCountdown(sentTime);
    }

    return () => {
      if ( intervalRef.current ) clearInterval(intervalRef.current);
    };
  }, [emailChangeRequest, startCountdown]);

  const handleClick = () => {
    if (timeLeft) return;
    onResend();
    startCountdown(dayjs());
  };

  if ( !emailChangeRequest ) return null;

  return (
    <Alert
      sx={{
        p: 3,
        my: 2,
        '& .MuiAlert-message': {
          padding: 0
        }
      }}
      severity="warning"
      icon={false}
    >
      <FormHelperText sx={{ mt: 0 }}>
        <span style={{ display: 'block' }}>Requested email change: <b>{emailChangeRequest.email}.</b></span>
        {!expired ? (
          <span style={{ display: 'block' }}>Email verification link sent at {parseDateTimeToString(emailChangeRequest.verificationLinkSentOn)}.</span>
        ) : (
          <span style={{ display: 'block' }}>Email verification link is expired.</span>
        )}
      </FormHelperText>
      <Button
        sx={{
          p: 0,
          textTransform: 'none',
          '&:hover': {
            bgcolor: 'transparent',
            textDecoration: 'underline'
          }
        }}
        name="Send verification email"
        disabled={timeLeft !== null}
        onClick={handleClick}
        variant="text"
        size="small"
      >
        {timeLeft !== null ? `Resend in ${timeLeft}s` : 'Resend verification email'}
      </Button>
    </Alert>
  );
};

export default EmailVerificationHelperText;
