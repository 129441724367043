import React from 'react';
import { useParams } from 'react-router-dom';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
// Models
import { IPage } from '@root/models/Page';
// State
import { useSelector } from 'react-redux';
// Selectors
import { selectColumnValue } from 'app/store/ui/uiSelectors';
import { selectFilteredDocumentPages } from 'app/store/page/page.selectors';
import { selectForceReload } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// MaterialUI
import { Box } from '@mui/material';
// Components
import PageCard from '@root/components/PageCard';
import { Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
import { CARD_WIDTH, CARD_HEIGHT } from 'app/App.constants';

let timeout:any;

type Props = {
  listRef: any;
};

const FilesList:React.FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();

  const { documentId } = useParams<{ documentId:string }>();
  // State
  const columnValue:string = useSelector(selectColumnValue);
  const pages:IPage[] | null = useSelector((state:any) => selectFilteredDocumentPages(state, {
    documentId: Number(documentId)
  }));
  const forceReload = useSelector(selectForceReload);

  React.useLayoutEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const pageNum = urlSearchParams.get('pageNum');
    if ( pageNum ){
      timeout = setTimeout(() => {
        if ( listRef.current && pages ){
          const foundedPageIndex = pages.findIndex((page:any) => page.pageNum === Number(pageNum));
          if ( typeof foundedPageIndex !== 'undefined' ){
            const width = listRef.current.props.width;
            const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
            const rowIndex = Math.floor(foundedPageIndex/itemsPerRow)
            listRef.current.scrollToItem(rowIndex, 'center');
          }
        }
      }, 2000)
    }
    return () => {
      clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, []);

  if ( !pages ) return <Loader />
  if ( !pages.length ) return <Message text={t('labels.noPages')} />;
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
          <AutoSizer>
            {({ width, height }:any) => {
              const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
              const itemCount = Math.ceil(pages.length / itemsPerRow);
              const itemSize = columnValue === 'auto' ? CARD_HEIGHT : width/Number(columnValue)*3/2;

              return (
                <List
                  ref={listRef}
                  width={width}
                  height={height}
                  itemCount={itemCount}
                  itemSize={itemSize}
                  className="scrollListContainer"
                  initialScrollOffset={0}

                  itemData={{
                    itemsPerRow,
                    pages,
                    itemSize,
                    forceReload
                  }}
                >
                  {Row}
                </List>
              )
            }}
          </AutoSizer>
        </Box>
      </Box>
    </Box>
  )
}

export default FilesList;

const Row = React.memo(({ index, style, data:{ itemsPerRow, pages, itemSize, forceReload } }:any) => {
  const items = [];
  const fromIndex = index * itemsPerRow;
  const toIndex = Math.min(fromIndex + itemsPerRow, pages.length);
  for ( let i = fromIndex; i < toIndex; i++ ){
    const page = pages[i];
    items.push(
      <div key={`page-item-${page.documentId}-${page.pageNum}-${forceReload}`} className="card-page-item" style={{ width: `${(100/itemsPerRow).toFixed(2)}%` }}>
        <PageCard
          scrollContainer=".scrollListContainer"
          documentId={page.documentId}
          pageNum={page.pageNum}
          height={itemSize - 16}
        />
      </div>
    );
  }
  return (
    <div
      className="card-page-container"
      style={{ ...style }}
    >{items}</div>
  )
}, areEqual)
