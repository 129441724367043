import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createSubscription, updateSubscription } from 'app/store/Subscriptions/Subscriptions.async';
// Selectors
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectPackagesAsOptions } from 'app/store/BillingPackages/BillingPackages.selectors';
import { selectParams } from 'app/store/Subscriptions/Subscriptions.selectors';
// Mui
import { Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';

type Props = {
  open: boolean;
  onClose: () => void;
  subscriptionId?: number;
};

const SubscriptionPackageDialog:FC<Props> = ({
  // Props
  open, onClose, subscriptionId
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const packageOptions = useAppSelector(selectPackagesAsOptions);
  const params = useAppSelector(selectParams);

  const [ loading, setLoading ] = useState(false);
  const [ data, setData ] = useState({
    accountId: params.accountId || '',
    subscriptionPackageId: ''
  });

  const handleChange = (field:keyof typeof data) => (value:any) => {
    setData((prevState) => ({
      ...prevState,
      [field]: value
    }));
  }

  const handleClick = async () => {
    setLoading(true);
    try {
      if ( subscriptionId ){
        await dispatch(updateSubscription({ id: subscriptionId, data: {
          subscriptionPackageId: Number(data.subscriptionPackageId)
        }})).unwrap();
      } else {
        await dispatch(createSubscription(Object.fromEntries(
          Object.entries(data).map(([key, value]) => [key, Number(value)])
        ))).unwrap();
      }
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const actions = (
    <Fragment>
      <Button
        name="Close subscription package dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name={`Submit ${subscriptionId ? 'update' : 'create'} subscription package dialog`}
        disabled={false}
        onClick={handleClick}
        loading={loading}
        variant="contained"
        color="primary"
      >{t('buttons.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={subscriptionId ? t('pages.subscriptions.updatedFormTitle') : t('pages.subscriptions.createFormTitle')}
      actions={actions}
    >
      {!subscriptionId ? (
        <Autocomplete
          value={data.accountId}
          onChange={handleChange('accountId')}
          options={accountOptions || []}
          TextFieldProps={{
            label: t('labels.companyAccount')
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
              <Typography variant="subtitle1">{option.label}</Typography>
              {option.props.address ? (
                <Typography variant="caption">{option.props.address}</Typography>
              ) : null}
            </li>
          )}
        />
      ) : null}
      <Autocomplete
        value={data.subscriptionPackageId}
        onChange={handleChange('subscriptionPackageId')}
        options={packageOptions || []}
        TextFieldProps={{
          label: t('labels.subscriptionPackage')
        }}
      />
    </Dialog>
  );
}

export default SubscriptionPackageDialog;
