import { useCallback } from 'react';
// Models
import { IValidateError, ValidateErrorCodes } from 'app/store/DMSBatches/DMSBatches.models';
// Constants
import { MAX_FILE_NAME_LENGTH, MAX_FILE_SIZE } from 'app/App.constants';
// 
import { IFileItem } from './useUploadContext';

export const VALIDATION_ERRORS = {
  maxSize: {
    code: 'maxSize',
    message: 'Document exceeds max document size'
  },
  maxNameLength: {
    code: 'maxNameLength',
    message: `The document name is too long. Rename the document with less than ${MAX_FILE_NAME_LENGTH} characters`,
  },
  duplicateName: {
    code: 'duplicateName',
    message: `Document(s) with the same name were found in your upload`
  },
  duplicateContent: {
    code: 'duplicateContent',
    message: `Document(s) with the same content were found in your upload.`
  },
  nameExists: {
    code: 'nameExists',
    message: 'Document with the same name already exists'
  },
  contentExists: {
    code: 'contentExists',
    message: 'Document with the same content already exists'
  },
  other: {
    code: 'other',
    message: 'An error occurred while validating the document'
  }
};

const useFileValidations = () => {
  const getDuplicateFileNameError = useCallback((
    fileItems: IFileItem[],
    currentFileItem:IFileItem
  ):IValidateError | null => {
    // Count occurrences of the file name
    const duplicates = fileItems.filter(
      file => file.id !== currentFileItem.id && file.file.name === currentFileItem.file.name
    );
    if ( duplicates.length === 0 ) return null;
    return {
      references: duplicates.map(file => file.id),
      errorCode: ValidateErrorCodes.DuplicateName,
      errorMessage: VALIDATION_ERRORS.duplicateName.message
    };
  }, []);

  const getMaxFileSizeError = useCallback((
    fileSize: number
  ):IValidateError | null => {
    if ( fileSize <= MAX_FILE_SIZE ) return null;
    return {
      errorCode: ValidateErrorCodes.MaxSize,
      errorMessage: VALIDATION_ERRORS.maxSize.message
    };
  }, []);

  const getMaxFileNameLengthError = useCallback((
    fileName: string
  ): IValidateError | null =>  {
    if ( fileName.length <= MAX_FILE_NAME_LENGTH ) return null;
    return {
      errorCode: ValidateErrorCodes.MaxNameLength,
      errorMessage: VALIDATION_ERRORS.maxNameLength.message
    };
  }, []);

  return { getDuplicateFileNameError, getMaxFileSizeError, getMaxFileNameLengthError };
};

export default useFileValidations;
