import { FC, useState } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { toggleFilesAdditionalTools } from 'app/store/ui/uiSlice';
// Selectors
import { selectShowFilesAdditionalTools, selectAdditionalToolsHidden } from 'app/store/ui/uiSelectors';
// Mui
import { Paper, Box, Toolbar, IconButton, Typography, Tooltip } from '@mui/material';
// Icons
import {
  Settings as SettingsIcon,
  Close as CloseIcon
} from '@mui/icons-material';
// Components
import ColumnSwitcher from 'app/components/ColumnSwitcher';
// 
import ToolsSettings from './ToolsSettings';
import Tags from './Tags';
import Colors from './Colors';
import HaveDuplicateInWorkspace from './HaveDuplicateInWorkspace';
import GoToPage from './GoToPage';
import GoToLastOpenedPage from './GoToLastOpenedPage';
// i18next
import { useTranslation } from 'react-i18next';
import AdditionalToolsNames from 'app/types/AdditionalToolsNames';

type Props = {
  listRef: any;
}

const AdditionalTools:FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const show:boolean = useAppSelector(selectShowFilesAdditionalTools);
  const tools = useAppSelector((state:RootState) => selectAdditionalToolsHidden(state, {
    toolsType: 'files'
  }));

  const [ open, setOpen ] = useState(false);

  const handleClose = () => {
    dispatch(toggleFilesAdditionalTools());
  }

  const toggleOpen = () => setOpen(!open);

  if ( !show ) return null;
  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Paper
        sx={{
          width: 360,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          borderTop: 'none',
          borderBottom: 'none'
        }}
        variant="outlined"
      >
        <Toolbar sx={{ gap: 4 }}>
          <Tooltip title={t('labels.toolsSettings')}>
            <IconButton
              onClick={toggleOpen}
            ><SettingsIcon /></IconButton>
          </Tooltip>
          <Typography sx={{ flexGrow: 1 }}>{t('labels.additionalTools')}</Typography>
          <Tooltip title={t('buttons.close')}>
            <IconButton
              onClick={handleClose}
            ><CloseIcon /></IconButton>
          </Tooltip>
        </Toolbar>
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 4,
          overflowY: 'auto'
        }}>
          {tools.includes(AdditionalToolsNames.GoToPage) ? (
            <GoToPage listRef={listRef} />
          ) : null}
          {tools.includes(AdditionalToolsNames.GoToLastOpenedPage) ? (
            <GoToLastOpenedPage listRef={listRef} />
          ) : null}
          {tools.includes(AdditionalToolsNames.NumberOfColumns) ? (
            <Box>
              <ColumnSwitcher />
            </Box>
          ) : null}
          {tools.includes(AdditionalToolsNames.Tags) ? (
            <Box sx={{ mb: 2 }}><Tags /></Box>
          ) : null}
          {tools.includes(AdditionalToolsNames.Colors) ? (
            <Box sx={{ mb: 2 }}><Colors /></Box>
          ) : null}
          {tools.includes(AdditionalToolsNames.HaveDuplicateInWorkspace) ? (
            <HaveDuplicateInWorkspace />
          ) : null}
        </Box>
      </Paper>
      {open ? (
        <ToolsSettings onClose={toggleOpen} />
      ) : null}
    </Box>
  )
}

export default AdditionalTools;
