import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
// Models
import { AppDispatch } from 'app/store';
// Async
import { executeInstantWorkflow } from 'app/store/AutomatedWorkflowsExecutions/AutomatedWorkflowsExecutions.async';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Services
import Mixpanel from "app/services/Mixpanel.service";
import MixpanelTracks from 'app/types/MixpanelTracks';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import Reducers from 'app/types/Reducers';

const AutomatedWorkflowsExecutionsMiddleware:Middleware = ({ getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( (type as string).startsWith(Reducers.AutomatedWorkflowsExecutions) ){
    const state = getState();
    const currenAccount = selectCurrentAccount(state);
    const myUser = selectMyUser(state);
    const insuranceCase = selectInsuranceCase(state);

    if ( type === executeInstantWorkflow.fulfilled.type ){
      Mixpanel.track(MixpanelTracks.InstantWorkflowRun, {
        accountId: currenAccount.id,
        caseId: insuranceCase?.id,
        userId: myUser?.id,
        workflowId: payload.workflow.id,
      });
    }
  }
  return next(action);
}

export default AutomatedWorkflowsExecutionsMiddleware;