import { FC, Fragment } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { RootState } from 'app/store';
import { IMyUser } from 'app/models/User';
import { IPresenterPage } from 'app/models/Page';
import IEpisode from 'app/models/Episode';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectEpisodeEntity } from 'app/store/Episodes/Episodes.selectors';
// Hooks
import useFormattedDate from 'app/hooks/useFormattedDate';
import useFormattedTime from 'app/hooks/useFormattedTime';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  page: IPresenterPage;
}

const CardPageHeadDate:FC<Props> = ({
  // Props
  page
}) => {
  const { t } = useTranslation();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const episode:IEpisode | undefined = useAppSelector((state:RootState) => selectEpisodeEntity(state, {
    episodeId: page.parentEpisodeId
  }));

  const formattedDate = useFormattedDate(episode?.date);
  const formattedTime = useFormattedTime(episode?.time);

  const isMyRoleClientOrPresenter = myUser?.role === UserRoles.Client || myUser?.role === UserRoles.Presenter;

  if ( page.staple && page.staple.order > 1 ) return null;
  if ( ( !episode || !episode.date ) && isMyRoleClientOrPresenter ) return null;
  return (
    <Fragment>
      <div className="mb-1">
        {formattedDate ? (
          <span className="cp-text">{formattedDate}{formattedTime ? ` ${formattedTime}` : ''}</span>
        ) : (
          <span className="cp-text">{t('components.cardPage.setDate')}</span>
        )}
      </div>
    </Fragment>
  )
}

export default CardPageHeadDate;