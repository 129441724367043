import { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Models
import IEpisodeAuthor from 'app/models/EpisodeAuthor';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { checkAuthors, updateAuthor } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// Selectors
import { selectLoading } from 'app/store/EpisodeAuthors/EpisodeAuthors.selectors';
// Mui
import { FormGroup, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Switch, Button, LoadingButton } from 'app/components/Mui';
// Hooks
import useDebounce from 'app/hooks/useDebounce';
// Utilities
import { isRequired } from 'app/utilities/Validations';
import { trimValue } from 'app/utilities/Utilities';
// 
import EpisodeTypes from './EpisodeTypes';
// i18next
import { Trans, useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
  verified: boolean;
  organization: boolean;
}

type Props = {
  open: boolean;
  onClose: () => void;
  author: IEpisodeAuthor;
}

const AuthorRegistryFormDialog:React.FC<Props> = ({
  // Props
  open, onClose, author
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const [ episodeTypes, setEpisodeTypes ] = useState(author.episodeTypes || []);
  const [ auhtorsNames, setAuthorsNames ] = useState<string | null>(null);

  const { control, handleSubmit, formState: { errors }, watch } = useForm<IFormData>({
    defaultValues: {
      name: author.name || '',
      verified: author.verified || false,
      organization: author.organization || false
    }
  });

  const watchName = watch('name');

  const debounceName = useDebounce(watchName);

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      await dispatch(updateAuthor({
        authorId: author.id,
        data: {
          ...data,
          name: trimValue(data.name),
          episodeTypes
        }
      })).unwrap();
      onClose();
    } catch(error){}
  });

  useEffect(() => {
    const asyncCheckAuthors = async () => {
      setAuthorsNames(null);
      try {
        const authors = await dispatch(checkAuthors({ name: debounceName })).unwrap();

        if ( !authors.length ) return;

        setAuthorsNames(authors.map((author:IEpisodeAuthor) => author.name).join(', '));
      } catch(error){}
    }

    if ( author.name !== debounceName ) asyncCheckAuthors();
    // eslint-disable-next-line
  }, [debounceName]);

  const actions = (
    <Fragment>
      <Button
        name="Cancel episode author dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Update episode author dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.update')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.authorRegistry.editEpisodeAuthorTitle')}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      {/* Name */}
      <Controller
        control={control} name="name"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.name')}
            error={Boolean(errors.name)}
            helperText={errors.name?.message || ''}
            required
          />
        )}
      />
      {auhtorsNames ? (
        <FormHelperText sx={{
          color: (theme:any) => theme.palette.warning.main,
          fontSize: 14
        }}>
          <Trans
            t={t}
            i18nKey="form.helperText.authorAlreadyExists"
            values={{ auhtorsNames }}
            components={{ b: <b /> }}
          />
        </FormHelperText>
      ) : null}
      <FormGroup>
        <Controller
          control={control}
          name="verified"
          render={({ field:{ onChange, ...otherField }}) => (
            <Switch
              {...otherField}
              label={t('labels.verified')}
              onChange={(_, checked:boolean) => onChange(checked)}
            />
          )}
        />
        <Controller
          control={control}
          name="organization"
          render={({ field:{ onChange, ...otherField }}) => (
            <Switch
              {...otherField}
              label={t('labels.organization')}
              onChange={(_, checked:boolean) => onChange(checked)}
            />
          )}
        />
      </FormGroup>
      <EpisodeTypes
        episodeTypes={episodeTypes}
        onSetEpisodeTypes={setEpisodeTypes}
      />
    </Dialog>
  )
}

export default AuthorRegistryFormDialog;
