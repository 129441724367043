import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.order') }),
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.order') }),
  Complete: i18n.t('notifications.billingOrders.complete'),
  Cancel: i18n.t('notifications.billingOrders.cancel')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.order') });
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.order') });
  Messages.Complete = i18n.t('notifications.billingOrders.complete');
  Messages.Cancel = i18n.t('notifications.billingOrders.cancel');
});