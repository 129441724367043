import { forwardRef, memo } from 'react';
import { FixedSizeList as List, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectColumnValue } from 'app/store/ui/uiSelectors';
import { selectFilteredWorkspacePrimaryPagesIdsByPreEvent } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import CardPage from 'app/components/CardPage';
import CardPageSeparator from 'app/components/CardPageSeparator';
import { Loader, Message } from 'app/components/Utilities';
// Constants
import { CARD_WIDTH, CARD_HEIGHT } from 'app/App.constants';
// i18next
import { useTranslation } from 'react-i18next';

const PagesList = forwardRef<any>((_, ref) => {
  const { t } = useTranslation();
  // State
  const columnValue:string = useAppSelector(selectColumnValue);
  const pageIds:string[] | null = useAppSelector(selectFilteredWorkspacePrimaryPagesIdsByPreEvent);

  if ( !pageIds ) return <Loader />;
  if ( !pageIds.length ) return <Message text={t('labels.noPages')} />;
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>

        <AutoSizer>
          {({ width, height }:any) => {
            const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
            const itemCount = Math.ceil(pageIds.length / itemsPerRow);
            const itemSize = columnValue === 'auto' ? CARD_HEIGHT : CARD_HEIGHT + (CARD_HEIGHT / Number(columnValue))

            return (
              <List
                ref={ref}
                width={width}
                height={height}
                itemCount={itemCount}
                itemSize={itemSize}
                initialScrollOffset={0}
                className="react_scrollPageList"
                itemData={{
                  itemsPerRow,
                  pageIds
                }}
              >{Row}</List>
            )
          }}
        </AutoSizer>

      </Box>
    </Box>
  )
})

export default PagesList;

const Row = memo(({ index, style, data:{ itemsPerRow, pageIds } }:any) => {
  const { t } = useTranslation();
  const items:any[] = [];
  const fromIndex = index * itemsPerRow;
  const toIndex = Math.min(fromIndex + itemsPerRow, pageIds.length);
  const styleWidth = `${(100/itemsPerRow).toFixed(2)}%`;

  let negative = 0;

  const pageSeperatorLabel:Record<string, string> = {
    "pre-event": t('labels.preEvent'),
    "post-event": t('labels.postEvent'),
  }

  for ( let i = fromIndex; i < toIndex; i++ ){
    const pageId = pageIds[i];

    if ( pageId === 'pre-event' || pageId === 'post-event' ){
      negative--;
      items.push(
        <CardPageSeparator
          key={i}
          label={pageSeperatorLabel[pageId]}
          width={styleWidth}
        />
      )
    } else {
      items.push(
        <div key={i} className="card-page-item" style={{ width: styleWidth }}>
          <CardPage
            pageId={pageId}
            index={i + negative}
          />
        </div>
      );
    }
  }
  return (
    <div
      className="card-page-container"
      style={{ ...style }}
    >{items}</div>
  )
}, areEqual);
