import dayjs from "dayjs";

export const sortByCreationDate = (a:any, b:any, sort:'asc'|'desc' = 'desc') => {
  const aTime = new Date(a.createdOn).getTime();
  const bTime = new Date(b.createdOn).getTime();
  return sort === 'desc' ? bTime - aTime : aTime - bTime;
}

export const sortByName = <T>(a:T, b:T, field:keyof T, sort:'asc'|'desc' = 'asc') => {
  const aName = (a[field] as string).trim().toLowerCase();
  const bName = (b[field] as string).trim().toLowerCase();
  return aName === bName
    ? 0
    : sort === 'asc'
      ? aName < bName ? -1 : 1
      : aName < bName ? 1 : -1
  ;
}

export const sortCalendarDataByTime = (a:any, b:any) => {
  if (!a.time) return -1;
  if (!b.time) return 1;
  
  const [ aHour, aMinutes] = a.time.split(':').map(Number);
  const [ bHour, bMinutes] = b.time.split(':').map(Number);
  const aTime = dayjs().hour(aHour).minute(aMinutes);
  const bTime = dayjs().hour(bHour).minute(bMinutes);
  return aTime.isBefore(bTime) ? -1 : 1;
}
