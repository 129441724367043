import i18n from "i18n";

const Messages = {
  ProfileUpdate: i18n.t('notifications.common.update', { dataType: i18n.t('labels.profile') }),
  ResetPassword: i18n.t('notifications.users.resetPassword'),
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.user') }),
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.user') }),
  Import: i18n.t('notifications.users.import'),
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.user') }),
  AuthenticationEnabled: i18n.t('notifications.users.2FAuthenticationEnabled'),
  AuthenticationDisabled: i18n.t('notifications.users.2FAuthenticationDisabled'),
  Enable: i18n.t('notifications.users.enable'),
  Disable: i18n.t('notifications.users.disable'),
  SendEmailChangeVerificationLink: i18n.t('notifications.users.sendEmailChangeVerificationLink'),
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.ProfileUpdate = i18n.t('notifications.common.update', { dataType: i18n.t('labels.profile') });
  Messages.ResetPassword = i18n.t('notifications.users.resetPassword');
  Messages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.user') });
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.user') });
  Messages.Import = i18n.t('notifications.users.import');
  Messages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.user') });
  Messages.AuthenticationEnabled = i18n.t('notifications.users.2FAuthenticationEnabled');
  Messages.AuthenticationDisabled = i18n.t('notifications.users.2FAuthenticationDisabled');
  Messages.Enable = i18n.t('notifications.users.enable');
  Messages.Disable = i18n.t('notifications.users.disable');
  Messages.SendEmailChangeVerificationLink = i18n.t('notifications.users.sendEmailChangeVerificationLink');
});