import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
// Constants
import { LANGUAGES } from 'app/App.constants';

export const selectTrialMode = (state:RootState) => state[Reducers.AppUi].trialMode;
export const selectWorkspacePages = (state:RootState) => state[Reducers.AppUi].workspacePageIds;
export const selectCalendarDates = (state:RootState) => state[Reducers.AppUi].calendarDates;

export const selectRequestedUrl = (state:RootState) => state[Reducers.AppUi].requestedUrl;
export const selectPagesInRow = (state:RootState) => state[Reducers.AppUi].pagesInRow;
export const selectGridResizerWidth = (state:RootState) => state[Reducers.AppUi].gridResizerWidth;

export const selectAvailableLanguages = (state:RootState) => state[Reducers.AppUi].availableLanguages;
export const selectLanguage = (state:RootState) => state[Reducers.AppUi].language;

export const selectFilesSidebarOpen = (state:RootState) => state[Reducers.AppUi].filesSidebarOpen;
export const selectAIChatSidebarOpen = (state:RootState) => state[Reducers.AppUi].aiChatSidebarOpen;

export const selectSearchSidebarOpen = (state:RootState) => state[Reducers.AppUi].searchSidebarOpen;

export const selectInvoicesSidebarOpen = (state:RootState) => state[Reducers.AppUi].invoicesSidebarOpen;

export const selectLabellingToolsSidebarOpen = (state:RootState) => state[Reducers.AppUi].labellingToolsSidebarOpen;

export const selectSelectedPageIds = (state:RootState) => state[Reducers.AppUi].selectedPageIds;

export const selectPreviewPageOpen = (state:RootState) => state[Reducers.AppUi].previewPageOpen;
export const selectPreviewPage = (state:RootState) => state[Reducers.AppUi].previewPage;

export const selectAvailableLanguageAsOptions = createSelector(
  [ selectAvailableLanguages ],
  ( languages:string[] ) => {
    return languages.map((language:string) => ({
      id: language,
      name: (LANGUAGES as Record<string, string>)[language]
    }))
  }
);

export const selectWorkspacePageId = createSelector(
  [
    selectWorkspacePages,
    (_, props:{ caseId:number }) => props
  ],
  ( workspacePages:Record<number, string>, { caseId } ) => {
    return workspacePages[caseId] || null;
  }
);

export const selectCalendarDate = createSelector(
  [
    selectCalendarDates,
    (_, props:{ caseId:number }) => props
  ],
  ( calendarDates:Record<number, string>, { caseId } ) => {
    return calendarDates[caseId] || null;
  }
);

export const selectIsPageIdInSelected = createSelector(
  [
    selectSelectedPageIds,
    (_, props:{ pageId:string }) => props
  ],
  ( selectedPageIds:string[], { pageId } ) =>  selectedPageIds.includes(pageId)
);
