import { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { replace } from "connected-react-router";
import { setUser } from '@sentry/react';
// Types
import BodyErrors from 'app/types/BodyErrors';
import MixpanelTracks from "app/types/MixpanelTracks";
// Models
import { AppDispatch } from 'app/store';
// Async
import { login, logout, switchAccount } from 'app/store/Auth/Auth.async';
import { getCurrentUser } from 'app/store/Users/Users.async';
// Actions
import { AccountsActions } from 'app/store/Accounts/Accounts.slice';
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import { AuthActions } from 'app/store/Auth/Auth.slice';
import { UsersActions } from 'app/store/Users/Users.slice';
import { PresenterActions } from 'app/store/Presenter/Presenter.slice';
// Services
import IdempotencyKeyService from 'app/services/IdempotencyKey.service';
import WebSocketService from 'app/services/WebSocketService';
import LocalStorageService from 'app/services/LocalStorage.service';
import CookieService from 'app/services/Cookie.service';
// Utiliies
import { sortByName } from "app/utilities/SortBy";
import { parseToken } from 'app/utilities/Utilities';
// 
import Mixpanel from 'app/services/Mixpanel.service';

// ToDO: To pass data between `pending` and `rejected` action.type
let loginData:any = null;

const AuthMiddleware:Middleware = ({ dispatch }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload, meta } = action;

  // Login user
  if ( type === login.pending.type ){
    loginData = {...(loginData || {}), ...meta.arg};
  }
  if ( type === login.fulfilled.type ){
    loginData = null;

    CookieService.clearCookie();

    handleLoginFulfilled(dispatch, payload);
  }
  if ( type === login.rejected.type ){
    if ( !loginData ) throw Error('Canno`t find login data or object is empty');

    const { username, password, accountId } = loginData;

    if ( !payload || !payload.error ) return next(action);

    if ( payload.error === BodyErrors.MfaRequired ){
      const { id:challengeId, ...otherBody } = payload.challenge;
      const nextData = { ...otherBody, challengeId, username, password };
      dispatch(AuthActions.setMultiFactoryData(nextData));
      dispatch(replace('/verification'));
      // return next(action);
    } else if ( payload.error === BodyErrors.AccountRequired ){
      const { accounts, message } = payload;
      dispatch(AuthActions.setMultiAccountsData({
        accounts: [...accounts].sort((a, b) => sortByName(a, b, 'company')),
        message
      }));
      // return next(action);
    } else if ( payload.error === BodyErrors.PasswordChangeRequired ){
      const { passwordChangeToken } = payload;
      const searchParams = new URLSearchParams();
      searchParams.append('token', passwordChangeToken);
      searchParams.append('email', username);
      if ( accountId ) searchParams.append('accountId', accountId);
      dispatch(replace(`/reset-password?${searchParams}`));
      // return next(action);
    }
  }

  // Switch account
  if ( type === switchAccount.fulfilled.type ){
    WebSocketService.close();

    handleStateClear(dispatch);

    LocalStorageService.setAuthCredential(payload);

    dispatch(replace('/'));

    handleLoginFulfilled(dispatch, payload);
  }

  // Logout user
  if ( type === logout.fulfilled.type ){
    handleStateClear(dispatch);

    Mixpanel.reset();
    WebSocketService.close();
    IdempotencyKeyService.clearDuplicateKeys();

    LocalStorageService.clearAuthCredential();

    setUser(null);
  }

  return next(action);
}

export default AuthMiddleware;

const handleLoginFulfilled = (dispatch:AppDispatch, payload:any) => {
  LocalStorageService.setAuthCredential(payload);

  // Parse tokenAccess token, to get userId and pass it before login
  const tokenData = parseToken(payload.accessToken);
  const userId = tokenData.sub ? Number(tokenData.sub) : null;

  if ( userId ) Mixpanel.identify(userId);
  Mixpanel.track(MixpanelTracks.LogIn);

  dispatch(getCurrentUser({}));
}

const handleStateClear = (dispatch:AppDispatch) => {
  dispatch(AppUiNotificationsActions.resetState());
  dispatch(AppUIActions.resetState());
  dispatch(AuthActions.resetState());
  dispatch(AccountsActions.resetState());
  dispatch(UsersActions.resetState());
  dispatch(PresenterActions.resetState());
}
