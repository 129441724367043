import { FC, useState, useEffect, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { IMyUser } from 'app/models/User';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteIntegration, getQuickbooksOAuth } from 'app/store/Integrations/Integrations.async';
import { selectIntegrations, selectLoading } from 'app/store/Integrations/Integrations.selectors';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Button, Collapse, IconButton, Alert } from '@mui/material';
// Icon
import { Close as CloseIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';

const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=640,height=640,left=20,top=20`;

const InvoicesQuckbooks:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const integrations = useAppSelector(selectIntegrations);
  const loading = useAppSelector(selectLoading)

  const { open, toggle } = useToggle();

  const [ showAlertConnected, setShowAlertConnected ] = useState(false);
  const [ showAlertDisconnected, setShowAlertDisconnected ] = useState(false);

  const integration = useMemo(() => {
    if ( !integrations ) return null;
    return integrations[0];
  }, [integrations]);

  useEffect(() => {
    if ( !integration ) return;

    if ( integration.status === 'connected' ){
      setShowAlertConnected(true);
    }
    if ( integration.status === 'disconnected' ){
      setShowAlertDisconnected(true);
    }
    // eslint-disable-next-line
  }, [integration]);

  const handleQuickbooksOAuth = async () => {
    try {
      const response = await dispatch(getQuickbooksOAuth({})).unwrap();;
      window.open(response.oauth2Url, 'quickBooksOAuth', params);
    } catch(error){}
  }
  const handleQuickbooksOAuthReconnect = async () => {
    if ( !integration ) return;
    try {
      const response = await dispatch(getQuickbooksOAuth({ integrationId: integration.id })).unwrap();
      window.open(response.oauth2Url, 'quickBooksOAuth', params);
    } catch(error){}
  }

  const handleConfirm = () => {
    if ( !integration ) return;
    dispatch(deleteIntegration(integration.id))
  }

  if ( myUser?.role !== 'admin' && myUser?.role !== 'am' ) return null;
  if ( integrations ){
    if ( integrations.length === 0 ){
      return (
        <div style={{ textAlign: 'right' }}>
          <button
            className="btn-quickbooks"
            type="button"
            onClick={handleQuickbooksOAuth}
          ></button>
        </div>
      )
    } else {
      return integration.status === 'disconnected' ? (
        <Collapse in={showAlertDisconnected}>
          <Alert
            severity="warning"
            action={
              [
                <Button
                  key="details"
                  color="inherit"
                  size="small"
                  onClick={handleQuickbooksOAuthReconnect}
                >{t('pages.invoices.reconnectButton')}</Button>,
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setShowAlertDisconnected(false)}
                ><CloseIcon fontSize="inherit" /></IconButton>
              ]
            }
          >{integration.name} {t('pages.invoices.authenticationExpired')}</Alert>
        </Collapse>
      ) : (
        <Fragment>
          <Collapse in={showAlertConnected}>
            <Alert
              severity="success"
              action={
                [
                  <Button
                    key="details"
                    color="inherit"
                    size="small"
                    onClick={toggle}
                  >{t('pages.invoices.deleteConnectionButton')}</Button>,
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setShowAlertConnected(false)}
                  ><CloseIcon fontSize="inherit" /></IconButton>
                ]
              }
            >{t('pages.invoices.connectedTo')} {integration.name}</Alert>
          </Collapse>
          {open ? (
            <ConfirmationDialog
              loading={loading}
              open={open}
              onClose={toggle}
              title={t('pages.invoices.deleteConnectionTitle')}
              content={t('pages.invoices.deleteConnectionConfirmation')}
              onConfirm={handleConfirm}
            />
          ) : null}
        </Fragment>
      )
    }
  } 
  return null;
}

export default InvoicesQuckbooks;
