import { ChangeEvent, FC, Fragment, useState } from 'react';
// Models
import ILabel from 'app/models/Label';
// Types
import Colors from 'app/types/Colors';
import Labels from 'app/components/Labels';
// Mui
import { Box } from '@mui/material';
// Components
import ColorPicker from 'app/components/ColorPicker';
import { Input, Button } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  labels: ILabel[];
  size?: 'small' | 'medium';
  onUpdate: (nextLabels:ILabel[]) => void;
};

const LabelsForm:FC<Props> = ({
  // Props
  labels, size = 'medium', onUpdate
}) => {
  const { t } = useTranslation();

  const [ name, setName ] = useState('');
  const [ color, setColor ] = useState<Colors|string>(Colors.Red);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleAddLabel = () => {
    if ( !name || !color ) return;

    const label = { name, color };

    onUpdate([...labels, label])
    setName('');
    setColor(Colors.Red);
  }

  const handleRemoveLabel = (index:number) => {
    onUpdate(labels.filter((_:any, idx) => idx !== index));
  }

  const handleClickColor = (nextColor:Colors|string) => setColor(nextColor);

  return (
    <Fragment>
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <Box flexGrow={1}>
          <Input
            label={t('labels.name')} value={name}
            onChange={handleChange}
            margin="none"
            size={size}
          />
        </Box>
        <Box sx={{ pt: size === 'small' ? 0.5 : 2.5 }}>
          <Button
            name="Add label"
            disabled={!name}
            onClick={handleAddLabel}
            variant="contained"
            color="primary"
          >{t('buttons.add')}</Button>
        </Box>
      </Box>
      <Box sx={{ mb: 4 }}>
        <ColorPicker
          id=""
          activeColors={[color]}
          onClick={handleClickColor}
        />
      </Box>
      <Labels
        id=""
        labels={labels}
        onRemove={handleRemoveLabel}
      />
    </Fragment>
  )
}

export default LabelsForm;