import { ChangeEvent, FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IEpisodeFact from 'app/models/EpisodeFact';
// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks/useStore';
// Actions
import { EpisodeFactsActions } from 'app/store/EpisodeFacts/EpisodeFacts.slice';
// Async
import { createFact, updateFact, deleteFact } from 'app/store/EpisodeFacts/EpisodeFacts.async';
// Selectors
import { selectCaseMedicareRate } from 'app/store/Cases/Cases.selectors';
import { selectIsFactEditable } from 'app/store/EpisodeFacts/EpisodeFacts.selectors';
// Mui
import { Box, Paper, InputAdornment, Typography } from '@mui/material';
// Components
import { Button, Input } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Utilities
import { toNumberWithCommas } from 'app/utilities/Utilities';

type Props = {
  fact: IEpisodeFact;
}

const FactItem:FC<Props> = ({
  // Props
  fact
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const caseMedicareRate:number = useAppSelector(selectCaseMedicareRate);
  const isFactEditable = useAppSelector((state) => selectIsFactEditable(state, { factId: fact.id }));

  const [ price, setPrice ] = useState<number | string>(fact.data.price);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  }

  const handleCancelClick = () => {
    setPrice(fact.data.price);
    dispatch(EpisodeFactsActions.setFactNotEditable(fact.id));
  }

  const handleSave = () => {
    const { id, ...otherFact } = fact;
    const data = {
      ...otherFact,
      data: {
        ...otherFact.data,
        price: Number(price)
      }
    };
    if ( fact.episodeId < 0 ){
      dispatch(EpisodeFactsActions.updateFact({ ...data, createdOn: new Date(), id }));
      dispatch(EpisodeFactsActions.setFactNotEditable(id));
    } else {
      if ( id < 0 ){
        asyncCreateFact(data);
      } else {
        asyncUpdateFact(data);
      }
    }
  }

  const handleDelete = () => {
    if ( fact.episodeId < 0 ){
      dispatch(EpisodeFactsActions.deleteFact(fact.id))
    } else {
      asyncDeleteFact(fact.id)
    }
  }

  const asyncCreateFact = async (data:any) => {
    try {
      await dispatch(createFact(data)).unwrap();
      dispatch(EpisodeFactsActions.deleteFact(fact.id));
    } catch(error){}
  }

  const asyncUpdateFact = async (data:any) => {
    try {
      await dispatch(updateFact({ factId: fact.id, data })).unwrap();
      dispatch(EpisodeFactsActions.setFactNotEditable(fact.id));
    } catch(error){}
  }

  const asyncDeleteFact = async (factId:number) => {
    try {
      await dispatch(deleteFact(factId)).unwrap();
    } catch(error){}
  }

  const handleEditClick = () => {
    dispatch(EpisodeFactsActions.setFactEditable(fact.id));
  }

  return (
    <Paper variant="outlined" sx={{
      display: 'flex',
      flexDirection: 'column',
      p: 4
    }}>
      <Typography variant="subtitle2">#{fact.data.hcpcsCode}{fact.data.modifier ? ` ${fact.data.modifier}` : null}</Typography>
      {fact.data.shortDescription ? (
        <Typography
          sx={{ display: 'block', color: 'rgba(0,0,0,0.75)', fontWeight: 700 }}
          variant="caption"
        >{fact.data.shortDescription}</Typography>
      ) : null}
      {fact.data.medicareRate ? (
        <InfoBlock
          label={t('dialogs.episodeFacts.medicareRateAmount', { medicareRate: caseMedicareRate })}
          value={`$${toNumberWithCommas(fact.data.medicareRate * caseMedicareRate)}`}
        />
      ) : null}
      {fact.data.vaRate ? (
        <InfoBlock label={t('dialogs.episodeFacts.vaRate')} value={`$${toNumberWithCommas(fact.data.vaRate)}`} />
      ) : null}
      {isFactEditable ? (
        <Input
          sx={{ mt: 2 }}
          label={t('labels.price')} value={price} name="price" type="number"
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            )
          }}
          margin="none"
          size="small"
        />
      ) : (
        <InfoBlock label={t('labels.price')} value={`$${toNumberWithCommas(Number(price))}`} />
      )}
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        gap: 2,
        pt: 2
      }}>
        {isFactEditable ? (
          <Fragment>
            <Button
              name={`Cancel edit fact ${fact.id}`}
              onClick={handleCancelClick}
              size="small"
              color="inherit"
            >{t('buttons.close')}</Button>
            <Button
              name={`Save fact ${fact.id}`}
              onClick={handleSave}
              size="small"
              variant="contained"
            >{t('buttons.save')}</Button>
          </Fragment>
        ) : (
          <Fragment>
            <Button
              name={`Edit fact ${fact.id}`}
              onClick={handleEditClick}
              size="small"
              variant="contained"
            >{t('buttons.edit')}</Button>
            <Button
              name={`Delete fact ${fact.id}`}
              onClick={handleDelete}
              size="small"
              color="error"
            >{t('buttons.delete')}</Button>
          </Fragment>
        )}
      </Box>
    </Paper>
  )
}

export default FactItem;
