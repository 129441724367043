import { ChangeEvent, FC, Fragment, useState } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMultiAccountsData } from 'app/store/Auth/Auth.selectors';
import { selectCurrentAccount, selectSortedUserAccounts } from 'app/store/Accounts/Accounts.selectors';
// Mui
import {
  RadioGroup, FormControlLabel, Radio,
  Typography
} from '@mui/material';
// Component
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: (accountId?:number) => void;
};

const MultiAccountsDialog:FC<Props> = ({
  // Props
  open, onClose,
}) => {
  const { t } = useTranslation();
  // State
  const multiAccountData = useAppSelector(selectMultiAccountsData);
  // Since we used same `dialog` to switch account
  // we should check on current account to disable it
  const currentAccount = useAppSelector(selectCurrentAccount);
  const userAccounts = useAppSelector(selectSortedUserAccounts);

  const [ accountId, setAccountId ] = useState<number | null>(currentAccount?.id || null);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setAccountId(Number(event.target.value));
  };

  const handleClose = () => onClose();

  const handleUse = () => {
    if ( !accountId ) return;

    onClose(accountId);
  }

  const accounts = multiAccountData.accounts.length
    ? multiAccountData.accounts
    : userAccounts
  ;

  const closeButtonText = multiAccountData.accounts.length
    ? t('buttons.close')
    : t('buttons.cancel')
  ;

  const useButtonText = multiAccountData.accounts.length
    ? t('buttons.use')
    : t('buttons.switch')
  ;

  const actions = (
    <Fragment>
      <Button
        name="Cancel multi accounts dialog"
        onClick={handleClose}
      >{closeButtonText}</Button>
      <Button
        name="Use multi accounts dialog"
        disabled={!accountId}
        onClick={handleUse}
        variant="contained"
        color="primary"
      >{useButtonText}</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title={t('dialogs.multiAccounts.title')}
      actions={actions}
    >
      {multiAccountData.message ? (
        <Typography variant="subtitle1">{multiAccountData.message}</Typography>
      ) : null}
      <RadioGroup
        name="accountId"
        value={accountId}
        onChange={handleChange}
      >
        {accounts.map((account:any) => (
          <FormControlLabel
            key={`Account item ${account.id}`}
            value={account.id}
            disabled={currentAccount?.id === account.id}
            control={<Radio color="primary" />}
            label={account.company}
          />
        ))}
      </RadioGroup>
    </Dialog>
  )
}

export default MultiAccountsDialog;
