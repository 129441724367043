import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components
import { Switch } from 'app/components/Mui';

type Props = {
  isDisabled: boolean;
}

const EpisodeFormTogglers:FC<Props> = ({ isDisabled }) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  return (
    <Fragment>
      <Controller
        control={control} name="preEvent"
        render={({ field:{ onChange, ...otherField }}) => (
          <Switch
            {...otherField}
            label={t('labels.preEvent')}
            disabled={isDisabled}
            onChange={(_, checked:boolean) => onChange(checked)}
          />
        )}
      />
      <Controller
        control={control} name="showInTimeline"
        render={({ field:{ onChange, ...otherField }}) => (
          <Switch
          {...otherField}
            label={t('labels.showInTimeline')}
            disabled={isDisabled}
            onChange={(_, checked:boolean) => onChange(checked)}
          />
        )}
      />
    </Fragment>
  )
}

export default EpisodeFormTogglers;
