import { ChangeEvent, FC, useCallback, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { Dayjs } from 'dayjs';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectInvoicesSidebarOpen } from 'app/store/AppUI/AppUI.selectors';
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectParams, selectValidateDateRange } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Typography, FormHelperText } from '@mui/material';
// Components
import SidebarToggleButton from 'app/components/SidebarToggleButton';
import { Input, Select, DesktopDatePicker } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// i18next
import { useTranslation } from 'react-i18next';
import { SIDEBAR_WIDTH } from 'app/App.constants';

const InvoicesSidebar:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const invoicesSidebarOpen = useAppSelector(selectInvoicesSidebarOpen)
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const params = useAppSelector(selectParams);
  const validationErrors = useAppSelector(selectValidateDateRange);

  const [ search, setSearch ] = useState('');

  const debounceParams = useCallback(debounce(1000, (field:keyof typeof params, value:any) => {
    dispatch(BillingInvoicesActions.setParams({ [field]: value, offset: 0 }));
    // eslint-disable-next-line
  }), []);

  const handleAccountChange = (value:any) => {
    debounceParams('accountId', value);
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if ( name === 'search' ) setSearch(value);
    debounceParams(name as any, value);
  }
  
  const handleClearSearch = () => {
    setSearch('');
    dispatch(BillingInvoicesActions.setParams({ search: '', offset: 0 }));
  }

  const handleDateChange = (period:'start' | 'end') => (newValue:Dayjs | null) => {
    debounceParams(`${period === 'start' ? 'dateRangeStart' : 'dateRangeEnd'}`, newValue);
  }

  const handleToggleInvoiceSidebar = () => {
    dispatch(AppUIActions.toggleInvoicesSidebarOpen());
  }

  const statusOptions = [
    { id: 'all', name: t('labels.all') },
    { id: 'draft', name: t('labels.draft') },
    { id: 'paid', name: t('labels.paid') },
    { id: 'sent', name: t('labels.outstanding') }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: SIDEBAR_WIDTH,
        flexShrink: 0,
        bgcolor: 'white',
        borderRight: '1px solid rgba(0,0,0,0.08)',
        zIndex: 1,
        marginLeft: invoicesSidebarOpen ? 0 : `-${SIDEBAR_WIDTH}px`
      }}
    >
      <Box sx={{
        flexGrow: 1,
        p: 4,
        overflowY: 'auto'
      }}>
        <Autocomplete
          value={params.accountId}
          onChange={handleAccountChange}
          options={accountOptions || []}
          TextFieldProps={{
            label: t('labels.companyAccount')
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
              <Typography variant="subtitle1">{option.label}</Typography>
              {option.props.address ? (
                <Typography variant="caption">{option.props.address}</Typography>
              ) : null}
            </li>
          )}
        />
        <Input
          label={t('labels.search')} name="search" value={search}
          placeholder={t('form.placeholder.caseClaimOrInvoiceNumber')}
          onChange={handleChange}
          onClear={handleClearSearch}
        />
        <Select
          label={t('labels.status')} name="status" value={params.status}
          onChange={handleChange}
          options={statusOptions}
        />
        <DesktopDatePicker
          label={t('labels.start')} name="dateRangeStart" value={params.dateRangeStart}
          onChange={handleDateChange('start')}
          inputProps={{
            error: Boolean(validationErrors && validationErrors.startError),
            helperText: validationErrors?.startError || ''
          }}
        />
        <DesktopDatePicker
          label={t('labels.end')} name="dateRangeEnd" value={params.dateRangeEnd}
          onChange={handleDateChange('end')}
          inputProps={{
            error: Boolean(validationErrors && validationErrors.endError),
            helperText: validationErrors?.endError || ''
          }}
        />
        {validationErrors && validationErrors.rangeError ? (
          <FormHelperText error>{validationErrors.rangeError}</FormHelperText>
        ) : null}
      </Box>
      <SidebarToggleButton
        name="Billing invoices sidebar toggle button"
        open={invoicesSidebarOpen}
        onClick={handleToggleInvoiceSidebar}
      />
    </Box>
  )
}

export default InvoicesSidebar;
