import { FC, useState, useEffect } from 'react';
import { useParams, useLocation, NavLink } from 'react-router-dom';
// Mui
import { Theme, Box, Toolbar, Tabs, Tab } from '@mui/material';
// Components
import InsuranceCaseInfo from 'app/components/CaseInfo';
import Compare from 'app/components/Compare';
// i18next
import { useTranslation } from 'react-i18next';

const PATHS:string[] = ['workspace', 'calendar'];

const InsuranceCaseToolbar:FC = () => {
  const { t } = useTranslation();

  const { insuranceCaseId } = useParams<{ insuranceCaseId:string }>();
  const { pathname } = useLocation<{ pathname:string }>();

  const tabLabels:Record<string, string> = {
    workspace: t('labels.workspace'),
    calendar: t('labels.calendar'),
  }

  const [ tab, setTab ] = useState('workspace');

  useEffect(() => {
    for ( let path of PATHS ){
      if ( pathname.includes(path) && tab !== path ){
        setTab(path);
        break;
      }
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleChange = (_:any, nextTab:string) => {
    setTab(nextTab);
  };

  return (
    <Toolbar>
      <Box sx={{
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        width: 384,
        minHeight: { xs: 56, sm: 63 },
        borderRight: '1px solid rgba(0,0,0,0.08)',
        pr: 4
      }}>
        <InsuranceCaseInfo />
      </Box>
      <Tabs
        sx={{ px: 4 }}
        value={tab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
      >
        {PATHS.map((link:string) => (
          <Tab
            key={`insurance-case-details-tab-item-${link}`}
            sx={{
              minHeight: { xs: 56, sm: 63 },
              fontSize: 16,
              px: 3,
              textTransform: 'capitalize',
              '&:hover': {
                bgcolor: (theme:Theme) => theme.palette.primary.light
              }
            }}
            component={NavLink}
            to={`/presenter/cases/${insuranceCaseId}/${link}`}
            value={link}
            label={tabLabels[link]}
          />
        ))}
      </Tabs>
      <Box flexGrow={1} />
      <Compare />
    </Toolbar>
  )
}

export default InsuranceCaseToolbar;
