import i18n from "i18n";

const Messages = {
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.alert') }),
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.alert') });
});