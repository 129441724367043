import { useCallback } from "react";
// Types
import FileTypes from "app/types/FileTypes";
import FileFormats from "app/types/FileFormats";
// Models
import { ICollection } from "app/store/DMSCollections/DMSCollections.models";
// Store
import store from "app/store";
import { useAppSelector } from "app/hooks/useStore";
// Selectors
import { selectFileFormatByMimeType } from "app/store/Settings/Settings.selectors";
import { selectCollections } from 'app/store/DMSCollections/DMSCollections.selectors';
// Utils
import generateShortId from 'app/utilities/shortIds';

const useGenerateFileItem = () => {
  // State
  const collections:ICollection[] | null = useAppSelector(selectCollections);

  const preselectCollectionId = useCallback((fileFormat:FileFormats | null) => {
    let collectionId:number | undefined;
    if ( !collections || !collections.length ) return collectionId;

    // fileFormat === null → Match collections with no fileFormats or empty fileFormats
    // Otherwise, match collections that support the file format
    const isCollectionMatchFormat = (collection:ICollection) => fileFormat === null
      ? !collection.fileFormats || collection.fileFormats.length === 0
      : !collection.fileFormats?.length || collection.fileFormats.includes(fileFormat)

    // Collection will be set if there is only one collection that supports the file format
    // If there are multiple collections that support the file format, collection will be undefined
    for ( const collection of collections ){
      if ( isCollectionMatchFormat(collection) ) {
        if ( !collectionId ) {
          collectionId = collection.id;
        } else {
          collectionId = undefined;
          break;
        }
      }
    }

    return collectionId;
  }, [collections]);

  const handleGenerateFileItem = useCallback((file:File) => {
    const shortId = generateShortId();
    const state = store.getState();
    const fileFormat = selectFileFormatByMimeType(state, {
      mimeType: file.type
    });
    return {
      id: shortId,
      type: FileTypes.Document,
      file: new File([file], file.name, {
        type: file.type || 'application/octet-stream',
        lastModified: file.lastModified,
      }),
      errors: [],
      fileFormat,
      collectionId: preselectCollectionId(fileFormat),
      comment: ''
    };
    // eslint-disable-next-line
  }, []);

  return {
    onGenerateFileItem: handleGenerateFileItem
  }
}

export default useGenerateFileItem;
