import { FC } from 'react';
// Models
import IManagementAccount from 'app/models/ManagementAccount';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import {
  selectInvoicesSummary, selectParams, selectAccountFromParams, selectValidateDateRange
} from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Paper, Typography } from '@mui/material';
// Components
import { InfoBlock } from 'app/components/Utilities';
// Utilities
import { toNumberWithCommas } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

const InvoicesSummary:FC = () => {
  const { t } = useTranslation();
  // State
  const account:IManagementAccount | undefined = useAppSelector(selectAccountFromParams);
  const invoicesSummary:any = useAppSelector(selectInvoicesSummary);
  const params = useAppSelector(selectParams);
  const validationErrors = useAppSelector(selectValidateDateRange);

  const startDate = !validationErrors?.startError && params.dateRangeStart
    ? params.dateRangeStart.format('MMM DD, YYYY')
    : null
  ;
  const endDate = !validationErrors?.endError && params.dateRangeEnd
    ? params.dateRangeEnd.format('MMM DD, YYYY')
    : null
  ;

  if ( !invoicesSummary ) return null;
  return (
    <Paper sx={{ bgcolor: 'transparent', p: 4, mb: 4 }} variant="outlined">
      <Typography variant="subtitle2">
        {t(`pages.amBillingInvoices.${account ? 'summaryTitle' : 'summaryTitleForAllClients'}`,
          { startDate, endDate, company: account?.company }
        )}
      </Typography>
      <Box display="flex" gap={4}>
        <InfoBlock label={t('pages.amBillingInvoices.numberOfInvoices')} value={toNumberWithCommas(invoicesSummary.numberOfInvoices)} />
        <InfoBlock label={t('pages.amBillingInvoices.numberOfOutstandingInvoices')} value={toNumberWithCommas(invoicesSummary.numberOfOutstandingInvoices)} />
        <InfoBlock label={t('pages.amBillingInvoices.numberOfPaidInvoices')} value={toNumberWithCommas(invoicesSummary.numberOfPaidInvoices)} />
        <InfoBlock label={t('pages.amBillingInvoices.outstandingAmount')} value={`$${toNumberWithCommas(invoicesSummary.outstandingAmount)}`} />
        <InfoBlock label={t('pages.amBillingInvoices.paidAmount')} value={`$${toNumberWithCommas(invoicesSummary.paidAmount)}`} />
      </Box>
    </Paper>
  )
}

export default InvoicesSummary;
