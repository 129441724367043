import { MouseEvent, FC, Fragment, useRef, useState } from 'react';
// Types
import { TemplateDataType, TemplateLayout } from 'app/types/Template';
// Mui
import { Theme, Box, Divider, Tooltip, Typography } from '@mui/material';
// Icons
import {
  FunctionsOutlined as FunctionsOutlinedIcon,
  InsertPageBreak as InsertPageBreakIcon,
  DatasetLinked as DatasetLinkedIcon,
  FullscreenOutlined as FullscreenOutlinedIcon,
  FullscreenExitOutlined as FullscreenExitOutlinedIcon
} from '@mui/icons-material';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Components
import { IconButton } from 'app/components/Mui';
// Constants
import { FONT_FAMILIES, FONT_SIZES, TAB_STOP_VALUES } from 'app/App.constants';
// 
import VariablesDialog from './VariablesDialog';
import LinkVariablesDialog from './LinkVariablesDialog';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  columnId?: string;
  id: string;
  disabled?: boolean;
  dataType?: TemplateDataType;
  layout?: TemplateLayout;

  isLinkVariableVisible?: boolean;
  isVariableVisible?: boolean;
  isTabStopVisible?: boolean;
  isPageBreakVisible?: boolean;
  isFullScreenVisible?: boolean;
};

const Toolbar:FC<Props> = ({
  // Props
  columnId, id, disabled = false, dataType, layout,
  isLinkVariableVisible = false,
  isVariableVisible = false,
  isTabStopVisible = false,
  isPageBreakVisible = false,
  isFullScreenVisible = false
}) => {
  const { t } = useTranslation();

  const toolbarId = `toolbar-${id}`;

  const toolbarRef = useRef<HTMLDivElement | null>(null)
  const buttonVariableRef = useRef<HTMLButtonElement | null>(null);
  const buttonLinkVariableRef = useRef<HTMLButtonElement | null>(null);

  const { open, toggle } = useToggle();
  const { open:openLinkVariablesDialog, toggle:toggleLinkVariablesDialog } = useToggle();

  const [ fullscreenOn, setFullscreenOn ] = useState(false);

  const handleConfirm = (variableTag:string) => {
    if ( open ) toggle();

    if ( !buttonVariableRef.current ) return;

    buttonVariableRef.current.value = variableTag;
    const e = new Event('click');
    buttonVariableRef.current.dispatchEvent(e);
  }

  const handleLinkConfirm = (variableName:string) => {
    if ( openLinkVariablesDialog ) toggleLinkVariablesDialog();

    if ( !buttonLinkVariableRef.current ) return;

    buttonLinkVariableRef.current.value = variableName;
    const e = new Event('click');
    buttonLinkVariableRef.current.dispatchEvent(e);
  }

  const handleToggleFullScreen = (event: MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();

    const quillWrapperElem = document.querySelector('.quill-wrapper');
    const fullscreenClass = 'fullscreen';

    if ( !quillWrapperElem ) return;

    if ( fullscreenOn ){
      quillWrapperElem.classList.remove(fullscreenClass);
      setFullscreenOn(false);
    } else {
      quillWrapperElem.classList.add(fullscreenClass);
      setFullscreenOn(true);
    }
  }

  if ( disabled ) return <div className="cc-toolbar ql-hidden" id={toolbarId}></div>;
  return (
    <Fragment>
      <div ref={toolbarRef} className="cc-toolbar" id={toolbarId}>
        <select className="ql-font" defaultValue="arial">
          {FONT_FAMILIES.map((fontFamily:string) => (
            <option
              key={`${toolbarId}-font-family-item-${fontFamily}`}
              value={fontFamily.toLowerCase().replace(/\s/g, '')}
            >{fontFamily}</option>
          ))}
        </select>
        <Divider flexItem orientation="vertical" />
        <select className="ql-size" defaultValue="12pt">
          {FONT_SIZES.map((fontSize:number) => (
            <option
              key={`${toolbarId}-font-size-item-${fontSize}`}
              value={`${fontSize}pt`}
            >{`${fontSize}pt`}</option>
          ))}
        </select>
        <Divider flexItem orientation="vertical" />
        <select className="ql-header" defaultValue="false">
          <option value="1">{`${t('components.quillEditor.heading')} 1`}</option>
          <option value="2">{`${t('components.quillEditor.heading')} 2`}</option>
          <option value="3">{`${t('components.quillEditor.heading')} 3`}</option>
          <option value="4">{`${t('components.quillEditor.heading')} 4`}</option>
          <option value="5">{`${t('components.quillEditor.heading')} 5`}</option>
          <option value="6">{`${t('components.quillEditor.heading')} 6`}</option>
          <option value="false">{t('components.quillEditor.normal')}</option>
        </select>
        <Divider flexItem orientation="vertical" />
        <span className="ql-formats">
          <Tooltip title={t('components.quillEditor.bold')}>
            <button className="ql-bold" />
          </Tooltip>
          <Tooltip title={t('components.quillEditor.italic')}>
            <button className="ql-italic" />
          </Tooltip>
          <Tooltip title={t('components.quillEditor.underline')}>
            <button className="ql-underline" />
          </Tooltip>
          <Tooltip title={t('components.quillEditor.lineThrough')}>
            <button className="ql-strike" />
          </Tooltip>
        </span>
        <Divider flexItem orientation="vertical" />
        <span className="ql-formats">
          <Tooltip title={t('components.quillEditor.orderedList')}>
            <button className="ql-list" value="ordered" />
          </Tooltip>
          <Tooltip title={t('components.quillEditor.bulledList')}>
            <button className="ql-list" value="bullet" />
          </Tooltip>
        </span>
        <Divider flexItem orientation="vertical" />
        <select className="ql-align" />
        <Divider flexItem orientation="vertical" />
        <span className="ql-formats">
          <Tooltip title={t('components.quillEditor.indentNegative')}>
            <button className="ql-indent" value="-1" />
          </Tooltip>
          <Tooltip title={t('components.quillEditor.indentPositive')}>
            <button className="ql-indent" value="+1" />
          </Tooltip>
        </span>
        {isTabStopVisible ? (
          <Fragment>
            <Divider flexItem orientation="vertical" />
            <Box sx={{ position: 'relative' }}>
              <Typography sx={{
                position: 'absolute',
                bgcolor: 'white',
                top: -5,
                left: 4,
                display: 'block',
                fontSize: 9,
                px: 1,
                zIndex: 6
              }} variant="caption">{t('components.quillEditor.tabStop')}</Typography>
              <select className="ql-tabstop" defaultValue="">
                <option value="">{t('components.quillEditor.none')}</option>
                {TAB_STOP_VALUES.map((value:string) => (
                  <option
                    key={`${toolbarId}-tab-stop-item-${value}`}
                    value={value}
                  >{value}"</option>
                ))}
              </select>
            </Box>
          </Fragment>
        ) : null}
        {isPageBreakVisible ? (
          <Fragment>
            <Divider flexItem orientation="vertical" />
            <Tooltip title={t('components.quillEditor.pageBreak')}>
              <button className="ql-pagebreak">
                <InsertPageBreakIcon
                  sx={{
                    color: '#666',
                    '&:hover': {
                      color: (theme:Theme) => theme.palette.primary.main
                    }
                  }}
                />
              </button>
            </Tooltip>
          </Fragment>
        ) : null}
        <Divider flexItem orientation="vertical" />
        <span className={t('components.quillEditor.link')}>
          <Tooltip title="Link">
            <button className="ql-link" />
          </Tooltip>
          <Tooltip title={t('components.quillEditor.image')}>
            <button className="ql-image" />
          </Tooltip>
        </span>
        {isLinkVariableVisible ? (
          <Fragment>
            <Divider flexItem orientation="vertical" />
            <button ref={buttonLinkVariableRef} className="ql-linkVariable ql-hidden" />
            <Tooltip title={t('components.quillEditor.linkDialogTitle')}>
              <IconButton
                name="App link variables"
                onClick={toggleLinkVariablesDialog}
              ><DatasetLinkedIcon /></IconButton>
            </Tooltip>
          </Fragment>
        ) : null}
        {isVariableVisible ? (
          <Fragment>
            <Divider flexItem orientation="vertical" />
            <button ref={buttonVariableRef} className="ql-variable ql-hidden" />
            <Tooltip title={t('components.quillEditor.variablesDialogTitle')}>
              <IconButton
                name="Variables"
                onClick={toggle}
              ><FunctionsOutlinedIcon /></IconButton>
            </Tooltip>
          </Fragment>
        ) : null}
        {isFullScreenVisible ? (
          <Fragment>
            <Divider flexItem orientation="vertical" />
            <Tooltip title={fullscreenOn ? t('components.quillEditor.exitFullScreen') : t('components.quillEditor.fullScreen')}>
              <IconButton
                name="Full screen"
                onClick={handleToggleFullScreen}
              >{fullscreenOn ? <FullscreenExitOutlinedIcon /> : <FullscreenOutlinedIcon />}</IconButton>
            </Tooltip>
          </Fragment>
        ) : null}
      </div>
      {open ? (
        <VariablesDialog
          open={open}
          onClose={toggle}
          onConfirm={handleConfirm}
          columnId={columnId}
          id={id}
          dataType={dataType}
          layout={layout}
        />
      ) : null}
      {openLinkVariablesDialog ? (
        <LinkVariablesDialog
          open={openLinkVariablesDialog}
          onClose={toggleLinkVariablesDialog}
          onConfirm={handleLinkConfirm}
          columnId={columnId}
          id={id}
          dataType={dataType}
          layout={layout}
        />
      ) : null}
    </Fragment>
  )
}

export default Toolbar;
