import React from 'react';
// Types
import StapleModes from '@root/types/StapleModes';
import { pageMessages } from 'app/store/page/page.messages';
// Models
import { IPage, IPageWithAction } from '@root/models/Page';
// Store
import { useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectStapleMode, selectStapleId, selectStaplePagesToUpdate, selectStatus } from 'app/store/page/page.selectors';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
// Service
import { generateStapleGroup } from 'app/store/page/page.service';
// MaterialUI
import Fab from '@mui/material/Fab';
// i18next
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'app/hooks/useStore';

const WorkspaceStapleFab:React.FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const status:any = useSelector(selectStatus);
  const stapleId:string | null = useSelector(selectStapleId);
  const stapleMode:StapleModes | null = useSelector(selectStapleMode);
  const staplePagesToUpdate:Array<IPageWithAction> = useSelector(selectStaplePagesToUpdate);
  const episodes:any = useSelector(selectEpisodesAll);

  React.useEffect(() => {
    return () => {
      handleCancelStapleMode();
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( status === 'Success' ) handleCancelStapleMode();
    // eslint-disable-next-line
  }, [status])

  const handleSave = () => {
    if ( !stapleId ) return;
    let pagesToUpdate = generateStapleGroup(stapleMode, stapleId, staplePagesToUpdate, episodes);
    let message = pageMessages.updateStaple;
    if ( staplePagesToUpdate.length === pagesToUpdate.length && pagesToUpdate.every((page:IPage) => !page.staple) ){
      message = pageMessages.removeStaple;
    } else {
      const pagesByPageNum = pagesToUpdate.reduce((acc:any, cur:any) => {
        acc[cur.pageNum] = cur;
        return acc;
      }, {});
      pagesToUpdate = staplePagesToUpdate.map((page) => {
        const nextPage = pagesByPageNum[page.pageNum] || page;

        if ( !nextPage.staple ) return nextPage;

        const [ stapleId, version ] = (nextPage.staple as any).id.split(':');

        const { action, annotations, highlights, ...otherNextPage } = nextPage;

        const nextVersion = version ? Number(version) + 1 : 1;

        return {...otherNextPage, staple:{
          ...otherNextPage.staple,
          id: `${stapleId}:${nextVersion}`
        }}
      });
    }
    dispatch(PageActions.patchPages(pagesToUpdate, message));
  }
  const handleCancelStapleMode = () => dispatch(PageActions.cancelStapleMode());

  const isSaveDisabled = !staplePagesToUpdate.some((page:IPageWithAction) => page.action);

  if ( !stapleMode && stapleMode !== StapleModes.Edit ) return null;
  return (
    <div style={{
      position: 'fixed',
      right: '16px',
      bottom: '16px'
    }}>
      <Fab
        style={{ marginRight: '8px' }}
        variant="extended"
        color="primary"
        disabled={isSaveDisabled}
        onClick={handleSave}
      >{t('buttons.save')}</Fab>
      <Fab
        variant="extended"
        color="default"
        onClick={handleCancelStapleMode}
      >{t('buttons.cancel')}</Fab>
    </div>
  )
}

export default WorkspaceStapleFab;
