import React from 'react';
// State
import { useSelector } from 'react-redux';
// Selectors
import { selectComparePagesIds } from 'app/store/page/page.selectors';
// Components
import { Message } from 'app/components/Utilities';
// 
import PageCompareListItem from './PageCompareListItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {};

const PageCompareList:React.FC<Props> = () => {
  const { t } = useTranslation();
  // Select
  const comparePagesIds = useSelector(selectComparePagesIds);
  const columnSize = comparePagesIds.length > 2 ? 45 : comparePagesIds.length === 1 ? 100 : 50;

  if ( comparePagesIds.length === 0 ) return <Message text={t('dialogs.pageCompare.noPages')} />
  return (
    <div style={{
      display: 'flex',
      overflowX: 'auto',
      marginRight: '-8px',
      marginLeft: '-8px'
    }}>
      {comparePagesIds.map((pageId:string) => (
        <div
          key={`compare-page-item-${pageId}`}
          style={{
            maxWidth: `${columnSize}%`,
            flexBasis: `${columnSize}%`,
            flexShrink: 0,
            paddingRight: '8px',
            paddingLeft: '8px',
            overflowX: 'hidden',
            overflowY: 'auto'
          }}
        >
          <PageCompareListItem pageId={pageId} />
        </div>
      ))}
    </div>
  )
}

export default PageCompareList;
