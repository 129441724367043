import { FC } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { toggleFilesAdditionalTools } from 'app/store/ui/uiSlice';
// Selectors
import { selectAdditionalToolsShown } from 'app/store/ui/uiSelectors';
// Mui
import { Toolbar, IconButton, Tooltip } from '@mui/material';
// Icons
import { Tune as TuneIcon } from '@mui/icons-material';
// 
import GoToPage from './GoToPage';
import GoToLastOpenedPage from './GoToLastOpenedPage';
import DocumentName from 'app/components/DocumentName';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  listRef: any;
}

const FilesToolbar:FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // Statte
  const toolsShown = useAppSelector((state:RootState) => selectAdditionalToolsShown(state, {
    toolsType: 'files'
  }));

  const handleClick = () => {
    dispatch(toggleFilesAdditionalTools());
  }

  return (
    <Toolbar sx={{ gap: 4 }}>
      <DocumentName />
      {toolsShown.includes('go_to_last_opened_page') ? (
        <GoToLastOpenedPage listRef={listRef} />
      ) : null}
      {toolsShown.includes('go_to_page') ? (
        <GoToPage listRef={listRef} />
      ) : null}
      <Tooltip title={t('labels.additionalTools')}>
        <IconButton
          onClick={handleClick}
        ><TuneIcon /></IconButton>
      </Tooltip>
    </Toolbar>
  )
}

export default FilesToolbar;
