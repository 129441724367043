import { FC, Fragment } from 'react';
import { Link, useParams }from 'react-router-dom';
import dayjs from 'dayjs';
// Types
import CalendarLegends from 'app/types/CalendarLegends';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { addToCompare, removeFromCompare } from 'app/store/ui/uiSlice';
import { setSelectedDate } from 'app/store/calendar/calendarSlice';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectIsInCompare } from 'app/store/ui/uiSelectors';
import { selectPrimaryStaplePage } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Material
import {
  Button, IconButton,
  Box, Typography
} from '@mui/material';
// Components
import Icon from 'app/components/Icon';
import EpisodeLabels from '@root/components/EpisodeLabels';
// Hooks
import useLongDateTme from 'app/hooks/useLongDateTime';
// i18next
import { useTranslation } from 'react-i18next';
import UserRoles from 'app/types/UserRoles';

type Props = {
  onClose: () => void;
  episode: any;
  showDailyButton?: boolean;
};

const PopoverContent:FC<Props> = ({
  // Props
  onClose, episode, showDailyButton = false
}) => {
  const { t } = useTranslation();

  const pageId = `${episode.documentId}:${episode.pageNum}`;

  const { insuranceCaseId } = useParams<{ insuranceCaseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const isInCompare = useAppSelector((state:RootState) => selectIsInCompare(state, { id: pageId }));
  const primaryStaplePage = useAppSelector((state:RootState) => selectPrimaryStaplePage(state, {
    documentId: episode.documentId,
    pageNum: episode.pageNum
  }));
  // ToDO
  const myUser = useAppSelector(selectMyUser);

  const longDateTime = useLongDateTme({
    dateValue: episode.date,
    timeValue: episode.time
  });

  const handleSetSelectedDate = () => {
    dispatch(setSelectedDate(dayjs(episode.date)));
  }

  const handleToggleCompare = () => {
    if ( isInCompare ){
      dispatch(removeFromCompare(pageId));
    } else {
      dispatch(addToCompare(pageId))
    }
  }

  const userPath = myUser?.role === UserRoles.Admin || myUser?.role === UserRoles.Staff
    ? 'admin'
    : 'presenter'
  ;

  const pageNum = primaryStaplePage ? primaryStaplePage.pageNum : episode.pageNum;
  const link = `/${userPath}/cases/${insuranceCaseId}/documents/${episode.documentId}/${pageNum}`;

  const isPublicTypeActive =
    episode.legend.key !== CalendarLegends.EventDate &&
    episode.legend.key !== CalendarLegends.ExaminationDate &&
    episode.legend.key !== CalendarLegends.TrialDate
  ;

  console.log(episode.legend.key);

  return (
    <Box sx={{ width: 480, display: 'flex', flexDirection: 'column', px: 4, py: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', color: 'rgba(0,0,0,0.87)', pr: '8px' }}>
          <Icon
            icon="event"
            label={longDateTime}
          />
        </Box>
        <Box>
          {isPublicTypeActive ? (
            <Fragment>
              {showDailyButton ? (
                <IconButton
                  onClick={handleSetSelectedDate}
                ><Icon icon="load_data_to_daily_chart" /></IconButton>
              ) : null}
              <IconButton
                onClick={handleToggleCompare}
                color={isInCompare ? 'primary' : 'default'}
              ><Icon icon="compare" /></IconButton>
            </Fragment>
          ) : null}
          <IconButton
            onClick={onClose}
            edge="end"
          ><Icon icon="close" /></IconButton>
        </Box>
      </Box>
      <Box>
        {episode.labels && episode.labels.length ? (
          <Box sx={{ mb: 2 }}>
            <EpisodeLabels id="calendar-popover" labels={episode.labels} />
          </Box>
        ) : null}
        <Typography variant="h6">{episode.legend.label}</Typography>
        {episode.author && episode.author.name ? (
          <Typography variant="subtitle2">{episode.author.name}</Typography>
        ) : null}
      </Box>
      {isPublicTypeActive ? (
        <Box>
          <Button
            sx={{ ml: -2 }}
            component={Link}
            to={link}
            target="_blank"
            color="primary"
          >{t('components.popoverContent.pageOnlyPreview')}</Button>
        </Box>
      ) : null}
    </Box>
  )
}

export default PopoverContent;
