import { FC, Fragment, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { StepTasks } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPromptsAsOptions } from 'app/store/AIChatPrompts/AIChatPrompts.selectors';
// Mui
import { Box, FormHelperText, Typography, Button, IconButton, InputAdornment } from '@mui/material';
// Icons
import {
  DeleteOutline as DeleteOutlineIcon,
  Percent as PercentIcon
} from '@mui/icons-material';
// Dialogs
import AIPromptSelectDialog from 'app/dialogs/AIPromptSelectDialog';
// Components
import { Input, Switch } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { isMax, isMin, isRequired } from 'app/utilities/Validations';
// 
import { useWorkflowContext } from '../Workflow.context'

const RunPromptControls:FC<any> = () => {
  const { t } = useTranslation();
  // State
  const promptOptions = useAppSelector(selectPromptsAsOptions)

  const { selectedStep, getStepFieldIndex } = useWorkflowContext();

  const { control, formState:{ errors }, setValue, clearErrors } = useFormContext();

  const { open, toggle } = useToggle();

  const promptName = useCallback((promptId:string) => {
    if ( !promptOptions ) return '';
    const findedPrompt = promptOptions.find((p) => p.id === promptId);
    return findedPrompt?.name || '';
  }, [promptOptions]);

  const handleConfirm = (aiPrompt:any) => {
    setValue(`steps[${fieldIndex}].input.parameters.promptId`, aiPrompt.id);
    clearErrors(`steps[${fieldIndex}].input.parameters.promptId`);
  }

  if ( !selectedStep || selectedStep.task !== StepTasks.RunPrompt ) return null;

  const fieldIndex = getStepFieldIndex(selectedStep.id) as number;

  const promptIdError = (errors as any)?.steps?.[fieldIndex]?.input?.parameters?.promptId;
  const coverageError = (errors as any)?.steps?.[fieldIndex]?.input?.parameters?.coverage;

  return (
    <Fragment>
      <Controller
        control={control}
        name={`steps[${fieldIndex}].input.parameters.promptId`}
        rules={{ required: isRequired }}
        render={({ field }) => {
          return (
            <Box sx={{ pt: 2 }}>
              <Box>
                {field.value ? (
                  <Box sx={{
                    display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'space-between'
                  }}>
                    <Typography sx={{ wordBreak: 'break-word' }}>{promptName(field.value)}</Typography>
                    <IconButton
                      onClick={() => {
                        field.onChange(null);
                      }}
                      size="small"
                      edge="end"
                    ><DeleteOutlineIcon fontSize="small" /></IconButton>
                  </Box>
                ) : (
                  <Button
                    onClick={toggle}
                    variant="outlined"
                  >{t('pages.automatedWorkflows.selectPromptButton')}</Button>
                )}
              </Box>
              {promptIdError ? (
                <FormHelperText error>{promptIdError?.message || ''}</FormHelperText>
              ) : null}
            </Box>
          );
        }}
      />
      <Controller
        control={control}
        name={`steps[${fieldIndex}].input.parameters.coverage`}
        rules={{
          min: isMin(0),
          max: isMax(100)
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('pages.automatedWorkflows.documentCoverage')} type="number"
            error={Boolean(coverageError)}
            helperText={coverageError?.message || ''}
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="end"><PercentIcon /></InputAdornment>
            }}
          />
        )}
      />
      <FormHelperText>{t('pages.automatedWorkflows.documentCoverageHint')}</FormHelperText>
      <Controller
        name={`steps[${fieldIndex}].input.parameters.validateResponse`} defaultValue={false}
        render={({ field:{ onChange, ...otherField }}) => (
          <Switch
            {...otherField}
            onChange={(_, checked:boolean) => onChange(checked)}
            label={t('labels.validateResponse')}
          />
        )}
      />
      {open ? (
        <AIPromptSelectDialog
          open={open}
          onClose={toggle}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default RunPromptControls;