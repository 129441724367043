import { FC, Fragment } from 'react';
// Model
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
import { IPage } from '@root/models/Page';
// Selectors
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectFilteredDocumentsPages } from 'app/store/page/page.selectors';
// Mui
import { Alert } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store';
import { useAppSelector } from 'app/hooks/useStore';

type Props = {
  isFilesPage: boolean;
  page: IPage;
  prevDocumentId: number;
}

const DocumentAlert:FC<Props> = ({
  // Props
  isFilesPage, page, prevDocumentId
}) => {
  const { t } = useTranslation();
  // State
  const documentEntity = useAppSelector((state:RootState) => selectDocumentEntity(state, { documentId: page.documentId })) as IDocument;
  const documentsPages = useAppSelector(selectFilteredDocumentsPages) || [];

  const pageIndex = documentsPages.findIndex((p:IPage) => p.documentId === page.documentId && p.pageNum === page.pageNum);

  const isNextDisabled = pageIndex === documentsPages.length - 1;

  if ( !isFilesPage ) return null;
  return (
    <Fragment>
      {isNextDisabled && (
        <Alert sx={{ mb: 4 }} severity="info">{t('dialogs.cardPage.youReachedVeryLastPage')}</Alert>
      )}
      {page.documentId !== prevDocumentId && (
        <Alert sx={{ mb: 4 }} severity="info">{t('dialogs.cardPage.youSwitchedToDocument', { fileName: documentEntity?.name })}</Alert>
      )}
    </Fragment>
  )
}

export default DocumentAlert;
