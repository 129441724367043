import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { ContentType } from 'app/types/ContentItem';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectTemplateById } from 'app/store/Templates/Templates.selectors';
// Mui
import { Theme, Box, Typography, Tooltip } from '@mui/material';
// Icons
import {
  Add as AddIcon,
  VisibilityOutlined as VisibilityOutlinedIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Dialogs
import ContentPreviewDialog from 'app/dialogs/ContentPreviewDialog';
// 
import { useReportPresetContext } from 'app/context/ReportPreset.context';

type Props = {
  id: number;
  contentType: ContentType;
}

const ContentItem:FC<Props> = ({
  // Props
  id, contentType
}) => {
  const { t } = useTranslation();
  // State
  const document = useAppSelector((state:RootState) => selectDocumentEntity(state, {
    documentId: id
  }));
  const template = useAppSelector((state:RootState) => selectTemplateById(state, {
    templateId: id
  }));

  const { caseId, onAddContentItem } = useReportPresetContext();
  const { open, toggle } = useToggle();

  const isContentTypeTemplate = contentType === ContentType.Template;

  const name = useMemo(() => {
    return isContentTypeTemplate ? template?.name : document.name;
  }, [isContentTypeTemplate, document, template]);

  const handleClick = () => {
    onAddContentItem(contentType, id);
  }

  const contentPreviewDialogProps = isContentTypeTemplate
    ? { templateIds: [id] }
    : { documentIds: [id] }
  ;

  return (
    <Fragment>
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        bgcolor: 'white',
        borderTop: (theme:Theme) => `1px solid ${theme.palette.divider}`,
        py: 2,
        px: 6
      }}>
        <Box sx={{ flexGrow: 1, pr: 2 }}>
          <Typography variant="body1">{name}</Typography>
        </Box>
        <Box sx={{ whiteSpace: 'nowrap', mr: -1 }}>
          {caseId ? (
            <Tooltip title={t('buttons.preview')}>
              <IconButton
                name={`Preview ${contentType} ${id}`}
                aria-label={`Preview ${contentType}`}
                onClick={toggle}
                size="small"
              ><VisibilityOutlinedIcon /></IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title={t('buttons.add')}>
            <IconButton
              aria-label="Add"
              name={`Add ${contentType} ${id}`}
              onClick={handleClick}
              size="small"
              edge="end"
            ><AddIcon /></IconButton>
          </Tooltip>
        </Box>
      </Box>
      {open ? (
        <ContentPreviewDialog
          {...contentPreviewDialogProps}
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default ContentItem;