import { FC, Fragment } from 'react';
// Models
import { IManagerUser } from 'app/models/User';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { patchUser, unlockUser } from 'app/store/UsersManagement/UsersManagement.async';
// Mui
import { Theme, Paper, Box, Typography } from '@mui/material';
// Icon
import {
  MoreVert as MoreVertIcon,
  Lock as LockIcon,
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useMenu from 'app/hooks/useMenu';
// 
import CompanyInviteDialog from './CompanyInviteDialog';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  user: IManagerUser;
}

const UsersListItem:FC<Props> = ({
  // Props
  user
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();

  const { open, toggle } = useToggle();
  const { Menu, MenuItem, openMenu } = useMenu();

  const handleClick = () => {
    dispatch(unlockUser(user.id));
  }

  const handleToggleUserPlatform = () => {
    dispatch(patchUser({ userId: user.id, data: { platform: !user.platform } }));
  }

  return (
    <Fragment>
      <Paper
        sx={{
          display: 'flex',
          bgcolor: (theme:Theme) => !user.enabled ? theme.palette.grey[100] : 'white',
          p: 4
        }}
        variant="outlined"
      >
        <Box
          sx={{
            flexGrow: 1,
            opacity: !user.enabled ? '0.5': 'initial',
            pr: 2
          }}
        >
          <Typography sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }} variant="subtitle1">
            {user.locked ? <LockIcon color={`action`} fontSize="small" /> : null}
            {user.name}
            {user.platform ? ' (Case Chronology)' : null}
          </Typography>
          <InfoBlock label={t('labels.email')} value={user.email} direction="row" />
          {user.account?.company ? (
            <InfoBlock label={t('labels.company')} value={user.account.company} direction="row" />
          ) : null}
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`User dropdown ${user.id}`}
            aria-label="User item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            {user.platform ? (
              <MenuItem
              name={`${user.platform ? 'non-platform' : 'platform'} user platform ${user.id}`}
              onClick={toggle}
            >{t('pages.amUsers.invite')}</MenuItem>
            ) : null}
            {user.locked ? (
              <MenuItem
                name={`Unlock user ${user.id}`}
                onClick={handleClick}
              >{t('pages.amUsers.unlock')}</MenuItem>
            ) : null}
            <MenuItem
              name={`Mark as a ${user.platform ? 'non-platform' : 'platform'} user ${user.id}`}
              onClick={handleToggleUserPlatform}
            >{user.platform ? t('pages.amUsers.markAsNonPlatformUser') : t('pages.amUsers.markAsPlatformUser')}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {open ? (
        <CompanyInviteDialog
          open={open}
          onClose={toggle}
          user={user}
        />
      ) : null}
    </Fragment>
  )
}

export default UsersListItem;
