import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSortedReportSections } from 'app/store/ReportSections/ReportSections.selectors';
// Mui
import {
  Container, List, Paper
} from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import ReportSectionItem from './ReportSectionItem';

const ReportSectionsList:FC = () => {
  const { t } = useTranslation();

  // Selectors
  const reportSections = useAppSelector(selectSortedReportSections);

  if ( !reportSections ) return <Loader />;
  if ( !reportSections.length ) return <Message text={t('pages.reportSections.noData')} />
  return (
    <Container sx={{ pt: 4, pb: 22 }} maxWidth="sm">
      <Paper variant="outlined">
        <List>
          {reportSections.map((reportSection) => (
            <ReportSectionItem
              key={`report section item ${reportSection.id}`}
              reportSection={reportSection}
            />
          ))}
        </List>
      </Paper>
    </Container>
  )
}

export default ReportSectionsList;
