import { FC, Fragment } from 'react';
// Components
import NotificationPreferences from 'app/components/NotificationPreferences';

const NotificationsStepContent:FC = () => {
  return (
    <Fragment>
      <NotificationPreferences outlineBlockVisible={false} />
    </Fragment>
  );
};

export default NotificationsStepContent;
