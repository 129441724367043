import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// ASync
import { getPresets } from 'app/store/ReportPresets/ReportPresets.async';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import PresetsFilter from './PresetsFilter';
import PresetsList from './PresetsList';
import PresetsFab from './PresetsFab';
// i18next
import { useTranslation } from 'react-i18next';

const PresetsPage:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPresets({}));
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.presets.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="sm">
          <PresetsFilter />
          <PresetsList />
        </Container>
        <PresetsFab />
      </Box>
    </Fragment>
  )
}

export default PresetsPage;
