import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Models
import { RootState } from 'app/store';
// Async
import { getSearchPages } from 'app/store/DMSDocumentPages/DMSDocumentPages.async';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
// Selectors
import { selectSearchSidebarOpen } from 'app/store/AppUI/AppUI.selectors';
import { selectDocumentsAsOptions } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import {
  Box, Paper, Chip,
  FormHelperText,
  Typography, Tooltip,
  Autocomplete as MuiAutocomplete
} from '@mui/material';
// Icons
import { Add as AddIcon } from '@mui/icons-material';
// Components
import SidebarToggleButton from 'app/components/SidebarToggleButton';
import { Button, Input, Select, DesktopDatePicker } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import { Message, OutlineBlock } from 'app/components/Utilities';
// Services
import LocalStorageService from 'app/services/LocalStorage.service';
// Utilities
import { dateToString } from 'app/utilities/DateTime';
// i18next
import { useTranslation } from 'react-i18next';
import { SIDEBAR_WIDTH } from 'app/App.constants';

interface IFormData {
  match: 'all' | 'any';
  documentIds: (number | string)[],
  inWorkspace: 'all' | 'true' | 'false';
  date: Dayjs | null;
  chronologyDateRange: {
    start: Dayjs | null;
    end: Dayjs | null;
  }
}

const SearchSidebar:FC = () => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const searchSidebarOpen = useAppSelector(selectSearchSidebarOpen);
  const documentOptions = useAppSelector((state:RootState) => selectDocumentsAsOptions(state, { excludeCustomFormat: false, onlyOcred: true }));

  const [ keyword, setKeyword ] = useState('');
  const [ keywords, setKeywords ] = useState<string[]>([]);
  const [ recentlyKeywords, setRecentlyKeywords ] = useState<string[]>([]);

  const initialDocumentIds = ['all'];

  const { handleSubmit, control, reset, watch, setValue } = useForm<IFormData>({
    defaultValues: {
      match: 'all',
      documentIds: initialDocumentIds,
      inWorkspace: 'all',
      date: null,
      chronologyDateRange: {
        start: null,
        end: null
      }
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    dispatch(AppUIActions.setInitialField('selectedPageIds'));

    const { match, date, chronologyDateRange:{ start, end }, inWorkspace } = data;

    const documentIds = data.documentIds[0] === 'all'
      ? documentOptions.map(({ value }) => value)
      : data.documentIds
    ;

    const nextParams:any = { match, insuranceCaseId: Number(caseId), documentIds };

    const nextKeywords = keyword ? [...keywords, keyword] : keywords;
    if ( nextKeywords.length ) nextParams['keywords'] = nextKeywords;
    if ( date ) nextParams['date'] = dateToString(date);
    if ( start || end ){
      nextParams['chronologyDateRange'] = {};
      if ( start ) nextParams['chronologyDateRange']['start'] = dateToString(start);
      if ( end ) nextParams['chronologyDateRange']['end'] = dateToString(dayjs(end).add(1, 'day'));
    }
    if ( inWorkspace !== 'all' ) nextParams['inWorkspace'] = inWorkspace;

    if (keyword) {
      LocalStorageService.setSearchKeywords(keyword);
      setKeywords(nextKeywords);
      setKeyword('');
    }

    dispatch(getSearchPages(nextParams));
  });

  const handleChangeKeyword = (event:ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  }

  const handleAddKeyword = (event:any) => {
    if ( !keyword ) return;

    if (
      event.type === 'click' ||
      (event.type === 'keypress' && event.charCode === 13)
    ){
      LocalStorageService.setSearchKeywords(keyword);
      setKeywords([...keywords, keyword]);
      setKeyword('');
    }
  }

  const handleRemoveKeyword = (idx:number) => () => {
    setKeywords((prevState:string[]) => prevState.filter((_, index:number) => index !== idx));
  }

  const handleReset = () => {
    reset();
    setKeywords([]);

    dispatch(AppUIActions.setInitialField('selectedPageIds'));
  }

  const handleToggleSearchSidebar = () => {
    dispatch(AppUIActions.toggleSearchSidebarOpen());
  }

  const matchOptions = [
    { id: 'all', name: t('pages.search.label.allKeywords') },
    { id: 'any', name: t('pages.search.label.anyKeyword') }
  ];
  const inWorkspaceOptions= [
    { id: 'all', name: t('pages.search.label.all') },
    { id: 'true', name: t('pages.search.label.inWorkspaceOnly') },
    { id: 'false', name: t('pages.search.label.notInWorkspace') },
  ];

  const { start, end } = watch('chronologyDateRange');


  useEffect(() => {
    setRecentlyKeywords(LocalStorageService.getSearchKeywords());
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: SIDEBAR_WIDTH,
        flexShrink: 0,
        bgcolor: 'white',
        borderRight: '1px solid rgba(0,0,0,0.08)',
        zIndex: 1,
        marginLeft: searchSidebarOpen ? 0 : `-${SIDEBAR_WIDTH}px`
      }}
      component="form"
      noValidate
    >
      <Box sx={{
        flexGrow: 1,
        p: 4,
        overflowY: 'auto'
      }}>
        <Controller
          control={control} name="match"
          render={({ field }) => (
            <Select
              {...field}
              sx={{ mb: 4 }}
              label={t('pages.search.label.match')}
              options={matchOptions}
              margin="none"
            />
          )}
        />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          mb: 4
        }}>
          <MuiAutocomplete
            fullWidth
            freeSolo
            options={recentlyKeywords}
            inputValue={keyword}
            onInputChange={(_, keyword) => setKeyword(keyword)}
            renderInput={(params) => (
              <Input
                {...params}
                name="keywords" id="keywords"
                label={t('pages.search.label.keywords')}
                value={keyword}
                onChange={handleChangeKeyword}
                margin="none"
                onKeyPress={handleAddKeyword}
              />
            )}
          />
          <Tooltip title={t('pages.search.addKeywordButton')}>
            <span>
              <Button
                name="Add keyword"
                aria-label="Add keyword button"
                sx={{
                  minWidth: 'initial',
                  px: 2
                }}
                disabled={!keyword}
                onClick={handleAddKeyword}
                variant="contained"
                size="large"
              ><AddIcon /></Button>
            </span>
          </Tooltip>
        </Box>

        <Paper sx={{ p: 2, mb: 2 }}>
          {keywords.length ? (
            <Fragment>
              <Typography sx={{
                textAlign: 'center',
                mb: 1
              }} display="block" variant="caption">{t('pages.search.label.keywords')}</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {keywords.map((key:string, index:number) => (
                  <Chip
                    key={`keyword-item-${index}`}
                    label={key}
                    onDelete={handleRemoveKeyword(index)}
                    size="small"
                  />
                ))}
              </Box>
            </Fragment>
          ) : (
            <Message text={t('pages.search.noKeywordsAdded')} />
          )}
        </Paper>

        <Controller
          control={control} name="documentIds"
          render={({ field:{ onChange, name, ...otherField } }) => (
            <Autocomplete
              {...otherField}
              options={[
                { value: 'all', label: t('labels.all') },
                ...documentOptions
              ]}
              onChange={(nextValue, reason) => {
                let newValue = nextValue || initialDocumentIds;

                if ( nextValue && Array.isArray(nextValue) ){
                  if ( reason === 'removeOption' || reason === 'clear' ){
                    if ( !nextValue || !nextValue.length ) newValue = initialDocumentIds;
                  }
                  if ( reason === 'selectOption' ){
                    const [ firstAllOption, ...otherOptions ] = nextValue;
                    const lastAllOption = nextValue.at(-1)
                    if ( firstAllOption === 'all' ){
                      newValue = otherOptions
                    } else if ( lastAllOption === 'all' ){
                      newValue = initialDocumentIds;
                    }
                  }
                }

                onChange(newValue)
              }}
              TextFieldProps={{
                label: t('labels.documents'),
                name
              }}
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => {
                const { onDelete, ...otherTagPros } = getTagProps({ index });
                return (
                  <Chip
                    {...otherTagPros}
                    key={option.value}
                    style={{ maxWidth: '160px' }}
                    label={option.label}
                    title={option.label}
                    size={'medium'}
                    onDelete={option.value === 'all' ? undefined : onDelete}
                  />
                )
              })}
              groupBy={(option) => option.props?.collection?.name || 'all'}
              renderGroup={(params) => {
                if ( params.group === 'all' ) return (
                  <Box key={params.key}>{params.children}</Box>
                );
                return (
                  <Box key={params.key}>
                    <Box sx={{
                      bgcolor: 'rgb(238,238,238)',
                      color: 'rgba(0,0,0,0.87)',
                      fontWeight: 700,
                      py: 2,
                      px: 4
                    }}>{params.group}</Box>
                    {params.children}
                  </Box>
                )
              }}
              multiple={true}
              limitTags={3}
              filterSelectedOptions
            />
          )}
        />

        <Controller
          control={control} name="inWorkspace"
          render={({ field }) => (
            <Select
              {...field}
              sx={{ mb: 4 }}
              label={t('labels.pagesTitle')}
              options={inWorkspaceOptions}
              margin="normal"
            />
          )}
        />

        <Controller
          control={control} name="date"
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={t('labels.date')}
              inputProps={{
                margin: 'dense'
              }}
            />
          )}
        />
        <FormHelperText sx={{ mb: 6 }}>{t('pages.search.hint.matchesAnyDate')}</FormHelperText>

        <OutlineBlock label={t('pages.search.label.chronologyDate')}>
          <Fragment>
            <Controller
              control={control} name="chronologyDateRange.start"
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  onChange={(date: Dayjs | null) => {
                    if (date && date.isAfter(end)) {
                      setValue('chronologyDateRange.end', date.add(1, 'day'));
                    }
                    field.onChange(date);
                  }}
                  label={t('labels.start')}
                  maxDate={end ? end.subtract(1, 'day') : undefined}
                  inputProps={{
                    margin: 'dense'
                  }}
                />
              )}
            />
            <FormHelperText sx={{ mb: 4 }}>{t('pages.search.hint.startDate')}</FormHelperText>

            <Controller
              control={control} name="chronologyDateRange.end"
              render={({ field }) => (
                <DesktopDatePicker
                  {...field}
                  onChange={(date: Dayjs | null) => {
                    if (date && date.isBefore(start)) {
                      setValue('chronologyDateRange.start', date.subtract(1, 'day'));
                    }
                    field.onChange(date);
                  }}
                  label={t('labels.end')}
                  minDate={start ? start.add(1, 'day') : undefined}
                  inputProps={{
                    margin: 'dense'
                  }}
                />
              )}
            />
            <FormHelperText>{t('pages.search.hint.endDate')}</FormHelperText>
          </Fragment>
        </OutlineBlock>
      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid rgba(0,0,0,0.08)',
        p: 4
      }}>
        <Button
          name="Reset search"
          onClick={handleReset}
          fullWidth
        >{t('buttons.reset')}</Button>
        <Button
          name="Submit search"
          type="button"
          onClick={onSubmit}
          variant="contained"
          color="primary"
          fullWidth
        >{t('buttons.search')}</Button>
      </Box>

      <SidebarToggleButton
        name="Search sidebar toggle button"
        open={searchSidebarOpen}
        onClick={handleToggleSearchSidebar}
      />
    </Box>
  )
}

export default SearchSidebar;
