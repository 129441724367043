import { FC, Fragment, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectMonthlyStatsForChart } from 'app/store/Analytics/Analytics.selectors';
// Mui
import { Box, Button, Typography } from '@mui/material';
import { DesktopDatePicker } from 'app/components/Mui';
// 
import MonthlyChart from './MonthlyChart';
// i18next
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

type Props = {
  title?: string;
  onGetMonthlyStats: (params:any) => void;
}

const initialStart = dayjs().subtract(1, 'year').date(1);
const initialEnd = dayjs().date(1).add(1, 'month');

const AnalyticsMonthlyStats:FC<Props> = ({
  // Props
  title = i18n.t('components.analytics.monthlyBreakdown'), onGetMonthlyStats
}) => {
  const { t } = useTranslation();
  // State
  const insuranceCasesData = useAppSelector((state:RootState) => selectMonthlyStatsForChart(state, {
    legendText: t('components.analytics.casesCreated'),
    color: 'gray',
    field: 'numberOfInsuranceCasesCreated'
  }));
  const documentsData = useAppSelector((state:RootState) => selectMonthlyStatsForChart(state, {
    legendText: t('components.analytics.documentPagesImported'),
    color: 'blue',
    field: 'numberOfDocumentPagesImported'
  }));

  const [ monthRangeStart, setMonthRangeStart ] = useState<Dayjs | null>(initialStart);
  const [ monthRangeEnd, setMonthRangeEnd ] = useState<Dayjs | null>(initialEnd);

  useEffect(() => {
    const params = {
      monthRangeStart: monthRangeStart?.format('YYYY-MM-DD'),
      monthRangeEnd: monthRangeEnd?.format('YYYY-MM-DD'),
    };
    onGetMonthlyStats(params);
    // eslint-disable-next-line
  }, []);

  const handleChangeStart = (newValue:Dayjs | null) => {
    setMonthRangeStart(newValue);
  }

  const handleChangeEnd = (newValue:Dayjs | null) => {
    setMonthRangeEnd(newValue);
  }

  const handelClick = () => {
    const params = {
      monthRangeStart: monthRangeStart && monthRangeStart.isValid()
        ? monthRangeStart.date(1).format('YYYY-MM-DD')
        : null
      ,
      monthRangeEnd: monthRangeEnd && monthRangeEnd.isValid()
        ? monthRangeEnd.date(1).add(1, 'month').format('YYYY-MM-DD')
        : null
    };
    onGetMonthlyStats(params);
  }

  return (
    <Fragment>
      <Typography variant="subtitle2" marginBottom={4}>{title}</Typography>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        mb: 4
      }}>
        <DesktopDatePicker
          label={t('labels.start')} value={monthRangeStart} name="start"
          onChange={handleChangeStart}
          inputProps={{
            margin: 'none'
          }}
        />
        <DesktopDatePicker
          label={t('labels.end')} value={monthRangeEnd} name="end"
          onChange={handleChangeEnd}
          inputProps={{
            margin: 'none'
          }}
        />
        <Button
          onClick={handelClick}
          variant="contained"
          color="primary"
        >{t('components.analytics.filterButton')}</Button>
      </Box>
      <Box marginBottom={4}>
        <MonthlyChart data={insuranceCasesData} />
      </Box>
      <Box>
        <MonthlyChart data={documentsData} />
      </Box>
    </Fragment>
  )
}

export default AnalyticsMonthlyStats;
