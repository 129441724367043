import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// Utils
import { getSearchParam } from 'app/utilities/Utilities';
// 
import Highlights, { IHighlight } from './Highlights';

type Props = {
  pageNum: number;
  urlBB?: string;
}

const AutomatedHighlights:FC<Props> = ({ pageNum, urlBB }) => {
  const { search } = useLocation();
  const { pageNum:paramsPageNum = '' } = useParams<{ pageNum:string }>();

  const bb = urlBB || getSearchParam(search, 'bb');
  const isDifferentPageNum = paramsPageNum ? Number(paramsPageNum) !== pageNum : false;
  console.log(isDifferentPageNum)

  if ( !bb || isDifferentPageNum ) return null;

  const highlights = bb.split(',').reduce((acc:IHighlight[], _, index, array) => {
    if ( index % 4 === 0 ){
      const left = parseFloat(array[index]);
      const top = parseFloat(array[index + 1]);
      const right = parseFloat(array[index + 2]);
      const bottom = parseFloat(array[index + 3]);
      acc.push({
        left: `${(left * 100).toFixed(2)}%`,
        top: `${(top * 100).toFixed(2)}%`,
        width: `${((right - left) * 100).toFixed(2)}%`,
        height: `${((bottom - top) * 100).toFixed(2)}%`
      });
    }
    return acc;
  }, []);

  return <Highlights highlights={highlights} type="automated" />
}

export default AutomatedHighlights