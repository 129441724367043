import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectHcpcsCodesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { FormHelperText } from '@mui/material';
// Component
import { Input } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';

const MedicareTabContent:FC = () => {
  const { t } = useTranslation();
  // State
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);

  const { control, formState:{ errors } } = useFormContext();

  if ( !hcpcsCodesEnabled ) return null;
  return (
    <Fragment>
      <Controller
        control={control} name="settings.medicareOverchargeThreshold"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.medicareOverchargeThreshold')} type="number"
            error={Boolean((errors.settings as any)?.medicareOverchargeThreshold)}
            helperText={(errors.settings as any)?.medicareOverchargeThreshold?.message || ''}
            required
          />
        )}
      />
      <FormHelperText>{t('form.helperText.medicareOverchargeThreshold')}</FormHelperText>
    </Fragment>
  )
}

export default MedicareTabContent;
