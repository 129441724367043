import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
// Models
import { IPage } from '@root/models/Page';
// State
import { useSelector } from 'react-redux';
// Selectors
import { selectColumnValue, selectWorkspaceType } from 'app/store/ui/uiSelectors';
import { selectLastOpenedWorkspacePages } from 'app/store/TODO2_app/app.selectors';
import { selectPagesInWorkspaceByPreEvent } from 'app/store/page/page.selectors';
// MaterialUI
import Button from '@mui/material/Button';
// i18next
import { useTranslation } from 'react-i18next';

const CARD_WIDTH = 300;

type Props = {
  listRef: any;
}

const GoToLastOpenedPage:React.FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();

  const columnValue:string = useSelector(selectColumnValue);
  const workspaceType = useSelector(selectWorkspaceType);
  const lastOpenedWorkspacePages:{ [key:number]:string } = useSelector(selectLastOpenedWorkspacePages);
  const pagesWithPreEvent:Array<IPage> | null = useSelector((state:any) => selectPagesInWorkspaceByPreEvent(state, { preEvent: true }));
  const pagesWithoutPreEvent:Array<IPage> | null = useSelector((state:any) => selectPagesInWorkspaceByPreEvent(state, { preEvent: false }));

  const pages = useMemo(() => {
    const result:any = [];
    if ( pagesWithPreEvent && pagesWithPreEvent.length ){
      result.push({ type: 'placeholder', label: 'Pre-event' });
      for ( let i = 0; i < pagesWithPreEvent.length; i++ ){
        result.push({...pagesWithPreEvent[i], type: 'page', index: i });
      }
    }
    if ( pagesWithoutPreEvent && pagesWithoutPreEvent.length ){
      const index = pagesWithPreEvent ? pagesWithPreEvent.length : 0;
      result.push({ type: 'placeholder', label: 'Post-event' });
      for ( let i = 0; i < pagesWithoutPreEvent.length; i++ ){
        result.push({...pagesWithoutPreEvent[i], type: 'page', index: index + i });
      }
    }
    return result;
    // eslint-disable-next-line
  }, [pagesWithPreEvent, pagesWithoutPreEvent]);

  const handleGoToLastOpenedPage = () => {
    const pageId = lastOpenedWorkspacePages[Number(caseId)];
    if ( pageId ){
      const [ docId, pageNum ] = pageId.split(':').map(Number);
      if ( listRef.current ){
        const foundedPageIndex = pages.findIndex((page:any) => page.documentId === Number(docId) && page.pageNum === Number(pageNum));
        if ( typeof foundedPageIndex !== 'undefined' ){
          const width = listRef.current.props.width;
          const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
          const rowIndex = Math.floor(foundedPageIndex/itemsPerRow);
          listRef.current.scrollToItem(rowIndex, 'center');
        }
      }
    }
  }

  if ( workspaceType !== 'pages' || !lastOpenedWorkspacePages[Number(caseId)] ) return null;
  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={handleGoToLastOpenedPage}
    >{t('buttons.goToLastOpened')}</Button>
  )
}

export default GoToLastOpenedPage;
