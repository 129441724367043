import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionsAsOptions } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Typography } from '@mui/material';
// Components
import { Autocomplete } from 'app/components/Mui/Autocompletes';

const DocumentsSettings:FC = () => {
  const { t } = useTranslation();
  // State
  const collectionOptions = useAppSelector(selectCollectionsAsOptions);

  const { control } = useFormContext();

  return (
    <Controller
      control={control} name="document.collectionIds" defaultValue={[]}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={collectionOptions || []}
          TextFieldProps={{
            label: t('labels.documentCollections'), 
            name: field.name,
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
              <Typography variant="subtitle1">{option.label}</Typography>
              {option.props.description ? (
                <Typography variant="caption">{option.props.description}</Typography>
              ) : null}
            </li>
          )}
          multiple={true}
          filterSelectedOptions
        />
      )}
    />
  )
}

export default DocumentsSettings;
