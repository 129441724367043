import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Alert } from '@mui/material';
// 
import useUploadContext from './UploadProvider/useUploadContext';

const FilesUploadAlert:FC = () => {
  const { t } = useTranslation();

  const { formState:{ isLoading } } = useUploadContext();

  if ( !isLoading ) return null;
  return (
    <Alert
      sx={{
        mt: 4
      }}
      color="warning"
      severity="warning"
    >{t('dialogs.caseUploadDocuments.uploadInProgress')}</Alert>
  )
}

export default FilesUploadAlert