import React, { useMemo } from 'react';
// Models
import { IPage } from '@root/models/Page';
// State
import { useSelector } from 'react-redux';
// Selectors
import { selectPagesInWorkspaceByPreEvent } from 'app/store/page/page.selectors';
// MaterialUI
import Button from '@mui/material/Button';
import { selectColumnValue } from 'app/store/ui/uiSelectors';
// i18next
import { useTranslation } from 'react-i18next';

const CARD_WIDTH = 300;

type Props = {
  listRef: any;
}

const GoToPostEvent:React.FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();

  const columnValue:string = useSelector(selectColumnValue);
  const pagesWithPreEvent:Array<IPage> | null = useSelector((state:any) => selectPagesInWorkspaceByPreEvent(state, { preEvent: true }));
  const pagesWithoutPreEvent:Array<IPage> | null = useSelector((state:any) => selectPagesInWorkspaceByPreEvent(state, { preEvent: false }));

  const pages = useMemo(() => {
    const result:any = [];
    if ( pagesWithPreEvent && pagesWithPreEvent.length ){
      result.push({ type: 'placeholder', label: 'Pre-event' });
      for ( let i = 0; i < pagesWithPreEvent.length; i++ ){
        result.push({...pagesWithPreEvent[i], type: 'page', index: i });
      }
    }
    if ( pagesWithoutPreEvent && pagesWithoutPreEvent.length ){
      const index = pagesWithPreEvent ? pagesWithPreEvent.length : 0;
      result.push({ type: 'placeholder', label: 'Post-event' });
      for ( let i = 0; i < pagesWithoutPreEvent.length; i++ ){
        result.push({...pagesWithoutPreEvent[i], type: 'page', index: index + i });
      }
    }
    return result;
    // eslint-disable-next-line
  }, [pagesWithPreEvent, pagesWithoutPreEvent]);

  const handleGoToPostEvent = () => {
    const index = pages.findIndex((p:any) => p.label === 'Post-event');
    if ( listRef.current && index > -1 ){
      const width = listRef.current.props.width;
      const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
      const rowIndex = Math.floor(index/itemsPerRow);
      listRef.current.scrollToItem(rowIndex, 'center');
    }
  }

  return pagesWithPreEvent && pagesWithPreEvent.length && pagesWithoutPreEvent && pagesWithoutPreEvent.length ? (
    <Button
      color="primary"
      variant="outlined"
      onClick={handleGoToPostEvent}
    >{t('pages.workspace.postEventButton')}</Button>
  ) : null
}

export default GoToPostEvent;
