import { ChangeEvent, FC, useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { setFilter } from 'app/store/calendar/calendarSlice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectFilter, selectInWorkspaceEpisodeLabels } from 'app/store/calendar/calendarSelectors';
// Mui
import { Box, Button, TextField, Autocomplete, Tooltip } from '@mui/material';
// Components
import { Select } from 'app/components/Mui';
// Services
import CalendarService from 'app/services/CalendarService';
// 
import CalendarMonth from './CalendarMonth';
// i18next
import { useTranslation } from 'react-i18next';
import IOption from 'app/models/Option';

const now = dayjs();

const Calendar:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase:IInsuranceCase | null = useAppSelector(selectInsuranceCase);
  const episodeLabels = useAppSelector(selectInWorkspaceEpisodeLabels);
  const filter = useAppSelector(selectFilter);

  const [ currentYearAndMonth, setCurrentYearAndMonth ] = useState([now.year(), now.month() + 1]);

  const [ year, month ] = currentYearAndMonth;

  const monthOptions = useMemo(() => {
    return CalendarService.generateMonthsOptions();
  }, []);

  const yearOptions = useMemo(() => {
    return CalendarService.generateYearOptions(year - 10, year + 10);
  }, [year]) as IOption[];

  useEffect(() => {
    if ( !insuranceCase || !insuranceCase.eventDate ) return;

    const [ eventYear, eventMonth ] = insuranceCase.eventDate.split('-').map(Number);
    setCurrentYearAndMonth([ eventYear, eventMonth ]);
    // eslint-disable-next-line
  }, [insuranceCase]);

  const handlePrevMonth = () => {
    let nextYear = year;
    let nextMonth = month - 1;
    if ( nextMonth === 0 ){
      nextYear = year - 1;
      nextMonth = 12
    };
    setCurrentYearAndMonth([nextYear, nextMonth]);
  }

  const handleNextMonth = () => {
    let nextYear = year;
    let nextMonth = month + 1;
    if ( nextMonth === 13 ){
      nextMonth = 1;
      nextYear = year + 1;
    }
    setCurrentYearAndMonth([nextYear, nextMonth]);
  }

  const handleChangeMonth = (event:ChangeEvent<HTMLInputElement>) => {
    setCurrentYearAndMonth([year, Number(event.target.value)]);
  }

  const handleChangeYear = (event:ChangeEvent<HTMLInputElement>) => {
    setCurrentYearAndMonth([Number(event.target.value), month]);
  }

  const handleChangeVisibleMonths = (event:ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilter({ field: 'visibleMonths', value: Number(event.target.value) }));
  }

  const handleEpisodeLabelChange = (_:any, value:string[]) => {
    dispatch(setFilter({ field: 'episodeLabels', value }));
  }

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title={t('pages.calendar.tooltip.previousMonth')}>
            <Button
              onClick={handlePrevMonth}
              color="primary"
              variant="outlined"
            >{t('buttons.prev')}</Button>
          </Tooltip>
          <Select
            style={{ minWidth: 128 }}
            label={t('labels.month')} value={month}
            onChange={handleChangeMonth}
            options={monthOptions}
            size="small"
            fullWidth={false}
            margin="none"
          />
          <Select
            style={{ minWidth: 100 }}
            label={t('labels.year')} value={year}
            onChange={handleChangeYear}
            options={yearOptions}
            size="small"
            fullWidth={false}
            margin="none"
          />
          <Tooltip title={t('pages.calendar.tooltip.nextMonth')}>
            <Button
              onClick={handleNextMonth}
              color="primary"
              variant="outlined"
            >{t('buttons.next')}</Button>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Select
            style={{ minWidth: 112 }}
            label={t('pages.calendar.visibleMonths')} value={filter.visibleMonths}
            onChange={handleChangeVisibleMonths}
            options={[
              { id: 1, name: '1' },
              { id: 2, name: '2' },
              { id: 3, name: '3' }
            ]}
            size="small"
            fullWidth={false}
            margin="none"
          />
          <Autocomplete
            style={{ minWidth: 320 }}
            value={filter.episodeLabels}
            options={episodeLabels || []}
            onChange={handleEpisodeLabelChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('pages.calendar.episodeLabel')}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            multiple
            size="small"
            limitTags={3}
            filterSelectedOptions
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <CalendarMonth visibleMonths={filter.visibleMonths} currentYearAndMonth={currentYearAndMonth} />
        {filter.visibleMonths > 1 ? (
          <CalendarMonth visibleMonths={filter.visibleMonths} currentMonth={1} currentYearAndMonth={currentYearAndMonth} />
        ) : null}
        {filter.visibleMonths === 3 ? (
          <CalendarMonth visibleMonths={filter.visibleMonths} currentMonth={2} currentYearAndMonth={currentYearAndMonth} />
        ) : null}
      </Box>
    </Box>
  )
}

export default Calendar;
