import { FC, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Models
import { RootState } from 'app/store';
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectDocumentsIds, selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Actions
import PageActions from 'app/store/page/page.actions';
// Components
import { Loader, MessageError } from 'app/components/Utilities';
// ToDO
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
// 
import DocumentPagesPage from './DocumentPagesPage';
import DocumentPDFPage from './DocumentPDFPage';
import DocumentMediaPage from './DocumentMediaPage';
import DocumentCustomPage from './DocumentCustomPage';
import DocumentTextPage from './DocumentTextPage';

const DocumentDetailsPage:FC = () => {
  const { t } = useTranslation();

  const { caseId, documentId } = useParams<{ caseId:string, documentId:string }>();
  const { pathname } = useLocation<{ pathname:string }>();

  // Dispathc
  const dispatch = useAppDispatch();
  // State
  const documentIds:number[] | null = useAppSelector(selectDocumentsIds);
  const documentEntity:IDocument = useAppSelector((state:RootState) => selectDocumentEntity(state, {
    documentId: Number(documentId)
  }));

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseFilesView, { id: Number(caseId) });

    if ( !documentEntity ) return;

    if ( documentEntity.deleted || documentEntity.processing.status !== 'ready') {
      const url = pathname.replace(`/${documentId}`, '');
      dispatch(replace(url));
      return;
    }

    const documentElem = document.querySelector(`[data-scroll-id="document-${documentEntity.id}"]`);

    if ( !documentElem ) return;

    documentElem.scrollIntoView({
      block: "center"
    });

    return () => {
      dispatch(PageActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, [documentEntity]);

  // Check `document ids` is they are loaded
  if ( !documentIds ) return <Loader />;
  // Check `documentEntity` is document exist at all
  if ( !documentEntity ) return (
    <MessageError
      status={404}
      title={t('pages.files.noData')}
      text={t('pages.files.noDataHint')}
    />
  );
  return documentEntity.ocred
    ? <DocumentPagesPage />
    : documentEntity.fileFormat === 'custom'
      ? <DocumentCustomPage />
      : documentEntity.fileFormat === 'pdf'
        ? <DocumentPDFPage />
        : documentEntity.fileFormat === 'txt' || documentEntity.fileFormat === 'html'
          ? <DocumentTextPage />
          : <DocumentMediaPage />
  ;
}

export default DocumentDetailsPage;
