import { FC, Fragment } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
import CaseStatuses from 'app/types/CaseStatuses';
// Models
import IInsuranceCase from 'app/models/Case';
import { IMyUser } from 'app/models/User';
// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks/useStore';
// Async
import { archiveInsuranceCase, unarchiveInsuranceCase, statusesInsuranceCase } from 'app/store/Cases/Cases.async';
// Actions
import { AppUiDialogActions } from 'app/store/AppUIDialog/AppUIDialog.slice';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Divider, Theme } from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ClientDetailsDialog from 'app/dialogs/ClientDetailsDialog';
import CaseDetailsDialog from 'app/dialogs/CaseDetailsDialog';
import CaseDuplicateFormDialog from 'app/dialogs/CaseDuplicateFormDialog';
import CaseShareAccessDialog from 'app/dialogs/CaseShareAccessDialog';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import CaseFormDialog from './CaseFormDialog';
import CaseRejectDialog from './CaseRejectDialog';
import CaseDeleteDialog from './CaseDeleteDialog';
// Utilities
import { uuidv4 } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  insuranceCase: IInsuranceCase;
}

const CasesListDropdown:FC<Props> = ({
  // Props
  insuranceCase
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openFormDialog, toggle:toggleFormDialog } = useToggle();
  const { open:openRejectDialog, toggle:toggleRejectDialog } = useToggle();
  const { open:openDeleteDialog, toggle:toggleDeleteDialog } = useToggle();
  const { open:openClientDetailsDialog, toggle:toggleClientDetailsDialog } = useToggle();
  const { open:openDetailsDialog, toggle:toggleDetailsDialog } = useToggle();
  const { open:openDuplicateFormDialog, toggle:toggleDuplicateFormDialog } = useToggle();
  const { open:openShareAccessDialog, toggle:toggleShareAccessDialog } = useToggle();

  const handleOpenLabelsDialog = () => {
    dispatch(AppUiDialogActions.showDialog({
      dialogName: 'CaseLabelsDialog',
      dialogProps: {
        insuranceCaseId: insuranceCase.id,
        labels: insuranceCase.labels,
        version: insuranceCase.version
      }
    }));
  }

  const handleOpenNotesDialog = () => {
    dispatch(AppUiDialogActions.showDialog({
      dialogName: 'CaseNotesDialog',
      dialogProps: {
        insuranceCaseId: insuranceCase.id
      }
    }));
  }

  const isMyUserRoleAdmin = myUser?.role === UserRoles.Admin;
  const isMyUserRoleStaff = myUser?.role === UserRoles.Staff;

  const isCaseStatusPending = insuranceCase.status === CaseStatuses.Pending;
  const isCaseStatusWaiting = insuranceCase.status === CaseStatuses.Waiting;
  const isCaseStatusOpen = insuranceCase.status === CaseStatuses.Open;
  const isCaseStatusArchived = insuranceCase.status === CaseStatuses.Archived;
  const isCaseStatusRejected = insuranceCase.status === CaseStatuses.Rejected;

  const noProcessingOrReady = !insuranceCase?.processing || insuranceCase.processing.status === 'ready';
  const noProcessingOrFailed = !insuranceCase?.processing || insuranceCase.processing.status === 'failed';

  const isDeleteMenuItemVisible = isMyUserRoleAdmin && (noProcessingOrReady || noProcessingOrFailed);
  const isArchiveMenuItemVisible = noProcessingOrReady || noProcessingOrFailed;
  const isCaseDetailsDialogDisabled = insuranceCase.status === CaseStatuses.Archived;

  const CaseDetailsMenuItem = (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-case-details`}
      name={`Case details ${insuranceCase.id}`}
      onClick={toggleDetailsDialog}
    >{t('pages.cases.contextMenu.caseDetails')}</MenuItem>
  );
  const ClientDetailsMenuItem = (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-client-details`}
      name={`Client details ${insuranceCase.id}`}
      onClick={toggleClientDetailsDialog}
    >{t('pages.cases.contextMenu.clientDetails')}</MenuItem>
  );
  const ArchiveMenuItem = isArchiveMenuItemVisible ? (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-archive`}
      name={`Archive case ${insuranceCase.id}`}
      onClick={() => dispatch(archiveInsuranceCase(insuranceCase.id))}
    >{t('pages.cases.contextMenu.archive')}</MenuItem>
  ) : null;
  const MoveToOpenMenuItem = (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-move-to-open`}
      name={`Move to open case ${insuranceCase.id}`}
      onClick={() => dispatch(statusesInsuranceCase({ insuranceCaseId: insuranceCase.id, data: {
        status: CaseStatuses.Open
      }}))}
    >{t('pages.cases.contextMenu.moveToOpen')}</MenuItem>
  );
  const LabelsMenuItem = (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-labels`}
      name={`Case labels ${insuranceCase.id}`}
      onClick={handleOpenLabelsDialog}
    >{t('pages.cases.contextMenu.labels')}</MenuItem>
  );
  const NotesMenuItem = (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-notes`}
      name={`Case notes ${insuranceCase.id}`}
      onClick={handleOpenNotesDialog}
    >{t('pages.cases.contextMenu.notes')}</MenuItem>
  );
  const DeleteMenuItem = isDeleteMenuItemVisible ? (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-delete`}
      sx={{ color: (theme:Theme) => theme.palette.error.main }}
      name={`Delete case ${insuranceCase.id}`}
      onClick={toggleDeleteDialog}
    >{t('pages.cases.contextMenu.delete')}</MenuItem>
  ) : null;
  const DuplicateMenuItem = isMyUserRoleAdmin ? (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-duplicate`}
      name={`Duplicate case ${insuranceCase.id}`}
      onClick={toggleDuplicateFormDialog}
    >{t('pages.cases.contextMenu.duplicate')}</MenuItem>
  ) : null;
  const ShareAccessMenuItem = (isMyUserRoleAdmin || isMyUserRoleStaff) && insuranceCase.clientAccountId ? (
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-share-access`}
      name={`Share access case ${insuranceCase.id}`}
      onClick={toggleShareAccessDialog}
    >{t('pages.cases.contextMenu.shareAccess')}</MenuItem>
  ) : null;

  const pendingMenuItems = [
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-approve`}
      name={`Approve case ${insuranceCase.id}`}
      onClick={toggleFormDialog}
    >{t('pages.cases.contextMenu.approve')}</MenuItem>,
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-reject`}
      name={`Reject case ${insuranceCase.id}`}
      onClick={toggleRejectDialog}
    >{t('pages.cases.contextMenu.reject')}</MenuItem>,
    ClientDetailsMenuItem
  ];
  const waitingMenuItems = [
    CaseDetailsMenuItem,
    LabelsMenuItem,
    NotesMenuItem,
    <Divider key={uuidv4()} />,
    ShareAccessMenuItem,
    DuplicateMenuItem,
    MoveToOpenMenuItem,
    ArchiveMenuItem,
    DeleteMenuItem
  ];
  const openMenuItems = [
    CaseDetailsMenuItem,
    LabelsMenuItem,
    NotesMenuItem,
    <Divider key={uuidv4()} />,
    ShareAccessMenuItem,
    DuplicateMenuItem,
    ArchiveMenuItem,
  ];
  const archivedMenuItems = [
    CaseDetailsMenuItem,
    NotesMenuItem,
    <Divider key={uuidv4()} />,
    <MenuItem
      key={`${insuranceCase.id}-dropdown-item-unarchive`}
      name={`Unarchive case ${insuranceCase.id}`}
      onClick={() => dispatch(unarchiveInsuranceCase(insuranceCase.id))}
    >{t('pages.cases.contextMenu.unarchive')}</MenuItem>,
    DeleteMenuItem
  ];
  const rejectedMenuItems = [
    ClientDetailsMenuItem,
    DeleteMenuItem
  ];

  return (
    <Fragment>
      <IconButton
        sx={{ mt: -1, mr: -2 }}
        name={`Case dropdown ${insuranceCase.id}`}
        aria-label="Case item dropdown button"
        onClick={openMenu}
      ><MoreVertIcon /></IconButton>
      <Menu>
        {isCaseStatusPending ? pendingMenuItems : null}
        {isCaseStatusWaiting ? waitingMenuItems : null}
        {isCaseStatusOpen ? openMenuItems : null}
        {isCaseStatusArchived ? archivedMenuItems : null}
        {isCaseStatusRejected ? rejectedMenuItems : null}
      </Menu>
      {/* Approve */}
      {openFormDialog ? (
        <CaseFormDialog
          open={openFormDialog}
          onClose={toggleFormDialog}
          caseId={insuranceCase.id}
        />
      ) : null}
      {/* Reject */}
      {openRejectDialog ? (
        <CaseRejectDialog
          open={openRejectDialog}
          onClose={toggleRejectDialog}
          insuranceCaseId={insuranceCase.id}
        />
      ) : null}
      {/* Delete */}
      {openDeleteDialog ? (
        <CaseDeleteDialog
          open={openDeleteDialog}
          onClose={toggleDeleteDialog}
          insuranceCase={insuranceCase}
        />
      ) : null}
      {/* Client details */}
      {openClientDetailsDialog ? (
        <ClientDetailsDialog
          open={openClientDetailsDialog}
          onClose={toggleClientDetailsDialog}
          insuranceCaseId={insuranceCase.id}
        />
      ) : null}
      {openDetailsDialog ? (
        <CaseDetailsDialog
          open={openDetailsDialog}
          onClose={toggleDetailsDialog}
          insuranceCaseId={insuranceCase.id}
          disabled={isCaseDetailsDialogDisabled}
        />
      ) : null}
      {openDuplicateFormDialog ? (
        <CaseDuplicateFormDialog
          open={openDuplicateFormDialog}
          onClose={toggleDuplicateFormDialog}
          caseId={insuranceCase.id}
          casesListView={true}
        />
      ) : null}
      {openShareAccessDialog ? (
        <CaseShareAccessDialog
          open={openShareAccessDialog}
          onClose={toggleShareAccessDialog}
          insuranceCaseId={insuranceCase.id}
          clientAccountId={insuranceCase.clientAccountId as number}
        />
      ) : null}
    </Fragment>
  )
}

export default CasesListDropdown;