import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// Types
import { WorkflowType } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Models
import { IWorkflow } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.models';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteWorkflow } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.async';
// Selectors
import { selectLoading } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.selectors';
// Mui
import { Theme, ListItem, ListItemText, ListItemIcon, Tooltip } from '@mui/material';
// Icons
import {
  HdrAuto as HdrAutoIcon,
  FlashOn as FlashOnIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import WorkflowFormDialog from './WorkflowFormDialog';
import WorkflowExecutionsDialog from './WorkflowExecutionsDialog';

type Props = {
  workflow: IWorkflow;
}

const WorkflowItem:FC<Props> = ({
  // Props
  workflow
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();
  const { open:openExecutionsDialog, toggle:toggleExecutionsDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = () => {
    dispatch(deleteWorkflow(workflow.id));
  }

  return (
    <Fragment>
      <ListItem
        sx={{
          bgcolor: (theme:Theme) => !workflow.enabled ? theme.palette.grey[100] : 'white',
          '& + &': {
            borderTop: '1px solid rgba(0,0,0,0.08)'
          }
        }}
        secondaryAction={
          <Fragment>
            <IconButton
              name={`Workflow dropdown ${workflow.id}`}
              aria-label="Prompt item dropdown button"
              onClick={openMenu}
            ><MoreVertIcon /></IconButton>
            <Menu>
              <MenuItem
                name={`Edit workflow ${workflow.id}`}
                onClick={toggle}
              >{t('buttons.edit')}</MenuItem>
              <MenuItem
                name={`Executions ${workflow.id}`}
                onClick={toggleExecutionsDialog}
              >{t('buttons.executions')}</MenuItem>
              <MenuItem
                name={`Delete workflow ${workflow.id}`}
                sx={{
                  color: (theme:Theme) => theme.palette.error.main
                }}
                onClick={toggleConfirmationDialog}
              >{t('buttons.delete')}</MenuItem>
            </Menu>
          </Fragment>
        }
      >
        <ListItemIcon>
          {workflow.type === WorkflowType.Automated ? (
            <Tooltip title={t('pages.automatedWorkflows.speedDialActionAutomated')}>
              <HdrAutoIcon />
            </Tooltip>
          ) : (
            <Tooltip title={t('pages.automatedWorkflows.speedDialActionInstant')}>
              <FlashOnIcon />
            </Tooltip>
          )}
        </ListItemIcon>
        <ListItemText sx={{
          opacity: !workflow.enabled ? '0.5': 'initial',
        }} primary={workflow.name} />
      </ListItem>
      {open ? (
        <WorkflowFormDialog
          open={open}
          onClose={toggle}
          workflow={workflow}
        />
      ) : null}
      {openExecutionsDialog ? (
        <WorkflowExecutionsDialog
          open={openExecutionsDialog}
          onClose={toggleExecutionsDialog}
          workflowId={workflow.id}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('dialogs.confirmation.deleteTitle', { dataType: t('labels.workflow') })}
          content={(
            <Trans
              t={t} i18nKey="dialogs.confirmation.deleteConfirmationWithName"
              components={{ strong: <strong /> }}
              values={{ dataType: t('labels.workflow'), dataName: workflow.name }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default WorkflowItem;
