import { createSelector } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';
// Types
import CalendarLegends from 'app/types/CalendarLegends';
// Models
import { RootState } from 'app/store';
// ToDO: Utilities
import { getVisitColor } from 'utilities/utilities';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
import Reducers from 'app/types/Reducers';
import { sortCalendarDataByTime } from 'app/utilities/SortBy';

export const STORAGE_KEY = 'chart_legend_settings';

export const getParsedLegendsStorage = ():any => {
  const storage = localStorage.getItem(STORAGE_KEY);
  const legends = storage ? JSON.parse(storage) : {};
  return Array.isArray(legends) ? {} : legends;
};

export const selectPreferences = (state:RootState) => state.currentUser.preferences;
export const selectSelectedDate = (state:RootState) => state.currentUser.selectedDate;
export const selectFilter = (state:RootState) => state.currentUser.filter;
export const selectLoading = (state:RootState) => state.currentUser.loading;

export const selectPreferenceByName = createSelector(
  [ selectPreferences ],
  (preferences:any) => {
    if ( !preferences || !preferences.value ) return null;
    return JSON.parse(preferences.value);
  }
);



export const selectEpisodes = createSelector(
  [ selectEpisodesAll ],
  (episodes:any[] | null) => {
    if ( !episodes ) return null;
    return episodes.filter((episode:any) => {
      if ( !episode.showInTimeline ) return false;
      if ( !episode.date ) return false;
      return episode;
    });
  }
);

export const selectWorkspaceEpisodes = createSelector(
  [
    selectEpisodes,
    (state:any) => state.page.pages,
    selectFilter
  ],
  (episodes:any[] | null, pages:any[] | null, { inWorkspace }:any) => {
    if ( !inWorkspace ) return episodes;

    const pagesInWorkspaceIds = pages
      ? pages
          .filter((page:any) => !page.deleted && page.workspaceOrder)
          .map((page:any) => `${page.documentId}:${page.pageNum}`)
      : []
    ;

    return episodes
      ? episodes.filter((episode:any) => pagesInWorkspaceIds.includes(`${episode.documentId}:${episode.pageNum}`))
      : []
    ;
  }
);

export const selectFilteredWorkspaceEpisodes = createSelector(
  [
    selectWorkspaceEpisodes,
    selectFilter
  ],
  (episodes:any[] | null, { labels, authors }:any) => {
    if ( !episodes ) return null;
    return episodes.filter((episode:any) => {
      const hasLabel = labels.length
        ? !episode.labels || !episode.labels.length
          ? false
          : labels.some((label:string) => episode.labels.includes(label))
        : true
      ;
      const hasAuthor = authors.length
        ? !episode.author || !episode.author.name
          ? false
          : authors.find((a:any) => a.value === episode.author.name)
        : true
      ;
      // const hasAuthor = authors.length
      //   ? !episode.author || !episode.author.name
      //     ? false
      //     : authors.includes(episode.author.name)
      //   : true
      // ;
      return hasLabel && hasAuthor;
    })
  }
);

export const selectWorkspaceEpisodeLabels = createSelector(
  [ selectWorkspaceEpisodes ],
  (episodes:any[] | null) => {
    if ( !episodes ) return [];
    const result:string[] = [];
    for ( let episode of episodes ){
      if ( episode.labels ){
        for ( let label of episode.labels ){
          if ( !result.includes(label) ) result.push(label);
        }
      }
    }
    return result.sort();
  }
);

export const selectWorkspaceEpisodeAuthors = createSelector(
  [ selectWorkspaceEpisodes ],
  (episodes:any[] | null) => {
    if ( !episodes ) return [];
    const authorNames:string[] = [];
    const result:any[] = [];
    for ( let episode of episodes ){
      if ( episode.author && episode.author.name ){
        if ( !authorNames.includes(episode.author.name) ){
          authorNames.push(episode.author.name)
          result.push(episode);
        }
      }
    }
    return result.sort((a:any, b:any) => {
      const aName = a.author.name;
      const bName = b.author.name;
      return aName === bName ? 0 : aName > bName ? 1 : -1;
    }).map((ep:any) => ({ value: ep.author.name, label: ep.author.name, verified: ep.author.verified }));
    // return result.sort();
  }
)

export const selectEpisodeLegends = createSelector(
  [
    selectInsuranceCase,
    selectWorkspaceEpisodes,
    selectPreferences
  ],
  (insuranceCase:any, episodes:any[] | null, preferences:any) => {
    const episodeTypes = episodes ? episodes.reduce((acc:any, cur:any) => {
      const type = cur.type || CalendarLegends.Unassigned;
      return acc.includes(type) ? acc : [...acc, type];
    }, []).sort() : [];

    // Episode legends
    const episodeLegends = episodeTypes.reduce((acc:any, key:string) => {
      acc[key] = { key: key, label: key, color: getColorByLabelKey(key), visible: true }
      return acc;
    }, {});
    // Get storage legends
    const storageLegends = insuranceCase && insuranceCase.id
      ? getParsedLegendsStorage()[insuranceCase.id]
        ? getParsedLegendsStorage()[insuranceCase.id]
        : {}
      : {}
    ;
    // DB legends
    const dbLegends = preferences && preferences.value ? JSON.parse(preferences.value) : {};

    if ( insuranceCase ){
      if ( insuranceCase.eventDate ) episodeLegends[CalendarLegends.EventDate] = {
        key: CalendarLegends.EventDate,
        label: CalendarLegends.EventDate,
        color: getColorByLabelKey(CalendarLegends.EventDate),
        visible: true
      }
      if ( insuranceCase.examinationDate ) episodeLegends[CalendarLegends.ExaminationDate] = {
        key: CalendarLegends.ExaminationDate,
        label: CalendarLegends.ExaminationDate,
        color: getColorByLabelKey(CalendarLegends.ExaminationDate),
        visible: true
      }
      if ( insuranceCase.trialDate ) episodeLegends[CalendarLegends.TrialDate] = {
        key: CalendarLegends.TrialDate,
        label: CalendarLegends.TrialDate,
        color: getColorByLabelKey(CalendarLegends.TrialDate),
        visible: true
      }
    }

    const storageLegendsWithKey = Object.keys(storageLegends).reduce((acc:any, cur:any) => {
      if ( storageLegends[cur]['key'] ) acc[cur] = storageLegends[cur];
      return acc;
    }, {});

    const dbLegendsWithKey = Object.keys(dbLegends).reduce((acc:any, cur:any) => {
      if ( dbLegends[cur]['key'] ) acc[cur] = dbLegends[cur];
      return acc;
    }, {});

    const result:any = {};

    for ( let key in episodeLegends ){
      result[key] = episodeLegends[key];
      if ( storageLegendsWithKey[key] ) result[key] = storageLegendsWithKey[key];
      if ( dbLegendsWithKey[key] ) result[key] = dbLegendsWithKey[key];
    }

    return result;
  }
);

export const selectCalendarData = createSelector(
  [
    selectInsuranceCase,
    selectFilteredWorkspaceEpisodes,
    selectEpisodeLegends
  ],
  (insuranceCase:any, episodes:any[] | null, legends:any) => {
    const result:any = {};
    if ( insuranceCase ){
      if ( insuranceCase.eventDate ) result[insuranceCase.eventDate] = [{
        date: insuranceCase.eventDate,
        legend: legends[CalendarLegends.EventDate]
      }]
      if ( insuranceCase.examinationDate ) result[insuranceCase.examinationDate] = [{
        date: insuranceCase.examinationDate,
        legend: legends[CalendarLegends.ExaminationDate]
      }]
      if ( insuranceCase.trialDate ) result[insuranceCase.trialDate] = [{
        date: insuranceCase.trialDate,
        legend: legends[CalendarLegends.TrialDate]
      }]
    }
    if ( !episodes ) return result;
    for ( let episode of episodes ){
      const nextEpisode = {...episode, legend: episode.type ? legends[episode.type] : legends[CalendarLegends.Unassigned] };

      if ( !nextEpisode.legend.visible ) continue;

      if ( result[nextEpisode.date] ){
        result[nextEpisode.date] = [...result[nextEpisode.date], nextEpisode]
      } else {
        result[nextEpisode.date] = [nextEpisode]
      }
    }
    return result;
  }
);

export const selectCalendarDataByDateString = createSelector(
  [
    selectCalendarData,
    (_:any, props:{ dateString:string }) => props
  ],
  (calendarData:any, { dateString }) => calendarData[dateString]?.sort((a:any, b:any) => sortCalendarDataByTime(a, b))
);

export const selectSortedCalendarDates = createSelector(
  [
    selectInsuranceCase,
    selectWorkspaceEpisodes,
  ],
  (insuranceCase:any, episodes:any[] | null) => {
    const result:string[] = [];
    if ( insuranceCase ){
      if ( insuranceCase.eventDate ) result.push(insuranceCase.eventDate);
      if ( insuranceCase.examinationDate ) result.push(insuranceCase.examinationDate);
      if ( insuranceCase.trialDate ) result.push(insuranceCase.trialDate);
    }
    if ( !episodes ) return result;
    for ( let episode of episodes ){
      if ( !result.includes(episode.date) ) result.push(episode.date)
    }
    return result.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }
);

export const selectFullChartData = createSelector(
  [
    selectInsuranceCase,
    selectFilter,
    selectFilteredWorkspaceEpisodes,
    selectEpisodeLegends,
    selectSortedCalendarDates
  ],
  (insuranceCase:any, { showAll }:any, episodes:any[] | null, legends:any, calendarDates:string[]) => {
    const eventsByTypeKey:any = {};
    const eventsPerDate:any = {};

    if ( insuranceCase ){
      if ( insuranceCase.eventDate ){
        eventsByTypeKey[CalendarLegends.EventDate] = {
          [insuranceCase.eventDate]: {
            events: [{
              key: CalendarLegends.EventDate,
              date: insuranceCase.eventDate,
              time: insuranceCase.eventTime || '',
              legend: legends[CalendarLegends.EventDate]
            }]
          }
        };
      }
      if ( insuranceCase.examinationDate ){
        eventsByTypeKey[CalendarLegends.ExaminationDate] = {
          [insuranceCase.examinationDate]: {
            events: [{
              key: CalendarLegends.ExaminationDate,
              date: insuranceCase.examinationDate,
              time: '',
              legend: legends[CalendarLegends.ExaminationDate]
            }]
          }
        }
      }
      if ( insuranceCase.trialDate ){
        eventsByTypeKey[CalendarLegends.TrialDate] = {
          [insuranceCase.trialDate]: {
            events: [{
              key: CalendarLegends.TrialDate,
              date: insuranceCase.trialDate,
              time: '',
              legend: legends[CalendarLegends.TrialDate]
            }]
          }
        }
      }
    }

    if ( episodes ){
      for ( let episode of episodes ){
        const episodeType = episode.type ? legends[episode.type].key : legends[CalendarLegends.Unassigned].key
        const type = `${episodeType}`;

        const hasEventType = eventsByTypeKey[type];
        if ( !hasEventType || !hasEventType[episode.date] ){
          if ( !hasEventType ) eventsByTypeKey[type] = {};
          eventsByTypeKey[type][episode.date] = {
            events: []
          }
        }
        eventsByTypeKey[type][episode.date] = {
          events: [
            ...eventsByTypeKey[type][episode.date]['events'],
            { ...episode, legend: episode.type ? legends[episode.type] : legends[CalendarLegends.Unassigned] }
          ]
        };
        eventsPerDate[episode.date] = !eventsPerDate[episode.date] ? 1 : eventsPerDate[episode.date] + 1;
      }
    }

    const maxEventsPerDate = Object.keys(eventsPerDate).reduce((acc:number, cur:string) => {
      return eventsPerDate[cur] > acc ? eventsPerDate[cur] : acc;
    }, 0);

    if ( insuranceCase && insuranceCase.eventDate ){
      eventsByTypeKey[CalendarLegends.EventDate][insuranceCase.eventDate] = {
        ...eventsByTypeKey[CalendarLegends.EventDate][insuranceCase.eventDate],
        y: maxEventsPerDate + 1
      }
    }

    const viewportMinimum = dayjs(calendarDates[0]).subtract(6, 'month').format('YYYY-MM-DD');
    const viewportMaximum = dayjs(calendarDates[calendarDates.length - 1]).add(6, 'month').format('YYYY-MM-DD');

    const result:any = [];

    for ( let key in eventsByTypeKey ){
      const dataPoints:any = [];
      const legend = legends[key];

      if ( !legend.visible ) continue;

      for ( let date in eventsByTypeKey[key] ){
        const [ year, month, day ] = date.split('-');
        const events = eventsByTypeKey[key][date]['events'];
        dataPoints.push({
          x: new Date(Number(year), Number(month) - 1, Number(day)),
          y: eventsByTypeKey[key][date]['y'] || events.length,
          events
        });
      }
      result.push({
        key,
        type: 'stackedColumn',
        name: key === CalendarLegends.EventDate ? `  ${legend.label}` : legend.label,
        color: legend.color,
        visible: typeof showAll !== 'undefined'
          ? showAll
          : key === CalendarLegends.EventDate
        ,
        dataPoints,
        showInLegend: true
      });
    }

    return {
      maxEventsPerDate,
      fullChartData: result.sort((a:any, b:any) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        return aName === bName ? 0 : aName < bName ? -1 : 1
      }),
      initialViewportMinimum: new Date(viewportMinimum),
      initialViewportMaximum: new Date(viewportMaximum)
    };
  }
);

export const selectDailyChartData = createSelector(
  [
    selectInsuranceCase,
    selectSelectedDate,
    selectCalendarData
  ],
  (insuranceCase:any, selectedDate:Dayjs | null, calendaData:any) => {
    console.log(selectedDate);

    const selectedDateToString = selectedDate ? selectedDate.format('YYYY-MM-DD') : '';

    const episodes = selectedDateToString
      ? calendaData[selectedDateToString]
      : insuranceCase && insuranceCase.eventDate
        ? calendaData[insuranceCase.eventDate]
        : []
    ;

    console.log(calendaData);

    const result:any = [{
      type: 'stackedColumn',
      dataPoints: [
        { x: new Date(), y: 0 }
      ]
    }];

    if ( episodes && episodes.length ){
      const dataPoints:any = [];
      for ( let episode of episodes ){
        if ( !episode.legend.visible ) continue;

        const [ year, month, day ] = episode.date.split('-').map(Number);
        const [ hours, minutes ] = episode.time ? episode.time.split(':').map(Number) : [];
  
        dataPoints.push({
          x: episode.time
            ? new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes))
            : new Date(Number(year), Number(month) - 1, Number(day), 0, 0)
          ,
          y: 1,
          color: episode.legend.color,
          events: [episode]
        });
      }
      result[0]['dataPoints'] = dataPoints;
    }

    return result;
  }
)


const getColorByLabelKey = (key:string):string => {
  if ( key === CalendarLegends.EventDate ) return '#000000';
  if ( key === CalendarLegends.ExaminationDate ) return '#28a745';
  if ( key === CalendarLegends.TrialDate ) return '#6f42c1';
  return getVisitColor(key);
}
