import { FC, Fragment } from 'react';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import InsuranceCasesFilter from './InsuranceCasesFilter';
import InsuranceCasesList from './InsuranceCasesList';
// i18next
import { useTranslation } from 'react-i18next';

const InsuranceCasesPage:FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.cases.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="md">
          <InsuranceCasesFilter />
          <InsuranceCasesList />
        </Container>
      </Box>
    </Fragment>
  )
}

export default InsuranceCasesPage;
