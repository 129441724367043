import config from '../../../config';

import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Quill from 'quill';
// Types
import NotificationStatuses from 'app/types/NotificationStatuses';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
import { generatePresignedUrls } from 'app/store/Content/Сontent.async';
// Mui
import { Theme, Paper, FormControl, FormHelperText, SxProps, Box } from '@mui/material';
// Components
import { Loader } from 'app/components/Utilities';
// Utilities
import { getFontSize } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

import './FontFamilyFormat';
import './FontSizeFormat';
import './TabStopFormat';
import './PageBreakBlot';
import './SpanElements';
import './LinkVariableFormat';

(window as any).Quill = Quill;

// Preserve whiteSpace
class PreserveWhiteSpace {
  constructor(private quill: any, private options: {}) {
    quill.container.style.whiteSpace = "pre-line";
  }
}
Quill.register('modules/preserveWhiteSpace', PreserveWhiteSpace);

// Image resizer
const ImageResize = require('quill-image-resize-module').default;
Quill.register('modules/imageResize', ImageResize);

type Props = {
  id: string;
  label: string;
  value: string;
  required?: boolean;
  disabled? :boolean;
  error?: boolean;
  helperText?: string;
  onChange: (value:string) => void;
  onFocus?: () => void;

  height?: number;

  paperSx?: SxProps;
}

const imageTypes = ['image/jpeg', 'image/png'];
const maxImageWidth = 540;
const maxImageSize = 5 * 1024 * 1024; // 5MB in bytes

const QuillEditor = forwardRef<Props, any>(({
  // Props
  id, label, value, required, disabled = false, error, helperText, onChange, onFocus, height, paperSx = {}
}, ref) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();

  const quillContainerRef = useRef<any>();
  const quillRef = useRef<any>();

  const [ rendered, setRendered ] = useState(false);
  const [ imageLoading, setImageLoading ] = useState(false);

  const uploadQuillImage = useCallback(async (file:File) => {
    // const quill = getQuill as Quill;
    if ( !quillRef.current ) return;

    const quill = quillRef.current;

    if ( file.size > maxImageSize ){
      dispatch(AppUiNotificationsActions.addSnackbar({
        message: t('notifications.quillEditor.sizeError'),
        options: { variant: NotificationStatuses.Error }
      }))
      return;
    }

    setImageLoading(true);

    try {
      const data = {
        file: {
          name: file.name,
          contentType: file.type
        }
      };
      // Save current cursor state
      const selection = quill.getSelection(true);

      const { downloadUrl, uploadUrl } = await dispatch(generatePresignedUrls(data)).unwrap();

      await fetch(uploadUrl, { method: 'PUT', body: file });

      const img = new Image();
      img.src = downloadUrl;
      img.onload = function(){
        const imageWidth = (this as any).width;
        quill.insertEmbed(selection.index, 'image', downloadUrl);
        if ( imageWidth > maxImageWidth ){
          // Insert uploaded image
          quill.formatText(selection.index, 1, 'width', maxImageWidth);
        }
      }
    } catch(error){} finally {
      setImageLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    quillRef.current = new Quill(quillContainerRef.current, {
      theme: 'snow',
      placeholder: disabled ? '' : `${label}${required ? ' *' : ''}`,
      readOnly: disabled,
      modules: {
        preserveWhiteSpace: true,
        toolbar: {
          container: `#toolbar-${id}`,
          handlers: {
            align: function(align:string){
              const quill = (this as any).quill;
              const selection = quill.getSelection();
              if ( !selection ) return;
              quill.formatLine(selection.index, selection.length, 'align', align);
              quill.theme.modules.imageResize.onUpdate();
            },
            variable: function(value:string){
              const quill = (this as any).quill;
              const selection = quill.getSelection();
              if ( !selection ) return;

              quill.insertEmbed(selection.index, 'spanVariable', value);

              setTimeout(() => {
                quill.setSelection(value.length + 1);
              }, 100);
            },
            linkVariable: function(value:string){
              const quill = (this as any).quill;
              const selection = quill.getSelection();
              if ( !selection ) return;
              quill.formatText(selection.index, selection.length, 'link_variable', value);
            },
            image: function(){
              const input = document.createElement('input') as HTMLInputElement;
              input.setAttribute('type', 'file');
              input.setAttribute('accept', imageTypes.join(','));
              input.click();

              input.onchange = async () => {
                if ( !input.files ) return;
                const file = input.files[0];
                uploadQuillImage(file);
              }
            },
            pagebreak: function(){
              const quill = (this as any).quill;
              const selection = quill.getSelection();
              if ( !selection ) return;
              quill.insertEmbed(selection.index, 'pagebreak', true, 'user');
              quill.insertText(selection.index + 1, '\n', 'user');
              quill.setSelection(selection.index + 2);
            },
            header: function(value:string){
              quill.format('header', value);
              const fontSize = getFontSize(value);
              quill.format('size', fontSize || false)
            },
            tabstop: function(value:any){
              const quill = (this as any).quill;
              const selection = quill.getSelection();
              if ( !selection ) return;

              quill.formatLine(selection.index, selection.length, 'tabstop', value);
            }
          }
        },
        imageResize: {
          parchment: Quill.import('parchment'),
          modules: [ 'Resize', 'DisplaySize' ]
        },
        clipboard: {
          matchVisual: false,
          matchers: [
            [Node.ELEMENT_NODE, (node:any, delta:any) => {
              const unsupportedAttrs = ['code-block'];

              delta.ops = delta.ops.map((op:any) => {
                const emptyOp = { insert: '' };

                if ( op.insert && op.insert.image ){
                  if ( isBase64(op.insert.image) ){
                    const filePromise = base64ToFile(op.insert.image);
                    filePromise.then(async (file:File) => {
                      if ( !file ) return;
                      if ( !imageTypes.includes(file.type) ){
                        dispatch(AppUiNotificationsActions.addSnackbar({
                          message: t('notifications.quillEditor.fileNotSupported')
                        }));
                        return;
                      }

                      uploadQuillImage(file);
                    }).catch((err) => {
                      if ( err.message ) dispatch(AppUiNotificationsActions.addSnackbar({
                        message: err.message
                      }));
                    })
                  } else {
                    if ( op.insert.image.includes(config.cdnUrl) ){
                      return op;
                    } else {
                      const filePromise = downloadImageToFile(op.insert.image);
                      filePromise.then(async (file:File | null) => {
                        if ( !file ) return;
                        if ( !imageTypes.includes(file.type) ){
                          dispatch(AppUiNotificationsActions.addSnackbar({
                            message: t('notifications.quillEditor.fileNotSupported')
                          }));
                          return;
                        }
                        uploadQuillImage(file);
                      }).catch((err) => {
                        if ( err.message ) dispatch(AppUiNotificationsActions.addSnackbar({
                          message: err.message
                        }));
                      })
                    }
                  }
    
                  return emptyOp;
                }
                if ( op.attributes ){
                  const hasUnsupportedAttrs = Object.keys(op.attributes).some((key) => unsupportedAttrs.includes(key));
                  if ( hasUnsupportedAttrs ) return { attributes: {}, insert: op.insert };
                }

                if ( node.style.background || node.style.backgroundColor ){
                  node.style.background = '';
                  node.style.backgroundColor = '';
                  op.attributes = {
                    ...(op.attributes || {}),
                    background: '',
                    backgroundColor: ''
                  };
                }

                if ( node.style.fontSize ){
                  op.attributes = {
                    ...(op.attributes || {}),
                    size: node.style.fontSize.includes('px')
                      ? `${pxToPt(parseFloat(node.style.fontSize))}pt`
                      : node.style.fontSize
                  };
                }
                return op;
              });
              return delta;
            }]
          ]
        },
        keyboard: {
          bindings: {
            'list autofill': {
              prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/
            },
            tab: {
              key: 9,
              // @ts-ignore
              handler: (selection, context) => {
                context.format['tab'] = true;
                quill.insertEmbed(selection.index, 'spanTab', '');
                quill.setSelection(selection.index + 1);
                return false;
              }
            }
          }
        }
      }
    });

    const quill = quillRef.current;

    const handleTextChange = () => {
      const innerHTML = quill.root.innerHTML
      onChange(innerHTML === '<p><br></p>' ? '' : innerHTML);
    }

    quill.on('text-change', handleTextChange);

    const handleFocus = () => {
      if ( typeof onFocus === 'undefined' ) return;

      onFocus();
    }

    const handleScroll = () => {
      const { img, onUpdate } = quill.theme.modules.imageResize;

      if ( img ) onUpdate();
    }

    setRendered(true);

    quill.root.addEventListener('focus', handleFocus);
    quill.root.addEventListener('scroll', handleScroll);

    return () => {
      quill.off('text-change', handleTextChange);

      quill.root.removeEventListener('focus', handleFocus);
      quill.root.removeEventListener('scroll', handleScroll);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !rendered ) return;

    const quill = quillRef.current;
    const delta = quill.clipboard.convert(value);

    quill.updateContents(delta);
    // eslint-disable-next-line
  }, [rendered]);

  return (
    <FormControl
      sx={{
        position: 'relative',
        display: 'flex',
        height: height || '100%'
      }}
      className="quill-editor"
      error={error}
    >
      {imageLoading ? (
        <Box sx={{
          position: 'absolute',
          top: 0, left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(255,255,255,0.75)',
          zIndex: 1
        }}><Loader /></Box>
      ) : null}
      <Paper
        translate="no"
        ref={quillContainerRef}
        sx={{
          borderColor: (theme:Theme) => error ? theme.palette.error.main : 'rgba(0,0,0,0.23)',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          ...paperSx
        }}
      />
      {helperText ? (
        <FormHelperText
          sx={{
            position: 'absolute',
            bottom: 0, left: 0,
            mb: 2,
            zIndex: 1
          }}
          error={error}
        >{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  )
})

export default QuillEditor;

const downloadImageToFile = async (url:string) => {
  try {
    const response = await fetch(url);
    if ( !response.ok ) throw new Error('Image fetching error');

    const contentType = response.headers.get('content-type');
    if ( !contentType ) throw new Error('Content type not found in response headers');

    let fileName = `${new Date().getTime()}`;
    let fileType = 'jpg';

    const parsedUrl = new URL(url);
    parsedUrl.search = '';
    const clearUrl = parsedUrl.toString();

    const urlParts = clearUrl.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    const nameAndType = lastPart.split('.');

    if ( nameAndType.length >= 2 ){
      fileName = nameAndType[0];
      fileType = nameAndType[1];
    }

    const blob = await response.blob();

    return new File([blob], `${fileName}.${fileType}`, { type: contentType });
  } catch (error) {
    throw Error('Failed to insert this file');
  }
}

const base64ToFile = async (base64Data:string) => {
  const block = base64Data.split(';');
  const mimeType = block[0].split(':')[1];
  const type = mimeType.split('/')[1];
  const response:Response = await fetch(base64Data);
  const blob:Blob = await response.blob();
  return new File([blob], `${new Date().getTime()}.${type}`, { type: mimeType });
}

const isBase64 = (value:string) => {
  const pattern = new RegExp('data:image\/([a-zA-Z]*);base64,([^\"]*)', 'g');
  return Boolean(value.match(pattern));
}


const pxToPt = (px:number) => {
  const inches = px / 96;
  const points = inches * 72;
  return Math.round(points);
}