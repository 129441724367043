import { ChangeEvent, FC, useState, useEffect } from 'react';
// Models
import ITeam from 'app/models/Team';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { TeamsActions } from 'app/store/Teams/Teams.slice';
// Selectors
import { selectTeams, selectFilter } from 'app/store/Teams/Teams.selectors';
// MaterialUI
import { Box } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// Hooks
import useDebounce from 'app/hooks/useDebounce';
// i18next
import { useTranslation } from 'react-i18next';

const TeamsFilter:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const teams:ITeam[] | null = useAppSelector(selectTeams);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const debounceSearch = useDebounce(stateFilter.search);

  useEffect(() => {
    if ( debounceSearch !== filter.search ){
      dispatch(TeamsActions.setFilter({ field: 'search', value: debounceSearch }));
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  }
  
  const handleClearSearch = () => {
    setStateFilter((prevState:any) => ({
      ...prevState,
      search: ''
    }));
  }

  if ( teams && !teams.length ) return null;
  return (
    <Box sx={{ mb: 4 }}>
      <Input
        label={t('labels.search')} id="search" name="search"
        placeholder={t('form.placeholder.nameOrDescription')}
        value={stateFilter.search}
        onChange={handleChange}
        onClear={handleClearSearch}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default TeamsFilter;
