import { FC } from 'react';
// Models
import IReportPreset from 'app/models/ReportPreset';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredPresets } from 'app/store/ReportPresets/ReportPresets.selectors';
// Mui
import { List, Paper } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import PresetsListItem from './PresetsListItem';
// i18next
import { useTranslation } from 'react-i18next';

const PresetsList:FC = () => {
  const { t } = useTranslation();
  // State
  const presets:IReportPreset[] | null = useAppSelector(selectFilteredPresets);

  if ( !presets ) return <Loader />;
  if ( !presets.length ) return <Message text={t('pages.presets.noData')} />;
  return (
    <Paper>
      <List>
        {presets.map((preset:IReportPreset) => (
          <PresetsListItem
            key={`Preset item ${preset.id}`}
            preset={preset}
          />
        ))}
      </List>
    </Paper>
  )
}

export default PresetsList;
