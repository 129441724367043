import { Action } from 'redux';
// Types
import Statuses from '@root/types/Statuses';
// Models
import { IPage } from '@root/models/Page';
import { IState } from './page.models';

// Types
export enum PageTypes {
  FetchPages = '@@page/Fetch pages',
  FetchPagesSuccess = '@@page/Fetch pages success',

  FetchPage = '@@page/Fetch page',
  FetchPageSuccess = '@@page/Fetch page success',
  FetchPageFailure = '@@page/Fetch page failure',

  UpdatePage = '@@page/Update page',
  UpdatePageSuccess = '@@page/Update page success',

  PatchPage = '@@page/Patch page',
  PatchPageSuccess = '@@page/Patch page success',

  RotatePage = '@@page/Rotate page',
  RotatePageSuccess = '@@page/Rotate page success',

  DeletePage = '@@page/Delete page',
  DeletePageSuccess = '@@page/Delete page success',

  PatchPages = '@@page/Patch pages',
  PatchPagesSuccess = '@@page/Patch pages success',

  ExportPagesToPDF = '@@page/Export pages to pdf',
  ExportPagesToPDFSuccess = '@@page/Export pages to pdf success',

  SetStatus = '@@page/Set status',
  SetInitialField = '@@page/Set initial field',
  SetFilter = '@@page/Set filter',
  // Compare
  AddPageToCompare = '@@page/Add page to compare',
  RemovePageFromCompare = '@@page/Remove page from compare',
  // Staple
  SetStapleModeCreate = '@@page/Set staple mode create',
  SetStapleModeEdit = '@@page/Set staple mode edit',
  CancelStapleMode = '@@page/Cancel staple mode',
  // Add/Remove to/from staple
  AddPageToStaple = '@@page/Add page to staple',
  RemovePageFromStaple = '@@page/Remove page from staple',
  AddAllPagesToStaple = '@@page/Add all pages to staple',

  MovePageInCompare = '@@page/Move page in compare',

  DocumentPagesRefetch = '@@page/Document pages re-fetch',

  UpdatePagesWithSocket = '@@page/Update pages with socket',

  SetPageStaples = '@@page/Set page staples'
};

// Actions types
// Fetch pages
interface FetchPages extends Action<typeof PageTypes.FetchPages> {
  payload: { params:any }
};
interface FetchPagesSuccess extends Action<typeof PageTypes.FetchPagesSuccess> {
  payload: { pages:Array<IPage> }
};

interface FetchPage extends Action<typeof PageTypes.FetchPage> {
  payload: { documentId:number, pageNum:number }
};
interface FetchPageSuccess extends Action<typeof PageTypes.FetchPageSuccess> {
  payload: { page:IPage }
};
interface FetchPageFailure extends Action<typeof PageTypes.FetchPageFailure> {
  payload: { status:number }
};

interface UpdatePage extends Action<typeof PageTypes.UpdatePage> {
  payload: { documentId:number, pageNum:number, data:any, message:string|null }
}
interface UpdatePageSuccess extends Action<typeof PageTypes.UpdatePageSuccess> {
  payload: { page:IPage }
};

interface PatchPage extends Action<typeof PageTypes.PatchPage> {
  payload: { documentId:number, pageNum:number, data:any, message:string|null, pageToRemove?:any }
}
interface PatchPageSuccess extends Action<typeof PageTypes.PatchPageSuccess> {
  payload: { page:IPage, pageToRemove?:any }
};

interface RotatePage extends Action<typeof PageTypes.RotatePage> {
  payload: { documentId:number, pageNum:number, data:any }
}
interface RotatePageSuccess extends Action<typeof PageTypes.RotatePageSuccess> {
  payload: { page:IPage }
};

interface DeletePage extends Action<typeof PageTypes.DeletePage> {
  payload: { documentId:number, pageNum:number }
}
interface DeletePageSuccess extends Action<typeof PageTypes.DeletePageSuccess> {
  payload: { documentId:number, pageNum:number }
};

interface PatchPages extends Action<typeof PageTypes.PatchPages> {
  payload: { data:any, message:string|null, cb?:() => void, pageToRemove?:any }
};
interface PatchPagesSuccess extends Action<typeof PageTypes.PatchPagesSuccess> {
  payload: { pages:Array<IPage>, pageToRemove?:any }
};

interface ExportPagesToPDF extends Action<typeof PageTypes.ExportPagesToPDF> {
  payload: { pages:Array<{ documentId:number, pageNum:number }> }
};
interface ExportPagesToPDFSuccess extends Action<typeof PageTypes.ExportPagesToPDFSuccess> {};

interface SetStatus extends Action<typeof PageTypes.SetStatus> {
  payload: { status:Statuses }
};
interface SetInitialField extends Action<typeof PageTypes.SetInitialField> {
  payload: { field:keyof IState }
};
interface SetFilter extends Action<typeof PageTypes.SetFilter> {
  payload: { field:string, value:any }
};
// Compare
interface AddPageToCompare extends Action<typeof PageTypes.AddPageToCompare> {
  payload: { documentId:number, pageNum:number, stapleId:string|null }
};
interface RemovePageFromCompare extends Action<typeof PageTypes.RemovePageFromCompare> {
  payload: { documentId:number, pageNum:number, stapleId:string|null }
};
// Staple
interface SetStapleModeCreate extends Action<typeof PageTypes.SetStapleModeCreate> {
  payload: { documentId:number }
};
interface SetStapleModeEdit extends Action<typeof PageTypes.SetStapleModeEdit> {
  payload: { documentId:number, stapleId:string, isWorkspacePage:boolean }
};
interface CancelStapleMode extends Action<typeof PageTypes.CancelStapleMode> {};
// Add/Remove to/from staple
interface AddPageToStaple extends Action<typeof PageTypes.AddPageToStaple> {
  payload: { page:IPage }
};
interface RemovePageFromStaple extends Action<typeof PageTypes.RemovePageFromStaple> {
  payload: { page:IPage }
};
interface AddAllPagesToStaple extends Action<typeof PageTypes.AddAllPagesToStaple> {
  payload: { documentId:number }
};

interface MovePageInCompare extends Action<typeof PageTypes.MovePageInCompare> {
  payload: { pageId:string, isNext:boolean }
};

interface DocumentPagesRefetch extends Action<typeof PageTypes.DocumentPagesRefetch> {
  payload: { pages:IPage[] }
};

interface UpdatePagesWithSocket extends Action<typeof PageTypes.UpdatePagesWithSocket> {
  payload: { pages:IPage[] }
};

interface SetPageStaples extends Action<typeof PageTypes.SetPageStaples> {
  payload: { pageStaples:IPage[] | null }
};

export type PageActionTypes =
  FetchPages | FetchPagesSuccess |
  FetchPage | FetchPageSuccess | FetchPageFailure |
  UpdatePage | UpdatePageSuccess |
  PatchPage | PatchPageSuccess |
  RotatePage | RotatePageSuccess |
  DeletePage | DeletePageSuccess |
  PatchPages | PatchPagesSuccess |
  ExportPagesToPDF | ExportPagesToPDFSuccess |
  SetStatus | SetInitialField | SetFilter |
  // Compare
  AddPageToCompare | RemovePageFromCompare |
  // Staple
  SetStapleModeCreate | SetStapleModeEdit | CancelStapleMode |
  // Add/Remove to/from staple
  AddPageToStaple | RemovePageFromStaple | AddAllPagesToStaple |
  MovePageInCompare | DocumentPagesRefetch |
  UpdatePagesWithSocket | SetPageStaples
;