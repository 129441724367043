import { Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import { RootState } from 'app/store';
import IInsuranceCase from 'app/models/Case';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { createDocumentExport } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import {
  selectDocumentsEntities, selectDocumentsAsOptions
} from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectLoading } from 'app/store/DMSDocumentsExports/DMSDocumentsExports.selectors';
// Mui
import { Typography, FormHelperText, Box } from '@mui/material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import Dialog from 'app/components/Dialog'
import Phone from 'app/components/Phone';
import { Button, Input } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// Utility
import { isRequired, isEmailValid } from 'app/utilities/Validations';
import { formatPhoneNumber } from '@root/utilities/utlities';
// Hooks
import useToggle from 'app/hooks/useToggle';

interface IFormData {
  documentIds: number[];
  recipient: {
    email: string;
    phone: string;
  }
};

type Props = {
  open: boolean;
  onClose: () => void;
}

const DocumentExportCreateFormDialog:React.FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const documentEntities = useAppSelector(selectDocumentsEntities);
  const documentOptions = useAppSelector((state:RootState) => selectDocumentsAsOptions(state, {
    excludeCustomFormat: true
  }));
  const loading:boolean = useAppSelector(selectLoading);

  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const { control, handleSubmit, formState:{ errors }, watch, getValues, setValue } = useForm<IFormData>({
    defaultValues: {
      documentIds: [],
      recipient: {
        email: '',
        phone: ''
      }
    }
  });

  const onSubmit = handleSubmit(() => {
    toggleConfirmationDialog();
  });

  const handleSelectAllDocumentsClick = () => {
    setValue('documentIds', documentOptions.map(({ value }) => value));
  }

  const handleConfirm = async () => {
    try {
      const data = getValues();
      await dispatch(createDocumentExport({
        ...data,
        insuranceCaseId: insuranceCase.id
      })).unwrap();
      onClose();
    } catch(error){}
  }

  const watchDocumentIds = watch('documentIds');
  const watchRecipientEmail = watch('recipient.email') as string;
  const watchRecipientPhone = (watch('recipient.phone') as string) || undefined;

  const totalNumberOfPages = [...(watchDocumentIds || [])].reduce((acc:number, cur:number) => {
    const documentEntity = documentEntities[cur];
    return acc + (documentEntity.numberOfPages || 0);
  }, 0);

  const actions = (
    <Fragment>
      <Button
        name="Cancel record export dialog"
        onClick={onClose}
        color="primary"
      >{t('buttons.close')}</Button>
      <Button
        name="Create record export dialog"
        onClick={onSubmit}
        color="primary"
        variant="contained"
      >{t('buttons.create')}</Button>
    </Fragment>
  );

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        title={t('pages.exportMaterials.formTitle')}
        actions={actions}

        PaperProps={{
          component: 'form',
          noValidate: true
        }}
      >
        <Typography display="block" variant="caption" style={{ marginBottom: '16px' }}>
          {t('pages.exportMaterials.formHint')}
        </Typography>

        {/* E-mail */}
        <Controller
          control={control} name="recipient.email"
          rules={{ required: isRequired, pattern: isEmailValid }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.email')}
              error={Boolean(errors.recipient?.email)}
              helperText={errors.recipient?.email ? errors.recipient.email.message : ''}
              required
            />
          )}
        />
        {/* Phone */}
        <Controller
          control={control} name="recipient.phone"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Phone
              {...field}
              label={t('labels.cellPhone')}
              error={Boolean(errors.recipient?.phone)}
              helperText={errors.recipient?.phone ? errors.recipient.phone.message : ''}
              required={true}
            />
          )}
        />

        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
          <Controller
            control={control} name="documentIds"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={documentOptions}
                TextFieldProps={{
                  label: t('labels.documents'),
                  name: field.name,
                  required: true,
                  error: Boolean(errors.documentIds),
                  helperText: (errors.documentIds as any)?.message || '',
                }}
                groupBy={(option) => option.props.collection.name}
                renderGroup={(params) => (
                  <Box key={params.key}>
                    <Box sx={{
                      bgcolor: 'rgb(238,238,238)',
                      color: 'rgba(0,0,0,0.87)',
                      fontWeight: 700,
                      py: 2,
                      px: 4
                    }}>{params.group}</Box>
                    {params.children}
                  </Box>
                )}
                multiple={true}
                limitTags={3}
                filterSelectedOptions
              />
            )}
          />
          <Button
            name="Select all documents"
            sx={{ mt: 6.5 }}
            onClick={handleSelectAllDocumentsClick}
            variant="contained"
          >{t('buttons.all')}</Button>
        </Box>
        <FormHelperText>{t('labels.totalPages')}: {totalNumberOfPages}</FormHelperText>
      </Dialog>
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('pages.exportMaterials.shareConfirmation')}
          content={t('pages.exportMaterials.shareConfirmationContent', { caseName: insuranceCase.name, email: watchRecipientEmail, phone: formatPhoneNumber(watchRecipientPhone) })}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default DocumentExportCreateFormDialog;
