import { FC, Fragment } from 'react';
// Models
import IEpisodeAuthor from 'app/models/EpisodeAuthor';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLoading } from 'app/store/EpisodeAuthors/EpisodeAuthors.selectors';
// Mui
import {
  Theme,
  Box, Chip, Tooltip,
  ListItem, ListItemText
} from '@mui/material';
// Icon
import {
  CheckCircle as CheckCircleIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material'
// Dialog
import AuthorRegistryDetailsDialog from './AuthorRegistryDetailsDialog';
import AuthorRegistryFormDialog from './AuthorRegistryFormDialog';
import AuthorLinkedEpisodesDialog from './AuthorLinkedEpisodesDialog';
import AuthorRegistryDeduplicateDialog from './AuthorRegistryDeduplicateDialog';


// Dialog
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useMenu from 'app/hooks/useMenu';
import { Bull } from 'app/components/Utilities';
import { patchAuthor, deleteAuthor } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  author: IEpisodeAuthor;
}

const AuthorRegistryListItem:FC<Props> = ({
  // Props
  author
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // Store
  const loading = useAppSelector(selectLoading);

  const { openMenu, Menu, MenuItem } = useMenu();
  
  const { open:openDetailsDialog, toggle:toggleDetailsDialog } = useToggle();
  const { open:openFormDialog, toggle:toggleFormDialog } = useToggle();
  const { open:openLinkedEpisodesDialog, toggle:toggleLinkedEpisodesDialog } = useToggle();
  const { open:openDeduplicateDialog, toggle:toggleDeduplicateDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleToggleVerified = () => {
    dispatch(patchAuthor({ authorId: author.id, data: { verified: !author.verified } }));
  }

  const handleConfirm = (params:any = {}) => async () => {
    try {
      await dispatch(deleteAuthor({ authorId: author.id, params })).unwrap();
    } catch(error){
      if ( (error as any).statusCode === 409 ) toggleConfirmationDialog();
    }
  }

  const verifyLable = author.verified ? t('pages.authorRegistry.markUnverified') : t('pages.authorRegistry.markVerified');

  return (
    <Fragment>
      <ListItem
        sx={{
          '& + &': { borderTop: '1px solid rgba(0,0,0,0.08)' }
        }}
        secondaryAction={
          <Fragment>
            <IconButton
              aria-label="Author registry dropdown button"
              name={`Author dropdown ${author.id}`}
              edge="end"
              onClick={openMenu}
            ><MoreVertIcon /></IconButton>
            <Menu>
              <MenuItem
                name={`Details author ${author.id}`}
                onClick={toggleDetailsDialog}
              >{t('pages.authorRegistry.details')}</MenuItem>
              <MenuItem
                name={`Edit author ${author.id}`}
                onClick={toggleFormDialog}
              >{t('pages.authorRegistry.edit')}</MenuItem>
              <MenuItem
                name={`Deduplicate author ${author.id}`}
                onClick={toggleDeduplicateDialog}
              >{t('pages.authorRegistry.markDuplicateOf')}</MenuItem>
              <MenuItem
                name={`Linked episodes author ${author.id}`}
                onClick={toggleLinkedEpisodesDialog}
              >{t('pages.authorRegistry.linkedEpisodes')}</MenuItem> 
              <MenuItem
                name={`${verifyLable} author ${author.id}`}
                onClick={handleToggleVerified}
              >{verifyLable}</MenuItem>
              <MenuItem
                name={`Delete author ${author.id}`}
                sx={{ color: (theme:Theme) => theme.palette.error.main }}
                onClick={handleConfirm()}
              >{t('pages.authorRegistry.delete')}</MenuItem>
            </Menu>
          </Fragment>
        }
      >
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }} component="span">
              {author.verified ? (
                <Tooltip title={t('labels.verified')}>
                  <CheckCircleIcon color="success" />
                </Tooltip>
              ) : null}
              {author.name}
              {author.organization ? (
                <Fragment>
                  <Bull />{t('labels.organization')}
                </Fragment>
              ) : null}
            </Box>
          }
          secondary={
            <Fragment>
              {author.episodeTypes.map((episodeType:string, index:number) => (
                <Chip
                  key={`episode-type-${author.id}-${index}`}
                  sx={{ mr: 2, mt: 2 }}
                  component="span"
                  label={episodeType}
                  variant="outlined"
                  size="small"
                  title={episodeType}
                />
              ))}
            </Fragment>
          }
        ></ListItemText>
      </ListItem>
      {openDetailsDialog ? (
        <AuthorRegistryDetailsDialog
          open={openDetailsDialog}
          onClose={toggleDetailsDialog}
          authorId={author.id}
        />
      ) : null}
      {openFormDialog ? (
        <AuthorRegistryFormDialog
          open={openFormDialog}
          onClose={toggleFormDialog}
          author={author}
        />
      ) : null}
      {openDeduplicateDialog ? (
        <AuthorRegistryDeduplicateDialog
          open={openDeduplicateDialog}
          onClose={toggleDeduplicateDialog}
          author={author}
        />
      ) : null}
      {openLinkedEpisodesDialog ? (
        <AuthorLinkedEpisodesDialog
          open={openLinkedEpisodesDialog}
          onClose={toggleLinkedEpisodesDialog}
          author={author}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          title={t('pages.authorRegistry.deleteTitle')}
          content={(
            <Trans
              t={t} i18nKey="pages.authorRegistry.deleteConfirmation"
              components={{ strong: <strong /> }}
              values={{ authorName: author.name }}
            />
          )}
          loading={loading}
          onConfirm={handleConfirm({ force: true })}
        />
      ) : null}
    </Fragment>
  )
}

export default AuthorRegistryListItem;
