import { ChangeEvent, FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getWorkflows } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.async';
// Actions
import { AutomatedWorkflowsActions } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.slice';
// Selectors
import { getPrompts } from 'app/store/AIChatPrompts/AIChatPrompts.async';
import { selectFilteredWorkflows, selectFilter } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.selectors';
// Components
import DataLoading from 'app/components/DataLoading';
import { Input } from 'app/components/Mui';
// Mui
import {
  Box, Toolbar, Typography, Container, Paper,
  List
} from '@mui/material';
// 
import AutomatedWorkflowsFab from './AutomatedWorkflowsFab';
import WorkflowItem from './WorkflowItem';

const AutomatedWorkflowsPage:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const workflows = useAppSelector(selectFilteredWorkflows);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  // eslint-disable-next-line
  const debounceFilter = useCallback(debounce(500, (field:keyof typeof filter, value:any) => {
    dispatch(AutomatedWorkflowsActions.setFilter({ field, value }))
  }), []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState) => ({
      ...prevState,
      [name]: value
    }));
    debounceFilter(name as keyof typeof filter, value);
  }

  const handleClearFilter = () => {
    dispatch(AutomatedWorkflowsActions.setInitialField('filter'));
  }

  useEffect(() => {
    setStateFilter(filter);
  }, [filter]);

  useEffect(() => {
    dispatch(getPrompts({ owners: 'self,platform' }));
    dispatch(getWorkflows({}));

    return () => {
      handleClearFilter();
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.automatedWorkflows.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Box sx={{ pt: 4, px: 4, width: '100%', maxWidth: '600px', margin: '0 auto' }}>
          <Input
            label={t('labels.search')} id="search" name="search"
            value={stateFilter.search}
            onChange={handleChange}
            size="small"
            margin="none"
            onClear={handleClearFilter}
          />
        </Box>
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <DataLoading
            data={workflows}
            text={t('pages.automatedWorkflows.noData')}
          >
            {(data) => (
              <Paper variant="outlined">
                <List>
                  {data.map((workflow) => (
                    <WorkflowItem
                      key={`workflow item ${workflow.id}`}
                      workflow={workflow}
                    />
                  ))}
                </List>
              </Paper>
            )}
          </DataLoading>
        </Container>
      </Box>
      <AutomatedWorkflowsFab />
    </Fragment>
  );
};

export default AutomatedWorkflowsPage;
