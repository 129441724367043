import { AuditOperations, AuditOperation } from './Audit.types';

export const generateAuditData = (auditOperation:AuditOperation) => {
  const { operation, data } = auditOperation;
  let description = '';
  switch (operation) {
    case AuditOperations.CaseLabelAdding:
    case AuditOperations.CaseLabelRemoving:
      description = buildCaseLabelsDescription(data.userName, data.addedLabels, data.removedLabels);
      break;
    case AuditOperations.DocumentLabelAdding:
    case AuditOperations.DocumentLabelRemoving:
      description = buildDocumentLabelsDescription(data.userName, data.addedLabels, data.removedLabels, data.fileName);
      break;
    case AuditOperations.DocumentExport:
      description = `${data.userName} exported document "${data.fileName}"`;
      break;
    case AuditOperations.CaseShared:
      description = buildCaseShareDescription(data.userName, data.userEmail, data.shared = false);
      break;
    case AuditOperations.CaseNotesUpdate:
      description = `${data.userName} updated case notes`;
      break;
    case AuditOperations.ReportShared:
      description = `${data.userName} shared report "${data.reportName}" to client users "${data.clientUserEmails.join('", "')}"`;
      break;
    case AuditOperations.ReportDeleted:
      description = buildReportDeleteDescription(data.userName, data.reportName, data.clientUserEmails);
      break;
    case AuditOperations.ExportPagesToPDF:
      description = `${data.userName} exported pages to PDF`;
      break;
    case AuditOperations.ExportWorkspacePagesToPDF:
      description = `${data.userName} exported workspace pages to PDF`;
      break;
    default:
      description = 'Unknown operation';
  }
  return { caseId: data.caseId, operation, description };
}

const buildCaseLabelsDescription = (userName:string, addedLabels:string[], removedLabels:string[]):string => {
  let description = '';
  if ( removedLabels.length > 0 ){
    description = `${userName} removed labels "${removedLabels.join('", "')}" from case`;
  }
  if ( addedLabels.length > 0 ){
    description = `${userName} added labels "${addedLabels.join('", "')}" to case`;
  }
  return description;
};

const buildDocumentLabelsDescription = (userName:string, addedLabels:string[], removedLabels:string[], documentFileName:string):string => {
  let description = '';
  if ( removedLabels.length > 0 ){
    description = `${userName} removed label "${removedLabels.join('", "')}" from document "${documentFileName}"`;
  }
  if ( addedLabels.length > 0 ){
    description = `${userName} added label "${addedLabels.join('", "')}" to document "${documentFileName}"`;
  }
  return description;
};

const buildCaseShareDescription = (userName:string, userEmail:string, shared:boolean):string => {
  let description = '';
  if ( shared ) {
    description = `${userName} shared case with client/presenter user "${userEmail}"`;
  } else {
    description = `${userName} stopped case sharing with "${userEmail}"`;
  }
  return description;
};

const buildReportDeleteDescription = (userName:string, reportName:string, clientUserEmails?:string[]):string => {
  let description = '';
  if ( clientUserEmails && clientUserEmails.length ) {
    description = `${userName} deleted report "${reportName}" shared with client users "${clientUserEmails.join('", "')}"`;
  } else {
    description = `${userName} deleted report "${reportName}"`
  }
  return description;
};
