import React from 'react';
import { useParams } from 'react-router-dom';
// State
import { useSelector } from 'react-redux';
// Selectors
import { selectDuplicatesList, selectLoading } from 'app/store/Cases/Cases.selectors';
// MaterialUI
import Tooltip from '@mui/material/Tooltip';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Components
import Icon from 'app/components/Icon';
import useCaseStatus from 'app/hooks/useCaseStatus';
import { updateDuplicates } from 'app/store/Cases/Cases.async';
import { IconButton } from 'app/components/Mui';
import { useAppDispatch } from 'app/hooks/useStore';
import MixpanelTracks from 'app/types/MixpanelTracks';
import Mixpanel from 'app/services/Mixpanel.service';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  documentId: number;
  pageNum: number;
}

const PageCardFooterDuplicate:React.FC<Props> = ({
  // Props
  documentId, pageNum
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const duplicates = useSelector(selectDuplicatesList);
  const loading:boolean = useSelector(selectLoading);

  const { isArchived } = useCaseStatus();

  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const toggleDialog = () => setDialogOpen((prevState:boolean) => !prevState);

  const handleConfirm = React.useCallback(async () => {
    if ( !duplicates ) return;

    const duplicatesToUpdate:any[] = [];

    const notDuplicatePageIds:string[] = [];
    const duplicatedPageIds:string[] = [];

    for ( let cluster of Object.values(duplicates) ){
      const nextCluster = [];
      for ( let page of cluster ){
        if (
          page.documentId === documentId &&
          page.pageNum === pageNum
        ){
          notDuplicatePageIds.push(`${documentId}:${pageNum}`);
          continue;
        } else {
          duplicatedPageIds.push(`${page.documentId}:${page.pageNum}`);
          nextCluster.push(page);
        }
      }
      if ( nextCluster.length > 1 ){
        duplicatesToUpdate.push(nextCluster);
      }
    }

    await dispatch(updateDuplicates({
      insuranceCaseId: Number(caseId),
      data: duplicatesToUpdate,
      message: t('notifications.cardPage.pageDeleteFromDuplicates')
    })).unwrap();

    Mixpanel.track(MixpanelTracks.PageMarkedNotADUplicate, {
      caseId: Number(caseId),
      // Deleted
      notDuplicatePageIds,
      // Remaining
      duplicatedPageIds
    });
    // eslint-disable-next-line
  }, [duplicates])

  return (
    <React.Fragment>
      <Tooltip title={t('components.cardPage.notDuplicateButton')}>
        <span>
          <IconButton
            name="Not a duplicate"
            disabled={isArchived}
            onClick={toggleDialog}
            size="small"
          ><Icon icon="stapled" /></IconButton>
        </span>
      </Tooltip>
      {dialogOpen ? (
        <ConfirmationDialog
          open={dialogOpen}
          onClose={toggleDialog}
          loading={loading}
          status={'initial'}
          title={t('components.cardPage.notDuplicateTitle')}
          content={t('components.cardPage.notDuplicateConfirmation')}
          onConfirm={handleConfirm}
        />
      ) : null}
    </React.Fragment>
  )
}

export default PageCardFooterDuplicate;
