import { ChangeEvent, FC, useState, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { TemplatesActions } from 'app/store/Templates/Templates.slice';
// Selectors
import { selectAllTemplates, selectFilter } from 'app/store/Templates/Templates.selectors';
// MaterialUI
import { Box } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// Hooks
import useDebounce from 'app/hooks/useDebounce';
// i18next
import { useTranslation } from 'react-i18next';

const TemplatesFilter:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const templates = useAppSelector(selectAllTemplates);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const debounceSearch = useDebounce(stateFilter.search);

  useEffect(() => {
    if ( debounceSearch !== filter.search ){
      dispatch(TemplatesActions.setFilter({ field: 'search', value: debounceSearch }));
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  }

  if ( templates && !templates.length ) return null;
  return (
    <Box sx={{ px: 6, py: 4 }}>
      <Input
        label={t('labels.search')} id="search" name="search"
        placeholder={t('form.placeholder.templateName')}
        value={stateFilter.search}
        onChange={handleChange}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default TemplatesFilter;
