import i18n from "i18n";

const Messages = {
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.user') }),
  Invite: i18n.t('notifications.users.invite'),
  Unlock: i18n.t('notifications.users.unlock')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.user') });
  Messages.Invite = i18n.t('notifications.users.invite');
  Messages.Unlock = i18n.t('notifications.users.unlock')
});