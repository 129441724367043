import { FC, Fragment } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
import Permissions from 'app/types/Permissions';
// Models
import { RootState } from 'app/store';
import IUser from 'app/models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// ASync
import { deleteUser, disableUser, enableUser, updateUserMFA } from 'app/store/Users/Users.async';
// Selectors
import { selectLoading } from 'app/store/Users/Users.selectors';
import { selectHcpcsCodesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectCompanyNameByAccountId } from 'app/store/Clients/Clients.selectors';
// Mui
import { Theme, Paper, Box, Divider, Typography } from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock, MailTo } from 'app/components/Utilities';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// Utilities
import { permissionToNormalLabel } from 'app/utilities/Utilities';
// 
import UserFormDialog from './UserFormDialog';
import SharedCasesDialog from './SharedCasesDialog';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  user: IUser;
}

const UsersListItem:FC<Props> = ({
  // Props
  user
}) => {
  const { t } = useTranslation(); 
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);
  const companyName = useAppSelector((state:RootState) => selectCompanyNameByAccountId(state, {
    accountId: user?.customAttributes?.clientAccountId
  }));
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openEditDialog, toggle:toggleEditDialog } = useToggle();
  const { open:openSharedCaseDialog, toggle:toggleSharedCaseDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleEnableOrDisableUser = () => {
    if ( user.enabled ){
      dispatch(disableUser(user.id));
    } else {
      dispatch(enableUser(user.id));
    }
  }
  const handleUpdateUserMFA = () => {
    dispatch(updateUserMFA({ userId: user.id, data: { enabled: !user.mfaEnabled } }));
  }

  const handleConfirm = () => {
    dispatch(deleteUser(user.id));
  }

  const isUserClient = user.role === UserRoles.Client;
  const isUserClientOrPresenter = isUserClient || user.role === UserRoles.Presenter;
  const enableLabel = user.enabled ? t('pages.users.disable') : t('pages.users.enable');
  const mfaEnableLabel = user.mfaEnabled
    ? t('pages.users.turnOff2FAuthentication')
    : t('pages.users.turnOn2FAuthentication')
  ;

  const notifications = user.notificationPreferences
    ? Object.keys(user.notificationPreferences).reduce((acc:string[], cur:any) => {
        if ( (user.notificationPreferences as any)[cur] ){
          acc.push(cur === 'push' ? t('labels.app') : cur === 'email' ? t('labels.email') : t('labels.sms'));
        }
        return acc;
      }, [])
    : []
  ;

  const hcpcsCodesAccountPermission = `${Permissions.HcpcsCodes}:account`;

  const permissions = user.permissions
    ? user.permissions
        .reduce((acc:Permissions[], cur:Permissions) => {
          if ( cur.includes(Permissions.HcpcsCodes) ){
            if ( cur === Permissions.HcpcsCodes ){
              if ( hcpcsCodesEnabled ) acc.push(cur);
            } else {
              if (
                hcpcsCodesEnabled &&
                !user.permissions.includes(Permissions.HcpcsCodes) &&
                !acc.some((p:Permissions) => p.includes(hcpcsCodesAccountPermission))
              ){
                acc.push(cur);
              }
            }
          } else {
            acc.push(cur);
          }
          return acc;
        }, [])
        .map((permission:Permissions) => {
          if ( permission.includes(hcpcsCodesAccountPermission) ) return t('pages.users.hcpcsCodesObtainedFromOtherAccount');
          return permissionToNormalLabel(permission as Permissions);
        })
    : []
  ;

  const userRolesLabels:Record<UserRoles, string> = {
    [UserRoles.AccountManager]: t('pages.users.accountManagerLabel'),
    [UserRoles.Admin]: t('pages.users.adminLabel'),
    [UserRoles.Client]: t('pages.users.clientLabel'),
    [UserRoles.Presenter]: t('pages.users.presenterLabel'),
    [UserRoles.Staff]: t('pages.users.staffLabel'),
  }

  return (
    <Fragment>
      <Paper
        sx={{
          display: 'flex',
          bgcolor: (theme:Theme) => !user.enabled ? theme.palette.grey[100] : 'white',
          p: 4
        }}
        variant="outlined"
      >
        <Box
          sx={{
            flexGrow: 1,
            opacity: !user.enabled ? '0.5': 'initial',
            pr: 2
          }}
        >
          <Typography
            sx={{
              color: (theme:Theme) => theme.palette.primary.main,
              textTransform: 'capitalize'
            }}
            variant="caption"
          >{userRolesLabels[user.role]}</Typography>
          <Typography variant="subtitle1">{user.name}{user.platform ? ' (Case Chronology)' : null}</Typography>
          {!user.platform && user.email ? (
            <InfoBlock label={t('labels.email')} value={<MailTo email={user.email} />} direction="row" />
          ) : null}
          {!user.platform && user.phone ? (
            <InfoBlock label={t('labels.phone')} value={user.phone} direction="row" />
          ) : null}
          {companyName ? (
            <InfoBlock label={t('labels.company')} value={companyName} direction="row" />
          ) : null}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', gap: 4 }}>
            <InfoBlock label={t('pages.users.2FAuth')} value={user.mfaEnabled ? 'On' : 'Off'} />
            {!isUserClient && notifications.length ? (
              <InfoBlock label={t('labels.notifications')} value={notifications.join(', ')} />
            ) : null}
            {permissions.length ? (
              <InfoBlock label={t('labels.permissions')} value={permissions.join(', ')} />
            ) : null}
          </Box>
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`User dropdown ${user.id}`}
            aria-label="User item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            {!isUserClient ? (
              [
                <MenuItem
                  key={`Edit user ${user.id}`}
                  name={`Edit user ${user.id}`}
                  onClick={toggleEditDialog}
                >{t('buttons.edit')}</MenuItem>,
                <MenuItem
                  key={`${enableLabel} user ${user.id}`}
                  name={`${enableLabel} user ${user.id}`}
                  onClick={handleEnableOrDisableUser}
                >{enableLabel}</MenuItem>,
                <MenuItem
                  key={`${mfaEnableLabel} ${user.id}`}
                  name={`${mfaEnableLabel} ${user.id}`}
                  onClick={handleUpdateUserMFA}
                >{mfaEnableLabel}</MenuItem>
              ]
            ) : null}
            {isUserClientOrPresenter ? (
              <MenuItem
                name={`edit-${user.id}`}
                onClick={toggleSharedCaseDialog}
              >{t('buttons.sharedCases')}</MenuItem>
            ) : null}
            <MenuItem
              name={`Delete user ${user.id}`}
              sx={{
                color: (theme:Theme) => theme.palette.error.main
              }}
              onClick={toggleConfirmationDialog}
            >{t('buttons.delete')}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {/* Dialogs */}
      {openEditDialog ? (
        <UserFormDialog
          open={openEditDialog}
          onClose={toggleEditDialog}
          user={user}
        />
      ) : null}
      {openSharedCaseDialog ? (
        <SharedCasesDialog
          open={openSharedCaseDialog}
          onClose={toggleSharedCaseDialog}
          userId={user.id}
          userEmail={user.email}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('dialogs.confirmation.deleteTitle', { dataType: t('labels.user') })}
          content={(
            <Trans
              t={t} i18nKey="dialogs.confirmation.deleteConfirmationWithName"
              components={{ strong: <strong /> }}
              values={{ dataType: t('labels.user'), dataName: user.name }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default UsersListItem;
