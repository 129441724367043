import { ChangeEvent, FC, useMemo, useState } from 'react';
import dayjs from 'dayjs';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Async
import { downloadCalendarFile } from 'app/store/Cases/Cases.async';
// Actions
import { setFilter } from 'app/store/currentUser/currentUserSlice';
// Selectors
import { selectFilter, selectWorkspaceEpisodeLabels, selectWorkspaceEpisodeAuthors, selectSortedCalendarDates } from 'app/store/currentUser/currentUserSelectors';
// Mui
import { Box, Button, FormControlLabel, FormGroup, IconButton, Switch, TextField, Tooltip } from '@mui/material';
// Icons
import { Download as DownloadIcon } from '@mui/icons-material';
// Lab
import { Autocomplete } from '@mui/material';
// Components
import { Select } from 'app/components/Mui';
// import { LoadingButton } from '@mui/lab';
// Services
import CalendarService from 'app/services/CalendarService';

import useMenu from 'app/hooks/useMenu';
// 
import CalendarMonth from './CalendarMonth';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import { useParams } from 'react-router-dom';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';

import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
// i18next
import { useTranslation } from 'react-i18next';
import IOption from 'app/models/Option';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';

const Calendar:FC = () => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode);
  const filter = useSelector(selectFilter);
  const episodeLabels = useSelector(selectWorkspaceEpisodeLabels);
  const episodeAuthors = useSelector(selectWorkspaceEpisodeAuthors);
  const dates = useSelector(selectSortedCalendarDates);
  // const calendarSettings = useSelector(selectCalendarSettings);
  // ToDO
  const litigationCase = useSelector(selectInsuranceCase);
  // const loading = useSelector(selectLoading);

  // const { visibleMonths } = calendarSettings;

  const eventYear = litigationCase?.eventDate ? litigationCase.eventDate.split('-')[0] : dayjs().year();
  const eventMonth = litigationCase?.eventDate ? litigationCase.eventDate.split('-')[1] : dayjs().month() + 1;

  const { Menu, MenuItem, openMenu } = useMenu();

  const [ currentYearAndMonth, setCurrentYearAndMonth ] = useState([ Number(eventYear), Number(eventMonth) ]);
  // const [ stateLoading, setStateLoading ] = useState({
  //   currentRange: false,
  //   fullRange: false
  // });

  const [ year, month ] = currentYearAndMonth;

  const monthOptions = useMemo(() => {
    return CalendarService.generateMonthsOptions();
  }, []);

  const yearOptions = useMemo(() => {
    return CalendarService.generateYearOptions(year - 10, year + 10);
  }, [year]) as IOption[];

  // useEffect(() => {
  //   if ( !loading ) setStateLoading({
  //     currentRange: false,
  //     fullRange: false
  //   });
  //   // eslint-disable-next-line
  // }, [ loading ])

  const handlePrevMonth = () => {
    let nextYear = year;
    let nextMonth = month - 1;
    if ( nextMonth === 0 ){
      nextYear = year - 1;
      nextMonth = 12
    };
    setCurrentYearAndMonth([nextYear, nextMonth]);
  }

  const handleNextMonth = () => {
    let nextYear = year;
    let nextMonth = month + 1;
    if ( nextMonth === 13 ){
      nextMonth = 1;
      nextYear = year + 1;
    }
    setCurrentYearAndMonth([nextYear, nextMonth]);
  }

  const handleChangeMonth = (event:ChangeEvent<HTMLInputElement>) => {
    setCurrentYearAndMonth([year, Number(event.target.value)]);
  }

  const handleChangeYear = (event:ChangeEvent<HTMLInputElement>) => {
    setCurrentYearAndMonth([Number(event.target.value), month]);
  }

  const handleChangeVisibleMonths = (event:ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilter({ field: 'visibleMonths', value: Number(event.target.value) }));
  }

  const handleEpisodeLabelChange = (_:any, value:string[]) => {
    dispatch(setFilter({ field: 'labels', value }));

    Mixpanel.track(MixpanelTracks.CaseCalendarFilter, { caseId: Number(caseId), filterName: 'labels' });
  }

  const handleEpisodeAuthorChange = (_:any, value:string[]) => {
    dispatch(setFilter({ field: 'authors', value }));

    Mixpanel.track(MixpanelTracks.CaseCalendarFilter, { caseId: Number(caseId), filterName: 'authors' });
  }

  const handleInWorkspace = (event:ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilter({ field: 'inWorkspace', value: event.target.checked }));

    Mixpanel.track(MixpanelTracks.CaseCalendarFilter, { caseId: Number(caseId), filterName: 'inWorkspace' });
  }

  const handleDownloadCurrentRange = () => {
    if ( !litigationCase ) return;

    const start = dayjs(`${year}-${month}-01`).format('YYYY-MM-DD');
    const end = dayjs(start).add(filter.visibleMonths, 'month').format('YYYY-MM-DD');
    dispatch(downloadCalendarFile({
      insuranceCaseId: litigationCase.id,
      params: {
        monthRangeStart: start,
        monthRangeEnd: end
      }
    }));
    // setStateLoading((prevState:any) => ({ ...prevState, currentRange: true }));
  }

  const handleDownloadFullRange = () => {
    if ( !litigationCase ) return;

    const start = dayjs(dates[0]).date(1).format('YYYY-MM-DD');
    const end = dayjs(dates[dates.length - 1]).date(1).add(1, 'month').format('YYYY-MM-DD');
    dispatch(downloadCalendarFile({
      insuranceCaseId: litigationCase?.id,
      params: {
        monthRangeStart: start,
        monthRangeEnd: end
      }
    }));
    // setStateLoading((prevState:any) => ({ ...prevState, fullRange: true }));
  }

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Tooltip title={t('pages.calendar.tooltip.previousMonth')}>
            <Button
              onClick={handlePrevMonth}
              color="primary"
              variant="outlined"
            >{t('buttons.prev')}</Button>
          </Tooltip>
          <Select
            style={{ minWidth: '96px' }}
            label={t('labels.month')} value={month}
            onChange={handleChangeMonth}
            options={monthOptions}
            size="small"
            fullWidth={false}
            margin="none"
          />
          <Select
            style={{ minWidth: '96px' }}
            label={t('labels.year')} value={year}
            onChange={handleChangeYear}
            options={yearOptions}
            size="small"
            fullWidth={false}
            margin="none"
          />
          <Tooltip title={t('pages.calendar.tooltip.nextMonth')}>
            <Button
              onClick={handleNextMonth}
              color="primary"
              variant="outlined"
            >{t('buttons.next')}</Button>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, flexGrow: 1 }}>
          <Select
            style={{ maxWidth: 112 }}
            label={t('pages.calendar.visibleMonths')} value={filter.visibleMonths}
            onChange={handleChangeVisibleMonths}
            options={[
              { id: 1, name: '1' },
              { id: 2, name: '2' },
              { id: 3, name: '3' }
            ]}
            size="small"
            margin="none"
          />
          {!trialMode ? (
            <Autocomplete
              style={{ minWidth: 240 }}
              value={filter.labels}
              options={episodeLabels}
              onChange={handleEpisodeLabelChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('pages.calendar.episodeLabel')}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              multiple
              size="small"
              limitTags={3}
              filterSelectedOptions
            />
          ) : null}
          <Autocomplete
            style={{ minWidth: 240 }}
            value={filter.authors}
            options={episodeAuthors}
            onChange={handleEpisodeAuthorChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('pages.calendar.episodeAuthor')}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            multiple
            size="small"
            limitTags={3}
            filterSelectedOptions
            getOptionLabel={(option:any) => option.label}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.verified ? (
                  <CheckCircleIcon sx={{ mr: 2 }} color="success" />
                ) : null}
                {option.label}
              </li>
            )}
            isOptionEqualToValue={(option, value) => {
              if ( value.value ) return option.value === value.value;
              return option.value === value;
            }}
          />
          {!trialMode ? (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={filter.inWorkspace}
                    onChange={handleInWorkspace}
                    color="primary"
                  />
                }
                label={t('labels.inWorkspaceOnly')}
              />
            </FormGroup>
          ) : null}
        </Box>
        {!trialMode ? (
          <Box>
            <Tooltip title={t('buttons.download')}>
              <IconButton
                onClick={openMenu}
              ><DownloadIcon /></IconButton>
            </Tooltip>
            <Menu>
              <MenuItem name="Download current range PDF" onClick={handleDownloadCurrentRange}>{t('pages.calendar.currentRangePdf')}</MenuItem>
              <MenuItem name="Download full range PDF" onClick={handleDownloadFullRange}>{t('pages.calendar.fullRangePdf')}</MenuItem>
            </Menu>
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1
        }}
      >
        <CalendarMonth visibleMonths={filter.visibleMonths} currentYearAndMonth={currentYearAndMonth} />
        {filter.visibleMonths > 1 ? (
          <CalendarMonth visibleMonths={filter.visibleMonths} currentMonth={1} currentYearAndMonth={currentYearAndMonth} />
        ) : null}
        {filter.visibleMonths === 3 ? (
          <CalendarMonth visibleMonths={filter.visibleMonths} currentMonth={2} currentYearAndMonth={currentYearAndMonth} />
        ) : null}
      </Box>
    </Box>
  )
}

export default Calendar;
