import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { StepTasks } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Components
import { Input } from 'app/components/Mui';
// Utilities
import { isEmailValid, isRequired } from 'app/utilities/Validations';
// 
import { useWorkflowContext } from '../Workflow.context'

const SendEmailControls:FC = () => {
  const { t } = useTranslation();

  const { selectedStep, getStepFieldIndex } = useWorkflowContext();

  const { control, formState:{ errors } } = useFormContext();

  if ( !selectedStep || selectedStep.task !== StepTasks.SendEmail ) return null;

  const fieldIndex = getStepFieldIndex(selectedStep.id) as number;

  const toError = (errors as any)?.steps?.[fieldIndex]?.input?.parameters?.to;

  return (
    <Fragment>
      <Controller
        control={control}
        name={`steps[${fieldIndex}].input.parameters.to`}
        rules={{
          required: isRequired,
          pattern: isEmailValid
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.to')} type="email"
            required
            error={Boolean(toError)}
            helperText={toError?.message || ''}
            size="small" />
        )}
      />
      <Controller
        control={control}
        name={`steps[${fieldIndex}].input.parameters.subject`}
        render={({ field }) => (
          <Input {...field} label={t('labels.subject')} size="small" />
        )}
      />
    </Fragment>
  )
}

export default SendEmailControls;