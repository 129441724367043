import { FC, Fragment, ReactNode } from 'react';
// i18next
import i18n from 'i18n';
// Mui
import { Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onUnconfirm?: () => void;
  title?: string;
  content?: ReactNode;
  loading?: boolean;
  closeLabel?: string;
  confirmLabel?: string;
  unconfirmLabel?: string;
}

const ConfirmationDialog:FC<Props> = ({
  // Props
  open,
  onClose,
  onConfirm,
  onUnconfirm,
  title = '',
  content,
  loading = false,
  closeLabel = i18n.getResource(i18n.language, 'translations', 'buttons.close'),
  confirmLabel = i18n.getResource(i18n.language, 'translations', 'buttons.confirm'),
  unconfirmLabel = 'Unconfirm'
}) => {
  const actions = (
    <Fragment>
      <Button
        name={`${closeLabel} dialog`}
        onClick={onClose}
        color="inherit"
      >{closeLabel}</Button>
      {typeof onUnconfirm !== 'undefined' ? (
        <LoadingButton
          name={`${unconfirmLabel} dialog`}
          loading={loading}
          onClick={onUnconfirm}
          variant="contained"
          color="error"
        >{unconfirmLabel}</LoadingButton>
      ) : null}
      <LoadingButton
        name={`${confirmLabel} dialog`}
        loading={loading}
        onClick={onConfirm}
        variant="contained"
        color="error"
      >{confirmLabel}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={title}
      actions={actions}
    >{content ? (
      <Fragment>
        {
          typeof content === 'string'
          ? <Typography variant="body1">{content}</Typography>
          : content
        }
        </Fragment>
      ) : null}
    </Dialog>
  )
}

export default ConfirmationDialog;
