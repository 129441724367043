import { FC, Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Models
import { RootState } from 'app/store';
import IAnnotation from 'app/models/Annotation';
import { IDocumentLink } from 'app/store/DMSDocuments/DMSDocuments.models';
// Async
import { downloadDocument, processDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Selectors
import { selectDocumentEntity } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Toolbar, Tooltip, Typography } from '@mui/material';
// Icons
import { Archive as ArchiveIcon } from '@mui/icons-material';
// Compoentns
import DocumentName from 'app/components/DocumentName';
import { IconButton, Button } from 'app/components/Mui';
import { InfoBlock, MessageError } from 'app/components/Utilities';
// Dialogs
import ConsentToExportDialog from 'app/dialogs/ConsentToExportDialog';
// Hooks
import useIsDocumentProcessAllowed from 'app/hooks/useIsDocumentProcessAllowed';
import useToggle from 'app/hooks/useToggle';
// Constants
import { SUPPORTED_PREVIEW_FILE_FORMATS } from 'app/App.constants';
import { createAuditRecord } from 'app/store/Audit/Audit.async';
import { AuditOperations } from 'app/store/Audit/Audit.types';
import { selectMyUser } from 'app/store/Users/Users.selectors';

const DocumentPDFPage:FC = () => {
  const { t } = useTranslation();

  const { caseId, documentId } = useParams<{ caseId:string; documentId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser);
  const documentEntity = useAppSelector((state:RootState) => selectDocumentEntity(state, {
    documentId: Number(documentId)
  }));

  const { showProcessHintAndButton } = useIsDocumentProcessAllowed(documentEntity);

  const { open, toggle } = useToggle();

  const links = useMemo(() => {
    if ( !documentEntity.links ) return {};
    return documentEntity.links.reduce((acc:Record<string, IDocumentLink>, cur:IDocumentLink) => {
      acc[cur.rel] = cur;
      return acc;
    }, {});
  }, [documentEntity.links]);

  const handleProcessDocument = () => {
    dispatch(processDocument(documentEntity.id));
  }

  const handleConfirm = async () => {
    toggle();

    if ( !links.download ) return;

    try {
      await dispatch(downloadDocument({
        url: links.download.href,
        documentName: documentEntity.name
      })).unwrap();
      await dispatch(createAuditRecord({
        operation: AuditOperations.DocumentExport,
        data: {
          caseId: +caseId,
          userName: myUser?.name || '',
          fileName: documentEntity.name
        }
      }));
    } catch(error){
      console.log(error);
    }
  }

  const href = (links.preview && links.preview.href) || '';

  return (
    <Fragment>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Toolbar sx={{ py: 2, height: 'initial' }}>
          <Box sx={{ flexGrow: 1 }}>
            {showProcessHintAndButton ? (
              <Typography variant="caption" color="red">{t('pages.files.processHint')}</Typography>
            ) : null}
            <DocumentName />
            {documentEntity.annotations && documentEntity.annotations.length ?
              documentEntity.annotations.map((annotation:IAnnotation, index) => (
                <InfoBlock
                  key={`annotation item ${index}`}
                  label={annotation.name}
                  value={annotation.value || 'N/A'}
                  direction="row"
                />
              ))
            : null}
          </Box>
          {showProcessHintAndButton ? (
            <Button
              sx={{ mr: 2 }}
              name="Process docuemnt"
              onClick={handleProcessDocument}
              variant="outlined"
              size="small"
            >{t('buttons.process')}</Button>
          ) : null}
          <Tooltip title={t('buttons.download')}>
            <IconButton
              name={`Download ${documentEntity.id}`}
              onClick={toggle}
            ><ArchiveIcon /></IconButton>
          </Tooltip>
        </Toolbar>
        {SUPPORTED_PREVIEW_FILE_FORMATS.includes(documentEntity.fileFormat) ? (
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <embed
              src={href}
              type="application/pdf"
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          </Box>
        ) : (
          <MessageError
            title={t('pages.files.previewNotAvailable')}
            text=""
            status={500}
          />
        )}
      </Box>
      {open ? (
        <ConsentToExportDialog
          open={open}
          onClose={toggle}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default DocumentPDFPage;
