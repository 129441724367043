import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Types
import Features from 'app/types/Features';
// Models
import IClientAccount from 'app/models/ClientAccount';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { updateAccount } from 'app/store/Clients/Clients.async';
// Selectors
import { selectLoading } from 'app/store/Clients/Clients.selectors';
// Mui
import { FormGroup } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { OutlineBlock } from 'app/components/Utilities';
import { Button, Switch, LoadingButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  features: (Features)[];
}

type Props = {
  open: boolean;
  onClose: () => void;
  account: IClientAccount;
}

const ClientAccountUpdateDialog:FC<Props> = ({
  // Props
  open, onClose, account
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading)

  const { control, handleSubmit } = useForm<IFormData>({
    defaultValues: {
      features: account?.features || []
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      await dispatch(updateAccount({
        accountId: account.id,
        data
      })).unwrap();
      onClose();
    } catch(error){}
  });

  const actions = (
    <Fragment>
      <Button
        name="Cancel client account update dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Update client account dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.update')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.clientAccounts.updateFormTitle')}
      actions={actions}
    >
      <form noValidate>
        <OutlineBlock sx={{ mt: 4 }} label={t('labels.features')}>
          <Controller
            control={control} name="features"
            render={({ field: { value, onChange } }) => {
              const handleChange = (feature:Features) => () => {
                const hasFeature = (value as (Features)[]).includes(feature);
                const nextFeatures = hasFeature
                  ? (value as (Features)[]).filter((v) => v !== feature)
                  : [...value, feature]
                ;
                onChange(nextFeatures);
              }
              return (
                <FormGroup>
                  <Switch
                    name="Patient portal"
                    label={t('labels.patientPortal')}
                    value={value.includes(Features.PatientPortal)}
                    onChange={handleChange(Features.PatientPortal)}
                  />
                </FormGroup>
              )
            }}
          />
        </OutlineBlock>
      </form>
    </Dialog>
  )
}

export default ClientAccountUpdateDialog;
