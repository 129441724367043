import { FC, Fragment } from 'react';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { switchAccount } from 'app/store/Auth/Auth.async';
// Selectors
import { selectUserAccounts } from 'app/store/Accounts/Accounts.selectors';
// Dialogs
import MultiAccountsDialog from 'app/dialogs/MultiAccountsDialog';
// Components
import { Button } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

const SwitchAccountButton:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const userAccounts = useAppSelector(selectUserAccounts);

  const { open, toggle } = useToggle();

  const handleClick = async (accountId?:number) => {
    toggle();

    if ( !accountId ) return;

    dispatch(switchAccount(accountId))
  };

  if ( !userAccounts.length || userAccounts.length === 1 ) return null;
  return (
    <Fragment>
      <Button
        name="Switch company"
        onClick={toggle}
        fullWidth
        variant="outlined"
      >{t('buttons.switchCompany')}</Button>
      {open ? (
        <MultiAccountsDialog
          open={open}
          onClose={handleClick}
        />
      ) : null}
    </Fragment>
  );
}

export default SwitchAccountButton