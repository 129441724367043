import { FC } from 'react';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getAccountStats, getAccountMonthlyStats } from 'app/store/Analytics/Analytics.async';
// Mui
import { Button, Divider } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { AnalyticsStats, AnalyticsMonthlyStats } from 'app/components/Analytics';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  accountId: number;
};

const AccountAnalyticsDialog:FC<Props> = ({
  // Props
  open, onClose, accountId
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();

  const handleGetAccountStats = () => {
    dispatch(getAccountStats(accountId));
  }

  const handleGetAccountMonhtlyStats = (params:any) => {
    dispatch(getAccountMonthlyStats({ accountId, params }));
  }

  const actions = (
    <Button
      onClick={onClose}
    >{t('buttons.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      title={t('labels.usageReport')}
      actions={actions}
    >
      <AnalyticsStats onGetStats={handleGetAccountStats} />
      <Divider sx={{ my: 4 }} />
      <AnalyticsMonthlyStats onGetMonthlyStats={handleGetAccountMonhtlyStats} />
    </Dialog>
  );
}

export default AccountAnalyticsDialog;
