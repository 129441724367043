import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { setCloudfrontCookies } from 'app/store/Content/Сontent.async';
import { getInsuranceCase, getDuplicates } from 'app/store/Cases/Cases.async';
import { getAlerts } from 'app/store/CaseAlerts/CaseAlerts.async';
import { getBatches } from 'app/store/DMSBatches/DMSBatches.async';
import { getDocuments } from 'app/store/DMSDocuments/DMSDocuments.async';
import { getPages } from 'app/store/DMSDocumentPages/DMSDocumentPages.async';
import { getEpisodes } from 'app/store/Episodes/Episodes.async';
import { getTemplates, getGlobalTemplates } from 'app/store/Templates/Templates.async';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
import { CasesActions } from 'app/store/Cases/Cases.slice';
import { CaseAlertsActions } from 'app/store/CaseAlerts/CaseAlerts.slice';
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
import { DMSDocumentsActions } from 'app/store/DMSDocuments/DMSDocuments.slice';
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
import { TemplatesActions } from 'app/store/Templates/Templates.slice';

import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// 
import CaseDetailsToolbar from 'app/pages/Staff/CaseDetailsPage/CaseDetailsToolbar';
import CaseDetailsRouting from 'app/routes/CaseDetailsRouting'
import CaseDetailsScrollTop from './CaseDetailsScrollTop';
import CaseStatuses from 'app/types/CaseStatuses';
import PageImagePreview from 'app/components/PageImagePreview'

const CaseDetailsPage = () => {
  const { caseId } = useParams();

  const params = { insuranceCaseId: Number(caseId) };

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase);

  useEffect(() => {
    const casePromise = dispatch(getInsuranceCase(params.insuranceCaseId));

    return () => {
      // Abort dispatch
      if ( casePromise ) casePromise.abort();

      dispatch(AppUIActions.setInitialField('filesSidebarTab'));

      dispatch(CasesActions.resetState());
      dispatch(CaseAlertsActions.resetState());
      dispatch(DMSBatchesActions.resetState());
      dispatch(DMSDocumentsActions.resetState());
      dispatch(DMSDocumentPagesActions.resetState());
      dispatch(EpisodesActions.resetState());

      dispatch(TemplatesActions.setInitialField('templates'));

      dispatch(PageActions.setInitialField('comparePagesIds'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !insuranceCase ) return;

    const cloudFrontPromise = dispatch(setCloudfrontCookies(params));
    const alertsPromise = dispatch(getAlerts({ caseId: params.insuranceCaseId }));
    const batchesPromise = dispatch(getBatches(params));
    const documentsPromise = dispatch(getDocuments(params));
    const pagesPromise = dispatch(getPages(params));
    const episodesPromise = dispatch(getEpisodes({...params, fields: 'author'}));
    const templatesPromise = dispatch(getTemplates({ caseId: params.insuranceCaseId }));
    const globalTemplatesPromise = dispatch(getGlobalTemplates());

    let duplicatesPromise = null;

    const isCaseStatusOpen = insuranceCase?.status === CaseStatuses.Open;
    const isCaseStatisArchive = insuranceCase?.status === CaseStatuses.Archived;
    const isCaseProcessingStatusReady = insuranceCase?.processing?.status === 'ready';

    if ( (isCaseStatusOpen || isCaseStatisArchive) && isCaseProcessingStatusReady ){
      duplicatesPromise = dispatch(getDuplicates(params.insuranceCaseId));
    } else {
      dispatch(CasesActions.setDuplicates([]));
    }

    return () => {
      if ( cloudFrontPromise ) cloudFrontPromise.abort();
      if ( alertsPromise ) alertsPromise.abort();
      if ( batchesPromise ) batchesPromise.abort();
      if ( documentsPromise ) documentsPromise.abort();
      if ( pagesPromise ) pagesPromise.abort();
      if ( episodesPromise ) episodesPromise.abort();
      if ( duplicatesPromise ) duplicatesPromise.abort();
      if ( templatesPromise ) templatesPromise.abort();
      if ( globalTemplatesPromise ) globalTemplatesPromise.abort();
    }
    // eslint-disable-next-line
  }, [insuranceCase?.id]);

  if ( !insuranceCase ) return null;
  return (
    <React.Fragment>
      <CaseDetailsToolbar />
      <CaseDetailsRouting />
      <CaseDetailsScrollTop showBelow={240} />

      <PageImagePreview />
    </React.Fragment>
  )
}

export default CaseDetailsPage;
