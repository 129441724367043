import { FC, Fragment, useEffect } from 'react';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getSubscriptions } from 'app/store/Subscriptions/Subscriptions.async';
// Actions
import { SubscriptionsActions } from 'app/store/Subscriptions/Subscriptions.slice';
// Selectors
import { selectParams, selectSortedSubscriptions } from 'app/store/Subscriptions/Subscriptions.selectors';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// Components
import DataLoader from 'app/components/DataLoader';
// 
import SubscriptionsFilter from './SubscriptionsFilter';
import SubscriptionItem from './SubscriptionItem';
import SubscriptionsFab from './SubscriptionsFab';
// i18next
import { useTranslation } from 'react-i18next';

const SubscriptionsPage:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const params = useAppSelector(selectParams);

  useEffect(() => {
    return () => {
      dispatch(SubscriptionsActions.resetState());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.subscriptions.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            py: 4
          }}
          maxWidth="md"
        >
          <SubscriptionsFilter />
          <DataLoader
            asyncThunkFn={getSubscriptions}
            params={params}
            selector={selectSortedSubscriptions}
            noDataText={t('pages.subscriptions.noData')}
          >
            {( data ) => (
              data.map((subscription) => (
                <SubscriptionItem
                  key={`subscriptionItem-${subscription.id}`}
                  subscription={subscription}
                />
              ))
            )}
          </DataLoader>
        </Container>
      </Box>
      <SubscriptionsFab />
    </Fragment>
  )
}

export default SubscriptionsPage;