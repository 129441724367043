import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.dmsBatches.create'),
  Update: i18n.t('notifications.dmsBatches.update'),
  DeleteCoverLetter: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.coverLetter') })
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.dmsBatches.create');
  Messages.Update = i18n.t('notifications.dmsBatches.update');
  Messages.DeleteCoverLetter = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.coverLetter') });
});