import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Models
import IReportPreset from 'app/models/ReportPreset';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLoading as selectReportsLoading } from 'app/store/CaseReports/CaseReports.selectors';
import { selectLoading as selectPresetsLoading } from 'app/store/ReportPresets/ReportPresets.selectors';
// Mui
import { Box, Divider, Tooltip } from '@mui/material';
// Icons
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material';
// Dialogs
import ContentPreviewDialog from 'app/dialogs/ContentPreviewDialog';
// Components
import { Input, Button, LoadingButton, IconButton } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Context
import { useReportPresetContext } from 'app/context/ReportPreset.context';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  preset?: IReportPreset;
  onClose: () => void;
}

const DialogTitle:FC<Props> = ({
  // Props
  preset, onClose
}) => {
  const { t } = useTranslation();
  // State
  const reportsLoading = useAppSelector(selectReportsLoading);
  const presetsLoading = useAppSelector(selectPresetsLoading);

  const { open, toggle } = useToggle();

  const { caseId, content } = useReportPresetContext();
  const { control, formState: { errors } } = useFormContext();

  const loading = caseId ? reportsLoading : presetsLoading;

  return (
    <Fragment>
      <Box sx={{
        display: 'flex',
        alignItems: 'flex-start',
        borderBottom: '1px solid rgba(0,0,0,0.08)',
        px: 6,
        py: 4
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 4
        }}>
          <Controller
            control={control} name="name"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                sx={{ width: 351 }}
                placeholder={t('labels.name')}
                required
                error={Boolean(errors.name)}
                helperText={(errors.name?.message as string) || ''}
                margin="none"
                size="small"
                fullWidth={false}
              />
            )}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}>
          {caseId && content.length ? (
            <Fragment>
              <Tooltip title={t('dialogs.reportPreset.previewButton')}>
                <IconButton
                  name="Preview report form dialog"
                  onClick={toggle}
                ><VisibilityOutlinedIcon /></IconButton>
              </Tooltip>
              <Divider sx={{ height: 32 }} orientation="vertical" />
            </Fragment>
          ) : null}
          <Button
            name="Cancel report form dialog"
            onClick={onClose}
            variant="outlined"
          >{t('buttons.close')}</Button>
          <LoadingButton
            name={`${preset ? 'update' : 'create'} report form dialog`}
            type="submit"
            loading={loading}
            variant="contained"
          >{preset ? t('buttons.update') : t('buttons.create')}</LoadingButton>
        </Box>
      </Box>
      {open ? (
        <ContentPreviewDialog
          open={open}
          onClose={toggle}
          content={content}
        />
      ) : null}
    </Fragment>
  )
}

export default DialogTitle;
