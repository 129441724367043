import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { updateMyPreferences } from 'app/store/currentUser/currentUserAsync';
// Selectors
import { selectEpisodeLegends, selectLoading } from 'app/store/currentUser/currentUserSelectors';
// Mui
import {
  Button,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  label: string;
  color: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  legend: any;
}

const LegendFormDialog:FC<Props> = ({
  // Props
  open, onClose, legend
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const legends = useAppSelector(selectEpisodeLegends);
  const isLoading:boolean = useAppSelector(selectLoading);

  const { handleSubmit, control, formState: { errors } } = useForm<IFormData>({
    defaultValues: {
      label: legend.label,
      color: legend.color
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const preferenceName = `case:${caseId}.chart.legend`;
    try {
      await dispatch(updateMyPreferences({
        preferenceName,
        data: {
          name: preferenceName,
          value: JSON.stringify({
            ...legends,
            [legend.key]: {...legend, ...data}
          })
        }
      })).unwrap();
      onClose();
    } catch(error){}
  });

  const handleClose = (_:any, reason:string) => {
    if ( reason === 'backdropClick' ) return;
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t('pages.calendar.legendFormTitle')}</DialogTitle>
      <DialogContent dividers>
        <form noValidate id="legendForm">
          {/* Label */}
          <Controller
            control={control} name="label" rules={{ required: isRequired }}
            render={(props) => (
              <Input
                {...props}
                label={t('labels.label')} required
                error={Boolean(errors.label)}
                helperText={errors.label ? errors.label.message : ''}
              />
            )}
          />
          {/* Color */}
          <Controller
            control={control} name="color"
            render={(props) => (
              <Input
                {...props}
                label={t('labels.color')} type="color"
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
        >{t('buttons.close')}</Button>
        <Button
          form="legendForm"
          disabled={isLoading}
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >{t('buttons.save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default LegendFormDialog;
