import { FC, Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLegacyReportTemplatesEnabled, selectHcpcsCodesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectExportTemplatesAsOptions } from 'app/store/ExportTemplates/ExportTemplates.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { OutlineBlock, InfoBlock } from 'app/components/Utilities';
// Utilities
import { convertDateToString, convertTimeToString } from 'app/utilities/DateTime';

interface IFormData {
  name: string;
  reportTemplateId: string | number;
  teamId: string | number;
  clientAccountId?: string | number;
  claimant: {
    name: string;
    dateOfBirth: Dayjs | null;
  },
  settings: {
    medicareOverchargeThreshold: number;
  };
  eventDate: Dayjs | null,
  eventTime: Dayjs | null;
  claimNumber: string;
  notificationPreferences: {
    emails: string[];
  }
};

const ReviewCreateStepContent:FC = () => {
  const { t } = useTranslation();

  // State
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);
  const teamOptions = useAppSelector(selectTeamsAsOptions);
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const exportTemplatesOptions = useAppSelector(selectExportTemplatesAsOptions);

  const { getValues } = useFormContext<IFormData>();

  const formData = getValues();

  const teamName = useMemo(() => {
    if ( !teamOptions ) return '';
    const foundedOption = teamOptions.find(({ value }) => value === Number(formData.teamId));
    return foundedOption?.label || '-';
    // eslint-disable-next-line
  }, [teamOptions, formData.teamId]);

  const clientAccountName = useMemo(() => {
    if ( !accountOptions ) return '';
    const foundedOption = accountOptions.find(({ value }) => value === Number(formData.clientAccountId));
    return foundedOption?.label || '-';
    // eslint-disable-next-line
  }, [accountOptions, formData.clientAccountId]);

  const reportTemplateName = useMemo(() => {
    if ( !legacyReportTemplatesEnabled || !exportTemplatesOptions ) return undefined;
    const foundedReportTemplate = exportTemplatesOptions.find(r => r.id === Number(formData.reportTemplateId));
    return foundedReportTemplate?.name || '-';
    // eslint-disable-next-line
  }, [exportTemplatesOptions, formData.reportTemplateId]);

  const medicareOverchargeThreshold = useMemo(() => {
    if ( !hcpcsCodesEnabled ) return undefined;
    return formData.settings.medicareOverchargeThreshold || '-';
    // eslint-disable-next-line
  }, [formData.settings.medicareOverchargeThreshold]);

  const eventDate = formData.eventDate ? convertDateToString(formData.eventDate) as string : '-';
  const eventTime = formData.eventTime ? convertTimeToString(formData.eventTime) as string : '-';
  const claimName = formData.claimant.name || '-';
  const dateOfBirth = formData.claimant.dateOfBirth ? convertDateToString(formData.claimant.dateOfBirth) as string : '-';
  const claimNumber = formData.claimNumber || '-';

  return (
    <Box sx={{ pt: 2 }}>
      <OutlineBlock label={t('labels.general')}>
        <Fragment>
          <InfoBlock label={t('labels.caseName')} value={formData.name || '-'} direction="row" />
          <InfoBlock label={t('labels.eventDate')} value={eventDate} direction="row" />
          {formData.eventTime ? (
            <InfoBlock label={t('labels.eventTime')} value={eventTime} direction="row" />
          ) : null}
          <InfoBlock label={t('labels.team')} value={teamName} direction="row" />
          <InfoBlock label={t('labels.clientAccount')} value={clientAccountName} direction="row" />
        </Fragment>
      </OutlineBlock>
      <OutlineBlock sx={{ mt: 6 }} label={t('labels.claimant')}>
        <Fragment>
          <InfoBlock label={t('labels.name')} value={claimName} direction="row" />
          <InfoBlock label={t('labels.dateOfBirth')} value={dateOfBirth} direction="row" />
          {claimNumber ? (
            <InfoBlock label={t('labels.claimNumber')} value={claimNumber} direction="row" />
          ) : null}
        </Fragment>
      </OutlineBlock>
      {typeof reportTemplateName !== 'undefined' ? (
        <OutlineBlock sx={{ mt: 6 }} label={t('labels.report')}>
          <Fragment>
            <InfoBlock label={t('labels.reportTemplate')} value={reportTemplateName} direction="row" />
          </Fragment>
        </OutlineBlock>
      ) : null}
      {typeof medicareOverchargeThreshold !== 'undefined' ? (
        <OutlineBlock sx={{ mt: 6 }} label={t('labels.medicare')}>
          <Fragment>
            <InfoBlock label={t('labels.medicareOverchargeThreshold')} value={medicareOverchargeThreshold} direction="row" />
          </Fragment>
        </OutlineBlock>
      ) : null}
      <OutlineBlock sx={{ mt: 6 }} label={t('labels.notifications')}>
        <Fragment>
          <InfoBlock
            label={t('labels.email')}
            value={formData.notificationPreferences.emails && formData.notificationPreferences.emails.length
              ? [...formData.notificationPreferences.emails].join(', ')
              : '-'
            }
            direction="row" />
        </Fragment>
      </OutlineBlock>
    </Box>
  );
}

export default ReviewCreateStepContent;
