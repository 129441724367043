import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Actions
import { DMSUploadsActions } from 'app/store/DMSUploads/DMSUploads.slice';
// Mui
import { Theme, Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
// 
import UploadProvider from './UploadProvider';
import CommentControl from './CommentControl';
import FilesUploadAlert from './FilesUploadAlert';
import FilesUploader from './FilesUploader';
import FilesUploadHints from './FilesUploadHints';
import DialogActionButtons from './DialogActionButtons';

type Props = {
  open: boolean;
  onClose: () => void;
};

const CaseUploadDocumentsFormDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(DMSUploadsActions.setInitialField('preSignedUrls'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <UploadProvider>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        title={t('dialogs.caseUploadDocuments.title')}

        PaperProps={{
          sx: {
            height: '100%',
            '& .MuiDialogContent-root': {
              borderTop: (theme:Theme) => `1px solid ${theme.palette.divider}`,
              borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
              py: '24px !important'
            }
          },
          component: 'form',
          noValidate: true
        }}

        ContentProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <CommentControl />
          <FilesUploadAlert />
          <FilesUploader />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
          <FilesUploadHints />
          <DialogActionButtons onClose={onClose} />
        </Box>
      </Dialog>
    </UploadProvider>
  )
}

export default CaseUploadDocumentsFormDialog;
