import { FC, useState } from 'react';
// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { patchInsuranceCase } from 'app/store/Cases/Cases.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Mui
import { Theme, Box } from '@mui/material';
// Components
import { LoadingButton } from 'app/components/Mui';
// Hooks
import useCasePermissionResolved from 'app/hooks/useCasePermissionResolver';
// i18next
import { useTranslation } from 'react-i18next';

const AIChatDisableButton:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;

  const [ loading, setLoading ] = useState(false);

  const { caseAIChatEnabled, isCaseProcessingReadyOrNotStarted } = useCasePermissionResolved();

  const handleClick = async () => {
    setLoading(true);

    try {
      await dispatch(patchInsuranceCase({
        insuranceCaseId: insuranceCase.id,
        data: {
          version: insuranceCase.version,
          settings: {
            ...(insuranceCase.settings || {}),
            aiChatEnabled: false
          }
        },
        message: 'AI Chat is disabled'
      })).unwrap();
    } catch (error){
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  if ( !caseAIChatEnabled || !isCaseProcessingReadyOrNotStarted ) return null;
  return (
    <Box sx={{
      borderTop: (theme:Theme) => `1px solid ${theme.palette.divider}`,
      p: 4
    }}>
      <LoadingButton
        name="AI Chat Disable"
        loading={loading}
        onClick={handleClick}
        fullWidth
        variant="outlined"
        color="error"
      >{t('pages.aiChat.disableButton')}</LoadingButton>
    </Box>
  )
}

export default AIChatDisableButton;
