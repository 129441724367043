import React from 'react';
// Types
import { pageMessages } from 'app/store/page/page.messages';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectPageStaples, selectIsLoading } from 'app/store/page/page.selectors';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
// Service
import { generateWorkspaceOrder } from 'app/store/page/page.service';
// Components
import { Switch } from 'app/components/Mui';
import useCaseStatus from 'app/hooks/useCaseStatus';
import { Box } from '@mui/material';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import IInsuranceCase from 'app/models/Case';

type Props = {
  page: IPage;
}

const PageDetailsFormToggler:React.FC<Props> = ({
  // Props
  page
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode);
  const insuranceCase = useSelector(selectInsuranceCase) as IInsuranceCase;
  const staplePages:Array<IPage> | null = useSelector(selectPageStaples)
  // ToDO
  const episodes:any = useSelector(selectEpisodesAll);
  const loading = useSelector(selectIsLoading);

  const { isArchived } = useCaseStatus();

  const handleChange = (_:any, checked:boolean) => {
    const inWorkspace = checked;
    const message = inWorkspace ? pageMessages.addPageToWorkspace : pageMessages.removePageFromWorkspace;
    const workspaceOrder = inWorkspace ? generateWorkspaceOrder(
      episodes, {
        parentEpisodeId: page.parentEpisodeId,
        workspaceOrder: page.workspaceOrder || null
      }
    ) : null;
    if ( staplePages && page.staple && page.staple.order === 1 ){
      const pagesToUpdate = staplePages.map((page:IPage) => ({
        caseId: insuranceCase.id,
        documentId: page.documentId,
        pageNum: page.pageNum,
        version: page.version,
        inWorkspace,
        workspaceOrder
      }));
      dispatch(PageActions.patchPages(pagesToUpdate, message));
    } else {
      dispatch(PageActions.patchPage(page.documentId, page.pageNum, {
        version: page.version,
        inWorkspace,
        workspaceOrder
      }, message))
    }
  }

  const disabled = isArchived || loading;

  if ( trialMode ) return null;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, mt: -1 }}>
      <Switch
        label={t('dialogs.cardPage.includePageIntoWorkspace')} value={page.inWorkspace} name="inWorkspace"
        onChange={handleChange}
        disabled={disabled}
      />
    </Box>
  );
}

export default PageDetailsFormToggler;
