import { ChangeEvent, Fragment, useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
// Mui
import {
  Theme, Box,
  ListItem, ListItemIcon, ListItemText
} from '@mui/material';
// Icons
import {
  DragHandle as DragHandleIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
// Components
import { Input, IconButton, Button } from 'app/components/Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';
// Utilities
import { trimValue } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  index: number;
  episodeType: string;
  isDragging: boolean;
  onUpdate: (index:number, value:string) => void;
  onDelete: (index:number) => void;
}

const EpisodeTypesItem:React.FC<Props> = ({
  // Props
  index, episodeType, isDragging, onUpdate, onDelete
}) => {
  const { t } = useTranslation();

  const [ isEditable, setIsEditable ] = useState(false);
  const [ value, setValue ] = useState(episodeType);

  const { openMenu, Menu, MenuItem } = useMenu();

  useEffect(() => {
    if ( isDragging ) setIsEditable(false);
    // eslint-disable-next-line
  }, [isDragging]);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const handleCancel = () => {
    setIsEditable(false);
  }

  const handleSave = () => {
    if ( !value ) return;
    onUpdate(index, trimValue(value));
    setIsEditable(false);
  }

  const handleEdit = () => {
    setIsEditable(true);
  }

  const handleDelete = () => {
    onDelete(index);
  }

  const ListItemTextOrControls = !isEditable ? episodeType : (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pr: 4 }}>
      <Input
        label={t('labels.name')} name="name" value={value}
        margin="none"
        onChange={handleChange}
        fullWidth={false}
        size="small"
      />
      <Box sx={{ flexGrow: 1 }} />
      <Button
        name={`Cancel episode type edit ${index}`}
        onClick={handleCancel}
        size="small"
      >{t('buttons.cancel')}</Button>
      <Button
        name={`Save episode type edit ${index}`}
        disabled={!value}
        onClick={handleSave}
        variant="contained"
        size="small"
      >{t('buttons.save')}</Button>
    </Box>
  )

  return (
    <Fragment>
      <Draggable draggableId={`${index}`} index={index}>
        {(provided:any, snapshot:any) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              backgroundColor: snapshot.isDragging ? '#ededed' : ''
            }}
            secondaryAction={
              <IconButton
                name={`Episode type dropdown ${index}`}
                onClick={openMenu}
              ><MoreVertIcon /></IconButton>
            }
          >
            <ListItemIcon><DragHandleIcon /></ListItemIcon>
            <ListItemText primary={ListItemTextOrControls} />
          </ListItem>
        )}
      </Draggable>
      <Menu>
        <MenuItem
          name={`Edit episode type ${index}`}
          onClick={handleEdit}
        >{t('buttons.edit')}</MenuItem>
        <MenuItem
          name={`Delete episode type ${index}`}
          sx={{ color: (theme:Theme) => theme.palette.error.main }}
          onClick={handleDelete}
        >{t('buttons.delete')}</MenuItem>
      </Menu>
    </Fragment>
  )
}

export default EpisodeTypesItem;
