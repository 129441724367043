import { FC } from 'react';
// Types
import IConversation from 'app/models/Conversation';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSortedConversations } from 'app/store/Conversations/Conversations.selectors';
// Mui
import { Box, List } from '@mui/material';
// Components
import { Message, Loader } from 'app/components/Utilities';
// 
import ConversationsListItem from './ConversationsListItem';
// i18next
import { useTranslation } from 'react-i18next';

const ConversationsList:FC = () => {
  const { t } = useTranslation();

  const conversations:IConversation[] | null = useAppSelector(selectSortedConversations);

  if ( !conversations ) return <Loader />;
  if ( !conversations.length ) return <Message text={t('pages.messages.noConversationsData')} />;
  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
      <List>
        {conversations.map((conversation:IConversation) => (
          <ConversationsListItem
            key={`conversation-item-${conversation.id}`}
            conversation={conversation}
          />
        ))}
      </List>
    </Box>
  )
}

export default ConversationsList;
