import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'throttle-debounce';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getInvoices } from 'app/store/BillingInvoices/BillingInvoices.async';
// Actions
import { BillingInvoicesActions } from 'app/store/BillingInvoices/BillingInvoices.slice';
// Selectors
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectParams } from 'app/store/BillingInvoices/BillingInvoices.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import { Input, Select } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// Utilities
import { getSearchParam } from 'app/utilities/Utilities';

const BillingInvoicesFilter:FC = () => {
  const { t } = useTranslation();

  const { search:searchLocaltion } = useLocation();
  
  const invoiceNumber = getSearchParam(searchLocaltion, 'invoiceNumber');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountOptions = useAppSelector(selectAccountsAsOptions)
  const params:any = useAppSelector(selectParams);
  const { dateRangeStart, dateRangeEnd, ...otherParams } = params;

  const [ search, setSearch ] = useState(invoiceNumber || '');

  // eslint-disable-next-line
  const debounceParams = useCallback(debounce(500, (field:keyof typeof params, value:any) => {
    const newParams = {
      ...otherParams,
      offset: 0,
      [field]: value
    };
    dispatch(BillingInvoicesActions.setParams({ [field]: value, offset: 0 }));
    dispatch(getInvoices({
      ...newParams,
      accountId: null,
      'insuranceCase.clientAccountId': newParams.accountId
    }));
  }), [params]);

  useEffect(() => {
    const invoiceParams = { ...otherParams };
    if ( invoiceNumber ){
      invoiceParams['search'] = invoiceNumber;
      invoiceParams['status'] = 'paid,sent';
      dispatch(BillingInvoicesActions.setParams(invoiceParams));
    }
    dispatch(getInvoices(invoiceParams));
    // eslint-disable-next-line
  }, []);

  const handleAccountChange = (value:any) => {
    debounceParams('accountId', value);
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if ( name === 'search' ) setSearch(value);
    debounceParams(name as any, value);
  }
  
  const handleClear = () => {
    setSearch('');
    debounceParams('search', '');
  }

  const statusOptions = [
    { id: 'paid,sent', name: t('labels.all') },
    { id: 'paid', name: t('labels.paid') },
    { id: 'sent', name: t('labels.outstanding') }
  ];

  return (
    <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
      <Autocomplete
        value={params.accountId}
        onChange={handleAccountChange}
        options={accountOptions || []}
        TextFieldProps={{
          label: t('labels.clientAccount'),
          margin: "none",
          size: "small"
        }}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
            <Typography variant="subtitle1">{option.label}</Typography>
            {option.props.address ? (
              <Typography variant="caption">{option.props.address}</Typography>
            ) : null}
          </li>
        )}
      />
      <Input
        label={t('labels.search')} name="search" value={search}
        placeholder={t('form.placeholder.caseClaimOrInvoiceNumber')}
        onChange={handleChange}
        onClear={handleClear}
        margin="none"
        size="small"
      />
      <Select
        label={t('labels.status')} id="status" name="status" value={params.status}
        margin="none" size="small"
        onChange={handleChange}
        options={statusOptions}
      />
    </Box>
  )
}

export default BillingInvoicesFilter;
