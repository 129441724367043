import { ChangeEvent, FC, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
// Types
import { Dimension } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.types';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { ManagementBillingUsageActions } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.slice';
// Selectors
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectServicesAsOptions } from 'app/store/BillingServices/BillingServices.selectors';
import { selectPackagesAsOptions } from 'app/store/BillingPackages/BillingPackages.selectors';
import { selectParams, selectValidateDateRange } from 'app/store/ManagementBillingUsage/ManagementBillingUsage.selectors';
// Mui
import {
  Theme, Drawer, Typography,
  Divider,
  FormHelperText
} from '@mui/material';
// Components
import { DesktopDatePicker, Select } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// Constant
import { SIDEBAR_WIDTH } from 'app/App.constants';

const CostExplorerDrawer:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const serviceOptions = useAppSelector(selectServicesAsOptions);
  const packageOptions = useAppSelector(selectPackagesAsOptions);
  const params = useAppSelector(selectParams);
  const validationErrors = useAppSelector(selectValidateDateRange);

  const [ stateParams, setStateParams ] = useState(params);

  const debounceParams = useCallback(debounce(1000, (field:keyof typeof params, value:any) => {
    dispatch(ManagementBillingUsageActions.setParams({ [field]: value }));
    // eslint-disable-next-line
  }), []);

  const handleChange = (field:keyof typeof params) => (newValue:any) => {
    // debounceParams(field, newValue);
    dispatch(ManagementBillingUsageActions.setParams({ [field]: newValue }));
  }

  const handleDateChange = (period:'start' | 'end') => (newValue:Dayjs | null) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      [`period.${period}`]: newValue
    }));
    debounceParams(`period.${period}`, newValue);
  }

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: SIDEBAR_WIDTH,
          position: 'relative',
          borderRadius: 0,
          border: 'initial',
          borderRight: (theme:Theme) => `1px solid ${theme.palette.divider}`,
          py: 2,
          px: 4
        }
      }}
      // Data attribute which explude block from print using CTRL/CMD + P
      data-print="exclude"
      open={true}
      variant="permanent"
      anchor="left"
    >
      <Select
        label={t('labels.breakdown')} name="status" value={params.dimensions}
        onChange={(event:ChangeEvent<HTMLInputElement>) => handleChange('dimensions')(event.target.value)}
        options={[
          { id: Dimension.Service, name: t('labels.service') },
          { id: Dimension.Account, name: t('labels.account') },
          { id: Dimension.SubscriptionPackage, name: t('labels.subscriptionPackage') },
        ]}
      />
      <Divider sx={{ mt: 4, mb: 2 }} />
      <Autocomplete
        value={params.accountId}
        onChange={handleChange('accountId')}
        options={accountOptions || []}
        TextFieldProps={{
          label: t('labels.companyAccount')
        }}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
            <Typography variant="subtitle1">{option.label}</Typography>
            {option.props.address ? (
              <Typography variant="caption">{option.props.address}</Typography>
            ) : null}
          </li>
        )}
      />
      <Autocomplete
        value={params.serviceId}
        onChange={handleChange('serviceId')}
        options={serviceOptions || []}
        TextFieldProps={{
          label: t('labels.service')
        }}
      />
      <Autocomplete
        value={params.subscriptionPackageId}
        onChange={handleChange('subscriptionPackageId')}
        options={packageOptions || []}
        TextFieldProps={{
          label: t('labels.subscriptionPackage')
        }}
      />
      <DesktopDatePicker
        label={t('labels.start')} name="dateRangeStart" value={stateParams['period.start']}
        onChange={handleDateChange('start')}
        inputProps={{
          error: Boolean(validationErrors && validationErrors.startError),
          helperText: validationErrors?.startError || ''
        }}
      />
      <DesktopDatePicker
        label={t('labels.end')} name="dateRangeEnd" value={stateParams['period.end']}
        onChange={handleDateChange('end')}
        inputProps={{
          error: Boolean(validationErrors && validationErrors.endError),
          helperText: validationErrors?.endError || ''
        }}
      />
      {validationErrors && validationErrors.rangeError ? (
        <FormHelperText error>{validationErrors.rangeError}</FormHelperText>
      ) : null}
    </Drawer>
  )
}

export default CostExplorerDrawer