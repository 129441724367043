import { FC } from 'react';
import { useParams } from 'react-router-dom';
// Api
import { useGetDocumentCommentsQuery } from 'app/store/DMSDocuments/DMSDocuments.api';
// Mui
import { Box } from '@mui/material';
// Utils
import { Loader } from 'app/components/Utilities';
// 
import Comment, { IComment } from './Comment'

const DocumentComments:FC = () => {
  const { documentId } = useParams<{ documentId:string }>();

  const { data, isLoading } = useGetDocumentCommentsQuery(+documentId);

  if ( isLoading ) return <Box sx={{ width: '100%', maxWidth: 400 }}><Loader /></Box>;
  if ( data && !data.length ) return null;
  return (
    <Box sx={{
      width: '100%',
      maxWidth: 400,
      display: 'flex',
      flexDirection: 'column',
      gap: 4
    }}>
      {data.map((comment:IComment) => (
        <Comment
          key={`commentItem-${comment.id}`}
          documentId={parseInt(documentId)}
          comment={comment}
        />
      ))}
    </Box>
  )
}

export default DocumentComments;