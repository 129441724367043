import { FC } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectDocument } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Paper } from '@mui/material';
// Compoentns
import { Loader } from 'app/components/Utilities';
// Hooks
import { useLinkHandler } from 'app/hooks/useContainerLinkHandler';

const DocumentPreview:FC = () => {
  // Selectors
  const document = useAppSelector(selectDocument);

  const handleClick = useLinkHandler();

  if ( !document ) return <Loader />;
  return (
    <Box sx={{
      width: '100%',
      maxWidth: 920
    }}>
      <Paper elevation={2}
        sx={{
          height: 'initial',
          overflow: 'initial',

          borderRadius: 2,
          py: 6,
          px: 4,
          'whiteSpace': 'break-spaces'
        }}
        className="ql-editor preview"
        component="div"
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: document.content || '' }}
      />
    </Box>
  )
}

export default DocumentPreview;