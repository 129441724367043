import { FC, Fragment } from 'react';
// Models
import ITeam from 'app/models/Team';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectLoading } from 'app/store/Teams/Teams.selectors';
// Async
import { deleteTeam } from 'app/store/Teams/Teams.async';
// Mui
import {
  Theme, Paper, Box, Divider,
  Typography, Chip
} from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import TeamFormDialog from './TeamFormDialog';
// Utilities
import { sortByName } from 'app/utilities/SortBy';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  team: ITeam;
}

const TeamsListItem:FC<Props> = ({
  // Props
  team
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openEditDialog, toggle:toggleEditDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = () => {
    dispatch(deleteTeam(team.id));
  }

  return (
    <Fragment>
      <Paper sx={{ display: 'flex', p: 4 }} variant="outlined">
        <Box sx={{ flexGrow: 1, pr: 2 }} >
          <Typography variant="subtitle1">{team.name}</Typography>
          {team.description ? (
            <Typography color="CaptionText" variant="body2">{team.description}</Typography>
          ) : null}
          <Divider sx={{ my: 2 }} />
          <InfoBlock
            label={t('labels.members')}
            value={
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                {[...team.members].sort((a, b) => sortByName(a, b, 'name')).map((member:any, index:number) => (
                  <Chip
                    key={`team-item-${team.id}-members-${index}`}
                    sx={{ borderRadius: 1 }}
                    label={member.name}
                    size="small"
                  />
                ))}
              </Box>
            }
          />
          {team.caseNotificationPreferences?.recipients ? (
            <Box sx={{ mt: 2 }}>
              <InfoBlock
                label={t('labels.notificationRecipients')}
                value={
                  <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    {[...team.caseNotificationPreferences.recipients].sort((a, b) => sortByName(a, b, 'name')).map((recipient:any, index:number) => (
                      <Chip
                        key={`team-item-${team.id}-notificationRecipients-${index}`}
                        sx={{ borderRadius: 1 }}
                        label={recipient.name}
                        size="small"
                      />
                    ))}
                  </Box>
                }
              />
            </Box>
          ) : null}
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`Team dropdown ${team.id}`}
            aria-label="Team item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              name={`Edit team ${team.id}`}
              onClick={toggleEditDialog}
            >{t('buttons.edit')}</MenuItem>
            <MenuItem
              name={`Delete team ${team.id}`}
              sx={{
                color: (theme:Theme) => theme.palette.error.main
              }}
              onClick={toggleConfirmationDialog}
            >{t('buttons.delete')}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {/* Dialogs */}
      {openEditDialog ? (
        <TeamFormDialog
          open={openEditDialog}
          onClose={toggleEditDialog}
          team={team}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('dialogs.confirmation.deleteTitle', { dataType: t('labels.team') })}
          content={(
            <Trans
              t={t} i18nKey="dialogs.confirmation.deleteConfirmationWithName"
              components={{ strong: <strong /> }}
              values={{ dataType: t('labels.team'), dataName: team.name }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default TeamsListItem;
