import { FC, Fragment, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectSearchPagesList, selectSearchPagesParams } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
import { selectSelectedPageIds } from 'app/store/AppUI/AppUI.selectors';
import { selectPageEntities } from 'app/store/page/page.selectors';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
import { selectCaseAiChatEnabled } from 'app/store/Cases/Cases.selectors';
//
import { pageMessages } from 'app/store/page/page.messages';
// Mui
import { Box, Toolbar, Tooltip, Typography } from '@mui/material';
// Icons
import { Tag as TagIcon, ForumOutlined as ForumOutlinedIcon } from '@mui/icons-material';
// Components
import Icon from 'app/components/Icon';
import { IconButton, Button } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Dialogs
import CardPageTagsDialog from 'app/dialogs/CardPageTagsDialog';
import CardPageColorsDialog from 'app/dialogs/CardPageColorsDialog';
import ConversationFormDialog from 'app/pages/Staff/CaseDetailsPage/AIChatPage/ConversationFormDialog';
// Services
import PageService from 'app/services/PageService';
import { generateWorkspaceOrder } from 'app/store/page/page.service';
import { selectAIChatEnabled } from 'app/store/Accounts/Accounts.selectors';

const SearchToolbar:FC = () => {
  const { caseId } = useParams<{ caseId:string }>();

  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const aiChatEnabled = useAppSelector(selectAIChatEnabled)
  const selectedPageIds = useAppSelector(selectSelectedPageIds);
  const pages = useAppSelector(selectSearchPagesList);
  const params = useAppSelector(selectSearchPagesParams);
  const caseAiChatEnabled = useAppSelector(selectCaseAiChatEnabled);
  // ToDO
  const episodes:any = useAppSelector((state:any) => selectEpisodesAll(state));

  const pageEntities = useAppSelector((state:any) => selectPageEntities(state));

  const { open:openSetTagDialog, toggle:toggleSetTagDialog } = useToggle();
  const { open:openSetColorsDialog, toggle:toggleSetColorsDialog } = useToggle();
  const { open:openAIConversationDialog, toggle:toggleAIConversationDialog } = useToggle();

  const nextPages = useMemo(() => {
    return selectedPageIds.map((pageId:string) => {
      const [ documentId, pageNum ] = PageService.fromPageId(pageId);
      return pageEntities[documentId][pageNum];
    })
    .filter((page:any) => !page.staple || page.staple.order === 1);
    // eslint-disable-next-line
  }, [selectedPageIds, pageEntities]);

  const handleClick = () => {
    if ( !nextPages.length ) return;
    const pagesToUpdate = nextPages
      .map((page:any, index:number) => {
        const workspaceOrder = generateWorkspaceOrder(
          episodes, {
            parentEpisodeId: page.parentEpisodeId,
            workspaceOrder: page.workspaceOrder || null,
            index
          }
        );
        return {
          caseId,
          documentId: page.documentId,
          pageNum: page.pageNum,
          version: page.version,
          inWorkspace: true,
          workspaceOrder
        };
      });
    dispatch(PageActions.patchPages(pagesToUpdate, pageMessages.addPageToWorkspace));
  }

  const handleClickSelectAll = () => {
    if ( !pages ) return;
    if ( isSelectedAllPages ){
      dispatch(AppUIActions.setInitialField('selectedPageIds'));
    } else {
      const pageIds = pages.map((page:any) => PageService.toPageId(page.documentId, page.pageNum));
      dispatch(AppUIActions.addPageIdsToSelected(pageIds));
    }
  }

  const isSelectedAllPages = pages && pages.length === selectedPageIds.length;
  const isButtonDisabled = selectedPageIds.length === 0;
  const isAiChatButtonDisabled = isButtonDisabled || !caseAiChatEnabled;

  if ( !pages || !pages.length ) return null;
  return (
    <Fragment>
      <Toolbar sx={{ justifyContent: 'space-between', gap: 4 }}>
        <Typography variant="body2">
          <Trans
            t={t} i18nKey={params.keywords?.length ? 'pages.search.pagesFoundFor' : 'pages.search.pagesFound'}
            components={{ b: <b /> }}
            values={{ pages: pages.length, keywords: params.keywords?.join(', ') }}
          />
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          {aiChatEnabled ? (
            <Tooltip title={t('buttons.sendToAiChat')}>
              <span>
                <IconButton
                  name="Send to AI Chat"
                  onClick={toggleAIConversationDialog}
                  disabled={isAiChatButtonDisabled}
                ><ForumOutlinedIcon /></IconButton>
              </span>
            </Tooltip>
          ) : null}
          <Tooltip title={t('pages.search.addPagesToWorkspace')}>
            <span>
              <IconButton
                name="Add page(s) to workspace"
                onClick={handleClick}
                disabled={isButtonDisabled}
              ><Icon icon="add_to_workspace" /></IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('pages.search.addPagesTag')}>
            <span>
              <IconButton
                name="Add page(s) tag"
                onClick={toggleSetTagDialog}
                disabled={isButtonDisabled}
              ><TagIcon /></IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('pages.search.addPagesColors')}>
            <span>
              <IconButton
                name="Add page(s) colors"
                onClick={toggleSetColorsDialog}
                disabled={isButtonDisabled}
              ><Icon icon="color" /></IconButton>
            </span>
          </Tooltip>
          <Button
            name={isSelectedAllPages ? 'Unselect all' : 'Select all'}
            onClick={handleClickSelectAll}
            variant="outlined"
          >{isSelectedAllPages ? t('buttons.unselectAll') : t('buttons.selectAll')}</Button>
        </Box>
      </Toolbar>
      {openSetTagDialog ? (
        <CardPageTagsDialog
          open={openSetTagDialog}
          onClose={toggleSetTagDialog}
          pages={nextPages}
          dialogTitle={t('pages.search.addPagesTag')}
        />
      ) : null}
      {openSetColorsDialog ? (
        <CardPageColorsDialog
          open={openSetColorsDialog}
          onClose={toggleSetColorsDialog}
          pages={nextPages}
          dialogTitle={t('pages.search.addPagesColors')}
        />
      ) : null}
      {openAIConversationDialog ? (
        <ConversationFormDialog
          open={openAIConversationDialog}
          onClose={toggleAIConversationDialog}
          pages={nextPages}
          source="search"
        />
      ) : null}
    </Fragment>
  )
}

export default SearchToolbar;
