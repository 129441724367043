import { FC, useEffect } from 'react';
// Types
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Store
import { useAppSelector, useAppDispatch } from 'app/hooks/useStore';
// Async
import { getAssignments, deleteAssignment } from 'app/store/CaseAssignments/CaseAssignments.async';
import { createAuditRecord } from 'app/store/Audit/Audit.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectAssignments, selectLoading } from 'app/store/CaseAssignments/CaseAssignments.selectors';
// Mui
import {
  Paper,
  List, ListItem, ListItemSecondaryAction, ListItemText
} from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
import { Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  userId: number;
  userEmail: string;
}

const SharedCasesDialog:FC<Props> = ({
  // Props
  open, onClose, userId, userEmail
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser);

  const assignments:any = useAppSelector(selectAssignments);
  const loading:boolean = useAppSelector(selectLoading);

  useEffect(() => {
    dispatch(getAssignments({ userId }));
    // eslint-disable-next-line
  }, []);

  const handleClick = (assignment:any) => async () => {
    try {
      await dispatch(deleteAssignment({
        insuranceCaseId: assignment.insuranceCase.id,
        userIds: [assignment.userId]
      })).unwrap();
      dispatch(createAuditRecord({
        operation: AuditOperations.CaseShared,
        data: {
          caseId: assignment.insuranceCase.id,
          userName: myUser?.name || '',
          userEmail,
          shared: false
        }
      }));
    } catch(error){}
  }

  const actions = (
    <Button
      name="Close shared cases dialog"
      onClick={onClose}
    >{t('buttons.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('pages.users.sharedDialogTitle')}
      actions={actions}
    >
      {!assignments ? (
        <Loader />
      ) : (
        !assignments.length ? (
          <Message text={t('pages.users.noSharedData')} />
        ) : (
          <Paper variant="outlined">
            <List>
              {assignments.map((assignment:any) => (
                <ListItem key={`assignment-item-${assignment.insuranceCase?.id}`}>
                  <ListItemText primary={assignment.insuranceCase?.name} />
                  <ListItemSecondaryAction>
                    <LoadingButton
                      name={`Stop sharing ${assignment.insuranceCase?.id}`}
                      onClick={handleClick(assignment)}
                      color="error"
                      loading={loading}
                    >{t('buttons.stopSharing')}</LoadingButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        )
      )}
    </Dialog>
  )
}

export default SharedCasesDialog;
