import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from 'app/types/Reducers';
// Async
import { login, logout } from "./Auth.async";

interface IState {
  multiFactory: {
    channel: string;
    challengeId: string;
    email?: string;
    phone?: string;
    accountId: number | null;
    username: string;
    password: string;
  },
  multiAccounts: {
    accounts: any[];
    message: string;
  };

  authenticated: boolean | undefined;
  loading: boolean;
}

const initialState:IState = {
  multiFactory: {
    channel: '',
    challengeId: '',
    email: '',
    phone: '',
    accountId: null,
    username: '',
    password: '',
  },
  multiAccounts: {
    accounts: [],
    message: ''
  },

  authenticated: undefined,
  loading: false
}

const slice = createSlice({
  name: Reducers.Auth,
  initialState,
  reducers: {
    setMultiFactoryData: (state, action:PayloadAction<any>) => {
      state.multiFactory = action.payload;
    },
    setMultiAccountsData: (state, action:PayloadAction<any>) => {
      state.multiAccounts = action.payload;
    },
    setAuthenticated: (state, action:PayloadAction<undefined | boolean>) => {
      state.authenticated = action.payload;
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    // Logout
    builder.addCase(logout.fulfilled, (state) => {
      state.authenticated = false
    });

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    );
  }
});

export const AuthActions = slice.actions;

export default slice.reducer;
