import { FC, Fragment } from 'react';
// Types
import { IMyUser } from 'app/models/User';
import IConversation from 'app/models/Conversation';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { markAsRead } from 'app/store/Conversations/Conversations.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectConversation } from 'app/store/Conversations/Conversations.selectors';
import { selectMessages } from 'app/store/Messages/Messages.selectors';
// Mui
import {
  Box, Toolbar,
  Button, IconButton, Typography
} from '@mui/material';
// Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import ConversationFormDialog from 'app/dialogs/ConversationFormDialog';
import ConversationMembersDialog from './ConversationMembersDialog';
import IMessage from 'app/models/Message';
// i18next
import { useTranslation } from 'react-i18next';

const MessagesToolbar:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const conversation:IConversation | null = useAppSelector(selectConversation);
  const messages:IMessage[] | null = useAppSelector(selectMessages);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openFormDialog, toggle:toggleFormDialog } = useToggle();
  const { open:openMembersDialog, toggle:toggleMembersDialog } = useToggle();

  const handleMarkAsRead = () => {
    if ( !conversation || !messages ) return;

    dispatch(markAsRead({
      conversationId: conversation.id,
      data: { messageId: messages[messages.length - 1].id }
    }))
  }

  if ( !conversation ) return null;
  return (
    <Fragment>
      <Toolbar sx={{ gap: 2, overflow: 'hidden' }} variant="dense">
        <Box sx={{ flexGrow: 1, position: 'relative', height: 28 }}>
          <Typography
            sx={{
              position: 'absolute',
              left: 0, top: 0,
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 700
            }}
            variant="subtitle1"
          >{conversation?.name}</Typography>
        </Box>
        {conversation?.me?.numberOfUnseenMessages ? (
          <Button
            aria-label="Mark as read button"
            onClick={handleMarkAsRead}
            color="primary"
            variant="outlined"
            size="small"
          >{t('pages.messages.markAsReadButton')}</Button>
        ) : null}
        {myUser?.role === 'admin' ? (
          <IconButton
            aria-label="Message toolbar dropdown button"
            onClick={openMenu}
            size="small"
          ><MoreVertIcon /></IconButton>
        ) : null}
      </Toolbar>
      <Menu>
        <MenuItem
          name={`Rename conversation ${conversation?.id}`}
          onClick={toggleFormDialog}
        >{t('pages.messages.renameConversationButton')}</MenuItem>
        <MenuItem
          name={`Members ${conversation?.id}`}
          onClick={toggleMembersDialog}
        >{t('pages.messages.membersButton')}</MenuItem>
      </Menu>
      {/* Dialogs */}
      {openFormDialog ? (
        <ConversationFormDialog
          open={openFormDialog}
          onClose={toggleFormDialog}
          conversation={conversation || undefined}
        />
      ) : null}
      {openMembersDialog ? (
        <ConversationMembersDialog
          open={openMembersDialog}
          onClose={toggleMembersDialog}
          conversation={conversation}
        />
      ) : null}
    </Fragment>
  )
}

export default MessagesToolbar;
