import config from '../../config';

import React from 'react';
// Models
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectDocumentEntity} from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectPageEntity } from 'app/store/page/page.selectors';
// Models
import IAnnotation from 'app/models/Annotation';
import { alpha, Box, Theme, Typography } from '@mui/material';
import { selectSearchPageBboxes } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// i18next
import { useTranslation } from 'react-i18next';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';

// Models
import IInsuranceCase from 'app/models/Case';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';

import Highlights from 'app/components/PageImageHighlights/Highlights';

type Props = {
  className?: string;
  documentId: number;
  pageNum: number;
  showPageNum?: boolean;
  onLoadCallback?: () => void;

  wrapperStyles?: any;

  highlights?: any;

  loading?: string;
};

const PageImage:React.FC<Props> = ({
  // Props
  className = 'card-page-image', documentId, pageNum, showPageNum = true, onLoadCallback, wrapperStyles = {
    position: 'relative',
    display: 'inline-block',
    height: '100%'
  },
  highlights = [],

  loading = 'lazy'
}) => {
  const { t } = useTranslation();

  const imageProps:any = {};

  const imageRef = React.useRef<HTMLImageElement | null>(null);

  // State
  const trialMode = useSelector(selectTrialMode);
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const page:any = useSelector((state:any) => selectPageEntity(state, { documentId, pageNum }));
  const documentEntity = useSelector((state:any) => selectDocumentEntity(state, { documentId })) as IDocument;

  const bboxes = useSelector((state:any) => selectSearchPageBboxes(state, { documentId, pageNum }));

  let src = `${config.cdnUrl}/pages/${insuranceCase?.id}_${documentId}_${pageNum}.png`;
  // Get rotations
  if ( page?.annotations && page.annotations.length ){
    const manualRotation = page.annotations.find((annotation:IAnnotation) => annotation.name === 'manualRotation');
    if ( manualRotation && manualRotation.value ) src += `?rotation=${manualRotation.value}`;
  }
  if ( onLoadCallback ){
    imageProps['onLoad'] = () => {
      if ( imageRef?.current?.complete ) onLoadCallback();
    }
  }

  if ( documentEntity?.processing?.status === 'processing' ) return (
    <Typography
      display="block"
      variant="body2"
      style={{ textAlign: 'center' }}
    >{t('components.pageImage.notAvailable')}</Typography>
  );
  return (
    <div className="relative" style={{ height: '100%', textAlign: 'center' }}>
      {showPageNum ? (
        <span className="card-page-pageNum" style={{ zIndex: 2 }}>{pageNum}</span>
      ) : null}
      <div style={wrapperStyles}>
        <img
          {...imageProps}
          ref={imageRef}
          className={className}
          src={src}
          alt={`${documentId}-${pageNum}`}
          loading={loading}
        />
        {bboxes ? (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}>
            {bboxes.map((box:any, index:number) => (
              <Box
                key={`page-item-${documentId}-${pageNum}-highlight-${index}`}
                className="marker-highlight"
                sx={{
                  ...box,
                  backgroundColor: (theme:Theme) => alpha(theme.palette.primary.main, 0.3),
                  position: 'absolute'
                }}
              />
            ))}
          </div>
        ) : null}
        {!trialMode ? <Highlights highlights={highlights} /> : null}
      </div>
    </div>
  )
}

export default PageImage;
