import { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Models
import IAnnotation from 'app/models/Annotation';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectDocument } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box, Divider, Toolbar, Tooltip } from '@mui/material';
// Icons
import { Edit as EditIcon, Comment as CommentIcon } from '@mui/icons-material';
// Compoentns
import DocumentName from 'app/components/DocumentName';
import { IconButton } from 'app/components/Mui';
import { InfoBlock, Loader } from 'app/components/Utilities';
// Dialogs
import DocumentFormDialog from 'app/dialogs/DocumentFormDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';

type Props = {
  openComments: boolean;
  toggleOpenComments: (value:boolean) => void;
}

const DocumentToolbar:FC<Props> = ({
  // Props
  openComments, toggleOpenComments
}) => {
  const { t } = useTranslation();

  const { documentId } = useParams<{ documentId:string }>();

  // Selectors
  const document = useAppSelector(selectDocument);

  const { open, toggle } = useToggle();

  if ( !document ) return <Loader />;
  return (
    <Fragment>
      <Toolbar sx={{ py: 2, height: 'initial' }}>
        <Box sx={{ flexGrow: 1 }}>
          <DocumentName />
          {document.annotations && document.annotations.length ?
            document.annotations.map((annotation:IAnnotation, index:number) => (
              <InfoBlock
                key={`annotation item ${index}`}
                label={annotation.name}
                value={annotation.value || 'N/A'}
                direction="row"
              />
            ))
          : null}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Tooltip title={t('buttons.edit')}>
            <IconButton
              name={`Edit ${documentId}`}
              onClick={toggle}
            ><EditIcon /></IconButton>
          </Tooltip>
          <Divider orientation="vertical" sx={{ height: 40 }} />
          <Tooltip title={openComments ? t('buttons.hideComments') : t('buttons.showComments')}>
            <IconButton
              name={`Toggle comment ${documentId}`}
              color={openComments ? 'primary' : 'default'}
              onClick={() => toggleOpenComments(!openComments)}
            ><CommentIcon /></IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      {open ? (
        <DocumentFormDialog
          open={open}
          onClose={toggle}
          document={document}
        />
      ) : null}
    </Fragment>
  )
}

export default DocumentToolbar;