import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Mui
import { Box, Autocomplete } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';

type Props = {
  pagesNums: string[];
  isDisabled: boolean;
}

const EpisodeFormPageNum:FC<Props> = ({ pagesNums, isDisabled }) => {
  const { t } = useTranslation();

  const { control, formState:{ errors } } = useFormContext();

  if ( !pagesNums.length || pagesNums.length === 1 ) return null;
  return (
    <Box sx={{ mb: 4 }}>
      <Controller
        control={control} name="pageNum"
        rules={{
          validate: (value) => {
            if ( value && !pagesNums.includes(value) ) return t('validations.pageNumberNotValid');
            // false - not valid
            // true - valid
            return true;
          }
        }}
        render={({ field:{ onChange, ...otherField }}) => (
          <Autocomplete
            {...otherField}
            disabled={isDisabled}
            options={pagesNums}
            onChange={(_, value:string) => onChange(value)}
            renderInput={( params ) => (
              <Input
                {...params}
                label={t('labels.pageNumber')}
                name={otherField.name}
                margin="none"
                size="small"
                error={Boolean(errors.pageNum)}
                helperText={errors.pageNum?.message as string || ''}
              />
            )}
          />
        )}
      />
    </Box>
  )
}

export default EpisodeFormPageNum;
