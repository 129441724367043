import i18n from "i18n";

export const pageMessages = {
  updatePage: i18n.t('notifications.common.update', { dataType: i18n.t('labels.page') }),
  deletePage: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.page') }),
  rotatePage: i18n.t('notifications.page.rotatePage'),

  updatePageTag: i18n.t('notifications.common.update', { dataType: i18n.t('labels.pageTag') }),
  removePageTag: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.pageTag') }),

  updatePageColor: i18n.t('notifications.common.update', { dataType: i18n.t('labels.pageColors') }),
  removePageColor: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.pageColors') }),

  addPageToWorkspace: i18n.t('notifications.page.addPageToWorkspace'),
  removePageFromWorkspace: i18n.t('notifications.page.removePageFromWorkspace'),

  addHighlightToPage: i18n.t('notifications.page.addHighlightToPage'),
  deleteHighlightFromPage: i18n.t('notifications.page.deleteHighlightFromPage'),

  removePageFromStaple: i18n.t('notifications.page.removePageFromStaple'),

  exportPagesToPDF: i18n.t('notifications.page.exportPagesToPDF'),

  createStaple: i18n.t('notifications.common.create', { dataType: i18n.t('labels.staple') }),
  updateStaple: i18n.t('notifications.common.update', { dataType: i18n.t('labels.staple') }),
  removeStaple: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.staple') })
};

i18n.on('languageChanged', () => {
  pageMessages.updatePage = i18n.t('notifications.common.update', { dataType: i18n.t('labels.page') });
  pageMessages.deletePage = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.page') });
  pageMessages.rotatePage = i18n.t('notifications.page.rotatePage');
  pageMessages.updatePageTag = i18n.t('notifications.common.update', { dataType: i18n.t('labels.pageTag') });
  pageMessages.removePageTag = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.pageTag') });
  pageMessages.updatePageColor = i18n.t('notifications.common.update', { dataType: i18n.t('labels.pageColors') });
  pageMessages.removePageColor = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.pageColors') });
  pageMessages.addPageToWorkspace = i18n.t('notifications.page.addPageToWorkspace');
  pageMessages.removePageFromWorkspace = i18n.t('notifications.page.removePageFromWorkspace');
  pageMessages.addHighlightToPage = i18n.t('notifications.page.addHighlightToPage');
  pageMessages.deleteHighlightFromPage = i18n.t('notifications.page.deleteHighlightFromPage');
  pageMessages.removePageFromStaple = i18n.t('notifications.page.removePageFromStaple');
  pageMessages.exportPagesToPDF = i18n.t('notifications.page.exportPagesToPDF');
  pageMessages.createStaple = i18n.t('notifications.common.create', { dataType: i18n.t('labels.staple') });
  pageMessages.updateStaple = i18n.t('notifications.common.update', { dataType: i18n.t('labels.staple') });
  pageMessages.removeStaple = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.staple') });
})
