import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// Models
import { ITerms } from 'app/store/Terms/Terms.models';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { archiveTerms, deleteTerms, publishTerms } from 'app/store/Terms/Terms.async';
// Selectors
import { selectLoading } from 'app/store/Terms/Terms.selectors';
// Mui
import { Theme, ListItem, ListItemText, Typography } from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
import TermsPreviewDialog from 'app/dialogs/TermsPreviewDialog';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import TermsFormDialog from './TermsFormDialog';

type Props = {
  terms: ITerms;
};

const TermsListItem:FC<Props> = ({
  // Props
  terms
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openEditDialog, toggle:toggleEditDialog } = useToggle();
  const { open:openPreviewDialog, toggle:togglePreviewDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const termsId = terms.id;

  const handlePublishTerms = () => {
    dispatch(publishTerms(termsId));
  }

  const handleArchiveTerms = () => {
    dispatch(archiveTerms(termsId));
  }

  const handleConfirm = () => {
    dispatch(deleteTerms(termsId));
  }

  const isStatusDraft = terms.status === 'draft';
  const isStatusPublished = terms.status === 'published';

  return (
    <Fragment>
      <ListItem sx={{ '& + &': { borderTop: '1px solid rgba(0,0,0,0.08)' } }} secondaryAction={
        <Fragment>
          <IconButton
            name={`Term and condition dropdown ${termsId}`}
            aria-label="Terms and condition item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              key={`preview menu item ${termsId}`}
              name={`Preview terms ${termsId}`}
              onClick={togglePreviewDialog}
            >{t('buttons.preview')}</MenuItem>
            {isStatusDraft ? (
              [
                <MenuItem
                  key={`edit menu item ${termsId}`}
                  name={`Edit terms ${termsId}`}
                  onClick={toggleEditDialog}
                >{t('buttons.edit')}</MenuItem>,
                <MenuItem
                  key={`publish menu item ${termsId}`}
                  name={`Publish terms ${termsId}`}
                  onClick={handlePublishTerms}
                >{t('buttons.publish')}</MenuItem>
              ]
            ) : (
              [
                isStatusPublished ? (
                  <MenuItem
                    key={`archive menu item ${termsId}`}
                    name={`Archive terms ${termsId}`}
                    onClick={handleArchiveTerms}
                  >{t('buttons.archive')}</MenuItem>
                ) : (
                  <MenuItem
                    key={`delete menu item ${termsId}`}
                    name={`Delete terms ${termsId}`}
                    sx={{ color: (theme:Theme) => theme.palette.error.main }}
                    onClick={toggleConfirmationDialog}
                  >{t('buttons.delete')}</MenuItem>
                )
              ]
            )}
          </Menu>
        </Fragment>
      }>
        <ListItemText
          secondaryTypographyProps={{
            component: 'div'
          }}
          primary={terms.name}
          secondary={
            <Fragment>
              <Typography
                sx={{
                  color: (theme:Theme) => terms.status === 'archived'
                    ? theme.palette.warning.light
                    : terms.status === 'published'
                      ? theme.palette.success.main
                      : theme.palette.text.secondary
                  ,
                  textTransform: 'capitalize'
                }}
                variant="caption"
              >{terms.status}</Typography>
              <InfoBlock direction="row" label={t('labels.scope')} value={terms.scope} />
            </Fragment>
          }
        />
      </ListItem>
      {openEditDialog ? (
        <TermsFormDialog
          open={openEditDialog}
          onClose={toggleEditDialog}
          terms={terms}
        />
      ) : null}
      {openPreviewDialog ? (
        <TermsPreviewDialog
          open={openPreviewDialog}
          onClose={togglePreviewDialog}
          content={terms.content}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('dialogs.confirmation.deleteTitle', { dataType: t('labels.termsAndConditions') })}
          content={(
            <Trans
              t={t} i18nKey="dialogs.confirmation.deleteConfirmationWithName"
              components={{ strong: <strong /> }}
              values={{ dataType: t('labels.termsAndConditions'), dataName: terms.name }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default TermsListItem;
