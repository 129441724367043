import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectHasAnyDocumentOcred } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Toolbar } from '@mui/material';
// Components
import { Button } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useCasePermissionResolved from 'app/hooks/useCasePermissionResolver';
// 
import ConversationFormDialog from './ConversationFormDialog';

const ConversationCreateButton:FC = () => {
  const { t } = useTranslation();
  // State
  const hasAnyDocumentOcred = useAppSelector(selectHasAnyDocumentOcred);

  const { open, toggle } = useToggle();
  const { caseAIChatEnabled, isCaseProcessingReadyOrNotStarted } = useCasePermissionResolved();

  const enableButton = caseAIChatEnabled && isCaseProcessingReadyOrNotStarted && hasAnyDocumentOcred;

  return (
    <Fragment>
      <Toolbar>
        <Button
          name="Create conversation"
          disabled={!enableButton}
          onClick={toggle}
          fullWidth
          variant="contained"
        >{t('pages.aiChat.createButton')}</Button>
      </Toolbar>
      {open ? (
        <ConversationFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default ConversationCreateButton;
