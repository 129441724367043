import { FC, Fragment } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectStapleModeType } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Button } from '@mui/material';
// Icons
import { Add as AddIcon } from '@mui/icons-material';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  pageId: string;
}

const CardPageFootStapleMode:FC<Props> = ({
  // Props
  pageId
}) => {
  const { t } = useTranslation();
  // State
  const stapleModeType = useAppSelector(selectStapleModeType);

  const handleAddToStaple = () => {

  }

  const handleRemoveFromStaple = () => {
    
  }

  if ( !stapleModeType ) return null;
  return (
    <Fragment>
      <Button
        onClick={handleAddToStaple}
        color="inherit"
        variant="outlined"
        fullWidth
      >
        <AddIcon sx={{ mr: 2 }} />
        {t('components.cardPage.addToStaple')}
      </Button>
    </Fragment>
  )
}

export default CardPageFootStapleMode;
