import { FC, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Async
import { updateMyPreferences } from 'app/store/currentUser/currentUserAsync';
// Selectors
import { selectEpisodeLegends } from 'app/store/currentUser/currentUserSelectors';
// Mui
import {
  Button,
  Box, Typography
} from '@mui/material';
// 
import LegendFormDialog from './LegendForm.dialog';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  legend: any;
}

const LegendsListItem:FC<Props> = ({
  // Props
  legend
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const legends = useSelector(selectEpisodeLegends);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const handleDialogOpen = () => setDialogOpen(!dialogOpen);

  const handleToggleVisible = () => {
    const preferenceName = `case:${caseId}.chart.legend`;
    dispatch(updateMyPreferences({
      preferenceName,
      data: {
        name: preferenceName,
        value: JSON.stringify({
          ...legends,
          [legend.key]: {...legend, visible: !legend.visible}
        })
      }
    }));
  }

  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2
        }}
      >
        <Box mr={2}>
          <Box sx={{
            width: 24,
            height: 24,
            bgcolor: legend.color,
            borderRadius: '50%'
          }} />
        </Box>
        <Box sx={{ flexGrow: 1, paddingRight: '8px' }}>
          <Typography variant="subtitle1">{legend.label}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }} style={{ gap: 8 }}>
          <Button
            onClick={handleToggleVisible}
            variant={legend.visible ? 'outlined' : 'text'}
            color="primary"
            size="small"
          >{legend.visible ? t('buttons.hide') : t('buttons.show')}</Button>
          <Button
            onClick={handleDialogOpen}
            variant="contained"
            color="primary"
            size="small"
          >{t('buttons.edit')}</Button>
        </Box>
      </Box>
      {dialogOpen ? (
        <LegendFormDialog
          open={dialogOpen}
          onClose={handleDialogOpen}
          legend={legend}
        />
      ) : null}
    </Fragment>
  )
}

export default LegendsListItem;
