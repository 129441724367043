import { FC } from 'react';
// Models
import { RootState } from 'app/store';
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectStaplePageIds } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Tooltip } from '@mui/material';
// Components
import Icon from 'app/components/Icon';
// 
import CardPageHeadDate from './CardPageHeadDate';
import CardPageHeadTag from './CardPageHeadTag';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  page: IPage | IPresenterPage;
  index?: number;
}

const CardPageHead:FC<Props> = ({
  // Props
  page, index
}) => {
  const { t } = useTranslation();
  // State
  const staplePageIds:string[] | null = useAppSelector((state:RootState) => selectStaplePageIds(state, {
    documentId: page.documentId,
    stapleId: page.staple?.id || null
  }));

  return (
    <div className="cp-head">
      <div className="flex align-center gap-4 mb-1">
        <span className="cp-text flex-grow-1">{t('labels.page')} {typeof index !== 'undefined' ? index + 1 : page.pageNum}</span>
        {staplePageIds && staplePageIds.length ? (
          <div className="flex align-center gap-4">
            <Tooltip title={t('components.cardPage.pagesInStaple', { pages: staplePageIds.length })}>
              <div>
                <Icon icon="stapled" size="small" />
              </div>
            </Tooltip>
          </div>
        ) : null}
      </div>
      <CardPageHeadDate page={page} />
      <CardPageHeadTag page={page as IPage} />
    </div>
  )
}

export default CardPageHead;