import { createSlice, PayloadAction, AnyAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import { IManagerUser } from "app/models/User";
// Async
import { getUsers, inviteUser, patchUser, unlockUser } from './UsersManagement.async';

interface IState {
  users: IManagerUser[] | null;
  params: {
    limit: number;
    offset: number;
    accountId: number | null;
    search: string;
    enabled: string;
    locked: string;
    platform: string;
  },
  hasMore: boolean;
  gettingMore: boolean;
  loading: boolean;
};

const initialState:IState = {
  users: null,
  params: {
    limit: 10,
    offset: 0,
    accountId: null,
    search: '',
    enabled: 'all',
    locked: 'all',
    platform: 'all'
  },
  hasMore: false,
  gettingMore: false,
  loading: false
};

const slice = createSlice({
  name: Reducers.UsersManagement,
  initialState,
  reducers: {
    setParams: (state, action:PayloadAction<any>) => {
      const params = action.payload;
      state.params = Object.keys(state.params).reduce((acc:any, cur:any) => {
        acc[cur] = (state.params as any)[cur];
        if ( typeof params[cur] !== 'undefined' ) acc[cur] = params[cur];
        return acc;
      }, {});
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
    resetState: () => initialState
  },
  extraReducers(builder) {
    // Get users
    builder.addCase(getUsers.pending, (state, action:any) => {
      const { offset } = action.meta.arg;

      if ( offset === 0 ){
        state.users = null
      } else {
        state.params.offset = offset;
        state.gettingMore = true;
      }
    });
    builder.addCase(getUsers.fulfilled, (state, action:PayloadAction<{ data:IManagerUser[], total:number }>) => {
      const users = action.payload.data;
      state.users = state.users ? [...state.users, ...users] : users;
      state.hasMore = users.length === state.params.limit;
      state.gettingMore = false;
    });
    // Patch user
    builder.addCase(patchUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchUser.fulfilled, (state, action:PayloadAction<Partial<IManagerUser>>) => {
      const userPlatform = action.payload.platform || false;
      if ( state.users ){
        if (
          state.params.platform === 'all' ||
          state.params.platform === String(userPlatform)
        ){
          state.users = state.users.map((user:IManagerUser) => {
            if ( user.id === action.payload.id ) return {...user, ...action.payload};
            return user;
          });
        } else {
          state.users = state.users.filter((user:IManagerUser) => user.id !== action.payload.id);
        }
      }
    });
    // Invite user
    builder.addCase(inviteUser.pending, (state) => {
      state.loading = true;
    });
    // Unlock user
    builder.addCase(unlockUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(unlockUser.fulfilled, (state, action:PayloadAction<IManagerUser>) => {
      const userLocked = action.payload.locked || false;
      if ( state.users ){
        if (
          state.params.locked === 'all' ||
          state.params.locked === String(userLocked)
        ){
          state.users = state.users.map((user:IManagerUser) => {
            if ( user.id === action.payload.id ) return action.payload;
            return user;
          });
        } else {
          state.users = state.users.filter((u:IManagerUser) => u.id !== action.payload.id);
        }
      }
    });

    builder.addMatcher(
      (action:AnyAction) => action.type.includes('fulfilled') || action.type.includes('rejected'),
      (state) => {
        state.loading = false
      }
    )
  },
});

export const UsersManagementActions = slice.actions;

export default slice.reducer;
