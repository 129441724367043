import { FC, Fragment } from 'react';
// Mui
import { Chip } from '@mui/material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  tag: string;
  onDelete: () => void
};

const AccountTagItem:FC<Props> = ({
  // Props
  tag, onDelete
}) => {
  const { t } = useTranslation();

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Chip label={tag} onDelete={toggle} />
      {open ? (
        <ConfirmationDialog
          open={open}
          onClose={toggle}
          title={t('pages.companyAccount.removeTagTitle')}
          content={t('pages.companyAccount.removeTagConfirmation')}
          onConfirm={onDelete}
        />
      ) : null}
    </Fragment>
  )
}

export default AccountTagItem;
