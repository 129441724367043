import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import ITeam from 'app/models/Team';

export const selectTeam = (state:RootState) => state[Reducers.Teams].team;
export const selectTeams = (state:RootState) => state[Reducers.Teams].teams;
export const selectFilter = (state:RootState) => state[Reducers.Teams].filter;
export const selectLoading = (state:RootState) => state[Reducers.Teams].loading;

export const selectFilteredTeams = createSelector(
  [
    selectTeams,
    selectFilter
  ],
  (teams:ITeam[] | null, filter:any) => {
    if ( !teams ) return null;
    const { search } = filter;
    if ( !search ) return teams;
    const searchToLower = search.toLowerCase();
    return teams.filter((team:ITeam) => {
      const hasName = searchToLower ? team.name?.toLowerCase().includes(searchToLower) : true;
      const hasDescription = searchToLower ? team.description?.toLowerCase().includes(searchToLower) : true;
      return hasName || hasDescription;
    });
  }
);

export const selectTeamsAsOptions_TODO = createSelector(
  [ selectTeams ],
  (teams:ITeam[] | null) => {
    if ( !teams ) return null;
    return teams.map((team:ITeam) => ({
      id: team.id,
      name: team.name,
      subname: team.description || ''
    }));
  }
);

export const selectTeamsAsOptions = createSelector(
  [ selectTeams ],
  (teams:ITeam[] | null) => {
    if ( !teams ) return null;
    return teams.map((team:ITeam) => ({
      value: team.id,
      label: team.name,
      props: {
        description: team.description || ''
      }
    }));
  }
);