import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import { WorkflowType } from './AutomatedWorkflows.types';
// Models
import { RootState } from 'app/store';
import { IWorkflow } from './AutomatedWorkflows.models';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectWorkflows = (state:RootState) => state[Reducers.AutomatedWorkflows].workflows;
export const selectFilter = (state:RootState) => state[Reducers.AutomatedWorkflows].filter;
export const selectLoading = (state:RootState) => state[Reducers.AutomatedWorkflows].loading;

export const selectSortedWorkflows = createSelector(
  [ selectWorkflows ],
  ( workflows:IWorkflow[] | null ) => {
    if ( !workflows ) return null;
    return [...workflows].sort((a, b) => sortByName(a, b, 'name'));
  }
)

export const selectFilteredWorkflows = createSelector(
  [ selectSortedWorkflows, selectFilter ],
  ( workflows, filter ) => {
    if ( !workflows ) return null;
    return workflows.filter((workflow:IWorkflow) => {
      const matchesSearch = workflow.name.toLowerCase().includes(filter.search.trim().toLowerCase());

      return matchesSearch;
    });
  }
);

export const selectInstantWorkflows = createSelector(
  [ selectSortedWorkflows, selectFilter ],
  ( workflows:IWorkflow[] | null, filter ) => {
    if ( !workflows ) return null;
    return workflows.filter((workflow:IWorkflow) => {
      const isEnabled = workflow.enabled;
      const isInstantType = workflow.type === WorkflowType.Instant;
      const matchesSearch = workflow.name.toLowerCase().includes(filter.search.trim().toLowerCase());

      return isEnabled && isInstantType && matchesSearch;
    });
  }
);
