import { FC, Fragment } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectColumnValue } from 'app/store/ui/uiSelectors';
import { selectFilteredWorkspacePrimaryPagesIdsByPreEvent, selectFilteredWorkspaceTotalPagesIds } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Button, Box, Toolbar, Typography, Skeleton } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';
// Dialogs
import ExportPagesToPDFDialog from 'app/dialogs/ExportPagesToPDFDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';

const CARD_WIDTH = 300;

type Props = {
  listRef: any;
}

const WorkspaceToolbar:FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();
  // State
  const columnValue:string = useAppSelector(selectColumnValue);
  const pageIds:string[] | null = useAppSelector(selectFilteredWorkspacePrimaryPagesIdsByPreEvent);
  const totalPagesIds = useAppSelector(selectFilteredWorkspaceTotalPagesIds);

  const { open, toggle } = useToggle();

  const handleGoToPostEvent = () => {
    if ( pageIds && listRef && listRef.current ){
      const foundedIndex:number | undefined = pageIds.findIndex((id:string) => id === 'post-event');
      if ( typeof foundedIndex === 'undefined' ) return;
      const width = listRef.current.props.width;
      const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
      const rowIndex = Math.floor(foundedIndex/itemsPerRow);
      listRef.current.scrollToItem(rowIndex, 'center');
    }
  }

  return (
    <Fragment>
      <Toolbar sx={{ gap: 2 }}>
        {!totalPagesIds ? (
          <Skeleton width={360} />
        ) : (
          <Typography variant="body2">
            {t('pages.workspace.totalNumberOfPages')}:&nbsp;
            <b>{totalPagesIds.totalPrimary}</b>.&nbsp;
            {t('pages.workspace.includingStapledPages')}:&nbsp;
            <b>{totalPagesIds.total}</b>.
          </Typography>
        )}
        <Box flexGrow={1} />
        {pageIds && pageIds.includes('post-event') ? (
          <Button
            onClick={handleGoToPostEvent}
            variant="outlined"
          >{t('pages.workspace.postEventButton')}</Button>
        ) : null}
        <Button
          onClick={toggle}
          variant="outlined"
          color="primary"
        >{t('pages.workspace.exportPdfButton')}</Button>
      </Toolbar>
      {open ? (
        <ExportPagesToPDFDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default WorkspaceToolbar;
