import { forwardRef, FC, useState, useEffect } from 'react';
// Models
import { IOption_V2 } from 'app/models/Option';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectEpisodeAuthorsAsOptions } from 'app/store/Episodes/Episodes.selectors';
// Mui
import { Chip, Autocomplete, TextField } from '@mui/material';
// Icons
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
// i18next
import { useTranslation } from 'react-i18next';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';

type Props = {
  value: number[];
  onChange: (nextValue:any[]) => void;

  size?: any;
  margin?: any;
}

const AutocompleteAuthors:FC<Props> = forwardRef(({
  // Props
  value, onChange, size, margin = 'normal'
}, ref) => {
  const { t } = useTranslation();
  // State
  const options = useAppSelector((state:any) => selectEpisodeAuthorsAsOptions(state));

  const [ selectedOptions, setSelectedOptions ] = useState<IOption_V2[]>([]);

  // useEffect(() => {
  //   return () => {
  //     setSelectedOptions([]);
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if ( value.length && options ){
      const filteredOptions = options.filter((option:IOption_V2) => value.includes(Number(option.value)));
      setSelectedOptions(filteredOptions);
    } else {
      setSelectedOptions([]);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleChange = (_:any, nextValue:IOption_V2[] | undefined) => {
    setSelectedOptions(nextValue || []);
    onChange(nextValue ? nextValue.map((option:IOption_V2) => Number(option.value)) : []);
  }

  return (
    <Autocomplete
      {...generateAttrForTesting('control', 'autocomplete', 'authors')}
      value={selectedOptions}
      onChange={handleChange}
      options={options || []}
      size={size}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('labels.authors')}
          margin={margin}
          fullWidth
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.props.verified ? (
            <CheckCircleIcon sx={{ mr: 2 }} color="success" />
          ) : null}
          {option.label}
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            sx={{ maxWidth: '160px' }}
            key={option.value}
            label={option.label}
            title={option.label}
            size={size}
          />
        ))
      }
      getOptionLabel={(option:IOption_V2) => option.label}
      isOptionEqualToValue={(option, { value }) => option.value === value}
      fullWidth
      multiple
      limitTags={3}
      filterSelectedOptions

      ListboxProps={{...generateAttrForTesting('element', 'list', 'authors')}}
    />
  )
})

export default AutocompleteAuthors;
