import React, { ChangeEvent, useMemo } from 'react';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { CasesActions } from 'app/store/Cases/Cases.slice';
// Selector
import { selectDuplicatesFilter, selectDuplicateDocumentAsOptions } from 'app/store/Cases/Cases.selectors';
// Mui
import { Container, Grid } from '@mui/material';
// Components
import ColumnSwitcher from 'app/components/ColumnSwitcher';
import { Select } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

const DuplicatesFilter:React.FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const filter = useSelector(selectDuplicatesFilter);
  const documentsOptions = useSelector(selectDuplicateDocumentAsOptions) || [];

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(CasesActions.setDuplicatesFilter({
      field: name || '',
      value: value && value !== 'all' && name === 'documentBy' ? Number(value) : value
    }));
  }

  const pagesOptions = useMemo(() => [
    { id: 'all', name: t('pages.duplicates.allLabel') },
    { id: 'hasWorkspacePages', name: t('pages.duplicates.hasWorkspacePages') },
    { id: 'hasWorkspaceDuplicates', name: t('pages.duplicates.hasWorkspaceDuplicates') }
    // eslint-disable-next-line
  ], [])

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2} alignItems="center" style={{ paddingTop: '16px', paddingBottom: '8px' }}>
        <Grid item xs style={{ flex: '0 1 auto' }}>
          <ColumnSwitcher />
        </Grid>
        <Grid item xs style={{ flex: '0 1 auto' }}>
          <Select
            sx={{ minWidth: 280, maxWidth: 320 }}
            label={t('labels.byDocument')}
            value={filter.documentBy} name="documentBy"
            onChange={handleChange}
            options={[
              { id: 'all', name: t('labels.all') },
              ...documentsOptions
            ]}
            size="small"
            margin="none"
          />
        </Grid>
        <Grid item xs style={{ flex: '0 1 auto' }}>
          <Select
            sx={{ minWidth: 280, maxWidth: 320 }}
            label={t('labels.pagesFilter')}
            value={filter.pages} name="pages"
            onChange={handleChange}
            options={pagesOptions}
            size="small"
            margin="none"
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default DuplicatesFilter;