import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Theme, Box, Tooltip } from '@mui/material';
// Icons
import {
  DeleteOutline as DeleteOutlineIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';

type Props = {
  onClick: () => void;
}

const RemoveStepsButton:FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Box
      className="stepsAction_hover"
      sx={{
        position: 'absolute',
        bgcolor: 'white',
        top: 0, right: 0,
        border: (theme:Theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1,
        p: 1,
        m: -5,
        opacity: 0,
        zIndex: 2,
        visibility: 'hidden',
        transition: 'opacity 0.25s, visibility 0.25s'
      }}
    >
      <Tooltip title={t('buttons.remove')}>
        <IconButton
          name="Remove steps"
          onClick={onClick}
          size="small"
          color="error"
        ><DeleteOutlineIcon fontSize="small" /></IconButton>
      </Tooltip>
    </Box>
  )
}

export default RemoveStepsButton;
