import { createApi } from "@reduxjs/toolkit/query/react";
// Utilities
import baseQuery from "app/utilities/BaseQuery";

const episodeAuthorsApi = createApi({
  reducerPath: 'episodeAuthors.api',
  baseQuery,
  endpoints: (builder) => ({
    getAuthors: builder.query({
      query: (params:any) => {
        const queryParams = new URLSearchParams();
        Object.keys(params).forEach((key:string) => {
          queryParams.append(key, params[key]);
        });
        return `/episodeAuthors?${queryParams}`;
      },
      keepUnusedDataFor: 0
    }),
  })
});

export const { useLazyGetAuthorsQuery } = episodeAuthorsApi;

export default episodeAuthorsApi;
