import { FC, useEffect, useMemo } from 'react';
// Models
import { ICurrentAccount } from 'app/models/Account';
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectCollections } from 'app/store/DMSCollections/DMSCollections.selectors';
import { selectFilter, selectCollectionTotalDocuments } from 'app/store/DMSBatches/DMSBatches.selectors';
// Mui
import { Theme, Box, Tabs, Tab } from '@mui/material';
// Components
import { Loader } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
import LocalStorageService from 'app/services/LocalStorage.service';

const CollectionTabs:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);
  const collections:ICollection[] | null = useAppSelector(selectCollections);
  const collectionTotalDocuments = useAppSelector(selectCollectionTotalDocuments);
  const filter = useAppSelector(selectFilter);

  const tabs = useMemo(() => {
    if ( !collections ) return null;

    const options = collections.map((collection:ICollection) => ({
      id: collection.id,
      name: collection.name
    }));

    if ( collections.length === 1 ) return options;

    return [
      { id: 0, name: t('labels.all') },
      ...options
    ];
    // eslint-disable-next-line
  }, [collections]);

  const allTotalDocument = useMemo(() => {
    if ( !collectionTotalDocuments ) return 0;

    return Object.keys(collectionTotalDocuments).reduce((acc:number, cur:any) => {
      return acc + collectionTotalDocuments[cur];
    }, 0);
    // eslin-disable-next-line
  }, [collectionTotalDocuments]);

  useEffect(() => {
    if ( !tabs || !currentAccount ) return;

    const collectionTab = LocalStorageService.getCollectionTab(currentAccount.id);

    handleChange(null, collectionTab);
    // eslint-disable-next-line
  }, [currentAccount, tabs])

  const handleChange = (_:any, nextTab:number) => {
    dispatch(DMSBatchesActions.setFilter({ field: 'collectionId', value: nextTab }));

    if ( !currentAccount ) return;

    LocalStorageService.setCollectionTab(currentAccount.id, nextTab);
  }

  if ( !tabs || typeof filter.collectionId === 'undefined' ) return <Loader />
  return (
    <Box sx={{
      borderBottom: (theme:Theme) => `1px solid ${theme.palette.divider}`,
      px: 4, mb: 4
    }}>
      <Tabs
        value={filter.collectionId}
        onChange={handleChange}
        variant={tabs.length === 1 ? 'standard' : 'scrollable'}
        centered={tabs.length === 1 ? true : false}
      >
        {tabs.map((tab:any) => {
          // Tab 'All'
          const isTabAll = tab.id === 0;

          const totalDocuments = !collectionTotalDocuments
            ? null
            : isTabAll
              ? allTotalDocument
              : (collectionTotalDocuments[tab.id] || 0)
          ;

          return (
            <Tab
              sx={{ textTransform: 'initial' }}
              key={`collection tab item ${tab.id}`}
              label={`
                ${tab.name}
                ${totalDocuments === null ? '' : `(${totalDocuments})`}
              `}
              value={tab.id}
            />
          )
        })}
      </Tabs>
    </Box>
  )
}

export default CollectionTabs;
