import { FC, Fragment } from 'react';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { RootState } from 'app/store';
import { IMyUser } from 'app/models/User';
import IPage from 'app/models/Page';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectPageEntity, selectStapleModeType } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Mui
import { IconButton, Tooltip } from '@mui/material';
// Components
import Icon from 'app/components/Icon';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Services
import PageService from 'app/services/PageService';
// 
import CardPageColorsDialog from 'app/dialogs/CardPageColorsDialog';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  pageId:string;
}

const CardPageFootColors:FC<Props> = ({
  // Props
  pageId
}) => {
  const { t } = useTranslation();

  const [ documentId, pageNum ] = PageService.fromPageId(pageId);
  // State
  const trialMode:boolean = useAppSelector(selectTrialMode);
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const page = useAppSelector((state:RootState) => selectPageEntity(state, {
    documentId,
    pageNum
  })) as IPage;
  const stapleModeType = useAppSelector(selectStapleModeType);

  const { open, toggle } = useToggle();

  const myUserRoleClientOrPresenter = myUser?.role === UserRoles.Client || myUser?.role === UserRoles.Presenter;

  if ( trialMode ) return null;
  if ( myUserRoleClientOrPresenter ) return null;
  if ( stapleModeType ) return null;
  if ( page.staple && page.staple.order > 1 ) return null;
  return (
    <Fragment>
      <Tooltip title={t('labels.pageColors')}>
        <IconButton
          onClick={toggle}
          size="small"
        ><Icon icon="color" /></IconButton>
      </Tooltip>
      {open ? (
        <CardPageColorsDialog
          open={open}
          onClose={toggle}
          page={page}
        />
      ) : null}
    </Fragment>
  )
}

export default CardPageFootColors;