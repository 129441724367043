import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Box, Toolbar, Typography } from '@mui/material';
// Constants
import { SIDEBAR_WIDTH } from 'app/App.constants';
// 
import GlobalTemplatesList from './GlobalTemplatesList';

const TemplatesSidebar:FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      // flexGrow: 1,
      width: SIDEBAR_WIDTH,
      bgcolor: 'white',
      borderLeft: '1px solid rgba(0,0,0,0.08)',
      overflowY: 'auto'
    }}>
      <Toolbar>
        <Typography variant="h6">{t('pages.templates.globalTitle')}</Typography>
      </Toolbar>
      <GlobalTemplatesList />
    </Box>
  )
}

export default TemplatesSidebar;
