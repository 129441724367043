import { FC, useContext } from 'react';
// Models
import { IBillingPackageItem } from 'app/models/BillingPackage';
// Selectors
import { selectEntities as selectServiceEntities } from 'app/store/BillingServices/BillingServices.selectors';
// Mui
import { Box, Paper, Typography } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// Contexts
import PackageServicesContext from 'app/context/PackageServicesContext';
// 
import PackageServicesListItem from './PackageServicesListItem';
// i18next
import { useTranslation } from 'react-i18next';
import { sortByName } from 'app/utilities/SortBy';
import { useAppSelector } from 'app/hooks/useStore';

const PackageServicesList:FC = () => {
  const { t } = useTranslation();

  const { items } = useContext(PackageServicesContext);

  const serviceEntities = useAppSelector(selectServiceEntities);

  const sx = { minHeight: 240 };

  if ( !items ) return <Box sx={sx}><Loader /></Box>;
  if ( !items.length ) return <Box sx={sx}><Message text={t('pages.subscriptionPackages.noServicesData')} /></Box>;

  const sortedItems = [...items].sort((a, b) => {
    const serviceA = serviceEntities[a.serviceId];
    const serviceB = serviceEntities[b.serviceId];
    return sortByName(serviceA, serviceB, 'name');
  });

  return (
    <Box sx={{
      ...sx,
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Typography sx={{
        fontWeight: 700,
        mb: 1
      }} variant="subtitle1">{t('labels.services')}</Typography>
      <Paper sx={{ flexGrow: 1, p: 4 }}>
        {sortedItems.map((item:IBillingPackageItem) => (
          <PackageServicesListItem
            key={`Service item ${item.serviceId}`}
            packageItem={item}
          />
        ))}
      </Paper>
    </Box>
  )
}

export default PackageServicesList;
