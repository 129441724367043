import { FC } from 'react';
// Models
import IUser from 'app/models/User';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredUsers } from 'app/store/Users/Users.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import UsersListItem from './UsersListItem';
// i18next
import { useTranslation } from 'react-i18next';

const UsersList:FC = () => {
  const { t } = useTranslation();
  // State
  const users:IUser[] | null = useAppSelector(selectFilteredUsers);

  if ( !users ) return <Loader />;
  if ( !users.length ) return <Message text={t('pages.users.noData')} />;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {users.map((user:IUser) => (
        <UsersListItem
          key={`user-item-${user.id}`}
          user={user}
        />
      ))}
    </Box>
  )
}

export default UsersList;
