// Types
import Strategy from 'app/types/Strategy';
import MixpanelEvents from 'app/features/mixpanel/MixpanelEvents';
// Models
import { IValidateError } from 'app/store/DMSBatches/DMSBatches.models';
// Services
import Mixpanel from "app/services/Mixpanel.service";

export const validateBatchStrategy:Strategy = (payload, { accountId, userId, caseId }) => {
  const filesErrors = payload as any;

  const hasError = filesErrors.filter((error:IValidateError[]) => error.length).length;

  if ( hasError ){
    const errors = filesErrors.map((error:IValidateError[]) => error.map(error => error.errorCode).join(" | "));
    const numberOfUploadedFiles = filesErrors.length;
  
    Mixpanel.track(MixpanelEvents.DocumentsValidationFailed, {
      portal: 'case',
      accountId,
      userId,
      caseId,
      numberOfUploadedFiles,
      errors
    });
  }
};