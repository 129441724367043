import { FC, Fragment, useEffect } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { setCloudfrontCookies } from 'app/store/Content/Сontent.async';
import { getReportSectionTemplate } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.async';
// Actions
import { ReportSectionTemplatesActions } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.slice';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import ReportSectionTemplatesList from './ReportSectionTemplatesList';
import ReportSectionTemplatesFab from './ReportSectionTemplatesFab';

const ReportSectionTemplatesPage:FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCloudfrontCookies({}));
    dispatch(getReportSectionTemplate({}));

    return () => {
      dispatch(ReportSectionTemplatesActions.setInitialField('reportSectionTemplates'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.reportSectionTemplates.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <ReportSectionTemplatesList />
          <ReportSectionTemplatesFab />
        </Container>
      </Box>
    </Fragment>
  )
}

export default ReportSectionTemplatesPage;
