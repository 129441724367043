import { useContext, createContext } from "react";
// Types
import FileFormats from "app/types/FileFormats";
// Models
import { IValidateError, ValidateErrorCodes } from "app/store/DMSBatches/DMSBatches.models";

export interface IFileItem {
  id: string;
  file: File;
  type: string;
  fileFormat: FileFormats | null;
  errors: IValidateError[];
  comment: string;
  collectionId?: number;
};

export interface IFileItemsErrorGroup {
  errorCode?: ValidateErrorCodes;
  fileItems: IFileItem[];
}

export interface IUploadContext {
  comment: string;
  onCommentChange: (comment:string) => void;
  uploadedFilesCount: number;
  onSetUploadedFilesCount: (initialState:number | ((prevState:number) => number)) => void;

  fileItems: IFileItem[];

  onAdd: (files:File[]) => void;
  onRemove: (fileItemId:string) => void;
  onUpdate: (fileItemId:string, data:any) => void;
  updateFileItemsCollections: (fileFormat:FileFormats | null, collectionId:number) => void;

  handleBuildErrorMap: (errors:IValidateError[][], fileIdsByIndexes:string[]) => void;
  handleSubmit: (cb:() => void) => void;
  formState: {
    isLoading: boolean;
    isSubmitted: boolean;
    isValid: boolean;
    onSetIsLoading: (isLoading:boolean) => void;
    onSetIsSubmitted: (isSubmitted:boolean) => void;
  };
}

export const UploadContext = createContext<IUploadContext | undefined>(undefined);

const useUploadContext = ():IUploadContext => {
  const context = useContext(UploadContext);
  if ( !context ) throw new Error('useUploadContext must be used within a UploadProvider');
  return context;
}

export default useUploadContext;