import { FC, useEffect } from 'react';
import { Dayjs } from 'dayjs';
// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/hooks/useStore';
// Actions
import { MedicalActions } from 'app/store/Medical/Medical.slice';
// Selectors
import { selectGridResizerWidth } from 'app/store/AppUI/AppUI.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
// 
import ScheduleSearch from './ScheduleSearch';
import ScheduleList from './ScheduleList';
import FactsDataCopyButton from './FactsDataCopyButton';
import FactList from './FactList';
// i18next
import { useTranslation } from 'react-i18next';
// Context
import EpisodeFactsProvider from 'app/context/EpisodeFacts.context';

type Props = {
  open: boolean;
  onClose: () => void;
  // ToDO
  date: Dayjs | null;
}

const EpisodeFactsDialog:FC<Props> = ({
  // Props
  open, onClose, date
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  // State
  const gridResizerWidth:number = useSelector(selectGridResizerWidth);

  useEffect(() => {
    return () => {
      dispatch(MedicalActions.setSearchHcpcsCode(''));
      dispatch(MedicalActions.resetHcpcsCode());
      dispatch(MedicalActions.resetSchedules());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('dialogs.episodeFacts.title')}

      sx={{
        width: `${100 - gridResizerWidth}%`,
        left: 'auto',
        '& .MuiBackdrop-root': {
          width: `${100 - gridResizerWidth}%`,
          left: 'auto',
        }
      }}
    >
      <EpisodeFactsProvider year={date?.year() || null}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ScheduleSearch />
          <ScheduleList />
        </Box>
        <Box sx={{ minHeight: 320, borderTop: '1px solid rgba(0,0,0,0.08)', pt: 4, mt: 4 }}>
          <FactsDataCopyButton />
          <FactList />
        </Box>
      </EpisodeFactsProvider>
    </Dialog>
  )
}

export default EpisodeFactsDialog;