import { FC } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionTotalPages } from 'app/store/DMSBatches/DMSBatches.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const CollectionTotalPages:FC = () => {
  const { t } = useTranslation();
  // State
  const totalPages:number | null = useAppSelector(selectCollectionTotalPages);

  if ( totalPages === null ) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid rgba(0,0,0,0.08)',
        p: 4
      }}
    >
      <Typography
        sx={{ flexGrow: 1 }}
        variant="subtitle2"
      >{t('labels.total')}:</Typography>
      <Typography
        variant="subtitle2"
      >{totalPages} {t('labels.pages')}</Typography>
    </Box>
  )
}

export default CollectionTotalPages;
