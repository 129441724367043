import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Selectors
import { selectPreviewPageOpen } from 'app/store/AppUI/AppUI.selectors';
import { selectDocument } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Box } from '@mui/material';
// Compoentns
import { Loader } from 'app/components/Utilities';
// Hooks
import DocumentToolbar from './DocumentToolbar';
import DocumentComments from './DocumentComments';
import DocumentPreview from './DocumentPreview';

const DocumentCustomPage:FC = () => {

  const { documentId } = useParams<{ documentId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const document = useAppSelector(selectDocument);
  const previewPageOpen = useAppSelector(selectPreviewPageOpen);

  const [ openComments, setOpenComments ] = useState(true);

  useEffect(() => {
    if ( !documentId ) return;

    dispatch(getDocument(Number(documentId)));
    // eslint-disable-next-line
  }, [documentId]);

  useEffect(() => {
    if ( !document ) return;
    if ( +documentId !== document.id ){
      // reset open comments when document changes
      setOpenComments(true);
    };
  }, [documentId, document]);

  if ( !document ) return <Loader />;
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <DocumentToolbar openComments={openComments} toggleOpenComments={setOpenComments} />
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: previewPageOpen ? 'flex-end' : 'center',
        gap: 4,
        p: 4,
        overflowY: 'auto',
      }}>
        <DocumentPreview />
        {openComments ? (
          <DocumentComments />
        ) : null}
      </Box>
    </Box>
  )
}

export default DocumentCustomPage;