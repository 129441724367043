import { FC } from 'react';
// Types
import { DroppableIds, ContentType } from 'app/types/ContentItem';
// Mui
import { Box } from '@mui/material';
// Components
import DataLoading from 'app/components/DataLoading';
// 
import ContentDroppable from './ContentDroppable';
import ContentDraggable from './ContentDraggable';
import ContentItem from './ContentItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  documentIds: number[];
}

const DocumentsTabContent:FC<Props> = ({
  // Props
  documentIds
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      pt: 4
    }}>
      <ContentDroppable droppableId={DroppableIds.Document}>
        <DataLoading data={documentIds} text={t('dialogs.reportPreset.noDocumentsData')}>
          {(data) => (
            <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
              {data.map((id, index) => (
                <ContentDraggable
                  key={`${ContentType.Document} item ${id}`}
                  index={index}
                  draggableId={`${ContentType.Document}-${id}`}
                ><ContentItem id={id} contentType={ContentType.Document} /></ContentDraggable>
              ))}
            </Box>
          )}
        </DataLoading>
      </ContentDroppable>
    </Box>
  )
}

export default DocumentsTabContent