import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.documentsExport') }),
  Send: i18n.t('notifications.material.send')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.documentsExport') });
  Messages.Send = i18n.t('notifications.material.send');
});