import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Types
import Reducers from "app/types/Reducers";
// Models
import IEpisodeFact from "app/models/EpisodeFact";
// Async
import { getFacts, createFact, updateFact, deleteFact } from './EpisodeFacts.async';

interface IState {
  facts: IEpisodeFact[] | null;
  editableFactIds: number[];
  loading: boolean;
};

const initialState:IState = {
  facts: null,
  editableFactIds: [],
  loading: false
};

const slice = createSlice({
  name: Reducers.EpisodeFacts,
  initialState,
  reducers: {
    createFact: (state, action:PayloadAction<Omit<IEpisodeFact, 'id'>>) => {
      if ( state.facts ){
        const id = !state.facts.length ? -1 : -(state.facts.length + 1)
        state.facts = [{ ...action.payload, id }, ...state.facts];
        state.editableFactIds = [
          ...state.editableFactIds,
          id
        ];
      }
    },
    updateFact: (state, action:PayloadAction<IEpisodeFact>) => {
      if ( state.facts ){
        state.facts = state.facts.map((fact:IEpisodeFact) => {
          if ( fact.id === action.payload.id ) return action.payload;
          return fact;
        });
      }
    },
    deleteFact: (state, action:PayloadAction<number>) => {
      if ( state.facts ){
        state.facts = state.facts.filter((fact:IEpisodeFact) => fact.id !== action.payload);
      }
    },
    clearFactsWithNegativeId: (state) => {
      if ( state.facts ){
        state.facts = state.facts.filter((fact:IEpisodeFact) => fact.id > 0);
      }
    },
    setFactEditable: (state, action:PayloadAction<number>) => {
      state.editableFactIds = [
        ...state.editableFactIds,
        action.payload
      ];
    },
    setFactNotEditable: (state, action:PayloadAction<number>) => {
      state.editableFactIds = state.editableFactIds.filter((id:number) => id !== action.payload);
    },
    // Default
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers(builder) {
    // Get facts
    builder.addCase(getFacts.pending, (state) => {
      state.facts = initialState.facts;
    });
    builder.addCase(getFacts.fulfilled, (state, action:PayloadAction<IEpisodeFact[]>) => {
      state.facts = action.payload;
    });
    // Create fact
    builder.addCase(createFact.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createFact.fulfilled, (state, action:PayloadAction<IEpisodeFact>) => {
      if ( state.facts ){
        state.facts = [action.payload, ...state.facts];
      }
    });
    // Update fact
    builder.addCase(updateFact.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateFact.fulfilled, (state, action:PayloadAction<IEpisodeFact>) => {
      if ( state.facts ){
        state.facts = state.facts.map((fact:IEpisodeFact) => {
          if ( fact.id === action.payload.id ) return action.payload;
          return fact;
        });
      }
    });
    // Delete fact
    builder.addCase(deleteFact.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFact.fulfilled, (state, action:PayloadAction<number>) => {
      if ( state.facts ){
        state.facts = state.facts.filter((fact:IEpisodeFact) => fact.id !== action.payload);
      }
    });

    builder.addMatcher(
      (action) => action.type.includes('/rejected') || action.type.includes('/fulfilled'),
      (state) => {
        state.loading = false;
      }
    )
  },
});

export const EpisodeFactsActions = slice.actions;

export default slice.reducer;