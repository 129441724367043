import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { FormHelperText } from '@mui/material';
// 
import useUploadContext from './UploadProvider/useUploadContext';

const FilesUploaderError:FC = () => {
  const { t } = useTranslation();

  const { formState:{ isSubmitted, isValid } } = useUploadContext();

  if ( !isSubmitted || isValid ) return null;
  return (
    <FormHelperText sx={{ mt: 0, mb: 4 }} error>{t('dialogs.caseUploadDocuments.uploadErrorText')}</FormHelperText>
  )
}

export default FilesUploaderError;