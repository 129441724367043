import { FC, Fragment } from 'react';
// Models
import IBillingOrder, { IBillingOrderLine } from 'app/models/BillingOrder';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { completeOrder, cancelOrder } from 'app/store/BillingOrders/BillingOrders.async';
// Mui
import {
  Theme,
  Divider,
  Box, Paper, Typography
} from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Hooks
import useMenu from 'app/hooks/useMenu';
// 
import OrderLineItem from './OrderLineItem';
// i18next
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store';
import { selectAccountById } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { InfoBlock } from 'app/components/Utilities';

type Props = {
  order: IBillingOrder;
}

const OrdersListItem:FC<Props> = ({
  // Props
  order
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const companyAccount = useAppSelector((state:RootState) => selectAccountById(state, {
    accountId: order.accountId
  }));

  const { Menu, MenuItem, openMenu } = useMenu();

  const handleCompleteOrder = () => {
    dispatch(completeOrder(order.id));
  }
  const handleCancelOrder = () => {
    dispatch(cancelOrder(order.id));
  }

  const isStatusOpen = order.status === 'open';

  const statusLabels:Record<string, string> = {
    completed: t('pages.billingOrders.completed'),
    cancelled: t('pages.billingOrders.cancelled'),
    open: t('labels.open')
  }

  return (
    <Paper sx={{ display: 'flex', p: 4, '& + &': { mt: 4 } }} variant="outlined">
      <Box sx={{ flexGrow: 1, pr: isStatusOpen ? 2 : 0 }}>
        <Typography
          sx={{
            color: (theme:Theme) => order.status === 'cancelled'
                  ? theme.palette.error.main
                  : order.status === 'completed'
                    ? theme.palette.success.main
                    : theme.palette.info.main
            ,
            textTransform: 'capitalize'
          }}
          variant="caption"
        >{statusLabels[order.status]}</Typography>
        {order.case ? (
          <Typography variant="subtitle1">{order.case.name}</Typography>
        ) : null}
        {companyAccount ? (
          <InfoBlock label={t('labels.company')} value={companyAccount.company} direction="row" />
        ) : null}
        {order.lines && order.lines.length ? (
          <Fragment>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', gap: 4 }}>
              {order.lines.map((line:IBillingOrderLine) => (
                <OrderLineItem
                  key={`${order.id}-line-item-${line.id}`}
                  line={line}
                />
              ))}
            </Box>
          </Fragment>
        ) : null}
      </Box>
      {isStatusOpen ? (
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`Order dropdown ${order.id}`}
            aria-label="Order item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              name={`complete-${order.id}`}
              onClick={handleCompleteOrder}
            >{t('pages.billingOrders.markAsCompleted')}</MenuItem>
            <MenuItem
              sx={{ color: (theme:Theme) => theme.palette.error.main }}
              name={`cancel-${order.id}`}
              onClick={handleCancelOrder}
            >{t('pages.billingOrders.markAsCancelled')}</MenuItem>
          </Menu>
        </Box>
      ) : null}
    </Paper>
  )
}

export default OrdersListItem;
