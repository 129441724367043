import { FC } from 'react';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { addToCompare, removeFromCompare } from 'app/store/ui/uiSlice';
// Selector
import { selectIsInCompare } from 'app/store/ui/uiSelectors';
import { selectStapleModeType } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { IconButton, Tooltip } from '@mui/material';
// Components
import Icon from 'app/components/Icon';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  pageId: string;
};

const CardPageFootCompare:FC<Props> = ({
  // Props
  pageId
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const isInCompare = useAppSelector((state:RootState) => selectIsInCompare(state, { id: pageId }));
  const stapleModeType = useAppSelector(selectStapleModeType);

  const handleToggleCompare = () => {
    if ( isInCompare ){
      dispatch(removeFromCompare(pageId));
    } else {
      dispatch(addToCompare(pageId))
    }
  }

  if ( stapleModeType ) return null;
  return (
    <Tooltip title={isInCompare ? t('components.cardPage.removePageFromCompare') : t('components.cardPage.addPageToCompare')}>
      <IconButton
        onClick={handleToggleCompare}
        color={isInCompare ? 'primary' : 'default'}
        size="small"
      ><Icon icon="compare" /></IconButton>
    </Tooltip>
  )
}

export default CardPageFootCompare;