import { ChangeEvent, FC, Fragment, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Models
import IExportTemplate from 'app/models/ReportTemplate';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createExportTemplate, updateExportTemplate } from 'app/store/ExportTemplates/ExportTemplates.async';
// Selectors
import { selectLoading } from 'app/store/ExportTemplates/ExportTemplates.selectors';
// Mui
import { Box, FormHelperText, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  exportTemplate?: IExportTemplate;
}

const ExportTemplateFormDialog:FC<Props> = ({
  // Props
  open, onClose, exportTemplate
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [ file, setFile ] = useState<File | null>(null);

  const { control, handleSubmit, formState: { isSubmitted, errors } } = useForm<IFormData>({
    defaultValues: {
      name: exportTemplate?.name || ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    if ( !exportTemplate && !file ) return;

    try {
      if ( exportTemplate?.id ){
        await dispatch(updateExportTemplate({ exportTemplateId: exportTemplate.id, data })).unwrap();
      } else {
        const formData = new FormData();
        if ( file ){
          formData.append('data', new Blob([JSON.stringify({
            name: data.name,
            type: file.type
          })], { type: 'application/json' }));
          formData.append('file', file);
        }
        await dispatch(createExportTemplate(formData)).unwrap();
      }
      onClose();
    } catch(error){}
  })

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if ( !files ) return;
    setFile(files[0]);
  }

  const handleClick = () => {
    setFile(null);
    if ( inputRef.current ) inputRef.current.value = '';
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel report template dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name={`${exportTemplate ? 'update' : 'create'} report template dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{exportTemplate ? t('buttons.update') : t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={exportTemplate ? t('pages.exportTemplates.updateFormTitle') : t('pages.exportTemplates.createFormTitle')}
      actions={actions}
    >
      <form noValidate>
        {/* Name */}
        <Controller
          control={control} name="name"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.name')}
              error={Boolean(errors.name)}
              helperText={errors.name?.message || ''}
              required
            />
          )}
        />

        {!exportTemplate ? (
          <Fragment>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
              <input
                ref={inputRef}
                type="file"
                onChange={handleChange}
                id="file"
                hidden
              />
              <label htmlFor="file">
                <Button
                  aria-label="Add report template file button"
                  name="Add report template file"
                  component="span"
                  color="primary"
                  variant="contained"
                >{t('pages.exportTemplates.chooseFile')}</Button>
              </label>
              {file ? (
                <Fragment>
                  <Button
                    aria-label="Clear file button"
                    name="Clear file"
                    onClick={handleClick}
                    variant="outlined"
                  >{t('buttons.clear')}</Button>
                  <Typography>{file.name}</Typography>
                </Fragment>
              ) : null}
            </Box>
            {isSubmitted && !file ? (
              <FormHelperText sx={{ ml: 3 }} error>{t('validations.fieldRequired')}</FormHelperText>
            ) : null}
          </Fragment>
        ) : null}
      </form>
    </Dialog>
  )
}

export default ExportTemplateFormDialog;
