import { FC, useState } from 'react';
// Types
import Colors from 'app/types/Colors';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
// Selectors
import { selectPagesFilter } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
import { selectEpisodeTypesAsOptions, selectEpisodeLabelsAsOptions, selectEpisodeAuthorsAsOptions } from 'app/store/Episodes/Episodes.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import ColumnSwitcher from 'app/components/ColumnSwitcher';
import ColorPicker from 'app/components/ColorPicker';
import TagPicker from 'app/components/TagPicker';
// i18next
import { useTranslation } from 'react-i18next';

const PagesFilter:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const filter:any = useAppSelector(selectPagesFilter);
  const episodeTypesOptions = useAppSelector(selectEpisodeTypesAsOptions);
  const episodeLabelsOptions = useAppSelector(selectEpisodeLabelsAsOptions);
  const episodeAuthors = useAppSelector(selectEpisodeAuthorsAsOptions);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const handleAutocompleteChange = (newValue:any) => (field:any) => {
    setStateFilter((prevState:any) => ({ ...prevState, [field]: newValue }));
    dispatch(DMSDocumentPagesActions.setPagesFilter({ field, value: newValue }));
  }

  const handleColorClick = (color:Colors|string) => {
    const nextColors = stateFilter.colors.includes(color)
      ? stateFilter.colors.filter((c:Colors | string) => c !== color)
      : [...stateFilter.colors, color]
    ;
    setStateFilter((prevState:any) => ({ ...prevState, colors: nextColors }));
    dispatch(DMSDocumentPagesActions.setPagesFilter({ field: 'colors', value: nextColors }));
  }

  const handleClickTag = (nextTag:string | null) => {
    const idx = stateFilter.tags.indexOf(nextTag);
    const nextTags = idx !== -1 ? stateFilter.tags.filter((t:string, i:number) => i !== idx) : [...stateFilter.tags, nextTag];
    setStateFilter((prevState:any) => ({ ...prevState, tags: nextTags }));
    dispatch(DMSDocumentPagesActions.setPagesFilter({ field: 'tags', value: nextTags }));
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 4, p: 4 }}>
      <Autocomplete
        value={stateFilter.episodeTypes}
        onChange={handleAutocompleteChange('episodeTypes')}
        options={episodeTypesOptions || []}
        TextFieldProps={{
          sx: {
            minWidth: 260
          },
          label: t('labels.episodeTypes'),
          margin: "none",
          size: "small"
        }}
      />
      <Autocomplete
        value={stateFilter.episodeLabels}
        onChange={handleAutocompleteChange('episodeLabels')}
        options={episodeLabelsOptions || []}
        TextFieldProps={{
          sx: {
            minWidth: 260
          },
          label: t('labels.episodeLabels'),
          margin: "none",
          size: "small"
        }}
      />
      <Autocomplete
        value={stateFilter.episodeAuthors}
        onChange={handleAutocompleteChange('episodeAuthors')}
        options={episodeAuthors || []}
        TextFieldProps={{
          sx: {
            minWidth: 260
          },
          label: t('labels.episodeAuthors'),
          margin: "none",
          size: "small"
        }}
      />
      <ColumnSwitcher />
      <TagPicker
        id="presenter-workspace-tags"
        onClick={handleClickTag}
        noTagVisible={true}
        activeTags={stateFilter.tags}
      />
      <ColorPicker
        id="workspacePage"
        activeColors={stateFilter.colors}
        noColorVisible={true}
        onClick={handleColorClick}
      />
    </Box>
  )
}

export default PagesFilter;
