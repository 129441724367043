import { FC, Fragment } from 'react';
// Dialogs
import TemplateWizardFormDialog from 'app/dialogs/TemplateWizardFormDialog';
import TemplateFormDialog from 'app/dialogs/TemplateFormDialog';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

const TemplatesFab:FC = () => {
  const { t } = useTranslation();

  const { open:openWizardDialog, toggle:toggleWizardDialog } = useToggle();
  const { open:openFormDialog, toggle:toggleFormDialog } = useToggle();

  const handelConfirm = () => {
    toggleWizardDialog();
    toggleFormDialog();
  }

  return (
    <Fragment>
      <Fab
        name="Create template"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        onClick={toggleWizardDialog}
        color="primary"
        variant="extended"
      >{t('pages.templates.createButton')}</Fab>
      {openWizardDialog ? (
        <TemplateWizardFormDialog
          open={openWizardDialog}
          onClose={toggleWizardDialog}
          onConfirm={handelConfirm}
        />
      ) : null}
      {openFormDialog ? (
        <TemplateFormDialog
          open={openFormDialog}
          onClose={toggleFormDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default TemplatesFab;
