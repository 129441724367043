import { FC, useState, useEffect, useCallback, ChangeEvent } from 'react';
import { debounce } from 'throttle-debounce';
// Models
import IInsuranceCase from 'app/models/Case';
import { IWorkflow } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.models';
// store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getWorkflows } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.async';
// Actions
import { AutomatedWorkflowsActions } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.slice';
// Selectors
import { selectCaseAiChatEnabled, selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectFilter, selectInstantWorkflows } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.selectors';
import { executeInstantWorkflow } from 'app/store/AutomatedWorkflowsExecutions/AutomatedWorkflowsExecutions.async';
// Mui
import { Box, Toolbar, Paper, Typography, Tooltip, CircularProgress } from '@mui/material';
// Icons
import {
  PlayCircleFilledWhiteOutlined as PlayCircleFilledWhiteOutlinedIcon
} from '@mui/icons-material';
// Components
import DataLoading from 'app/components/DataLoading';
import { IconButton, Input } from 'app/components/Mui';
// Constants
import { SIDEBAR_WIDTH } from 'app/App.constants';
// i18next
import { useTranslation } from 'react-i18next';

const WorkflowsSidebar:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const caseAiChatEnabled = useAppSelector(selectCaseAiChatEnabled);
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const workflows = useAppSelector(selectInstantWorkflows);
  const filter = useAppSelector(selectFilter);

  const [ disabledWorkflowIds, setDisabledWorkflowIds ] = useState<number[]>([]);
  const [ stateFilter, setStateFilter ] = useState(filter);

  // eslint-disable-next-line
  const debounceFilter = useCallback(debounce(500, (field:keyof typeof filter, value:any) => {
    dispatch(AutomatedWorkflowsActions.setFilter({ field, value }))
  }), []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState) => ({
      ...prevState,
      [name]: value
    }));
    debounceFilter(name as keyof typeof filter, value);
  }

  const handleClearFilter = () => {
    dispatch(AutomatedWorkflowsActions.setInitialField('filter'));
  }

  useEffect(() => {
    setStateFilter(filter);
  }, [filter]);

  useEffect(() => {
    dispatch(getWorkflows({}));

    return () => {
      handleClearFilter();
    }
    // eslint-disable-next-line
  }, []);

  const handleClick = (workflowId:number) => async () => {
    setDisabledWorkflowIds(prevState => [...prevState, workflowId]);
    try {
      await dispatch(executeInstantWorkflow({
        caseId: insuranceCase.id,
        workflowId
      }));
    } catch(error){
      console.error(error)
    } finally {
      setDisabledWorkflowIds(prevState => prevState.filter(id => id !== workflowId));
    }
  }

  const renderWorkflowItem = (workflow:IWorkflow) => {
    const loadingWorkflow = disabledWorkflowIds.includes(workflow.id);
    return (
      <Paper
        key={`workflow item ${workflow.id}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          px: 3, py: 1
        }}
        variant="outlined"
      >
        <Box sx={{ flexGrow: 1, pr: 2 }}>
          <Typography variant="subtitle1" fontWeight={500}>{workflow.name}</Typography>
        </Box>
        <Tooltip title={caseAiChatEnabled ? 'Run' : 'AI Chat should be enabled'}>
          <span>
            <IconButton
              name={`Run workflow ${workflow.id}`}
              aria-label="Run workflow button"
              onClick={handleClick(workflow.id)}
              disabled={!caseAiChatEnabled || loadingWorkflow}
              size="small"
            >{loadingWorkflow ? <CircularProgress size={24} thickness={6} /> : <PlayCircleFilledWhiteOutlinedIcon />}</IconButton>
          </span>
        </Tooltip>
      </Paper>
    );
  }

  return (
    <Box sx={{
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      width: SIDEBAR_WIDTH,
      bgcolor: 'white',
      borderRight: '1px solid rgba(0,0,0,0.08)',
      overflowY: 'auto'
    }}>
      <Toolbar>
        <Typography variant="h6">Workflows</Typography>
      </Toolbar>
      <Box sx={{ mt: 4, px: 4 }}>
        <Input
          label={t('labels.search')} id="search" name="search"
          value={stateFilter.search}
          onChange={handleChange}
          size="small"
          margin="none"
          onClear={handleClearFilter}
        />
      </Box>
      <DataLoading
        data={workflows}
        text="No workflow(s)"
      >
        {(data) => (
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            overflowY: 'auto',
            p: 4
          }}>
            {data.map((workflow) => (
              renderWorkflowItem(workflow)
            ))}
          </Box>
        )}
      </DataLoading>
    </Box>
  )
}

export default WorkflowsSidebar