import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTeamsAsOptions_TODO } from 'app/store/Teams/Teams.selectors';
// Mui
import { Box, FormHelperText } from '@mui/material';
// Component
import Phone from 'app/components/Phone';
import { Input, Select } from 'app/components/Mui';
import BillingEmails from 'app/components/BillingEmails';
// Utilities
import { isRequired, isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

const GeneralTabContent:FC = () => {
  const { t } = useTranslation();
  // State
  const teamsOptions = useAppSelector(selectTeamsAsOptions_TODO);

  const { control, formState:{ errors } } = useFormContext();

  return (
    <Fragment>
      {/* Company */}
      <Controller
        control={control} name="company"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.company')}
            error={Boolean(errors.company)}
            helperText={errors.company?.message as string || ''}
            required
          />
        )}
      />
      {/* E-mail */}
      <Controller
        control={control} name="email"
        rules={{ required: isRequired, pattern: isEmailValid }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.email')} type="email"
            error={Boolean(errors.email)}
            helperText={errors.email?.message as string || ''}
            required
          />
        )}
      />
      {/* Phone */}
      <Controller
        control={control} name="phone"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Phone
            {...field}
            label={t('labels.cellPhone')}
            error={Boolean(errors.phone)}
            helperText={errors.phone?.message || ''}
            required={true}
          />
        )}
      />
      <Controller
        control={control} name="address.line"
        render={({ field }) => (
          <Input {...field} label={t('labels.address')} />
        )}
      />

      <Box sx={{ display: 'flex', gap: 4 }}>
        <Controller
          control={control} name="address.city"
          render={({ field }) => (
            <Input {...field} label={t('labels.city')} />
          )}
        />
        <Controller
          control={control} name="address.state"
          render={({ field }) => (
            <Input {...field} label={t('labels.state')} />
          )}
        />
        <Controller
          control={control} name="address.zipCode"
          render={({ field }) => (
            <Input {...field} label={t('labels.zipCode')} />
          )}
        />
      </Box>

      <Box sx={{ pt: 4, mb: 2 }}>
        <Controller
          control={control} name="onboardingTeamId"
          render={({ field }) => (
            <Select
              {...field}
              sx={{ mb: 1 }}
              label={t('pages.companyAccount.onboardingTeamLabel')}
              options={teamsOptions}
              margin="none"
            />
          )}
        />
        <FormHelperText>{t('pages.companyAccount.onboardingTeamHelperText')}</FormHelperText>
      </Box>
      <BillingEmails
        label={t('pages.companyAccount.billingEmailsLabel')}
        helperText={t('pages.companyAccount.billingEmailHelperText')}
      />
    </Fragment>
  )
}

export default GeneralTabContent;
