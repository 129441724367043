import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { StepTasks } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Mui
import { FormHelperText } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// Utilities
import { isMaxLength } from 'app/utilities/Validations';
// 
import { useWorkflowContext } from '../Workflow.context'

const CreateReportControls:FC = () => {
  const { t } = useTranslation();

  const { selectedStep, getStepFieldIndex } = useWorkflowContext();

  const { register, control, formState: { errors }, watch } = useFormContext();

  if ( !selectedStep || selectedStep.task !== StepTasks.CreateReport ) return null;

  const fieldIndex = getStepFieldIndex(selectedStep.id) as number;

  const watermarkTextError = (errors as any)?.steps?.[fieldIndex]?.input?.parameters?.watermark?.text;

  const watchWatermarkText = watch(`steps[${fieldIndex}].input.parameters.watermark.text`);

  return (
    <Fragment>
      <input {...register(`steps[${fieldIndex}].input.parameters.watermark.tiled`)} value="true" type="hidden" />

      <Controller
        control={control}
        name={`steps[${fieldIndex}].input.parameters.name`}
        render={({ field }) => (
          <Input {...field} label={t('labels.name')} size="small" />
        )}
      />

      <OutlineBlock sx={{ pt: 6, mt: 6 }} label="Watermark">
        <Controller
          control={control}
          name={`steps[${fieldIndex}].input.parameters.watermark.text`}
          rules={{ maxLength: isMaxLength(21) }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.text')}
              error={Boolean((watermarkTextError))}
              helperText={watermarkTextError?.message || ''}
              size="small"
              margin="none"
              InputProps={{
                endAdornment: (
                  <FormHelperText
                    sx={{ p: 0, whiteSpace: 'nowrap' }}
                  >{`${watchWatermarkText.length} / 21`}</FormHelperText>
                )
              }}
            />
          )}
        />
      </OutlineBlock>
    </Fragment>
  )
}

export default CreateReportControls;