import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IInsuranceCase from 'app/models/Case';
import { IValidateError } from 'app/store/DMSBatches/DMSBatches.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createBatch, validateBatch } from 'app/store/DMSBatches/DMSBatches.async';
import { createFileUploadToken, createPreSignedUrlForFile, uploadFileByPreSignedUrl } from 'app/store/DMSUploads/DMSUploads.async';
// Actions
import { DMSUploadsActions } from 'app/store/DMSUploads/DMSUploads.slice';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectPreSignedUrls } from 'app/store/DMSUploads/DMSUploads.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Button, LoadingButton } from 'app/components/Mui';
// 
import useUploadContext from './UploadProvider/useUploadContext';

type Props = {
  onClose: () => void;
};

let token:any = null;

const DialogActionButtons:FC<Props> = ({
  onClose
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const preSignedUrls = useAppSelector(selectPreSignedUrls);

  const {
    onSetUploadedFilesCount,
    comment,
    fileItems,
    handleBuildErrorMap,
    handleSubmit,
    formState:{ isLoading, onSetIsLoading }
  } = useUploadContext();

  const onSubmit = () => handleSubmit(async () => {
    const s3Errors:(boolean | null)[] = [];
    const uploadData:any = { caseId: insuranceCase.id, files: [] };

    if ( comment ) uploadData['comment'] = comment;

    onSetIsLoading(true);
    onSetUploadedFilesCount(0);

    try {
      // Used to preset error.references instead of using indexs
      // predefined id from fileItem
      const uploadedFileIdsByIndexes:string[] = []

      if ( !token ){
        const response = await dispatch(createFileUploadToken({ caseId: insuranceCase.id })).unwrap();
        token = response.token;
      }

      uploadData['token'] = token;

      for ( let i = 0; i < fileItems.length; i++ ){
        const { id, file, type, collectionId, comment } = fileItems[i];

        const fileData:any = { name: file.name, type };

        if ( comment ) fileData['annotations'] = [{ name: 'comment', value: comment }];

        const preSignedData:any = {
          token,
          file: {
            name: file.name,
            contentType: file.type
          },
          fileType: type
        };
        if ( collectionId ){
          fileData['collectionId'] = Number(collectionId);
          preSignedData['collectionId'] = Number(collectionId);
        }

        if ( !Object.keys(preSignedUrls).length || !preSignedUrls[id] ){
          const { url } = await dispatch(createPreSignedUrlForFile(preSignedData)).unwrap();
          const s3Error = await dispatch(uploadFileByPreSignedUrl({ url, file, index: i })).unwrap();
          if ( s3Error ){
            s3Errors[i] = true;
          }
          dispatch(DMSUploadsActions.addPreSignedUrl({ id, url }));
        } else {
          s3Errors[i] = null;
        }

        uploadData.files = [...uploadData.files, fileData];
        onSetUploadedFilesCount((prevState:number) => prevState + 1);
        uploadedFileIdsByIndexes.push(id);
      }

      if ( s3Errors.some((error:boolean | null) => error) ) return;
      const validateErrors = await dispatch(validateBatch(uploadData)).unwrap();
      handleBuildErrorMap(validateErrors, uploadedFileIdsByIndexes);
      if ( validateErrors.some((error:IValidateError[]) => error.length) ) return;
      await dispatch(createBatch(uploadData)).unwrap();
      onClose();
    } catch(error){
      console.error(error);
    } finally {
      onSetIsLoading(false);
    }
  });

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Button
        name="Cancel batch dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Upload batch dialog"
        loading={isLoading}
        onClick={onSubmit}
        color="primary"
        variant="contained"
      >{t('buttons.upload')}</LoadingButton>
    </Box>
  )
}

export default DialogActionButtons;
