import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.companyAccount') }),
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.companyAccount') }),
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.companyAccount') }),
  Active: i18n.t('notifications.companyAccounts.active'),
  Suspend: i18n.t('notifications.companyAccounts.suspend'),
  Disable: i18n.t('notifications.companyAccounts.disable'),
  UpdateQuickbooksID: i18n.t('notifications.companyAccounts.updateQuickbooksID'),
  UpdateSubscription: i18n.t('notifications.companyAccounts.updateSubscription')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.companyAccount') });
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.companyAccount') });
  Messages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.companyAccount') });
  Messages.Active = i18n.t('notifications.companyAccounts.active');
  Messages.Suspend = i18n.t('notifications.companyAccounts.suspend');
  Messages.Disable = i18n.t('notifications.companyAccounts.disable');
  Messages.UpdateQuickbooksID = i18n.t('notifications.companyAccounts.updateQuickbooksID');
  Messages.UpdateSubscription = i18n.t('notifications.companyAccounts.updateSubscription');
});