import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
// Models
import { IDocument } from 'app/store/DMSDocuments/DMSDocuments.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { patchDocument } from 'app/store/DMSDocuments/DMSDocuments.async';
// Actions
import { DMSBatchesActions } from 'app/store/DMSBatches/DMSBatches.slice';
// Selectors
import {
  selectEntities as selectCollectionEntities,
  selectCollectionsAsOptionsByFileType
} from 'app/store/DMSCollections/DMSCollections.selectors';
import { selectLoading } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { FormHelperText, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// Validations
import { isRequired } from 'app/utilities/Validations';
import { RootState } from 'app/store';

interface IFormData {
  collectionId: number | null;
}

type Props = {
  open: boolean;
  onClose: () => void;
  documentEntity: IDocument;
};

const DocumentMoveDialog:FC<Props> = ({
  // Props
  open, onClose, documentEntity
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const collectionEntities = useAppSelector(selectCollectionEntities);
  const collectionOptions:any = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType(state, {
    fileFormat: documentEntity?.fileFormat
  }));
  const loading:boolean = useAppSelector(selectLoading);

  const filteredCollectionOptions = useMemo(() => {
    if ( !collectionOptions ) return null;
    return collectionOptions.filter(({ value }:any) => value !== documentEntity.collectionId);
    // eslint-disable-next-line
  }, [collectionOptions])

  const { control, handleSubmit, formState:{ errors }, watch } = useForm<IFormData>({
    defaultValues: {
      collectionId: null
    }
  });

  const handleSave = handleSubmit(async (data:IFormData) => {
    try {
      const collectionId = Number(data.collectionId);
      await dispatch(patchDocument({ documentId: documentEntity.id, data:{ collectionId } })).unwrap();
      dispatch(DMSBatchesActions.setFilter({ field: 'collectionId', value: collectionId }));
      onClose();
    } catch(error){}
  });

  const watchCollectionId = watch('collectionId');
  const collectionEntity = watchCollectionId ? collectionEntities[watchCollectionId] : null;

  const actions = (
    <Fragment>
      <Button
        name="Cancel document move dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Submit document move dialog"
        onClick={handleSave}
        loading={loading}
        variant="contained"
        color="primary"
      >{t('buttons.move')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('components.filesSidebar.documentMoveTitle')}
      actions={actions}
    >
      <Controller
        control={control} name="collectionId"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={filteredCollectionOptions || []}
            TextFieldProps={{
              label: t('labels.documentCollections'), 
              name: field.name,
              required: true,
              error: Boolean(errors.collectionId),
              helperText: errors.collectionId?.message || ''
            }}
            renderOption={(props, option) => (
              <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                <Typography variant="subtitle1">{option.label}</Typography>
                {option.props.description ? (
                  <Typography variant="caption">{option.props.description}</Typography>
                ) : null}
              </li>
            )}
          />
        )}
      />
      {!documentEntity.ocred && collectionEntity && collectionEntity.applyOcr ? (
        <FormHelperText>{t('components.filesSidebar.documentMoveHint')}</FormHelperText>
      ) : null}
    </Dialog>
  )
}

export default DocumentMoveDialog;