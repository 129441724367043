import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import IManagementAccount from 'app/models/ManagementAccount';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectAccounts = (state:RootState) => state[Reducers.AccountsManagement].accounts;
export const selectAccount = (state:RootState) => state[Reducers.AccountsManagement].account;
export const selectAccountBilling = (state:RootState) => state[Reducers.AccountsManagement].accountBilling;
export const selectFilter = (state:RootState) => state[Reducers.AccountsManagement].filter;
export const selectLoading = (state:RootState) => state[Reducers.AccountsManagement].loading;

export const selectFilteredAccounts = createSelector(
  [
    selectAccounts,
    selectFilter
  ],
  (accounts:IManagementAccount[] | null, filter) => {
    if ( !accounts ) return null;
    const { search, statuses } = filter;
    const searchToLower = search.toLowerCase();
    return accounts
      .filter((account:IManagementAccount) => {
        const hasSearch = searchToLower ? account.company.toLowerCase().includes(searchToLower) : true;
        const hasStatuses = statuses.length ? statuses.includes(account.status) : true;
        return hasSearch && hasStatuses;
      })
      .sort((a:IManagementAccount, b:IManagementAccount) => sortByName(a, b, 'company'))
  }
);

export const selectAccountsAsOptions = createSelector(
  [selectAccounts],
  (accounts: IManagementAccount[] | null) => {
    if (!accounts) return null;
    return accounts.map(({ id, company, address = {} }) => {
      const { line, city, state, zipCode } = address;
      return {
        value: id,
        label: company,
        props: {
          address: [line, city, state, zipCode].filter(Boolean).join(', '),
        },
      };
    }).sort((a, b) => sortByName(a, b, 'label'));
  }
);

export const selectAccountById = createSelector(
  [
    selectAccounts,
    (_, props:{ accountId:number | undefined }) => props
  ],
  (accounts:IManagementAccount[] | null, { accountId }) => {
    if ( !accounts || !accountId ) return undefined;
    return accounts.find((account:IManagementAccount) => account.id === accountId);
  }
)
