import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.report') }),
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.report') }),
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.report') }),
  Share: i18n.t('notifications.caseReports.share')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.report') });
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.report') });
  Messages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.report') });
  Messages.Share = i18n.t('notifications.caseReports.share');
});