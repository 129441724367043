import { FC, Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Asycn
import { updateCurrentUser } from 'app/store/Users/Users.async';
// Mui
import {
  Theme,
  Box,
  Typography, FormHelperText
} from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';

interface IFormData {
  code: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
  multiFactoryData: any;
}

const VerificationFormDialog:FC<Props> = ({
  // Props
  open, onClose, multiFactoryData
}) => {
  const { email, phone, profileData } = multiFactoryData;

  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  
  const [ loading, setLoading ] = useState(false);

  const { control, handleSubmit, formState:{ errors } } = useForm<IFormData>({
    defaultValues: {
      code: ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    setLoading(true);
    try {
      await dispatch(updateCurrentUser({
        data: profileData,
        args: {
          headers: {
            'MFA-Secret': data.code
          }
        }
      })).unwrap();
      onClose();
    } catch(error){
      console.log(error);
    } finally {
      setLoading(false);
    }
  });

  const actions = (
    <Fragment>
      <Button
        name="Cancel verification code dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Submit verification code dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.verify')}</LoadingButton>
    </Fragment>
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Verify your account"
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      <Typography sx={{ mb: 4 }} display="block">
        {t('pages.verification.description', { source: email || phone })}
      </Typography>
      {/* Code */}
      <Controller
        control={control} name="code"
        rules={{ validate: (value:string) => value.length === 6 }}
        render={({ field }) => (
          <Box>
            <MuiOtpInput
              {...field}
              sx={{
                gap: 2,
                mb: 2,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: (theme:Theme) => errors.code ? theme.palette.error.main : '#cacaca'
                }
              }}
              length={6}
              margin="normal"
            />
            {errors.code ? (
              <FormHelperText error>{t('validations.codeNotValid')}</FormHelperText>
            ) : null}
          </Box>
        )}
      />
    </Dialog>
  )
}

export default VerificationFormDialog