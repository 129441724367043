export enum AuditOperations {
  CaseLabelAdding = 'caseLabelAdding',
  CaseLabelRemoving = 'caseLabelRemoving',
  CaseNotesUpdate = 'caseNotesUpdate',
  CaseShared = 'caseShared',
  ReportShared = 'reportShared',
  ReportDeleted = 'reportDeleted',
  ExportPagesToPDF = 'exportPagesToPDF',
  ExportWorkspacePagesToPDF = 'exportWorkspacePagesToPDF',
  DocumentLabelAdding = 'documentLabelAdding',
  DocumentLabelRemoving = 'documentLabelRemoving',
  DocumentExport = 'documentExport',
};

export type AuditOperation = 
  | CaseLabelAddingOperation
  | CaseLabelRemovingOperation
  | DocumentLabelAddingOperation
  | DocumentLabelRemovingOperation
  | DocumentExport
  | CaseNotesUpdateOperation
  | CaseSharedOperation
  | ReportSharedOperation
  | ReportDeletedOperation
  | ExportPagesToPDFOperation
  | ExportWorkspacePagesToPDFOperation
;

type CaseLabelAddingOperation = {
  operation: AuditOperations.CaseLabelAdding;
  data: {
    caseId: number;
    userName: string;
    addedLabels: string[];
    removedLabels: string[];
  };
};

type CaseLabelRemovingOperation = {
  operation: AuditOperations.CaseLabelRemoving;
  data: {
    caseId: number;
    userName: string;
    addedLabels: string[];
    removedLabels: string[];
  };
};

type DocumentLabelAddingOperation = {
  operation: AuditOperations.DocumentLabelAdding;
  data: {
    caseId: number;
    userName: string;
    addedLabels: string[];
    removedLabels: string[];
    fileName: string;
  };
};

type DocumentLabelRemovingOperation = {
  operation: AuditOperations.DocumentLabelRemoving;
  data: {
    caseId: number;
    userName: string;
    addedLabels: string[];
    removedLabels: string[];
    fileName: string;
  };
};

type DocumentExport = {
  operation: AuditOperations.DocumentExport;
  data: {
    caseId: number;
    userName: string;
    fileName: string;
  };
};

type CaseNotesUpdateOperation = {
  operation: AuditOperations.CaseNotesUpdate;
  data: {
    caseId: number;
    userName: string;
  };
};

type CaseSharedOperation = {
  operation: AuditOperations.CaseShared;
  data: {
    caseId: number;
    userName: string;
    userEmail: string;
    shared: boolean;
  };
};

type ReportSharedOperation = {
  operation: AuditOperations.ReportShared;
  data: {
    caseId: number;
    userName: string;
    reportName: string;
    clientUserEmails: string[];
  };
};

type ReportDeletedOperation = {
  operation: AuditOperations.ReportDeleted;
  data: {
    caseId: number;
    userName: string;
    reportName: string;
    clientUserEmails?: string[];
  };
};

type ExportPagesToPDFOperation = {
  operation: AuditOperations.ExportPagesToPDF;
  data: {
    caseId: number;
    userName: string;
  };
};

type ExportWorkspacePagesToPDFOperation = {
  operation: AuditOperations.ExportWorkspacePagesToPDF;
  data: {
    caseId: number;
    userName: string;
  };
};

