import { FC, ReactNode } from 'react';
// Types
import DialogReasons from 'app/types/DialogReasons';
// Mui
import {
  IconButton, Typography,
  DialogProps,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  PaperPropsVariantOverrides,
  DialogContentProps,
  Tooltip
} from '@mui/material';
// Icons
import { Close as CloseIcon } from '@mui/icons-material';
// Utilities
import { generateAttrForTesting } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = Omit<DialogProps, 'title' | 'PaperProps' | 'ContentProps'> & {
  PaperProps?: PaperPropsVariantOverrides;

  ContentProps?: DialogContentProps;

  open: boolean;
  onClose: () => void;
  title?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
};

const Dialog:FC<Props> = ({
  // Props
  title, actions, children, open, maxWidth = 'xs', onClose, ContentProps = { dividers: true }, ...props
}) => {
  const { t } = useTranslation();

  const iconButtonAttr = generateAttrForTesting('action', 'button', 'Close dialog');

  const handleClose = (_:any, reason:DialogReasons) => {
    if ( reason === DialogReasons.BackdropClick ) return;
    onClose();
  }

  return (
    <MuiDialog
      {...props}
      open={open}
      fullWidth
      disableEscapeKeyDown
      onClose={handleClose}
      maxWidth={maxWidth}
    >
      {title ? (
        <MuiDialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{ flexGrow: 1, textTransform: 'capitalize' }}
            component="span"
            variant="h6"
          >{title}</Typography>
          {onClose ? (
            <Tooltip title={t('buttons.closeDialog')} aria-label={undefined}>
              <IconButton
                {...iconButtonAttr}
                aria-label="Close dialog button"
                onClick={onClose}
                edge="end"
              ><CloseIcon /></IconButton>
            </Tooltip>
          ) : null}
        </MuiDialogTitle>
      ) : null}
      <MuiDialogContent {...ContentProps}>{children}</MuiDialogContent>
      {actions ? <MuiDialogActions>{actions}</MuiDialogActions> : null}
    </MuiDialog>
  )
}

export default Dialog;
