import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectGridResizerWidth } from 'app/store/AppUI/AppUI.selectors';
import { selectAllPages, selectError, selectPage } from 'app/store/page/page.selectors';
// Mui
import { Box, IconButton, Tooltip } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
// Components
import { MessageError } from 'app/components/Utilities';
import PageEpisodes from 'app/components/PageEpisodes';
// 
import CardPageDeletedPlaceholder from '@root/dialogs/CardPageDialog/CardPageDeletedPlaceholder';
import CardPageTabs from '@root/dialogs/CardPageDialog/CardPageTabs';
import CardPageStaplePlaceholder from '@root/dialogs/CardPageDialog/CardPageStapledPlaceholder';
import CardPageDelete from '@root/dialogs/CardPageDialog/CardPageDelete';
import CardPageTag from '@root/dialogs/CardPageDialog/CardPageTag';
import CardPageColors from '@root/dialogs/CardPageDialog/CardPageColors';
import CardPageToggler from '@root/dialogs/CardPageDialog/CardPageToggler';
import DocumentPageInfo from '@root/dialogs/CardPageDialog/DocumentPageInfo';

import GridResizer from 'app/components/GridResizer';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
import useToggle from 'app/hooks/useToggle';
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
import { selectEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

let scrollPercent = 0;
let scrollTimer;

const DocumentPageDetailsPage = () => {
  const { t } = useTranslation();

  const { caseId, documentId, pageNum } = useParams();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const trialMode = useSelector(selectTrialMode)
  const gridResizerWidth = useSelector(selectGridResizerWidth);
  const pages = useSelector(selectAllPages);
  const page = useSelector(selectPage);
  const episodes = useSelector(selectEpisodesAll);
  const error = useSelector(selectError);

  const scrollContainerRef = useRef();

  const { open, toggle } = useToggle(true);

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseSinglePageOpen, { caseId: Number(caseId) });

    dispatch(PageActions.fetchPage(documentId, pageNum));

    dispatch(AppUIActions.toggleFilesSidebar());

    return () => {
      dispatch(AppUIActions.toggleFilesSidebar());
      dispatch(PageActions.setInitialField('error'));
    }
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    toggle();

    if ( scrollContainerRef.current ){
      const scrollContainer = scrollContainerRef.current;
      const scrollTop = scrollContainer.scrollTop;
      const scrollHeight = scrollContainer.scrollHeight;

      if ( !scrollPercent ) scrollPercent = (scrollTop * 100)/scrollHeight;
    }

    setTimeout(() => {
      if ( !scrollContainerRef.current ) return;

      const scrollContainer = scrollContainerRef.current;
      const scrollHeight = scrollContainer.scrollHeight;

      scrollContainer.scrollTop = scrollHeight * (scrollPercent/100);
    }, 0);
  }

  const handleScroll = (event) => {
    clearTimeout(scrollTimer);

    scrollTimer = setTimeout(() => {
      const element = event.target;
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;

      scrollPercent = (scrollTop * 100)/scrollHeight;
    }, 250);
  }

  if ( [401,404].includes(error?.status) ) return (
    <MessageError
      status={error.status}
      title={t('pages.files.pageNotFound')}
      text={t('pages.files.pageNotFoundText')}
      backTo={`/admin/cases/${caseId}/documents/${documentId}`}
      backToLabel={t('pages.caseDetails.goToDocumentsButton')}
    />
  );

  if ( !episodes || !page || !pages ) return null;
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 4
        }}
      >
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <DocumentPageInfo documentId={documentId} pageNum={pageNum} />
        </Box>
        {!trialMode ? (
          <Tooltip title={t('pages.files.toggleSidebar')}>
            <IconButton
              onClick={handleClick}
            ><MenuIcon /></IconButton>
          </Tooltip>
        ) : null}
      </Box>
      <div style={{
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        paddingLeft: '16px',
        paddingRight: '16px'
      }}>
        <div
          ref={scrollContainerRef}
          onScroll={handleScroll}
          style={{
            width: open ? `${gridResizerWidth}%` : '100%',
            display: 'flex',
            flexDirection:  'column'
          }}
        >
          {page.deleted ? (
            <CardPageDeletedPlaceholder />
          ) : (
            <CardPageTabs />
          )}
        </div>
        {!trialMode ? (
          <React.Fragment>
            {open ? <GridResizer /> : null}
            <div style={{
              flex: 1,
              position: 'relative',
              height: '100%',
              overflowY: 'auto',
              paddingRight: '8px',
              paddingLeft: '8px',
              display: open ? 'block' : 'none'
            }}>
              {!page.deleted ? (
                <React.Fragment>
                  {!page.staple || page.staple.order === 1 ? (
                    <React.Fragment>
                      <CardPageTag page={page} isDisabled={trialMode} />
                      <CardPageColors page={page} />
                      <CardPageToggler page={page} />
                      {page ? <PageEpisodes /> : null}
                      <CardPageDelete page={page} />
                    </React.Fragment>
                  ) : (
                    <CardPageStaplePlaceholder page={page} isFilesPage={true} />
                  )}
                </React.Fragment>
              ) : null}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default DocumentPageDetailsPage;
