import { FC, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Types
import UserRoles from 'app/types/UserRoles';
import AdditionalToolsNames from 'app/types/AdditionalToolsNames';
// Models
import { RootState } from 'app/store';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { setWorkspaceType, toggleWorkspaceAdditionalTools } from 'app/store/ui/uiSlice';
// Selectors
import { selectShowWorkspaceAdditionalTools, selectAdditionalToolsHidden } from 'app/store/ui/uiSelectors';
import { selectPagesInWorkspace } from 'app/store/page/page.selectors';
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectCaseAiChatEnabled } from 'app/store/Cases/Cases.selectors';
// Mui
import { Paper, Box, Toolbar, IconButton, Typography, Tooltip, Button } from '@mui/material';
// Icons
import {
  Settings as SettingsIcon,
  Close as CloseIcon
} from '@mui/icons-material';
// Components
import ColumnSwitcher from 'app/components/ColumnSwitcher';
// 
import ToolsSettings from './ToolsSettings';
import Tags from './Tags';
import Colors from './Colors';
import GoToPage from './GoToPage';
import GoToLastOpenedPage from './GoToLastOpenedPage';
import GoToPostEvent from './GoToPostEvent';
import EpisodeTypes from './EpisodeTypes';
import EpisodeLabels from './EpisodeLabels';
import EpisodeAuthors from './EpisodeAuthors';
import AdditionalRecordsOnly from './AdditionalRecordsOnly';
// Dialogs
import ExportWorkspacePagesToPDFDialog from '@root/dialogs/ExportWorkspacePagesToPDF.dialog';
import ConversationFormDialog from 'app/pages/Staff/CaseDetailsPage/AIChatPage/ConversationFormDialog';
// i18next
import { useTranslation } from 'react-i18next';
import { selectAIChatEnabled } from 'app/store/Accounts/Accounts.selectors';

type Props = {
  listRef: any;
}

const AdditionalTools:FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const trialMode = useSelector(selectTrialMode);
  const aiChatEnabled = useAppSelector(selectAIChatEnabled);
  const show:boolean = useAppSelector(selectShowWorkspaceAdditionalTools);
  const tools:AdditionalToolsNames[] = useAppSelector((state:RootState) => selectAdditionalToolsHidden(state, {
    toolsType: 'workspace'
  }));
  // ToDO
  const workspaceType = useSelector(selectWorkspaceType);
  const myUser:any = useSelector(selectMyUser);
  const pages:any = useSelector(selectPagesInWorkspace);
  const caseAiChatEnabled = useAppSelector(selectCaseAiChatEnabled);

  const [ open, setOpen ] = useState(false);

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const handleClose = () => {
    dispatch(toggleWorkspaceAdditionalTools());
  }

  const toggleOpen = () => setOpen(!open);
  const toggleDialog = () => setDialogOpen(!dialogOpen);

  const { open:openAIConversationDialog, toggle:toggleAIConversationDialog } = useToggle();

  const handleChangeView = () => {
    dispatch(setWorkspaceType(workspaceType === 'pages' ? 'episodes' : 'pages'));
  }

  const isAiChatButtonDisabled = !caseAiChatEnabled;

  if ( !show ) return null;
  return (
    <Fragment>
      <Box sx={{ display: 'flex', position: 'relative' }}>
        <Paper
          sx={{
            width: 360,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            borderTop: 'none',
            borderBottom: 'none'
          }}
          variant="outlined"
        >
          <Toolbar sx={{ gap: 4 }}>
            <Tooltip title={t('labels.toolsSettings')}>
              <IconButton
                onClick={toggleOpen}
              ><SettingsIcon /></IconButton>
            </Tooltip>
            <Typography sx={{ flexGrow: 1 }}>{t('labels.additionalTools')}</Typography>
            <Tooltip title={t('buttons.close')}>
              <IconButton
                onClick={handleClose}
              ><CloseIcon /></IconButton>
            </Tooltip>
          </Toolbar>
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 4,
            overflowY: 'auto'
          }}>
            {tools.includes(AdditionalToolsNames.GoToPostEvent) ? (
              <GoToPostEvent listRef={listRef} />
            ) : null}
            {tools.includes(AdditionalToolsNames.GoToLastOpenedPage) ? (
              <GoToLastOpenedPage listRef={listRef} />
            ) : null}
            {tools.includes(AdditionalToolsNames.GoToPage) ? (
              <GoToPage listRef={listRef} />
            ) : null}
            {tools.includes(AdditionalToolsNames.EpisodeTypes) ? (
              <EpisodeTypes />
            ) : null}
            {tools.includes(AdditionalToolsNames.EpisodeLabels) ? (
              <EpisodeLabels />
            ) : null}
            {tools.includes(AdditionalToolsNames.EpisodeAuthors) ? (
              <EpisodeAuthors />
            ) : null}
            {tools.includes(AdditionalToolsNames.NumberOfColumns) ? (
              <Box>
                <ColumnSwitcher />
              </Box>
            ) : null}
            {tools.includes(AdditionalToolsNames.Tags) ? (
              <Box sx={{ mb: 2 }}><Tags /></Box>
            ) : null}
            {tools.includes(AdditionalToolsNames.Colors) ? (
              <Box sx={{ mb: 2 }}><Colors /></Box>
            ) : null}
            {tools.includes(AdditionalToolsNames.AdditionalRecordsOnly) ? (
              <AdditionalRecordsOnly />
            ) : null}

            {!trialMode && (myUser?.role === UserRoles.Admin || myUser?.role === UserRoles.Staff) ? (
              pages && pages.length ? (
                <Button
                  onClick={toggleDialog}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >{t('pages.workspace.exportPdfButton')}</Button>
              ) : null
            ) : null}
            {!trialMode ? (
              <Fragment>
                <Button
                  onClick={handleChangeView}
                  fullWidth
                  variant="outlined"
                  color="primary"
                >{workspaceType === 'pages' ? t('pages.workspace.episodesView') : t('pages.workspace.pagesView')}</Button>
                {aiChatEnabled ? (
                  <Button
                    disabled={isAiChatButtonDisabled}
                    onClick={toggleAIConversationDialog}
                    color="primary"
                    variant="outlined"
                    fullWidth
                  >{t('buttons.sendToAiChat')}</Button>
                ) : null}
              </Fragment>
            ) : null}
          </Box>
        </Paper>
        {open ? (
          <ToolsSettings onClose={toggleOpen} />
        ) : null}
      </Box>
      {dialogOpen ? (
        <ExportWorkspacePagesToPDFDialog
          open={dialogOpen}
          onClose={toggleDialog}
        />
      ) : null}
      {openAIConversationDialog ? (
        <ConversationFormDialog
          open={openAIConversationDialog}
          onClose={toggleAIConversationDialog}
          pages={pages}
          source="workspace"
        />
      ) : null}
    </Fragment>
  )
}

export default AdditionalTools;
