import React, { useMemo } from 'react';
// State
import { useDispatch, useSelector } from 'react-redux';
// Selectors
import {
  selectPagesInWorkspace, selectPagesInWorkspaceByPreEvent,
  selectEpisodesInWorkspace, selectEpisodesInWorkspaceByPreEvent
} from 'app/store/page/page.selectors';
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import WorkspaceList from './WorkspaceList';
import WorkspaceStapleFab from './WorkspaceStapleFab';
import WorkspaceScroll from './WorkspaceScroll';
// 
import { WorkspaceToolbar, WorkspaceFilter, AdditionalTools } from 'app/layouts/WorkspaceTools';
import { useParams } from 'react-router-dom';
import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';

import PageActions from 'app/store/page/page.actions';

import FilesSidebar from 'app/layouts/DocumentsSidebar';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {};

const WorkspacePage:React.FC<Props> = () => {
  const { t } = useTranslation();

  const listRef = React.useRef<any>();

  // Dispatch
  const dispatch = useDispatch();

  const workspaceType = useSelector(selectWorkspaceType);
  const pagesInWorkspace = useSelector(selectPagesInWorkspace);
  const episodesInWorkspace = useSelector(selectEpisodesInWorkspace);

  const pagesPreEvent = useSelector((state:any) => selectPagesInWorkspaceByPreEvent(state, { preEvent: true }));
  const pagesPostEvent = useSelector((state:any) => selectPagesInWorkspaceByPreEvent(state, { preEvent: false }));

  const episodesPreEvent = useSelector((state:any) => selectEpisodesInWorkspaceByPreEvent(state, { preEvent: true }));
  const episodesPostEvent = useSelector((state:any) => selectEpisodesInWorkspaceByPreEvent(state, { preEvent: false }));

  const { caseId } = useParams<{ caseId:string }>();

  React.useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseWorkspaceView, { id: Number(caseId) });

    return () => {
      dispatch(PageActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, []);

  const pages = useMemo(() => {
    const result:any = [];
    if ( pagesPreEvent && pagesPreEvent.length ){
      result.push({ type: 'placeholder', label: 'Pre-event' });
      for ( let i = 0; i < pagesPreEvent.length; i++ ){
        result.push({...pagesPreEvent[i], type: 'page', index: i });
      }
    }
    if ( pagesPostEvent && pagesPostEvent.length ){
      const index = pagesPreEvent ? pagesPreEvent.length : 0;
      result.push({ type: 'placeholder', label: 'Post-event' });
      for ( let i = 0; i < pagesPostEvent.length; i++ ){
        result.push({...pagesPostEvent[i], type: 'page', index: index + i });
      }
    }
    return result;
    // eslint-disable-next-line
  }, [pagesPreEvent, pagesPostEvent]);

  const episodes = useMemo(() => {
    const result:any = [];
    if ( episodesPreEvent && episodesPreEvent.length ){
      result.push({ type: 'placeholder', label: 'Pre-event' });
      for ( let i = 0; i < episodesPreEvent.length; i++ ){
        result.push({...episodesPreEvent[i], type: 'page', index: i });
      }
    }
    if ( episodesPostEvent && episodesPostEvent.length ){
      const index = pagesPreEvent ? pagesPreEvent.length : 0;
      result.push({ type: 'placeholder', label: 'Post-event' });
      for ( let i = 0; i < episodesPostEvent.length; i++ ){
        result.push({...episodesPostEvent[i], type: 'page', index: index + i });
      }
    }
    return result;
    // eslint-disable-next-line
  }, [episodesPreEvent, episodesPostEvent]);

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      overflow: 'hidden'
    }}>
      <FilesSidebar />
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <WorkspaceToolbar listRef={listRef} />
          <WorkspaceFilter />
          {workspaceType === 'pages' ? (
            !pagesInWorkspace ? (
              <Loader />
            ) : !pagesInWorkspace.length ? (
              <Message text={t('pages.workspace.noPages')} />
            ) : (
              <WorkspaceList pages={pages} listRef={listRef} />
            )
          ) : (
            !episodesInWorkspace ? (
              <Loader />
            ) : !episodesInWorkspace.length ? (
              <Message text={t('pages.workspace.noEpisodes')} />
            ) : (
              <WorkspaceList pages={episodes} listRef={listRef} />
            )
          )}
        </Box>
        <AdditionalTools listRef={listRef} />
      </Box>
      <WorkspaceScroll listRef={listRef} />
      {/* Fab */}
      <WorkspaceStapleFab />
    </Box>
  )
}

export default WorkspacePage;
