import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Types
import Features from 'app/types/Features';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createAccount } from 'app/store/Clients/Clients.async';
// Selectors
import { selectLoading } from 'app/store/Clients/Clients.selectors';
// Mui
import { Box, FormGroup } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import Phone from 'app/components/Phone';
import { Button, Input, Switch, LoadingButton } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// Utilities
import { isRequired, isEmailValid } from 'app/utilities/Validations';
import { selectPatientPortalEnabled } from 'app/store/Accounts/Accounts.selectors';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  company: string;
  email: string;
  phone: string;
  address?: {
    city?: string;
    state?: string;
    zipCode?: string;
    line?: string;
  };
  owner: {
    name: string;
    email: string;
    phone: string;
  };
  features?: (Features)[];
}

type Props = {
  open: boolean;
  onClose: () => void;
}

const ClientAccountCreateDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const patientPortalEnabled = useAppSelector(selectPatientPortalEnabled);
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState:{ errors } } = useForm<IFormData>({
    defaultValues: {
      company: '',
      email: '',
      phone: '',
      address: {
        city: '',
        state: '',
        zipCode: '',
        line: '',
      },
      owner: {
        name: '',
        email: '',
        phone: ''
      },
      features: []
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const { features, address, ...otherData } = data;
    const nextData:IFormData = otherData;
    if ( patientPortalEnabled && features?.length ) nextData['features'] = features;
    if ( address && Object.values(address).some((v:string) => v) ){
      nextData['address'] = {} as IFormData['address'];
      Object.keys(address).forEach((key:string) => {
        const value = address[key as keyof IFormData['address']];
        if ( value ) (nextData['address'] as any)[key] = value;
      });
    }
    asyncCreateClientAccount(nextData);
  });

  const asyncCreateClientAccount = async (data:IFormData) => {
    try {
      await dispatch(createAccount(data)).unwrap();
      onClose();
    } catch(e) {}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel account dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Create account dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.clientAccounts.createFormTitle')}
      actions={actions}
    >
      <form noValidate>
        <OutlineBlock
          sx={{ mt: 4 }}
          label={t('labels.company')}
          helperText={t('form.helperText.usedForCommunicationPurpose')}
        >
          <Fragment>
            <Controller
              control={control} name="company"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.name')}
                  error={Boolean(errors.company)}
                  helperText={errors.company?.message || ''}
                  required
                />
              )}
            />
            {/* E-mail */}
            <Controller
              control={control} name="email"
              rules={{ required: isRequired, pattern: isEmailValid }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.contactEmail')} type="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message || ''}
                  required
                />
              )}
            />
            {/* Phone */}
            <Controller
              control={control} name="phone"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Phone
                  {...field}
                  label={t('labels.contactPhone')}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone?.message || ''}
                  required={true}
                />
              )}
            />
            <Controller
              control={control} name="address.line"
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.address')} id="address.line"
                />
              )}
            />
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Controller
                control={control} name="address.city"
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('labels.city')} id="address.city"
                  />
                )}
              />
              <Controller
                control={control} name="address.state"
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('labels.state')} id="address.state"
                  />
                )}
              />
              <Controller
                control={control} name="address.zipCode"
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('labels.zipCode')} id="address.zipCode"
                  />
                )}
              />
            </Box>
          </Fragment>
        </OutlineBlock>

        {/* Owner */}
        <OutlineBlock
          sx={{ mt: 6 }}
          label={t('labels.owner')}
          helperText={t('form.helperText.userWillBeCreatedUsingThisData')}
        >
          <Fragment>
            <Controller
              control={control} name="owner.name"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.name')}
                  error={Boolean(errors.owner?.name)}
                  helperText={errors.owner?.name?.message || ''}
                  required
                />
              )}
            />
            {/* E-mail */}
            <Controller
              control={control} name="owner.email"
              rules={{ required: isRequired, pattern: isEmailValid }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.email')} type="email"
                  error={Boolean(errors.owner?.email)}
                  helperText={errors.owner?.email?.message || ''}
                  required
                />
              )}
            />
            {/* Phone */}
            <Controller
              control={control} name="owner.phone"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Phone
                  {...field}
                  label={t('labels.cellPhone')}
                  error={Boolean(errors.owner?.phone)}
                  helperText={errors.owner?.phone?.message || ''}
                  required={true}
                />
              )}
            />
          </Fragment>
        </OutlineBlock>

        {patientPortalEnabled ? (
          <OutlineBlock sx={{ mt: 6 }} label={t('labels.features')}>
            <Controller
              control={control} name="features"
              render={({ field }) => {
                const handleChange = (feature:Features) => () => {
                  const hasFeature = (field.value as (Features)[]).includes(feature);
                  const nextFeatures = hasFeature
                    ? (field.value as (Features)[]).filter((v) => v !== feature)
                    : [...(field.value || []), feature]
                  ;
                  field.onChange(nextFeatures);
                }
                return (
                  <FormGroup>
                    <Switch
                      name="Patient portal"
                      label={t('labels.patientPortal')}
                      value={(field.value || []).includes(Features.PatientPortal)}
                      onChange={handleChange(Features.PatientPortal)}
                    />
                  </FormGroup>
                )
              }}
            />
          </OutlineBlock>
        ) : null}

      </form>
    </Dialog>
  )
}

export default ClientAccountCreateDialog;
