import { FC, Fragment } from 'react';
// Models
import IBillingPackage from 'app/models/BillingPackage';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPackages } from 'app/store/BillingPackages/BillingPackages.selectors';
// Mui
import { Loader, Message } from 'app/components/Utilities';
// 
import PackagesListItem from './PackagesListItem';
// i18next
import { useTranslation } from 'react-i18next';

const PackagesList:FC = () => {
  const { t } = useTranslation();
  // State
  const packages:IBillingPackage[] | null = useAppSelector(selectPackages)

  if ( !packages ) return <Loader />;
  if ( !packages.length ) return <Message text={t('pages.subscriptionPackages.noData')} />;
  return (
    <Fragment>
      {packages.map((pkg:IBillingPackage) => (
        <PackagesListItem
          key={`package-item-${pkg.id}`}
          pkg={pkg}
        />
      ))}
    </Fragment>
  )
}

export default PackagesList;
