import { FC } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
// Selectors
import { selectFilesSidebarOpen } from 'app/store/AppUI/AppUI.selectors';
// Components
import SidebarToggleButton from 'app/components/SidebarToggleButton';

const SidebarToggle:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const open = useAppSelector(selectFilesSidebarOpen);

  const handleToggleFilesSidebar = () => {
    dispatch(AppUIActions.toggleFilesSidebar());
  }

  return (
    <SidebarToggleButton
      name="Files sidebar toggle button"
      open={open}
      onClick={handleToggleFilesSidebar}
    />
  )
}

export default SidebarToggle;
