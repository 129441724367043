import { FC, Fragment } from 'react';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import ClientAccountCreateDialog from './ClientAccountCreateDialog';
// i18next
import { useTranslation } from 'react-i18next';

const AccountsFab:FC = () => {
  const { t } = useTranslation();
  
  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Fab
        name="Create client account"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        onClick={toggle}
        color="primary"
        variant="extended"
      >{t('pages.clientAccounts.createButton')}</Fab>
      {/* Create dialog */}
      {open ? (
        <ClientAccountCreateDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default AccountsFab;
