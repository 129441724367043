import { FC, Fragment, useEffect } from 'react';
import { useHistory, useLocation,  } from 'react-router-dom';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Utilities
import { getSearchParam } from 'app/utilities/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
// Dialogs
import CaseCreateFormDialog from './CaseCreateFormDialog';
import CaseShareFormDialog from './CaseShareFormDialog';

const CasesFab:FC = () => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const history = useHistory();

  const { open, toggle } = useToggle();
  const { open:openShareDialog, toggle:toggleShareDialog } = useToggle();

  const action = getSearchParam(search, 'action');
  const token = getSearchParam(search, 'token');

  useEffect(() => {
    if ( action === 'share' && token ) toggleShareDialog();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    if ( action === 'share' && token ) history.push({ search: '' });
    toggleShareDialog();
  }

  return (
    <Fragment>
      <Fab
        name="Create case"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        onClick={toggle}
        variant="extended"
        color="primary"
      >{t('pages.cases.createButton')}</Fab>
      {/* Insurance case form dialog */}
      {open ? (
        <CaseCreateFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
      {openShareDialog ? (
        <CaseShareFormDialog
          open={openShareDialog}
          onClose={handleClose}
          token={token as string}
        />
      ) : null}
    </Fragment>
  )
}

export default CasesFab;
