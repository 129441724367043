import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
//
import AIPromptFormDialog from 'app/dialogs/AIPromptFormDialog';

const AIPromptHubFab:FC = () => {
  const { t } = useTranslation();

  const { open, toggle } = useToggle();
  return (
    <Fragment>
      <Fab
        name="Create prompt"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        onClick={toggle}
        color="primary"
        variant="extended"
      >{t('pages.aiPromptHub.createButton')}</Fab>
      {/* Dialog */}
      {open ? (
        <AIPromptFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default AIPromptHubFab;
