import { FC, useState } from 'react';
// Models
import IPage, { IPresenterPage } from 'app/models/Page';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectPage } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// Mui
import { Box, Paper, Tabs, Tab } from '@mui/material';
// Components
import { Loader } from 'app/components/Utilities';
// 
import TabContentPdf from './TabContentPdf';
import TabContentImage from './TabContentImage';
// import TabContentText from './TabContentText';
// i18next
import { useTranslation } from 'react-i18next';

const CardPageTabs:FC = () => {
  const { t } = useTranslation();
  // State
  const page:IPage | IPresenterPage | null = useAppSelector(selectPage);

  const [ activeTab, setActiveTab ] = useState<'pdf' | 'image' | 'text'>('image');

  const handleChange = (_:any, nextTab:'pdf' | 'image' | 'text') => {
    setActiveTab(nextTab);
  }

  if ( !page ) return <Loader />;
  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Paper variant="outlined" sx={{ mb: 4 }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          centered
        >
          <Tab value="pdf" label={t('labels.pdf')} />
          <Tab value="image" label={t('labels.image')} />
          {/* <Tab value="text" label={t('labels.text')} /> */}
        </Tabs>
      </Paper>
      <Box sx={{
        flexGrow: 1,
        overflowY: 'auto'
      }}>
        {activeTab === 'pdf' ? <TabContentPdf page={page} /> : null}
        {activeTab === 'image' ? <TabContentImage page={page} /> : null}
        {/* {activeTab === 'text' ? <TabContentText page={page} /> : null} */}
      </Box>
    </Box>
  )
}

export default CardPageTabs;
