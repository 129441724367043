import { FC, Fragment } from 'react';
// Models
import IExportTemplate from 'app/models/ReportTemplate';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteExportTemplate, downloadExportTemplateFile } from 'app/store/ExportTemplates/ExportTemplates.async';
// Selectors
import { selectLoading } from 'app/store/ExportTemplates/ExportTemplates.selectors';
// MaterialUI
// Core
import { 
  ListItem, ListItemText, ListItemSecondaryAction
} from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from '@mui/material';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
// 
import ExportTemplateFormDialog from './ExportTemplateFormDialog';
import ExportTemplateUploadDialog from './ExportTemplateUploadDialog';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  exportTemplate: IExportTemplate;
}

const ExportTemplatesListItem:FC<Props> = ({
  // Props
  exportTemplate
}) => {
  const { t } = useTranslation();
  // Dispathc
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openFormDialog, toggle:toggleFormDialog } = useToggle();
  const { open:openUploadDialog, toggle:toggleUploadDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleDownloadExportTemplate = () => {
    dispatch(downloadExportTemplateFile(exportTemplate.id));
  }

  const handleConfirm = async () => {
    try {
      await dispatch(deleteExportTemplate(exportTemplate.id)).unwrap();
      // handleClose();
    } catch(error){}
  }

  return (
    <Fragment>
      <ListItem>
        <ListItemText primary={exportTemplate.name} />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="Export template item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              name={`Download export template ${exportTemplate.id}`}
              onClick={handleDownloadExportTemplate}
            >{t('buttons.download')}</MenuItem>
            <MenuItem
              name={`Upload export template ${exportTemplate.id}`}
              onClick={toggleUploadDialog}
            >{t('buttons.upload')}</MenuItem>
            <MenuItem
              name={`Edot export template ${exportTemplate.id}`}
              onClick={toggleFormDialog}
            >{t('buttons.edit')}</MenuItem>
            <MenuItem
              name={`Delete export template ${exportTemplate.id}`}
              sx={{
                color: (theme) => theme.palette.error.main
              }}
              onClick={toggleConfirmationDialog}
            >{t('buttons.delete')}</MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
      {openFormDialog ? (
        <ExportTemplateFormDialog
          open={openFormDialog}
          onClose={toggleFormDialog}
          exportTemplate={exportTemplate}
        />
      ) : null}
      {openUploadDialog ? (
        <ExportTemplateUploadDialog
          open={openUploadDialog}
          onClose={toggleUploadDialog}
          exportTemplateId={exportTemplate.id}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('dialogs.confirmation.deleteTitle', { dataType: t('labels.reportTemplate') })}
          content={(
            <Trans
              t={t} i18nKey="dialogs.confirmation.deleteConfirmationWithName"
              components={{ strong: <strong /> }}
              values={{ dataType: t('labels.reportTemplate'), dataName: exportTemplate.name }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default ExportTemplatesListItem;
