import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getAuditRecords } from 'app/store/Audit/Audit.async';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import AuditRecordsList from './AuditRecordsList';
import AuditRecordsFilter from './AuditRecordsFilter';

const AuditRecordsPage:FC = () => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAuditRecords({
      caseId,
      limit: 1000,
      offset: 0
    }));
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.auditLogs.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="md">
          <AuditRecordsFilter />
          <AuditRecordsList />
        </Container>
      </Box>
    </Box>
  )
}

export default AuditRecordsPage;
