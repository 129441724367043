import { FC, Fragment, useEffect } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import FileFormats from 'app/types/FileFormats';
// Models
import IOption from 'app/models/Option';
import IUser, { IMyUser } from 'app/models/User';
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getExportTemplates } from 'app/store/ExportTemplates/ExportTemplates.async';
import { getTeams } from 'app/store/Teams/Teams.async';
import { acceptCaseShare, getCaseShareDetails } from 'app/store/CaseShares/CaseShares.async';
// Selectors
import { selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectCollections } from 'app/store/DMSCollections/DMSCollections.selectors';
import { selectExportTemplatesAsOptions } from 'app/store/ExportTemplates/ExportTemplates.selectors';
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
import { selectLoading } from 'app/store/CaseShares/CaseShares.selectors';
// Mui
import { Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Select, Button, LoadingButton } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import NotificationPreferences from 'app/components/NotificationPreferences';
// 
import ShareAlert from './ShareAlert';
import DocumentPlacements from './DocumentPlacements';

interface IFormData {
  reportTemplateId: string;
  teamId: number | null;
  clientAccountId: number | null;
  token: string;
  documentPlacements: { name:string; collectioId:number }[];
  notificationPreferences: {
    recipientIds: IUser[];
  }
};

type Props = {
  open: boolean;
  onClose: () => void;
  token: string;
}

const CaseFormDialog:FC<Props> = ({
  // Props
  open, onClose, token
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const collections:ICollection[] | null = useAppSelector(selectCollections);
  const exportTemplatesOptions:IOption[] | null = useAppSelector(selectExportTemplatesAsOptions);
  const teamOptions = useAppSelector(selectTeamsAsOptions);
  const loading = useAppSelector(selectLoading);

  const methods = useForm<IFormData>({
    defaultValues: {
      reportTemplateId: '',
      teamId: null,
      clientAccountId: null,
      documentPlacements: [],
      notificationPreferences: {
        recipientIds: myUser ? [myUser] : []
      }
    }
  });

  const { control, handleSubmit, setValue } = methods;

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { reportTemplateId, notificationPreferences, ...otherData } = data;
    const nextData:any = { ...otherData, token };

    if ( legacyReportTemplatesEnabled && reportTemplateId ) nextData['reportTemplateId'] = Number(reportTemplateId);

    if ( notificationPreferences ){
      const { recipientIds } = notificationPreferences;
      if ( recipientIds && recipientIds.length ){
        nextData['notificationPreferences'] = {
          recipientIds: recipientIds.map((recipient:IUser) => recipient.id)
        };
      }
    }

    try {
      await dispatch(acceptCaseShare(nextData)).unwrap();
      onClose();
    } catch(error){}
  });

  useEffect(() => {
    dispatch(getExportTemplates({}));
    dispatch(getTeams({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !collections ) return;

    const asyncGetCaseShareDetails = async () => {
      try {
        const response = await dispatch(getCaseShareDetails(token)).unwrap();
        if ( response.documents ) setValue('documentPlacements', response.documents.map((d:any) => {
          const nextDocument = { ...d, collectionId: '' };
          if ( d.fileFormat && collections && collections.length ){
            let collectionId:string = '';
            for ( let collection of collections ){
              if ( typeof d.ocred !== 'undefined' && collection.applyOcr !== d.ocred ) continue;
              if (
                !collection.fileFormats ||
                !collection.fileFormats.length ||
                collection.fileFormats.includes(d.fileFormat as FileFormats)
              ){
                if ( !collectionId ){
                  collectionId = collection.id.toString();
                } else {
                  collectionId = '';
                  break;
                }
              }
            }
            nextDocument['collectionId'] = collectionId;
          }
          return nextDocument;
        }));
      } catch(error){}
    }

    asyncGetCaseShareDetails();
    // eslint-disable-next-line
  }, [collections]);

  const actions = (
    <Fragment>
      <Button
        name="Cancel share case form dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Submit share case form dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.share')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.cases.shareDialogTitle')}
      actions={actions}
    >
      <FormProvider {...methods}>
        <form noValidate>
          <ShareAlert />

          {/* Report template */}
          {legacyReportTemplatesEnabled ? (
            <Controller
              control={control} name="reportTemplateId"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('labels.reportTemplate')}
                  options={exportTemplatesOptions}
                />
              )}
            />
          ) : null}
          <Controller
            control={control} name="teamId"
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={teamOptions || []}
                TextFieldProps={{
                  label: t('labels.team')
                }}
                renderOption={(props, option) => (
                  <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                    <Typography variant="subtitle1">{option.label}</Typography>
                      {option.props.description ? (
                        <Typography variant="caption">{option.props.description}</Typography>
                      ) : null}
                  </li>
                )}
              />
            )}
          />
          {/* Client account */}
          <Controller
            control={control} name="clientAccountId"
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={accountOptions || []}
                TextFieldProps={{
                  label: t('labels.clientAccount')
                }}
                renderOption={(props, option) => (
                  <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                    <Typography variant="subtitle1">{option.label}</Typography>
                    {option.props.address ? (
                      <Typography variant="caption">{option.props.address}</Typography>
                    ) : null}
                  </li>
                )}
                disableClearable={true}
              />
            )}
          />

          <NotificationPreferences />

          <DocumentPlacements />
        </form>
      </FormProvider>
    </Dialog>
  )
}

export default CaseFormDialog;