import { FC, ChangeEvent, useState, useEffect } from 'react';
// Models
import IClientAccount from 'app/models/ClientAccount';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { ClientsActions } from 'app/store/Clients/Clients.slice';
// Selectors
import { selectAccounts, selectFilter } from 'app/store/Clients/Clients.selectors';
// MaterialUI
import { Box } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// Hooks
import useDebounce from 'app/hooks/useDebounce';
// i18next
import { useTranslation } from 'react-i18next';

const AccountsFilter:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accounts:IClientAccount[] | null = useAppSelector(selectAccounts);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState({
    search: filter.search,
  });

  const debounceSearch = useDebounce(stateFilter.search);

  useEffect(() => {
    if ( debounceSearch !== filter.search ){
      dispatch(ClientsActions.setFilter({ field: 'search', value: debounceSearch }));
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  }
  
  const handleClearSearch = () => {
    setStateFilter((prevState:any) => ({
      ...prevState,
      search: ''
    }));
  }

  if ( accounts && !accounts.length ) return null;
  return (
    <Box sx={{ display: 'flex', gap: 4, mb: 4 }}>
      <Input
        label={t('labels.search')} id="search" name="search"
        placeholder={t('form.placeholder.companyOrAddress')}
        value={stateFilter.search}
        onChange={handleChange}
        onClear={handleClearSearch}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default AccountsFilter;
