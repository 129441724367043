import { FC, Fragment, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
import { AppUiDialogActions } from 'app/store/AppUIDialog/AppUIDialog.slice';
// Selectors
import { selectPreviewPageOpen, selectPreviewPage } from 'app/store/AppUI/AppUI.selectors';
import { selectDocumentsEntities } from 'app/store/DMSDocuments/DMSDocuments.selectors';
// Mui
import { Paper, Box, Tooltip, Typography, Divider } from '@mui/material';
// Icons
import {
  Close as CloseIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Remove as RemoveIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import InjestedPageImage from './InjestedPageImage';
import DeletePlaceholder from 'app/components/DeletePlaceholder';
import { AutomatedHighlights } from 'app/components/PageImageHighlights';
// ToDO
import { selectPageEntities } from 'app/store/page/page.selectors';
// End: ToDO

const zoomLevels = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];

const PageImagePreview:FC = () => {
  const { t } = useTranslation();
  const pageRef = useRef<HTMLDivElement | null>(null);
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const previewPageOpen = useAppSelector(selectPreviewPageOpen);
  const previewPage = useAppSelector(selectPreviewPage);
  const documentsEntities = useAppSelector(selectDocumentsEntities);
  const pageEntities = useAppSelector(selectPageEntities);

  const [ imageLoaded, setImageLoaded ] = useState(false);
  const [ urlBB, setUrlBB ] = useState('');
  const [ scale, setScale ] = useState<number>(100);
  const [position, setPosition] = useState<{x:number, y:number}>({ x: 0, y: 0 });
  const [dragStart, setDragStart] = useState<{x:number, y:number} | null>(null);
  const [contentSize, setContentSize] = useState<{width: number, height:number}>({ width: 0, height: 0 });

  const isScaled = useMemo(() => scale > 100, [scale]);
  
  const handleZoomIn = () => {
    const currentIndex = zoomLevels.indexOf(scale);
    if (currentIndex < zoomLevels.length - 1) {
      setScale(zoomLevels[currentIndex + 1]);
    }
  };
  const handleZoomOut = () => {
    const currentIndex = zoomLevels.indexOf(scale);
    if (currentIndex > 0) {
      setScale(zoomLevels[currentIndex - 1]);
    }
  };

  const handleMouseDown = (e:React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!isScaled) return;
    // setDragStart({ x: e.clientX, y: e.clientY });
    setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });

  };

  const handleMouseMove = (e:React.MouseEvent<HTMLDivElement>) => {
    if (!dragStart) return;
    // const deltaX = e.clientX - dragStart.x
    // const deltaY = e.clientY - dragStart.y;
    // setPosition(prev => ({ x: prev.x + deltaX, y: prev.y + deltaY }));
    // setDragStart({ x: e.clientX, y: e.clientY });
    setPosition({
      x: e.clientX - dragStart.x,
      y: e.clientY - dragStart.y,
    });
  };

  const handleMouseUp = () => setDragStart(null);
  const handleMouseLeave = () => setDragStart(null);

  const documentEntity = useMemo(() => {
    if ( !previewPage ) return undefined;
    return documentsEntities[previewPage.documentId];
  }, [documentsEntities, previewPage]);

  const pageEntity = useMemo(() => {
    if ( !previewPage ) return undefined;
    if ( !documentEntity || documentEntity.deleted ) return undefined;
    return pageEntities[previewPage.documentId][previewPage.pageNum];
  }, [documentEntity, pageEntities, previewPage])

  useEffect(() => {
    if ( !previewPage ) return;
    setImageLoaded(false);
    setUrlBB(
      previewPage.bboxes && previewPage.bboxes.length > 0
      ? previewPage.bboxes.map((bbox:any) => bbox.join(',')).join(',')
      : previewPage.bb || ''
    );
    return () => {
      setUrlBB('');
    }
    // eslint-disable-next-line
  }, [previewPage]);

  useEffect(() => {
    setScale(100);
    setPosition({ x: 0, y: 0 });
  }, [pageEntity]);

  useEffect(() => {
    if (!isScaled) {
      setPosition({ x: 0, y: 0 });
      return;
    }
  
    const maxX = -(contentSize.width * (scale / 100 - 1));
    const maxY = -(contentSize.height * (scale / 100 - 1));
  
    const correctedX = Math.min(0, Math.max(position.x, maxX));
    const correctedY = Math.min(0, Math.max(position.y, maxY));
  
    setPosition({ x: correctedX, y: correctedY });
  
    // eslint-disable-next-line
  }, [scale]);

  const handleClick = () => {
    if ( !previewPage ) return;
    const documentPages = pageEntities[previewPage.documentId];
    if ( !documentPages ) return;
    const page = documentPages[previewPage.pageNum];
    let documentId = page.documentId;
    let pageNum = page.pageNum;
    if ( page.staple && page.staple.order !== 1 ) {
      const pages = Object.values(documentPages);
      const foundedPage:any = pages.find((p:any) => p.staple?.id === page.staple.id && p.staple.order === 1);
      if ( !foundedPage ) return;
      pageNum = foundedPage.pageNum;
    }
    dispatch(AppUiDialogActions.showDialog({
      dialogName: 'PageDetailsDialog',
      dialogProps: {
        documentId,
        pageNum,
        openedPageNum: page.pageNum,
        showNavigations: false,
        pageBB: {
          pageNum: page.pageNum,
          urlBB
        }
      }
    }));
  }

  const handleClose = () => {
    dispatch(AppUIActions.setPreviewPageOpen(false));
    dispatch(AppUIActions.setPreviewPage(null));
  }

  const handleImageLoaded = (e:SyntheticEvent<HTMLImageElement>) => {
    setImageLoaded(true);
    const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
    setContentSize({ width: naturalWidth, height: naturalHeight });
  }

  const scaledStyles = {
    transform: `translate(${position.x}px, ${position.y}px) scale(${scale / 100})`,
    transformOrigin: isScaled ? 'top left' : 'center',
    cursor: !isScaled ? 'default' : dragStart ? 'grabbing' : 'grab',  
  };

  const disableZoomIn = scale === zoomLevels[zoomLevels.length - 1];
  const disableZoomOut = scale === zoomLevels[0];
  const isValidPage = !!pageEntity && !pageEntity.deleted;

  if ( !previewPageOpen ) return null;
  return (
    <Paper sx={{
      position: 'fixed',
      top: 0, left: 0, bottom: 0,
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'white',
      margin: 4,
      overflow: 'hidden',
      zIndex: 1199
    }}>
      <Box sx={{
        position: 'absolute',
        top: 0, right: 0,
        m: 4,
        p: 2,
        bgcolor: 'white',
        border: '1px solid rgba(0,0,0,0.08)',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        zIndex: 1
      }}>
        {isValidPage ? (
          <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <IconButton
                name="zoom out page"
                onClick={handleZoomOut}
                size="small"
                disabled={disableZoomOut}
              ><RemoveIcon /></IconButton>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, userSelect: 'none' }}>{`${scale} %`}</Typography>
              <IconButton
                name="zoom in page"
                onClick={handleZoomIn}
                size="small"
                disabled={disableZoomIn}
              ><AddIcon /></IconButton>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Tooltip title={t('buttons.editPage')}>
              <IconButton
                name="Edit page"
                onClick={handleClick}
                size="small"
              ><EditIcon /></IconButton>
            </Tooltip>
          </Fragment>
        ) : null}
        <Tooltip title={t('buttons.close')}>
          <IconButton
            name="Close injested page preview"
            onClick={handleClose}
            size="small"
          ><CloseIcon /></IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          className="cp-pageNum"
          sx={{
            position: 'absolute',
            top: 0, left: 0,
            m: 4
          }}
        >{pageEntity?.pageNum}</Box>
        <Box
          sx={{ position: 'relative' }}
          ref={pageRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          {typeof documentEntity !== 'undefined' ? (
            <Fragment>
              {!isValidPage ? (
                <DeletePlaceholder label={documentEntity?.deleted ? t('labels.documentDeleted') : t('labels.pageDeleted')} />
              ) : (
                <Box sx={{
                  position: 'relative',
                  ...(isValidPage && scaledStyles), 
                  '& > img': {
                    display: 'block',
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%'
                  }
                }}>
                  <InjestedPageImage
                    documentId={pageEntity.documentId}
                    pageNum={pageEntity.pageNum}
                    onImageLoaded={handleImageLoaded}
                  />
                  {imageLoaded && urlBB ? (
                    <AutomatedHighlights
                      pageNum={pageEntity.pageNum}
                      urlBB={urlBB}
                    />
                  ) : null}
                </Box>
              )}
            </Fragment>
          ) : null}
        </Box>
      </Box>
    </Paper>
  )
}

export default PageImagePreview;