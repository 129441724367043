export interface IState {
  ids: string[] | null,
  entities: Record<string, IBatch>;
  filter: {
    collectionId: number | undefined;
    search: string;
  };
  loading: boolean;
};

export interface IBatch {
  id: string;
  createdOn: string;
  comment?: string;
  coverLetterFile?: {
    path: string;
    name: string;
  },
  additional: boolean;

  documentIds: number[];

  // ToDO
  attachments: any[];
  attachmentsIds: any[];
  documents: any[];
  documentsIds: any[];
  // End: ToDO
};

export interface IValidateError {
  references?: string[];
  errorCode: ValidateErrorCodes;
  errorMessage: string;
};

export enum ValidateErrorCodes {
  DuplicateName = 'duplicateName',
  DuplicateContent = 'duplicateContent',
  NameExists = 'nameExists',
  ContentExists = 'contentExists',
  MaxSize = 'maxSize',
  MaxNameLength = 'maxNameLength',
  Other = 'other'
};
