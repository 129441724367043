import { FC, Fragment, useEffect, useMemo } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
// Types
import Features, { DatasetHPLFeatures } from 'app/types/Features';
// Models
import IOption from 'app/models/Option';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getGeneralSettings } from 'app/store/Settings/Settings.async';
import { createAccount } from 'app/store/AccountsManagement/AccountsManagement.async';
// Actions
import { BillingPackagesActions } from 'app/store/BillingPackages/BillingPackages.slice';
// Selectors
import { selectAvailableLanguageAsOptions } from 'app/store/AppUI/AppUI.selectors';
import { selectPackagesAsOptions, selectPackages } from 'app/store/BillingPackages/BillingPackages.selectors';
import { selectEntities as selectServicesEntities } from 'app/store/BillingServices/BillingServices.selectors';
import { selectLoading } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectDateFormatAsOptions, selectRecordsLanguageAsOptions, selectTimeFormatAsOptions, selectTimeZoneAsOptions } from 'app/store/Settings/Settings.selectors';
// Mui
import { Box, FormGroup } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import Phone from 'app/components/Phone';
import { Input, Select, Button, LoadingButton, Switch, Checkbox } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import { OutlineBlock } from 'app/components/Utilities';
import BillingEmails from 'app/components/BillingEmails';
// Utility
import { isRequired, isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';
import { FONT_FAMILIES, FONT_SIZES } from 'app/App.constants';

interface IFormData {
  company: string;
  email: string;
  phone: string;
  address: {
    city: string;
    state: string;
    zipCode: string;
    line: string;
  },
  owner: {
    name: string;
    email: string;
    phone: string;
    platform: boolean;
  },
  quickBooksId: string;
  subscriptionPackageIds: number[];
  billingEmails: string[];
  // Features
  datasetHPL: DatasetHPLFeatures | undefined;
  features: (Features)[];

  settings: {
    timeZone: string;
    dateFormat: string;
    timeFormat: string;
    recordsLanguage: string;
    language: string;
    reportPreferences: {
      fontFamily: string;
      fontSize: number;
    };
  }
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const AccountCreateFormDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const languageOptions = useAppSelector(selectAvailableLanguageAsOptions);
  const loading:boolean = useAppSelector(selectLoading);
  const packages = useAppSelector(selectPackages);
  const packageOptions = useAppSelector(selectPackagesAsOptions);
  const dateFormatOptions:IOption[] | null = useAppSelector(selectDateFormatAsOptions);
  const timeFormatOptions:IOption[] | null = useAppSelector(selectTimeFormatAsOptions);
  const recordsLanguageOptions:IOption[] | null = useAppSelector(selectRecordsLanguageAsOptions);
  const timeZoneOptions:IOption[] | null = useAppSelector(selectTimeZoneAsOptions);
  const serviceEntities = useAppSelector(selectServicesEntities);

  const methods = useForm<IFormData>({
    defaultValues: {
      company: '',
      email: '',
      phone: '',
      address: {
        city: '',
        state: '',
        zipCode: '',
        line: '',
      },
      owner: {
        name: '',
        email: '',
        phone: '',
        platform: false
      },
      quickBooksId: '',
      subscriptionPackageIds: [],
      billingEmails: [],
      datasetHPL: Features.DatasetHPL_FL,
      features: [Features.HcpcsCodes, Features.PageClassification],

      settings: {
        timeZone: '',
        recordsLanguage: '',
        dateFormat: '',
        timeFormat: '',
        language: '',
        reportPreferences: {
          fontFamily: FONT_FAMILIES[0],
          fontSize: FONT_SIZES[7]
        }
      }
    }
  });

  const { register, control, handleSubmit, formState: { errors }, watch } = methods;

  const watchSubscriptionPackageIds = watch('subscriptionPackageIds') || [];

  const hasAnySubscripionPackageCPTCodesAccess:boolean = useMemo(() => {
    let hasCPTCodesAccess = false;
    if ( !packages || watchSubscriptionPackageIds.length === 0 ) return hasCPTCodesAccess;
    for ( let pkg of packages ){
      if ( !watchSubscriptionPackageIds.includes(pkg.id) ) continue;
      if ( !pkg.items || pkg.items.length === 0 ) continue;

      for ( let item of pkg.items ){
        const serviceEntity = serviceEntities[item.serviceId];
        if ( !serviceEntity ) continue;
        if ( serviceEntity.code === 'cptCodesAccess' ){
          hasCPTCodesAccess = true;
          break;
        }
      }
      if ( hasCPTCodesAccess ) break;
    }
    return hasCPTCodesAccess;
    // eslint-disable-next-line
  }, [packages, watchSubscriptionPackageIds]);

  useEffect(() => {
    dispatch(getGeneralSettings({}));
    return () => {
      dispatch(BillingPackagesActions.setInitialField('package'));
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      const { address, quickBooksId, billingEmails, features, datasetHPL, ...otherData } = data;
      const nextData:any = {
        ...otherData,
        features: features.length
          ? features.filter((feature:Features) => {
              if ( !hasAnySubscripionPackageCPTCodesAccess && feature === Features.HcpcsCodes ) return false;
              return true;
            })
          : []
      };
      if ( datasetHPL ) nextData['features'] = [...nextData['features'], datasetHPL]
      if ( quickBooksId ) nextData['quickBooksId'] = quickBooksId;
      if ( billingEmails.length ) nextData['billingEmails'] = billingEmails;
      if ( Object.values(address).some(v => v) ){
        nextData['address'] = {};
        for ( let key in address ){
          const value = (address as any)[key];
          if ( value ) nextData['address'][key] = value;
        }
      }
      await dispatch(createAccount(nextData)).unwrap();
      onClose();
    } catch(error){}
  });

  const datasetHPLOptions = [
    { id: '', name: t('pages.companyAccounts.chooseauthorMatchingDataset') },
    { id: Features.DatasetHPL_FL, name: 'FL' },
    { id: Features.DatasetHPL_WA, name: 'WA' },
    { id: Features.DatasetHPL_CA, name: 'CA' },
  ];

  const actions = (
    <Fragment>
      <Button
        name="Cancel create company account dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Create company account dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.companyAccounts.createFormTitle')} 
      actions={actions}
    >
      <form noValidate>
        {/* Hidden fields */}
        <input {...register('settings.reportPreferences.fontFamily') as any} type="hidden" value={FONT_FAMILIES[0]} />
        <input {...register('settings.reportPreferences.fontSize') as any} type="hidden" value={FONT_SIZES[7]} />

        <OutlineBlock
          sx={{ mt: 4 }}
          label={t('labels.company')}
          helperText={t('form.helperText.usedForCommunicationPurpose')}
        >
          <Fragment>
            {/* Company */}
            <Controller
              control={control} name="company"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.name')}
                  error={Boolean(errors.company)}
                  helperText={errors.company?.message || ''}
                  required
                />
              )}
            />
            {/* E-mail */}
            <Controller
              control={control} name="email"
              rules={{ required: isRequired, pattern: isEmailValid }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.contactEmail')}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message || ''}
                  required
                />
              )}
            />
            {/* Phone */}
            <Controller
              control={control} name="phone"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Phone
                  {...field}
                  label={t('labels.contactPhone')}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone?.message || ''}
                  required={true}
                />
              )}
            />
            <Controller
              control={control} name="address.line"
              render={({ field }) => (
                <Input {...field} label={t('labels.address')} />
              )}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Controller
                control={control} name="address.city"
                render={({ field }) => (
                  <Input {...field} label={t('labels.city')} />
                )}
              />
              <Controller
                control={control} name="address.state"
                render={({ field }) => (
                  <Input {...field} label={t('labels.state')} />
                )}
              />
              <Controller
                control={control} name="address.zipCode"
                render={({ field }) => (
                  <Input {...field} label={t('labels.zipCode')} />
                )}
              />
            </Box>
          </Fragment>
        </OutlineBlock>
        <OutlineBlock
          sx={{ mt: 6 }}
          label={t('labels.owner')}
          helperText={t('form.helperText.userWillBeCreatedUsingThisData')}
        >
          <Fragment>
            {/* Name */}
            <Controller
              control={control} name="owner.name"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.name')}
                  error={Boolean(errors.owner?.name)}
                  helperText={errors.owner?.name?.message || ''}
                  required
                />
              )}
            />
            {/* E-mail */}
            <Controller
              control={control} name="owner.email"
              rules={{ required: isRequired, pattern: isEmailValid }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.email')} type="email"
                  error={Boolean(errors.owner?.email)}
                  helperText={errors.owner?.email?.message || ''}
                  required
                />
              )}
            />
            {/* Phone */}
            <Controller
              control={control} name="owner.phone"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Phone
                  {...field}
                  label={t('labels.cellPhone')}
                  error={Boolean(errors.owner?.phone)}
                  helperText={errors.owner?.phone?.message || ''}
                  required={true}
                />
              )}
            />
            <Controller
              control={control} name="owner.platform"
              render={({ field:{ onChange, ...otherField }}) => (
                <Checkbox
                  {...otherField}
                  onChange={(_, checked:boolean) => onChange(checked)}
                  label={t('labels.markPlatform')}
                />
              )}
            />
          </Fragment>
        </OutlineBlock>
        {/* Subscription */}
        <OutlineBlock sx={{ mt: 6 }} label={t('labels.subscriptionPackage')}>
          <Fragment>
            {/* Package */}
            <Controller
              control={control} name="subscriptionPackageIds"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={packageOptions || []}
                  TextFieldProps={{
                    label: t('labels.packages'),
                    required: true,
                    error: Boolean(errors.subscriptionPackageIds),
                    helperText: errors.subscriptionPackageIds?.message || ''
                  }}
                  multiple
                  filterSelectedOptions
                />
              )}
            />
            {/* Company */}
            <Controller
              control={control} name="quickBooksId"
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('labels.quickbooksId')}
                  helperText={t('pages.companyAccounts.quickbooksIdHelperText')}
                />
              )}
            />
          </Fragment>
        </OutlineBlock>
        {/* Subscription */}
        <OutlineBlock sx={{ mt: 6 }} label={t('labels.billing')}>
          {/* E-mails */}
          <FormProvider {...methods}>
            <BillingEmails label={t('labels.email')} />
          </FormProvider>
        </OutlineBlock>

        <Controller
          control={control} name="settings.timeZone" defaultValue="America/New_York"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.timeZone')}
              required
              error={Boolean(errors.settings?.timeZone)}
              helperText={errors.settings?.timeZone?.message || ''}
              options={timeZoneOptions}
            />
          )}
        />
        {/* Date format */}
        <Controller
          control={control} name="settings.dateFormat"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.dateFormat')}
              error={Boolean(errors.settings?.dateFormat)}
              helperText={errors.settings?.dateFormat?.message || ''}
              required
              options={dateFormatOptions}
            />
          )}
        />
        {/* Date format */}
        <Controller
          control={control} name="settings.timeFormat"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.timeFormat')}
              error={Boolean(errors.settings?.timeFormat)}
              helperText={errors.settings?.timeFormat?.message || ''}
              required
              options={timeFormatOptions}
            />
          )}
        />
        {/* OCR language */}
        <Controller
          control={control} name="settings.recordsLanguage"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.ocrLanguage')}
              error={Boolean(errors.settings?.recordsLanguage)}
              helperText={errors.settings?.recordsLanguage?.message || ''}
              required
              options={recordsLanguageOptions}
            />
          )}
        />
        {/* Language */}
        <Controller
          control={control} name="settings.language"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.language')}
              error={Boolean(errors.settings?.language)}
              helperText={errors.settings?.language?.message || ''}
              required
              options={languageOptions}
            />
          )}
        />

        <OutlineBlock sx={{ mt: 6 }} label={t('labels.features')}>
          <Fragment>
            <Controller
              control={control} name="features"
              render={({ field:{ onChange, value } }) => {
                const handleChange = (feature:Features) => () => {
                  const hasFeature = (value as (Features)[]).includes(feature);
                  const nextFeatures = hasFeature
                    ? (value as (Features)[]).filter((v) => v !== feature)
                    : [...value, feature]
                  ;
                  onChange(nextFeatures);
                }
                return (
                  <FormGroup>
                    <Switch
                      name="AI Chat"
                      label={t('labels.aiChat')}
                      value={value.includes(Features.AIChat)}
                      onChange={handleChange(Features.AIChat)}
                    />
                    {hasAnySubscripionPackageCPTCodesAccess ? (
                      <Switch
                        name="Cpt codes access"
                        label={t('pages.companyAccounts.cptCodesAccess')}
                        value={value.includes(Features.HcpcsCodes)}
                        onChange={handleChange(Features.HcpcsCodes)}
                        helperText={t('pages.companyAccounts.cptCodesAccessHelperText')}
                      />
                    ) : null}
                    <Switch
                      name="Automatic page classification"
                      label={t('pages.companyAccounts.automaticPageClassification')}
                      value={value.includes(Features.PageClassification)}
                      onChange={handleChange(Features.PageClassification)}
                      helperText={t('pages.companyAccounts.automaticPageClassificationHelperText')}
                    />
                    <Switch
                      name="Patient portal"
                      label={t('labels.patientPortal')}
                      value={value.includes(Features.PatientPortal)}
                      onChange={handleChange(Features.PatientPortal)}
                    />
                  </FormGroup>
                )
              }}
            />
            <Controller
              control={control} name="datasetHPL"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('pages.companyAccounts.authorMatchingDataset')}
                  options={datasetHPLOptions}
                />
              )}
            />
          </Fragment>
        </OutlineBlock>
      </form>
    </Dialog>
  );
}

export default AccountCreateFormDialog;
