import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSortedInvoices } from 'app/store/Invoices/Invoices.selectors';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import InvoicesTable from './InvoicesTable';

const InvoicesList:FC = () => {
  const { t } = useTranslation();

  const invoices = useAppSelector(selectSortedInvoices);

  if ( !invoices ) return <Loader />;
  if ( !invoices.length ) return <Message text={t('pages.invoices.noData')} />;
  return (
    <Fragment>
      {invoices.map((invoice:any) => (
        <InvoicesTable
          key={`invoice table ${invoice.id}`}
          invoice={invoice}
        />
      ))}
    </Fragment>
  )
}

export default InvoicesList;
