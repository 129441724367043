import { Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Models
import { IMyUser } from 'app/models/User';
// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks/useStore';
// Async
import { sendEmailChangeVerificationLink, updateCurrentUser } from 'app/store/Users/Users.async';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
// Selectors
import { selectAvailableLanguageAsOptions } from 'app/store/AppUI/AppUI.selectors';
import { selectLoading, selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import {
  Box, Container, Divider, Paper, Typography,
  FormGroup
} from '@mui/material';
// Components
import Phone from 'app/components/Phone';
import PasswordHints from 'app/components/PasswordHints';
import { Input, Select, Switch, LoadingButton } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
import EmaiVerificationlHelperText from 'app/components/EmaiVerificationlHelperText';
// Utilities
import { isRequired, isEmailValid } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';
import useToggle from 'app/hooks/useToggle';
import VerificationDialog from './VerificationFormDialog';
import BodyErrors from 'app/types/BodyErrors';

interface IFormData {
  name: string;
  email: string;
  phone: string;
  notificationPreferences: {
    sms: boolean;
    email: boolean;
    push: boolean;
  },
  language: string;
  newPassword: string;
  reNewPassword: string;
};

const MyProfilePage = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const languageOptions = useAppSelector(selectAvailableLanguageAsOptions);
  const myUser:IMyUser | null = useAppSelector(selectMyUser);
  const loading:boolean = useAppSelector(selectLoading);

  const { open, toggle } = useToggle();

  const [ multiFactoryData, setMultiFactoryData ] = useState<any>(null);

  const { control, handleSubmit, formState:{ errors }, watch } = useForm<IFormData>({
    defaultValues: {
      name: myUser?.name || '',
      email: myUser?.email || '',
      phone: myUser?.phone || '',
      notificationPreferences: {
        sms: myUser?.notificationPreferences.sms || false,
        email: myUser?.notificationPreferences.email || false,
        push: myUser?.notificationPreferences.push || false,
      },
      language: myUser?.language || 'en',
      newPassword: '',
      reNewPassword: ''
    }
  });

  useEffect(() => {
    if ( multiFactoryData && !open ) toggle();
  }, [multiFactoryData, open, toggle]);

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { newPassword, reNewPassword, ...otherData } = data;
    const profileData:Partial<IFormData> & {
      customAttributes?: any;
      password?: string;
    } = { ...otherData };
    if ( myUser?.customAttributes ) profileData['customAttributes'] = myUser.customAttributes;
    if ( newPassword ) profileData['password'] = newPassword;
    try {
      await dispatch(updateCurrentUser({ data: profileData })).unwrap();
      // Chnage language on user save
      dispatch(AppUIActions.setLanguage(profileData.language as string));
    } catch(error:any){
      if ( error && error.error === BodyErrors.MfaRequired ){
        const { id:challengeId, ...otherBody } = error.challenge;
        setMultiFactoryData({ ...otherBody, challengeId, profileData });
      }
    }
  });

  const handleClose = () => {
    if ( open ) toggle();
    setMultiFactoryData(null);
  }

  const handleResendClick = () => {
    dispatch(sendEmailChangeVerificationLink({}));
  }

  const watchNewPassword = watch('newPassword');
  const watchReNewPassword = watch('reNewPassword');

  const isNewPasswordRequired = watchReNewPassword !== '';
  const isReNewPasswordRequired = watchNewPassword !== '';

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container maxWidth="sm">
          <Paper sx={{ p: 8 }} variant="outlined">
            <form onSubmit={onSubmit} noValidate>
              {/* Name */}
              <Controller
                control={control} name="name"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('labels.name')}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message || ''}
                    required
                  />
                )}
              />
              {/* E-mail */}
              <Controller
                control={control} name="email"
                rules={{ required: isRequired, pattern: isEmailValid }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('labels.email')} type="email"
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message || ''}
                    required
                  />
                )}
              />
              <EmaiVerificationlHelperText
                emailChangeRequest={myUser?.emailChangeRequest}
                onResend={handleResendClick}
              />
              {/* Phone */}
              <Controller
                control={control} name="phone"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <Phone
                    {...field}
                    label={t('labels.cellPhone')}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone?.message || ''}
                    required={true}
                  />
                )}
              />
              <Typography
                sx={{ display: 'block' }}
                variant="caption"
                component="small"
              >{t('pages.profile.phoneHint')}</Typography>
              {/* <Divider sx={{ mt: 4, mb: 2 }} /> */}
              <OutlineBlock sx={{ mt: 6 }} label="Notifications">
                <FormGroup>
                  <Controller
                    control={control} name="notificationPreferences.sms"
                    render={({ field:{ onChange, ...otherField } }) => (
                      <Switch
                        {...otherField}
                        label={t('labels.sms')}
                        onChange={(_, checked:boolean) => onChange(checked)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="notificationPreferences.email"
                    render={({ field:{ onChange, ...otherField } }) => (
                      <Switch
                        {...otherField}
                        label={t('labels.email')}
                        onChange={(_, checked:boolean) => onChange(checked)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="notificationPreferences.push"
                    render={({ field:{ onChange, ...otherField } }) => (
                      <Switch
                        {...otherField}
                        label={t('labels.app')}
                        onChange={(_, checked:boolean) => onChange(checked)}
                      />
                    )}
                  />
                </FormGroup>
              </OutlineBlock>
              <OutlineBlock sx={{ mt: 6, mb: 4 }} label="Settings">
                {/* Language */}
                <Controller
                  control={control} name="language"
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label={t('labels.language')}
                      error={Boolean(errors.language)}
                      helperText={errors.language?.message || ''}
                      required
                      options={languageOptions}
                    />
                  )}
                />
              </OutlineBlock>
              <Divider sx={{ my: 2 }} />
              <Controller
                control={control} name="newPassword"
                rules={{ required: isNewPasswordRequired ? isRequired : false }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('labels.newPassword')} type="password" autoComplete="off"
                    error={Boolean(errors.newPassword)}
                    helperText={errors.newPassword?.message || ''}
                    required={Boolean(isNewPasswordRequired)}
                  />
                )}
              />
              {/* Re-Password */}
              <Controller
                control={control} name="reNewPassword"
                rules={{
                  required: isReNewPasswordRequired ? isRequired : false,
                  validate: (value:string) => value === watchNewPassword || t('validations.passwordDoesntMatch')
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('labels.confirmNewPassword')} type="password" autoComplete="off"
                    error={Boolean(errors.reNewPassword)}
                    helperText={errors.reNewPassword?.message || ''}
                    required={Boolean(isReNewPasswordRequired)}
                  />
                )}
              />
              <PasswordHints />
              <Box sx={{ mt: 4 }}>
                <LoadingButton
                  name="Save profile"
                  loading={loading}
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >{t('buttons.save')}</LoadingButton>
              </Box>
            </form>
          </Paper>
        </Container>
      </Box>
      {open ? (
        <VerificationDialog
          open={open}
          onClose={handleClose}
          multiFactoryData={multiFactoryData}
        />
      ) : null}
    </Fragment>
  )
}

export default MyProfilePage;
