import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { TemplateDataType, TemplateLayout, TemplateType } from 'app/types/Template';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { TemplatesActions } from 'app/store/Templates/Templates.slice';
// Selectors
import { selectWizardFormInfo } from 'app/store/Templates/Templates.selectors';
// Mui
import { FormGroup, FormHelperText, FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
// Icons
import {
  Subject as SubjectIcon,
  SourceOutlined as SourceOutlinedIcon,
  ViewQuiltOutlined as ViewQuiltOutlinedIcon
} from '@mui/icons-material';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
import { getTemplateDataTypeLabel, getTemplateLayoutLabel, getTemplateTypeLabel } from 'app/utilities/Utilities';

interface IFormData {
  type: TemplateType | null;
  dataType: TemplateDataType | null;
  layout: TemplateLayout | null;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const TemplateWizardFormDialog:FC<Props> = ({
  // Props
  open, onClose, onConfirm
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const wizardFormInfo = useAppSelector(selectWizardFormInfo);

  const { handleSubmit, control, formState:{ errors }, watch } = useForm<IFormData>({
    defaultValues: {
      type: wizardFormInfo.type,
      dataType: wizardFormInfo.dataType,
      layout: wizardFormInfo.layout
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    dispatch(TemplatesActions.setWizardFormInfo(data));
    onConfirm();
  });

  const watchType = watch('type');
  const watchDataType = watch('dataType');

  const actions = (
    <Fragment>
      <Button
        name="Cancel wizard form dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <Button
        name="Confirm wizard form dialog"
        type="submit"
        variant="contained"
        color="primary"
      >{t('buttons.next')}</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('dialogs.templateForm.createTitle')}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      <FormGroup>
        <FormLabel sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <SubjectIcon sx={{ mr: 2 }} fontSize="small" />
          {t('labels.type')}
        </FormLabel>
        <Controller
          control={control} name="type"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <ToggleButtonGroup
              {...field}
              color="primary"
              exclusive
              fullWidth
              size="small"
            >
              {(Object.values(TemplateType) as TemplateType[]).map((type) => (
                <ToggleButton
                  key={`type item ${type}`}
                  sx={{ textTransform: 'capitalize' }}
                  value={type}
                >{getTemplateTypeLabel(type)}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />
        {errors.type ? (
          <FormHelperText sx={{ pl: 4, mt: 2 }} error>{errors.type.message}</FormHelperText>
        ) : null}
      </FormGroup>

      {watchType === TemplateType.Data ? (
        <Fragment>
          <FormGroup sx={{ mt: 4 }}>
            <FormLabel sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <SourceOutlinedIcon sx={{ mr: 2 }} fontSize="small" />
              {t('labels.source')}
            </FormLabel>
            <Controller
              control={control} name="dataType"
              rules={{ required: isRequired }}
              render={({ field }) => (
                <ToggleButtonGroup
                  {...field}
                  color="primary"
                  exclusive
                  fullWidth
                  size="small"
                >
                  {(Object.values(TemplateDataType) as TemplateDataType[]).map((dataType) => (
                    <ToggleButton
                      key={`type item ${dataType}`}
                      sx={{ textTransform: 'capitalize' }}
                      value={dataType}
                    >{getTemplateDataTypeLabel(dataType)}</ToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}
            />
            {errors.dataType ? (
              <FormHelperText sx={{ pl: 4, mt: 2 }} error>{errors.dataType.message}</FormHelperText>
            ) : null}
          </FormGroup>

          {watchDataType ? (
            <FormGroup sx={{ mt: 4 }}>
              <FormLabel sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <ViewQuiltOutlinedIcon sx={{ mr: 2 }} fontSize="small" />
                {t('labels.layout')}
              </FormLabel>
              <Controller
                control={control} name="layout"
                rules={{ required: isRequired }}
                render={({ field }) => (
                  <ToggleButtonGroup
                    {...field}
                    color="primary"
                    exclusive
                    fullWidth
                    size="small"
                  >
                    {(Object.values(TemplateLayout) as TemplateLayout[]).map((layout) => {
                      if ( watchDataType === TemplateDataType.Document && layout === TemplateLayout.Chart ) return null;
                      return (
                        <ToggleButton
                          key={`type item ${layout}`}
                          sx={{ textTransform: 'capitalize' }}
                          value={layout}
                        >{getTemplateLayoutLabel(layout)}</ToggleButton>
                      )
                    })}
                  </ToggleButtonGroup>
                )}
              />
              {errors.layout ? (
                <FormHelperText sx={{ pl: 4, mt: 2 }} error>{errors.layout.message}</FormHelperText>
              ) : null}
            </FormGroup>
          ) : null}
        </Fragment>
      ) : null}
    </Dialog>
  )
}

export default TemplateWizardFormDialog;