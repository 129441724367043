// Models
import { RootState } from "app/store";
// Store
import { useAppSelector } from "app/hooks/useStore";
import { IDocument } from "app/store/DMSDocuments/DMSDocuments.models";
// Selectors
import { selectIsFormatOcrEligible } from "app/store/Settings/Settings.selectors";
import { selectIsDocumentReprocessingEnabled } from "app/store/Accounts/Accounts.selectors";
import { selectEntities as selectCollectionEntities } from "app/store/DMSCollections/DMSCollections.selectors";

const useIsDocumentProcessAllowed = (document:IDocument) => {
  const isDocumentReprocessingEnabled = useAppSelector(selectIsDocumentReprocessingEnabled);
  const isFormatOcrEligible = useAppSelector((state:RootState) => selectIsFormatOcrEligible(state, {
    fileFormat: document.fileFormat
  }));
  const collectionEntities = useAppSelector(selectCollectionEntities);

  const isOCREligibleForDocument = isFormatOcrEligible && collectionEntities[document.collectionId]?.applyOcr;

  return {
    showProcessHintAndButton: isOCREligibleForDocument && !document.ocred && document.processing?.status !== 'processing',
    showProcessMenuItem: isOCREligibleForDocument && ( !document.ocred || isDocumentReprocessingEnabled )
  }
};

export default useIsDocumentProcessAllowed;
