import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { IBatch } from 'app/store/DMSBatches/DMSBatches.models';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Select
import { selectFilteredBatchesByCollection } from 'app/store/DMSBatches/DMSBatches.selectors';
// Mui
import { Box, List } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import BatchItemSubhead from './BatchItemSubhead';
import DocumentItem from './DocumentItem';

const BatchesList:FC = () => {
  const { t } = useTranslation();
  // State
  const batches:IBatch[] | null = useAppSelector(selectFilteredBatchesByCollection);

  if ( !batches ) return <Box sx={{ flexGrow: 1 }}><Loader /></Box>;
  if ( !batches.length ) return <Box sx={{ flexGrow: 1 }}><Message text={t('components.filesSidebar.noData')} /></Box>;
  return (
    <List
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        py: 0
      }}
    >
      {batches.map((batch:IBatch) => (
        <li key={`batch item ${batch.id}`}>
          <List sx={{ py: 0 }}>
            <BatchItemSubhead batch={batch} />
            {batch.documentIds.map((documentId:number) => (
              <DocumentItem
                key={`document item ${documentId}`}
                documentId={documentId}
              />
            ))}
          </List>
        </li>
      ))}
    </List>
  )
}

export default BatchesList;
