import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.termsAndConditions') }),
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.termsAndConditions') }),
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.termsAndConditions') }),
  Publish: i18n.t('notifications.terms.publish'),
  Archive: i18n.t('notifications.terms.archive')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.termsAndConditions') });
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.termsAndConditions') });
  Messages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.termsAndConditions') });
  Messages.Publish = i18n.t('notifications.terms.publish');
  Messages.Archive = i18n.t('notifications.terms.archive');
});