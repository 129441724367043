import { FC, Fragment, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
// Models
import IOption from 'app/models/Option';
import IUser from 'app/models/User';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectExportTemplatesAsOptions } from 'app/store/ExportTemplates/ExportTemplates.selectors';
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
import { selectAccountsAsOptions } from 'app/store/Clients/Clients.selectors';
import { selectHcpcsCodesEnabled, selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { Box, Divider, FormHelperText, Typography } from '@mui/material';
// Components
import { Input, Select, DesktopDatePicker, DesktopTimePicker } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import NotificationPreferences from 'app/components/NotificationPreferences';
// Utility
import { isRequired, isDateValid, isTimeValid, isMaxLength } from 'app/utilities/Validations';

interface IFormData {
  name: string;
  reportTemplateId: string | number;
  teamId: string | number;
  clientAccountId?: string | number;
  settings: {
    medicareOverchargeThreshold: number;
  };
  eventDate: Dayjs | null,
  eventTime: Dayjs | null;
  notificationPreferences: {
    recipientIds: IUser[];
  };
};

type Props = {
  caseId?: number;
  onSetError: (error:boolean) => void;
}

const CaseTabContent:FC<Props> = ({
  // Props
  caseId, onSetError
}) => {
  const { t } = useTranslation();

  // State
  const hcpcsCodesEnabled = useAppSelector(selectHcpcsCodesEnabled);
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const exportTemplatesOptions:IOption[] | null = useAppSelector(selectExportTemplatesAsOptions);
  const teamOptions = useAppSelector(selectTeamsAsOptions);
  const accountOptions = useAppSelector(selectAccountsAsOptions);

  const { control, watch, formState:{ errors, isSubmitted } } = useFormContext<IFormData>();

  useEffect(() => {
    if ( !isSubmitted ) return;
    const { claimant, claimNumber, ...otherErrors } = errors as any;
    onSetError(Boolean(Object.keys(otherErrors).length));
    // eslint-disable-next-line
  }, [isSubmitted, Object.keys(errors)]);

  const watchEventDate= watch('eventDate');

  return (
    <Fragment>
      <Controller
        control={control} name="name"
        rules={{
          required: isRequired,
          maxLength: isMaxLength(100)
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.caseName')}
            required
            error={Boolean(errors.name)}
            helperText={errors.name?.message || ''}
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watch('name').length} / 100`}</FormHelperText>
              )
            }}
          />
        )}
      />
      <Box sx={{ display: 'flex', gap: 4 }} >
        <Controller
          control={control} name="eventDate"
          rules={{
            required: isRequired,
            validate: isDateValid
          }}
          render={({ field }) => (
            <DesktopDatePicker
              {...field}
              label={t('labels.eventDate')}
              inputProps={{
                required: true,
                error: Boolean(errors.eventDate),
                helperText: errors.eventDate?.message || ''
              }}
            />
          )}
        />
        {watchEventDate ? (
          <Controller
            control={control} name="eventTime"
            rules={{
              validate: {
                pattern: isTimeValid
              }
            }}
            render={({ field }) => (
              <DesktopTimePicker
                {...field}
                label={t('labels.eventTime')}
                inputProps={{
                  error: Boolean(errors.eventTime),
                  helperText: errors.eventTime?.message || ''
                }}
              />
            )}
          />
        ) : null}
      </Box>
      <Divider sx={{ mt: 4, mb: 2 }} />

      {/* Report template */}
      {legacyReportTemplatesEnabled ? (
        <Controller
          control={control} name="reportTemplateId" defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              label={t('labels.reportTemplate')}
              options={exportTemplatesOptions}
            />
          )}
        />
      ) : null}
      <Controller
        control={control} name="teamId"
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={teamOptions || []}
            TextFieldProps={{
              label: t('labels.team')
            }}
            renderOption={(props, option) => (
              <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                <Typography variant="subtitle1">{option.label}</Typography>
                {option.props.description ? (
                  <Typography variant="caption">{option.props.description}</Typography>
                ) : null}
              </li>
            )}
          />
        )}
      />
      {/* Client account */}
      {!caseId ? (
        <Controller
          control={control} name="clientAccountId"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={accountOptions || []}
              TextFieldProps={{
                label: t('labels.clientAccount'),
                required: true,
                error: Boolean(errors.clientAccountId),
                helperText: errors.clientAccountId?.message || ''
              }}
              renderOption={(props, option) => (
                <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                  <Typography variant="subtitle1">{option.label}</Typography>
                  {option.props.address ? (
                    <Typography variant="caption">{option.props.address}</Typography>
                  ) : null}
                </li>
              )}
              disableClearable={true}
            />
          )}
        />
      ) : null}

      {hcpcsCodesEnabled ? (
        <Fragment>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Controller
            control={control} name="settings.medicareOverchargeThreshold"
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('labels.medicareOverchargeThreshold')} type="number"
                error={Boolean(errors.settings?.medicareOverchargeThreshold)}
                helperText={errors.settings?.medicareOverchargeThreshold?.message || ''}
                required={hcpcsCodesEnabled}
              />
            )}
          />
          <FormHelperText>{t('form.helperText.medicareOverchargeThreshold')}</FormHelperText>
        </Fragment>
      ) : null}

      <NotificationPreferences />
    </Fragment>
  )
}

export default CaseTabContent;
