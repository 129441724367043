import { FC, Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createAuditRecord } from 'app/store/Audit/Audit.async';
import { shareReport } from 'app/store/CaseReports/CaseReports.async';
import { getAccountUsers } from 'app/store/Clients/Clients.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectLoading } from 'app/store/CaseReports/CaseReports.selectors';
import { selectAccountUsersAsOptions } from 'app/store/Clients/Clients.selectors';
// Mui
import { Typography, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// 
import MixpanelTracks from 'app/types/MixpanelTracks';
import Mixpanel from 'app/services/Mixpanel.service';

type Props = {
  open: boolean;
  onClose: () => void;
  reportId: number;
  reportName: string;
}

const ReportShareDialog:FC<Props> = ({
  // Props
  open, onClose, reportId, reportName
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // Sttae
  const myUser = useAppSelector(selectMyUser);
  const accountUserOptions = useAppSelector(selectAccountUsersAsOptions);
  const insuranceCase = useAppSelector(selectInsuranceCase);
  const loading = useAppSelector(selectLoading)

  const [ recipientIds, setRecipientIds ] = useState<number[]>([]);
  const [ error, setError ] = useState<string | null>(null);

  const handleClick = async () => {
    if ( !recipientIds || !recipientIds.length ){
      setError(t('validations.fieldRequired'));
      return;
    }

    setError(null);

    const insuranceCaseId = Number(caseId);

    try {
      const notificationRecipients = (accountUserOptions || [])
        .filter(({ value }) => recipientIds.includes(value))
        .map((option) => ({
          id: option.value,
          name: option.label,
          email: option.props.email
        }))
      ;
      await dispatch(shareReport({ reportId, data:{ notificationRecipients } })).unwrap();

      Mixpanel.track(MixpanelTracks.CaseReportShared, { caseId: insuranceCaseId });

      dispatch(createAuditRecord({
        operation: AuditOperations.ReportShared,
        data: {
          caseId: insuranceCaseId,
          reportName,
          userName: myUser?.name || '',
          clientUserEmails: notificationRecipients.map((recipient:any) => recipient.email)
        }
      }));

      onClose();
    } catch(error){}
  }

  useEffect(() => {
    if ( insuranceCase?.clientAccount?.id ){
      dispatch(getAccountUsers(insuranceCase.clientAccount.id));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (newValue:any) => {
    setRecipientIds(newValue);
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel report dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Share report dialog"
        loading={loading}
        onClick={handleClick}
        variant="contained"
        color="primary"
      >{t('pages.reports.shareButton')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.reports.shareDialogTitle')}
      actions={actions}
    >
      <Typography sx={{ mb: 2 }} variant="body1">{t('pages.reports.shareDialogSubtitle1')}</Typography>
      <Typography sx={{ mb: 4 }} variant="body1">{t('pages.reports.shareDialogSubtitle2')}</Typography>
      <Autocomplete
        value={recipientIds}
        onChange={handleChange}
        options={accountUserOptions || []}
        TextFieldProps={{
          label: t('pages.reports.recipientsLabel'),
          required: true,
          error: Boolean(error),
          helperText: error || ''
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.props.email}
          </li>
        )}
        multiple
        filterSelectedOptions
        getOptionLabel={(option:any) => option.props.email}
      />
      <FormHelperText>{t('pages.reports.recipientsHelperText')}</FormHelperText>
    </Dialog>
  )
}

export default ReportShareDialog;
