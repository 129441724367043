import { MiddlewareAPI, Middleware, isFulfilled, isRejected } from '@reduxjs/toolkit'
// Models
import { AppDispatch } from 'app/store';
// Async
import { logout, switchAccount } from 'app/store/Auth/Auth.async';
import { updateCurrentUser, verifyEmail } from 'app/store/Users/Users.async';
import { createPreSignedUrlForFile, uploadFileByPreSignedUrl } from 'app/store/DMSUploads/DMSUploads.async';
// Actions
import { RequestsActions } from 'app/store/Requests/Requests.slice';
import { DMSUploadsActions } from 'app/store/DMSUploads/DMSUploads.slice';
// Selectors
import { selectAuthenticated } from 'app/store/Auth/Auth.selectors';

const RequestsMiddleware:Middleware = ({ dispatch, getState }:MiddlewareAPI<AppDispatch>) => (next:any) => (action:any) => {
  const { type, payload } = action;

  const excludedTypes = [
    // Switch account is duplicate of POST /api/users/self to avoid 401 and logout
    switchAccount.pending.type,
    switchAccount.fulfilled.type,
    switchAccount.rejected.type,

    // Update current user is duplicate of PUT /api/users/self to avoid 401 and logout
    updateCurrentUser.pending.type,
    updateCurrentUser.fulfilled.type,
    updateCurrentUser.rejected.type,

    verifyEmail.pending.type,
    verifyEmail.fulfilled.type,
    verifyEmail.rejected.type,

    createPreSignedUrlForFile.pending.type,
    createPreSignedUrlForFile.fulfilled.type,
    createPreSignedUrlForFile.rejected.type,

    uploadFileByPreSignedUrl.pending.type,
    uploadFileByPreSignedUrl.fulfilled.type,
    uploadFileByPreSignedUrl.rejected.type,

    DMSUploadsActions.addPreSignedUrl.type,
    DMSUploadsActions.removePreSignedUrl.type,
  ];

  // Since we might have over 100+ files to upload
  // It might be 400+ dispatch actions
  if ( excludedTypes.includes(type) ) return next(action);

  if ( type.endsWith('/pending') ){
    const controller = new AbortController();
    action.meta.signal = controller.signal;
    dispatch(RequestsActions.addAbortController(controller));
    dispatch(RequestsActions.incrementActiveRequests());
  } else if ( isFulfilled(action) ){
    dispatch(RequestsActions.decrementActiveRequests());
  } else if ( isRejected(action) ){
    const state = getState();
    const authenticated = selectAuthenticated(state);

    if ( typeof payload !== 'undefined' ){
      const { statusCode } = payload;
      if ( authenticated && statusCode === 401 ){
        dispatch(RequestsActions.clearAbortControllers());
        dispatch(logout());
      }
    }

    dispatch(RequestsActions.decrementActiveRequests());
  }

  return next(action);
}

export default RequestsMiddleware;
