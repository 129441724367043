import { ChangeEvent, FC, Fragment, useState, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { WorkflowTriggers, WorkflowType } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionsAsOptionsByFileType } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { Box, Paper, Toolbar, Typography } from '@mui/material';
// Components
import { Input, Select, Switch, Button } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import Labels from 'app/components/Labels';
// Utilities
import { isRequired } from 'app/utilities/Validations';
import { OutlineBlock } from 'app/components/Utilities';
import { RootState } from 'app/store';

interface IFormData {
  name: string;
  type: WorkflowType;
  trigger: WorkflowTriggers;
  enabled: boolean;
  conditions: {
    'document:collectionId': [],
    'case:labels': []
  }
};

const SidebarWorkflowDetails:FC = () => {
  const { t } = useTranslation();
  // State
  const collectionOptions = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType(state, {
    ocred: true
  }));

  const { register, control, formState:{ errors }, watch, setValue } = useFormContext<IFormData>();

  const watchLabels = useMemo(() => {
    return watch('conditions.case:labels') || [];
    // eslint-disable-next-line
  }, [watch('conditions.case:labels')]);

  const watchWorkflowType = watch('type');

  const [ name, setName ] = useState('');

  const modifiedLabels = useMemo(() => {
    return watchLabels.map((label:string) => ({ name: label, color: 'initial' }))
  }, [watchLabels]);

  const handleChangeLabelName = (event:ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const handleAddLabel = () => {
    if ( !name ) return;
    const value = [...watchLabels, name] as any;
    setValue('conditions.case:labels', value);
    setName('');
  }

  const handleRemoveLabel = (index:number) => {
    const value = [...watchLabels] as any;
    setValue('conditions.case:labels', value.filter((_:any, idx:number) => idx !== index))
  }

  const collectionIdError = errors?.conditions?.['document:collectionId'];

  return (
    <Paper variant="outlined">
      <Toolbar variant="dense" sx={{ borderRadius: '4px 4px 0 0' }}>
        <Typography variant="subtitle2">{t('pages.automatedWorkflows.workflowDetails')}</Typography>
      </Toolbar>
      <Box sx={{ pt: 2, pb: 4, px: 4 }}>
        <input {...register('type')} type="hidden" />

        <Controller
          control={control} name="name"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.name')}
              required
              error={Boolean(errors.name)}
              helperText={errors.name?.message || ''}
              size="small"
            />
          )}
        />
        {watchWorkflowType === WorkflowType.Automated ? (
          <Fragment>
            <Controller
              control={control} name="trigger"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('pages.automatedWorkflows.trigger')}
                  disabled={true}
                  options={[
                    { id: WorkflowTriggers.DocumentBatchReady, name: t('pages.automatedWorkflows.documentBatchReady') },
                  ]}
                  size="small"
                />
              )}
            />
            <OutlineBlock sx={{ pt: 4, mt: 6, mb: 2 }} label={t('pages.automatedWorkflows.triggerConditions')}>
              <Fragment>
                <Controller
                  control={control} name="conditions.document:collectionId" // ToDO: Naming should be checked to be: document:collectionIds
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={collectionOptions || []}
                      TextFieldProps={{
                        label: t('labels.documentCollection'),
                        name: field.name,
                        size: 'small',
                        required: true,
                        error: Boolean(collectionIdError),
                        helperText: collectionIdError?.message || ''
                      }}
                      renderOption={(props, option) => (
                        <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                          <Typography variant="subtitle1">{option.label}</Typography>
                          {option.props.description ? (
                            <Typography variant="caption">{option.props.description}</Typography>
                          ) : null}
                        </li>
                      )}
                      multiple={true}
                      filterSelectedOptions
                    />
                  )}
                />

                <input {...register('conditions.case:labels') as any} type="hidden" />

                <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
                  <Box flexGrow={1}>
                    <Input
                      label={t('pages.automatedWorkflows.caseLabels')} value={name} name="name"
                      onChange={handleChangeLabelName}
                      size="small"
                      margin="none"
                    />
                  </Box>
                  <Box sx={{ mt: 0.5 }}>
                    <Button
                      name="Add label"
                      disabled={!name}
                      onClick={handleAddLabel}
                      variant="contained"
                      color="primary"
                    >{t('buttons.add')}</Button>
                  </Box>
                </Box>
                {modifiedLabels.length ? (
                  <Box sx={{ pt: 4 }}>
                    <Labels
                      id=""
                      labels={modifiedLabels}
                      onRemove={handleRemoveLabel}
                    />
                  </Box>
                ) : null}
              </Fragment>
            </OutlineBlock>
          </Fragment>
        ) : null}
        <Controller
          control={control} name="enabled"
          render={({ field:{ onChange, ...otherField }}) => (
            <Switch
              {...otherField}
              onChange={(_, checked:boolean) => onChange(checked)}
              label={t('labels.enabled')}
            />
          )}
        />
      </Box>
    </Paper>
  )
}

export default SidebarWorkflowDetails