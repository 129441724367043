import { FC, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
// Types
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Models
import IReport from 'app/models/Report';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createAuditRecord } from 'app/store/Audit/Audit.async';
import { deleteReport, downloadReportFile, previewReport } from 'app/store/CaseReports/CaseReports.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectLoading } from 'app/store/CaseReports/CaseReports.selectors';
// Mui
import { Theme, Paper, Box, Divider, Typography, Chip, CircularProgress } from '@mui/material';
// Icon
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Dialogs
import LEGACYReportFormDialog from 'app/dialogs/ReportFormDialog';
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
import Icon from 'app/components/Icon';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
import useLongDateTme from 'app/hooks/useLongDateTime';
// ToDO: Sevice
import MixpanelTracks from 'app/types/MixpanelTracks';
import Mixpanel from 'app/services/Mixpanel.service';
// 
import ReportFormDialog from './ReportFormDialog';
import ReportUploadDialog from './ReportUploadDialog';
import ReportShareDialog from './ReportShareDialog';

type Props = {
  report: IReport;
}

const ReportsListItem:FC<Props> = ({
  // Props
  report
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();

  const { isArchived } = useCaseStatus();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser);
  const loading = useAppSelector(selectLoading);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();
  const { open:openLegacyFormDialog, toggle:toggleLegacyFormDialog } = useToggle();
  const { open:openUploadDialog, toggle:toggleUploadDialog } = useToggle();
  const { open:openShareDialog, toggle:toggleShareDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const [ stateLoading, setStateLoading ] = useState(false);

  const longCreatedOn = useLongDateTme({
    dateValue: report.createdOn,
    displayTime: true,
    local: true
  });
  const longDownloadedOn = useLongDateTme({
    dateValue: report.downloadedOn,
    displayTime: true,
    local: true
  });
  const longSharedOn = useLongDateTme({
    dateValue: report.sharedOn,
    displayTime: true,
    local: true
  });

  const handleReportDownloadFile = (format:any) => async () => {
    setStateLoading(true);
    try {
      await dispatch(downloadReportFile({ reportId: report.id, params:{ format }}));
    } catch(error){} finally {
      setStateLoading(false);
    }
  }

  const handleReportPreview = () => {
    dispatch(previewReport(report.id));
  }

  const handleConfirm = async () => {
    const insuranceCaseId = Number(caseId);
    try {
      await dispatch(deleteReport(report.id)).unwrap();

      Mixpanel.track(MixpanelTracks.CaseReportRemoved, { caseId: insuranceCaseId });

      if ( report.shared ){
        dispatch(createAuditRecord({
          operation: AuditOperations.ReportDeleted,
          data: {
            caseId: insuranceCaseId,
            reportName: report.name,
            userName: myUser?.name || '',
            clientUserEmails: report.notificationRecipients.map((recipient:any) => recipient.email)
          }
        }));
      } else {
        dispatch(createAuditRecord({
          operation: AuditOperations.ReportDeleted,
          data: {
            caseId: insuranceCaseId,
            reportName: report.name,
            userName: myUser?.name || ''
          }
        }));
      }
    } catch(error){}
  }

  return (
    <Fragment>
      <Paper
        sx={{ display: 'flex', p: 4, '& + &': { mt: 4 } }}
        variant="outlined"
      >
        <Box sx={{ flexGrow: 1, pr: 2 }} >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ flexGrow: 1 }} variant="subtitle1">{report.name}</Typography>
            {report.sharedOn ? (
              <Icon size="small" icon="shared" label="Shared" />
            ) : null}
          </Box>
          {report.notificationRecipients && report.notificationRecipients.length ? (
            <InfoBlock
              label={t('labels.sharedTo')}
              value={
                <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {report.notificationRecipients.map((recipient:any, index:number) => (
                    <Chip
                      key={`report-item-${report.id}-recipient-${index}`}
                      sx={{ borderRadius: 1 }}
                      label={recipient.email}
                      size="small"
                    />
                  ))}
                </Box>
              }
            />
          ) : null}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', gap: 4, mr: -12 }}>
            {longCreatedOn ? (
              <InfoBlock label={t('labels.createdAt')} value={longCreatedOn} />
            ) : null}
            {longDownloadedOn ? (
              <InfoBlock label={t('labels.downloadedAt')} value={longDownloadedOn} />
            ) : null}
            {longSharedOn ? (
              <InfoBlock label={t('labels.sharedAt')} value={longSharedOn} />
            ) : null}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: -2, mr: -2 }}>
          <IconButton
            name={`Report dropdown ${report.id}`}
            aria-label="Report item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          {stateLoading ? (
            <CircularProgress size={24} thickness={4} />
          ) : null}
          <Menu>
            <MenuItem
              name={`Create a new version ${report.id}`}
              onClick={toggle}
            >{t('pages.reports.createANewVersion')}</MenuItem>
            {!report.shared &&
              [
                <MenuItem
                  key={`Edit report ${report.id}`}
                  name={`Edit report ${report.id}`}
                  disabled={isArchived}
                  onClick={toggleLegacyFormDialog}
                >{t('pages.reports.renameButton')}</MenuItem>,
                <MenuItem
                  key={`Share report ${report.id}`}
                  name={`Share report ${report.id}`}
                  disabled={isArchived}
                  onClick={toggleShareDialog}
                >{t('pages.reports.shareButton')}</MenuItem>
              ]
            }
            <MenuItem
              name={`Preview report ${report.id}`}
              onClick={handleReportPreview}
            >{t('pages.reports.previewButton')}</MenuItem>
            <Divider />
            <MenuItem
              name={`Download report ${report.id} pdf`}
              disabled={stateLoading}
              onClick={handleReportDownloadFile('pdf')}
            >{t('pages.reports.downloadPdfButton')}</MenuItem>
            <MenuItem
              name={`Download report ${report.id} word`}
              disabled={stateLoading}
              onClick={handleReportDownloadFile('word')}
            >{t('pages.reports.downloadWordButton')}</MenuItem>
            {!report.shared &&
              <MenuItem
                name={`Upload file ${report.id}`}
                disabled={isArchived}
                onClick={toggleUploadDialog}
              >{t('buttons.upload')}</MenuItem>
            }
            <Divider />
            <MenuItem
              name={`Delete report ${report.id}`}
              sx={{ color: (theme:Theme) => theme.palette.error.main }}
              disabled={isArchived}
              onClick={toggleConfirmationDialog}
            >{t('buttons.delete')}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {open ? (
        <ReportFormDialog
          open={open}
          onClose={toggle}
          report={report}
        />
      ) : null}
      {/* Dialogs */}
      {openLegacyFormDialog ? (
        <LEGACYReportFormDialog
          open={openLegacyFormDialog}
          onClose={toggleLegacyFormDialog}
          report={report}
        />
      ) : null}
      {openUploadDialog ? (
        <ReportUploadDialog
          open={openUploadDialog}
          onClose={toggleUploadDialog}
          reportId={report.id}
        />
      ) : null}
      {openShareDialog ? (
        <ReportShareDialog
          open={openShareDialog}
          onClose={toggleShareDialog}
          reportId={report.id}
          reportName={report.name}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          loading={loading}
          title={t('dialogs.confirmation.deleteTitle', { dataType: t('labels.report') })}
          content={(
            <Trans
              t={t} i18nKey="dialogs.confirmation.deleteConfirmationWithName"
              components={{ strong: <strong /> }}
              values={{ dataType: t('labels.report'), dataName: report.name }}
            />
          )}
          onConfirm={handleConfirm}
        />
      ) : null}
    </Fragment>
  )
}

export default ReportsListItem;
