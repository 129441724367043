import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createReportSection } from 'app/store/ReportSections/ReportSections.async';
import { getReportSectionTemplate } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.async';
// Actions
import { ReportSectionTemplatesActions } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.slice';
// Selectors
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
import { selectReportSectionTemplates } from 'app/store/ReportSectionTemplates/ReportSectionTemplates.selectors';
// Mui
import {
  List, ListItem, ListItemText, ListItemSecondaryAction
} from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
import { Loader, Message } from 'app/components/Utilities';


type Props = {
  open: boolean;
  onClose: () => void;
}

const ReportSectionImportDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const reportSectionTemplates = useAppSelector(selectReportSectionTemplates);

  useEffect(() => {
    dispatch(getReportSectionTemplate({}));

    return () => {
      dispatch(ReportSectionTemplatesActions.setInitialField('reportSectionTemplates'));
    }
    // eslint-disable-next-line
  }, []);

  const actions = (
    <Button
      name="Cancel report section import dialog"
      onClick={onClose}
    >{t('buttons.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.reportSections.importDialogTitle')}
      actions={actions}
    >
      {!reportSectionTemplates
        ? <Loader />
        : !reportSectionTemplates.length
          ? <Message text={t('pages.reportSections.noTemplatesData')} />
          : (
              <List>
                {reportSectionTemplates.map((reportSectionTemplate:any) => (
                  <ReportSectionTemplateItem
                    key={`report section template item ${reportSectionTemplate.id}`}
                    reportSectionTemplate={reportSectionTemplate}
                  />
                ))}
              </List>
            )

      }
    </Dialog>
  )
}

export default ReportSectionImportDialog;

const ReportSectionTemplateItem = ({
  reportSectionTemplate
}:{
  reportSectionTemplate:any
}) => {
  const { caseId } = useParams<{ caseId:string }>();

  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentAccount = useAppSelector(selectCurrentAccount);

  const [ loading, setLoading ] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    const nextData = {
      ...reportSectionTemplate,
      insuranceCaseId: caseId,
      accountId: currentAccount.id
    };
    try {
      await dispatch(createReportSection(nextData)).unwrap();
    } catch(error){} finally {
      setLoading(false);
    }
  }

  return (
    <ListItem>
      <ListItemText primary={reportSectionTemplate.name} />
      <ListItemSecondaryAction>
        <LoadingButton
          aria-label="Import report section template button"
          name={`Import report section template ${reportSectionTemplate.id}`}
          loading={loading}
          onClick={handleClick}
          color="primary"
          variant="contained"
        >{t('buttons.import')}</LoadingButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
