import { FC, ReactNode, useState, useCallback } from 'react';
// Types
import FileFormats from 'app/types/FileFormats';
// Models
import { IValidateError } from 'app/store/DMSBatches/DMSBatches.models';
// 
import { IFileItem, UploadContext } from './useUploadContext';
import useGenerateFileItem from './useGenerateFileItem';
import useErrorsHandler from './useErrorsHandler';
import useFileValidationErrors from './useFileItemsHandler';

interface IProps {
  children: ReactNode;
}

const UploadProvider:FC<IProps> = ({
  // Props
  children
}) => {
  const { onGenerateFileItem } = useGenerateFileItem();
  const { transformErrorsToMap, handleRemoveErrorAndReferences } = useErrorsHandler();
  const { mergeValidationErrors, assignErrorsToFileItems } = useFileValidationErrors();

  const [ comment, setComment ] = useState('');
  const [ fileItems, setFileItems ] = useState<IFileItem[]>([]);
  const [ fileItemErrorMap, setFileItemErrorMap ] = useState<Map<string, IValidateError[]>>(new Map());
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ isValid, setIsValid ] = useState(true);
  const [ uploadedFilesCount, setUploadedFilesCount ] = useState(0);

  const validateFileItems = useCallback((fileItems:IFileItem[]) => {
    let isValid = true;
    for ( const fileItem of fileItems ){
      if ( !fileItem.collectionId || fileItem.errors.length ) {
        isValid = false;
        break;
      }
    }
    return isValid;
  }, []);

  const handleValidateFiles = useCallback((
    fileItems:IFileItem[],
    fileItemErrorsMap:Map<string, IValidateError[]>
  ) => {
    const validationErrors = mergeValidationErrors(fileItems, fileItemErrorsMap);
    const updatedFileItems = assignErrorsToFileItems(fileItems, validationErrors);

    setFileItemErrorMap(validationErrors);
    setFileItems(updatedFileItems);

    const isValid = validateFileItems(updatedFileItems);

    setIsValid(isValid);

    return isValid;
    // eslint-disable-next-line
  }, []);

  const handleCommentChange = useCallback((comment:string) => {
    setComment(comment);
  }, []);

  // Add file item to map
  const handleAdd = useCallback((files:File[]) => {
    setFileItems((prevState) => {
      const result = [...prevState];
      for (let file of files) {
        const fileItem = onGenerateFileItem(file);
        result.push(fileItem);
      }
      return result;
    });
    // eslint-disable-next-line
  }, [onGenerateFileItem]);

  // Update file item in map
  // data:{ comments, collectionId }
  const handleUpdate = useCallback((fileItemId:string, data:any) => {
    setFileItems((prevState) => prevState.map((fileItem) => {
      if ( fileItem.id === fileItemId ) return { ...fileItem, ...data };
      return fileItem;
    }));
    // eslint-disable-next-line
  }, []);

  // Remove file item from map
  // And remove BE error by fileItemId
  const handleRemove = useCallback((fileItemId:string) => {
    const updateFileItems = fileItems.filter((fileItem) => fileItem.id !== fileItemId);
    const newErrorsMap = handleRemoveErrorAndReferences(fileItemErrorMap, fileItemId);
    handleValidateFiles(updateFileItems, newErrorsMap);
    // eslint-disable-next-line
  }, [fileItems, fileItemErrorMap, handleRemoveErrorAndReferences, handleValidateFiles]);

  const handleUpdateFileItemsCollections = useCallback((fileFormat:FileFormats | null, collectionId:number) => {
    setFileItems((prevState) => prevState.map((fileItem) => {
      if ( !fileItem.collectionId && fileItem.fileFormat === fileFormat ) {
        return { ...fileItem, collectionId };
      }
      return fileItem;
    }));
    // eslint-disable-next-line
  }, []);

  const handleBuildErrorMap = useCallback((
    errors:IValidateError[][],
    fileIdsByIndexes:string[]
  ) => {
    const newErrorMap = transformErrorsToMap(errors, fileIdsByIndexes);
    // setFileItemErrorMap(newErrorMap);
    handleValidateFiles(fileItems, newErrorMap);
    // eslint-disable-next-line
  }, [fileItems, transformErrorsToMap]);

  const handleSubmit = useCallback((cb:() => void) => {
    setIsSubmitted(true);
    const isValid = handleValidateFiles(fileItems, fileItemErrorMap);
    if ( !fileItems.length || !isValid ) return;
    cb();
    // eslint-disable-next-line
  }, [fileItems, fileItemErrorMap]);

  return (
    <UploadContext.Provider value={{
      comment,
      onCommentChange: handleCommentChange,
      uploadedFilesCount,
      onSetUploadedFilesCount: setUploadedFilesCount,

      fileItems,

      onAdd: handleAdd,
      onUpdate: handleUpdate,
      onRemove: handleRemove,
      updateFileItemsCollections: handleUpdateFileItemsCollections,

      handleBuildErrorMap,
      handleSubmit,
      formState: {
        isLoading,
        isSubmitted,
        isValid,
        onSetIsLoading: setIsLoading,
        onSetIsSubmitted: setIsSubmitted
      }
    }}>{children}</UploadContext.Provider>
  )
}

export default UploadProvider;