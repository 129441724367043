import { FC, Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
import { IPrompt } from 'app/store/AIChatPrompts/AIChatPrompts.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getTeams } from 'app/store/Teams/Teams.async';
import { createPrompt, updatePrompt } from 'app/store/AIChatPrompts/AIChatPrompts.async';
// Selectors
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
import { selectLoading } from 'app/store/AIChatPrompts/AIChatPrompts.selectors';
// Mui
import { Typography, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import LabelsForm from 'app/components/LabelsForm';
import { Button, Input, LoadingButton } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import { OutlineBlock } from 'app/components/Utilities';
// Utilities
import { isMaxLength, isRequired } from 'app/utilities/Validations';

interface IFormData {
  title: string;
  content: string;
  teamIds: number[];
}

type Props = {
  open: boolean;
  onClose: () => void;
  aiPrompt?: IPrompt;
  content?: string;
}

const AIPromptFormDialog:FC<Props> = ({
  // Props
  open, onClose, aiPrompt, content
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const insuranceCase = useAppSelector(selectInsuranceCase);
  const teamOptions = useAppSelector(selectTeamsAsOptions);
  const loading = useAppSelector(selectLoading);

  const showTeams = myUser.role !== UserRoles.AccountManager;
  const prefillTeams = myUser.role === UserRoles.Staff && insuranceCase;
  const requireTeamsAutocomplete = myUser.role !== UserRoles.Admin; // Team optional for `admin` user

  const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm<IFormData>({
    defaultValues: {
      title: aiPrompt?.title || '',
      content: content || aiPrompt?.content || '',
      teamIds: aiPrompt?.teamIds || []
    }
  });

  const [ labels, setLabels ] = useState(aiPrompt?.labels || [])

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { teamIds, ...otherData } = data;
    const nextData:any = otherData;
    if ( teamIds && teamIds.length ) nextData['teamIds'] = teamIds;
    if ( labels && labels.length ) nextData['labels'] = labels;
    try {
      if ( aiPrompt ){
        await dispatch(updatePrompt({ id: aiPrompt.id, data: nextData })).unwrap();
      } else {
        await dispatch(createPrompt(nextData)).unwrap();
      }
      onClose();
    } catch(err){}
  });

  useEffect(() => {
    if ( showTeams ) dispatch(getTeams({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !prefillTeams ) return;
    if ( !showTeams || !teamOptions ) return;

    let teamId:number | undefined;
    if ( insuranceCase ) teamId = insuranceCase.team?.id;

    const teamIds = teamOptions
      .map(({ value }) => value)
      .filter((value) => {
        if ( teamId ) return value === teamId;
        return value;
      });
    setValue('teamIds', teamIds);
    // eslint-disable-next-line
  }, [teamOptions, prefillTeams]);

  const actions = (
    <Fragment>
      <Button
        name="Cancel prompt dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name={`${aiPrompt ? 'update' : 'create'} prompt dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{aiPrompt ? t('buttons.update') : t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={aiPrompt ? t('dialogs.aiPromptForm.updateFormTitle') : t('dialogs.aiPromptForm.createFormTitle')}
      actions={actions}
    >
      <form noValidate>
        {/* Title */}
        <Controller
          control={control} name="title"
          rules={{
            required: isRequired,
            maxLength: isMaxLength(50)
          }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.title')}
              required
              error={Boolean(errors.title)}
              helperText={errors.title?.message || ''}
              InputProps={{
                endAdornment: (
                  <FormHelperText
                    sx={{ p: 0, whiteSpace: 'nowrap' }}
                  >{`${watch('title').length} / 50`}</FormHelperText>
                )
              }}
            />
          )}
        />
        {/* Name */}
        <Controller
          control={control} name="content"
          rules={{
            required: isRequired,
            maxLength: isMaxLength(1000)
          }}
          render={({ field }) => (
            <Fragment>
              <Input
                {...field}
                label={t('labels.content')}
                required
                error={Boolean(errors.content)}
                helperText={errors.content?.message || ''}
                multiline
                rows={4}
              />
              <FormHelperText
                sx={{ p: 0, whiteSpace: 'nowrap', textAlign: 'right' }}
              >{`${watch('content').length} / 1000`}</FormHelperText>
            </Fragment>
          )}
        />
        {showTeams ? (
          <Fragment>
            <Controller
              control={control} name="teamIds"
              rules={{ required: requireTeamsAutocomplete ? isRequired : false }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={teamOptions || []}
                  TextFieldProps={{
                    label: t('labels.team'),
                    name: field.name,
                    required: requireTeamsAutocomplete,
                    error: Boolean(errors.teamIds),
                    helperText: (errors.teamIds as any)?.message || ''
                  }}
                  renderOption={(props, option) => (
                    <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                      <Typography variant="subtitle1">{option.label}</Typography>
                      {option.props.description ? (
                        <Typography variant="caption">{option.props.description}</Typography>
                      ) : null}
                    </li>
                  )}
                  multiple={true}
                  limitTags={3}
                  filterSelectedOptions
                />
              )}
            />
            <FormHelperText>{t('dialogs.aiPromptForm.teamsHint')}</FormHelperText>
          </Fragment>
        ) : null}

        <OutlineBlock sx={{ pt: 6, mt: 6 }} label={t('labels.labels')}>
          <LabelsForm labels={labels} onUpdate={setLabels} />
        </OutlineBlock>
      </form>
    </Dialog>
  )
}

export default AIPromptFormDialog;
