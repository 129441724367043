import { FC, memo, useMemo, useCallback, useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
// Canvas
import CanvasJSReact from 'app/libraries/canvasjs.react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { setFilter } from 'app/store/calendar/calendarSlice';
// Selectors
import { selectFilter, selectInWorkspaceEpisodeLabels, selectFullChartData } from 'app/store/calendar/calendarSelectors';
// Mui
import { Box, Button, Typography, TextField, Autocomplete, Tooltip } from '@mui/material';
// 
import ChartPopover from './ChartPopover';
// i18next
import { useTranslation } from 'react-i18next';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

type Props = {
  initialDataPointWidth: number;
  initialOptions: any;
}

const FullChart:FC<Props> = ({
  // Props
  initialDataPointWidth, initialOptions
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const filter:any = useAppSelector(selectFilter);
  const episodeLabels = useAppSelector(selectInWorkspaceEpisodeLabels);
  const { fullChartData, initialViewportMinimum, initialViewportMaximum } = useAppSelector(selectFullChartData);

  const canvasRef = useRef<any>(null);

  const [ selectedBar, setSelectedBar ] = useState(null);

  const handleOpenBar = (event:any) => setSelectedBar(event);
  const handleCloseBar = () => setSelectedBar(null);

  const data = useMemo(() => {
    return fullChartData.map((data:any) => ({
      ...data,
      click: (event:any) => handleOpenBar(event)
    }))
  }, [fullChartData]);

  const toggleShowAll = useCallback(() => {
    dispatch(setFilter({ field: 'showAll', value: !filter.showAll }));
    setOptions((prevState:any) => ({
      ...prevState,
      data: prevState.data.map((data:any) => ({...data, visible: !filter.showAll}))
    }));
    // eslint-disable-next-line
  }, [filter.showAll]);

  const handleZoomOut = useCallback(() => {
    setOptions((prevState:any) => {
      let { viewportMinStack, viewportMaxStack } = prevState;
      let nextViewportMinStack = [];
      let nextViewportMaxStack = [];
      let nextViewportMinimim = initialViewportMinimum;
      let nextViewportMaximum = initialViewportMaximum;
      let zoomOutVisible = false;
      let pointWidth = initialDataPointWidth;
      if ( viewportMinStack.length > 1 ){
        viewportMinStack.pop();
        viewportMaxStack.pop();
        nextViewportMinStack = viewportMinStack;
        nextViewportMaxStack = viewportMaxStack;
        nextViewportMinimim = nextViewportMinStack[nextViewportMinStack.length - 1];
        nextViewportMaximum = nextViewportMaxStack[nextViewportMaxStack.length - 1];
        pointWidth = setDataPointWidth(nextViewportMinimim, nextViewportMaximum, initialDataPointWidth);
        zoomOutVisible = true;
      }
      return {
        ...prevState,
        zoomOutVisible,
        dataPointWidth: pointWidth,
        viewportMinStack: nextViewportMinStack,
        viewportMaxStack: nextViewportMaxStack,
        axisX: {
          ...prevState.axisX,
          viewportMinimum: nextViewportMinimim,
          viewportMaximum: nextViewportMaximum
        }
      }
    })
    // eslint-disable-next-line
  }, []);

  const handleClick = useCallback((event:any) => {
    const key = event.dataSeries.key;
    setOptions((prevState:any) => ({
      ...prevState,
      data: prevState.data.map((data:any) => data.key === key ? {...data, visible: !data.visible} : data)
    }));
    // eslint-disable-next-line
  }, []);

  const handleRangeChanging = useCallback((event:any) => {
    if ( event.trigger === 'zoom' ){
      const axisX = event.axisX[0];
      const { viewportMinimum, viewportMaximum } = axisX;
      setOptions((prevState:any) => ({
        ...prevState,
        zoomOutVisible: true,
        // Resize bar width if daterange is less than 12 months
        dataPointWidth: setDataPointWidth(viewportMinimum, viewportMaximum, initialDataPointWidth),

        // Zoom Out points
        viewportMinStack: [...prevState.viewportMinStack, viewportMinimum],
        viewportMaxStack: [...prevState.viewportMaxStack, viewportMaximum],
        axisX: {
          ...prevState.axisX,
          viewportMinimum,
          viewportMaximum
        }
      }));
    }
    if ( event.trigger === 'reset' ){
      setOptions((prevState:any) => ({
        ...prevState,
        zoomOutVisible: false,
        dataPointWidth: initialDataPointWidth,
        axisX: {
          ...prevState.axisX,
          viewportMinimum: initialViewportMinimum,
          viewportMaximum: initialViewportMaximum
        }
      }))
    }
    // eslint-disable-next-line
  }, []);

  const [ options, setOptions ] = useState({
    ...initialOptions,
    rangeChanging: handleRangeChanging,

    viewportMinStack: [],
    viewportMaxStack: [],
    pointWidths: [],
  
    zoomEnabled: true,
    legend: {
      verticalAlign: 'bottom',
      horizontalAlign: 'center',
      cursor: 'pointer',
      itemclick: handleClick
    },
    axisX: {
      ...initialOptions.axisX,
      valueFormatString: "MMM, DD YYYY",
      viewportMinimum: initialViewportMinimum,
      viewportMaximum: initialViewportMaximum
    },

    data,
  
    toggleVisible: false,
    zoomOutVisible: false
  });

  useEffect(() => {
    setOptions((prevState:any) => ({
      ...prevState,
      data
    }));
    // eslint-disable-next-line
  }, [filter.episodeLabels]);

  const handleEpisodeLabelChange = (_:any, value:string[]) => {
    dispatch(setFilter({ field: 'episodeLabels', value }));
  }

  const barOpen = Boolean(selectedBar);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {filter.showAll ? (
            <Tooltip title={t('pages.calendar.tooltip.hideAllBars')}>
              <Button
                onClick={toggleShowAll}
                color="primary"
                variant="outlined"
                size="small"
              >{t('pages.calendar.hideAllButton')}</Button>
            </Tooltip>
          ) : (
            <Tooltip title={t('pages.calendar.tooltip.showAllBars')}>
              <Button
                onClick={toggleShowAll}
                color="primary"
                variant="outlined"
                size="small"
              >{t('pages.calendar.showAllButton')}</Button>
            </Tooltip>
          )}
          {options.zoomOutVisible ? (
            <Button
              onClick={handleZoomOut}
              color="primary"
              variant="outlined"
              size="small"
            >{t('pages.calendar.zoomOutButton')}</Button>
          ) : null}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Autocomplete
            sx={{ width: 320 }}
            value={filter.episodeLabels}
            options={episodeLabels || []}
            onChange={handleEpisodeLabelChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('labels.episodeLabels')}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
            multiple
            size="small"
            limitTags={3}
            filterSelectedOptions
          />
        </Box>
      </Box>
      <Box sx={{ pb: 4 }}>
        <Typography variant="caption">{t('pages.calendar.selectAreaOnChart')}</Typography>
      </Box>
      <CanvasJSChart ref={canvasRef} options={options} />
      {barOpen ? (
        <ChartPopover
          selectedBar={selectedBar}
          onClose={handleCloseBar}
          showDailyButton={true}
        />
      ) : null}
    </Box>
  )
}

export default memo(FullChart);

const setDataPointWidth = (min:Date | string, max:Date | string, initialDataPointWidth:number) => {
  let diff = dayjs(max).diff(dayjs(min), 'month');
  return diff < 12 ? (12 - diff + 1) * 2 : initialDataPointWidth;
}
