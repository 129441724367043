import { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Models
import { IAnnotation, IConversation } from 'app/models/ChatAI';
import { IPage } from '@root/models/Page';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createConversation, updateConversation } from 'app/store/AIChat/AIChat.async';
// Selectors
import { selectAIChatEligibleDocumentsAsOptions } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectLoading } from 'app/store/AIChat/AIChat.selectors';
// Mui
import { Alert, Box, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { OutlineBlock } from 'app/components/Utilities';
import { Input, Button, LoadingButton, Checkbox } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// Utilities
import { isMax, isMaxLength, isMin, isRequired } from 'app/utilities/Validations';

interface PageIds {
  documentId: number;
  page: number;
}

interface IFormData {
  name: string;
  settings: {
    ragMaxPages: number;
  };
  documentIds: number[];
  annotations?: IAnnotation[];
  private: boolean;
}

type Props = {
  open: boolean;
  onClose: () => void;
  conversation?: IConversation;
  pages?: IPage[];
  source?: 'workspace' | 'search';
};

const ConversationFormDialog:FC<Props> = ({
  // Props
  open, onClose, conversation, pages, source
}) => {
  const { t } = useTranslation();
  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const documentOptions = useAppSelector(selectAIChatEligibleDocumentsAsOptions);
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState:{ errors }, setValue, clearErrors, watch } = useForm<IFormData>({
    defaultValues: {
      name: conversation?.name || '',
      settings: {
        ragMaxPages: conversation?.settings?.ragMaxPages || 10
      },
      documentIds: conversation?.documentIds || [],
      annotations: conversation?.annotations || [],
      private: conversation ? conversation.private : true
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const { settings, documentIds, annotations, ...otherData } = data;
    const nextData:any = {
      ...otherData,
      settings: {
        ...settings,
        ragMaxPages: Number(settings.ragMaxPages)
      }
    };
    if ( documentIds && documentIds.length ) nextData['documentIds'] = documentIds;
    if ( annotations?.length ) nextData['annotations'] = annotations;
    if ( conversation?.id ){
      asyncUpdateConversation(conversation.id, nextData);
    } else {
      nextData['caseId'] = Number(caseId);
      asyncCreateConversation(nextData);
    }
  });

  const asyncCreateConversation = async (data:IFormData) => {
    try {
      const nextData:IFormData & { pageIds?:PageIds[] } = {...data};

      if (pages) nextData['pageIds'] = pages.map(p => ({ documentId: p.documentId, page: p.pageNum }));
      if (source) nextData['annotations'] = [{ name: 'source', value: source }];
  
      await dispatch(createConversation(nextData)).unwrap();
      onClose();
    } catch(e) {}
  }

  const asyncUpdateConversation = async (conversationId:string, data:IFormData) => {
    try {
      const nextData:IFormData & { pageIds?:PageIds[] } = {...data};
      if (conversation?.pageIds) nextData['pageIds'] = conversation?.pageIds;
      await dispatch(updateConversation({ conversationId, data: nextData })).unwrap();
      onClose();
    } catch(e) {}
  }

  const handleSelectAllDocumentsClick = () => {
    if ( !documentOptions ) return;

    setValue('documentIds', documentOptions.map(({ value }) => value));
    clearErrors('documentIds');
  }

  const getAnnotationSourceHelpetText = ():string => {
    if (!conversation || !conversation.annotations) return '';
    const sourceAnnotation = conversation.annotations.find(annotation => annotation.name === 'source');
    if (!sourceAnnotation) return '';
    if (sourceAnnotation.value === 'search') return t('pages.aiChat.searchSourceHelperText');
    if (sourceAnnotation.value === 'workspace') return t('pages.aiChat.workspaceSourceHelperText');
    return '';
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel conversation dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name={`${conversation ? 'update' : 'create'} conversation dialog`}
        loading={loading}
        type="submit"
        variant="contained"
        color="primary"
      >{conversation ? t('buttons.update') : t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  const isSourceWorkspace = source === 'workspace';
  const isSourceSearch = source === 'search';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={conversation ? t('pages.aiChat.updateFormTitle') : t('pages.aiChat.createFormTitle')}
      actions={actions}
      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      {!!pages && !conversation ? (
        <Fragment>
          <FormHelperText>{t('pages.aiChat.createConversationHelperText')}</FormHelperText>
          {isSourceWorkspace ? (
            <FormHelperText>{t('pages.aiChat.createConversationHelperTextWorkspace')}</FormHelperText>
          ) : null}
          {isSourceSearch ? (
            <FormHelperText>{t('pages.aiChat.createConversationHelperTextSearch')}</FormHelperText>
          ) : null}
        </Fragment>
      ) : null}
      {/* Name */}
      <Controller
        control={control} name="name"
        rules={{
          required: isRequired,
          maxLength: isMaxLength(50)
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.name')}
            required
            error={Boolean(errors.name)}
            helperText={errors.name?.message || ''}
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watch('name').length} / 50`}</FormHelperText>
              )
            }}
          />
        )}
      />

      {!pages && (
        <Fragment>
          <OutlineBlock sx={{ mt: 6 }} label={t('labels.settings')}>
            {/* Max */}
            <Controller
              control={control} name="settings.ragMaxPages"
              rules={{
                required: isRequired,
                min: isMin(1),
                max: isMax(100)
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('pages.aiChat.ragMaxPagesLabel')}
                  required
                  error={Boolean(errors.settings?.ragMaxPages)}
                  helperText={errors.settings?.ragMaxPages?.message || ''}
                />
              )}
            />
          </OutlineBlock>
          {!conversation?.pageIds && (
            <Fragment>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
                <Controller
                  control={control} name="documentIds"
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={documentOptions || []}
                      TextFieldProps={{
                        label: t('labels.documents'),
                        name: field.name,
                        required: true,
                        error: Boolean(errors.documentIds),
                        helperText: errors.documentIds?.message || ''
                      }}
                      groupBy={(option) => option.props.collection.name}
                      renderGroup={(params) => (
                        <Box key={params.key}>
                          <Box sx={{
                            bgcolor: 'rgb(238,238,238)',
                            color: 'rgba(0,0,0,0.87)',
                            fontWeight: 700,
                            py: 2,
                            px: 4
                          }}>{params.group}</Box>
                          {params.children}
                        </Box>
                      )}
                      multiple={true}
                      limitTags={3}
                      filterSelectedOptions
                      disableCloseOnSelect
                    />
                  )}
                />
                <Button
                  name="Select all documents"
                  sx={{ mt: 6.5 }}
                  onClick={handleSelectAllDocumentsClick}
                  variant="contained"
                >{t('buttons.all')}</Button>
              </Box>
              <FormHelperText>{t('pages.aiChat.documentsHint')}</FormHelperText>
            </Fragment>
          )}
        </Fragment>
      )}

      <Controller
        control={control} name="private"
        render={({ field:{ onChange, ...otherField }}) => (
          <Checkbox
            {...otherField}
            onChange={(_, checked:boolean) => onChange(checked)}
            label={t('labels.private')}
          />
        )}
      />
      <FormHelperText>{t('pages.aiChat.privateHint')}</FormHelperText>

      {getAnnotationSourceHelpetText() ? (
        <Alert sx={{ my: 4 }} severity="info">{getAnnotationSourceHelpetText()}</Alert>
      ) : null}
    </Dialog>
  )
}

export default ConversationFormDialog;
