// Types
import Reducers from 'app/types/Reducers';
// Actions
import { AppUiNotificationsActions } from 'app/store/AppUINotifications/AppUINotifications.slice';
// Messages
import Messages from "./Users.messages";
// Utilities
import asyncThunkHandler from "app/utilities/AsyncThunkHandler";
import { $get, $post, $put, $delete } from 'app/utilities/HttpClient';

const _url:string = '/users';

// Current user
// -------------------------------------------------
export const getCurrentUser = asyncThunkHandler(
  `${Reducers.Users}/Get current user`,
  async () => {
    const response:Response = await $get(`${_url}/self`);
    return response.json();
  }
);

export const updateCurrentUser = asyncThunkHandler(
  `${Reducers.Users}/Update current user`,
  async ({ args = {}, silence = false, data }:{ args?:RequestInit, silence?:boolean; data:any; }, { dispatch }) => {
    const response:Response = await $put(`${_url}/self`, data, args);
    if ( !silence ){
      dispatch(AppUiNotificationsActions.addSnackbar({
        message: Messages.ProfileUpdate
      }));
    }
    return response.json();
  }
);

export const sendEmailChangeVerificationLink = asyncThunkHandler(
  `${Reducers.Users}/Send email change verification link`,
  async (_, { dispatch }) => {
    const response:Response = await $post(`${_url}/self/actions/sendEmailChangeVerificationLink`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.SendEmailChangeVerificationLink
    }));
    return response.json();
  }
);

// -------------------------------------------------

export const resetPassword = asyncThunkHandler(
  `${Reducers.Users}/Reset password`,
  async (data:any, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/actions/resetPassword`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.ResetPassword
    }));
    return fulfillWithValue('Password reset link was sent to your email');
  }
);

export const updatePassword = asyncThunkHandler(
  `${Reducers.Users}/Update password`,
  async (data:any, { fulfillWithValue }) => {
    await $post(`${_url}/passwords`, data);
    return fulfillWithValue('Password was updated');
  }
);

export const getUsers = asyncThunkHandler(
  `${Reducers.Users}/Get users`,
  async (params:any) => {
    const response:Response = await $get(_url, params);
    return response.json();
  }
);

export const createUser = asyncThunkHandler(
  `${Reducers.Users}/Create user`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(_url, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Create
    }));
    return response.json();
  }
);

export const updateUser = asyncThunkHandler(
  `${Reducers.Users}/Update user`,
  async ({ userId, data }:{ userId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${userId}`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Update
    }));
    return response.json();
  }
);

export const importClientUser = asyncThunkHandler(
  `${Reducers.Users}/Import client user`,
  async (data:any, { dispatch }) => {
    const response:Response = await $post(`${_url}/actions/importClient`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Import
    }));
    return response.json();
  }
);

export const deleteUser = asyncThunkHandler(
  `${Reducers.Users}/Delete user`,
  async (userId:number, { dispatch, fulfillWithValue }) => {
    await $delete(`${_url}/${userId}`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Delete
    }));
    return fulfillWithValue(userId)
  }
);

export const updateUserMFA = asyncThunkHandler(
  `${Reducers.Users}/Update user MFA`,
  async ({ userId, data }:{ userId:number, data:any }, { dispatch }) => {
    const response:Response = await $put(`${_url}/${userId}/mfa`, data);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: data.enabled ? Messages.AuthenticationEnabled : Messages.AuthenticationDisabled
    }));
    return response.json();
  }
);

export const enableUser = asyncThunkHandler(
  `${Reducers.Users}/Enable user`,
  async (userId:number, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/${userId}/actions/enable`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Enable
    }));
    return fulfillWithValue(userId);
  }
);

export const disableUser = asyncThunkHandler(
  `${Reducers.Users}/Disable user`,
  async (userId:number, { dispatch, fulfillWithValue }) => {
    await $post(`${_url}/${userId}/actions/disable`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.Disable
    }));
    return fulfillWithValue(userId);
  }
);

export const acceptTermsAndConditions = asyncThunkHandler(
  `${Reducers.Users}/Accept terms and conditions`,
  async (termsAndConditionsId:number, { fulfillWithValue }) => {
    await $post(`${_url}/termsAndConditions/${termsAndConditionsId}/actions/ack`);
    return fulfillWithValue(termsAndConditionsId);
  }
);

export const verifyEmail = asyncThunkHandler(
  `${Reducers.Users}/Verify email`,
  async (token:string, { fulfillWithValue }) => {
    await $post(`${_url}/actions/verifyEmail`, { token });
    return fulfillWithValue('Email was verified');
  }
);

export const sendUserEmailChangeVerificationLink = asyncThunkHandler(
  `${Reducers.Users}/Send user email change verification link`,
  async (userId:number, { dispatch }) => {
    const response:Response = await $post(`${_url}/${userId}/actions/sendEmailChangeVerificationLink`);
    dispatch(AppUiNotificationsActions.addSnackbar({
      message: Messages.SendEmailChangeVerificationLink
    }));
    return response.json();
  }
);
