import { FC, Fragment } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectIds } from 'app/store/BillingServices/BillingServices.selectors';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import ServicesListItem from './ServicesListItem';
// i18next
import { useTranslation } from 'react-i18next';

const OrdersList:FC = () => {
  const { t } = useTranslation();
  // State
  const ids:number[] | null = useAppSelector(selectIds);

  if ( !ids ) return <Loader />;
  if ( !ids.length ) return <Message text={t('pages.billingServices.noData')} />;
  return (
    <Fragment>
      {ids.map((id:number) => (
        <ServicesListItem
          key={`service-item-${id}`}
          serviceId={id}
        />
      ))}
    </Fragment>
  )
}

export default OrdersList;
