import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createReport, updateReport } from 'app/store/CaseReports/CaseReports.async';
// Selectors
import { selectLoading } from 'app/store/CaseReports/CaseReports.selectors';
// Mui
import { Button, FormGroup, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Switch, LoadingButton } from 'app/components/Mui';
// Utility
import { isRequired, isMaxLength } from 'app/utilities/Validations';
import { useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
  author: boolean;
  date: boolean;
  episodeHyperlinks: boolean;
  documentName: boolean;
  timelineChart: boolean;
};

type Props = {
  open: boolean;
  onClose: () => void;
  insuranceCaseId?: number;
  report?: any;
}

const ReportFormDialog:FC<Props> = ({
  // Props
  open, onClose, insuranceCaseId, report
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState: { errors }, watch } = useForm<IFormData>({
    defaultValues: {
      name: report?.name || '',
      author: true,
      date: true,
      episodeHyperlinks: true,
      documentName: false,
      timelineChart: true
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const { name, ...options } = data;
    const nextData:any = { name };
    if ( report?.id ){
      asyncUpdateReport(report.id, nextData);
    } else {
      if ( insuranceCaseId ) nextData['insuranceCaseId'] = insuranceCaseId;
      nextData['options'] = options;
      asyncCreateReport(nextData);
    }
  });

  const asyncCreateReport = async (data:any) => {
    try {
      await dispatch(createReport(data)).unwrap();
      onClose();
    } catch(error){}
  }

  const asyncUpdateReport = async (reportId:number, data:any) => {
    try {
      await dispatch(updateReport({ reportId, data })).unwrap();
      onClose();
    } catch(error){}
  }

  const watchedName = watch('name');

  const switchers = [
    { name: 'author', label: t('labels.authorName') },
    { name: 'date', label: t('labels.episodeDate') },
    { name: 'episodeHyperlinks', label: t('labels.episodesHyperlink') },
    { name: 'documentName', label: t('labels.documentName') },
    { name: 'timelineChart', label: t('labels.timelinePreview') }
  ];

  const actions = (
    <Fragment>
      <Button
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name={`${report ? 'update' : 'create'} report dialog`}
        loading={loading}
        type="submit"
        variant="contained"
        color="primary"
      >{report ? t('buttons.update') : t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={report ? t('dialogs.reportForm.updateFormTitle') : t('dialogs.reportForm.createFormTitle')}
      actions={actions}
      maxWidth="sm"

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      {/* Name */}
      <Controller
        control={control} name="name"
        rules={{
          required: isRequired,
          maxLength: isMaxLength(100)
        }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.name')}
            error={Boolean(errors.name)}
            helperText={errors.name?.message || ''}
            required
            InputProps={{
              endAdornment: (
                <FormHelperText
                  sx={{ p: 0, whiteSpace: 'nowrap' }}
                >{`${watchedName.length} / 100`}</FormHelperText>
              )
            }}
          />
        )}
      />

      {!report ? (
        <FormGroup>
          {switchers.map((switcher:any) => (
            <Controller
              key={`switch-item-${switcher.name}`}
              control={control} name={switcher.name}
              render={({ field:{ onChange, ...otherField } }) => (
                <Switch
                  {...otherField}
                  label={switcher.label}
                  onChange={(_, checked:boolean) => onChange(checked)}
                />
              )}
            />
          ))}
        </FormGroup>
      ) : null}
    </Dialog>
  )
}

export default ReportFormDialog;
