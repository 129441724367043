import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getAuthors } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// Actions
import { EpisodeAuthorsActions } from 'app/store/EpisodeAuthors/EpisodeAuthors.slice';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
//
import AuthorRegistryFilter from './AuthorRegistryFilter';
import AuthorRegistryList from './AuthorRegistryList';
import AuthorRegistryPagination from './AuthorRegistryPagination';
// i18next
import { useTranslation } from 'react-i18next';

const AuthorRegistryPage:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAuthors({}));
    return () => {
      dispatch(EpisodeAuthorsActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.authorRegistry.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ py: 4 }} maxWidth="sm">
          <AuthorRegistryFilter />
          <AuthorRegistryList />
          <AuthorRegistryPagination />
        </Container>
      </Box>
    </Fragment>
  )
}

export default AuthorRegistryPage;
