import { FC } from 'react';
// Models
import { IBillingOrderLine } from 'app/models/BillingOrder';
// Components
import { InfoBlock } from 'app/components/Utilities';

type Props = {
  line: IBillingOrderLine
};

const OrderLineItem:FC<Props> = ({
  // Props
  line
}) => {
  if ( !line.service ) return null;
  return (
    <InfoBlock label={line.service.name} value={line.quantity} />
  )
}

export default OrderLineItem;
