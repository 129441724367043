import { FC, useMemo } from 'react';
// Models
import { IUsage } from 'app/store/BillingUsage/BillingUsage.models';
// Mui
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Theme } from '@mui/material';
// Utils
import { toNumberWithCommas } from 'app/utilities/Utilities';
import InvoiceNumberChips from './InvoiceNumberChips';
import InvoiceDraftIcon from './InvoiceDraftIcon';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  data: IUsage[];
};

const UsageGroupByService:FC<Props> = ({
  // Props
  data
}) => {
  const { t } = useTranslation();

  const totalAmount = useMemo(() => {
    return data.reduce((acc:number, cur) => acc + cur.amount, 0)
  }, [data]);

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('labels.serviceName')}</TableCell>
            <TableCell sx={{ width: '50%' }}>{t('labels.invoices')}</TableCell>
            <TableCell>{t('labels.qty')}</TableCell>
            <TableCell>{t('labels.total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((usage, index) => (
            <TableRow key={`tableRow-${usage.service?.id}-${index}`}>
              <TableCell>{usage.service?.name}</TableCell>
              <TableCell><InvoiceNumberChips invoices={usage.invoices || []} /></TableCell>
              <TableCell>{usage.quantity}</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <span>{`$${toNumberWithCommas(usage.amount)}`}</span>
                  <InvoiceDraftIcon invoices={usage.invoices} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
          <TableRow sx={{
            bgcolor: (theme:Theme) => theme.palette.grey[50],
            '& > td': {
              borderBottom: 0
            }
          }}>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }} colSpan={3}>{t('labels.total')}</TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }}>{`$${toNumberWithCommas(totalAmount)}`}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UsageGroupByService;