import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Models
import IUser from 'app/models/User';
// Mui
import { FormGroup } from '@mui/material';
// Components
import { Switch } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  user: IUser | undefined;
}

const FormPreferencesTogglers:FC<Props> = ({
  // Props
  user
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  if ( !user || !user.id ) return null;
  return (
    <Fragment>
      <OutlineBlock sx={{ mt: 6 }} label={t('labels.notifications')}>
        <FormGroup>
          <Controller
            control={control} name="notificationPreferences.sms"
            render={({ field:{ onChange, ...otherField }}) => (
              <Switch
                {...otherField}
                label={t('labels.sms')}
                onChange={(_, checked:boolean) => onChange(checked)}
              />
            )}
          />
          <Controller
            control={control} name="notificationPreferences.email"
            render={({ field:{ onChange, ...otherField }}) => (
              <Switch
                {...otherField}
                label={t('labels.email')}
                onChange={(_, checked:boolean) => onChange(checked)}
              />
            )}
          />
          <Controller
            control={control} name="notificationPreferences.push"
            render={({ field:{ onChange, ...otherField }}) => (
              <Switch
                {...otherField}
                label={t('labels.app')}
                onChange={(_, checked:boolean) => onChange(checked)}
              />
            )}
          />
        </FormGroup>
      </OutlineBlock>
    </Fragment>
  )
}

export default FormPreferencesTogglers;
