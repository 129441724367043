import { Fragment, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
import { ICurrentAccount } from 'app/models/Account';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { logout } from 'app/store/Auth/Auth.async';
import { updateCurrentUser } from 'app/store/Users/Users.async';
// Actions
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';
import { setInitialField } from 'app/store/currentUser/currentUserSlice';
// Selectors
import { selectLanguage, selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectCurrentAccount } from 'app/store/Accounts/Accounts.selectors';
// Mui
import {
  Theme, Divider,
  Box, Avatar, Drawer, Typography, List, ListItemButton, ListItemText
} from '@mui/material';
// Icons
import { Close as CloseIcon } from '@mui/icons-material';
// Components
import { Button, IconButton } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// Services
import LocalStorageService from 'app/services/LocalStorage.service';
// 
import SwitchAccountButton from './SwitchAccountButton';
import AccountManagerMenuItems from './AccountManagerMenuItems';
import AdminMenuItems from './AdminMenuItems';
import StaffMenuItems from './StaffMenuItems';
import PresenterMenuItems from './PresenterMenuItems';
// ToDO
import PageActions from 'app/store/page/page.actions';
// End: TODO

const MyUserDrawer = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const language = useAppSelector(selectLanguage);
  const trialMode = useAppSelector(selectTrialMode);
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  const currentAccount:ICurrentAccount | null = useAppSelector(selectCurrentAccount);

  const { open, toggle } = useToggle();

  const [ firstLetter, secondLetter ] = useMemo(() => {
    if ( !myUser ) return [];
    const [ firstName, lastName ] = myUser.name.split(' ');
    if ( !lastName ) return [firstName[0], ''];
    return [firstName[0], lastName[0]];
  }, [myUser]);

  useEffect(() => {
    if ( !myUser ) return;

    if (
      LocalStorageService.hasLanguage() &&
      myUser.language !== language
    ){
      dispatch(updateCurrentUser({ data: { ...myUser, language }, silence: true })).unwrap();
    } else {
      dispatch(AppUIActions.setLanguage(myUser.language));
    }
    // eslint-disable-next-line
  }, [myUser, language]);

  const handleClick = () => {
    dispatch(AppUIActions.toggleTrialMode());
    // ToDO
    dispatch(PageActions.setInitialField('filter'));
    dispatch(setInitialField('filter'));
    // End: ToDO
    toggle();
  }

  const handleClickLogout = () => {
    dispatch(logout());
  }

  if ( !myUser ) return null;
  return (
    <Fragment>
      <Divider sx={{ height: 40, mx: 3 }} orientation="vertical" />
      <Box
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={toggle}
      >
        <Avatar
          sx={{
            bgcolor: (theme:Theme) => theme.palette.primary.main,
          }}
          children={`${firstLetter}${secondLetter}`}
        />
      </Box>
      <Drawer
        aria-hidden="false"
        PaperProps={{
          sx: {
            width: 400,
            border: 'none'
          }
        }}
        open={open}
        onClose={toggle}
        anchor="right"
        elevation={0}
      >
        <IconButton
          name="Close user drawer"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8
          }}
          onClick={toggle}
          size="small"
        ><CloseIcon fontSize="small" /></IconButton>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexShrink: 0,
          // gap: 4,
          borderBottom: `1px solid rgba(0,0,0,0.08)`,
          p: 4,
          pt: 8,
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          {currentAccount?.company ? (
            <Typography variant="caption">{currentAccount.company}</Typography>
          ) : null}
          <Typography variant="h6" noWrap>{myUser.name}</Typography>
          <Typography sx={{ textTransform: 'capitalize' }} color="GrayText" variant="body2" noWrap>
            {myUser.role === UserRoles.AccountManager ? 'Account Manager' : myUser.role}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <List>
            {trialMode ? (
              <ListItemButton
                onClick={handleClick}
                sx={{ textTransform: 'capitalize' }}
              ><ListItemText primary={t('components.userDrawer.exitTrialMode')} /></ListItemButton>
            ) : (
              <Fragment>
                <AccountManagerMenuItems onClose={toggle} />
                <AdminMenuItems onClose={toggle} />
                <StaffMenuItems onClose={toggle} />
                <PresenterMenuItems onClose={toggle} />
                {myUser.role !== UserRoles.Client ? (
                  <ListItemButton
                    component={Link}
                    to="/profile"
                    onClick={toggle}
                    sx={{ textTransform: 'capitalize' }}
                  ><ListItemText primary={t('components.userDrawer.myProfile')} /></ListItemButton>
                ) : null}
                {myUser.role !== UserRoles.AccountManager ? (
                  <ListItemButton
                    component={Link}
                    to="/my-terms-and-conditions"
                    onClick={toggle}
                    sx={{ textTransform: 'capitalize' }}
                  ><ListItemText primary={t('components.userDrawer.myTermsAndConditions')} /></ListItemButton>
                ) : null}
              </Fragment>
            )}
          </List>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, p: 4 }}>
            <SwitchAccountButton />
            <Button
              name="Logout"
              onClick={handleClickLogout}
              fullWidth
              variant="outlined"
            >{t('components.userDrawer.logout')}</Button>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  )
}

export default MyUserDrawer;
