import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Models
import { ISubscriptionItem } from 'app/store/Subscriptions/Subscriptions.models';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createOrder, updateOrder } from 'app/store/BillingOrders/BillingOrders.async';
// Selectors
import { selectSubscriptionsWithAdditionalItems } from 'app/store/BillingSubscriptions/BillingSubscriptions.selectors';
import { selectLoading } from 'app/store/BillingOrders/BillingOrders.selectors';
// Mui
import { Button, Box, Divider, Typography, Tooltip, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// Components
import Icon from 'app/components/Icon';
import Dialog from 'app/components/Dialog';
import { Input, Select } from 'app/components/Mui';
import { InfoBlock, Message } from 'app/components/Utilities';
// Utility
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';
interface IFormData {
  description: string;
  lines: any;
};

type Props = {
  open: boolean;
  onClose: () => void;
  order?: any;
};

const OrderFormDialog:FC<Props> = ({
  // Props
  open, onClose, order
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);
  const subscriptions = useAppSelector(selectSubscriptionsWithAdditionalItems);

  const [ selectedSubscriptionId, setSelectedSubscriptionId ] = useState<number | string>('');
  const [ subscriptionItems, setSubscriptionItems ] = useState<ISubscriptionItem[] | null>(null);
  const [ selectedServiceId, setSelectedServiceId ] = useState<number | string>('');

  const { register, control, handleSubmit, formState: { errors, isSubmitted }, setValue } = useForm<IFormData>({
    defaultValues: {
      description: order?.description || '',
      lines: order?.lines || ''
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lines'
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    if ( !data.lines.length ) return;

    const orderData:any = {
      description: data.description,
      lines: data.lines.map((line:any) => ({
        serviceId: Number(line.service.id),
        quantity: Number(line.quantity)
      }))
    };
    if ( order && order.id ){
      asyncUpdateOrder({ orderId: order.id, data: orderData });
    } else {
      if ( !selectedSubscriptionId ) return;
      orderData['caseId'] = Number(caseId);
      orderData['subscriptionId'] = selectedSubscriptionId;
      asyncCreateOrder(orderData);
    }
  });

  const asyncUpdateOrder = async (nextData:any) => {
    try {
      await dispatch(updateOrder(nextData)).unwrap();
      onClose();
    } catch(e) {}
  }

  const asyncCreateOrder = async (data:IFormData) => {
    try {
      await dispatch(createOrder(data)).unwrap();
      onClose();
    } catch(e) {}
  }

  useEffect(() => {
    if ( !order ) return;
    const foundedSubscription = subscriptions.find((subscription) => subscription.id === order.subscriptionId);
    if ( !foundedSubscription ) return;
    setSubscriptionItems(foundedSubscription.items);
    // eslint-disable-next-line
  }, [])

  const handleSubscriptionChange = (event:ChangeEvent<HTMLInputElement>) => {
    const subscriptionId = Number(event.target.value);
    setSelectedSubscriptionId(subscriptionId);
    const foundedSubscription = subscriptions.find((subscription) => subscription.id === subscriptionId);
    if ( !foundedSubscription ) return;
    setSubscriptionItems(foundedSubscription.items);
    setSelectedServiceId('');
    setValue('lines', []);
  }

  const handleServiceChange = (event:ChangeEvent<HTMLInputElement>) => {
    setSelectedServiceId(Number(event.target.value));
  }

  const handleAddClick = () => {
    if ( !selectedServiceId || !subscriptionItems ) return;
    const foundedSubscriptionItem = subscriptionItems.find((subscriptionItem) => subscriptionItem.service.id === selectedServiceId);
    if ( !foundedSubscriptionItem ) return;
    append({ service: foundedSubscriptionItem.service, price: foundedSubscriptionItem.price, quantity: 0 });
    setSelectedServiceId('');
  }

  const handleRemoveClick = (index:number) => () => {
    remove(index);
  }

  const addedServiceIds = fields.map((field:any) => field.service.id);
  const availableSubscriptionItemOptions = subscriptionItems
    ? subscriptionItems.filter((subscriptionItem) => !addedServiceIds.includes(subscriptionItem.service.id))
    : []
  ;

  const disabledAddButton = !selectedServiceId;
  const subscriptionError = isSubmitted && !selectedSubscriptionId;
  const serviceError = isSubmitted && fields.length === 0;

  const getErrorMessage = (index:number, field:string) => {
    // @ts-ignore
    return errors.lines && errors.lines[index] && errors.lines[index][field] ? errors.lines[index][field].message : ''
  }

  const actions = (
    <Fragment>
      <Button
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={order && order.id ? t('pages.orders.updateFormTitle') : t('pages.orders.createFormTitle')}
      actions={actions}
    >
      <form noValidate>
        {/* Description */}
        <Controller
          control={control} name="description"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.description')}
              error={Boolean(errors.description)}
              helperText={errors.description?.message || ''}
              required
              multiline
              rows={5}
            />
          )}
        />

        {/* Subscription selector */}
        {!order ? (
          subscriptions.length ? (
            <Select
              label={t('labels.subscription')}
              value={selectedSubscriptionId} defaultValue=""
              onChange={handleSubscriptionChange}
              options={subscriptions.map((subscription) => ({
                id: subscription.id,
                name: subscription.subscriptionPackage.name
              }))}
              error={subscriptionError}
              helperText={subscriptionError ? isRequired : ''}
            />
          ) : (
            <Message text={t('pages.orders.noSubscriptionData')} />
          )
        ) : null}

        {(!order && !selectedSubscriptionId) ? null : (
          <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
              <Select
                label={t('labels.service')}
                value={selectedServiceId} defaultValue=""
                onChange={handleServiceChange}
                options={availableSubscriptionItemOptions.map((subscriptionItem) => ({
                  id: subscriptionItem.service.id,
                  name: subscriptionItem.service.name
                }))}
                error={serviceError}
                helperText={serviceError ? t('pages.orders.serviceHelperText') : ''}
              />
              <Button
                sx={{ mt: 6 }}
                disabled={disabledAddButton}
                onClick={handleAddClick}
                variant="contained"
              >{t('buttons.add')}</Button>
            </Box>
            {fields.length ? (
              <Divider sx={{ my: 4 }} />
            ) : null}
            {fields.map((field:any, index:number) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  pb: 4,
                  '& + &': {
                    pt: 4,
                    borderTop: '1px solid rgba(0,0,0,0.08)'
                  }
                }}
                key={`service-item-${field.id}`}
              >
                <input
                  {...register(`lines.${index}.service.id`)}
                  type="hidden"
                  value={field.service.id}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="subtitle2"
                  >{field.service.name}</Typography>
                  {field.service.description ? (
                    <Typography variant="body2" color="GrayText">{field.service.description}</Typography>
                  ) : null}
                  <InfoBlock label={t('labels.price')} value={`$${field.price}`} direction="row" />
                </Box>
                <Controller
                  control={control}
                  name={`lines.${index}.quantity`}
                  defaultValue={field.quantity}
                  rules={{ required: isRequired }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label={t('labels.quantity')} type="number"
                      error={Boolean(getErrorMessage(index, 'quantity'))}
                      helperText={getErrorMessage(index, 'quantity')}
                      required
                      margin="none"
                      sx={{ maxWidth: 220 }}
                    />
                  )}
                />
                <Tooltip title={t('pages.orders.deleteLineTooltip')}>
                  <IconButton
                    sx={{ alignSelf: 'center' }}
                    onClick={handleRemoveClick(index)}
                  ><Icon icon="delete" /></IconButton>
                </Tooltip>
              </Box>
            ))}
          </Fragment>
        )}
      </form>
    </Dialog>
  );
}

export default OrderFormDialog;
