import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Types
import { TemplateDataType, TemplateLayout, TemplateType } from 'app/types/Template';
// Mui
import { Box, InputLabel } from '@mui/material';
// Components
import { Editor, Toolbar } from 'app/components/QuillEditor';
import { Loader } from 'app/components/Utilities';
//
import EditorPlaceholder from './EditorPlaceholder';
import EditorSettings from './EditorSettings';
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import TableConfigColumns from './TableConfigColumns';
import TableConfigToolbars from './TableConfigToolbars';

type ContentIds = 'preContent' | 'content' | 'postContent';

type Props = {
  rendered: boolean;
}

const DialogContainer:FC<Props> = ({
  // Props
  rendered
}) => {
  const { t } = useTranslation();

  const preContentId:ContentIds = 'preContent';
  const contentId:ContentIds = 'content';
  const postContenteId:ContentIds = 'postContent';

  const [ activeEditorId, setActiveEditorId ] = useState<string | null>(null);

  const { formState:{ errors }, watch } = useFormContext();

  const watchType = watch('type');
  const watchDataType = watch('dataType');
  const watchLayout = watch('layout');

  const isTypeText = watchType === TemplateType.Text;
  const isDataTypeEpisode = watchDataType === TemplateDataType.Episode;
  const isLayoutChart = watchLayout === TemplateLayout.Chart;
  const isLayoutTable = watchLayout === TemplateLayout.Table;
  const isLayoutCustom = watchLayout === TemplateLayout.Custom;

  const isLayoutChartOrTable = isLayoutChart || isLayoutTable;

  const isEpisodeCustom = isDataTypeEpisode && isLayoutCustom;

  useEffect(() => {
    const editorId = isLayoutChartOrTable ? preContentId : contentId;
    setActiveEditorId(editorId as string);
    // eslint-disable-next-line
  }, [watchType, watchLayout])

  const handleFocus = (editorId:string) => () => {
    setActiveEditorId(editorId);
  }

  const editors = useMemo(() => ([
    {
      id: preContentId,
      label: t('labels.preContent'),
      visible: !isTypeText,
      required: false
    },
    {
      id: contentId,
      label: isDataTypeEpisode ? t('labels.episodeTemplate') : t('labels.content'),
      visible: !isLayoutChartOrTable,
      required: isTypeText || isLayoutCustom
    },
    {
      id: postContenteId,
      label: t('labels.postContent'),
      visible: !isTypeText,
      required: false
    }
    // eslint-disable-next-line
  ]), [isTypeText, isDataTypeEpisode, isLayoutCustom, isLayoutTable, isLayoutChartOrTable, i18n.language]);

  if ( !rendered ) return <Loader />;
  return (
    <Fragment>
      {editors.map((editor) => {
        if ( !editor.visible ) return null;
        return (
          <Box
            key={`toolbar item ${editor.id}`}
            sx={{
              display: activeEditorId === editor.id ? 'block' : 'none',
              borderBottom: '1px solid rgba(0,0,0,0.08)',
              px: 6,
              py: 2
            }}
          >
            <Toolbar
              id={editor.id as string}
              dataType={watchDataType}
              layout={watchLayout}
              isLinkVariableVisible={true}
              isVariableVisible={true}
              isTabStopVisible={true}
              isPageBreakVisible={true}
            />
          </Box>
        )
      })}
      <TableConfigToolbars />
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        overflowY: 'auto'
      }}>
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          bgcolor: '#f7f7f7',
          py: 4,
          px: 6,
          overflow: 'hidden'
        }}>
          {editors.map((editor:any) => {
            const isEditorContent = editor.id === 'content';
            const isEditorPreOrPostContent = editor.id === 'preContent' || editor.id === 'postContent';

            const height = isEditorPreOrPostContent
              ? '35%'
              : isLayoutCustom ? '30%' : '100%'
            ;

            return (
              <Fragment key={`editor item ${editor.id}`}>
                {isEditorContent ? (
                  isLayoutTable ? <TableConfigColumns activeEditorId={activeEditorId} onFocus={setActiveEditorId} /> : <EditorPlaceholder />
                ) : null}
                {editor.visible ? (
                  <Controller
                    name={editor.id}
                    rules={{ required: editor.required ? isRequired : false }}
                    render={({ field }) => (
                      <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%',
                        maxWidth: 1280,
                        height,
                        mx: 'auto'
                      }}>
                        {isEditorContent && isEpisodeCustom ? (
                          <InputLabel sx={{ pl: 4, mb: 2 }}>{t('dialogs.templateForm.templateHint')}</InputLabel>
                        ) : null}
                        <Box
                          sx={{
                            height: isEditorContent && isEpisodeCustom ? '50%' : '100%'
                          }}
                        >
                          <Editor
                            {...field}
                            label={editor.label} id={editor.id}
                            required={editor.required}
                            error={Boolean(errors[editor.id])}
                            helperText={errors[editor.id]?.message || ''}
                            onFocus={handleFocus(editor.id)}
                          />
                        </Box>
                      </Box>
                    )}
                  />
                ) : null}
              </Fragment>
            )
          })}
        </Box>
        <EditorSettings />
      </Box>
    </Fragment>
  )
}

export default DialogContainer;
