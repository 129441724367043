import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
// Mui
import { Box, FormControl, InputLabel, Paper } from '@mui/material';
// Components
import QuillEditor from 'app/components/QuillEditor';

type Props = {
  episodeId?: number;
  isDisabled: boolean;
}

const EpisodeFormNotes:FC<Props> = ({ episodeId, isDisabled }) => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext();

  const watchNotes = watch('notes');

  if ( isDisabled ) return (
    <FormControl sx={{ width: '100%', mb: 2 }}>
      <InputLabel shrink={true} disabled={true} sx={{
        bgcolor: 'white',
        px: '6px',
        ml: '-6px'
      }}>{t('labels.notes')}</InputLabel>
      <Paper
        sx={{
          py: 2,
          px: 4,
          borderColor: 'rgba(189,189,189)',
          color: 'rgba(0, 0, 0, 0.38)',
          minHeight: 360,
          overflowY: 'auto'
        }}
        className="ql-editor"
        variant="outlined"
        dangerouslySetInnerHTML={{ __html: watchNotes }}
      />
    </FormControl>
  );
  return (
    <Box sx={{ mb: 2 }} className="quill-wrapper">
      <Controller
        control={control} name="notes"
        render={({ field }) => (
          <Fragment>
            <QuillEditor
              {...field}
              id={episodeId ? `episode-update-${episodeId}` : `episode-create`}
              label={t('labels.notes')}
              height={360}

              isFullScreenVisible={true}
            />
          </Fragment>
        )}
      />
    </Box>
  )
}

export default EpisodeFormNotes;
