import { useCallback } from "react";
// Models
import { IValidateError, ValidateErrorCodes } from "app/store/DMSBatches/DMSBatches.models";

const DUPLICATE_ERROR_CODES = [
  ValidateErrorCodes.DuplicateName,
  ValidateErrorCodes.DuplicateContent,
];

const useErrorsHandler = () => {
  const removeReferencesFromErrors = (errors: IValidateError[], fileItemId: string):void => {
    // Iterate over errors and remove the incoming fileItemId from references for the duplicate error codes
    for ( const error of errors ) {
      if ( DUPLICATE_ERROR_CODES.includes(error.errorCode) ) {
        error.references = error.references?.filter(ref => ref !== fileItemId) ?? [];
      }
    }
  }
  
  const clearEmptyReferences = (errors: IValidateError[]):void => {
    // Clear references for DuplicateName and DuplicateContent errors if references are empty
    for ( const error of errors ) {
      if ( DUPLICATE_ERROR_CODES.includes(error.errorCode) && error.references?.length === 0 ) {
        error.references = [];
      }
    }
  }
  
  const replaceArrayWithEmptyIfNeeded = (errors: IValidateError[]):void => {
    for (let i = errors.length - 1; i >= 0; i--) {
      const error = errors[i];
      if ( error.references && error.references.length === 0 ){
        errors.splice(i, 1); // Remove error completely if references.length === 0
      }
    }
  }

  const transformErrorsToMap = useCallback((
    errors:IValidateError[][],
    fileIdsByIndexes:string[]
  ):Map<string, IValidateError[]> => {
    return errors.reduce((acc:Map<string, IValidateError[]>, cur:IValidateError[], index:number) => {
      const id = fileIdsByIndexes[index];
      if ( !cur.length ) {
        acc.set(id, []);
      } else {
        acc.set(id, cur.map((error: IValidateError) => {
          if ( DUPLICATE_ERROR_CODES.includes(error.errorCode) && error.references ) return {
            ...error,
            references: error.references.map((refIndex) => fileIdsByIndexes[refIndex as any])
          };
          return error;
        }));
      }
      return acc;
    }, new Map());
  }, []);

  const handleRemoveErrorAndReferences = useCallback((validateErrors:Map<string, IValidateError[]>, fileItemId: string):Map<string, IValidateError[]> => {
    // Step 1: Iterate over all file items and remove references for duplicateName and duplicateContent where references contain the incoming fileItemId
    validateErrors.forEach((errors) => {
      if ( errors ) removeReferencesFromErrors(errors, fileItemId);
    });

    // Step 2: Clear empty references for duplicateName and duplicateContent across all file items
    validateErrors.forEach((errors) => {
      if ( errors ) clearEmptyReferences(errors);
    });

    // Step 3: Replace the entire array with an empty array if all errors in the array have empty references
    validateErrors.forEach((errors) => {
      if ( errors ) replaceArrayWithEmptyIfNeeded(errors);
    });

    validateErrors.delete(fileItemId);
    // Step 4: Return the updated object with the cleaned up errors
    return validateErrors;
  }, []);

  return {
    transformErrorsToMap,
    handleRemoveErrorAndReferences
  };
};

export default useErrorsHandler;
