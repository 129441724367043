import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { RootState } from 'app/store';
import { IFileItem } from '../UploadProvider/useUploadContext';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFileFormatByMimeType } from 'app/store/Settings/Settings.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Utilities
import { toSize } from 'app/utilities/Utilities';
// Hooks
import { InfoBlock } from 'app/components/Utilities';

type Props = {
  fileItem: IFileItem;
}

const FileItemText:FC<Props> = ({
  // Props
  fileItem
}) => {
  const { t } = useTranslation();

  // State
  const fileFormat = useAppSelector((state:RootState) => selectFileFormatByMimeType(state, {
    mimeType: fileItem.file.type
  }));

  const renderFileExt = fileFormat ? (
    <InfoBlock label={t('labels.format')} value={
      <Typography
        sx={{ display: 'block', color: 'rgba(0,0,0,0.75)', fontSize: 12, fontWeight: 700, textTransform: 'uppercase' }}
        variant="caption"
      >{fileFormat}</Typography>
    } direction="row" />
  ) : null;
  const renderFileSize = fileItem.file.size ? (
    <InfoBlock label={t('labels.size')} value={toSize(fileItem.file.size)} direction="row" />
  ) : null;

  return (
    <Fragment>
      <Typography variant="subtitle2" noWrap title={fileItem.file.name}>{fileItem.file.name}</Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {renderFileExt}
        {renderFileSize}
      </Box>
      {fileItem.comment ? (
        <Typography color="CaptionText" variant="body2">{`${t('labels.comment')}: ${fileItem.comment}`}</Typography>
      ) : null}
    </Fragment>
  )
}

export default FileItemText;
