import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import IEpisodeFact from 'app/models/EpisodeFact';
// Models
import { RootState } from 'app/store';
// Selectors
import { selectCaseMedicareRate } from 'app/store/Cases/Cases.selectors';
import { selectSelectedEpisodeId } from 'app/store/Episodes/Episodes.selectors';
// Utilities
import { toNumberWithCommas } from 'app/utilities/Utilities';
import { sortByCreationDate } from 'app/utilities/SortBy';

export const selectFacts = (state:RootState) => state[Reducers.EpisodeFacts].facts;
export const selectEditableFactIds = (state:RootState) => state[Reducers.EpisodeFacts].editableFactIds;
export const selectLoading = (state:RootState) => state[Reducers.EpisodeFacts].loading;

export const selectIsFactEditable = createSelector(
  [
    selectEditableFactIds,
    (_, props:{ factId:number }) => props
  ],
  (editableFactIds:number[], { factId }) => editableFactIds.includes(factId)
);

export const selectSortedEpisodeFacts = createSelector(
  [
    selectSelectedEpisodeId,
    selectFacts
  ],
  (selectedEpisodeId:number | null, facts:IEpisodeFact[] | null) => {
    if ( !facts || selectedEpisodeId === null ) return null;
    return facts
      .filter((fact:IEpisodeFact) => fact.episodeId === selectedEpisodeId)
      .sort((a, b) => sortByCreationDate(a, b, 'desc'))
  }
);

export const selectEpisodeFactsWithNegativeId = createSelector(
  [ selectSortedEpisodeFacts ],
  ( facts:IEpisodeFact[] | null ) => {
    if ( !facts ) return null;
    return facts.filter((fact:IEpisodeFact) => fact.id < 0)
  }
);

export const selectEpisodeFactsDataAsString = createSelector(
  [
    selectCaseMedicareRate,
    selectSortedEpisodeFacts
  ],
  (caseMedicareRate:number, facts:IEpisodeFact[] | null) => {
    if ( !facts || !facts.length ) return '';
    let result:string = 'CPT/HCPCS From www.cms.gov. Default to Non-facility / Limiting Charge. \n\n';

    for ( let fact of facts ){
      const factString = [
        `CPT Code: ${fact.data.hcpcsCode} ${fact.data.modifier ? `${fact.data.modifier} ` : ''}`,
        `${fact.data.shortDescription || ''} `,
        `${fact.data && fact.data.carrierNumber ? `Locality: ${fact.data.carrierNumber} ` : ''}`,
        `${fact.data && fact.data.year ? `Year: ${fact.data.year} ` : ''}`,
        ` ${fact.data.vaRate ? `VA Outpt Facility Rate: $${toNumberWithCommas(fact.data.vaRate)} ` : ''}`,
        ` ${fact.data.medicareRate ? `MC: $${toNumberWithCommas(fact.data.medicareRate)} ` : ''}`,
        ` ${fact.data.medicareRate ? `${caseMedicareRate}xMC: $${toNumberWithCommas(fact.data.medicareRate * caseMedicareRate)} ` : ''}`,
        ` Charge: $${toNumberWithCommas(fact.data.price)}`,
        `\n`
      ].join('');
      result += factString;
    }
    return result;
  }
)
