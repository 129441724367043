import { RefObject, FC, Fragment, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// Model
import IEpisode from 'app/models/Episode';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAuthorAnalytics } from 'app/store/EpisodeAuthors/EpisodeAuthors.selectors';
// ToDO
import { selectEpisodesAll, selectEpisodesTotal, selectGettingMore, selectHasMore, selectParams } from 'app/store/Episodes/Episodes.selectors';
// End: ToDO
// MaterialUi
import {
  Box, List, Paper, Typography
} from '@mui/material';
// Componenrs
import { Loader, Message } from 'app/components/Utilities';
// Hooks
import useIntersectionObserver from 'app/hooks/useIntersectionObserver';
// 
import EpisodesListItem from './EpisodesListItem';
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
// i18next

const EpisodesList:FC = () => {
  const { t } = useTranslation();

  // Dispathc
  const dispatch = useAppDispatch();
  // State
  const analytic = useAppSelector(selectAuthorAnalytics);
  const episodes:IEpisode[] | null = useAppSelector(selectEpisodesAll);
  const episodesTotal:number = useAppSelector(selectEpisodesTotal);
  const params = useAppSelector(selectParams);
  const hasMore = useAppSelector(selectHasMore);
  const gettingMore = useAppSelector(selectGettingMore)

  const [ targetRef, isIntersecting ] = useIntersectionObserver({});

  useEffect(() => {
    if ( !isIntersecting ) return;
    if ( !hasMore ) return;

    const offset = params.offset + params.limit;

    dispatch(EpisodesActions.setParams({ offset }));
    // eslint-disable-next-line
  }, [isIntersecting]);

  if ( !episodes ) return <Loader />;
  if ( !episodes.length ) return <Message text={t('pages.authorRegistry.noEpisodesData')} />;
  return (
    <Fragment>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 4, gap: 4 }}>
        {episodesTotal ? (
          <Typography variant="body2">
            <Trans
              t={t} i18nKey="pages.authorRegistry.episodesFound"
              components={{ b: <b /> }} values={{ episodesTotal }}
            />
          </Typography>
        ) : null}
        {analytic && analytic.numberOfCases ? (
          <Typography variant="body2">
            <Trans
              t={t} i18nKey="pages.authorRegistry.numberOfCases"
              components={{ b: <b /> }} values={{ numberOfCases: analytic.numberOfCases }}
            />
          </Typography>
        ) : null}
      </Box>
      <Paper variant="outlined">
        <List>
          {episodes.map((episode:IEpisode, index:number) => (
            <Fragment key={`episode item ${episode.id}`}>
              <EpisodesListItem episode={episode} />
              {index === episodes.length - 1 ? (
                <span ref={targetRef as RefObject<HTMLSpanElement>} />
              ) : null}
            </Fragment>
          ))}
        </List>
      </Paper>
      {gettingMore ? <Loader /> : null}
    </Fragment>
  )
}

export default EpisodesList;
