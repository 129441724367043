import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Container, Toolbar, Typography } from '@mui/material';
// Layouts
import Header from 'app/components/Header';
//
import MyProfileForm from './MyProfileForm';

const MyProfilePage = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Header />
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.profile.title')}</Typography>
      </Toolbar>
      <Container sx={{ py: 4, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <MyProfileForm />
      </Container>
    </Fragment>
  )
}

export default MyProfilePage;
