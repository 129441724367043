import { FC } from 'react';
// Store
import IAuditRecord from 'app/models/AuditRecord';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredAuditRecords, selectSearch } from 'app/store/Audit/Audit.selectors';
// Mui
import { Paper, List, ListItem, ListItemText } from '@mui/material';
// Components
import { Message, Loader } from 'app/components/Utilities';
// Hooks
import useLongDateTme from 'app/hooks/useLongDateTime';
// i18next
import { useTranslation } from 'react-i18next';

const AuditRecordsList:FC = () => {
  const { t } = useTranslation();
  // State
  const auditRecords:IAuditRecord[] | null = useAppSelector(selectFilteredAuditRecords);
  const search:string = useAppSelector(selectSearch);

  if ( !auditRecords ) return <Loader />
  if ( !auditRecords.length && !search ) return <Message text={t('pages.auditLogs.noData')} />
  if ( !auditRecords.length ) return <Message text={t('pages.auditLogs.noDataFound')} />
  return (
    <Paper variant="outlined">
      <List>
        {auditRecords.map((auditRecord:IAuditRecord) => (
          <AuditRecordItem
            key={`audit-record-item-${auditRecord.id}`}
            auditRecord={auditRecord}
          />
        ))}
      </List>
    </Paper>
  )
}

export default AuditRecordsList;

type AuditRecordItemProps = {
  auditRecord: IAuditRecord;
}

const AuditRecordItem:FC<AuditRecordItemProps> = ({
  // Props
  auditRecord
}) => {
  const longCreatedOn = useLongDateTme({
    dateValue: auditRecord.createdOn,
    displayTime: true,
    local: true
  })

  return (
    <ListItem>
      <ListItemText
        primary={auditRecord.description}
        secondary={longCreatedOn}
      />
    </ListItem>
  )
}
