import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga'
// Middlewares
import AppMiddlewares from "app/middlewares";
// RootReducer
import rootReducer from './RootReducer';
// Api
import CasesApi from './Cases/Cases.api';
import DMSDocumentsApi from './DMSDocuments/DMSDocuments.api';
import EpisodeAuthorsApi from "./EpisodeAuthors/EpisodeAuthors.api";

import rootSaga from "./RootSaga";

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
  })
    .concat(CasesApi.middleware)
    .concat(DMSDocumentsApi.middleware)
    .concat(EpisodeAuthorsApi.middleware)
    .concat([
      ...AppMiddlewares,
      sagaMiddleware,
      routerMiddleware(history)
    ])
  ,
  devTools: process.env.REACT_APP_STAGE !== 'production'
});

sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
