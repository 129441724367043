import { ChangeEvent, FC, useState, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { ReportPresetsActions } from 'app/store/ReportPresets/ReportPresets.slice';
// Selectors
import { selectPresets, selectFilter } from 'app/store/ReportPresets/ReportPresets.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Input } from 'app/components/Mui';
// Hooks
import useDebounce from 'app/hooks/useDebounce';
// i18next
import { useTranslation } from 'react-i18next';

const PresetsFilter:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const presets = useAppSelector(selectPresets);
  const filter = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState(filter);

  const debounceSearch = useDebounce(stateFilter.search);

  useEffect(() => {
    if ( debounceSearch !== filter.search ){
      dispatch(ReportPresetsActions.setFilter({ field: 'search', value: debounceSearch }));
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  }
  
  const handleClearSearch = () => {
    setStateFilter((prevState) => ({
      ...prevState,
      search: ''
    }));
  }

  if ( presets && !presets.length ) return null;
  return (
    <Box sx={{ display: 'flex', mb: 4 }}>
      <Input
        label={t('labels.search')} id="search" name="search"
        placeholder={t('pages.presets.searchPlaceholder')}
        value={stateFilter.search}
        onChange={handleChange}
        onClear={handleClearSearch}
        size="small"
        margin="none"
      />
    </Box>
  )
}

export default PresetsFilter;
