import { FC } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectUnseenConversationsIds } from 'app/store/Conversations/Conversations.selectors';
// Mui
import { List } from '@mui/material';
// Components
import { Message, Loader } from 'app/components/Utilities';
// 
import ConversationListItem from './ConversationListItem';
// i18next
import { useTranslation } from 'react-i18next';

const ConversationList:FC = () => {
  const { t } = useTranslation();

  const conversationsIds:number[] | null = useAppSelector(selectUnseenConversationsIds);

  if ( !conversationsIds ) return <Loader />;
  if ( !conversationsIds.length ) return <Message text={t('components.unseenConversations.noData')} />;
  return (
    <List>
      {conversationsIds.map((idx:number) => (
        <ConversationListItem
          key={`conversation-item-${idx}`}
          conversationId={idx}
        />
      ))}
    </List>
  )
}

export default ConversationList;
