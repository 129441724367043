import { FC, Fragment } from 'react';
// Dialogs
import ReportPresetFormDialog from 'app/dialogs/ReportPresetFormDialog';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// i18next
import { useTranslation } from 'react-i18next';

const PresetsFab:FC = () => {
  const { t } = useTranslation();

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Fab
        name="Create preset"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        onClick={toggle}
        color="primary"
        variant="extended"
      >{t('pages.presets.createButton')}</Fab>
      {open ? (
        <ReportPresetFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default PresetsFab;
