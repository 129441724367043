import React from 'react';
// Store
import { useSelector } from 'react-redux';
// MaterialUI
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// 
import CardPageTabsPdf from './CardPageTabsPdf';
import CardPageTabsImage from './CardPageTabsImage';
import CardPageTabsText from './CardPageTabsText';
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
// i18next
import { useTranslation } from 'react-i18next';

let scrollPosition = 0;

const CardPageTabs:React.FC<{ pageBB?:any }> = ({ pageBB }) => {
  const { t } = useTranslation();
  // State
  const trialMode = useSelector(selectTrialMode);

  const [ tab, setTab ] = React.useState<number>(1);
  const [ active, setActive ] = React.useState<boolean>(false);

  const handleChange = (_:any, nextTab:number) => setTab(nextTab);

  const handleScroll = (event: any) => {
    const { scrollTop } = event.target;
    
    if (scrollTop === 0) {
      setActive(true);
    }
  
    if (scrollTop > (scrollPosition + 50)) {
      setActive(false);
      scrollPosition = scrollTop;
    }

    if (scrollTop < scrollPosition) {
      setActive(true);
      scrollPosition = scrollTop;
    }
  }

  const isTabTextVisible = React.useMemo(() => {
    return !trialMode && tab === 2
  }, [trialMode, tab]);

  return (
    <React.Fragment>
      <Paper sx={{
        marginTop: active ? 0 : '-66px',
        transition: 'margin-top 0.3s'
      }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={t('labels.pdf')} />
          <Tab label={t('labels.image')} />
          {!trialMode ? (
            <Tab label={t('labels.text')} />
          ) : null}
        </Tabs>
      </Paper>
      <div onScroll={handleScroll} style={{
        flex: 1,
        overflowY: 'auto',
      }}>
        {tab === 0 ? <CardPageTabsPdf /> : null}
        {tab === 1 ? <CardPageTabsImage pageBB={pageBB} /> : null}
        {!trialMode ? (
          <div style={{ display: isTabTextVisible ? 'block' : 'none' }}>
            <CardPageTabsText activeTab={tab} />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default CardPageTabs;
