import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IEpisodeFact from 'app/models/EpisodeFact';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectSortedEpisodeFacts } from 'app/store/EpisodeFacts/EpisodeFacts.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// 
import FactItem from './FactItem';

const FactList:FC = () => {
  const { t } = useTranslation();
  // State
  const facts:IEpisodeFact[] | null = useAppSelector(selectSortedEpisodeFacts);

  if ( !facts ) return <Loader />;
  if ( !facts.length ) return <Message text={t('dialogs.episodeFacts.noData')} />
  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: 2
    }}>
      {facts.map((fact:IEpisodeFact) => (
        <FactItem
          key={`fact item ${fact.id}`}
          fact={fact}
        />
      ))}
    </Box>
  )
}

export default FactList;
