import React from 'react';
// Types
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Models
import { IPage } from '@root/models/Page';
// Store
import { useSelector } from 'react-redux';
// Async
import { createAuditRecord } from 'app/store/Audit/Audit.async';
// Actions
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectIsLoading, selectPageStaples, selectStatus } from 'app/store/page/page.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// MaterialUi
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
// Utilities
import { LoadingButton } from '@mui/lab';
// i18next
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'app/hooks/useStore';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ExportPagesToPDFDialog:React.FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser = useSelector(selectMyUser);
  const isLoading:boolean = useSelector(selectIsLoading);
  const staplePages:Array<IPage> | null = useSelector(selectPageStaples);
  const insuranceCase = useSelector(selectInsuranceCase);
  const status:any = useSelector(selectStatus);

  const [ checked, setChecked ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if ( status === 'Success' ){
      onClose();
      if ( myUser && insuranceCase ){
        dispatch(createAuditRecord({
          operation: AuditOperations.ExportPagesToPDF,
          data: {
            caseId: insuranceCase.id,
            userName: myUser.name 
          }
        }));
      }
    }
    // eslint-disable-next-line
  }, [status]);

  const handleExport = () => {
    if (!staplePages) return;

    const pagesToExport = staplePages.map((page:IPage) => ({ documentId: page.documentId, pageNum: page.pageNum }));

    dispatch(PageActions.exportPagesToPDF(pagesToExport));
  }

  const handleClose = (_:any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if ( reason === 'backdropClick' ) return;
    onClose();
  }

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t('dialogs.consentToExport.title')}</DialogTitle>
      <DialogContent dividers>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={(event:React.ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked)}
            />
          }
          label={t('dialogs.consentToExport.consentLabel')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >{t('buttons.close')}</Button>
        <LoadingButton
          loading={isLoading}
          disabled={!checked}
          color="primary"
          variant="contained"
          onClick={handleExport}
        >{t('buttons.export')}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ExportPagesToPDFDialog;
