import { FC, Fragment } from 'react';
// Models
import IBillingPackage from 'app/models/BillingPackage';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deletePackage } from 'app/store/BillingPackages/BillingPackages.async';
// Selectors
import { selectLoading } from 'app/store/BillingPackages/BillingPackages.selectors';
// Mui
import { Theme, Paper, Box, Typography } from '@mui/material';
// Icons
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Hooks
import useToggle from 'app/hooks/useToggle';
import useMenu from 'app/hooks/useMenu';
// 
import PackageFormDialog from './PackageFormDialog';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  pkg: IBillingPackage
}

const AccountsListItem:FC<Props> = ({
  // Props
  pkg
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // Loading
  const loading:boolean = useAppSelector(selectLoading)

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open:openPackageFormDialog, toggle:togglePackageFormDialog } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const handleConfirm = () => {
    dispatch(deletePackage(pkg.id));
  }

  return (
    <Fragment>
      <Paper
        sx={{ display: 'flex', p: 4, '& + &': { mt: 4 } }}
        variant="outlined"
      >
        <Box sx={{ flexGrow: 1, pr: 2 }}>
          <Typography variant="subtitle1">{pkg.name}</Typography>
          <Typography variant="body2" color="CaptionText">{pkg.description}</Typography>
        </Box>
        <Box sx={{ mt: -2, mr: -2 }}>
          <IconButton
            name={`Subscription package dropdown ${pkg.id}`}
            aria-label="Subscription package item dropdown button"
            onClick={openMenu}
          ><MoreVertIcon /></IconButton>
          <Menu>
            <MenuItem
              name={`edit-${pkg.id}`}
              onClick={togglePackageFormDialog}
            >{t('buttons.edit')}</MenuItem>
            <MenuItem
              sx={{ color: (theme:Theme) => theme.palette.error.main }}
              name={`delete-${pkg.id}`}
              onClick={toggleConfirmationDialog}
            >{t('buttons.delete')}</MenuItem>
          </Menu>
        </Box>
      </Paper>
      {/* Package form dialog */}
      {openPackageFormDialog ? (
        <PackageFormDialog
          open={openPackageFormDialog}
          onClose={togglePackageFormDialog}
          packageId={pkg.id}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
          title={t('dialogs.confirmation.deleteTitle', { dataType: t('labels.subscriptionPackage') })}
          content={(
            <Trans
              t={t} i18nKey="dialogs.confirmation.deleteConfirmationWithName"
              components={{ strong: <strong /> }}
              values={{ dataType: t('labels.subscriptionPackage'), dataName: pkg.name }}
            />
          )}
          loading={loading}
        />
      ) : null}
    </Fragment>
  )
}

export default AccountsListItem;
