import { FC, Fragment, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Models
import IBillingPackage, { IBillingPackageItem } from 'app/models/BillingPackage';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { BillingPackagesActions } from 'app/store/BillingPackages/BillingPackages.slice';
// Async
import { getPackage, createPackage, updatePackage } from 'app/store/BillingPackages/BillingPackages.async';
// Selectors
import { selectPackage, selectLoading } from 'app/store/BillingPackages/BillingPackages.selectors';
import { selectNonAdditionalBillingPackageItems } from 'app/store/BillingServices/BillingServices.selectors';
// Mui
import { Divider } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button, LoadingButton } from 'app/components/Mui';
// Utility
import { isRequired } from 'app/utilities/Validations';

import PackageServicesContext from 'app/context/PackageServicesContext';
import PackageServicesAddButton from './PackageServicesAddButton';
import PackageServicesList from './PackageServicesList';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
  description: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  packageId?: number;
};

const PackageFormDialog:FC<Props> = ({
  // Props
  open, onClose, packageId
}) => {
  const { t } = useTranslation();

  const label = packageId ? t('common.update') : t('common.create');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const billingPackage:IBillingPackage | null = useAppSelector(selectPackage);
  const loading:boolean = useAppSelector(selectLoading);
  const nonAdditionaPackageItems:IBillingPackageItem[] | null = useAppSelector(selectNonAdditionalBillingPackageItems);

  const [ items, setItems ] = useState<IBillingPackageItem[]>([]);

  const { control, handleSubmit, formState:{ errors }, reset } = useForm<IFormData>({
    defaultValues: {
      name: '',
      description: '',
    }
  });

  useEffect(() => {
    if ( packageId ) dispatch(getPackage(packageId));
    return () => {
      dispatch(BillingPackagesActions.setInitialField('package'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !packageId ) {
      setItems(nonAdditionaPackageItems || []);
    }
    if ( billingPackage ) {
      reset({
        name: billingPackage.name,
        description: billingPackage.description,
      });
      setItems(billingPackage.items || []);
    }
    // eslint-disable-next-line
  }, [billingPackage])

  const onSubmit = handleSubmit((data:IFormData) => {
    const nextData = { ...data, items };
    if ( packageId ){
      asyncUpdatePackage({ packageId, data:nextData });
    } else {
      asyncCreatePackage(nextData);
    }
  });

  const asyncUpdatePackage = async (nextData:any) => {
    try {
      await dispatch(updatePackage(nextData)).unwrap();
      onClose();
    } catch(e) {}
  }

  const asyncCreatePackage = async (data:IFormData) => {
    try {
      await dispatch(createPackage(data)).unwrap();
      onClose();
    } catch(e) {}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel subscription package dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name={`${label} subscription package dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.save')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={`${label} ${t('pages.subscriptionPackages.subscriptionPackage')}`}
      actions={actions}
      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      {/* Name */}
      <Controller
        control={control} name="name"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.name')}
            error={Boolean(errors.name)}
            helperText={errors.name ? errors.name.message : ''}
            required
          />
        )}
      />
      {/* Description */}
      <Controller
        control={control} name="description"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.description')}
            error={Boolean(errors.description)}
            helperText={errors.description ? errors.description.message : ''}
            required
            multiline
            rows={5}
          />
        )}
      />
      <Divider sx={{ my: 4 }} />
      <PackageServicesContext.Provider value={{ items, onUpdate: setItems }}>
        <PackageServicesAddButton />
        <PackageServicesList />
      </PackageServicesContext.Provider>
    </Dialog>
  );
}

export default PackageFormDialog;
