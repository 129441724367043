import { FC, useMemo } from 'react';
// Mui
import { Box, CircularProgress, Theme } from '@mui/material';
// 
import useUploadContext from './UploadProvider/useUploadContext';

const FilesUploadProgress:FC = () => {
  const { uploadedFilesCount, fileItems, formState:{ isLoading } } = useUploadContext();

  const totalUploadedPercents:number = useMemo(() => {
    if ( !fileItems.length ) return 0;
    const percentPerOneFile = 100/fileItems.length;
    return Math.round(percentPerOneFile * uploadedFilesCount);
    // eslint-disable-next-line
  }, [fileItems, uploadedFilesCount]);

  if ( !isLoading ) return null;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 4 }}>
      {`${uploadedFilesCount}/${fileItems.length}`}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
        <CircularProgress
          sx={{
            color: (theme:Theme) => theme.palette.grey[300]
          }}
          variant="determinate"
          value={100}
          size={36}
          thickness={4}
        />
        <CircularProgress
          sx={{
            position: 'absolute',
            top: 0, left: 0,
            zIndex: 1
          }}
          variant="determinate"
          value={totalUploadedPercents}
          size={36}
          thickness={4}
        />
        <Box
          sx={{
            position: 'absolute',
            fontSize: 10
          }}
        >{totalUploadedPercents}%</Box>
      </Box>
    </Box>
  )
}

export default FilesUploadProgress;
