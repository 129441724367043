import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { StepTasks } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Mui
import { Box } from '@mui/material';
// 
import { useWorkflowContext } from './Workflow.context';
import GroupContainer from './GroupContainer';
import StepBlockStatic from './StepBlockStatic';
import StepBlock from './StepBlock';
import AddStepsButton from './AddStepsButton';
import RemoveStepsButton from './RemoveStepsButton';

const DialogContainer:FC = () => {
  const { t } = useTranslation();

  const { stepsGroup, stepCreateReport, stepSendEmail, onAddSteps, onRemoveSteps, onAddSendEmailStep } = useWorkflowContext();

  const minStepsGroups = 1;
  const maxStepsGroups = 10;

  const handleRemoveSteps = (stepId:string) => () => {
    onRemoveSteps(stepId);
  }

  const handleAddSteps = (stepTask?:StepTasks) => () => {
    if ( stepTask ){
      onAddSendEmailStep()
    } else {
      onAddSteps();
    }
  }

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflowY: 'auto'
    }}>
      {/* Render Flow */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
        p: 4,
        overflow: 'auto'
      }}>
        <StepBlockStatic label={t('labels.start')} />

        <GroupContainer>
          {stepsGroup.map((group:any, index:number) => (
            <GroupContainer.Item
              key={`group-item-${index}`}
              // Don't know is this ok to kepp such functionaliy in GroupContainer.Item
              sx={{
                '&:hover .stepsAction_hover': {
                  opacity: 1,
                  visibility: 'visible'
                }
              }}
            >
              {group.map((step:any) => (
                <StepBlock
                  key={`group item ${index} step item ${step.id}`}
                  step={step}
                />
              ))}
              {stepsGroup.length > minStepsGroups ? (
                <RemoveStepsButton onClick={handleRemoveSteps(group[group.length - 1].id)} />
              ) : null}
            </GroupContainer.Item>
          ))}
          {stepsGroup.length <= maxStepsGroups ? (
            <AddStepsButton onClick={handleAddSteps()} />
          ) : null}
        </GroupContainer>

        {stepCreateReport ? (
          <GroupContainer>
            <GroupContainer.Item>
              <StepBlock step={stepCreateReport} />
            </GroupContainer.Item>
          </GroupContainer>
        ) : null}

        <GroupContainer>
          {stepSendEmail ? (
            <GroupContainer.Item
              // Don't know is this ok to kepp such functionaliy in GroupContainer.Item
              sx={{
                '&:hover .stepsAction_hover': {
                  opacity: 1,
                  visibility: 'visible'
                }
              }}
            >
              <StepBlock step={stepSendEmail} />
              <RemoveStepsButton onClick={handleRemoveSteps(stepSendEmail.id)} />
            </GroupContainer.Item>
          ) : (
            <AddStepsButton
              tooltipTitle={t('labels.addSendEmail')}
              onClick={handleAddSteps(StepTasks.SendEmail)}
            />
          )}
        </GroupContainer>

        <StepBlockStatic label={t('labels.end')} />
      </Box>
    </Box>
  )
}

export default DialogContainer