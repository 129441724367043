import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { Input } from 'app/components/Mui';
// 
import useUploadContext from './UploadProvider/useUploadContext';

const CommentControl:FC = () => {
  const { t } = useTranslation();

  const { comment, onCommentChange } = useUploadContext();

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    onCommentChange(event.target.value);
  }

  return (
    <Input
      value={comment} onChange={handleChange}
      label={t('labels.comment')}
      size="small"
      margin="none"
    />
  )
}

export default CommentControl;
