import { FC, Fragment } from 'react';
// Models
import IManagementAccount from 'app/models/ManagementAccount';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectFilteredAccounts } from 'app/store/AccountsManagement/AccountsManagement.selectors';
// Mui
import { Loader, Message } from 'app/components/Utilities';
// 
import AccountsListItem from './AccountsListItem';
// i18next
import { useTranslation } from 'react-i18next';

const AccountsList:FC = () => {
  const { t } = useTranslation();
  // State
  const accounts:IManagementAccount[] | null = useAppSelector(selectFilteredAccounts)

  if ( !accounts ) return <Loader />;
  if ( !accounts.length ) return <Message text={t('pages.companyAccounts.noData')} />;
  return (
    <Fragment>
      {accounts.map((account:IManagementAccount) => (
        <AccountsListItem
          key={`account-item-${account.id}`}
          account={account}
        />
      ))}
    </Fragment>
  )
}

export default AccountsList;
