import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Store
import { useAppSelector, useAppDispatch } from 'app/hooks/useStore';
// Async
import { getExecutions } from 'app/store/AutomatedWorkflowsExecutions/AutomatedWorkflowsExecutions.async';
// Selectors
import { selectSortedExecutions } from 'app/store/AutomatedWorkflowsExecutions/AutomatedWorkflowsExecutions.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import DataLoading from 'app/components/DataLoading';
import WorkflowExecutionCard from 'app/components/WorkflowExecutionCard';
import { Button } from 'app/components/Mui';

type Props = {
  open: boolean;
  onClose: () => void;
  workflowId: number;
}

const WorkflowExecutionsDialog:FC<Props> = ({
  // Props
  open, onClose, workflowId
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // Selectors
  const executions = useAppSelector(selectSortedExecutions);

  useEffect(() => {
    dispatch(getExecutions({ workflowId }));
    // eslint-disable-next-line
  }, [])

  const actions = (
    <Button
      name="Close workflow executions dialog"
      onClick={onClose}
    >{t('buttons.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.automatedWorkflows.executionsDialogTitle')}
      actions={actions}
    >
      <DataLoading
        data={executions}
        text={t('pages.automatedWorkflows.noExecutionsData')}
      >
        {(data) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {data.map((execution) => (
              <WorkflowExecutionCard
                key={`workflow execution item ${execution.id}`}
                execution={execution}
                showWorkflowName={false}
              />
            ))}
          </Box>
        )}
      </DataLoading>
    </Dialog>
  )
}

export default WorkflowExecutionsDialog