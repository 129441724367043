import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import { Button } from 'app/components/Mui';
// Icons
import { Add as AddIcon } from '@mui/icons-material';
// hooks
import useToggle from 'app/hooks/useToggle';
import useCaseStatus from 'app/hooks/useCaseStatus';
// 
import EpisodesFormDialog from './EpisodeFormDialog';
// ToDO
import { IPage } from '@root/models/Page';
import { selectPage } from 'app/store/page/page.selectors';
// End: ToDO

const EpisodeCreateButton:FC = () => {
  const { t } = useTranslation();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  // ToDO
  const page = useAppSelector((state) => selectPage(state as any)) as IPage;
  // End: ToDO
  const { isArchived } = useCaseStatus();
  const { open, toggle } = useToggle();

  const myRolePresenterOrClient = myUser.role === UserRoles.Presenter || myUser.role === UserRoles.Client;

  if ( myRolePresenterOrClient || trialMode ) return null;
  if ( isArchived || (page && page.staple && page.staple.order !== 1)) return null;
  // We used absolute to place button near toggle "in workspace"
  // As we used context for pageEpisodes, we can`t move EpisdoeCreateButton component outside provider
  // Context we used to avoid store cleanup
  return (
    <Box sx={{
      position: 'absolute',
      top: 0, right: 0,
      mt: -12
    }}>
      <Button
        name="Create episode"
        onClick={toggle}
      ><AddIcon sx={{ mr: 2 }} />{t('components.pageEpisodes.createButton')}</Button>
      {open ? (
        <EpisodesFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Box>
  )
}

export default EpisodeCreateButton;
