import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Types
import BodyErrors from 'app/types/BodyErrors';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Asycn
import { logout } from 'app/store/Auth/Auth.async';
import { verifyEmail } from 'app/store/Users/Users.async';
// Mui
import {
  Box, Container, Alert
} from '@mui/material';
// Components
import Brand from 'app/components/Brand';
import { Button } from 'app/components/Mui';
// Utils
import { getSearchParam } from 'app/utilities/Utilities';
import { Loader } from 'app/components/Utilities';

const EmailVerificationPage:FC = () => {
  const { t } = useTranslation();

  const { search } = useLocation();

  const token = getSearchParam(search, 'token');
  // Dispatch
  const dispatch = useAppDispatch();

  const [ loading, setLoading ] = useState(true);
  const [ message, setMessage ] = useState('');
  const [ error, setError ] = useState(false);

  useEffect(() => {
    const handleVerifyEmail = async (token:string) => {
      setLoading(true);
      try {
        const response = await dispatch(verifyEmail(token));
        let message = '';
        if ( response.type === verifyEmail.rejected.type ){
          message = response.payload.error === BodyErrors.ChallengeCodeInvalid
            ? t('pages.emailVerification.errorMessages.challengeCodeInvalid')
            : t('pages.emailVerification.errorMessages.default');
          setError(true);
        } else {
          message = t('pages.emailVerification.successMessage')
        }
        setMessage(message);
      } finally {
        setLoading(false);
      }
    }

    if ( token ){
      handleVerifyEmail(token);
    } else {
      setLoading(false);
    }

    dispatch(logout());
    // eslint-disable-next-line
  }, []);

  return (
    <Container sx={{ pt: 8 }} maxWidth="xs">
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Brand />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        {loading ? <Loader /> : (
          <Alert severity={error ? 'error' : 'success'}>{message}</Alert>
        )}
        <Button
          name="Go to login"
          component={Link}
          to="/login"
          disabled={loading}
          variant="contained"
          fullWidth
        >{t('buttons.goToLogin')}</Button>
      </Box>
    </Container>
  )
}

export default EmailVerificationPage;
