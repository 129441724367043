import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
// Models
import { RootState } from 'app/store';
import IClientAccount from 'app/models/ClientAccount';
import IClientAccountUser from 'app/models/ClientAccountUser';
// Utilities
import { sortByName } from 'app/utilities/SortBy';

export const selectAccounts = (state:RootState) => state[Reducers.Clients].accounts;
export const selectAccountUsers = (state:RootState) => state[Reducers.Clients].accountUsers;
export const selectFilter = (state:RootState) => state[Reducers.Clients].filter;
export const selectLoading = (state:RootState) => state[Reducers.Clients].loading;

export const selectFilteredAccounts = createSelector(
  [
    selectAccounts,
    selectFilter
  ],
  (accounts:IClientAccount[] | null, filter) => {
    if ( !accounts ) return null;
    const { search } = filter;
    const searchToLower = search.toLowerCase();
    return accounts.filter((account:IClientAccount) => {
      const hasCompany = searchToLower ? account.company.toLowerCase().includes(searchToLower) : true;
      const hasAddress = searchToLower
        ? (
            (account.address && account.address.line
              ? account.address.line.toLowerCase().includes(searchToLower) : false
            ) ||
            (account.address && account.address.city
              ? account.address.city.toLowerCase().includes(searchToLower) : false
            ) ||
            (account.address && account.address.state
              ? account.address.state.toLowerCase().includes(searchToLower) : false
            ) ||
            (account.address && account.address.zipCode
              ? account.address.zipCode.toLowerCase().includes(searchToLower) : false
            )
          )
        : true;
      return hasCompany || hasAddress;
    }).sort((a:IClientAccount, b:IClientAccount) => sortByName(a, b, 'company'))
  }
);

export const selectAccountsAsOptions = createSelector(
  [ selectAccounts ],
  (accounts:IClientAccount[] | null) => {
    if (!accounts) return null;
    return accounts.map(({ id, company, address = {} }) => {
      const { line, city, state, zipCode } = address;
      return {
        value: id,
        label: company,
        props: {
          address: [line, city, state, zipCode].filter(Boolean).join(', ')
        }
      };
    });
  }
);

export const selectAccountUsersAsOptions = createSelector(
  [ selectAccountUsers ],
  (accountUsers:IClientAccountUser[] | null) => {
    if ( !accountUsers ) return null;
    return accountUsers.map((accountUser:IClientAccountUser) => ({
      value: accountUser.id,
      label: accountUser.name,
      props: {
        email: accountUser.email
      }
    }))
  }
);

export const selectCompanyNameByAccountId = createSelector(
  [
    selectAccounts,
    (_, props:{ accountId:string | number | undefined }) => props
  ],
  (accounts:IClientAccount[] | null, { accountId }) => {
    if ( !accounts || !accountId ) return null;
    const foundedAccount = accounts.find((account:IClientAccount) => account.id === Number(accountId));
    return foundedAccount?.company;
  }
)