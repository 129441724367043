import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import { StepTasks } from 'app/store/AutomatedWorkflows/AutomatedWorkflows.types';
// Models
import { RootState } from 'app/store';
import IOption from 'app/models/Option';
// Store
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCollectionsAsOptionsByFileType_TODO } from 'app/store/DMSCollections/DMSCollections.selectors';
// Box
import { MenuItem, Typography } from '@mui/material';
// Components
import LabelsForm from 'app/components/LabelsForm';
import { Input, Select } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// 
import { useWorkflowContext } from '../Workflow.context'

const CreateDocumentControls:FC = () => {
  const { t } = useTranslation();

  const { selectedStep, getStepFieldIndex } = useWorkflowContext();

  // State
  const collectionOptions = useAppSelector((state:RootState) => selectCollectionsAsOptionsByFileType_TODO(state, {
    fileFormat: 'custom'
  }));

  const { control, formState:{ errors } } = useFormContext();

  if ( !selectedStep || selectedStep.task !== StepTasks.CreateDocument ) return null;

  const fieldIndex = getStepFieldIndex(selectedStep.id) as number;

  const collectionIdError = (errors as any)?.steps?.[fieldIndex]?.input?.parameters?.collectionId;

  return (
    <Fragment>
      <Controller
        control={control}
        name={`steps[${fieldIndex}].input.parameters.name`}
        render={({ field }) => (
          <Input {...field} label={t('labels.name')} size="small" />
        )}
      />
      <Controller
        control={control}
        name={`steps[${fieldIndex}].input.parameters.collectionId`}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('labels.documentCollection')}
            options={collectionOptions}
            required
            error={Boolean(collectionIdError)}
            helperText={collectionIdError?.message || ''}
            renderOptions={(option:IOption, index:number) => (
              <MenuItem
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                key={`option item ${index} ${option.id}`}
                value={option.id.toString()}
              >
                <Typography variant="subtitle1">{option.name}</Typography>
                <Typography sx={{ maxWidth: 320, whiteSpace: 'normal' }} variant="caption">{option.props.description}</Typography>
              </MenuItem>
            )}
            size="small"
          />
        )}
      />
      <OutlineBlock sx={{ pt: 6, mt: 6 }} label={t('labels.labels')}>
        <Fragment>
          <Controller
            control={control} defaultValue={[]}
            name={`steps[${fieldIndex}].input.parameters.labels`}
            render={({ field }) => (
              <Fragment>
                <LabelsForm
                  labels={field.value}
                  onUpdate={(newLabels) => field.onChange(newLabels)}
                  size="small"
                />
              </Fragment>
            )}
          />
        </Fragment>
      </OutlineBlock>
    </Fragment>
  )
}

export default CreateDocumentControls;