import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import Dialog from 'app/components/Dialog';
import { Button, Checkbox } from 'app/components/Mui';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConsentToExportDialog:FC<Props> = ({
  // Props
  open, onClose, onConfirm
}) => {
  const { t } = useTranslation();

  const [ checked, setChecked ] = useState(false);

  const actions = (
    <Fragment>
      <Button
        name="Close consent to export dialog"
        onClick={onClose}
        color="primary"
      >{t('buttons.close')}</Button>
      <Button
        name="Submit consent to export dialog"
        disabled={!checked}
        color="primary"
        variant="contained"
        onClick={onConfirm}
      >{t('buttons.export')}</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('dialogs.consentToExport.title')}
      actions={actions}
    >
      <Checkbox
        name="Consent to export"
        label={t('dialogs.consentToExport.consentLabel')}
        value={checked}
        onChange={(_, checked) => setChecked(checked)}
      />
    </Dialog>
  );
}

export default ConsentToExportDialog;
