import { FC, useContext } from 'react'
// Mui
import { Box, Button } from '@mui/material';
// Icons
import { Add as AddIcon } from '@mui/icons-material';
// Contexts
import PackageServicesContext from 'app/context/PackageServicesContext';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import PackageItemFormDialog from './PackageItemFormDialog';
// i18next
import { useTranslation } from 'react-i18next';

const PackageServicesAddButton:FC = () => {
  const { t } = useTranslation();

  const { items, onUpdate } = useContext(PackageServicesContext);

  const { open, toggle } = useToggle();

  const handleConfirm = (item:any) => {
    onUpdate((prevItems:any) => [...prevItems, item]);
  }

  return (
    <Box sx={{ mb: 4, textAlign: 'right' }}>
      <Button
        onClick={toggle}
      ><AddIcon sx={{ mr: 2 }} />{t('pages.subscriptionPackages.addServiceButton')}</Button>
      {open ? (
        <PackageItemFormDialog
          open={open}
          onClose={toggle}
          onConfirm={handleConfirm}
          items={items}
        />
      ) : null}
    </Box>
  )
}

export default PackageServicesAddButton;
