import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Mui
import { Box, Theme, Tooltip } from '@mui/material';
// Icons
import { AddCircleOutlineOutlined as AddCircleOutlineOutlinedIcon } from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';

type Props = {
  tooltipTitle?: string;
  onClick: () => void;
}

const AddStepsButton:FC<Props> = ({ tooltipTitle = '', onClick }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{
      maxWidth: '200px', /* Max width for child elements */
      flex: '1 1 200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: (theme:Theme) => `1px solid ${theme.palette.divider}`,
      p: 4
    }}>
      <Tooltip title={tooltipTitle || t('buttons.add')}>
        <IconButton
          name="Add step group"
          onClick={onClick}
        ><AddCircleOutlineOutlinedIcon /></IconButton>
      </Tooltip>
    </Box>
  );
}

export default AddStepsButton;
