import { FC } from 'react';
// Models
import IExportTemplate from 'app/models/ReportTemplate';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectExportTemplates } from 'app/store/ExportTemplates/ExportTemplates.selectors';
// Mui
import { Paper, List } from '@mui/material';
// 
import ExportTemplatesListItem from './ExportTemplatesListItem';
// Components
import { Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

const ExportTemplatesList:FC = () => {
  const { t } = useTranslation();

  const exportTemplates = useAppSelector(selectExportTemplates);

  if ( !exportTemplates ) return <Loader />;
  if ( !exportTemplates.length ) return <Message text={t('pages.exportTemplates.noData')} />;
  return (
    <Paper variant="outlined">
      <List>
        {exportTemplates.map((exportTemplate:IExportTemplate) => (
          <ExportTemplatesListItem
            key={`Export template item ${exportTemplate.id}`}
            exportTemplate={exportTemplate}
          />
        ))}
      </List>
    </Paper>
  )
}

export default ExportTemplatesList;
