import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Store
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getCaseShares } from 'app/store/CaseShares/CaseShares.async';
// Selectors
import { selectCaseShares } from 'app/store/CaseShares/CaseShares.selectors';
// Mui
import { Paper, List } from '@mui/material';
// Components
import { Loader, Message } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';
// 
import ShareItem from './ShareItem';

const SharesList:FC = () => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const shares:any[] | null = useAppSelector(selectCaseShares);

  useEffect(() => {
    dispatch(getCaseShares(Number(caseId)));
    // eslint-disable-next-line
  }, []);

  if ( !shares ) return <Loader />;
  if ( !shares.length ) return <Message text={t('pages.caseShares.noData')} />;
  return (
    <Paper variant="outlined">
      <List>
        {shares.map((share:any) => (
          <ShareItem
            key={`share item ${share.id}`}
            share={share}
          />
        ))}
      </List>
    </Paper>
  )
}

export default SharesList;
