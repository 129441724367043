import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import TermsFormDialog from './TermsFormDialog';

const TermsFab:FC = () => {
  const { t } = useTranslation();

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Fab
        name="Create terms and conditions"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        onClick={toggle}
        color="primary"
        variant="extended"
      >{t('pages.termsAndConditions.createButton')}</Fab>
      {open ? (
        <TermsFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default TermsFab;
