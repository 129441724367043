import React, { useEffect } from 'react';
// MaterialUI
// Core
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
// Components
import { LoadingButton } from '@mui/lab';
// i18next
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const ConfirmationDialog = ({
  // Props
  loading, status, open, onClose, onUnconfirm, onConfirm, title, content, confirmLabel, unconfirmLabel, confirmButtonColor
}) => {
  const { t } = useTranslation();

  const handleClose = (_, reason) => {
    if ( reason === 'backdropClick' ) return;
    onClose();
  }

  useEffect(() => {
    if ( status === 'Success' ) onClose();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent><Typography variant="subtitle1">{content}</Typography></DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >{t('buttons.close')}</Button>
        {typeof onUnconfirm === 'function' ? (
          <Button
            onClick={onUnconfirm}
            variant="contained"
            color="inherit"
          >{unconfirmLabel}</Button>
        ) : null}
        <LoadingButton
          onClick={onConfirm}
          loading={loading}
          color={confirmButtonColor}
          variant="contained"
        >{confirmLabel}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.defaultProps = {
  open: false,
  onClose: () => null,
  loading: false,
  status: 'Initial',
  title: '',
  content: '',
  onConfirm: () => null,
  onUnconfirm: null,
  confirmLabel: i18n.t('buttons.confirm'),
  unconfirmLabel: i18n.t('labels.no'),
  confirmButtonColor: 'primary'
}

export default ConfirmationDialog;
