import { FC, Fragment, useState } from 'react';
// Types
import { SubscriptionStatus } from 'app/store/Subscriptions/Subscriptions.types';
// Models
import { ISubscription } from 'app/store/Subscriptions/Subscriptions.models';
// Store
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { cancelSubscription } from 'app/store/Subscriptions/Subscriptions.async';
// Mui
import {
  Theme,
  Paper, Box, Typography, Divider,
  Table, TableRow, TableCell, TableBody,
  TableHead
} from '@mui/material';
// Icons
import {
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';
import { InfoBlock } from 'app/components/Utilities';
// Dialogs
import ConfirmationDialog from 'app/dialogs/ConfirmationDialog';
// Hooks
import useMenu from 'app/hooks/useMenu';
import useToggle from 'app/hooks/useToggle';
import useLongDateTme from 'app/hooks/useLongDateTime';
// Utils
import { toNumberWithCommas, statusThemeColor } from 'app/utilities/Utilities';
// 
import SubscriptionPackageDialog from './SubscriptionPackageDialog';
import { sortByName } from 'app/utilities/SortBy';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  subscription: ISubscription;
}

const SubscriptionItem:FC<Props> = ({
  // Props
  subscription
}) => {
  const { t } = useTranslation();

  const { subscriptionPackage } = subscription;

  // Dispatch
  const dispatch = useAppDispatch();

  const [ loading, setLoading ] = useState(false);

  const { Menu, MenuItem, openMenu } = useMenu();
  const { open, toggle } = useToggle();
  const { open:openConfirmationDialog, toggle:toggleConfirmationDialog } = useToggle();

  const currentPeriodEnd = useLongDateTme({
    dateValue: subscription.currentPeriodEnd,
    local: true
  });

  const createdOn = useLongDateTme({
    dateValue: subscription.createdOn,
    local: true
  });

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await dispatch(cancelSubscription(subscription.id)).unwrap();
      toggleConfirmationDialog();
    } finally {
      setLoading(false);
    }
  }

  const sortedItems = (subscription.items || []).length
    ? [...subscription.items].sort((a, b) => sortByName(a.service, b.service, 'name'))
    : []
  ;

  const showDropdownMenu = subscription.status === SubscriptionStatus.Active;

  return (
    <Fragment>
      <Paper sx={{ p: 4 }} variant="outlined">
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Typography
                sx={{ flexGrow: 1 }}
                variant="subtitle1"
              >{subscriptionPackage.name}</Typography>
              <Typography
                variant="body2"
                sx={{
                  textTransform: 'capitalize',
                  color: (theme:Theme) => statusThemeColor(subscription.status, theme)
                }}
              >{subscription.status}</Typography>
            </Box>
            {subscriptionPackage.description ? (
              <Typography color="CaptionText" variant="body2">{subscriptionPackage.description}</Typography>
            ) : null}
          </Box>
          {showDropdownMenu ? (
            <Box sx={{ pl: 4 }}>
              <IconButton
                name={`Subscription dropdown ${subscription.id}`}
                sx={{ mt: -1.5, mr: -2 }}
                aria-label="Subscription dropdown button"
                onClick={openMenu}
              ><MoreVertIcon /></IconButton>
              <Menu>
                <MenuItem
                  name={`Update subscription ${subscription.id}`}
                  onClick={toggle}
                >{t('buttons.update')}</MenuItem>
                <MenuItem
                  name={`Cancel subscription ${subscription.id}`}
                  sx={{
                    color: (theme:Theme) => theme.palette.error.main
                  }}
                  onClick={toggleConfirmationDialog}
                >{t('buttons.cancel')}</MenuItem>
              </Menu>
            </Box>
          ) : null}
        </Box>
        <Divider sx={{ borderColor: (theme:Theme) => theme.palette.divider, my: 4 }} />
        <Box sx={{ display: 'flex', gap: 4 }}>
          <InfoBlock label={t('labels.company')} value={subscription.account.company} />
          {createdOn ? (
            <InfoBlock label={t('labels.createdOn')} value={createdOn} />
          ) : null}
          {currentPeriodEnd ? (
            <InfoBlock label={t('labels.nextBillDate')} value={currentPeriodEnd} />
          ) : null}
        </Box>
        {sortedItems.length ? (
          <Table sx={{
            // bgcolor: (theme:Theme) => theme.palette.primary.light,
            // border: (theme:Theme) => `1px solid ${theme.palette.divider}`,
            mt: 4
          }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('labels.name')}</TableCell>
                <TableCell sx={{ width: '20%' }} align="right">{t('labels.qty')}</TableCell>
                <TableCell sx={{ width: '20%' }} align="right">{t('labels.price')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedItems.map((item, index) => (
                <TableRow key={`subscriptionItem-${index}-${subscription.id}`}>
                  <TableCell>{item.service.name}</TableCell>
                  <TableCell align="right">{typeof item.quantity === 'undefined' ? '-' : item.quantity}</TableCell>
                  <TableCell align="right">{`$${toNumberWithCommas(item.price)}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </Paper>
      {open ? (
        <SubscriptionPackageDialog
          open={open}
          onClose={toggle}
          subscriptionId={subscription.id}
        />
      ) : null}
      {/* Delete account */}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
          title={t('pages.subscriptions.cancelTitle')}
          content={(
            <Trans
              t={t} i18nKey="pages.subscriptions.cancelConfirmation"
              components={{ strong: <strong /> }}
              values={{ subscriptionName: subscriptionPackage.name }}
            />
          )}
          loading={loading}
        />
      ) : null}
    </Fragment>
  )
}

export default SubscriptionItem;