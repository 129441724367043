import { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import { areEqual, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useTranslation } from "react-i18next";
// Mui
import { Box, Chip, Theme } from "@mui/material";
// Icons
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
// Components
import { Message } from "app/components/Utilities";
// Utils
import { camelCaseToNormalText } from "app/utilities/Utilities";
// 
import { IFileItem } from "./UploadProvider/useUploadContext";
import useFileValidationErrors from "./UploadProvider/useFileItemsHandler";
// 
import FileItem from "./FileItem";

type Props = {
  fileItems: IFileItem[];
}

const FileItems:FC<Props> = ({ fileItems }) => {
  const { t } = useTranslation();

  const { groupFileItemsByErrorCode } = useFileValidationErrors()

  // Reference to force re-measuring when expanded state changes
  const listRef = useRef<VariableSizeList>(null);

  const groupedFileItems = useMemo(() => groupFileItemsByErrorCode(fileItems), [fileItems, groupFileItemsByErrorCode]);

  // Expanded state - derived from groupedFiles when fileItems change
  const [ expanded, setExpanded ] = useState<Record<number, boolean>>({});

  useMemo(() => {
    // Delay the reset after the render cycle using requestAnimationFrame
    requestAnimationFrame(() => {
      if ( listRef.current ) listRef.current.resetAfterIndex(0); // Recalculate item sizes from index 0
    });

    setExpanded((prev) => {
      const newExpanded = { ...prev };
      groupedFileItems.forEach((_, index) => {
        newExpanded[index] = true; // Default to expanded
      });
      return newExpanded;
    });
  }, [groupedFileItems]);

  // Function to get item height based on expansion
  const getItemSize = useCallback((index: number) => {
    const group = groupedFileItems[index];
    // Check is only one group groupedFileItems
    const fileItemHeight = 112;
    const chipHeight = 50;
    const groupSpacing = groupedFileItems.length > 1 && index !== groupedFileItems.length - 1 ? 16 : 0;
    const collapsedGroupHeight = index === groupedFileItems.length - 1 ? 0 : chipHeight;
    const expandedGroupHeight = collapsedGroupHeight + group.fileItems.length * fileItemHeight;

    return expanded[index] ? expandedGroupHeight + groupSpacing : collapsedGroupHeight + groupSpacing;
  }, [expanded, groupedFileItems]);

  const toggleExpand = (index:number) => () => {
    setExpanded((prev) => {
      const newState = { ...prev, [index]: !prev[index] };
      return newState;
    });
    if ( listRef.current ) listRef.current.resetAfterIndex(0);
  }

  if ( fileItems.length === 0 ) return (
    <Box sx={{ width: '100%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Message text={t('dialogs.caseUploadDocuments.dragAndDrop')} />
    </Box>
  );
  return (
    <AutoSizer>
      {(dimmensions:{ height:number; width:number }) => (
        <VariableSizeList
          {...dimmensions}
          ref={listRef}
          itemCount={groupedFileItems.length}
          itemSize={getItemSize}
          itemData={{
            expanded,
            toggleExpand,
            groupedFileItems
          }}
        >{Row}</VariableSizeList>
      )}
    </AutoSizer>
  );
};

export default FileItems;

const Row = memo(({ index, style, data: { expanded, toggleExpand, groupedFileItems }}:any) => {
  const group = groupedFileItems[index];
  const isExpanded = expanded[index];
  const isLastGroup = index === groupedFileItems.length - 1;
  const icon = isExpanded ? <ExpandLessIcon color="error" /> : <ExpandMoreIcon color="error" />;

  return (
    <Box key={`groupItem-${index}`} style={style}>
      <Box sx={{
        border: (theme:Theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 1
      }}>
        {!isLastGroup ? (
          <Box
            sx={{
              height: 50,
              display: 'flex',
              alignItems: 'center',
              px: 4,
              gap: 4,
              '&:hover': { cursor: 'pointer' }
            }}
            onClick={toggleExpand(index)}
          >
            <Chip
              sx={{
                color: (theme:Theme) => theme.palette.error.main,
                backgroundColor: (theme:Theme) => theme.palette.error.light,
                fontWeight: 'bold'
              }}
              label={`${camelCaseToNormalText(group.errorCode)} (${group.fileItems.length})`}
              icon={icon}
            />
          </Box>
        ) : null}
        {isExpanded ? (
          group.fileItems.map((fileItem:IFileItem) => (
            <Box
              key={fileItem.id}
              sx={{
                height: 112,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderTop: (theme:Theme) => `1px solid ${theme.palette.divider}`,
                py: 2,
                px: 4,
                '&:first-of-type': {
                  borderTop: (theme:Theme) => isLastGroup ? 'none' : `1px solid ${theme.palette.divider}`
                }
              }}
            ><FileItem fileItem={fileItem} /></Box>
          ))
        ) : null}
      </Box>
    </Box>
  );
}, areEqual);
