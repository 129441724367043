import { ChangeEvent, FC, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { BillingOrdersActions } from 'app/store/BillingOrders/BillingOrders.slice';
// Selectors
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectParams } from 'app/store/BillingOrders/BillingOrders.selectors';
// Mui
import { Grid, Typography } from '@mui/material';
// Components
import { Select } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// i18next
import { useTranslation } from 'react-i18next';

const OrdersFilter:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const params = useAppSelector(selectParams);

  const [ stateParams, setStateParams ] = useState(params);

  // eslint-disable-next-line
  const debounceFilter = useCallback(debounce(1000, (field:keyof typeof params, value:any) => {
    dispatch(BillingOrdersActions.setParams({ [field]: value, offset: 0 }));
    // eslint-disable-next-line
  }), []);

  const handleAccountChange = (value:any) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      accountId: value
    }));
    debounceFilter('accountId', value);
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateParams((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
    debounceFilter((name as any), value);
  }

  return (
    <Grid container spacing={4} sx={{ mb: 4 }}>
      <Grid item xs={8}>
        <Autocomplete
          value={stateParams.accountId}
          onChange={handleAccountChange}
          options={accountOptions || []}
          TextFieldProps={{
            label: t('labels.companyAccount'),
            margin: 'none',
            size: 'small'
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
              <Typography variant="subtitle1">{option.label}</Typography>
              {option.props.address ? (
                <Typography variant="caption">{option.props.address}</Typography>
              ) : null}
            </li>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          label={t('labels.status')} name="status" value={stateParams.status}
          onChange={handleChange}
          options={[
            { id: '', name: t('labels.all') },
            { id: 'open', name: t('labels.open') },
            { id: 'completed', name: t('pages.billingOrders.completed') },
            { id: 'cancelled', name: t('pages.billingOrders.cancelled') }
          ]}
          margin="none"
          size="small"
          SelectProps={{
            displayEmpty: true
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </Grid>
  )
}

export default OrdersFilter;
