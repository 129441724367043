import { createSelector } from '@reduxjs/toolkit';
// Types
import Reducers from 'app/types/Reducers';
import FileFormats from 'app/types/FileFormats';
// Models
import { RootState } from 'app/store';
import { ICollection } from './DMSCollections.models';

export const selectIds = (state:RootState) => state[Reducers.DMSCollections].ids;
export const selectEntities = (state:RootState) => state[Reducers.DMSCollections].entities;
export const selectLoading = (state:RootState) => state[Reducers.DMSCollections].loading;

export const selectCollectionEntity = createSelector(
  [
    selectEntities,
    (_, props:{ collectionId:number }) => props
  ],
  ( entities:Record<number, ICollection>, { collectionId }):ICollection | undefined => entities[collectionId]
);

export const selectCollections = createSelector(
  [
    selectIds,
    selectEntities
  ],
  ( ids:number[] | null, entities:Record<number, ICollection> ) => {
    if ( !ids ) return null;
    return ids.map((id:number) => entities[id]);
  }
);

export const selectCollectionsAsOptions = createSelector(
  [ selectCollections ],
  ( collections:ICollection[] | null) => {
    if ( !collections ) return null;
    return collections.map((collection:ICollection) => ({
      value: collection.id,
      label: collection.name,
      props: {
        description: collection.description || ''
      }
    }));
  }
);

export const selectCollectionsAsOptionsByFileType_TODO = createSelector(
  [
    selectCollections,
    (_:any, props:{ fileFormat?:FileFormats | null, ocred?: boolean }) => props
  ],
  ( collections:ICollection[] | null, { fileFormat, ocred }) => {
    if ( !collections ) return null;

    let filteredCollections = collections.filter((collection: ICollection) => {
      // If fileFormat is `null`, return collections with no fileFormats or empty fileFormats
      if ( fileFormat === null ) return !collection.fileFormats || collection.fileFormats.length === 0;

      // If fileFormat is `undefined`, do not filter by fileFormats
      if ( fileFormat === undefined ) return true;

      // Otherwise, return collections that support the fileFormat
      return (
        !collection.fileFormats ||
        collection.fileFormats.length === 0 ||
        collection.fileFormats.includes(fileFormat)
      );
    });

    if ( !filteredCollections.length ) return null;

    // Apply ocred filtering
    if ( typeof ocred !== 'undefined' ) {
      filteredCollections = filteredCollections.filter((collection) => collection.applyOcr === ocred);
    }

    return filteredCollections.map((collection: ICollection) => ({
      id: collection.id.toString(),
      name: collection.name,
      props: {
        description: collection.description || ''
      }
    }));
  }
);

export const selectCollectionsAsOptionsByFileType = createSelector(
  [
    selectCollections,
    (_:any, props:{ fileFormat?:FileFormats | null, ocred?: boolean }) => props
  ],
  ( collections:ICollection[] | null, { fileFormat, ocred }) => {
    if ( !collections ) return null;

    let filteredCollections = collections.filter((collection: ICollection) => {
      // If fileFormat is `null`, return collections with no fileFormats or empty fileFormats
      if ( fileFormat === null ) return !collection.fileFormats || collection.fileFormats.length === 0;

      // If fileFormat is `undefined`, do not filter by fileFormats
      if ( fileFormat === undefined ) return true;

      // Otherwise, return collections that support the fileFormat
      return (
        !collection.fileFormats ||
        collection.fileFormats.length === 0 ||
        collection.fileFormats.includes(fileFormat)
      );
    });

    if ( !filteredCollections.length ) return null;

    // Apply ocred filtering
    if ( typeof ocred !== 'undefined' ) {
      filteredCollections = filteredCollections.filter((collection) => collection.applyOcr === ocred);
    }

    return filteredCollections.map((collection: ICollection) => ({
      value: collection.id.toString(),
      label: collection.name,
      props: {
        description: collection.description || ''
      }
    }));
  }
);
