import { FC, Fragment, useEffect } from 'react';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Actions
import { ClientsActions } from 'app/store/Clients/Clients.slice';
// Mui
import { Box, Container, Toolbar, Typography } from '@mui/material';
// 
import AccountsFilter from './AccountsFilter';
import AccountsList from './AccountsList';
import AccountsFab from './AccountsFab';
// i18next
import { useTranslation } from 'react-i18next';

const ClientAccountsPage:FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    // Client account fetching global, see 'Users.middleware'
    return () => {
      dispatch(ClientsActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t('pages.clientAccounts.title')}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <AccountsFilter />
          <AccountsList />
        </Container>
      </Box>
      <AccountsFab />
    </Fragment>
  )
}

export default ClientAccountsPage;
