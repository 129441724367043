import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// Services
import LocalStorageService from 'app/services/LocalStorage.service';

// EN
import en_common from 'translations/en/common.json';
import en_buttons from 'translations/en/buttons.json';
import en_form from 'translations/en/form.json';
import en_pages from 'translations/en/pages.json';
import en_validations from 'translations/en/validations.json';
import en_notifications from 'translations/en/notifications.json';
import en_labels from 'translations/en/labels.json';
import en_dialogs from 'translations/en/dialogs.json';
import en_components from 'translations/en/components.json';
// ES
import es_common from 'translations/es/common.json';
import es_buttons from 'translations/es/buttons.json';
import es_form from 'translations/es/form.json';
import es_pages from 'translations/es/pages.json';
import es_validations from 'translations/es/validations.json';
import es_notifications from 'translations/es/notifications.json';
import es_labels from 'translations/es/labels.json';
import es_dialogs from 'translations/es/dialogs.json';
import es_components from 'translations/es/components.json';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: LocalStorageService.getLanguage(),
    whitelist: ["en", "es"],
    debug: false,
    detection: {
      order: ["localStorage", "cookie"],
      caches: ["localStorage", "cookie"],
    },
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translations: {
          common: en_common,
          buttons: en_buttons,
          form: en_form,
          pages: en_pages,
          validations: en_validations,
          notifications: en_notifications,
          labels: en_labels,
          dialogs: en_dialogs,
          components: en_components
        }
      },
      es: {
        translations: {
          common: es_common,
          buttons: es_buttons,
          form: es_form,
          pages: es_pages,
          validations: es_validations,
          notifications: es_notifications,
          labels: es_labels,
          dialogs: es_dialogs,
          components: es_components
        }
      }
    }
  });

export default i18n;
