import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { batchChargeInvoices, chargeInvoice } from 'app/store/BillingCharges/BillingCharges.async';
// Selectors
import { selectBatchInvoicesIds } from 'app/store/BillingInvoices/BillingInvoices.selectors';
import { selectLoading } from 'app/store/BillingCharges/BillingCharges.selectors';
// Mui
import { Box, Divider, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Select, Button, Checkbox, LoadingButton } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  accountNumber: string;
  accountType: string;
  city: string;
  line: string;
  state: string;
  zipCode: string;
  checkName: string;
  checkType: string;
  routingNumber: string;
  acceptTheTermsAndConditions: boolean;
}

type Props = {
  open: boolean;
  onClose: () => void;
  invoiceId?: number;
}

const ACHPaymentFormDialog:FC<Props> = ({
  // Props
  open, onClose, invoiceId
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const batchInvoicesIds = useAppSelector(selectBatchInvoicesIds);
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState:{ errors } } = useForm<IFormData>({
    defaultValues: {
      accountNumber: '',
      accountType: '',
      city: '',
      line: '',
      state: '',
      zipCode: '',
      checkName: '',
      checkType: '',
      routingNumber: '',
      acceptTheTermsAndConditions: false
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { city, line, state, zipCode, ...otherData } = data;
    const check = {
      ...otherData,
      billingAddress: { city, line, state, zipCode }
    };
    try {
      if ( invoiceId ){
        await dispatch(chargeInvoice({
          check,
          invoiceId
        })).unwrap();
      } else {
        await dispatch(batchChargeInvoices({
          check,
          invoiceIds: batchInvoicesIds
        })).unwrap();
      }
      onClose();
    } catch(error){}
  });

  const accountTypeOptions = [
    { id: '', name: t('pages.billingAndSubscriptions.chooseAccountType') },
    { id: 'checking', name: t('pages.billingAndSubscriptions.checking') },
    { id: 'savings', name: t('pages.billingAndSubscriptions.savings') },
  ];

  const checkTypeOptions = [
    { id: '', name: t('pages.billingAndSubscriptions.chooseRoutingType') },
    { id: 'business', name: t('pages.billingAndSubscriptions.business') },
    { id: 'personal', name: t('pages.billingAndSubscriptions.personal') },
  ];

  const actions = (
    <Fragment>
      <Button
        name="Cancel ACH payment dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Pay ACH payment dialog"
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.pay')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.billingAndSubscriptions.achPaymentFormTitle')}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      <Controller
        control={control} name="accountNumber"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('pages.billingAndSubscriptions.accountNumber')}
            error={Boolean(errors.accountNumber)}
            helperText={errors.accountNumber?.message || ''}
            required
          />
        )}
      />
      <Controller
        control={control} name="accountType"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('pages.billingAndSubscriptions.accountType')}
            options={accountTypeOptions}
            error={Boolean(errors.accountType)}
            helperText={errors.accountType?.message || ''}
            required
          />
        )}
      />
      <Controller
        control={control} name="line"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('labels.address')}
            error={Boolean(errors.line)}
            helperText={errors.line?.message || ''}
            required
          />
        )}
      />
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Controller
          control={control} name="city"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.city')}
              error={Boolean(errors.city)}
              helperText={errors.city?.message || ''}
              required
            />
          )}
        />
        <Controller
          control={control} name="state"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.state')}
              error={Boolean(errors.state)}
              helperText={errors.state?.message || ''}
              required
            />
          )}
        />
        <Controller
          control={control} name="zipCode"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.zipCode')}
              error={Boolean(errors.zipCode)}
              helperText={errors.zipCode?.message || ''}
              required
            />
          )}
        />
      </Box>
      <Controller
        control={control} name="checkName"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('pages.billingAndSubscriptions.accountHolderName')}
            error={Boolean(errors.checkName)}
            helperText={errors.checkName?.message || ''}
            required
          />
        )}
      />
      <Controller
        control={control} name="checkType"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            label={t('pages.billingAndSubscriptions.checkType')}
            options={checkTypeOptions}
            error={Boolean(errors.checkType)}
            helperText={errors.checkType?.message || ''}
            required
          />
        )}
      />
      <Controller
        control={control} name="routingNumber"
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Input
            {...field}
            label={t('pages.billingAndSubscriptions.routingNumber')}
            error={Boolean(errors.routingNumber)}
            helperText={errors.routingNumber?.message || ''}
            required
          />
        )}
      />
      <Divider sx={{ my: 1 }} />
      <Box sx={{ maxHeight: 200, overflowY: 'scroll'}}>
        <Typography variant="h6">{t('pages.billingAndSubscriptions.paymentTermAndConditions')}</Typography>
        <Typography variant="button">{t('pages.billingAndSubscriptions.authorization')}</Typography>
        <Typography variant="body2">{t('pages.billingAndSubscriptions.authorizationContent')}</Typography>
        <Typography variant="button">{t('pages.billingAndSubscriptions.receipt')}</Typography>
        <Typography variant="body2">{t('pages.billingAndSubscriptions.receiptContent')}</Typography>
        <Typography variant="button">{t('pages.billingAndSubscriptions.contact')}</Typography>
        <Typography variant="body2">{t('pages.billingAndSubscriptions.contactContent')}</Typography>
      </Box>
      
      <Controller
        control={control} name="acceptTheTermsAndConditions"
        rules={{ required: isRequired }}
        render={({ field:{ onChange, ...otherField }}) => (
          <Checkbox
            {...otherField}
            onChange={(_, checked:boolean) => onChange(checked)}
            label={t('pages.billingAndSubscriptions.acceptTermsAndConditions')}
            error={Boolean(errors.acceptTheTermsAndConditions)}
            helperText={errors.acceptTheTermsAndConditions?.message || ''}
            required
          />
        )}
      />
    </Dialog>
  )
}

export default ACHPaymentFormDialog;
