import { FC, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Models
import IOption from 'app/models/Option';
import ITeam from 'app/models/Team';
import IUser from 'app/models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { getUsers } from 'app/store/Users/Users.async';
import { createTeam, updateTeam } from 'app/store/Teams/Teams.async';
// Selectors
import { selectUsersAsOptions } from 'app/store/Users/Users.selectors';
import { selectLoading } from 'app/store/Teams/Teams.selectors';
// Mui
import { Autocomplete, TextField } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, Input, LoadingButton } from 'app/components/Mui';
// Utilities
import { isRequired } from 'app/utilities/Validations';
import { sortByName } from 'app/utilities/SortBy';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
  description: string;
  members: any;
  caseNotificationPreferences: {
    recipientIds: IUser[];
  };
}

type Props = {
  open: boolean;
  onClose: () => void;
  team?: ITeam;
}

const TeamFormDialog:FC<Props> = ({
  // Props
  team, open, onClose
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);
  const usersOptions = useAppSelector(selectUsersAsOptions);

  const { control, handleSubmit, formState: { errors }, setError, clearErrors, setValue, watch } = useForm<IFormData>({
    defaultValues: {
      name: team?.name || '',
      description: team?.description || '',
      members: team?.members || [],
      caseNotificationPreferences: {
        recipientIds: team?.caseNotificationPreferences?.recipients || []
      }
    }
  });

  const membersWatcher = watch('members');
  const recipientIdsWatcher = watch('caseNotificationPreferences.recipientIds');
  const membersOptions = usersOptions?.filter(user => membersWatcher.some((member:any) => member.id === user.id));

  useEffect(() => {
    setValue('caseNotificationPreferences.recipientIds', recipientIdsWatcher.filter((recipient:any) => membersWatcher.some((m:any) => m.id === recipient.id)));
    // eslint-disable-next-line
  }, [membersWatcher]);

  const onSubmit = handleSubmit((data:IFormData) => {
    const { name, description, members, caseNotificationPreferences } = data;
    const nextData:any = {
      name,
      memberIds: members.map((member:any) => member.id)
    };
    if ( description ) nextData['description'] = description;
    if ( caseNotificationPreferences.recipientIds.length ) nextData['caseNotificationPreferences'] = {
      recipientIds: caseNotificationPreferences.recipientIds.map((recipient) => recipient.id)
    }
    if ( !nextData.memberIds.length ){
      setError('members', { type: 'required', message: 'This field is required' });
      return;
    } else {
      clearErrors();
    }

    if ( team?.id ){
      asyncUpdateTeam({ teamId: team.id, data: nextData });
    } else {
      asyncCreateTeam(nextData);
    }
  });

  const asyncCreateTeam = async (data:any) => {
    try {
      await dispatch(createTeam(data)).unwrap();
      onClose();
    } catch(err){}
  }

  const asyncUpdateTeam = async (data:any) => {
    try {
      await dispatch(updateTeam(data)).unwrap();
      onClose();
    } catch(err){}
  }

  useEffect(() => {
    if ( !usersOptions ) dispatch(getUsers({}));
    // eslint-disable-next-line
  }, []);

  const actions = (
    <Fragment>
      <Button
        name="Cancel team dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name={`${team ? 'update' : 'create'} team dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{team ? t('buttons.update') : t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={team ? t('pages.teams.updateFormTitle') : t('pages.teams.createFormTitle')}
      actions={actions}
    >
      <form noValidate>
        {/* Name */}
        <Controller
          control={control} name="name"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.name')}
              error={Boolean(errors.name)}
              helperText={errors.name?.message || ''}
              required
            />
          )}
        />

        {/* Name */}
        <Controller
          control={control} name="description"
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.description')}
            />
          )}
        />

        <Controller
          control={control} name="members"
          rules={{ required: isRequired }}
          render={({ field:{ value, onChange }}) => (
            <Autocomplete
              value={[...value].sort((a:any,b:any) => sortByName(a,b,'name'))}
              onChange={(_:any, nextValue:any) => onChange(nextValue)}
              options={usersOptions || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('labels.members')}
                  error={Boolean(errors.members)}
                  helperText={(errors.members?.message as string) || ''}
                  required={true}
                  fullWidth
                  margin="normal"
                />
              )}
              multiple
              filterSelectedOptions
              getOptionLabel={(option:IOption) => option.name}
              isOptionEqualToValue={(option:IOption, value:IOption) => option.id === value.id}
              renderOption={(props, option: any) => (
                <li {...props} key={option.id}>{`${option.name} (${option.role})`}</li>
              )}
            />
          )}
        />

        <Controller
          control={control} name="caseNotificationPreferences.recipientIds"
          render={({ field:{ value, onChange }}) => (
            <Autocomplete
              value={[...value].sort((a:any,b:any) => sortByName(a,b,'name'))}
              onChange={(_:any, nextValue:any) => onChange(nextValue)}
              options={membersOptions || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('labels.notificationRecipients')}
                  fullWidth
                  margin="normal"
                />
              )}
              multiple
              filterSelectedOptions
              getOptionLabel={(option:IOption) => option.name}
              isOptionEqualToValue={(option:IOption, value:IOption) => option.id === value.id}
              renderOption={(props, option: any) => (
                <li {...props} key={option.id}>{`${option.name} (${option.role})`}</li>
              )}
            />
          )}
        />
      </form>
    </Dialog>
  )
}

export default TeamFormDialog;
