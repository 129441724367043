import React, { useCallback } from 'react';
// Models
import { IPage } from '@root/models/Page';
// Selectors
import { selectAllPages, selectPageEntities, selectFilteredDocumentsPages, selectFilteredDocumentPages } from 'app/store/page/page.selectors';
import { selectDuplicatesList } from 'app/store/Cases/Cases.selectors';
// Lab
import { Box, Button, FormHelperText, TextField } from '@mui/material';
// Utilities
import { sortByStapleOrder } from '@root/utilities/sort';
import { selectSearchPagesList } from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
// i18next
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store';
import { useAppSelector } from 'app/hooks/useStore';

type Props = {
  isFilesPage: boolean;
  isWorkspacePage: boolean;
  isSearchPage: boolean;
  isDuplicatePage: boolean;
  page: IPage;
  workspacePages: IPage[];
  prevDocumentId: number;
  onFetchPage: (documentId:number, pageNum:number, prevDocumentId?:number, nextTargetPageNum?:number|null) => void;
  targetPageNum?: number | null;
};

const CardPageNavigation:React.FC<Props> = ({
  // Props
  isFilesPage, isWorkspacePage, isSearchPage, isDuplicatePage, page, workspacePages, prevDocumentId, onFetchPage, targetPageNum
}) => {
  const { t } = useTranslation();
  // State
  const allPages:Array<IPage> | null = useAppSelector(selectAllPages);
  const pageEntities:Record<number, Record<number, IPage>> = useAppSelector(selectPageEntities);
  const documentsPages = useAppSelector(selectFilteredDocumentsPages) || [];
  const documentPages:Array<IPage> = useAppSelector((state:RootState) => selectFilteredDocumentPages(state, { documentId: page.documentId })) || [];
  const duplicates = useAppSelector(selectDuplicatesList);
  const searchedPages = useAppSelector(selectSearchPagesList);

  const duplicatePages = duplicates
    ? Object.values(duplicates).reduce((acc, cur) => {
        if ( cur.some((p) => p.documentId === page.documentId && p.pageNum === page.pageNum) ){
          acc = cur;
        }
        return acc;
      }, [])
    : []
  ;

  const pages = isSearchPage
    ? searchedPages
      : isDuplicatePage
        ? duplicatePages
        : isFilesPage
          ? documentsPages
          : isWorkspacePage
            ? workspacePages
            : []
  ;
  const pageIndex = pages.findIndex((p:IPage) => p.documentId === page.documentId && p.pageNum === (targetPageNum ? targetPageNum : page.pageNum));

  const [ goTo, setGoTo ] = React.useState<number | string>(page.pageNum);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
    // eslint-disable-next-line
  }, []);

  const handleKeydown = (event:any) => {
    let hasActiveElement = false;

    const activeElement = document.activeElement;
    const inputs = ['input', 'select', 'button', 'textarea'];
    const classes = ['ql-editor'];

    if ( activeElement && (
      inputs.includes(activeElement.tagName.toLowerCase()) ||
      classes.includes(activeElement.className)
    )) hasActiveElement = true;

    if ( !hasActiveElement ){
      // Arrow left
      if ( event.keyCode === 37 && !isPrevDisabled ) handlePageChange(false);
      // Arrow right
      if ( event.keyCode === 39 && !isNextDisabled ) handlePageChange(true);
    }
  }

  const checkIsNextPageDisabled = useCallback((isNext:boolean, cb?:any) => {
    let nextPageIndex = isNext ? pageIndex + 1 : pageIndex - 1;

    let nextPage = pages[nextPageIndex];

    if ( typeof nextPage === 'undefined' ) return;

    let nextDocumentId = nextPage ? nextPage.documentId : null;
    let nextPageNum = nextPage ? nextPage.pageNum : null;
    let nextTargetPageNum = null;

    if ( isSearchPage && nextPage ){
      const pageEntity = pageEntities[nextDocumentId][nextPageNum];
      if ( pageEntity && pageEntity.staple && pageEntity.staple.order > 1 ){
        const stapleId = pageEntity.staple.id;
        if ( allPages ){
          const staplePages = allPages.filter((p:IPage) => p.staple && p.staple.id === stapleId).sort(sortByStapleOrder);
          const mainStaplePage = staplePages[0];
          if ( mainStaplePage ){
            nextDocumentId = mainStaplePage.documentId;
            nextPageNum = mainStaplePage.pageNum;
            nextTargetPageNum = nextPage.pageNum;
          }
        }
      }
    }

    const nextPageIsSecondaryPage = nextPage.staple && nextPage.staple.order > 1;

    if ( !nextPageIsSecondaryPage ){
      if ( typeof nextPage === 'undefined' ) return true;
      if ( cb ) cb(nextDocumentId, nextPageNum, page.documentId, nextTargetPageNum);
      return false;
    } else {
      while ( !(!nextPage.staple || nextPage.staple.order === 1) ){
        nextPageIndex = isNext ? nextPageIndex + 1 : nextPageIndex - 1;
        nextPage = pages[nextPageIndex];
        if ( typeof nextPage === 'undefined' ) return true;
        nextDocumentId = nextPage.documentId;
        nextPageNum = nextPage.pageNum;
      }
      if ( cb ) cb(nextDocumentId, nextPageNum, page.documentId, nextTargetPageNum);
      return false;
    }
    // eslint-disable-next-line
  }, [pages]);

  const isPrevPageDisabled = checkIsNextPageDisabled(false);
  const isNextPageDisabled = checkIsNextPageDisabled(true);

  const handlePageChange = (isNext:boolean) => {
    checkIsNextPageDisabled(isNext, (nextDocumentId:any, nextPageNum:any, documentId:any, nextTargetPageNum:any) => {
      onFetchPage(nextDocumentId, nextPageNum, documentId, nextTargetPageNum)
    })
  }

  const handleSubmit = (event:React.FormEvent) => {
    event.preventDefault();
    onFetchPage(page.documentId, Number(goTo));
  }

  const isPrevDisabled = pageIndex === 0;
  const isNextDisabled = pageIndex === pages.length - 1;

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FormHelperText>{t('dialogs.cardPage.stapledPagesSkipped')}</FormHelperText>
        <Button
          disabled={isPrevDisabled || isPrevPageDisabled}
          onClick={() => handlePageChange(false)}
          color="primary"
          variant="outlined"
        >{t('buttons.prev')}</Button>
        <Button
          disabled={isNextDisabled || isNextPageDisabled}
          onClick={() => handlePageChange(true)}
          color="primary"
          variant="outlined"
        >{t('buttons.next')}</Button>
        {isFilesPage ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
            onSubmit={handleSubmit}
            component="form"
          >
            <TextField
              aria-label="Go to input"
              sx={{
                width: 142
              }}
              value={goTo}
              onChange={(event:React.ChangeEvent<HTMLInputElement>) => {
                const re = /^[0-9\b]+$/;
                if (event.target.value === '' || re.test(event.target.value)) {
                  setGoTo(event.target.value)
                }
              }}
              margin="none"
              size="small"
              InputProps={{
                sx: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  pr: 0,
                  height: 36.5
                },
                endAdornment: (
                  <FormHelperText
                    sx={{ p: 0, mt: 0, fontSize: 16, whiteSpace: 'nowrap' }}
                  >{`/ ${documentPages.length}`}</FormHelperText>
                )
              }}
            />
            <Button
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, whiteSpace: 'nowrap' }}
              type="submit"
              disabled={typeof documentPages[Number(goTo) - 1] === 'undefined'}
              color="primary"
              variant="contained"
            >{t('buttons.goToPage')}</Button>
          </Box>
        ) : (
          <FormHelperText sx={{ mt: 0 }}>
            {t('dialogs.cardPage.pageOfPages', { currentPage: pageIndex + 1, allPages: pages.length })}
          </FormHelperText>
        )}
      </Box>
    </React.Fragment>
  )
}

export default CardPageNavigation
