import { MouseEvent, FC, useState, Fragment, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import UserRoles from 'app/types/UserRoles';
// Models
import { IMyUser } from 'app/models/User';
import IEpisode from 'app/models/Episode';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { deleteEpisode } from 'app/store/Episodes/Episodes.async';
// Actions
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
import { EpisodeFactsActions } from 'app/store/EpisodeFacts/EpisodeFacts.slice';
// Selectors
import { selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { selectSelectedEpisodeId } from 'app/store/Episodes/Episodes.selectors';
import { selectSortedEpisodeFacts } from 'app/store/EpisodeFacts/EpisodeFacts.selectors';
// Mui
import { Theme, Box, Typography, Tooltip, CircularProgress } from '@mui/material';
// Icons
import {
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ContentCopy as ContentCopyIcon,
  DeleteOutline as DeleteOutlineIcon,
  StarBorder as StarBorderIcon,
  Star as StarIcon
} from '@mui/icons-material';
// Compoennts
import { IconButton } from 'app/components/Mui';
import { Bull } from 'app/components/Utilities';
import EpisodeForm from 'app/components/EpisodeForm';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
import useToggle from 'app/hooks/useToggle';
import useFormattedDate from 'app/hooks/useFormattedDate';
import useFormattedTime from 'app/hooks/useFormattedTime';
// Context
import { usePageEpisodesContext } from 'app/context/PageEpisodes.context';
// 
import EpisodeFormDialog from './EpisodeFormDialog';
// ToDO
import { IPage } from '@root/models/Page';
import PageActions from 'app/store/page/page.actions';
import { generateWorkspaceOrder } from 'app/store/page/page.service';
import { selectPage } from 'app/store/page/page.selectors';
import { useLocation } from 'react-router-dom';
import { getSearchParam } from 'app/utilities/Utilities';
// End: ToDO

type Props = {
  episode: IEpisode;
}

const EpisodeItem:FC<Props> = ({
  // Props
  episode
}) => {
  const { t } = useTranslation();

  const { search } = useLocation<{ search:string }>();
  const searchEpisodeId = getSearchParam(search, 'episodeId');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const myUser = useAppSelector(selectMyUser) as IMyUser;
  // ToDO
  const page = useAppSelector((state) => selectPage(state as any)) as IPage;
  // End: ToDO
  const selectedEpisodeId = useAppSelector(selectSelectedEpisodeId);
  const facts = useAppSelector(selectSortedEpisodeFacts);

  const { isArchived } = useCaseStatus();
  const { open, toggle } = useToggle();

  const formattedDate = useFormattedDate(episode.date);
  const formattedTime = useFormattedTime(episode.time);

  const { onDelete } = usePageEpisodesContext();

  const [ stateLoading, setStateLoading ] = useState(false);

  const isEpisodeActive = selectedEpisodeId === episode.id;
  const isEpisodeParent = page.parentEpisodeId === episode.id;

  const handleScrollToPageNum = (pageNum:number) => {
    // Scroll to page image
    const imageElem = document.querySelector(`[react-page-num-dialog="${pageNum}"]`);
    if ( !imageElem ) return;
    imageElem.scrollIntoView();
  }

  useEffect(() => {
    if ( isEpisodeParent && episode.id !== selectedEpisodeId ){
      dispatch(EpisodesActions.setSelectedEpisodeId(episode.id))
    }
    // eslint-disable-next-line
  }, [isEpisodeParent]);

  useLayoutEffect(() => {
    if (
      isEpisodeParent ||
      (searchEpisodeId && searchEpisodeId === episode.id.toString())
    ) handleScrollToPageNum(episode.pageNum);
    // eslint-disable-next-line
  }, []);

  const handleClick = (event:MouseEvent<HTMLButtonElement> | null) => () => {
    if ( event ) event.stopPropagation();

    dispatch(EpisodesActions.setSelectedEpisodeId(isEpisodeActive ? null : episode.id));

    if ( !isEpisodeActive ) handleScrollToPageNum(episode.pageNum);
  }

  const handleDuplicateEpisode = (event:MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if ( facts && facts.length ){
      for ( let fact of facts ){
        dispatch(EpisodeFactsActions.createFact({...fact, episodeId: -1 }))
      }
    }

    toggle();
  }

  const handleMarkEpisodeAsParent = (event:MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    asyncPatchPage(
      isEpisodeParent ? null : episode.id,
      isEpisodeParent ? undefined : episode.date,
      isEpisodeParent ? undefined : episode.time
    );
  }

  const handleDeleteEpisode = async (event:MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setStateLoading(true)

    try {
      if ( isEpisodeParent ) await asyncPatchPage(null, undefined, undefined);
      await dispatch(deleteEpisode(episode.id));
      onDelete(episode.id)
    } catch(error){} finally {
      setStateLoading(false);
    }
  }

  // ToDO
  const asyncPatchPage = async (parentEpisodeId:number | null, date:string | undefined, time:string | undefined) => {
    // Patch page
    const nextPageData:any = {
      version: page.version,
      parentEpisodeId
    };
    if ( page.inWorkspace ){
      nextPageData['workspaceOrder'] = generateWorkspaceOrder(null, {
        workspaceOrder: page.workspaceOrder,
        date,
        time
      });
    }
    return new Promise((resolve) => {
      dispatch(PageActions.patchPage(page.documentId, page.pageNum, nextPageData));
      setTimeout(() => {
        resolve(`Page updated`)
      }, 500);
    });
  }

  const myRolePresenterOrClient = myUser.role === UserRoles.Presenter || myUser.role === UserRoles.Client;

  const isButtonHidden = (myRolePresenterOrClient || trialMode) || (page.staple && page.staple.order !== 1);
  const isButtonDisabled = isArchived || stateLoading;

  return (
    <Fragment>
      <Box
        sx={{
          bgcolor: 'white',
          border: (theme:Theme) => `1px solid ${isEpisodeActive ? theme.palette.primary.light : theme.palette.divider}`,
          borderRadius: 1,
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            bgcolor: (theme:Theme) => isEpisodeActive ? theme.palette.primary.light : 'transparent',
            py: 2,
            pl: 2,
            pr: 4,
            '&:hover': { cursor: 'pointer' }
          }}
          onClick={handleClick(null)}
        >
          <Box>
            <IconButton
              name={`Toggle episode ${episode.id}`}
              onClick={handleClick}
              size="small"
            >{isEpisodeActive ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {formattedDate ? (
              <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {formattedDate}{formattedTime ? ` ${formattedTime}` : ''}
              </Typography>
            ) : null}
            <Typography
              sx={{ fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden' }}
              variant="subtitle2"
            >
              {episode.author?.name || null}
              {episode.author?.name && episode.type ? <Bull /> : null}
              {episode.type || null}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {isButtonHidden && isEpisodeParent ? (
              <Tooltip title={t('components.pageEpisodes.parentEpisode')}>
                <IconButton
                  name="Parent episode"
                  onClick={() => null}
                  size="small"
                  color="primary"
                ><StarIcon fontSize="small" /></IconButton>
              </Tooltip>
            ) : null}
            {isButtonHidden ? null : (
              <Fragment>
                {stateLoading ? (
                  <CircularProgress size={24} thickness={4} />
                ) : null}
                <Tooltip title={t('components.pageEpisodes.duplicateEpisode')}>
                  <IconButton
                    name={`Duplicate episode ${episode.id}`}
                    disabled={isButtonDisabled}
                    onClick={handleDuplicateEpisode}
                    size="small"
                  ><ContentCopyIcon fontSize="small" /></IconButton>
                </Tooltip>
                <Tooltip title={
                  isEpisodeParent
                  ? t('components.pageEpisodes.markAsNotParent')
                  : t('components.pageEpisodes.markAsParent')}
                >
                  <IconButton
                    name={
                      isEpisodeParent
                      ? `Mark episode as not parent ${episode.id}`
                      : `Mark as a parent episode ${episode.id}`
                    }
                    disabled={isButtonDisabled}
                    onClick={handleMarkEpisodeAsParent}
                    size="small"
                    color={isEpisodeParent ? 'primary' : 'default'}
                  >{isEpisodeParent
                    ? <StarIcon fontSize="small" />
                    : <StarBorderIcon fontSize="small" />
                  }</IconButton>
                </Tooltip>
                <Tooltip title={t('components.pageEpisodes.deleteEpisode')}>
                  <IconButton
                    name={`Delete episode ${episode.id}`}
                    disabled={isButtonDisabled}
                    onClick={handleDeleteEpisode}
                    size="small"
                  ><DeleteOutlineIcon fontSize="small" /></IconButton>
                </Tooltip>
              </Fragment>
            )}
          </Box>
          {!page.staple || page.staple.order === 1 ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              
            </Box>
          ) : null}
        </Box>
        {isEpisodeActive ? (
          <Box sx={{ p: 4 }}>
            <EpisodeForm episode={episode} />
          </Box>
        ) : null}
      </Box>
      {open ? (
        <EpisodeFormDialog
          open={open}
          onClose={toggle}
          episode={{...episode, id: undefined }}
        />
      ) : null}
    </Fragment>
  )
}

export default EpisodeItem;
