import { FC, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Types
import CollectionPermissions from 'app/types/CollectionPermissions';
// Models
import { ICollection } from 'app/store/DMSCollections/DMSCollections.models';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createCollection, updateCollection } from 'app/store/DMSCollections/DMSCollections.async';
// Selectors
import { selectFileFormatsAsOptions } from 'app/store/Settings/Settings.selectors';
import { selectLoading } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { FormGroup, FormHelperText } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, Input, Switch, LoadingButton } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import { OutlineBlock } from 'app/components/Utilities';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
  description: string;
  fileFormats: string[];
  applyOcr: boolean;
  permissions: boolean;
}

type Props = {
  open: boolean;
  onClose: () => void;
  collection?: ICollection;
}

const CollectionFormDialog:FC<Props> = ({
  // Props
  collection, open, onClose
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const fileFormatOptions = useAppSelector(selectFileFormatsAsOptions);
  const loading = useAppSelector(selectLoading);

  const { control, handleSubmit, formState: { errors }} = useForm<IFormData>({
    defaultValues: {
      name: collection?.name || '',
      description: collection?.description || '',
      fileFormats: collection?.fileFormats || [],
      applyOcr: typeof collection?.applyOcr !== 'undefined' ? collection.applyOcr : true,
      permissions: collection && collection.permissions && collection.permissions.includes(CollectionPermissions.ClientUpload) ? true : false
    }
  });

  const onSubmit = handleSubmit((data:IFormData) => {
    const { description, permissions, fileFormats, ...otherData } = data;
    const nextData:any = otherData;
    if ( description ) nextData['description'] = description;
    if ( fileFormats && fileFormats.length ) nextData['fileFormats'] = fileFormats;
    if ( permissions ) nextData['permissions'] = [CollectionPermissions.ClientUpload]

    if ( collection?.id ){
      asyncUpdateCollection(collection.id, nextData);
    } else {
      asyncCreateCollection(nextData);
    }
  });

  const asyncCreateCollection = async (data:any) => {
    try {
      await dispatch(createCollection(data)).unwrap();
      onClose();
    } catch(err){}
  }

  const asyncUpdateCollection = async (collectionId:number, data:any) => {
    try {
      await dispatch(updateCollection({ collectionId, data })).unwrap();
      onClose();
    } catch(err){}
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel collection dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name={`${collection ? 'update' : 'create'} collection dialog`}
        loading={loading}
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >{collection ? t('buttons.update') : t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={collection ? t('pages.collections.updateFormTitle') : t('pages.collections.createFormTitle')}
      actions={actions}
    >
      <form noValidate>
        {/* Name */}
        <Controller
          control={control} name="name"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.name')}
              error={Boolean(errors.name)}
              helperText={errors.name?.message || ''}
              required
            />
          )}
        />

        {/* Description */}
        <Controller
          control={control} name="description"
          render={({ field }) => (
            <Input
              {...field}
              label={t('labels.description')}
            />
          )}
        />

        {/* Formats */}
        <Controller
          control={control} name="fileFormats"
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={fileFormatOptions}
              TextFieldProps={{
                label: t('pages.collections.fileFormats'),
                name: field.name,
                helperText: t('pages.collections.fileFormatsHelperText')
              }}
              multiple={true}
              filterSelectedOptions
            />
          )}
        />

        <FormGroup>
          <Controller
            control={control} name="applyOcr"
            render={({ field:{ onChange, ...otherField } }) => (
              <Switch
                {...otherField}
                label={t('pages.collections.applyOCR')}
                onChange={(_:any, checked:boolean) => onChange(checked)}
              />
            )}
          />
          <FormHelperText>{t('pages.collections.applyOCRHelperText')}</FormHelperText>
        </FormGroup>

        <OutlineBlock sx={{ mt: 6 }} label={t('pages.collections.permission')}>
          <FormGroup>
            <Controller
              control={control} name="permissions"
              render={({ field:{ onChange, ...otherField } }) => (
                <Switch
                  {...otherField}
                  label={t('pages.collections.clientUpload')}
                  onChange={(_:any, checked:boolean) => onChange(checked)}
                />
              )}
            />
          </FormGroup>
        </OutlineBlock>

      </form>
    </Dialog>
  )
}

export default CollectionFormDialog;
