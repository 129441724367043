import { FC, Fragment, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
// Mui
import { Theme, Box, FormHelperText } from '@mui/material';
// 
import useUploadContext from './UploadProvider/useUploadContext';
// 
import FilesPicker from './FilesPicker';
import FilesUploadProgress from './FilesUploadProgress';
import FilesUploaderError from './FilesUploaderError';
import FileItems from './FileItems';

const FilesUploader:FC = () => {
  const { t } = useTranslation();

  const { fileItems, onAdd, formState:{ isSubmitted } } = useUploadContext();

  const handleDrop = useCallback((files:File[]) => {
    onAdd(files);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: handleDrop,
    noClick: true
  });

  const isFileItemsEmpty = isSubmitted && fileItems.length === 0;

  return (
    <Fragment>
      {/* @ts-ignore */}
      <input {...getInputProps()} />

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FilesPicker onOpen={open} onDrop={handleDrop} />
        <Box sx={{ flexGrow: 1 }} />
        <FilesUploadProgress />
      </Box>

      <FilesUploaderError />

      <Box
        {...getRootProps()}
        sx={{
          flexGrow: 1,
          position: 'relative',
          width: '100%',
          border: (theme:Theme) => isDragActive
            ? `1px solid ${theme.palette.primary.main}`
            : isFileItemsEmpty
                ? `1px solid ${theme.palette.error.main}`
                : !fileItems.length ? `1px solid ${theme.palette.divider}` : 'none'
          ,
          borderRadius: 1,
          mb: 4
        }}
      ><FileItems fileItems={fileItems} /></Box>

      {isFileItemsEmpty ? (
        <FormHelperText sx={{ mt: -2, mb: 4 }} error>{t('dialogs.caseUploadDocuments.filesRequired')}</FormHelperText>
      ) : null}

    </Fragment>
  )
}

export default FilesUploader;