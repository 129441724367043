import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAccountCasesParams } from 'app/store/Cases/Cases.selectors';
import { selectCollectionsAsOptions } from 'app/store/DMSCollections/DMSCollections.selectors';
// Mui
import { FormHelperText, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
import { OutlineBlock } from 'app/components/Utilities';

type Props = {
  open: boolean;
  onClose: (nextCollectionIds?:string) => void;
}

const FilterPreferencesDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation();

  // State
  const collectionOptions = useAppSelector(selectCollectionsAsOptions);
  const params = useAppSelector(selectAccountCasesParams);

  const [ collectionIds, setCollectionIds ] = useState(params['sort.priority.collectionIds'].split(',').map(Number) || []);

  const handleClose = () => onClose();

  const handleChange = (value:any) => {
    setCollectionIds(value);
  }

  const handleClick = () => {
    onClose(collectionIds.join(','));
  }

  const actions = (
    <Fragment>
      <Button
        name="Decline check case dialog"
        onClick={handleClose}
      >{t('buttons.cancel')}</Button>
      <Button
        name="Accept check case dialog"
        onClick={handleClick}
        variant="contained"
        color="primary"
      >{t('buttons.save')}</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      title={t('dialogs.filterPreferences.title')}
      actions={actions}
    >
      <OutlineBlock sx={{
        mt: 2
      }} label={t('dialogs.filterPreferences.mostRecentDocument')}>
        <FormHelperText>{t('dialogs.filterPreferences.hint')}</FormHelperText>
      </OutlineBlock>
      <Autocomplete
        value={collectionIds}
        options={collectionOptions || []}
        onChange={handleChange}
        TextFieldProps={{
          label: t('labels.documentCollections'),
          name: 'collectionIds',
        }}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
            <Typography variant="subtitle1">{option.label}</Typography>
            {option.props.description ? (
              <Typography variant="caption">{option.props.description}</Typography>
            ) : null}
          </li>
        )}
        multiple={true}
        filterSelectedOptions
      />
    </Dialog>
  )
}

export default FilterPreferencesDialog;
