import { FC, Fragment, useState, useEffect } from 'react';
import dayjs from 'dayjs';
// Models
import IEpisodeAuthor from 'app/models/EpisodeAuthor';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Async
import { getAuthor } from 'app/store/EpisodeAuthors/EpisodeAuthors.async';
// Mui
import { Paper, Box, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button } from 'app/components/Mui';
import { InfoBlock, Loader } from 'app/components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  authorId: number;
};

const AuthorRegistryDetailsDialog:FC<Props> = ({
  // Props
  open, onClose, authorId
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();

  const [ stateAuthor, setStateAuthor ] = useState<IEpisodeAuthor | null>(null);
  const [ address, setAddress ] = useState<string[]>([]);

  useEffect(() => {
    const asyncGetAuthor = async () => {
      try {
        const author = await dispatch(getAuthor(authorId)).unwrap() as IEpisodeAuthor;
        setStateAuthor(author);
        if ( author.practiceLocation ){
          const { city, state, line } = author.practiceLocation;
          const addressArray = [];
          if ( city ) addressArray.push(city);
          if ( state ) addressArray.push(state);
          if ( line ) addressArray.push(line);
          setAddress(addressArray);
        }
      } catch(error){}
    }

    asyncGetAuthor();
    // eslint-disable-next-line
  }, []);

  const actions = (
    <Button
      name="Cancel author details dialog"
      onClick={onClose}
    >{t('buttons.close')}</Button>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.authorRegistry.detailsTitle')}
      actions={actions}
    >
      {!stateAuthor ? <Loader /> : (
        <Fragment>
          {stateAuthor.name ? (
            <Typography variant="body2" color="textSecondary">
              <b>{t('labels.name')}:&nbsp;</b>{stateAuthor.name}
            </Typography>
          ) : null}
          {stateAuthor.email ? (
            <Typography
              variant="body2" color="textSecondary"
              component="a" href={`mailto:${stateAuthor.email}`}
              sx={{ textDecoration: 'none' }}
            >
              <b>{t('labels.email')}:&nbsp;</b>{stateAuthor.email}
            </Typography>
          ) : null}
          {stateAuthor.phone ? (
            <Typography variant="body2" color="textSecondary">
              <b>{t('labels.phone')}:&nbsp;</b>{stateAuthor.phone}
            </Typography>
          ) : null}
          {stateAuthor.licenseNumber ? (
            <Typography variant="body2" color="textSecondary">
              <b>{t('labels.licenseNumber')}:&nbsp;</b>{stateAuthor.licenseNumber}
            </Typography>
          ) : null}
          {stateAuthor.licenseExpirationDate ? (
            <Typography variant="body2" color="textSecondary">
              <b>{t('labels.expirationDate')}:&nbsp;</b>{dayjs(stateAuthor.licenseExpirationDate).format('MMM DD, YYYY')}
            </Typography>
          ) : null}
          {stateAuthor.country ? (
            <Typography variant="body2" color="textSecondary">
              <b>{t('labels.country')}:&nbsp;</b>{stateAuthor.country}
            </Typography>
          ) : null}
          {address.length ? (
            <Typography variant="body2" color="textSecondary">
              <b>{t('labels.address')}:&nbsp;</b>{address.join(', ')}
            </Typography>
          ) : null}


          {stateAuthor.thirdPartyMatches && stateAuthor.thirdPartyMatches.length ? (
            <Box>
              <Typography variant="h6" sx={{ my: 2 }}>{t('pages.authorRegistry.externalDatasetMatches')}</Typography>
              {stateAuthor.thirdPartyMatches.map((match:any, index:number) => {
                const { record, source } = match;

                const recordAddress:string[] = [];
                if ( record?.practiceLocation?.line1 ) recordAddress.push(record.practiceLocation.line1);
                if ( record?.practiceLocation?.city ) recordAddress.push(record.practiceLocation.city);
                if ( record?.practiceLocation?.state ) recordAddress.push(record.practiceLocation.state);

                return (
                  <Paper key={`third part match ${index}`} sx={{ p: 4, '& + &': { mt: 2 } }} variant="outlined">
                    {source ? (
                      <InfoBlock label={t('labels.source')} value={source} direction="row" />
                    ) : null}
                    {record.firstName ? (
                      <InfoBlock label={t('labels.firstName')} value={record.firstName} direction="row" />
                    ) : null}
                    {record.middleName ? (
                      <InfoBlock label={t('labels.middleName')} value={record.middleName} direction="row" />
                    ) : null}
                    {record.lastName ? (
                      <InfoBlock label={t('labels.lastName')} value={record.lastName} direction="row" />
                    ) : null}
                    {record.profession ? (
                      <InfoBlock label={t('labels.profession')} value={record.profession} direction="row" />
                    ) : null}
                    {record.licenseNumber ? (
                      <InfoBlock label={t('labels.licenseNumber')} value={record.licenseNumber} direction="row" />
                    ) : null}
                    {record.licenseExpirationDate ? (
                      <InfoBlock label={t('labels.licenseExpirationDate')} value={dayjs(record.licenseExpirationDate).format('MMM DD, YYYY')} direction="row" />
                    ) : null}
                    {record.county ? (
                      <InfoBlock label={t('labels.county')} value={record.county} direction="row" />
                    ) : null}
                    {recordAddress.length ? (
                      <InfoBlock label={t('labels.address')} value={recordAddress.join(', ')} direction="row" />
                    ) : null}
                  </Paper>
                )
              })}
            </Box>
          ) : null}
        </Fragment>
      )}
    </Dialog>
  )
}

export default AuthorRegistryDetailsDialog;
