import { FC, Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createCaseDuplicate } from 'app/store/Cases/Cases.async';
import { getDocuments } from 'app/store/DMSDocuments/DMSDocuments.async';
import { getTeams } from 'app/store/Teams/Teams.async';
// Actions
import { DMSDocumentsActions } from 'app/store/DMSDocuments/DMSDocuments.slice';
import { TeamsActions } from 'app/store/Teams/Teams.slice';
// Selectors
import { selectLoading } from 'app/store/Cases/Cases.selectors';
import { selectDocumentsAsOptions } from 'app/store/DMSDocuments/DMSDocuments.selectors';
import { selectTeamsAsOptions } from 'app/store/Teams/Teams.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// Utilities
import { isRequired } from 'app/utilities/Validations';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  options: string[];
  documentIds: number[];
  teamId: number | null;
}

type Props = {
  open: boolean;
  onClose: () => void;
  caseId: number;
  casesListView?: boolean;
}

const CaseDuplicateFormDialog:FC<Props> = ({
  // Props
  open, onClose, caseId, casesListView = false
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);
  const documentOptions = useAppSelector(selectDocumentsAsOptions);
  const teamOptions = useAppSelector(selectTeamsAsOptions);

  const { handleSubmit, control, formState:{ errors }, setValue } = useForm<IFormData>({
    defaultValues: {
      options: [],
      documentIds: [],
      teamId: null
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { options, ...otherData } = data;

    const nextData:any = otherData;
    if ( options && options.length ) nextData['options'] = options

    try {
      await dispatch(createCaseDuplicate({ caseId, data: nextData })).unwrap();
      onClose();
    } catch(error){}
  });

  useEffect(() => {
    dispatch(getTeams({}));
    if ( casesListView ){
      dispatch(getDocuments({ insuranceCaseId: caseId }));
    }

    return () => {
      dispatch(TeamsActions.setInitialField('teams'));
      if ( casesListView ){
        dispatch(DMSDocumentsActions.resetState());
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleSelectAllOptions = () => {
    setValue('options', optionOptions.map(({ value }) => value));
  }

  const handleSelectAllDocumentsClick = () => {
    setValue('documentIds', documentOptions.map(({ value }) => value));
  }

  const optionOptions:any[] = [
    { value: 'episode.labels', label: t('labels.episodeLabels') },
    { value: 'episode.notes', label: t('labels.episodeNotes') },
    { value: 'episodeFacts', label: t('labels.episodeFacts') },
    { value: 'page.tag', label: t('labels.pageTag') },
    { value: 'page.colors', label: t('labels.pageColors') },
    { value: 'page.inWorkspace', label: t('labels.pageInWorkspace') },
    { value: 'page.highlights', label: t('labels.pageHighlights') },
    { value: 'document.labels', label: t('labels.documentLabels') }
  ];

  const actions = (
    <Fragment>
      <Button
        name="Cancel case duplicate dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Submit case duplicate dialog"
        type="submit"
        loading={loading}
        variant="contained"
        color="primary"
      >{t('buttons.create')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('dialogs.caseDuplicateForm.title')}
      actions={actions}

      PaperProps={{
        component: 'form',
        onSubmit,
        noValidate: true
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
        <Controller
          control={control} name="options"
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={optionOptions}
              TextFieldProps={{
                label: t('labels.options'),
                name: field.name
              }}
              multiple={true}
              limitTags={3}
              filterSelectedOptions
            />
          )}
        />
        <Button
          name="Select all options"
          sx={{ mt: 6.5 }}
          onClick={handleSelectAllOptions}
          variant="contained"
        >{t('buttons.all')}</Button>
      </Box>
      {/* Documents */}
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
        <Controller
          control={control} name="documentIds"
          rules={{ required: isRequired }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={documentOptions}
              TextFieldProps={{
                label: t('labels.documents'),
                name: field.name,
                required: true,
                error: Boolean(errors.documentIds),
                helperText: (errors.documentIds as any)?.message || '',
              }}
              groupBy={(option) => option.props.collection.name}
              renderGroup={(params) => (
                <Box key={params.key}>
                  <Box sx={{
                    bgcolor: 'rgb(238,238,238)',
                    color: 'rgba(0,0,0,0.87)',
                    fontWeight: 700,
                    py: 2,
                    px: 4
                  }}>{params.group}</Box>
                  {params.children}
                </Box>
              )}
              multiple={true}
              limitTags={3}
              filterSelectedOptions
            />
          )}
        />
        <Button
          name="Select all documents"
          sx={{ mt: 6.5 }}
          onClick={handleSelectAllDocumentsClick}
          variant="contained"
        >{t('buttons.all')}</Button>
      </Box>
      <Controller
        control={control} name="teamId"
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={teamOptions || []}
            TextFieldProps={{
              label: t('labels.team'),
              name: field.name
            }}
            renderOption={(props, option) => (
              <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
                <Typography variant="subtitle1">{option.label}</Typography>
                {option.props.description ? (
                  <Typography variant="caption">{option.props.description}</Typography>
                ) : null}
              </li>
            )}
          />
        )}
      />
    </Dialog>
  )
}

export default CaseDuplicateFormDialog;
