import { useCallback } from 'react';
// 
import useFileValidations, { VALIDATION_ERRORS } from './useFileValidations';
import { IFileItem, IFileItemsErrorGroup } from './useUploadContext';
import { IValidateError, ValidateErrorCodes } from 'app/store/DMSBatches/DMSBatches.models';

/// Main Hook Function
const useFileValidationErrors = () => {
  const { getDuplicateFileNameError, getMaxFileNameLengthError, getMaxFileSizeError } = useFileValidations();

  // Builds validation errors from UI-side checks
  const buildUIValidateErrors = useCallback((fileItems: IFileItem[]): Map<string, IValidateError[]> => {
    const errors = new Map<string, IValidateError[]>();

    fileItems.forEach((fileItem) => {
      // Run all validations and filter out null results
      const itemErrors: IValidateError[] = [
        getDuplicateFileNameError(fileItems, fileItem),
        getMaxFileSizeError(fileItem.file.size),
        getMaxFileNameLengthError(fileItem.file.name),
      ].filter(Boolean) as IValidateError[];
      
      if (itemErrors.length > 0) {
        errors.set(fileItem.id, itemErrors);
      }
    });

    return errors;
  }, [getDuplicateFileNameError, getMaxFileNameLengthError, getMaxFileSizeError]);

  // Creates a standardized error object with proper message and references
  const createStandardError = useCallback((error: IValidateError): IValidateError => {
    const isDuplicateError = (
      error.errorCode === ValidateErrorCodes.DuplicateName ||
      error.errorCode === ValidateErrorCodes.DuplicateContent
    );
    return isDuplicateError ? {
      ...error,
      errorMessage: VALIDATION_ERRORS[error.errorCode].message,
      references: error.references
    } : {
      errorCode: error.errorCode,
      errorMessage: VALIDATION_ERRORS[error.errorCode].message
    };
  }, []);

  // Merges UI and backend validation errors
  const mergeValidationErrors = useCallback((
    fileItems: IFileItem[],
    beValidateErrors: Map<string, IValidateError[]> = new Map()
  ): Map<string, IValidateError[]> => {
    const uiValidateErrors = buildUIValidateErrors(fileItems);
    const mergedErrors = new Map<string, IValidateError[]>(uiValidateErrors);

    // Process backend errors and merge them with UI errors
    beValidateErrors.forEach((errors, id) => {
      const uiErrors = mergedErrors.get(id) || [];
      const combinedErrors = [...uiErrors];

      errors.forEach(error => {
        const standardError = createStandardError(error);
        const existingErrorIndex = combinedErrors.findIndex(err => err.errorCode === error.errorCode);
        
        if (existingErrorIndex >= 0) {
          // Merge references for duplicate errors
          if (error.errorCode === ValidateErrorCodes.DuplicateName || 
              error.errorCode === ValidateErrorCodes.DuplicateContent) {
            const existingError = combinedErrors[existingErrorIndex];
            existingError.references = Array.from(new Set([
              ...(existingError.references || []),
              ...(standardError.references || [])
            ]));
          }
        } else {
          combinedErrors.push(standardError);
        }
      });

      mergedErrors.set(id, combinedErrors);
    });

    return mergedErrors;
  }, [buildUIValidateErrors, createStandardError]);

  // Sort function for file items based on errors
  const sortFileItemsByError = useCallback((a: IFileItem, b: IFileItem): number => {
    const aHasError = a.errors.length > 0 || !a.collectionId;
    const bHasError = b.errors.length > 0 || !b.collectionId;
    
    // Files with errors or missing collectionId should be at the top
    if (aHasError && !bHasError) return -1;
    if (!aHasError && bHasError) return 1;
    
    // If both have errors, sort by first error code
    if (a.errors.length > 0 && b.errors.length > 0) {
      return a.errors[0].errorCode.localeCompare(b.errors[0].errorCode);
    }
    
    return 0;
  }, []);

  // Assigns errors to file items and sorts them
  const assignErrorsToFileItems = useCallback((
    fileItems: IFileItem[],
    errors: Map<string, IValidateError[]>
  ): IFileItem[] => {
    return fileItems
      .map((fileItem) => {
        const fileErrors = errors.get(fileItem.id) || [];
        return { ...fileItem, errors: [...fileErrors] };
      })
      .sort(sortFileItemsByError);
  }, [sortFileItemsByError]);

  // Groups file items based on error type
  const groupFileItemsByErrorCode = useCallback((fileItems: IFileItem[]): IFileItemsErrorGroup[] => {
    const processedIds = new Set<string>();
    const errorGroups: IFileItemsErrorGroup[] = [];
    const duplicateGroups = new Map<string, { type: ValidateErrorCodes, fileItems: IFileItem[] }>();

    // Process duplicate errors first
    fileItems.forEach((fileItem) => {
      if (processedIds.has(fileItem.id)) return;

      // Find duplicate errors
      const duplicateContentError = fileItem.errors.find(
        e => e.errorCode === ValidateErrorCodes.DuplicateContent
      ) as IValidateError | undefined;

      const duplicateNameError = fileItem.errors.find(
        e => e.errorCode === ValidateErrorCodes.DuplicateName
      ) as IValidateError | undefined;

      // Create duplicate groups
      if (duplicateContentError || duplicateNameError) {
        const error = duplicateContentError || duplicateNameError;
        const errorType = error!.errorCode;
        
        const references = error!.references || [];
        const groupKey = [fileItem.id, ...references].sort().join(',');

        const groupEntry = duplicateGroups.get(groupKey) || { type: errorType, fileItems: [] };
        groupEntry.fileItems.push(fileItem);
        duplicateGroups.set(groupKey, groupEntry);
        processedIds.add(fileItem.id);
      } else if ( fileItem.errors.length > 0 ) {
        if ( fileItem.errors.length === 1 ) {
          // errorGroups.push({
          //   errorCode: fileItem.errors[0].errorCode,
          //   fileItems: [fileItem]
          // });
          // processedIds.add(fileItem.id);
          const errorCode = fileItem.errors[0].errorCode;

          // Check if an error group with the same errorCode already exists
          const existingGroup = errorGroups.find(group => group.errorCode === errorCode);

          if (existingGroup) {
            existingGroup.fileItems.push(fileItem);
          } else {
            errorGroups.push({
              errorCode,
              fileItems: [fileItem]
            });
          }

          processedIds.add(fileItem.id);
        }
      }
    });

    // Add duplicate groups to error groups
    duplicateGroups.forEach(group => {
      errorGroups.push({
        errorCode: group.type,
        fileItems: group.fileItems
      });
      group.fileItems.forEach(f => processedIds.add(f.id));
    });

    // Add remaining files that weren't processed yet
    const remainingFiles = fileItems.filter(fileItem => !processedIds.has(fileItem.id));
    if (remainingFiles.length > 0) {
      errorGroups.push({ fileItems: remainingFiles });
    }

    // Sort groups: error groups first, then by error type
    return errorGroups.sort((a, b) => {
      // Groups with error codes come first
      if (a.errorCode && !b.errorCode) return -1;
      if (!a.errorCode && b.errorCode) return 1;

      // Prioritize duplicate content errors
      if (a.errorCode === ValidateErrorCodes.DuplicateContent && b.errorCode !== ValidateErrorCodes.DuplicateContent) return -1;
      if (a.errorCode !== ValidateErrorCodes.DuplicateContent && b.errorCode === ValidateErrorCodes.DuplicateContent) return 1;

      return 0;
    });
  }, []);

  return { 
    mergeValidationErrors,
    assignErrorsToFileItems,
    groupFileItemsByErrorCode
  };
};

export default useFileValidationErrors;