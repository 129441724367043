import { FC, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Redux
import { useAppDispatch, useAppSelector } from "app/hooks/useStore";
// Async
import { getPrompts } from "app/store/AIChatPrompts/AIChatPrompts.async";
// Actions
import { AIChatPromptsActions } from "app/store/AIChatPrompts/AIChatPrompts.slice";
// Selectors
import { selectFilteredPrompts } from "app/store/AIChatPrompts/AIChatPrompts.selectors";
// Mui
import { Box, Container, Toolbar, Typography, Paper, List } from "@mui/material";
// Components
import DataLoading from "app/components/DataLoading";
// 
import AIPromptHubFilter from "./AIPromptHubFilter";
import AIPromptItem from "./AIPromptItem";
import AIPromptHubFab from "./AIPromptHubFab";

const AIPromptHubPage: FC = () => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const prompts = useAppSelector(selectFilteredPrompts);

  useEffect(() => {
    dispatch(getPrompts({}));

    return () => {
      dispatch(AIChatPromptsActions.resetState());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Toolbar variant="dense">
        <Typography variant="h6">{t("pages.aiPromptHub.title")}</Typography>
      </Toolbar>
      <Box sx={{ flexGrow: 1, overflowY: "auto" }} react-action="scroll">
        <Container sx={{ pt: 4, pb: 20 }} maxWidth="sm">
          <AIPromptHubFilter />
          <DataLoading
            data={prompts}
            text={t("pages.aiPromptHub.noData")}
          >{(data) => (
            <Paper variant="outlined">
              <List>
                {data.map((aiPrompt) => (
                  <AIPromptItem
                    key={`ai prompt item ${aiPrompt.id}`}
                    aiPrompt={aiPrompt}
                  />
                ))}
              </List>
            </Paper>
          )}</DataLoading>
        </Container>
      </Box>
      <AIPromptHubFab />
    </Fragment>
  );
};

export default AIPromptHubPage;
