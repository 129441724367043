import { FC, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectCaseReportTemplateSelected } from 'app/store/Cases/Cases.selectors';
import { selectLegacyReportTemplatesEnabled } from 'app/store/Accounts/Accounts.selectors';
// Mui
import { Box } from '@mui/material';
// Dialogs
import LEGACYReportFormDialog from 'app/dialogs/ReportFormDialog';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
import useToggle from 'app/hooks/useToggle';
// 
// import ReportFormDialog from 'app/dialogs/ReportPresetFormDialog';
import ReportFormDialog from './ReportFormDialog';

const ReportsFab:FC = () => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();

  // State
  const legacyReportTemplatesEnabled = useAppSelector(selectLegacyReportTemplatesEnabled);
  const caseReportTemplateSelected = useAppSelector(selectCaseReportTemplateSelected);

  const { isArchived } = useCaseStatus();

  const { open:openLegacyFormDialog, toggle:toggleLegacyFormDialog } = useToggle();
  const { open, toggle } = useToggle();

  const showLegacyCreateReportButton = legacyReportTemplatesEnabled && caseReportTemplateSelected;

  return (
    <Fragment>
      <Box sx={{
        position: 'fixed',
        right: 16,
        bottom: 16,
        display: 'flex',
        gap: 4
      }}>
        {showLegacyCreateReportButton ? (
          <Fab
            name="Create report"
            disabled={isArchived}
            onClick={toggleLegacyFormDialog}
            color="primary"
            variant="extended"
          >{t('pages.reports.legacyCreateButton')}</Fab>
        ) : null}
        <Fab
          name="Create report"
          disabled={isArchived}
          onClick={toggle}
          color="primary"
          variant="extended"
        >{t('pages.reports.createButton')}</Fab>
      </Box>
      {/* Create dialog */}
      {openLegacyFormDialog ? (
        <LEGACYReportFormDialog
          open={openLegacyFormDialog}
          onClose={toggleLegacyFormDialog}
          insuranceCaseId={Number(caseId)}
        />
      ) : null}
      {open ? (
        <ReportFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default ReportsFab;
