import { FC, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectEpisodeFactsDataAsString } from 'app/store/EpisodeFacts/EpisodeFacts.selectors';
// Mui
import { Box, Tooltip } from '@mui/material';
import {
  ContentCopy as ContentCopyIcon,
  Check as CheckIcon
} from '@mui/icons-material';
// Components
import { IconButton } from 'app/components/Mui';

let timeout:any = null;

const FactsDataCopyButton:FC = () => {
  const { t } = useTranslation();
  // State
  const factsString:string = useAppSelector(selectEpisodeFactsDataAsString);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const [ copied, setCopied ] = useState(false);

  useEffect(() => {
    return () => {
      if ( timeout ) clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( copied ){
      timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleClick = () => {
    if ( copied ) return;

    if ( typeof navigator.clipboard === 'undefined' ){
      if ( textAreaRef.current ){
        textAreaRef.current.focus();
        textAreaRef.current.select();

        try {
          const success = document.execCommand('copy');
          if ( success ) setCopied(true);
        } catch(err){}
      }
      return;
    }

    navigator.clipboard.writeText(factsString).then(() => {
      setCopied(true);
    }).catch(() => {
      console.error('Permission not granted')
    })
  }

  if ( !factsString ) return null;
  return (
    <Box sx={{ textAlign: 'right', mb: 4 }}>
      <Box
        ref={textAreaRef}
        sx={{
          position: 'fixed',
          top: -9999,
          left: -9999
        }}
        component="textarea"
        value={factsString}
        readOnly
      />
      <Tooltip title={copied ? t('labels.copied') : t('dialogs.episodeFacts.copyButton')}>
        <span>
          <IconButton
            name="Copy fact data as string"
            disabled={copied}
            onClick={handleClick}
            color={copied ? 'success' : 'primary'}
          >{copied ? <CheckIcon /> : <ContentCopyIcon />}</IconButton>
        </span>
      </Tooltip>
    </Box>

  )
}

export default FactsDataCopyButton;
