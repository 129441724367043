import { FC, Fragment } from 'react';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Type
import IEpisodeAuthor from 'app/models/EpisodeAuthor'
// Selectors
import { selectAuthorsPaging } from 'app/store/EpisodeAuthors/EpisodeAuthors.selectors';
// Mui
import { List, Paper } from '@mui/material';
// Components
import { Message, Loader } from 'app/components/Utilities';
// 
import AuthorRegistryListItem from './AuthorRegistryListItem';
// i18next
import { useTranslation } from 'react-i18next';

const AuthorRegistryList:FC = () => {
  const { t } = useTranslation();
  // State
  const authors = useAppSelector(selectAuthorsPaging)

  if ( !authors ) return <Loader />
  if ( !authors.length ) return <Message text={t('pages.authorRegistry.noData')} />;
  return (
    <Fragment>
      <Paper variant="outlined">
        <List>
          {authors.map((author:IEpisodeAuthor) => (
            <AuthorRegistryListItem
              key={`Author item ${author.id}`}
              author={author}
            />
          ))}
        </List>
      </Paper>
    </Fragment>
  )
}

export default AuthorRegistryList;
