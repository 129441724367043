import { createApi } from "@reduxjs/toolkit/query/react";
// Utilities
import baseQuery from "app/utilities/BaseQuery";

const dmsDocumentsApi = createApi({
  reducerPath: 'documentManagementService/documents.api',
  baseQuery,
  tagTypes: ['Comments'],
  endpoints: (builder) => ({
    getDocument: builder.query({
      query: (documentId:number | undefined) => `/dms/documents/${documentId}`,
      keepUnusedDataFor: 0
    }),
    getDocumentComments: builder.query({
      query: (documentId:number | undefined) => `/dms/documents/${documentId}/comments`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }:any) => ({ type: 'Comments', id }) as const),
              { type: 'Comments', id: 'LIST' },
            ]
          : [{ type: 'Comments', id: 'LIST' }]
    }),
    deleteComment: builder.mutation<void, { documentId:number; commentId:number }>({
      query: ({ documentId, commentId }) => ({
        url: `/dms/documents/${documentId}/comments/${commentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { commentId }) => [{ type: 'Comments', id:commentId }]
    }),
  })
});

export const {
  useGetDocumentQuery,
  useGetDocumentCommentsQuery,
  useDeleteCommentMutation
} = dmsDocumentsApi;

export default dmsDocumentsApi;
