import { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Types
import { DroppableIds } from 'app/types/ContentItem';
// Mui
import { Box, Container } from '@mui/material';
// Components
import DataLoading from 'app/components/DataLoading';
// Hooks
import { useReportPresetContext } from 'app/context/ReportPreset.context';
// 
import ContentDroppable from './ContentDroppable';
import ContentDraggable from './ContentDraggable';
import SelectedContentItem from './SelectedContentItem';

const ContainerContent:FC = () => {
  const { t } = useTranslation();

  const { content, parseContentItem } = useReportPresetContext();

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#f7f7f7',
        overflowY: 'auto',
        p: 4
      }}
    >
      <ContentDroppable droppableId={DroppableIds.Destination}>
        <DataLoading data={content} text={t('dialogs.reportPreset.noContentData')}>
          {(data) => (
            <Container sx={{ display: 'flex', flexDirection: 'column', gap: 4 }} maxWidth="md">
              {data.map((contentItem, index) => {
                const [ type, id ] = parseContentItem(contentItem);
                return (
                  <ContentDraggable
                    key={`${type} item ${id}`}
                    index={index}
                    draggableId={`${type}-${id}`}
                  ><SelectedContentItem id={id} contentType={type} /></ContentDraggable>
                )
              })}
            </Container>
          )}
        </DataLoading>
      </ContentDroppable>
    </Box>
  )
}

export default ContainerContent