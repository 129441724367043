import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import { RootState } from 'app/store';
import IEpisode from 'app/models/Episode';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { EpisodesActions } from 'app/store/Episodes/Episodes.slice';
import { EpisodeFactsActions } from 'app/store/EpisodeFacts/EpisodeFacts.slice';
// Selectors
import { selectGridResizerWidth } from 'app/store/AppUI/AppUI.selectors';
import { selectPageEpisodesAll } from 'app/store/Episodes/Episodes.selectors';
// Mui
import { Box, Paper, DialogTitle, DialogContent } from '@mui/material';
// Components
import EpisodeForm from 'app/components/EpisodeForm';
// ToDO
import { IPage } from '@root/models/Page';
import { selectPage } from 'app/store/page/page.selectors';
// End: ToDO

type Props = {
  open: boolean;
  onClose: () => void;
  episode?: Omit<IEpisode, 'id'> & {
    id: number | undefined
  } | undefined;
}

const EpisodeFormDialog:FC<Props> = ({
  // Props
  open, onClose, episode
}) => {
  const { t } = useTranslation();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const gridResizerWidth:number = useAppSelector(selectGridResizerWidth);
  // ToDO
  const page = useAppSelector((state) => selectPage(state as any)) as IPage;
  // End: ToDO
  const episodes = useAppSelector((state:RootState) => selectPageEpisodesAll(state, {
    pageNum: page.pageNum,
    documentId: page.documentId,
    stapleId: page.staple?.id,
    parentEpisodeId: page?.parentEpisodeId
  })) as IEpisode[] | null;

  useEffect(() => {
    dispatch(EpisodesActions.setSelectedEpisodeId(-1));
    return () => {
      dispatch(EpisodesActions.setSelectedEpisodeId(null));
      dispatch(EpisodeFactsActions.clearFactsWithNegativeId());
    };
    // eslint-disable-next-line
  }, []);

  const isParentEpisodeCreation = !page.parentEpisodeId && (!episodes || !episodes.length);

  if ( !open ) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: 0, right: 0, bottom: 0,
        width: `${100 - gridResizerWidth}%`,
        bgcolor: 'rgba(0,0,0,0.5)',
        zIndex: 1300,
        overflowY: 'auto'
      }}
    >
      <Box sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        p: 4,
      }}>
        <Paper sx={{ alignSelf: 'center', maxWidth: 540 }}>
          <DialogTitle>
            {isParentEpisodeCreation
              ? t('components.pageEpisodes.parentFormTitle')
              : t('components.pageEpisodes.formTitle')
            }
          </DialogTitle>
          <DialogContent dividers>
            <EpisodeForm
              episode={episode}
              onClose={onClose}
              isParentEpisodeCreation={isParentEpisodeCreation}
            />
          </DialogContent>
        </Paper>
      </Box>
    </Box>
  )
}

export default EpisodeFormDialog;
