import { createSelector } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';
// Types
import CalendarLegends from 'app/types/CalendarLegends';
// Models
import { RootState } from 'app/store';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import { selectPreferenceByName } from 'app/store/currentUser/currentUserSelectors';
import {
  selectWorkspacePagesIds
} from 'app/store/DMSDocumentPages/DMSDocumentPages.selectors';
import {
  selectEpisodesIds,
  selectEpisodesEntities
} from 'app/store/Episodes/Episodes.selectors';
// ToDO: Utilities
import { getVisitColor } from 'utilities/utilities';
import { sortCalendarDataByTime } from 'app/utilities/SortBy';

export const STORAGE_KEY = 'chart_legend_settings';

export const selectSelectedDate = (state:RootState) => state.calendar.selectedDate;
export const selectFilter = (state:RootState) => state.calendar.filter;

export const selectInWorkspaceEpisodeIds = createSelector(
  [
    selectWorkspacePagesIds,
    selectEpisodesIds,
    selectEpisodesEntities,
    selectFilter
  ],
  (pageIds:string[] | null, episodeIds:number[] | null, episodeEntities:any, filter:any) => {
    if ( !pageIds || !episodeIds ) return null;

    const { inWorkspace } = filter;

    return episodeIds
      .filter((id:number) => {
        const episode = episodeEntities[id];
        return episode.date && episode.showInTimeline;
      })
      .filter((id:number) => {
        const episode = episodeEntities[id];
        if ( !inWorkspace ) return true;
        const pageId = `${episode.documentId}:${episode.pageNum}`;
        return pageIds.includes(pageId);
      });
  }
);

export const selectFilteredEpisodeIds = createSelector(
  [
    selectWorkspacePagesIds,
    selectEpisodesEntities,
    selectInWorkspaceEpisodeIds,
    selectFilter
  ],
  (pageIds:string[] | null, episodeEntities:any, episodeIds:number[] | null, filter:any) => {
    if ( !pageIds || !episodeIds ) return null;

    const { episodeLabels } = filter;

    return episodeIds
      .filter((id:number) => {
        const episode = episodeEntities[id];
        if ( !episodeLabels.length ) return true;
        if ( !episode.labels ) return false;
        return episodeLabels.some((label:string) => episode.labels.includes(label))
      });
  }
);

export const selectInWorkspaceEpisodeLabels = createSelector(
  [
    selectInWorkspaceEpisodeIds,
    selectEpisodesEntities
  ],
  (episodeIds:number[] | null, episodeEntities:any) => {
    if ( !episodeIds ) return null;
    return episodeIds.reduce((acc:any[], cur:number) => {
      const episode = episodeEntities[cur];
      if ( episode.labels && episode.labels.length ){
        for ( let label of episode.labels ){
          if ( !acc.includes(label) ) acc.push(label);
        }
      }
      return acc;
    }, []).sort()
  }
)

export const selectInsuranceCaseLegendEntities = createSelector(
  [ selectInsuranceCase ],
  (insuranceCase:any) => {
    const result:any = {};
    if ( !insuranceCase ) return result;
    if ( insuranceCase.eventDate ) result[CalendarLegends.EventDate] = {
      key: CalendarLegends.EventDate,
      label: CalendarLegends.EventDate,
      color: getColorByLabelKey(CalendarLegends.EventDate),
      visible: true
    };
    if ( insuranceCase.examinationDate ) result[CalendarLegends.ExaminationDate] = {
      key: CalendarLegends.ExaminationDate,
      label: CalendarLegends.ExaminationDate,
      color: getColorByLabelKey(CalendarLegends.ExaminationDate),
      visible: true
    };
    if ( insuranceCase.trialDate ) result[CalendarLegends.TrialDate] = {
      key: CalendarLegends.TrialDate,
      label: CalendarLegends.TrialDate,
      color: getColorByLabelKey(CalendarLegends.TrialDate),
      visible: true
    };
    return result;
  }
);

export const selectEpisodeLegendEntities = createSelector(
  [
    selectInWorkspaceEpisodeIds,
    selectEpisodesEntities
  ],
  (episodeIds:number[] | null, episodeEntities:any) => {
    if ( !episodeIds ) return {};

    const episodeTypes = episodeIds.reduce((acc:string[], cur:number) => {
      const episode = episodeEntities[cur];
      const type = episode.type || CalendarLegends.Unassigned;
      if ( !acc.includes(type) ) acc.push(type);
      return acc;
    }, []).sort();

    return episodeTypes.reduce((acc:any, cur:string) => {
      acc[cur] = { key: cur, label: cur, color: getColorByLabelKey(cur), visible: true };
      return acc;
    }, {});
  }
);

export const selectLegendEntities = createSelector(
  [
    selectInsuranceCaseLegendEntities,
    selectEpisodeLegendEntities,
    selectPreferenceByName,
    // ToDO
    selectInsuranceCase
  ],
  (insuranceCaseLegendEntities:any, episodeLegendEntities:any, preference:any, insuranceCase:any) => {
    // ToDO: Should be removed on the next release
    // We do not support preferences in localStorage anymore
    const storageLegends = insuranceCase && insuranceCase.id ? getLegendsFromLocalStorage(insuranceCase.id) : {};

    const storageLegendsWithKey = Object.keys(storageLegends).reduce((acc:any, cur:any) => {
      if ( storageLegends[cur]['key'] ) acc[cur] = storageLegends[cur];
      return acc;
    }, {});

    const dbLegendsWithKey = preference ? Object.keys(preference).reduce((acc:any, cur:any) => {
      if ( preference[cur]['key'] ) acc[cur] = preference[cur];
      return acc;
    }, {}) : {};

    const legendEntities = {...insuranceCaseLegendEntities, ...episodeLegendEntities};

    return Object.keys(legendEntities).reduce((acc:any, cur:string) => {
      acc[cur] = legendEntities[cur];
      // ToDO
      if ( storageLegendsWithKey[cur] ) acc[cur] = storageLegendsWithKey[cur];
      // End ToDO
      if ( dbLegendsWithKey[cur] ) acc[cur] = dbLegendsWithKey[cur];
      return acc;
    }, {});
  }
);

export const selectCalendarData = createSelector(
  [
    selectLegendEntities,
    selectInsuranceCase,
    selectFilteredEpisodeIds,
    selectEpisodesEntities,
  ],
  (legendEntities:any, insuranceCase:any, episodeIds:number[] | null, episodeEntities:any) => {
    const result:any = {};

    if ( insuranceCase ){
      if ( insuranceCase.eventDate ) result[insuranceCase.eventDate] = [{
        date: insuranceCase.eventDate,
        legend: legendEntities[CalendarLegends.EventDate]
      }]
      if ( insuranceCase.examinationDate ) result[insuranceCase.examinationDate] = [{
        date: insuranceCase.examinationDate,
        legend: legendEntities[CalendarLegends.ExaminationDate]
      }]
      if ( insuranceCase.trialDate ) result[insuranceCase.trialDate] = [{
        date: insuranceCase.trialDate,
        legend: legendEntities[CalendarLegends.TrialDate]
      }]
    }

    if ( !episodeIds ) return result;

    for ( let id of episodeIds ){
      const episode = episodeEntities[id];
      const legend = episode.type ? legendEntities[episode.type] : legendEntities[CalendarLegends.Unassigned];
      if ( !legend.visible ) continue;
      if ( result[episode.date] ){
        result[episode.date] = [...result[episode.date], {...episode, legend}];
      } else {
        result[episode.date] = [{...episode, legend}];
      }
    }

    return result;
  }
);

export const selectCalendarDataByDateString = createSelector(
  [
    selectCalendarData,
    (_:any, props:{ dateString:string }) => props
  ],
  (calendarData:any, { dateString }) => calendarData[dateString]?.sort((a:any, b:any) => sortCalendarDataByTime(a, b))
);

export const selectSortedDatesFromCalendarData = createSelector(
  [ selectCalendarData ],
  (calendarData:any) => {
    return Object.keys(calendarData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }
);

export const selectFullChartData = createSelector(
  [
    selectFilter,
    selectLegendEntities,
    selectCalendarData,
    selectSortedDatesFromCalendarData
  ],
  (filter:any, legendEntities:any, calendaData:any, dates:string[]) => {
    const { showAll } = filter;

    const episodesByKeyAndDate = Object.keys(calendaData).reduce((acc:any, key:string) => {
      const episodes = calendaData[key];
      for ( let episode of episodes ){
        const hasLegendKey = acc[episode.legend.key];
        if ( hasLegendKey ){
          const hasLegendKeyData = hasLegendKey[key];
          if ( hasLegendKeyData ){
            acc[episode.legend.key][key] = [...acc[episode.legend.key][key], episode];
          } else {
            acc[episode.legend.key][key] = [episode];
          }
        } else {
          acc[episode.legend.key] = { [key]: [episode] };
        }
      }
      return acc;
    }, {});

    const maxEvents = Object.keys(episodesByKeyAndDate).reduce((acc:number, cur:string) => {
      for ( let date in episodesByKeyAndDate[cur] ){
        const episodes = episodesByKeyAndDate[cur][date];
        acc = acc > episodes.length ? acc : episodes.length;
      }
      return acc;
    }, 0);

    const result = [];

    for ( let key in episodesByKeyAndDate ){
      const legend = legendEntities[key];

      if ( !legend.visible ) continue;

      const dataPoints:any = [];

      for ( let date in episodesByKeyAndDate[key] ){
        const [ year, month, day ] = date.split('-')
        const events = episodesByKeyAndDate[key][date];
        dataPoints.push({
          x: new Date(Number(year), Number(month) - 1, Number(day)),
          y: key === CalendarLegends.EventDate ? maxEvents : events.length,
          events
        });
      }

      result.push({
        key,
        type: 'stackedColumn',
        name: key === CalendarLegends.EventDate ? `  ${legend.label}` : legend.label,
        color: legend.color,
        visible: showAll !== null ? showAll : key === CalendarLegends.EventDate,
        dataPoints,
        showInLegend: true
      });
    }

    const viewportMinimum = dayjs(dates[0]).subtract(6, 'month').format('YYYY-MM-DD');
    const viewportMaximum = dayjs(dates[dates.length - 1]).add(6, 'month').format('YYYY-MM-DD');

    return {
      fullChartData: result.sort((a:any, b:any) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        return aName === bName ? 0 : aName < bName ? -1 : 1
      }),
      initialViewportMinimum: new Date(viewportMinimum),
      initialViewportMaximum: new Date(viewportMaximum)
    };
  }
);

export const selectDailyChartData = createSelector(
  [
    selectCalendarData,
    selectSelectedDate,
    selectInsuranceCase,
  ],
  (calendaData:any, selectedDate:Dayjs | null, insuranceCase:any) => {
    const selectedDateToString = selectedDate ? selectedDate.format('YYYY-MM-DD') : '';

    const episodes = selectedDateToString
      ? calendaData[selectedDateToString]
      : insuranceCase && insuranceCase.eventDate
        ? calendaData[insuranceCase.eventDate]
        : []
    ;

    const result:any = [{
      type: 'stackedColumn',
      dataPoints: [
        { x: new Date(), y: 0 }
      ]
    }];

    if ( episodes && episodes.length ){
      const dataPoints:any = [];
      for ( let episode of episodes ){
        if ( !episode.legend.visible ) continue;

        const [ year, month, day ] = episode.date.split('-').map(Number);
        const [ hours, minutes ] = episode.time ? episode.time.split(':').map(Number) : [];
  
        dataPoints.push({
          x: episode.time
            ? new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes))
            : new Date(Number(year), Number(month) - 1, Number(day), 0, 0)
          ,
          y: 1,
          color: episode.legend.color,
          events: [episode]
        });
      }
      result[0]['dataPoints'] = dataPoints;
    }

    return result;
  }
)

const getLegendsFromLocalStorage = (insuranceCaseId:number):any => {
  const storage = localStorage.getItem(STORAGE_KEY);
  const legends = storage ? JSON.parse(storage) : {};
  return Array.isArray(legends) ? {} : (legends[insuranceCaseId] || {});
};

const getColorByLabelKey = (key:string):string => {
  if ( key === CalendarLegends.EventDate ) return '#000000';
  if ( key === CalendarLegends.ExaminationDate ) return '#28a745';
  if ( key === CalendarLegends.TrialDate ) return '#6f42c1';
  return getVisitColor(key);
}