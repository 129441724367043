import React, { FormEvent, useMemo } from 'react';
// Models
import { IPage } from '@root/models/Page';
// State
import { useSelector } from 'react-redux';
// Selectors
import { selectColumnValue, selectWorkspaceType } from 'app/store/ui/uiSelectors';
import { selectTotalPagesInWorkspace, selectPagesInWorkspaceByPreEvent } from 'app/store/page/page.selectors';
// MaterialUI
import { Box, Button, TextField, FormHelperText } from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

const CARD_WIDTH = 300;

type Props = {
  listRef: any;
}

const GoToPage:React.FC<Props> = ({
  // Props
  listRef
}) => {
  const { t } = useTranslation();
  // State
  const columnValue:string = useSelector(selectColumnValue);
  const workspaceType = useSelector(selectWorkspaceType);
  const pagesWithPreEvent:Array<IPage> | null = useSelector((state:any) => selectPagesInWorkspaceByPreEvent(state, { preEvent: true }));
  const pagesWithoutPreEvent:Array<IPage> | null = useSelector((state:any) => selectPagesInWorkspaceByPreEvent(state, { preEvent: false }));
  const totalPages:any = useSelector(selectTotalPagesInWorkspace);

  const pages = useMemo(() => {
    const result:any = [];
    if ( pagesWithPreEvent && pagesWithPreEvent.length ){
      result.push({ type: 'placeholder', label: 'Pre-event' });
      for ( let i = 0; i < pagesWithPreEvent.length; i++ ){
        result.push({...pagesWithPreEvent[i], type: 'page', index: i });
      }
    }
    if ( pagesWithoutPreEvent && pagesWithoutPreEvent.length ){
      const index = pagesWithPreEvent ? pagesWithPreEvent.length : 0;
      result.push({ type: 'placeholder', label: 'Post-event' });
      for ( let i = 0; i < pagesWithoutPreEvent.length; i++ ){
        result.push({...pagesWithoutPreEvent[i], type: 'page', index: index + i });
      }
    }
    return result;
    // eslint-disable-next-line
  }, [pagesWithPreEvent, pagesWithoutPreEvent]);

  const [ goTo, setGoTo ] = React.useState<number | string>('');

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setGoTo(value ? Number(value) : '');
  }
  const handleSubmit = (event:FormEvent) => {
    event.preventDefault();

    if ( listRef.current ){
      const pageNum = Number(goTo);
      const foundedPageIndex = pages.findIndex((page:any) => page.index === pageNum);
      if ( typeof foundedPageIndex !== 'undefined' ){
        const width = listRef.current.props.width;
        const itemsPerRow = columnValue === 'auto' ? Math.floor(width / CARD_WIDTH) : Number(columnValue);
        const rowIndex = Math.floor(foundedPageIndex/itemsPerRow)
        listRef.current.scrollToItem(rowIndex, 'center');
      }
    }
  }

  if ( workspaceType !== 'pages' ) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
      onSubmit={handleSubmit}
      component="form"
    >
      <TextField
        aria-label="Go to input"
        sx={{
          width: 128
        }}
        value={goTo}
        onChange={handleChange}
        margin="none"
        size="small"
        placeholder="1"
        InputProps={{
          sx: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            pr: 0,
            height: 36.5
          },
          endAdornment: (
            <FormHelperText
              sx={{ p: 0, mt: 0, fontSize: 16, whiteSpace: 'nowrap' }}
            >{`/ ${totalPages?.totalMainPagesInWorkspace || 0}`}</FormHelperText>
          )
        }}
      />
      <Button
        sx={{
          whiteSpace: 'nowrap',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }}
        type="submit"
        disabled={goTo && (Number(goTo) > 0 && Number(goTo) <= totalPages?.totalMainPagesInWorkspace) ? false : true}
        color="primary"
        variant="contained"
      >{t('buttons.goToPage')}</Button>
    </Box>
  )
}

export default GoToPage;
