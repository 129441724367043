import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch } from 'app/hooks/useStore';
// Actions
import { DMSDocumentPagesActions } from 'app/store/DMSDocumentPages/DMSDocumentPages.slice';
// Mui
import { Box } from '@mui/material';
// 
import SearchFilter from './SearchSidebar';
import SearchList from './SearchList';

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
// i18next
import SearchToolbar from './SearchToolbar';
import { AppUIActions } from 'app/store/AppUI/AppUI.slice';

const SearchPage = () => {
  const { caseId } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    Mixpanel.track(MixpanelTracks.CaseSearchView, { id: Number(caseId) });

    return () => {
      dispatch(AppUIActions.setInitialField('selectedPageIds'));
      dispatch(DMSDocumentPagesActions.setInitialField('searchPages'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      overflow: 'hidden'
    }}>
      <SearchFilter />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <SearchToolbar />
        <SearchList />
      </Box>
    </Box>
  )
}

export default SearchPage;
