import { FC, Fragment, useMemo } from 'react';
import { Link as RRLink } from 'react-router-dom';
// Models
import { IGroupByCaseAndService } from 'app/store/BillingUsage/BillingUsage.models';
// Mui
import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Theme } from '@mui/material';
// Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
// Utils
import { toNumberWithCommas } from 'app/utilities/Utilities';
// 
import InvoiceNumberChips from './InvoiceNumberChips';
import InvoiceDraftIcon from './InvoiceDraftIcon';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  data: IGroupByCaseAndService[];
}

const UsageGroupByCaseAndService:FC<Props> = ({
  // Props
  data
}) => {
  const { t } = useTranslation();

  const totalAmount = useMemo(() => {
    return data.reduce((acc, caseItem) => {
      const serviceTotal = caseItem.services.reduce((caseSum, service) => caseSum + service.amount, 0);
      return acc + serviceTotal;
    }, 0);
  }, [data]);

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('labels.caseName')}</TableCell>
            <TableCell sx={{ width: '33.33%' }}>{t('labels.invoices')}</TableCell>
            <TableCell>{t('labels.serviceName')}</TableCell>
            <TableCell>{t('labels.qty')}</TableCell>
            <TableCell>{t('labels.total')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((caseData) => (
            <Fragment key={`caseItem-${caseData.caseId}`}>
              <TableRow>
                <TableCell rowSpan={caseData.services.length + 1}>
                  <Link
                    sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                    component={RRLink}
                    target="_blank"
                    to={`/admin/cases/${caseData.caseId}/documents`}
                    underline="none"
                  >
                    {caseData.caseName}
                    <OpenInNewIcon fontSize="small" />
                  </Link>
                </TableCell>
                <TableCell rowSpan={caseData.services.length + 1}>
                  <InvoiceNumberChips invoices={caseData.invoices || []} />
                </TableCell>
              </TableRow>
              {caseData.services.map((service) => (
                <TableRow key={`${caseData.caseId}-serviceItem-${service.id}`}>
                  <TableCell>{service.name}</TableCell>
                  <TableCell>{service.quantity}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <span>{`$${toNumberWithCommas(service.amount)}`}</span>
                      <InvoiceDraftIcon invoices={caseData.invoices} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
        <TableFooter sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1 }}>
          <TableRow sx={{
            bgcolor: (theme:Theme) => theme.palette.grey[50],
            '& > td': {
              borderBottom: 0
            }
          }}>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }} colSpan={4}>{t('labels.total')}</TableCell>
            <TableCell sx={{ fontWeight: 700, fontSize: '0.875rem' }}>{`$${toNumberWithCommas(totalAmount)}`}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UsageGroupByCaseAndService