import { ChangeEvent, FC, Fragment, useState } from 'react';
// Components
import Dialog from 'app/components/Dialog';
import { Input, Button } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: (comment:string) => void;
  comment?: string;
}

const FileItemCommentDialog:FC<Props> = ({
  // Props
  open, onClose, comment
}) => {
  const { t } = useTranslation();

  const [ value, setValue ] = useState(comment || '');

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }

  const handleClose = () => {
    onClose(value);
  }

  const actions = (
    <Fragment>
      <Button
        name={`Cancel file comment dialog`}
        onClick={handleClose}
      >{t('buttons.close')}</Button>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('labels.comment')}
      actions={actions}
    >
      <Input
        label={t('labels.comment')} value={value}
        onChange={handleChange}
        margin="none"
        size="small"
      />
    </Dialog>
  )
}

export default FileItemCommentDialog;