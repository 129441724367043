import { FC, Fragment } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectAllTextTemplatesAsOptions } from 'app/store/Templates/Templates.selectors';
// Mui
import { Box, FormHelperText } from '@mui/material';
// Mui
import { Input, Select } from 'app/components/Mui';
import { OutlineBlock } from 'app/components/Utilities';
// Utilities
import { isMaxLength } from 'app/utilities/Validations';

const SettingsTabContent:FC = () => {
  const { t } = useTranslation();

  // State
  const templateOptions = useAppSelector(selectAllTextTemplatesAsOptions);

  const { control, register, watch, formState: { errors } } = useFormContext();

  const watchWatermarkText = watch('watermark.text') || '';

  return (
    <Box
      sx={{
        flexShrink: 0,
        width: 400,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'white',
        borderRight: '1px solid rgba(0,0,0,0.08)',
        p: 6
      }}
    >
      <input {...register('watermark.tiled') as any} type="hidden" value={true} />

      <OutlineBlock sx={{ pt: 6 }} label={t('dialogs.reportPreset.pageHeaderFooter')}>
        <Fragment>
          <Controller
            control={control} name="headerTemplateId" defaultValue="0"
            render={({ field }) => (
              <Select
                {...field}
                label={t('dialogs.reportPreset.headerTemplate')}
                options={[
                  { id: 0, name: t('dialogs.reportPreset.chooseTemplate') },
                  ...(templateOptions || [])
                ]}
                size="small"
                margin="none"
              />
            )}
          />
          <Box sx={{ pt: 4 }}>
            <Controller
              control={control} name="footerTemplateId" defaultValue="0"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('dialogs.reportPreset.footerTemplate')}
                  options={[
                    { id: 0, name: t('dialogs.reportPreset.chooseTemplate') },
                    ...(templateOptions || [])
                  ]}
                  size="small"
                  margin="none"
                />
              )}
            />
          </Box>
        </Fragment>
      </OutlineBlock>

      <OutlineBlock sx={{ pt: 6, mt: 6 }} label={t('labels.watermark')}>
        <Controller
          control={control} name="watermark.text"
          rules={{ maxLength: isMaxLength(21) }}
          render={({ field }) => (
            <Input
              {...field}
              label="Text"
              error={Boolean((errors.watermark as any)?.text)}
              helperText={(errors.watermark as any)?.text?.message || ''}
              size="small"
              margin="none"
              InputProps={{
                endAdornment: (
                  <FormHelperText
                    sx={{ p: 0, whiteSpace: 'nowrap' }}
                  >{`${watchWatermarkText.length} / 21`}</FormHelperText>
                )
              }}
            />
          )}
        />
      </OutlineBlock>

    </Box>
  )
}

export default SettingsTabContent;
