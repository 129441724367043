import { FC, Fragment } from 'react';
// Components
import { Fab } from 'app/components/Mui';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import AccountCreateFormDialog from './AccountCreateFormDialog';
// i18next
import { useTranslation } from 'react-i18next';

const AccountsFab:FC = () => {
  const { t } = useTranslation();

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <Fab
        name="Create company account"
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16
        }}
        variant="extended"
        color="primary"
        onClick={toggle}
      >{t('pages.companyAccounts.createButton')}</Fab>

      {/* Account create form dialog */}
      {open ? (
        <AccountCreateFormDialog
          open={open}
          onClose={toggle}
        />
      ) : null}
    </Fragment>
  )
}

export default AccountsFab;
