import React from 'react';
import { useParams } from 'react-router-dom';
// Models
import { IPage } from '@root/models/Page';
// Actions
import { createAuditRecord } from 'app/store/Audit/Audit.async';
// Selectors
import { selectIsLoading, selectAllPages, selectPagesInWorkspaceByPreEvent, selectStatus } from 'app/store/page/page.selectors';
// MaterialUi
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
// Components
import { AuditOperations } from 'app/store/Audit/Audit.types';
// Utilities
import { sortByStapleOrder } from '@root/utilities/sort';
import { selectMyUser } from 'app/store/Users/Users.selectors';
import { LoadingButton } from '@mui/lab';
// i18next
import { useTranslation } from 'react-i18next';
import PageActions from 'app/store/page/page.actions';
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
import { RootState } from 'app/store';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ExportWorkspacePagesToPDFDialog:React.FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation();

  const { caseId } = useParams<{ caseId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const myUser:any = useAppSelector(selectMyUser);
  const status:any = useAppSelector(selectStatus);
  const isLoading:boolean = useAppSelector(selectIsLoading);
  const pages:Array<IPage> | null = useAppSelector(selectAllPages);
  const pagesWithPreEvent:Array<IPage> | null = useAppSelector((state:RootState) => selectPagesInWorkspaceByPreEvent(state, { preEvent: true }));
  const pagesWithoutPreEvent:Array<IPage> | null = useAppSelector((state:RootState) => selectPagesInWorkspaceByPreEvent(state, { preEvent: false }));

  const [ checked, setChecked ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if ( status === 'Success' ){
      onClose();
      if ( myUser ){
        dispatch(createAuditRecord({
          operation: AuditOperations.ExportWorkspacePagesToPDF,
          data: {
            caseId: Number(caseId),
            userName: myUser.name 
          }
        }));
      }
    }
    // eslint-disable-next-line
  }, [status]);

  const handleExport = () => {
    let allPages:Array<IPage> = [];
    let pagesToExport:Array<IPage> = [];
    if ( pagesWithPreEvent ) allPages = [...allPages, ...pagesWithPreEvent];
    if ( pagesWithoutPreEvent ) allPages = [...allPages, ...pagesWithoutPreEvent];

    allPages.forEach((page:IPage) => {
      const pageStapleId = page.staple && page.staple.order === 1 ? page.staple.id : null;
      if ( pageStapleId ){
        if ( pages ){
          const staplePages:Array<IPage> = pages.filter((p:IPage) => p.staple && p.staple.id === pageStapleId).sort(sortByStapleOrder);
          pagesToExport = [...pagesToExport, ...staplePages];
        }
      } else {
        pagesToExport = [...pagesToExport, page];
      }
    });

    if ( !pagesToExport.length ) return;

    dispatch(PageActions.exportPagesToPDF(pagesToExport.map((page:IPage) => ({ documentId: page.documentId, pageNum: page.pageNum }))));
  }

  const handleClose = (_:any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if ( reason === 'backdropClick' ) return;
    onClose();
  }

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t('dialogs.consentToExport.title')}</DialogTitle>
      <DialogContent dividers>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={(event:React.ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked)}
            />
          }
          label={t('dialogs.consentToExport.consentLabel')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >{t('buttons.close')}</Button>
        <LoadingButton
          loading={isLoading}
          disabled={!checked}
          color="primary"
          variant="contained"
          onClick={handleExport}
        >{t('buttons.export')}</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ExportWorkspacePagesToPDFDialog;
