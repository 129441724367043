import i18n from "i18n";

const EpisodeAuthorsMessages = {
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.episodeAuthor') }),
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.episodeAuthor') }),
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.episodeAuthor') }),
  Deduplicate: i18n.t('notifications.episodeAuthors.deduplicate'),
};

export default EpisodeAuthorsMessages;

i18n.on('languageChanged', () => {
  EpisodeAuthorsMessages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.episodeAuthor') });
  EpisodeAuthorsMessages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.episodeAuthor') });
  EpisodeAuthorsMessages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.episodeAuthor') });
  EpisodeAuthorsMessages.Deduplicate = i18n.t('notifications.episodeAuthors.deduplicate');
});