import { ChangeEvent, FC, useState, useCallback } from 'react';
import { debounce } from 'throttle-debounce';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Actions
import { UsersManagementActions } from 'app/store/UsersManagement/UsersManagement.slice';
// Selectors
import { selectAccountsAsOptions } from 'app/store/AccountsManagement/AccountsManagement.selectors';
import { selectParams } from 'app/store/UsersManagement/UsersManagement.selectors';
// Mui
import { Box, Typography } from '@mui/material';
// Components
import { Input, Select } from 'app/components/Mui';
import { Autocomplete } from 'app/components/Mui/Autocompletes';
// i18next
import { useTranslation } from 'react-i18next';

const UsersFilter:FC = () => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const accountOptions = useAppSelector(selectAccountsAsOptions);
  const params = useAppSelector(selectParams);

  const [ stateParams, setStateParams ] = useState(params);

  const debounceParams = useCallback(debounce(500, (field:keyof typeof params, value:any) => {
    dispatch(UsersManagementActions.setParams({ [field]: value, offset: 0 }));
    // eslint-disable-next-line
  }), []);

  const handleAccountChange = (value:any) => {
    setStateParams((prevState:any) => ({
      ...prevState,
      accountId: value
    }));
    debounceParams('accountId', value);
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateParams((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
    debounceParams(name as keyof typeof params, value);
  }
  
  const handleClearSearch = () => {
    setStateParams((prevState) => ({
      ...prevState,
      search: ''
    }));
    dispatch(UsersManagementActions.setParams({ search: '', offset: 0 }));
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <Input
          label={t('labels.search')} name="search" placeholder={t('form.placeholder.nameOrEmail')}
          value={stateParams.search}
          onChange={handleChange}
          onClear={handleClearSearch}
          margin="none"
          size="small"
        />
        <Autocomplete
          value={stateParams.accountId}
          onChange={handleAccountChange}
          options={accountOptions || []}
          TextFieldProps={{
            sx: { width: 300 },
            label: t('labels.companyAccount'),
            margin: "none",
            size: "small"
          }}
          renderOption={(props, option) => (
            <li {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} key={option.value}>
              <Typography variant="subtitle1">{option.label}</Typography>
              {option.props.address ? (
                <Typography variant="caption">{option.props.address}</Typography>
              ) : null}
            </li>
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Select
          label={t('labels.locked')} name="locked" value={stateParams.locked}
          onChange={handleChange}
          options={[
            { id: 'all', name: t('labels.all') },
            { id: 'true', name: t('labels.locked') },
            { id: 'false', name: t('labels.nonLocked') }
          ]}
          margin="none"
          size="small"
        />
        <Select
          label={t('labels.enabled')} name="enabled" value={stateParams.enabled}
          onChange={handleChange}
          options={[
            { id: 'all', name: t('labels.all') },
            { id: 'true', name: t('labels.enabled') },
            { id: 'false', name: t('labels.disabled') } 
          ]}
          margin="none"
          size="small"
        />
        <Select
          label={t('labels.platform')} name="platform" value={stateParams.platform}
          onChange={handleChange}
          options={[
            { id: 'all', name: t('labels.all') },
            { id: 'true', name: t('labels.platform') },
            { id: 'false', name: t('labels.nonPlatform') } 
          ]}
          margin="none"
          size="small"
        />
      </Box>
    </Box>
  )
}

export default UsersFilter;
