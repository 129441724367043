import { FC, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
// Types
import { ContentItemType } from 'app/types/ContentItem';
// Models
import IInsuranceCase from 'app/models/Case';
import IReport from 'app/models/Report';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { createReport } from 'app/store/CaseReports/CaseReports.async';
import { getPresets } from 'app/store/ReportPresets/ReportPresets.async';
// Selectors
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
// Mui
import { Box } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import CaseInfoBar from 'app/components/CaseInfoBar';
// Context
import ReportPresetProvider from 'app/context/ReportPreset.context';
// 
import DialogTitle from './DialogTitle';
import DialogContainer from './DialogContainer';

interface IFormData {
  isNameChanged: boolean;
  name: string;
  headerTemplateId: string;
  footerTemplateId: string;
  // templateIds: number[];
  blocks: ContentItemType[];
  watermark: {
    text: string;
    tiled: boolean;
  };
}

type Props = {
  open: boolean;
  onClose: () => void;
  report?: IReport;
}

const ReportFormDialog:FC<Props> = ({
  // Props
  open, onClose, report
}) => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;

  const setName = (name:string | undefined) => {
    if ( !name ) return '';
    const versionPattern = / - v(\d+)$/;
    const match = name.match(versionPattern);
    if ( match ) {
      const versionNumber = parseInt(match[1], 10) + 1;
      return name.replace(versionPattern, ` - v${versionNumber}`);
    }
    return `${name} - v2`;
  }

  const methods = useForm<IFormData>({
    defaultValues: {
      isNameChanged: false,
      name: setName(report?.name),
      headerTemplateId: report?.headerTemplateId ? report.headerTemplateId.toString() : '0',
      footerTemplateId: report?.footerTemplateId ? report.footerTemplateId.toString() : '0',
      blocks: report?.blocks || [],
      watermark: {
        text: report?.watermark?.text || 'CaseChronology.com',
        tiled: true
      }
    }
  });

  const { handleSubmit, register } = methods;

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { isNameChanged, blocks, headerTemplateId, footerTemplateId, watermark, ...otherData } = data;

    if ( !blocks || !blocks.length ) return;

    const newReportData:any = { ...otherData };
    if ( headerTemplateId && headerTemplateId !== '0' ) newReportData['headerTemplateId'] = Number(headerTemplateId);
    if ( footerTemplateId && footerTemplateId !== '0' ) newReportData['footerTemplateId'] = Number(footerTemplateId);
    newReportData['blocks'] = blocks;
    newReportData['insuranceCaseId'] = insuranceCase.id;
    if ( watermark.text ) newReportData['watermark'] = watermark;
    asyncCreateReport(newReportData);
  });

  const asyncCreateReport = async (data:any) => {
    try {
      await dispatch(createReport(data)).unwrap();
      onClose();
    } catch(error){}
  }

  useEffect(() => {
    const teamId:number | undefined = insuranceCase?.team?.id;
    dispatch(getPresets({ teamId }));
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{ sx: { border: 0 } }}
      ContentProps={{ sx: { border: 0, p: 0 } }}
    >
      <FormProvider {...methods}>
        <ReportPresetProvider
          initialContent={report?.blocks || []}
          caseId={insuranceCase?.id}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              position: 'relative'
            }}
            component="form"
            onSubmit={onSubmit}
            noValidate
          >
            <input {...register('blocks') as any} type="hidden" />

            <DialogTitle onClose={onClose} report={report} />
            <CaseInfoBar />
            <DialogContainer />
          </Box>
        </ReportPresetProvider>
      </FormProvider>
    </Dialog>
  )
}

export default ReportFormDialog;
