import { FC } from 'react';
import { useHistory } from 'react-router-dom';
// Mui
import {
  Toolbar,
  Tabs, Tab
} from '@mui/material';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  activeTab: number;
  onChange: (nextActiveTab:number) => void;
}

const BillingAndSubscriptionsToolbar:FC<Props> = ({
  // Props
  activeTab, onChange
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleChange = (_:any, nextActiveTab:number) => {
    onChange(nextActiveTab);

    const params = { tab: `${nextActiveTab}` };
    const queryString = new URLSearchParams(params).toString();
    history.push(`/admin/billing-and-subscriptions?${queryString}`);
  }

  return (
    <Toolbar
      variant="dense"
      // Data attribute which explude block from print using CTRL/CMD + P
      data-print="exclude"
    >
      <Tabs value={activeTab} onChange={handleChange}>
        <Tab label={t('labels.invoices')} />
        <Tab label={t('labels.usageReport')} />
        <Tab label={t('labels.costExplorer')} />
        <Tab label={t('labels.subscriptions')} />
      </Tabs>
    </Toolbar>
  )
}

export default BillingAndSubscriptionsToolbar