import i18n from "i18n";

const Messages = {
  Create: i18n.t('notifications.common.create', { dataType: i18n.t('labels.invoice') }),
  Update: i18n.t('notifications.common.update', { dataType: i18n.t('labels.invoice') }),
  Delete: i18n.t('notifications.common.delete', { dataType: i18n.t('labels.invoice') }),
  Pay: i18n.t('notifications.invoice.pay'),
  Send: i18n.t('notifications.invoice.send'),
  Push: i18n.t('notifications.invoice.push')
};

export default Messages;

i18n.on('languageChanged', () => {
  Messages.Create = i18n.t('notifications.common.create', { dataType: i18n.t('labels.invoice') });
  Messages.Update = i18n.t('notifications.common.update', { dataType: i18n.t('labels.invoice') });
  Messages.Delete = i18n.t('notifications.common.delete', { dataType: i18n.t('labels.invoice') });
  Messages.Pay = i18n.t('notifications.invoice.pay');
  Messages.Send = i18n.t('notifications.invoice.send');
  Messages.Push = i18n.t('notifications.invoice.push');
})
