import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
// Hooks
import useCaseStatus from 'app/hooks/useCaseStatus';
// Models
import { IPage } from '@root/models/Page';
// Actions
import AppActions from 'app/store/TODO2_app/app.actions';
import PageActions from 'app/store/page/page.actions';
// Selectors
import { selectGridResizerWidth, selectTrialMode } from 'app/store/AppUI/AppUI.selectors';
import { selectPage, selectPagesInWorkspaceByPreEvent } from 'app/store/page/page.selectors';
// MaterialUi
import {
  IconButton,
  Dialog, DialogTitle, DialogContent, Tooltip,
  Box
} from '@mui/material';
// Icons
import { Close as CloseIcon } from '@mui/icons-material';
// Components
import { Loader } from 'app/components/Utilities';
import PageEpisodes from 'app/components/PageEpisodes';
import CaseInfo from 'app/components/CaseInfo';
// 
import CardPageDeletedPlaceholder from './CardPageDeletedPlaceholder';
import CardPageTabs from './CardPageTabs';
import CardPageNavigation from './CardPageNavigation';
import CardPageStaplePlaceholder from './CardPageStapledPlaceholder';
import CardPageDelete from './CardPageDelete';
import CardPageTag from './CardPageTag';
import CardPageColors from './CardPageColors';
import CardPageToggler from './CardPageToggler';
import SystemNotifications from '@root/components/SystemNotifications';
import { selectWorkspaceType } from 'app/store/ui/uiSelectors';

import GridResizer from 'app/components/GridResizer';

import Mixpanel from 'app/services/Mixpanel.service';
import MixpanelTracks from 'app/types/MixpanelTracks';
// 
import DocumentPageInfo from './DocumentPageInfo';
import DocumentAlert from './DocumentAlert';
import LabellingToolsSidebar from 'app/layouts/LabellingToolsSidebar';
import { useTranslation } from 'react-i18next';
import { selectInsuranceCase } from 'app/store/Cases/Cases.selectors';
import IInsuranceCase from 'app/models/Case';
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
import { RootState } from 'app/store';

type Props = {
  open: boolean;
  onClose: () => void;
  documentId: number;
  pageNum: number;
  openedPageNum?: number | null;

  showNavigations?: boolean;
  pageBB?: any;
};

const CardPageDialog:React.FC<Props> = ({
  // Props
  open, onClose, documentId, pageNum, openedPageNum = null, showNavigations = true, pageBB
}) => {
  const { t } = useTranslation();

  const { pathname } = useLocation<{ pathname:string }>();

  const isFilesPage:boolean = pathname.includes('/documents');
  const isWorkspacePage:boolean = pathname.includes('/workspace');
  const isSearchPage:boolean = pathname.includes('/search');
  const isDuplicatePage:boolean = pathname.includes('/duplicates');

  const { isArchived } = useCaseStatus();

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const trialMode = useAppSelector(selectTrialMode);
  const workspaceType = useAppSelector(selectWorkspaceType);
  const gridResizerWidth:number = useAppSelector(selectGridResizerWidth);
  const insuranceCase = useAppSelector(selectInsuranceCase) as IInsuranceCase;
  const page:IPage | null = useAppSelector(selectPage);
  const pagesWithPreEvent:Array<IPage> | null = useAppSelector((state:RootState) => selectPagesInWorkspaceByPreEvent(state, { preEvent: true }));
  const pagesWithoutPreEvent:Array<IPage> | null = useAppSelector((state:RootState) => selectPagesInWorkspaceByPreEvent(state, { preEvent: false }));

  const [ prevDocumentId, setPrevDocumentId ] = React.useState<number>(documentId);
  const [ targetPageNum, setTargetPageNum ] = React.useState<number | null>(openedPageNum);

  const workspacePages = React.useMemo(() => {
    let result:IPage[] = [];
    if ( pagesWithPreEvent ) result = pagesWithPreEvent;
    if ( pagesWithoutPreEvent ) result = [...result, ...pagesWithoutPreEvent];
    return result;
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    handleFetchPage(documentId, pageNum);

    return () => {
      dispatch(PageActions.setInitialField('page'));
      dispatch(PageActions.setInitialField('pageStaples'));
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( !targetPageNum ) return;
    const pageImageElement:any = document.querySelector(`[react-page-num-dialog="${targetPageNum}"]`);
    if ( pageImageElement ) pageImageElement.scrollIntoView();
    // eslint-disable-next-line
  }, [page !== null])

  const handleFetchPage = (documentId:number, pageNum:number, prevDocumentId?:number, nextTargetPageNum?:number | null) => {
    if ( isFilesPage ){
      if ( prevDocumentId && documentId !== prevDocumentId ) setPrevDocumentId(prevDocumentId);
      dispatch(AppActions.setLastOpenedFilePage(insuranceCase.id, documentId, pageNum))
    }
    if ( isWorkspacePage ) dispatch(AppActions.setLastOpenedWorkspacePage(insuranceCase.id, documentId, pageNum));
    if ( isSearchPage && typeof nextTargetPageNum !== 'undefined' ) setTargetPageNum(nextTargetPageNum);
    // dispatch(PagesActions.setPage({ documentId, pageNum }));

    dispatch(PageActions.fetchPage(documentId, pageNum));

    Mixpanel.track(MixpanelTracks.CasePageOpen, {
      caseId: insuranceCase.id,
      docId: documentId,
      pageNum: pageNum
    });
  }

  const handleClose = (_:any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if ( reason === 'backdropClick' ) return;
    onClose();
  }

  const isDisabled = trialMode || isArchived;

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        {!page ? (
          <Loader />
        ) : (
          <React.Fragment>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 4, overflow: 'hidden' }} component="div">
              <Box sx={{ overflow: 'hidden' }}>
                <CaseInfo statusVisible={false} direction="row" />
                <DocumentPageInfo
                  documentId={page.documentId}
                  pageNum={page.pageNum}
                  pageDeleted={page.deleted}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                {showNavigations && workspaceType === 'pages' ? (
                  <CardPageNavigation
                    isFilesPage={isFilesPage}
                    isWorkspacePage={isWorkspacePage}
                    isSearchPage={isSearchPage}
                    isDuplicatePage={isDuplicatePage}
                    page={page}
                    workspacePages={workspacePages}
                    prevDocumentId={prevDocumentId}
                    onFetchPage={handleFetchPage}
                    targetPageNum={targetPageNum}
                  />
                ) : null}
                <Tooltip title={t('buttons.closeDialog')} aria-label={undefined}>
                  <IconButton
                    aria-label="Close dialog button"
                    onClick={onClose}
                    edge="end"
                  ><CloseIcon /></IconButton>
                </Tooltip>
              </Box>
            </DialogTitle>
            <SystemNotifications />
            <DialogContent dividers sx={{ overflow: 'hidden' }}>
              <div style={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                marginRight: '-8px',
                marginLeft: '-8px'
              }}>
                <div
                  style={{
                    width: !trialMode ? `${gridResizerWidth}%` : '100%',
                    position: 'relative',
                    paddingRight: '8px',
                    paddingLeft: '8px',
                    display: 'flex',
                    flexDirection:  'column'
                  }}
                >
                  {page.deleted ? (
                    <CardPageDeletedPlaceholder />
                  ) : (
                    <CardPageTabs pageBB={pageBB} />
                  )}
                </div>
                {!trialMode ? (
                  <Fragment>
                    <GridResizer />
                    <div style={{
                      flex: 1,
                      position: 'relative',
                      height: '100%',
                      overflowY: 'auto',
                      paddingRight: '8px',
                      paddingLeft: '8px'
                    }}>
                      {!page.deleted ? (
                        <React.Fragment>
                          {!page.staple || page.staple.order === 1 ? (
                            <React.Fragment>
                              <DocumentAlert page={page} isFilesPage={isFilesPage} prevDocumentId={prevDocumentId} />
                              {workspaceType === 'pages' ? (
                                <React.Fragment>
                                  <CardPageTag page={page} isDisabled={isDisabled} />
                                  <CardPageColors page={page} />
                                  <CardPageToggler page={page} />
                                </React.Fragment>
                              ) : null}
                              <PageEpisodes />
                              {workspaceType === 'pages' && !isArchived ? (
                                <CardPageDelete page={page} />
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <CardPageStaplePlaceholder page={page} />
                          )}
                        </React.Fragment>
                      ) : null}
                    </div>
                    <LabellingToolsSidebar />
                  </Fragment>
                ) : null}
              </div>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  )
}

export default CardPageDialog;
