import { FC, Fragment, useContext } from 'react';
// Models
import { RootState } from 'app/store';
import IBillingService from 'app/models/BillingService';
import { IBillingPackageItem } from 'app/models/BillingPackage';
// Redux
import { useAppSelector } from 'app/hooks/useStore';
// Selectors
import { selectServiceEntity } from 'app/store/BillingServices/BillingServices.selectors';
// Mui
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
// Icons
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
// Components
import { InfoBlock } from 'app/components/Utilities';
// Contexts
import PackageServicesContext from 'app/context/PackageServicesContext';
// Hooks
import useToggle from 'app/hooks/useToggle';
// 
import PackageItemFormDialog from './PackageItemFormDialog';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  packageItem: IBillingPackageItem;
}

const PackageServicesListItem:FC<Props> = ({
  // Props
  packageItem
}) => {
  const { t } = useTranslation();
  // State
  const service:IBillingService = useAppSelector((state:RootState) => selectServiceEntity(state, {
    serviceId: packageItem.serviceId
  }));

  const { items, onUpdate } = useContext(PackageServicesContext);

  const { open, toggle } = useToggle();

  const handleDelete = () => {
    onUpdate((prevItems:any) => prevItems.filter((item:any) => item.serviceId !== packageItem.serviceId));
  }

  const handleConfirm = (item:any) => {
    onUpdate((prevItems:any) => prevItems.map((i:any) => {
      if ( i.serviceId === item.serviceId ) return item;
      return i;
    }))
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, '& + &': { mt: 4 } }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography>{service.name}</Typography>
          <InfoBlock label={t('labels.price')} value={`$${packageItem.price}`} direction="row" />
        </Box>
        <Box sx={{ mr: -2 }}>
          <Tooltip title={t('pages.subscriptionPackages.editServiceButton')}>
            <IconButton
              onClick={toggle}
            ><EditIcon /></IconButton>
          </Tooltip>
          <Tooltip title={t('pages.subscriptionPackages.deleteServiceButton')}>
            <IconButton
              onClick={handleDelete}
            ><DeleteIcon /></IconButton>
          </Tooltip>
        </Box>
      </Box>
      {open ? (
        <PackageItemFormDialog
          open={open}
          onClose={toggle}
          onConfirm={handleConfirm}
          packageItem={packageItem}
          items={items}
        />
      ) : null}
    </Fragment>
  )
}

export default PackageServicesListItem;
