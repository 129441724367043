import { ChangeEvent, FC, Fragment, useRef, useState } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'app/hooks/useStore';
// Async
import { uploadExportTemplateFile } from 'app/store/ExportTemplates/ExportTemplates.async';
// State
import { selectLoading } from 'app/store/ExportTemplates/ExportTemplates.selectors';
// Mui
import { Box, FormHelperText, Typography } from '@mui/material';
// Components
import Dialog from 'app/components/Dialog';
import { Button, LoadingButton } from 'app/components/Mui';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  exportTemplateId: number;
}

const ExportTemplateUploadDialog:FC<Props> = ({
  // Props
  open, onClose, exportTemplateId
}) => {
  const { t } = useTranslation();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading = useAppSelector(selectLoading);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [ file, setFile ] = useState<File | null>(null);
  const [ isSubmitted, setIsSubmitted ] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitted(true);

    if ( !file ) return;

    try {
      const formData = new FormData();
      formData.append('file', file);
      await dispatch(uploadExportTemplateFile({
        exportTemplateId,
        data: formData
      })).unwrap();
      onClose();
    } catch(error){}
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if ( !files ) return;
    setFile(files[0]);
  }

  const handleClick = () => {
    setFile(null);
    if ( inputRef.current ) inputRef.current.value = '';
  }

  const actions = (
    <Fragment>
      <Button
        name="Cancel report template file dialog"
        onClick={onClose}
      >{t('buttons.close')}</Button>
      <LoadingButton
        name="Upload report template file dialog"
        loading={loading}
        onClick={handleSubmit}
        variant="contained"
        color="primary"
      >{t('buttons.upload')}</LoadingButton>
    </Fragment>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      title={t('pages.exportTemplates.uploadFile')}
      actions={actions}
    >
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, alignItems: 'center' }}>
        <input
          ref={inputRef}
          type="file"
          onChange={handleChange}
          id="file"
          hidden
        />
        <label htmlFor="file">
          <Button
            aria-label="Add report template file button"
            name="Add report template file"
            component="span"
            color="primary"
            variant="contained"
          >{t('pages.exportTemplates.chooseFile')}</Button>
        </label>
        {file ? (
          <Fragment>
            <Button
              aria-label="Clear file button"
              name="Clear file"
              onClick={handleClick}
              variant="outlined"
            >{t('buttons.clear')}</Button>
            <Typography>{file.name}</Typography>
          </Fragment>
        ) : null}
      </Box>
      {isSubmitted && !file ? (
        <FormHelperText sx={{ ml: 3 }} error>{t('validations.fieldRequired')}</FormHelperText>
      ) : null}
    </Dialog>
  )
}

export default ExportTemplateUploadDialog;
